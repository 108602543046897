import "./index.scss";
import { FunctionComponent } from "react";
import { DateTime, Document, getEnumLabel, Report, Table, TableBody, TableColumn, TableHead, TableRow } from "buildingBlocks";
import { BalanceAmount } from "components/BalanceAmount";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";
import { CountryFragment, CountrySubdivisionFragment } from "graphql/schema";

type Props = {
  readonly countries: ReadonlyArray<CountryFragment>;
  readonly project: ProjectProviderTypes.MappedProject;
  readonly contract?: ProjectProviderTypes.MappedProjectContract;
  readonly invoice: ProjectProviderTypes.MappedProjectInvoice;
};

export const ReportInvoice: FunctionComponent<Props> = ({ project, contract, invoice, countries }) => {
  const countryCodes = new Map(countries.map((country) => [country.code, country.name]));
  const subdivisionCodes = new Map(
    countries.reduce<CountrySubdivisionFragment[]>((previous, current) => previous.concat(current.subdivisions), []).map((subdivision) => [subdivision.code, subdivision.name])
  );
  const contractSelection = contract?.selections.find((contractSelection) => contractSelection.id === invoice.contractSelectionId) ?? null;
  return (
    <Report id="report-invoice">
      <Document>
        <div className="invoice-header">
          <div className="invoice-header-left">
            <div className="invoice-project-contact-info">
              <strong>{project.contactInfo.companyName}</strong>
              <address>
                {project.contactInfo.address.line}
                <br />
                {project.contactInfo.address.city}, {subdivisionCodes.get(project.contactInfo.address.subdivisionCode)}, {project.contactInfo.address.postalCode}
                <br />
                {countryCodes.get(project.contactInfo.address.countryCode)}
              </address>
            </div>
            <div className="invoice-project-contact-info-props">
              {project.contactInfo.federalTaxNumber && (
                <div>
                  <label>TPS:&nbsp;</label>
                  <span>{project.contactInfo.federalTaxNumber}</span>
                </div>
              )}
              {project.contactInfo.provincialTaxNumber && (
                <div>
                  <label>TVQ:&nbsp;</label>
                  <span>{project.contactInfo.provincialTaxNumber}</span>
                </div>
              )}
            </div>
          </div>
          <div className="invoice-header-right">
            <div className="invoice-title">
              <h2>FACTURE</h2>
              <span># {invoice.label}</span>
            </div>
            <div className="invoice-balance">
              <label>Solde dû</label>
              <BalanceAmount value={invoice.balanceAmount} />
            </div>
          </div>
        </div>
        <div className="invoice-body">
          <div className="invoice-customer">
            <div className="invoice-customer-info">
              <label>Facturer à</label>
              <strong>{invoice.customerInfo.name}</strong>
              <address>
                {invoice.customerInfo.address.line}
                <br />
                {invoice.customerInfo.address.city}, {subdivisionCodes.get(invoice.customerInfo.address.subdivisionCode)}, {invoice.customerInfo.address.postalCode}
                <br />
                {countryCodes.get(invoice.customerInfo.address.countryCode)}
              </address>
            </div>
            <div className="invoice-customer-conditions">
              <div>
                <label>Date de facture :</label>
                <DateTime value={invoice.date} />
              </div>
              <div>
                <label>Conditions :</label>
                <span>{getEnumLabel(invoice.terms)}</span>
              </div>
              <div>
                <label>Date d'échéance :</label>
                <DateTime value={invoice.due} />
              </div>
            </div>
          </div>
          <Table fluid className="table-main" size="md">
            <TableHead>
              <TableRow>
                <TableColumn name="index">#</TableColumn>
                <TableColumn name="description">DESCRIPTION</TableColumn>
                <TableColumn name="quantity">QUANTITÉ</TableColumn>
                <TableColumn name="unit-price">PRIX UNITAIRE</TableColumn>
                <TableColumn name="amount">MONTANT</TableColumn>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoice.items.map((item, itemIndex) => (
                <TableRow key={itemIndex}>
                  <TableColumn name="index">{item.index + 1}</TableColumn>
                  <TableColumn name="description">{item.name}</TableColumn>
                  <TableColumn name="quantity">{item.quantity}</TableColumn>
                  <TableColumn name="unit-price">{item.unitPrice.toCurrencyFormat()}</TableColumn>
                  <TableColumn name="amount">{item.amount.toCurrencyFormat()}</TableColumn>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className="invoice-footer">
          <div className="invoice-summary">
            {invoice.feeAmount !== 0 && (
              <>
                <div>
                  <label>Sous-total</label>
                  <span>{(invoice.subtotalAmount + invoice.creditBeforeTaxAmount - invoice.feeAmount).toCurrencyFormat()}</span>
                </div>
                <div>
                  <label>Frais de gestion ({invoice.feePercent.toLocaleString("fr-CA")}%)</label>
                  <span>{invoice.feeAmount.toCurrencyFormat()}</span>
                </div>
              </>
            )}
            {invoice.creditBeforeTaxAmount !== 0 && (
              <>
                <div>
                  <label>Sous-total</label>
                  <span>{(invoice.subtotalAmount + invoice.creditBeforeTaxAmount).toCurrencyFormat()}</span>
                </div>
                <div>
                  <label>Crédit (avant taxe)</label>
                  <span>({invoice.creditBeforeTaxAmount.toCurrencyFormat()})</span>
                </div>
              </>
            )}
            <div>
              <label>Sous-total</label>
              <span>{invoice.subtotalAmount.toCurrencyFormat()}</span>
            </div>
            <div>
              <label>Taxe ({invoice.taxPercent.toLocaleString("fr-CA")}%)</label>
              <span>{invoice.taxAmount.toCurrencyFormat()}</span>
            </div>
            <div>
              <label>Total</label>
              <span>
                <b>{invoice.totalAmount.toCurrencyFormat()}</b>
              </span>
            </div>
            {invoice.creditAfterTaxAmount !== 0 && (
              <div>
                <label>Crédit (après taxe)</label>
                <span>({invoice.creditAfterTaxAmount.toCurrencyFormat()})</span>
              </div>
            )}
            <div>
              <label>Solde dû</label>
              <BalanceAmount value={invoice.balanceAmount} />
            </div>
          </div>
          {contract && contractSelection && (
            <div className="invoice-contract-info">
              <div className="invoice-contract-info-item">
                <label>Projet:</label>
                <span>{project.name}</span>
              </div>
              <div className="invoice-contract-info-item">
                <label>Unité #:</label>
                <span>{contract.unit.number}</span>
              </div>
              <div className="invoice-contract-info-item">
                <label>Révision:</label>
                <span>
                  [<DateTime value={contractSelection.updatedAt} />] {contractSelection.label}
                </span>
              </div>
            </div>
          )}
        </div>
      </Document>
    </Report>
  );
};
