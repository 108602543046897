import { createContext, FunctionComponent, useContext, useEffect, useState } from "react";
import { ContainerLoader } from "buildingBlocks";
import { useGetUserInfoQuery } from "graphql/queries";
import { UserInfoFragment } from "graphql/schema";
import { Outlet } from "react-router-dom";
import { useRouting } from "hooks";

type UserInfoContextValue = {
  readonly userInfo: UserInfoFragment;
};

const initialValue: UserInfoContextValue = {
  userInfo: {
    __typename: "UserInfo",
    userId: "",
    email: "",
    isSuperAdmin: false,
    tenants: [],
  },
};

const UserInfoContext = createContext<UserInfoContextValue>(initialValue);

const useUserInfoContext = () => useContext(UserInfoContext);

const UserInfoProvider: FunctionComponent = () => {
  const { navigate, toUnauthorizedPage } = useRouting();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserInfoFragment | null>(null);
  useGetUserInfoQuery(
    (userInfo) => {
      setUserInfo(userInfo);
      setLoaded(true);
    },
    () => {
      setUserInfo(null);
      setLoaded(true);
    }
  );
  useEffect(() => {
    if (loaded && userInfo === null) {
      navigate(toUnauthorizedPage());
    }
  }, [loaded, userInfo, navigate, toUnauthorizedPage]);
  if (!loaded || userInfo === null) {
    return <ContainerLoader />;
  }
  return (
    <UserInfoContext.Provider
      value={{
        userInfo: userInfo!,
      }}
    >
      <Outlet />
    </UserInfoContext.Provider>
  );
};

export { UserInfoProvider, useUserInfoContext };
