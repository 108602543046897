import "./index.scss";
import { FunctionComponent, PropsWithChildren } from "react";

type Props = PropsWithChildren & {
  readonly strike?: boolean;
  readonly highlight?: boolean;
  readonly subtile?: boolean;
};

export const Text: FunctionComponent<Props> = ({ children, highlight = false, strike = false, subtile = false }) => (
  <span className={`text${highlight ? " text-highlight" : ""}${strike ? " text-strike" : ""}${subtile ? " text-subtile" : ""}`}>{children}</span>
);
