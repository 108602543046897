import "./index.scss";
import { useBlockUserMutation } from "graphql/mutations";
import { FunctionComponent } from "react";
import { Dialog, DialogBody, DialogFooter } from "buildingBlocks";
import { Button } from "rsuite";
import { MappedUser } from "providers";

type Props = {
  readonly tenantId: string;
  readonly user: MappedUser;
  readonly onCompleted: () => void;
  readonly onClose: () => void;
};

export const UserBlockDialog: FunctionComponent<Props> = ({ tenantId, user, onCompleted, onClose }) => {
  const { blockUser, blockUserLoading } = useBlockUserMutation(onCompleted, onClose, true);
  return (
    <Dialog id="user-block-dialog" title="Bloquer l'utilisateur">
      <DialogBody>L'utilisateur n'aura plus accès à cette organisation.</DialogBody>
      <DialogFooter>
        <Button
          appearance="primary"
          size="sm"
          loading={blockUserLoading}
          onClick={async () => {
            await blockUser({
              tenantId: tenantId,
              userId: user.id,
            });
          }}
        >
          Bloqué
        </Button>
        <Button appearance="default" size="sm" disabled={blockUserLoading} onClick={onClose}>
          Fermer
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
