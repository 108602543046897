import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { SearchInputGroup, ViewList, Text } from "buildingBlocks";
import { useRouting } from "hooks";
import { useAppContext, useTenantContext } from "providers";
import { PermissionFragment } from "graphql/schema";

const useTenantSettingsPermissionsViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback((permissions: ReadonlyArray<PermissionFragment>) => permissions.filter((permission) => permission.name.toSearchFormat().includes(search.toSearchFormat())), [search]),
  };
};

export const TenantSettingsPermissionsView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useTenantSettingsPermissionsViewFilters();
  const { navigate, toTenantView, toTenantSettingsView } = useRouting();
  const { permissions } = useAppContext();
  const { tenant } = useTenantContext();
  return (
    <ViewList
      id="tenant-settings-permissions-view"
      security={{
        isTenantSecurityManager: true,
      }}
      header={{
        title: "Tous les permissions",
        returnButton: {
          onClick: () => {
            navigate(
              toTenantSettingsView({
                tenantId: tenant.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              appearance: "default",
              text: "Fermer les paramètres",
              show: true,
              security: {},
              onClick: () => {
                navigate(
                  toTenantView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [<SearchInputGroup size="xs" placeholder="Recherche par nom" value={filterValues.search} onChange={filterFunctions.setSearch} />],
            applyButton: null,
          },
          right: {
            inputs: [],
          },
        },
      }}
      content={{
        table: {
          headColumns: [
            {
              name: "name",
              value: "NOM",
            },
            {
              name: "description",
              value: "DESCRIPTION",
            },
          ],
          bodyRows: applyFilters(permissions).map((permission) => {
            return {
              key: permission.name,
              columns: [
                {
                  name: "name",
                  component: <Text highlight>{permission.displayName}</Text>,
                },
                {
                  name: "description",
                  component: permission.description,
                },
              ],
              actions: [],
            };
          }),
          bodyRowGroups: [],
        },
      }}
    />
  );
};
