import "./index.scss";
import { useUpdateSectionVibeMutation } from "graphql/mutations";
import { FunctionComponent } from "react";
import { ModalForm } from "buildingBlocks";
import { SectionVibeForm, useSectionVibeForm } from "forms";
import { SectionVibeMode } from "graphql/schema";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly section: ProjectProviderTypes.MappedProjectSection;
  readonly sectionVibe: ProjectProviderTypes.MappedProjectSectionVibe;
  readonly onCompleted: () => void;
  readonly onCancel: () => void;
};

export const SectionVibeUpdateModal: FunctionComponent<Props> = ({ tenantId, projectId, section, sectionVibe, onCompleted, onCancel }) => {
  const { values, setValues } = useSectionVibeForm({
    name: sectionVibe.name,
    mode: sectionVibe.mode,
    clientAmount: sectionVibe.clientAmount,
    costAmount: sectionVibe.costAmount,
    contingencyAmount: sectionVibe.contingencyAmount,
    items: sectionVibe.items.map((sectionVibeItem) => ({
      id: sectionVibeItem.id,
      index: sectionVibeItem.index,
      sectionElementId: sectionVibeItem.sectionElementId,
      sectionElementOptionId: sectionVibeItem.sectionElementOptionId,
      subcontractorAmount: sectionVibeItem.subcontractorAmount,
      contractorAmount: sectionVibeItem.contractorAmount,
      promoterAmount: sectionVibeItem.promoterAmount,
    })),
  });
  const { updateSectionVibe, updateSectionVibeErrors, updateSectionVibeLoading } = useUpdateSectionVibeMutation(onCompleted, undefined, false);
  return (
    <ModalForm
      id="section-vibe-update-modal"
      title="Modifier l'ambiance"
      size="lg"
      errors={updateSectionVibeErrors}
      loading={updateSectionVibeLoading}
      disabled={values.name.trim() === "" || values.items.some((item) => item.sectionElementOptionId === "") || (values.mode === SectionVibeMode.UPGRADE && values.costAmount === 0)}
      submitButton={{
        onClick: async () => {
          await updateSectionVibe({
            tenantId: tenantId,
            projectId: projectId,
            sectionId: section.id,
            sectionVibeId: sectionVibe.id,
            name: values.name,
            mode: values.mode,
            clientAmount: values.clientAmount,
            items: values.items.map((item) => ({
              sectionElementId: item.sectionElementId,
              sectionElementOptionId: item.sectionElementOptionId,
            })),
          });
        },
      }}
      closeButton={{
        text: "Annuler",
        onClick: onCancel,
      }}
    >
      <SectionVibeForm values={values} setValues={setValues} section={section} />
    </ModalForm>
  );
};
