import { FunctionComponent } from "react";
import { ViewForm } from "buildingBlocks";
import { useRouting, useProjectProductCategoryContext } from "hooks";
import { useUpdateProductCategoryMutation } from "graphql/mutations";
import { useProjectContext, useTenantContext } from "providers";
import { PermissionName } from "graphql/schema";
import { ProductCategoryForm, useProductCategoryForm } from "forms";

export const ProjectProductCategoryUpdateView: FunctionComponent = () => {
  const { navigate, toProjectProductsView } = useRouting();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { productCategory } = useProjectProductCategoryContext();
  const { values, setValues } = useProductCategoryForm({
    name: productCategory.name,
    displayName: productCategory.displayName ?? "",
    description: productCategory.description ?? "",
  });
  const { updateProductCategory, updateProductCategoryLoading, updateProductCategoryErrors } = useUpdateProductCategoryMutation(({ tenantId, projectId }) => {
    navigate(
      toProjectProductsView({
        tenantId: tenantId,
        projectId: projectId,
      })
    );
  });
  return (
    <ViewForm
      id="project-product-category-update-view"
      title="Modifier la catégorie de produit"
      security={{
        permissions: [[PermissionName.PRODUCT_READ, PermissionName.PRODUCT_CATEGORY_UPDATE]],
      }}
      errors={updateProductCategoryErrors}
      loading={updateProductCategoryLoading}
      onSubmit={async () => {
        await updateProductCategory({
          tenantId: tenant.id,
          projectId: project.id,
          productCategoryId: productCategory.id,
          name: values.name,
          displayName: values.displayName || null,
          description: values.description || null,
        });
      }}
      onCancel={() => {
        navigate(
          toProjectProductsView({
            tenantId: tenant.id,
            projectId: project.id,
          })
        );
      }}
    >
      <ProductCategoryForm values={values} setValues={setValues} />
    </ViewForm>
  );
};
