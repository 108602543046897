import "./index.scss";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ContainerLoader, ViewReport } from "buildingBlocks";
import { BillingReportFragment, PermissionName } from "graphql/schema";
import { useProjectContext, useTenantContext } from "providers";
import { ReportBilling } from "components";
import { CheckPicker } from "rsuite";
import { useRouting } from "hooks";
import { useList } from "react-use";
import { useGetReportBillingLazyQuery } from "graphql/queries";

export const ProjectReportBillingView: FunctionComponent = () => {
  const { navigate, toProjectReportsView } = useRouting();
  const { project, contracts } = useProjectContext();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [unitIds, { set: setUnitIds }] = useList<string>([]);
  const { tenant } = useTenantContext();
  const { units } = useProjectContext();
  const [billingReport, setBillingReport] = useState<BillingReportFragment | null>(null);
  const { getReportBilling, reportBillingLoading } = useGetReportBillingLazyQuery((billingReport) => {
    setBillingReport(billingReport);
    setIsLoaded(true);
  });
  const execute = useCallback(async () => {
    await getReportBilling({
      variables: {
        tenantId: tenant.id,
        projectId: project.id,
        unitIds: unitIds,
      },
    });
  }, [tenant, project, unitIds, getReportBilling]);
  useEffect(() => {
    execute();
    // eslint-disable-next-line
  }, []);
  if (!isLoaded || billingReport === null || reportBillingLoading) {
    return <ContainerLoader />;
  }
  const mappedBillingReport = {
    ...billingReport,
    tables: billingReport.tables.map((table) => {
      const contract = contracts.find((contract) => contract.id === table.contractId)!;
      return {
        ...table,
        contract: contract,
        contractSelection: contract.selections.find((contractSelection) => contractSelection.revisionNumber === table.revisionNumber)!,
      };
    }),
  };
  return (
    <ViewReport
      id="project-report-billing-view"
      security={{
        permissions: [[PermissionName.REPORT_READ], [PermissionName.REPORT_BILLING_READ]],
      }}
      header={{
        subtitle: "Ventes",
        title: "Facturation",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectReportsView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Dropdown",
              appearance: "primary",
              size: "sm",
              title: "Exporter",
              trigger: "click",
              placement: "bottomEnd",
              show: true,
              security: {},
              items: [
                {
                  __type: "DropdownItem",
                  text: "Télécharger (PDF)",
                  disabled: false,
                  onClick: () => {},
                },
                {
                  __type: "DropdownDivider",
                },
                {
                  __type: "DropdownItem",
                  text: "Imprimer",
                  disabled: false,
                  onClick: () => {},
                },
              ],
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [
              <CheckPicker
                size="xs"
                label="Unités"
                placeholder="Sélectionner des unités"
                searchable
                value={[...unitIds]}
                onChange={setUnitIds}
                data={units.map((unit) => ({
                  label: unit.number,
                  value: unit.id,
                }))}
              />,
            ],
            applyButton: {
              onClick: () => {
                execute();
              },
            },
          },
          right: {
            inputs: [],
          },
        },
      }}
    >
      <ReportBilling report={mappedBillingReport} />
    </ViewReport>
  );
};
