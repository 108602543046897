import "./index.scss";
import { FunctionComponent, PropsWithChildren, ReactNode } from "react";
import { Button, IconButton } from "rsuite";
import { useEnsureSecurity } from "hooks";
import { FormErrors } from "buildingBlocks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Types } from "types";

type Props = PropsWithChildren & {
  readonly id: string;
  readonly title: ReactNode;
  readonly security: Types.Security;
  readonly errors?: string[];
  readonly loading: boolean;
  readonly disabled?: boolean;
  readonly onSubmit: () => void;
  readonly onCancel: () => void;
};

export const ViewForm: FunctionComponent<Props> = ({ children, id, title, loading, security, disabled, errors, onSubmit, onCancel }) => {
  useEnsureSecurity(security);
  return (
    <section id={id} className="view-form">
      <header>
        <h1>{title}</h1>
        <IconButton appearance="link" onClick={onCancel} disabled={loading}>
          <FontAwesomeIcon icon={faClose} />
        </IconButton>
      </header>
      <main className={loading ? " loading" : ""}>
        {loading && <div className="loading-spinner" />}
        {errors && <FormErrors errors={errors} />}
        {children}
      </main>
      <footer>
        <Button size="lg" appearance="primary" onClick={onSubmit} disabled={disabled} loading={loading}>
          Enregistrer
        </Button>
        <Button size="lg" appearance="ghost" onClick={onCancel} disabled={loading}>
          Annuler
        </Button>
      </footer>
    </section>
  );
};
