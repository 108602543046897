import "./index.scss";
import { FunctionComponent } from "react";

type Props = {
  readonly subtitle: string;
  readonly value: string;
};

export const DocumentTitle: FunctionComponent<Props> = ({ subtitle, value }) => (
  <div className="document-title">
    <small>{subtitle}</small>
    <h1>{value}</h1>
  </div>
);
