import "./index.scss";
import { FunctionComponent, PropsWithChildren, ReactNode } from "react";
import { useEnsureSecurity } from "hooks";
import { HeaderTabs, HeaderToolbar, HeaderOverview } from "buildingBlocks";
import { IconButton } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Outlet } from "react-router-dom";
import { Types } from "types";

type Props = PropsWithChildren & {
  readonly id: string;
  readonly security: Types.Security;
  readonly header: {
    readonly returnButton?: {
      readonly onClick: () => void;
    };
    readonly title: ReactNode;
    readonly subtitle?: string;
    readonly toolbar: Types.HeaderToolbar;
    readonly overview?: Types.HeaderOverview;
    readonly tabs?: ReadonlyArray<Types.HeaderTab>;
  };
};

export const ViewDetail: FunctionComponent<Props> = ({ id, header, security, children }) => {
  useEnsureSecurity(security);
  return (
    <section id={id} className={`view-detail${header.overview && header.overview.cards.length !== 0 ? " view-detail-has-overview" : ""}${header.tabs ? ` view-detail-has-tabs` : ""}`}>
      <header>
        <div className="left">
          <div>
            {header.returnButton && <IconButton className="btn-return" size="sm" icon={<FontAwesomeIcon icon={faAngleLeft} />} appearance="default" onClick={header.returnButton.onClick} />}
            <div>
              {header.subtitle && <small className="subtitle">{header.subtitle}</small>}
              <h1>{header.title}</h1>
            </div>
          </div>
          <HeaderToolbar buttons={header.toolbar.buttons} otherActions={header.toolbar.otherActions} />
        </div>
        {header.overview && header.overview.cards.length !== 0 && <HeaderOverview cards={header.overview.cards} />}
        {header.tabs && <HeaderTabs tabs={header.tabs} />}
      </header>
      <main>
        {children}
        <Outlet />
      </main>
    </section>
  );
};
