import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import { Input, InputGroup } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

type Props = {
  readonly size: TypeAttributes.Size;
  readonly placeholder: string;
  readonly value: string;
  readonly onChange: (value: string) => void;
};

export const SearchInputGroup: FunctionComponent<Props> = ({ size, placeholder, value, onChange }) => (
  <InputGroup size={size} title={placeholder} className="search-input-group">
    <Input placeholder={placeholder} value={value} onChange={onChange} />
    <InputGroup.Addon>
      <FontAwesomeIcon icon={faSearch} />
    </InputGroup.Addon>
  </InputGroup>
);
