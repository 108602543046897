import "./index.scss";
import { FunctionComponent } from "react";
import { Card, DictionaryList } from "buildingBlocks";
import { UnitFragment } from "graphql/schema";

type Props = {
  readonly unit: UnitFragment;
};

export const UnitCard: FunctionComponent<Props> = ({ unit }) => (
  <Card className="unit-card" title="Unité">
    <DictionaryList
      inline
      items={[
        {
          title: "Numéro",
          description: unit.number,
        },
        {
          title: "Étage",
          description: unit.floor,
        },
        {
          title: "Nombre de salle de bain",
          description: unit.numberOfBathrooms,
        },
        {
          title: "Nombre de chambres",
          description: unit.numberOfBedrooms,
        },
      ]}
    />
  </Card>
);
