import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { SearchInputGroup, ViewList } from "buildingBlocks";
import { useRouting } from "hooks";
import { useTenantContext } from "providers";
import { CustomerFragment, PermissionName } from "graphql/schema";
import { CustomerDeleteDialog } from "dialogs";
import { CustomerDetails } from "components";

const useCustomersViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback(
      (customers: ReadonlyArray<CustomerFragment>) =>
        customers.filter(
          (customer) =>
            customer.displayName.toSearchFormat().includes(search.toSearchFormat()) ||
            customer.email.toSearchFormat().includes(search.toSearchFormat()) ||
            customer.phone.toSearchFormat().includes(search.toSearchFormat())
        ),
      [search]
    ),
  };
};

export const CustomersView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useCustomersViewFilters();
  const { navigate, toTenantCustomerView, toTenantCustomerCreateView, toTenantCustomerUpdateView } = useRouting();
  const { tenant, customers } = useTenantContext();
  const [customerToDelete, setCustomerToDelete] = useState<CustomerFragment | null>(null);
  return (
    <ViewList
      id="customers-view"
      security={{
        permissions: [[PermissionName.CUSTOMER_READ]],
      }}
      header={{
        title: "Tous les clients",
        toolbar: {
          buttons: [
            {
              __type: "Button",
              text: "Créer un client",
              show: true,
              security: {
                permissions: [[PermissionName.CUSTOMER_CREATE]],
              },
              onClick: () => {
                navigate(
                  toTenantCustomerCreateView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [<SearchInputGroup size="xs" placeholder="Recherche" value={filterValues.search} onChange={filterFunctions.setSearch} />],
            applyButton: null,
          },
          right: {
            inputs: [],
          },
        },
      }}
      content={{
        table: {
          emptyBodies: [
            {
              show: customers.length === 0,
              title: "Aucun client",
              content: "Pour créer un client, cliquez sur le bouton ci-dessous.",
              button: {
                text: "Créer un client",
                security: {
                  permissions: [[PermissionName.CUSTOMER_CREATE]],
                },
                onClick: () => {
                  navigate(
                    toTenantCustomerCreateView({
                      tenantId: tenant.id,
                    })
                  );
                },
              },
            },
          ],
          headColumns: [
            {
              name: "name",
              value: "NOM",
            },
            {
              name: "email",
              value: "COURRIEL",
            },
            {
              name: "phone",
              value: "TÉLÉPHONE",
            },
          ],
          bodyRows: applyFilters(customers).map((customer) => {
            return {
              key: customer.id,
              href: toTenantCustomerView({
                tenantId: tenant.id,
                customerId: customer.id,
              }),
              columns: [
                {
                  name: "name",
                  component: <CustomerDetails customer={customer} highlight />,
                },
                {
                  name: "email",
                  component: customer.email,
                },
                {
                  name: "phone",
                  component: customer.phone.toPhoneNumberFormat(),
                },
              ],
              actions: [
                {
                  __type: "Button",
                  content: "Modifier le client",
                  show: true,
                  security: {
                    permissions: [[PermissionName.CUSTOMER_UPDATE]],
                  },
                  onClick: () => {
                    navigate(
                      toTenantCustomerUpdateView({
                        tenantId: tenant.id,
                        customerId: customer.id,
                      })
                    );
                  },
                },
                {
                  __type: "Button",
                  content: "Supprimer le client",
                  show: true,
                  security: {
                    permissions: [[PermissionName.CUSTOMER_DELETE]],
                  },
                  onClick: () => {
                    setCustomerToDelete(customer);
                  },
                },
              ],
            };
          }),
          bodyRowGroups: [],
        },
      }}
    >
      {customerToDelete && (
        <CustomerDeleteDialog
          tenantId={tenant.id}
          customer={customerToDelete}
          onCompleted={() => {
            setCustomerToDelete(null);
          }}
          onClose={() => {
            setCustomerToDelete(null);
          }}
        />
      )}
    </ViewList>
  );
};
