import "./index.scss";
import { FunctionComponent, useState } from "react";
import { TooltipWrapper, ViewDetail } from "buildingBlocks";
import { useTenantCustomerContext, useRouting } from "hooks";
import { CustomerDeleteDialog } from "dialogs";
import { CustomerFragment, PermissionName } from "graphql/schema";
import { useTenantContext } from "providers";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CustomerView: FunctionComponent = () => {
  const { navigate, matchPath, toTenantCustomersView, toTenantCustomerView, toTenantCustomerUpdateView } = useRouting();
  const { tenant } = useTenantContext();
  const { customer } = useTenantCustomerContext();
  const [customerToDelete, setCustomerToDelete] = useState<CustomerFragment | null>(null);
  return (
    <ViewDetail
      id="customer-view"
      security={{
        permissions: [[PermissionName.CUSTOMER_READ]],
      }}
      header={{
        returnButton: {
          onClick: () => {
            navigate(
              toTenantCustomersView({
                tenantId: tenant.id,
              })
            );
          },
        },
        subtitle: "Client",
        title: (
          <div
            style={{
              display: "flex",
              gap: "5px",
            }}
          >
            {customer.isDefault && (
              <TooltipWrapper tooltip="Verrouillé">
                <FontAwesomeIcon color="grey" size="sm" icon={faLock} />
              </TooltipWrapper>
            )}
            <span>{customer.displayName}</span>
          </div>
        ),
        toolbar: {
          buttons: [
            {
              __type: "Button",
              text: "Modifier le client",
              show: true,
              security: {
                permissions: [[PermissionName.CUSTOMER_UPDATE]],
              },
              onClick: () => {
                navigate(
                  toTenantCustomerUpdateView({
                    tenantId: tenant.id,
                    customerId: customer.id,
                  })
                );
              },
            },
          ],
          otherActions: [
            {
              __type: "Button",
              content: "Supprimer le client",
              show: true,
              security: {
                permissions: [[PermissionName.CUSTOMER_DELETE]],
              },
              onClick: () => {
                setCustomerToDelete(customer);
              },
            },
          ],
        },
        tabs: [
          {
            name: "Vue d'ensemble",
            active: matchPath(
              toTenantCustomerView({
                tenantId: null,
                customerId: null,
              })
            ),
            show: true,
            security: {},
            onClick: () => {
              navigate(
                toTenantCustomerView({
                  tenantId: tenant.id,
                  customerId: customer.id,
                })
              );
            },
          },
          // {
          //   name: "Contrats",
          //   active: matchPath(
          //     toTenantCustomerContractsView({
          //       tenantId: null,
          //       customerId: null,
          //     })
          //   ),
          //   show: true,
          //   security: {
          //     permissions: [[PermissionName.CONTRACT_READ]],
          //   },
          //   onClick: () => {
          //     navigate(
          //       toTenantCustomerContractsView({
          //         tenantId: tenant.id,
          //         customerId: customer.id,
          //       })
          //     );
          //   },
          // },
        ],
      }}
    >
      {customerToDelete && (
        <CustomerDeleteDialog
          tenantId={tenant.id}
          customer={customerToDelete}
          onCompleted={() => {
            navigate(
              toTenantCustomersView({
                tenantId: tenant.id,
              })
            );
          }}
          onClose={() => {
            setCustomerToDelete(null);
          }}
        />
      )}
    </ViewDetail>
  );
};
