import "./index.scss";
import { FormContainer } from "buildingBlocks";
import produce from "immer";
import { FunctionComponent, useState } from "react";
import { Form, Input } from "rsuite";

type ProductCategoryFormValues = {
  readonly name: string;
  readonly displayName: string;
  readonly description: string;
};

type Props = {
  readonly values: ProductCategoryFormValues;
  readonly setValues: (values: ProductCategoryFormValues) => void;
};

export const useProductCategoryForm = (initialValues: ProductCategoryFormValues) => {
  const [values, setValues] = useState<ProductCategoryFormValues>(initialValues);
  return { values, setValues };
};

export const ProductCategoryForm: FunctionComponent<Props> = ({ values, setValues }) => (
  <Form id="product-category-form">
    <FormContainer highlight>
      <Form.Group>
        <Form.ControlLabel>Nom</Form.ControlLabel>
        <Input
          value={values.name}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.name = value;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Nom affiché</Form.ControlLabel>
        <Input
          value={values.displayName}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.displayName = value;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Description</Form.ControlLabel>
        <Input
          as="textarea"
          rows={3}
          value={values.description}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.description = value;
              })
            );
          }}
        />
      </Form.Group>
    </FormContainer>
  </Form>
);
