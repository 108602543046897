import "./index.scss";
import { FormContainer, Uploader } from "buildingBlocks";
import produce from "immer";
import { FunctionComponent, useState } from "react";
import { Form } from "rsuite";

type ModelFormValues = {
  readonly dataBlobFile: File | undefined;
  readonly unityDataBlobFile: File | undefined;
  readonly unityFrameworkBlobFile: File | undefined;
  readonly unityLoaderBlobFile: File | undefined;
  readonly unityWasmBlobFile: File | undefined;
  readonly productThumbnailBlobFiles: ReadonlyArray<File>;
};

type Props = {
  readonly values: ModelFormValues;
  readonly setValues: (values: ModelFormValues) => void;
};

export const useModelForm = (initialValues: ModelFormValues) => {
  const [values, setValues] = useState<ModelFormValues>(initialValues);
  return { values, setValues };
};

export const ModelForm: FunctionComponent<Props> = ({ values, setValues }) => (
  <Form id="model-form">
    <FormContainer highlight>
      <Form.Group>
        <Form.ControlLabel>Fichier de configuration du modèle (.json)</Form.ControlLabel>
        <Uploader
          accept=".json"
          disabled={values.dataBlobFile !== undefined}
          onChange={(fileTypes) => {
            const fileType = fileTypes[0];
            if (fileType) {
              setValues(
                produce(values, (draft) => {
                  draft.dataBlobFile = fileType.blobFile;
                })
              );
            }
          }}
          onRemove={() => {
            setValues(
              produce(values, (draft) => {
                draft.dataBlobFile = undefined;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Fichier de données Unity (.data)</Form.ControlLabel>
        <Uploader
          accept=".data.unityweb"
          disabled={values.unityDataBlobFile !== undefined}
          onChange={(fileTypes) => {
            const fileType = fileTypes[0];
            if (fileType) {
              setValues(
                produce(values, (draft) => {
                  draft.unityDataBlobFile = fileType.blobFile;
                })
              );
            }
          }}
          onRemove={() => {
            setValues(
              produce(values, (draft) => {
                draft.unityDataBlobFile = undefined;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Fichier du cadriciel Unity (.framework.js)</Form.ControlLabel>
        <Uploader
          accept=".framework.js.unityweb"
          disabled={values.unityFrameworkBlobFile !== undefined}
          onChange={(fileTypes) => {
            const fileType = fileTypes[0];
            if (fileType) {
              setValues(
                produce(values, (draft) => {
                  draft.unityFrameworkBlobFile = fileType.blobFile;
                })
              );
            }
          }}
          onRemove={() => {
            setValues(
              produce(values, (draft) => {
                draft.unityFrameworkBlobFile = undefined;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Fichier de chargement Unity (.loader.js)</Form.ControlLabel>
        <Uploader
          accept=".loader.js"
          disabled={values.unityLoaderBlobFile !== undefined}
          onChange={(fileTypes) => {
            const fileType = fileTypes[0];
            if (fileType) {
              setValues(
                produce(values, (draft) => {
                  draft.unityLoaderBlobFile = fileType.blobFile;
                })
              );
            }
          }}
          onRemove={() => {
            setValues(
              produce(values, (draft) => {
                draft.unityLoaderBlobFile = undefined;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Fichier binaire Unity (.wasm)</Form.ControlLabel>
        <Uploader
          accept=".wasm.unityweb"
          disabled={values.unityWasmBlobFile !== undefined}
          onChange={(fileTypes) => {
            const fileType = fileTypes[0];
            if (fileType) {
              setValues(
                produce(values, (draft) => {
                  draft.unityWasmBlobFile = fileType.blobFile;
                })
              );
            }
          }}
          onRemove={() => {
            setValues(
              produce(values, (draft) => {
                draft.unityWasmBlobFile = undefined;
              })
            );
          }}
        />
      </Form.Group>
    </FormContainer>
    <FormContainer>
      <Form.Group>
        <Form.ControlLabel>Vignettes des produits</Form.ControlLabel>
        <Uploader
          multiple
          accept=".png"
          disabled={values.productThumbnailBlobFiles.length !== 0}
          onChange={(fileList) => {
            setValues(
              produce(values, (draft) => {
                draft.productThumbnailBlobFiles = fileList.map((file) => file.blobFile!);
              })
            );
          }}
          onRemove={() => {
            setValues(
              produce(values, (draft) => {
                draft.productThumbnailBlobFiles = [];
              })
            );
          }}
        />
      </Form.Group>
    </FormContainer>
  </Form>
);
