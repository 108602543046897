import "./index.scss";
import { useCancelContractMutation } from "graphql/mutations";
import { FunctionComponent, useState } from "react";
import { Dialog, DialogBody, DialogFooter } from "buildingBlocks";
import { Button, Form, Input, Tag, Toggle } from "rsuite";
import produce from "immer";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly contract: ProjectProviderTypes.MappedProjectContract;
  readonly onCompleted: () => void;
  readonly onClose: () => void;
};

export const ContractCancelDialog: FunctionComponent<Props> = ({ tenantId, projectId, contract, onCompleted, onClose }) => {
  const [values, setValues] = useState<{
    readonly objectValue: string;
    readonly isRefundable: boolean;
  }>({
    objectValue: "",
    isRefundable: false,
  });
  const { cancelContract, cancelContractLoading } = useCancelContractMutation(onCompleted, onClose, true);
  return (
    <Dialog id="contract-cancel-dialog" title="Annuler le contrat">
      <DialogBody>
        <p className="dialog-danger-zone">Vous êtes sur le point de faire une action définitive. Cette action est irréversible et ne peut pas être annulée.</p>
        <Form>
          <Form.Group>
            <Form.ControlLabel>Est remboursable</Form.ControlLabel>
            <Toggle
              checked={values.isRefundable}
              checkedChildren="OUI"
              unCheckedChildren="NON"
              onChange={(checked) => {
                setValues(
                  produce(values, (draft) => {
                    draft.isRefundable = checked;
                  })
                );
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>
              Pour confirmer l'annulation, veuillez écrire{" "}
              <Tag className="dialog-object-name" size="sm">
                {contract.unit.label}
              </Tag>{" "}
              ci-dessous.
            </Form.ControlLabel>
            <Input
              value={values.objectValue}
              onChange={(value) => {
                setValues(
                  produce(values, (draft) => {
                    draft.objectValue = value;
                  })
                );
              }}
              size="md"
            />
          </Form.Group>
        </Form>
      </DialogBody>
      <DialogFooter>
        <Button
          appearance="primary"
          size="sm"
          disabled={contract.unit.label !== values.objectValue}
          loading={cancelContractLoading}
          onClick={async () => {
            await cancelContract({
              tenantId: tenantId,
              projectId: projectId,
              contractId: contract.id,
              isRefundable: values.isRefundable,
            });
          }}
        >
          Annulé
        </Button>
        <Button appearance="default" size="sm" disabled={cancelContractLoading} onClick={onClose}>
          Fermer
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
