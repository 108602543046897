import "./index.scss";
import { FunctionComponent } from "react";
import { Card, DictionaryList } from "buildingBlocks";
import { CustomerFragment } from "graphql/schema";

type Props = {
  readonly customer: CustomerFragment;
  readonly primary?: boolean;
};

export const CustomerCard: FunctionComponent<Props> = ({ customer, primary = false }) => (
  <Card className="customer-card" title={primary ? "Client principal" : "Client secondaire"}>
    <DictionaryList
      inline
      items={[
        {
          title: "Nom complet",
          description: customer.fullName,
        },
        {
          title: "Courriel",
          description: customer.email,
        },
        {
          title: "Téléphone",
          description: customer.phone.toPhoneNumberFormat(),
        },
      ]}
    />
  </Card>
);
