import { createContext, FunctionComponent, PropsWithChildren, useContext } from "react";
import { useList } from "react-use";
import { ContainerLoader } from "buildingBlocks";
import { useFetchCountriesQuery, useFetchPermissionsQuery, useFetchTenantsQuery } from "graphql/queries";
import { CountryFragment, PermissionFragment, TenantFragment } from "graphql/schema";

type AppContextValue = {
  readonly countries: ReadonlyArray<CountryFragment>;
  readonly permissions: ReadonlyArray<PermissionFragment>;
  readonly tenants: ReadonlyArray<TenantFragment>;
};

const initialValue: AppContextValue = {
  countries: [],
  permissions: [],
  tenants: [],
};

const AppContext = createContext<AppContextValue>(initialValue);

const useAppContext = () => useContext(AppContext);

const AppProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  // Country
  const [countries, { set: setCountries }] = useList<CountryFragment>([]);
  const { loading: loadingCountries } = useFetchCountriesQuery((countries) => {
    setCountries([...countries]);
  });
  // Permission
  const [permissions, { set: setPermissions }] = useList<PermissionFragment>([]);
  const { loading: loadingPermissions } = useFetchPermissionsQuery((permissions) => {
    setPermissions([...permissions]);
  });
  // Tenant
  const [tenants, { set: setTenants }] = useList<TenantFragment>([]);
  const { loading: loadingTenants } = useFetchTenantsQuery((tenants) => {
    setTenants([...tenants]);
  });
  // ----------
  if (loadingCountries || loadingPermissions || loadingTenants) {
    return <ContainerLoader />;
  }
  return (
    <AppContext.Provider
      value={{
        countries: countries,
        permissions: permissions,
        tenants: tenants,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, useAppContext };
