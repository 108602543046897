import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { EnumLabel, getEnumLabel, SearchInputGroup, ViewList, Text } from "buildingBlocks";
import { useRouting } from "hooks";
import { CheckPicker, SelectPicker } from "rsuite";
import { useProjectContext, useTenantContext } from "providers";
import { ContractState, PermissionName, ContractSelectionState, UnitState } from "graphql/schema";
import { UnitDeleteDialog, ContractCancelDialog, ContractDeleteDialog } from "dialogs";
import { BalanceAmount } from "components";
import { useList } from "react-use";
import { UnitsImportModal } from "modals";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

const useProjectUnitsViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  const [states, { set: setStates }] = useList<UnitState>([]);
  const [projectUnitFloor, setProjectUnitFloor] = useState<number | null>(null);
  return {
    filterValues: {
      search,
      states,
      projectUnitFloor,
    },
    filterFunctions: {
      setSearch,
      setStates,
      setProjectUnitFloor,
    },
    applyFilters: useCallback(
      (units: ReadonlyArray<ProjectProviderTypes.MappedProjectUnit>) =>
        units
          .filter((unit) => (states.length !== 0 ? states.includes(unit.state) : true))
          .filter((unit) => unit.label.toSearchFormat().includes(search.toSearchFormat()))
          .map((unit) => unit.floor)
          .unique()
          .sort((left, right) => left - right)
          .filter((floor) => (projectUnitFloor ? floor === projectUnitFloor : true))
          .map((floor) => ({
            value: floor,
            units: units
              .filter((projectUnit) => projectUnit.floor === floor)
              .filter((unit) => (states.length !== 0 ? states.includes(unit.state) : true))
              .filter((unit) => unit.label.toSearchFormat().includes(search.toSearchFormat())),
          })),
      [search, states, projectUnitFloor]
    ),
  };
};

export const ProjectUnitsView: FunctionComponent = () => {
  const {
    navigate,
    toProjectView,
    toProjectContractSelectionsView,
    toProjectContractInvoicesView,
    toProjectUnitContractCreateView,
    toProjectUnitsUpdateView,
    toProjectUnitCreateView,
    toProjectUnitUpdateView,
  } = useRouting();
  const { tenant } = useTenantContext();
  const { project, units } = useProjectContext();
  const { filterValues, filterFunctions, applyFilters } = useProjectUnitsViewFilters();
  const [unitToDelete, setUnitToDelete] = useState<ProjectProviderTypes.MappedProjectUnit | null>(null);
  const [contractToCancel, setContractToCancel] = useState<ProjectProviderTypes.MappedProjectContract | null>(null);
  const [contractToDelete, setContractToDelete] = useState<ProjectProviderTypes.MappedProjectContract | null>(null);
  const [projectToImportUnits, setProjectToImportUnits] = useState<ProjectProviderTypes.MappedProject | null>(null);
  return (
    <ViewList
      id="project-units-view"
      security={{
        permissions: [[PermissionName.UNIT_READ]],
      }}
      header={{
        title: "Toutes les unités",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              text: "Créer une unité",
              security: {
                permissions: [[PermissionName.UNIT_CREATE]],
              },
              show: units.length !== 0,
              onClick: () => {
                navigate(
                  toProjectUnitCreateView({
                    tenantId: tenant.id,
                    projectId: project.id,
                  })
                );
              },
            },
          ],
          otherActions: [
            {
              __type: "Button",
              content: "Modifier les unités",
              security: {
                permissions: [[PermissionName.UNIT_UPDATE]],
              },
              show: units.length !== 0,
              disabled: false,
              onClick: () => {
                navigate(
                  toProjectUnitsUpdateView({
                    tenantId: tenant.id,
                    projectId: project.id,
                  })
                );
              },
            },
          ],
        },
        filter: {
          left: {
            inputs: [
              <SearchInputGroup size="xs" placeholder="Recherche par numéro d'unité" value={filterValues.search} onChange={filterFunctions.setSearch} />,
              <SelectPicker
                size="xs"
                label="Étage"
                value={String(filterValues.projectUnitFloor)}
                onChange={(value) => {
                  if (value) {
                    filterFunctions.setProjectUnitFloor(Number(value));
                  } else {
                    filterFunctions.setProjectUnitFloor(null);
                  }
                }}
                data={units
                  .map((unit) => unit.floor)
                  .unique()
                  .sort((left, right) => left - right)
                  .map((floor) => ({
                    label: floor.toString(),
                    value: floor.toString(),
                  }))}
              />,
              <CheckPicker
                size="xs"
                label="État"
                value={filterValues.states}
                onChange={filterFunctions.setStates}
                cleanable
                searchable={false}
                data={[
                  { label: getEnumLabel(UnitState.NO_CONTRACT), value: UnitState.NO_CONTRACT },
                  { label: getEnumLabel(UnitState.CONTRACT_IS_CUSTOMIZED), value: UnitState.CONTRACT_IS_CUSTOMIZED },
                  { label: getEnumLabel(UnitState.CONTRACT_WITHOUT_FORM), value: UnitState.CONTRACT_WITHOUT_FORM },
                  { label: getEnumLabel(UnitState.CONTRACT_SELECTION_PENDING), value: UnitState.CONTRACT_SELECTION_PENDING },
                  { label: getEnumLabel(UnitState.CONTRACT_REVISION_PENDING), value: UnitState.CONTRACT_REVISION_PENDING },
                  { label: getEnumLabel(UnitState.CONTRACT_PAYMENT_PENDING), value: UnitState.CONTRACT_PAYMENT_PENDING },
                  { label: getEnumLabel(UnitState.CONTRACT_REFUND_PENDING), value: UnitState.CONTRACT_REFUND_PENDING },
                  { label: getEnumLabel(UnitState.CONTRACT_COMPLETED), value: UnitState.CONTRACT_COMPLETED },
                ]}
              />,
            ],
            applyButton: null,
          },
          right: {
            inputs: [],
          },
        },
      }}
      content={{
        table: {
          emptyBodies: [
            {
              show: units.length === 0,
              title: "Aucune unité",
              content: "Pour importer des unités, cliquez sur le bouton ci-dessous.",
              button: {
                text: "Importer des unités",
                security: {
                  permissions: [[PermissionName.UNIT_IMPORT]],
                },
                onClick: () => {
                  setProjectToImportUnits(project);
                },
              },
            },
          ],
          headColumns: [
            {
              name: "number",
              value: "UNITÉ",
            },
            {
              name: "state",
              value: "STATUT",
            },
            {
              name: "form",
              value: "FORMULAIRE",
            },
            {
              name: "selection",
              value: "SÉLECTION",
            },
            {
              name: "customers",
              value: "CLIENTS",
            },
            {
              name: "total",
              value: "TOTAL",
            },
            {
              name: "paid",
              value: "PAYÉ",
            },
            {
              name: "refunded",
              value: "REMBOURSÉ",
            },
            {
              name: "balance",
              value: "SOLDE",
            },
          ],
          bodyRows: [],
          bodyRowGroups: applyFilters(units).map((floor) => {
            return {
              key: floor.value.toString(),
              columns: [
                {
                  name: "floor",
                  component: (
                    <div>
                      <span>
                        {floor.value}
                        <sup>{floor.value === 1 ? "er" : "e"}</sup>
                      </span>
                      <span>étage</span>
                    </div>
                  ),
                },
              ],
              actions: [],
              rows: floor.units.map((unit) => {
                return {
                  key: unit.id,
                  href: !unit.currentContract
                    ? undefined
                    : unit.currentContract.state === ContractState.IS_CUSTOMIZED
                      ? toProjectContractInvoicesView({
                          tenantId: tenant.id,
                          projectId: project.id,
                          contractId: unit.currentContract.id,
                        })
                      : toProjectContractSelectionsView({
                          tenantId: tenant.id,
                          projectId: project.id,
                          contractId: unit.currentContract.id,
                        }),
                  columns: [
                    {
                      name: "number",
                      component: <Text highlight>{unit.number}</Text>,
                    },
                    {
                      name: "state",
                      component: (
                        <EnumLabel
                          value={unit.state}
                          appearances={[
                            { value: UnitState.NO_CONTRACT, appearance: "primary" },
                            { value: UnitState.CONTRACT_IS_CUSTOMIZED, appearance: "secondary" },
                            { value: UnitState.CONTRACT_WITHOUT_FORM, appearance: "primary" },
                            { value: UnitState.CONTRACT_WITHOUT_SELECTION, appearance: "primary" },
                            { value: UnitState.CONTRACT_SELECTION_PENDING, appearance: "primary" },
                            { value: UnitState.CONTRACT_REVISION_PENDING, appearance: "primary" },
                            { value: UnitState.CONTRACT_PAYMENT_PENDING, appearance: "warning" },
                            { value: UnitState.CONTRACT_REFUND_PENDING, appearance: "warning" },
                            { value: UnitState.CONTRACT_COMPLETED, appearance: "secondary" },
                          ]}
                          uppercase
                          bold
                          block
                        />
                      ),
                    },
                    {
                      name: "form",
                      component: unit.currentContract ? (unit.currentContract.form?.name ?? "--") : (unit.form?.name ?? "--"),
                    },
                    {
                      name: "selection",
                      component: unit.currentContract && unit.currentContract.currentSelection ? unit.currentContract.currentSelection.label : "--",
                    },
                    {
                      name: "customers",
                      component: unit.currentContract ? (
                        <div className="contract-customer-list">
                          {unit.currentContract.customers.map(({ customer }) => (
                            <span key={customer.id}>{customer.fullName}</span>
                          ))}
                        </div>
                      ) : (
                        "--"
                      ),
                    },
                    {
                      name: "total",
                      component:
                        unit.currentContract && unit.currentContract.state !== ContractState.IS_CUSTOMIZED ? unit.currentContract.totalAmount.toCurrencyFormat() : Number(0).toCurrencyFormat(),
                    },
                    {
                      name: "paid",
                      component: unit.currentContract && unit.currentContract.state !== ContractState.IS_CUSTOMIZED ? unit.currentContract.paidAmount.toCurrencyFormat() : Number(0).toCurrencyFormat(),
                    },
                    {
                      name: "refunded",
                      component:
                        unit.currentContract && unit.currentContract.state !== ContractState.IS_CUSTOMIZED ? unit.currentContract.refundedAmount.toCurrencyFormat() : Number(0).toCurrencyFormat(),
                    },
                    {
                      name: "balance",
                      component:
                        unit.currentContract && unit.currentContract.state !== ContractState.IS_CUSTOMIZED ? (
                          <BalanceAmount value={unit.currentContract.balanceAmount} />
                        ) : (
                          Number(0).toCurrencyFormat()
                        ),
                    },
                  ],
                  actions: [
                    {
                      __type: "Button",
                      content: "Modifier l'unité",
                      security: {
                        permissions: [[PermissionName.UNIT_UPDATE]],
                      },
                      show: true,
                      onClick: () => {
                        navigate(
                          toProjectUnitUpdateView({
                            tenantId: tenant.id,
                            projectId: project.id,
                            unitId: unit.id,
                          })
                        );
                      },
                    },
                    {
                      __type: "Button",
                      content: "Supprimer l'unité",
                      security: {
                        permissions: [[PermissionName.UNIT_DELETE]],
                      },
                      show: unit.contracts.length === 0,
                      onClick: () => {
                        setUnitToDelete(unit);
                      },
                    },
                    {
                      __type: "Button",
                      content: "Créer un contrat",
                      security: {
                        permissions: [[PermissionName.CONTRACT_READ, PermissionName.CONTRACT_CREATE]],
                      },
                      show: unit.currentContract === null,
                      onClick: () => {
                        navigate(
                          toProjectUnitContractCreateView({
                            tenantId: tenant.id,
                            projectId: project.id,
                            unitId: unit.id,
                          })
                        );
                      },
                    },
                    {
                      __type: "Button",
                      content: "Annuler le contrat",
                      security: {
                        permissions: [[PermissionName.CONTRACT_READ, PermissionName.CONTRACT_CANCEL]],
                      },
                      show: !!unit.currentContract && !unit.currentContract.isCanceled,
                      onClick: () => {
                        setContractToCancel(unit.currentContract!);
                      },
                    },
                    {
                      __type: "Button",
                      content: "Supprimer le contrat",
                      security: {
                        permissions: [[PermissionName.CONTRACT_READ, PermissionName.CONTRACT_DELETE]],
                      },
                      show:
                        !!unit.currentContract &&
                        !unit.currentContract.isCanceled &&
                        (unit.currentContract.selections.length === 0 || unit.currentContract.selections.every((contractSelection) => contractSelection.state === ContractSelectionState.PENDING)),
                      onClick: () => {
                        setContractToDelete(unit.currentContract!);
                      },
                    },
                  ],
                };
              }),
            };
          }),
        },
      }}
    >
      {projectToImportUnits && (
        <UnitsImportModal
          tenantId={tenant.id}
          projectId={projectToImportUnits.id}
          onCompleted={() => {
            setProjectToImportUnits(null);
          }}
          onCancel={() => {
            setProjectToImportUnits(null);
          }}
        />
      )}
      {unitToDelete && (
        <UnitDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          unit={unitToDelete}
          onCompleted={() => {
            setUnitToDelete(null);
          }}
          onClose={() => {
            setUnitToDelete(null);
          }}
        />
      )}
      {contractToDelete && (
        <ContractDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          contract={contractToDelete}
          onCompleted={() => {
            setContractToDelete(null);
          }}
          onClose={() => {
            setContractToDelete(null);
          }}
        />
      )}
      {contractToCancel && (
        <ContractCancelDialog
          tenantId={tenant.id}
          projectId={project.id}
          contract={contractToCancel}
          onCompleted={() => {
            setContractToCancel(null);
          }}
          onClose={() => {
            setContractToCancel(null);
          }}
        />
      )}
    </ViewList>
  );
};
