import { FunctionComponent } from "react";
import { ViewForm } from "buildingBlocks";
import { useUpdateCustomerMutation } from "graphql/mutations";
import { PermissionName } from "graphql/schema";
import { useRouting, useTenantCustomerContext } from "hooks";
import { useAppContext, useTenantContext } from "providers";
import { CustomerForm, useCustomerForm } from "forms";

export const CustomerUpdateView: FunctionComponent = () => {
  const { navigate, toTenantCustomerView } = useRouting();
  const { countries } = useAppContext();
  const { tenant } = useTenantContext();
  const { customer } = useTenantCustomerContext();
  const { values, setValues } = useCustomerForm({
    firstName: customer.firstName ?? "",
    lastName: customer.lastName ?? "",
    email: customer.email,
    phone: customer.phone,
    gender: customer.gender,
    language: customer.language,
    notes: customer.notes ?? "",
    address: {
      countryCode: customer.address.countryCode,
      line: customer.address.line,
      city: customer.address.city,
      subdivisionCode: customer.address.subdivisionCode,
      postalCode: customer.address.postalCode,
    },
  });
  const { updateCustomer, updateCustomerLoading, updateCustomerErrors } = useUpdateCustomerMutation(({ customer }) => {
    navigate(
      toTenantCustomerView({
        tenantId: tenant.id,
        customerId: customer.id,
      })
    );
  });
  return (
    <ViewForm
      id="customer-update-view"
      title="Modifier le client"
      security={{
        permissions: [[PermissionName.CUSTOMER_READ, PermissionName.CUSTOMER_UPDATE]],
      }}
      errors={updateCustomerErrors}
      loading={updateCustomerLoading}
      onSubmit={async () => {
        await updateCustomer({
          tenantId: tenant.id,
          customerId: customer.id,
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
          email: values.email,
          phone: values.phone,
          language: values.language,
          gender: values.gender,
          notes: values.notes.trim() || null,
          address: {
            countryCode: values.address.countryCode,
            line: values.address.line,
            city: values.address.city,
            subdivisionCode: values.address.subdivisionCode,
            postalCode: values.address.postalCode,
          },
        });
      }}
      onCancel={() => {
        navigate(
          toTenantCustomerView({
            tenantId: tenant.id,
            customerId: customer.id,
          })
        );
      }}
    >
      <CustomerForm values={values} setValues={setValues} countries={countries} />
    </ViewForm>
  );
};
