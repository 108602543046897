import "./index.scss";
import { faAngleUp, faAngleDown, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, Form, IconButton, Popover, Whisper } from "rsuite";
import { Fragment, FunctionComponent, ReactNode } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TypeAttributes } from "rsuite/esm/@types/common";
import { Table, TableBody, TableColumn, TableColumnActions, TableContent, TableHead, TableRow } from "buildingBlocks";
import { Types } from "types";

export type FormPanelInput = {
  readonly name: string;
  readonly label: string;
  readonly node: ReactNode;
};

type Props = {
  readonly className?: string;
  readonly header: {
    readonly start?: {
      readonly index: number;
      readonly buttons: {
        readonly increase: {
          readonly disabled: boolean;
          readonly onClick: () => void;
        };
        readonly decrease: {
          readonly disabled: boolean;
          readonly onClick: () => void;
        };
      };
    };
    readonly center: {
      readonly inputs: ReadonlyArray<FormPanelInput>;
    };
    readonly end?: {
      readonly buttons: ReadonlyArray<{
        readonly disabled?: boolean;
        readonly name?: string;
        readonly onClick: () => void;
      }>;
    };
  };
  readonly body?: {
    readonly header?: {
      readonly leftNodes: ReadonlyArray<ReactNode>;
      readonly rightNodes: ReadonlyArray<ReactNode>;
    };
    readonly display?: boolean;
    readonly table: Types.Table & {
      readonly title: string;
      readonly buttons?: ReadonlyArray<{
        readonly appearance?: TypeAttributes.Appearance;
        readonly text: string;
        readonly disabled?: boolean;
        readonly onClick: () => void;
        readonly icon?: IconProp;
      }>;
    };
  };
};

export const FormPanel: FunctionComponent<Props> = ({ className, header, body }) => (
  <div className={`${className ? `${className} ` : ""}form-panel${body ? " form-panel-with-body" : ""}`}>
    <div className="form-panel-header">
      {header.start && (
        <div className="form-panel-start">
          <Button tabIndex={-1} disabled={header.start.buttons.increase.disabled} size="xs" appearance="link" onClick={header.start.buttons.increase.onClick}>
            <FontAwesomeIcon size="xs" icon={faAngleUp} />
          </Button>
          <span className="form-panel-index">{header.start.index + 1}</span>
          <Button tabIndex={-1} disabled={header.start.buttons.decrease.disabled} size="xs" appearance="link" onClick={header.start.buttons.decrease.onClick}>
            <FontAwesomeIcon size="xs" icon={faAngleDown} />
          </Button>
        </div>
      )}
      <div className="form-panel-center">
        {header.center.inputs.map((input, index) => (
          <Form.Group key={index} className={`form-group-${input.name}`}>
            {input.label && <Form.ControlLabel>{input.label}</Form.ControlLabel>}
            {input.node}
          </Form.Group>
        ))}
      </div>
      {header.end !== undefined && (
        <div className="form-panel-end">
          <Whisper
            placement="left"
            trigger="click"
            speaker={({ onClose, left, top, className }, ref) => {
              const buttons = header.end?.buttons ?? [];
              return (
                <Popover ref={ref} className={className} style={{ left, top }} full>
                  <Dropdown.Menu
                    disabled={buttons.length === 0}
                    onSelect={() => {
                      onClose();
                    }}
                  >
                    {buttons.map((button, index) => (
                      <Dropdown.Item key={index} disabled={button.disabled} tabIndex={-1} eventKey={index} onClick={button.onClick}>
                        {button.name && <>{button.name}</>}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Popover>
              );
            }}
          >
            <IconButton appearance="link" icon={<FontAwesomeIcon size="lg" icon={faEllipsisV} />} />
          </Whisper>
        </div>
      )}
    </div>
    {body && (body.display === undefined || body.display === true) && (
      <div className="form-panel-body">
        {body.header && (
          <div className="form-panel-body-header">
            <div className="form-panel-body-header-left-nodes">
              {body.header.leftNodes.map((rightNode, index) => (
                <Fragment key={index}>{rightNode}</Fragment>
              ))}
            </div>
            <div className="form-panel-body-header-right-nodes">
              {body.header.rightNodes.map((rightNode, index) => (
                <Fragment key={index}>{rightNode}</Fragment>
              ))}
            </div>
          </div>
        )}
        <div className="form-panel-body-table-title">
          <span>{body.table.title}</span>
          {body.table.buttons && (
            <div className="table-title-buttons">
              {body.table.buttons.map((button, index) => (
                <Button key={index} onClick={button.onClick} size="xs" disabled={button.disabled} appearance={button.appearance}>
                  {button.icon && <FontAwesomeIcon icon={button.icon} />}
                  {button.text}
                </Button>
              ))}
            </div>
          )}
        </div>
        <Table fluid size="md">
          <TableContent>
            <TableHead>
              <TableRow>
                {body.table.headColumns.map((column, index) => (
                  <TableColumn key={index} name={column.name}>
                    {column.value.toUpperCase()}
                  </TableColumn>
                ))}
                <TableColumnActions />
              </TableRow>
            </TableHead>
            <TableBody>
              {body.table.bodyRows.map((row) => (
                <TableRow key={row.key} href={row.href}>
                  {row.columns.map((column) => (
                    <TableColumn key={column.name} name={column.name} href={row.href}>
                      {column.component}
                    </TableColumn>
                  ))}
                  <TableColumnActions appearance={row.appearance} actions={row.actions} />
                </TableRow>
              ))}
              {body.table.bodyRowGroups.map((group) => (
                <Fragment key={group.key}>
                  <TableRow highlight>
                    {group.columns.map((column) => (
                      <TableColumn key={column.name} name={column.name}>
                        {column.component}
                      </TableColumn>
                    ))}
                    <TableColumnActions actions={group.actions} />
                  </TableRow>
                  {group.rows.map((row) => (
                    <TableRow key={row.key} href={row.href}>
                      {row.columns.map((column) => (
                        <TableColumn key={column.name} name={column.name} href={row.href}>
                          {column.component}
                        </TableColumn>
                      ))}
                      <TableColumnActions actions={row.actions} />
                    </TableRow>
                  ))}
                </Fragment>
              ))}
            </TableBody>
          </TableContent>
        </Table>
      </div>
    )}
  </div>
);
