import "./index.scss";
import { FunctionComponent } from "react";
import { ViewForm } from "buildingBlocks";
import { useProjectReportContext, useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { useUpdateReportMutation } from "graphql/mutations";
import { PermissionName } from "graphql/schema";
import { ReportForm, ReportFormRoomElementValues, useReportForm } from "forms";

export const ProjectReportUpdateView: FunctionComponent = () => {
  const { navigate, toProjectReportsView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, activities } = useProjectContext();
  const { report } = useProjectReportContext();
  const { values, setValues } = useReportForm({
    name: report.name,
    activities: report.activities.map((reportActivity) => ({
      activityId: reportActivity.activityId,
      index: reportActivity.index,
    })),
    rooms: report.rooms.map((reportRoom) => ({
      id: reportRoom.id,
      index: reportRoom.index,
      name: reportRoom.name,
      isDeleted: false,
      elements: reportRoom.elements.map((reportRoomElement) => ({
        id: reportRoomElement.id,
        index: reportRoomElement.index,
        name: reportRoomElement.name,
        display: reportRoomElement.display,
        isDeleted: false,
      })),
    })),
  });
  const { updateReport, updateReportLoading, updateReportErrors } = useUpdateReportMutation(({ tenantId, projectId }) => {
    navigate(
      toProjectReportsView({
        tenantId: tenantId,
        projectId: projectId,
      })
    );
  });
  return (
    <ViewForm
      id="project-report-update-view"
      title="Configurer le rapport"
      security={{
        permissions: [[PermissionName.REPORT_READ, PermissionName.REPORT_UPDATE]],
      }}
      errors={updateReportErrors}
      loading={updateReportLoading}
      disabled={
        values.rooms.filter((room) => !room.isDeleted).some((room) => room.name.trim() === "") ||
        values.rooms
          .reduce<ReportFormRoomElementValues[]>((previousValue, currentValue) => previousValue.concat(currentValue.elements.filter((roomElement) => !roomElement.isDeleted)), [])
          .some((roomElement) => roomElement.name.trim() === "")
      }
      onSubmit={async () => {
        await updateReport({
          tenantId: tenant.id,
          projectId: project.id,
          reportId: report.id,
          activities: values.activities.map((activity) => ({
            activityId: activity.activityId,
            index: activity.index,
          })),
          rooms: values.rooms
            .filter((room) => !room.isDeleted)
            .map((room) => ({
              id: room.id,
              index: room.index,
              name: room.name,
              elements: room.elements
                .filter((roomElement) => !roomElement.isDeleted)
                .map((roomElement) => ({
                  id: roomElement.id,
                  index: roomElement.index,
                  name: roomElement.name,
                  display: roomElement.display,
                })),
            })),
        });
      }}
      onCancel={() => {
        navigate(
          toProjectReportsView({
            tenantId: tenant.id,
            projectId: project.id,
          })
        );
      }}
    >
      <ReportForm values={values} setValues={setValues} activities={activities} />
    </ViewForm>
  );
};
