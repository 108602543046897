import "./index.scss";
import { FunctionComponent, useState } from "react";
import { Form, Input } from "rsuite";
import { FormContainer } from "buildingBlocks";
import produce from "immer";

type ProviderFormValues = {
  readonly name: string;
  readonly websiteUrl: string;
  readonly description: string;
};

type Props = {
  readonly values: ProviderFormValues;
  readonly setValues: (values: ProviderFormValues) => void;
};

export const useProviderForm = (initialValues: ProviderFormValues) => {
  const [values, setValues] = useState<ProviderFormValues>(initialValues);
  return { values, setValues };
};

export const ProviderForm: FunctionComponent<Props> = ({ values, setValues }) => (
  <Form id="provider-form">
    <FormContainer highlight>
      <Form.Group>
        <Form.ControlLabel>Nom</Form.ControlLabel>
        <Input
          value={values.name}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.name = value;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Site web</Form.ControlLabel>
        <Input
          value={values.websiteUrl}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.websiteUrl = value;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Description</Form.ControlLabel>
        <Input
          as="textarea"
          rows={3}
          value={values.description}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.description = value;
              })
            );
          }}
        />
      </Form.Group>
    </FormContainer>
  </Form>
);
