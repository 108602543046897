import "./index.scss";
import { FunctionComponent, useState } from "react";
import { Form as RsForm, InputNumber, SelectPicker, Input } from "rsuite";
import { FormContainer } from "buildingBlocks";
import produce from "immer";
import { FormState } from "graphql/schema";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type UnitFormValues = {
  readonly number: string;
  readonly floor: number;
  readonly numberOfBathrooms: number;
  readonly numberOfBedrooms: number;
  readonly formId: string | null;
};

type Props = {
  readonly values: UnitFormValues;
  readonly setValues: (values: UnitFormValues) => void;
  readonly forms: ReadonlyArray<ProjectProviderTypes.MappedProjectForm>;
};

export const useUnitForm = (initialValues: UnitFormValues) => {
  const [values, setValues] = useState<UnitFormValues>(initialValues);
  return { values, setValues };
};

export const UnitForm: FunctionComponent<Props> = ({ values, setValues, forms }) => (
  <RsForm id="unit-form">
    <FormContainer highlight>
      <RsForm.Group>
        <RsForm.ControlLabel>Numéro</RsForm.ControlLabel>
        <Input
          value={values.number}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.number = value;
              })
            );
          }}
        />
      </RsForm.Group>
      <RsForm.Group>
        <RsForm.ControlLabel>Étage</RsForm.ControlLabel>
        <InputNumber
          min={1}
          value={values.floor}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.floor = Number(value);
              })
            );
          }}
        />
      </RsForm.Group>
      <RsForm.Group>
        <RsForm.ControlLabel>Nombre de salles de bain</RsForm.ControlLabel>
        <InputNumber
          min={1}
          value={values.numberOfBathrooms}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.numberOfBathrooms = Number(value);
              })
            );
          }}
        />
      </RsForm.Group>
      <RsForm.Group>
        <RsForm.ControlLabel>Nombre de chambres</RsForm.ControlLabel>
        <InputNumber
          min={1}
          value={values.numberOfBedrooms}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.numberOfBedrooms = Number(value);
              })
            );
          }}
        />
      </RsForm.Group>
    </FormContainer>
    <FormContainer>
      <RsForm.Group>
        <RsForm.ControlLabel>Formulaire</RsForm.ControlLabel>
        <SelectPicker
          value={values.formId}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.formId = value;
              })
            );
          }}
          cleanable
          data={forms
            .filter((form) => form.state === FormState.PUBLISHED)
            .map((form) => ({
              value: form.id,
              label: form.name,
            }))}
        />
      </RsForm.Group>
    </FormContainer>
  </RsForm>
);
