import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, FunctionComponent } from "react";
import { Button } from "rsuite";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Types } from "types";

type Props = {
  readonly leftInputs: Types.HeaderFilterInputs;
  readonly rightInputs: Types.HeaderFilterInputs;
  readonly applyButton: Types.HeaderFilterButton | null;
};

export const HeaderFilter: FunctionComponent<Props> = ({ leftInputs, rightInputs, applyButton }) => (
  <div className="header-filter">
    <div className="header-filter-left">
      <span className="header-filter-left-label">
        <FontAwesomeIcon icon={faFilter} />
        Filtres
      </span>
      <div className="header-filter-left-inputs">
        {leftInputs.map((input, index) => (
          <Fragment key={index}>{input}</Fragment>
        ))}
      </div>
      {applyButton && (
        <Button size="xs" appearance="primary" onClick={applyButton.onClick}>
          Appliquer
        </Button>
      )}
    </div>
    <div className="header-filter-right">
      <div className="header-filter-right-inputs">
        {rightInputs.map((input, inputIndex) => (
          <Fragment key={inputIndex}>{input}</Fragment>
        ))}
      </div>
    </div>
  </div>
);
