import "./index.scss";
import { FunctionComponent } from "react";
import { Panel, PanelControl, ViewDetail } from "buildingBlocks";
import { useRouting, useTenantSettingsRoleContext } from "hooks";
import { useTenantContext } from "providers";

export const TenantSettingsRoleView: FunctionComponent = () => {
  const { navigate, toTenantView, toTenantSettingsRolesView } = useRouting();
  const { tenant } = useTenantContext();
  const { role } = useTenantSettingsRoleContext();
  return (
    <ViewDetail
      id="tenant-settings-role-view"
      security={{
        isTenantSecurityManager: true,
      }}
      header={{
        title: role.name,
        subtitle: "Rôle",
        returnButton: {
          onClick: () => {
            navigate(
              toTenantSettingsRolesView({
                tenantId: tenant.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              appearance: "default",
              text: "Fermer les paramètres",
              show: true,
              security: {},
              onClick: () => {
                navigate(
                  toTenantView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
          ],
          otherActions: [],
        },
      }}
    >
      <Panel>
        <PanelControl label="Description" values={[role.description]} />
        <PanelControl label="Vérouillé" values={[role.isLocked ? "Oui" : "Non"]} />
        <PanelControl label="Gestionnaire du profil de l'organisation" values={[role.isTenantProfileManager ? "Oui" : "Non"]} />
        <PanelControl label="Gestionnaire de la sécurité de l'organisation" values={[role.isTenantSecurityManager ? "Oui" : "Non"]} />
        <PanelControl label="Gestionnaire de la comptabilité de l'organisation" values={[role.isTenantAccountingManager ? "Oui" : "Non"]} />
        <PanelControl label="Gestionnaire des projets de l'organisation" values={[role.isTenantProjectManager ? "Oui" : "Non"]} />
        <PanelControl label="Permissions" values={role.permissions.map((permission) => permission.displayName)} />
      </Panel>
    </ViewDetail>
  );
};
