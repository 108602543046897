import "./index.scss";
import { FunctionComponent, PropsWithChildren } from "react";

type Props = PropsWithChildren & {
  readonly className?: string;
  readonly title: string;
};

export const Card: FunctionComponent<Props> = ({ className, title, children }) => (
  <div className={`card${className ? ` ${className}` : ""}`}>
    <div className="card-header">{title}</div>
    <div className="card-body">{children}</div>
  </div>
);
