import "./index.scss";
import { FunctionComponent } from "react";
import { ModalForm } from "buildingBlocks";
import { SectionElementFormValues, SectionElementOptionFormValues } from "forms";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";
import { SectionElementOptionEditFormValues, useSectionElementOptionEditForm, SectionElementOptionEditForm } from "forms/SectionForm/_forms/SectionElementOptionEditForm";

type Props = {
  readonly sectionElement: SectionElementFormValues;
  readonly sectionElementOption: SectionElementOptionFormValues;
  readonly onEdit: (sectionElement: SectionElementFormValues, sectionElementOption: SectionElementOptionFormValues, values: SectionElementOptionEditFormValues) => void;
  readonly onCancel: (sectionElement: SectionElementFormValues, sectionElementOption: SectionElementOptionFormValues, values: SectionElementOptionEditFormValues) => void;
  readonly activities: ReadonlyArray<ProjectProviderTypes.MappedProjectActivity>;
  readonly products: ReadonlyArray<ProjectProviderTypes.MappedProjectProduct>;
};

export const SectionElementOptionEditModal: FunctionComponent<Props> = ({ sectionElement, sectionElementOption, onEdit, onCancel, activities, products }) => {
  const { values, setValues } = useSectionElementOptionEditForm({
    clientAmount: sectionElementOption.clientAmount,
    breakdownLines: sectionElementOption.breakdownLines.map((sectionElementOptionBreakdownLine) => ({
      activityId: sectionElementOptionBreakdownLine.activityId,
      subcontractorAmount: sectionElementOptionBreakdownLine.subcontractorAmount,
      contractorAmount: sectionElementOptionBreakdownLine.contractorAmount,
      promoterAmount: sectionElementOptionBreakdownLine.promoterAmount,
    })),
  });
  return (
    <ModalForm
      id="section-element-option-edit-modal"
      title="Éditer le montant du coût de l'option"
      size="lg"
      errors={[]}
      loading={false}
      disabled={false}
      submitButton={{
        text: "Éditer",
        onClick: () => {
          onEdit(sectionElement, sectionElementOption, values);
        },
      }}
      closeButton={{
        text: "Annuler",
        onClick: () => {
          onCancel(sectionElement, sectionElementOption, values);
        },
      }}
    >
      <SectionElementOptionEditForm sectionElementOption={sectionElementOption} values={values} setValues={setValues} activities={activities} products={products} />
    </ModalForm>
  );
};
