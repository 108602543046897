import "./index.scss";
import { Fragment, FunctionComponent } from "react";
import { Types } from "types";

type Props = {
  readonly cards: Types.HeaderOverviewCards;
};

export const HeaderOverview: FunctionComponent<Props> = ({ cards }) => (
  <div className="header-overview">
    {cards.map((card, cardIndex) => (
      <Fragment key={cardIndex}>{card}</Fragment>
    ))}
  </div>
);
