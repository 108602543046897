import { FunctionComponent } from "react";
import { useRouting } from "hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { Layout } from "buildingBlocks/Layout";
import { useTenantContext } from "providers";

export const SettingsLayout: FunctionComponent = () => {
  const {
    navigate,
    matchPath,
    toTenantSettingsProfileView,
    toTenantSettingsPermissionsView,
    toTenantSettingsTaxAgenciesView,
    toTenantSettingsTaxesView,
    toTenantSettingsTaxGroupsView,
    toTenantSettingsUsersView,
    toTenantSettingsUserView,
    toTenantSettingsRolesView,
    toTenantView,
  } = useRouting();
  const { logout } = useAuth0();
  const { tenant } = useTenantContext();
  return (
    <Layout
      onHeaderClick={() => {
        navigate(
          toTenantView({
            tenantId: tenant.id,
          })
        );
      }}
      tenant={tenant}
      navs={[
        {
          title: "Organisation",
          items: [
            {
              __type: "NavItem",
              name: "Profil",
              disabled: false,
              show: true,
              active: matchPath(
                toTenantSettingsProfileView({
                  tenantId: null,
                })
              ),
              security: {
                isTenantProfileManager: true,
              },
              onClick: () => {
                navigate(
                  toTenantSettingsProfileView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
          ],
        },
        {
          title: "Sécurité",
          items: [
            {
              __type: "NavItem",
              name: "Utilisateurs",
              disabled: false,
              show: true,
              active:
                matchPath(
                  toTenantSettingsUsersView({
                    tenantId: null,
                  })
                ) ||
                matchPath(
                  toTenantSettingsUserView({
                    tenantId: null,
                    userId: null,
                  })
                ),
              security: {
                isTenantSecurityManager: true,
              },
              onClick: () => {
                navigate(
                  toTenantSettingsUsersView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
            {
              __type: "NavItem",
              name: "Rôles",
              disabled: false,
              show: true,
              active: matchPath(
                toTenantSettingsRolesView({
                  tenantId: null,
                })
              ),
              security: {
                isTenantSecurityManager: true,
              },
              onClick: () => {
                navigate(
                  toTenantSettingsRolesView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
            {
              __type: "NavItem",
              name: "Permissions",
              disabled: false,
              show: true,
              active: matchPath(
                toTenantSettingsPermissionsView({
                  tenantId: null,
                })
              ),
              security: {
                isTenantSecurityManager: true,
              },
              onClick: () => {
                navigate(
                  toTenantSettingsPermissionsView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
          ],
        },
        {
          title: "Taxes & conformité",
          items: [
            {
              __type: "NavItem",
              name: "Taxes",
              disabled: false,
              show: true,
              active: matchPath(
                toTenantSettingsTaxesView({
                  tenantId: null,
                })
              ),
              security: {
                isTenantAccountingManager: true,
              },
              onClick: () => {
                navigate(
                  toTenantSettingsTaxesView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
            {
              __type: "NavItem",
              name: "Groupes de taxes",
              disabled: false,
              show: true,
              active: matchPath(
                toTenantSettingsTaxGroupsView({
                  tenantId: null,
                })
              ),
              security: {
                isTenantAccountingManager: true,
              },
              onClick: () => {
                navigate(
                  toTenantSettingsTaxGroupsView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
            {
              __type: "NavItem",
              name: "Organismes fiscaux",
              disabled: false,
              show: true,
              active: matchPath(
                toTenantSettingsTaxAgenciesView({
                  tenantId: null,
                })
              ),
              security: {
                isTenantAccountingManager: true,
              },
              onClick: () => {
                navigate(
                  toTenantSettingsTaxAgenciesView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
          ],
        },
      ]}
      onLogout={() => {
        logout();
      }}
    />
  );
};
