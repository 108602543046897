import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { SearchInputGroup, ViewList, DateTime, Text } from "buildingBlocks";
import { useRouting } from "hooks";
import { useTenantContext } from "providers";
import { ProjectDeleteDialog } from "dialogs";
import { ProjectFragment } from "graphql/schema";

const useProjectsViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback((projects: ReadonlyArray<ProjectFragment>) => projects.filter((project) => project.label.toSearchFormat().includes(search.toSearchFormat())), [search]),
  };
};

export const ProjectsView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useProjectsViewFilters();
  const { navigate, toProjectView, toProjectCreateView, toProjectUpdateView } = useRouting();
  const { tenant, projects } = useTenantContext();
  const [projectToDelete, setProjectToDelete] = useState<ProjectFragment | null>(null);
  return (
    <ViewList
      id="projects-view"
      security={{}}
      header={{
        title: "Tous les projets",
        toolbar: {
          buttons: [
            {
              __type: "Button",
              text: "Créer un projet",
              show: projects.length !== 0,
              security: {
                isTenantProjectManager: true,
              },
              onClick: () => {
                navigate(
                  toProjectCreateView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [<SearchInputGroup size="xs" placeholder="Recherche par étiquette" value={filterValues.search} onChange={filterFunctions.setSearch} />],
            applyButton: null,
          },
          right: {
            inputs: [],
          },
        },
      }}
      content={{
        table: {
          emptyBodies: [
            {
              show: projects.length === 0,
              title: "Aucun projet",
              content: "Pour créer un projet, cliquez sur le bouton ci-dessous.",
              button: {
                text: "Créer un projet",
                security: {
                  isTenantProjectManager: true,
                },
                onClick: () => {
                  navigate(
                    toProjectCreateView({
                      tenantId: tenant.id,
                    })
                  );
                },
              },
            },
          ],
          headColumns: [
            {
              name: "date",
              value: "DATE",
            },
            {
              name: "label",
              value: "ÉTIQUETTE",
            },
            {
              name: "description",
              value: "DESCRIPTION",
            },
          ],
          bodyRows: applyFilters(projects).map((project) => {
            return {
              key: project.id,
              href: toProjectView({
                tenantId: tenant.id,
                projectId: project.id,
              }),
              columns: [
                {
                  name: "date",
                  component: <DateTime value={project.createdAt} />,
                },
                {
                  name: "label",
                  component: <Text highlight>{project.label}</Text>,
                },
                {
                  name: "description",
                  component: project.description,
                },
              ],
              actions: [
                {
                  __type: "Button",
                  content: "Modifier le projet",
                  show: true,
                  security: {
                    isTenantProjectManager: true,
                  },
                  onClick: () => {
                    navigate(
                      toProjectUpdateView({
                        tenantId: tenant.id,
                        projectId: project.id,
                      })
                    );
                  },
                },
                {
                  __type: "Button",
                  content: "Supprimer le projet",
                  show: true,
                  security: {
                    isTenantProjectManager: true,
                  },
                  onClick: () => {
                    setProjectToDelete(project);
                  },
                },
              ],
            };
          }),
          bodyRowGroups: [],
        },
      }}
    >
      {projectToDelete && (
        <ProjectDeleteDialog
          tenantId={tenant.id}
          project={projectToDelete}
          onCompleted={() => {
            setProjectToDelete(null);
          }}
          onClose={() => {
            setProjectToDelete(null);
          }}
        />
      )}
    </ViewList>
  );
};
