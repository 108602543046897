import "./index.scss";
import { useCreateContractInvoiceRefundMutation } from "graphql/mutations";
import { FunctionComponent } from "react";
import { ModalForm } from "buildingBlocks";
import { RefundMode } from "graphql/schema";
import { ContractInvoiceRefundForm, useContractInvoiceRefundForm } from "forms";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly contract: ProjectProviderTypes.MappedProjectContract;
  readonly invoice: ProjectProviderTypes.MappedProjectInvoice;
  readonly refunds: ReadonlyArray<ProjectProviderTypes.MappedProjectRefund>;
  readonly onCompleted: () => void;
  readonly onCancel: () => void;
};

export const ContractInvoiceRefundCreateModal: FunctionComponent<Props> = ({ tenantId, projectId, contract, invoice, refunds, onCompleted, onCancel }) => {
  const { values, setValues } = useContractInvoiceRefundForm({
    label: `RFD-${("000000" + (refunds.length === 0 ? 1 : refunds.map((refund) => refund.number).max()! + 1)).slice(-6)}`,
    date: new Date(),
    referenceNumber: "",
    mode: RefundMode.CHECK,
    amount: 0,
    notes: "",
  });
  const { createContractInvoiceRefund, createContractInvoiceRefundLoading, createContractInvoiceRefundErrors } = useCreateContractInvoiceRefundMutation(onCompleted, undefined, false);
  return (
    <ModalForm
      id="contract-invoice-refund-create-modal"
      title="Créer un remboursement"
      errors={createContractInvoiceRefundErrors}
      loading={createContractInvoiceRefundLoading}
      disabled={values.amount <= 0}
      submitButton={{
        onClick: async () => {
          await createContractInvoiceRefund({
            tenantId: tenantId,
            projectId: projectId,
            contractId: contract.id,
            invoiceId: invoice.id,
            date: values.date,
            mode: values.mode,
            referenceNumber: values.referenceNumber.trim() || null,
            amount: values.amount,
            notes: values.notes.trim() || null,
          });
        },
      }}
      closeButton={{
        text: "Annuler",
        onClick: onCancel,
      }}
    >
      <ContractInvoiceRefundForm values={values} setValues={setValues} invoice={invoice} />
    </ModalForm>
  );
};
