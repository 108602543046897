import "./index.scss";
import { usePublishFormMutation } from "graphql/mutations";
import { FunctionComponent } from "react";
import { Dialog, DialogBody, DialogFooter } from "buildingBlocks";
import { Button } from "rsuite";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly form: ProjectProviderTypes.MappedProjectForm;
  readonly onCompleted: () => void;
  readonly onClose: () => void;
};

export const FormPublishDialog: FunctionComponent<Props> = ({ tenantId, projectId, form, onCompleted, onClose }) => {
  const { publishForm, publishFormLoading } = usePublishFormMutation(onCompleted, onClose, true);
  return (
    <Dialog id="form-publish-dialog" title="Archiver le formulaire">
      <DialogBody>Le formulaire sera disponible pour être associée à des contrats.</DialogBody>
      <DialogFooter>
        <Button
          appearance="primary"
          size="sm"
          loading={publishFormLoading}
          onClick={async () => {
            await publishForm({
              tenantId: tenantId,
              projectId: projectId,
              formId: form.id,
            });
          }}
        >
          Publié
        </Button>
        <Button appearance="default" size="sm" disabled={publishFormLoading} onClick={onClose}>
          Fermer
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
