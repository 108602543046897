import { createContext, FunctionComponent, useCallback, useContext } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Types } from "types";
import { useUserInfoContext } from "providers";

type IsAuthorized = (props: { readonly security: Types.Security | null }) => boolean;

type HasProject = (props: { readonly tenantId: string; readonly projectId: string }) => boolean;

type HasTenant = (props: { readonly tenantId: string }) => boolean;

type SecurityContextValue = {
  readonly isAuthorized: IsAuthorized;
  readonly hasTenant: HasTenant;
  readonly hasProject: HasProject;
};

const initialValue: SecurityContextValue = {
  isAuthorized: () => false,
  hasTenant: () => false,
  hasProject: () => false,
};

const SecurityContext = createContext<SecurityContextValue>(initialValue);

const useSecurityContext = () => useContext(SecurityContext);

type Params = {
  readonly tenantId: string;
  readonly projectId: string;
};

const SecurityProvider: FunctionComponent = () => {
  const { tenantId, projectId } = useParams<Params>();
  const { userInfo } = useUserInfoContext();
  const hasTenant: HasTenant = useCallback(
    ({ tenantId }) => {
      if (userInfo.isSuperAdmin) {
        return true;
      }
      const userTenant = userInfo.tenants.find((tenant) => tenant.id === tenantId || tenant.testId === tenantId);
      if (userTenant === undefined) {
        return false;
      }
      if (userTenant.testId === tenantId && !userTenant.hasAccessToTestTenant) {
        return false;
      }
      return true;
    },
    [userInfo]
  );
  const hasProject: HasProject = useCallback(
    ({ tenantId, projectId }) => {
      if (userInfo.isSuperAdmin) {
        return true;
      }
      const userTenant = userInfo.tenants.find((tenant) => tenant.id === tenantId || tenant.testId === tenantId);
      if (userTenant === undefined) {
        return false;
      }
      if (userTenant.testId === tenantId && userTenant.hasAccessToTestTenant) {
        return true;
      }
      if (!userTenant.hasAccessToAllProjects && !userTenant.projectIds.includes(projectId)) {
        return false;
      }
      return true;
    },
    [userInfo]
  );
  const isAuthorized: IsAuthorized = useCallback(
    ({ security }) => {
      if (userInfo.isSuperAdmin) {
        return true;
      }
      const userTenant = userInfo.tenants.find((tenant) => tenant.id === tenantId || tenant.testId === tenantId);
      if (userTenant === undefined) {
        return false;
      }
      if (userTenant.testId === tenantId) {
        if (!userTenant.hasAccessToTestTenant) {
          return false;
        }
      } else {
        if (projectId) {
          if (!userTenant.hasAccessToAllProjects && !userTenant.projectIds.includes(projectId)) {
            return false;
          }
        }
        if (security) {
          if (security.isTenantSettingsManager && !userTenant.isProfileManager && !userTenant.isProjectManager && !userTenant.isAccountingManager && !userTenant.isSecurityManager) {
            return false;
          }
          if (security.isTenantProfileManager && !userTenant.isProfileManager) {
            return false;
          }
          if (security.isTenantAccountingManager && !userTenant.isAccountingManager) {
            return false;
          }
          if (security.isTenantProjectManager && !userTenant.isProjectManager) {
            return false;
          }
          if (security.isTenantSecurityManager && !userTenant.isSecurityManager) {
            return false;
          }
          if (security.permissions) {
            if (!(security.permissions.length === 0 || security.permissions.some((permissions) => permissions.every((permission) => userTenant.permissions.includes(permission))))) {
              return false;
            }
          }
        }
      }
      return true;
    },
    [tenantId, projectId, userInfo]
  );
  return (
    <SecurityContext.Provider
      value={{
        isAuthorized: isAuthorized,
        hasTenant: hasTenant,
        hasProject: hasProject,
      }}
    >
      <Outlet />
    </SecurityContext.Provider>
  );
};

export { SecurityProvider, useSecurityContext };
