import "./index.scss";
import { useCreateContractSelectionMutation } from "graphql/mutations";
import { FunctionComponent } from "react";
import { ModalForm } from "buildingBlocks";
import { ContractSelectionCreateForm, useContractSelectionCreateForm } from "forms";
import { ContractSelectionWorkflow, InvoiceTermsOfPayment } from "graphql/schema";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";
import { MappedTaxGroup } from "providers";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly contract: ProjectProviderTypes.MappedProjectContract;
  readonly taxGroups: ReadonlyArray<MappedTaxGroup>;
  readonly onCompleted: () => void;
  readonly onCancel: () => void;
};

export const ContractSelectionCreateModal: FunctionComponent<Props> = ({ tenantId, projectId, contract, taxGroups, onCompleted, onCancel }) => {
  const { values, setValues } = useContractSelectionCreateForm({
    label: "Première rencontre",
    taxGroupId: taxGroups[0].id,
    terms: InvoiceTermsOfPayment.NET7,
    creditAmount: 0,
    mixAndMatchAmount: 250,
    workflow: ContractSelectionWorkflow.WITH_CUSTOMER,
  });
  const { createContractSelection, createContractSelectionErrors, createContractSelectionLoading } = useCreateContractSelectionMutation(onCompleted, undefined, false);
  return (
    <ModalForm
      id="contract-selection-create-modal"
      title="Créer une sélection"
      errors={createContractSelectionErrors}
      loading={createContractSelectionLoading}
      disabled={false}
      submitButton={{
        onClick: async () => {
          await createContractSelection({
            tenantId: tenantId,
            projectId: projectId,
            contractId: contract.id,
            taxGroupId: values.taxGroupId,
            terms: values.terms,
            creditAmount: values.creditAmount,
            mixAndMatchAmount: values.mixAndMatchAmount,
            workflow: values.workflow,
          });
        },
      }}
      closeButton={{
        text: "Annuler",
        onClick: onCancel,
      }}
    >
      <ContractSelectionCreateForm values={values} setValues={setValues} taxGroups={taxGroups} />
    </ModalForm>
  );
};
