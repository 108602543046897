import "./index.scss";
import { FunctionComponent } from "react";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly title?: string;
  readonly withBillingSettings?: boolean;
  readonly withSummary?: boolean;
  readonly contractSelection: ProjectProviderTypes.MappedProjectContractSelection;
};

export const ContractSelectionTotal: FunctionComponent<Props> = ({ title = undefined, contractSelection, withBillingSettings = false, withSummary = false }) => (
  <div className="contract-selection-total">
    {title && <div className="contract-selection-total-title">{title}</div>}
    <div className="start">
      {withBillingSettings && contractSelection.revisionNumber !== 0 && (
        <div className="contract-selection-total-item">
          <div className="contract-selection-total-item-label">Frais de révision</div>
          <div className="contract-selection-total-item-amount">{contractSelection.billingSettings.revisionAmount.toCurrencyFormat()}</div>
        </div>
      )}
      {withSummary && (
        <>
          {contractSelection.groups.map((contractSelectionGroup) => (
            <div key={contractSelectionGroup.id} className="contract-selection-total-item">
              <div className="contract-selection-total-item-label">{contractSelectionGroup.formSection.title}</div>
              <div className="contract-selection-total-item-amount">{contractSelectionGroup.clientAmount.toCurrencyFormat()}</div>
            </div>
          ))}
        </>
      )}
    </div>
    <div className="end">
      <div className="contract-selection-total-label">
        <span>TOTAL</span>
        <small>(Taxe non comprise)</small>
      </div>
      <div className="contract-selection-total-amount">{contractSelection.groups.reduce((previousValue, currentValue) => previousValue + currentValue.clientAmount, 0).toCurrencyFormat()}</div>
    </div>
  </div>
);
