import "./index.scss";
import { FunctionComponent } from "react";
import { Checkbox, Input, InputGroup, InputNumber, SelectPicker } from "rsuite";
import { FormPanel, FormPanelInput, getEnumLabel, InputIndex } from "buildingBlocks";
import { faAdd, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SectionElementType, SectionElementOptionType } from "graphql/schema";
import produce from "immer";
import { SectionElementFormValues, SectionElementOptionFormValues, SectionFormValues } from "forms";
import { Guid } from "utils";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly sectionElement: SectionElementFormValues;
  readonly hasSectionElementGroup?: boolean;
  readonly isOptionalDisabled?: boolean;
  readonly isHideProductCategoryDisabled?: boolean;
  readonly isAddOptionButtonDisabled?: boolean;
  readonly isManagedByVibesButtonDisabled?: boolean;
  readonly values: SectionFormValues;
  readonly setValues: (values: SectionFormValues) => void;
  readonly products: ReadonlyArray<ProjectProviderTypes.MappedProjectProduct>;
  readonly isDeleteOptionDisabled: (sectionElement: SectionElementFormValues, sectionElementOption: SectionElementOptionFormValues) => boolean;
  readonly sectionElementOptionTypes: ReadonlyArray<SectionElementOptionType>;
  readonly onSectionElementOptionTypeChange?: (type: SectionElementOptionType, sectionElementOption: SectionElementOptionFormValues) => void;
  readonly onSectionElementOptionCostAmountUpdated: (sectionElement: SectionElementFormValues, sectionElementOption: SectionElementOptionFormValues) => void;
};

export const SectionElementFormPanel: FunctionComponent<Props> = ({
  sectionElement,
  values,
  setValues,
  products,
  hasSectionElementGroup = false,
  sectionElementOptionTypes,
  onSectionElementOptionTypeChange = undefined,
  onSectionElementOptionCostAmountUpdated,
  isOptionalDisabled = false,
  isHideProductCategoryDisabled = false,
  isManagedByVibesButtonDisabled = false,
  isAddOptionButtonDisabled = false,
  isDeleteOptionDisabled,
}) => {
  const inputs: FormPanelInput[] = [];
  if (hasSectionElementGroup) {
    inputs.push({
      name: "group",
      label: "Groupe",
      node: (
        <SelectPicker
          size="sm"
          value={sectionElement.groupId}
          searchable={false}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                const draftElement = draft.elements.find((draftElement) => draftElement.id === sectionElement.id);
                if (draftElement) {
                  draftElement.groupId = value!;
                }
              })
            );
          }}
          cleanable
          data={values.elementGroups
            .filter((elementGroup) => elementGroup.name.trim() !== "")
            .map((elementGroup) => ({
              label: elementGroup.name,
              value: elementGroup.id,
            }))}
        />
      ),
    });
  }
  inputs.push({
    name: "label",
    label: "Étiquette",
    node: (
      <Input
        size="sm"
        value={sectionElement.label}
        onChange={(value) => {
          setValues(
            produce(values, (draft) => {
              const draftElement = draft.elements.find((draftElement) => draftElement.id === sectionElement.id);
              if (draftElement) {
                draftElement.label = value;
              }
            })
          );
        }}
      />
    ),
  });
  inputs.push({
    name: "type",
    label: "Type",
    node: <Input size="sm" value={getEnumLabel(sectionElement.type)} />,
  });
  return (
    <FormPanel
      className="section-element-form-panel"
      key={sectionElement.id}
      header={{
        start: {
          index: sectionElement.index,
          buttons: {
            increase: {
              disabled: sectionElement.index === 0,
              onClick: () => {
                setValues(
                  produce(values, (draft) => {
                    const draftElementToIncrease = draft.elements.find((draftElement) => draftElement.index === sectionElement.index);
                    const draftElementToDecrease = draft.elements.find((draftElement) => draftElement.index === sectionElement.index - 1);
                    if (draftElementToIncrease && draftElementToDecrease) {
                      draftElementToIncrease.index = draftElementToIncrease.index - 1;
                      draftElementToDecrease.index = draftElementToDecrease.index + 1;
                    }
                  })
                );
              },
            },
            decrease: {
              disabled: sectionElement.index === values.elements.length - 1,
              onClick: () => {
                setValues(
                  produce(values, (draft) => {
                    const draftElementToDecrease = draft.elements.find((draftSection) => draftSection.index === sectionElement.index);
                    const draftElementToIncrease = draft.elements.find((draftSection) => draftSection.index === sectionElement.index + 1);
                    if (draftElementToIncrease && draftElementToDecrease) {
                      draftElementToIncrease.index = draftElementToIncrease.index - 1;
                      draftElementToDecrease.index = draftElementToDecrease.index + 1;
                    }
                  })
                );
              },
            },
          },
        },
        center: {
          inputs: inputs,
        },
        end: {
          buttons: [
            {
              name: "Supprimer l'élément",
              disabled: sectionElement.modelLink !== null,
              onClick: () => {
                setValues(
                  produce(values, (draft) => {
                    draft.elements = draft.elements
                      .filter((draftElement) => draftElement.id !== sectionElement.id)
                      .map((draftElement, draftElementIndex) => ({
                        ...draftElement,
                        index: draftElementIndex,
                      }));
                  })
                );
              },
            },
          ],
        },
      }}
      body={{
        display: true,
        header: {
          leftNodes: [
            <div className="form-panel-body-header-has-model-link">
              <span className="form-panel-body-header-has-model-link-label">Lié au modèle:</span>
              <span className="form-panel-body-header-has-model-link-value">{sectionElement.modelLink !== null ? "Oui" : "Non"}</span>
            </div>,
          ],
          rightNodes: [
            <Checkbox
              checked={sectionElement.isManagedByVibes}
              disabled={isManagedByVibesButtonDisabled}
              onChange={(_, checked) => {
                setValues(
                  produce(values, (draft) => {
                    const draftElement = draft.elements.find((draftElement) => draftElement.id === sectionElement.id);
                    if (draftElement) {
                      draftElement.isManagedByVibes = checked;
                      draftElement.isOptional = false;
                      for (const draftElementOption of draftElement.options) {
                        if (draftElementOption.type === SectionElementOptionType.INCLUDED) {
                          draftElementOption.type = SectionElementOptionType.MIX_AND_MATCH;
                        } else if (draftElementOption.type === SectionElementOptionType.MIX_AND_MATCH) {
                          draftElementOption.type = SectionElementOptionType.INCLUDED;
                        }
                      }
                    }
                  })
                );
              }}
            >
              Géré par des ambiances
            </Checkbox>,
            <Checkbox
              checked={sectionElement.isOptional}
              disabled={isOptionalDisabled}
              onChange={(_, checked) => {
                setValues(
                  produce(values, (draft) => {
                    const draftElement = draft.elements.find((draftElement) => draftElement.id === sectionElement.id);
                    if (draftElement) {
                      draftElement.isOptional = checked;
                    }
                  })
                );
              }}
            >
              Optionnel
            </Checkbox>,
            <Checkbox
              checked={sectionElement.hideProductCategory}
              disabled={isHideProductCategoryDisabled}
              onChange={(_, checked) => {
                setValues(
                  produce(values, (draft) => {
                    const draftElement = draft.elements.find((draftElement) => draftElement.id === sectionElement.id);
                    if (draftElement) {
                      draftElement.hideProductCategory = checked;
                    }
                  })
                );
              }}
            >
              Cacher la catégorie
            </Checkbox>,
          ],
        },
        table: {
          title: "Options",
          buttons: [
            {
              appearance: "link",
              text: "Ajouter une option",
              icon: faAdd,
              disabled: isAddOptionButtonDisabled,
              onClick: () => {
                setValues(
                  produce(values, (draft) => {
                    const draftElement = draft.elements.find((draftElement) => draftElement.id === sectionElement.id);
                    if (draftElement) {
                      draftElement.options.push({
                        id: Guid.newGuid(),
                        index: draftElement.options.length,
                        type: SectionElementOptionType.INCLUDED,
                        clientAmount: 0,
                        productId: "",
                        modelLink: null,
                        breakdownLines: [],
                        isDeleted: false,
                      });
                    }
                  })
                );
              },
            },
          ],
          headColumns: [
            {
              name: "index",
              value: "INDEX",
            },
            {
              name: "product",
              value: "PRODUIT",
            },
            {
              name: "type",
              value: "TYPE",
            },
            {
              name: "client-amount",
              value: "MONTANT CLIENT",
            },
            {
              name: "cost-amount",
              value: "COÛT",
            },
            {
              name: "contingency-amount",
              value: "CONTINGENCE",
            },
          ],
          bodyRows: [...sectionElement.options]
            .sort((left, right) => left.index - right.index)
            .map((sectionElementOption) => {
              const costAmount = sectionElementOption.breakdownLines.reduce(
                (previousValue, currentValue) => previousValue + currentValue.subcontractorAmount + currentValue.contractorAmount + currentValue.promoterAmount,
                0
              );
              return {
                key: sectionElementOption.id,
                appearance: "default",
                columns: [
                  {
                    name: "index",
                    component: (
                      <InputIndex
                        index={sectionElementOption.index}
                        buttons={{
                          increase: {
                            disabled: sectionElementOption.index === 0,
                            onClick: () => {
                              setValues(
                                produce(values, (draft) => {
                                  const draftElement = draft.elements.find((draftElement) => draftElement.id === sectionElement.id);
                                  if (draftElement) {
                                    const draftElementOptionToIncrease = draftElement.options.find((draftElementOption) => draftElementOption.index === sectionElementOption.index);
                                    const draftElementOptionToDecrease = draftElement.options.find((draftElementOption) => draftElementOption.index === sectionElementOption.index - 1);
                                    if (draftElementOptionToIncrease && draftElementOptionToDecrease) {
                                      draftElementOptionToIncrease.index = draftElementOptionToIncrease.index - 1;
                                      draftElementOptionToDecrease.index = draftElementOptionToDecrease.index + 1;
                                    }
                                  }
                                })
                              );
                            },
                          },
                          decrease: {
                            disabled: sectionElementOption.index === sectionElement.options.length - 1,
                            onClick: () => {
                              setValues(
                                produce(values, (draft) => {
                                  const draftElement = draft.elements.find((draftElement) => draftElement.id === sectionElement.id);
                                  if (draftElement) {
                                    const draftElementOptionToDecrease = draftElement.options.find((draftSectionOption) => draftSectionOption.index === sectionElementOption.index);
                                    const draftElementOptionToIncrease = draftElement.options.find((draftSectionOption) => draftSectionOption.index === sectionElementOption.index + 1);
                                    if (draftElementOptionToIncrease && draftElementOptionToDecrease) {
                                      draftElementOptionToIncrease.index = draftElementOptionToIncrease.index - 1;
                                      draftElementOptionToDecrease.index = draftElementOptionToDecrease.index + 1;
                                    }
                                  }
                                })
                              );
                            },
                          },
                        }}
                      />
                    ),
                  },
                  {
                    name: "product",
                    component: (
                      <SelectPicker
                        size="sm"
                        cleanable={false}
                        value={sectionElementOption.productId}
                        readOnly={sectionElementOption.productId !== ""}
                        disabledItemValues={values.elements.find((element) => element.id === sectionElement.id)!.options.map((option) => option.productId)}
                        onChange={(value) => {
                          setValues(
                            produce(values, (draft) => {
                              const draftElement = draft.elements.find((draftElement) => draftElement.id === sectionElement.id);
                              if (draftElement) {
                                const draftElementOption = draftElement.options.find((draftElementOption) => draftElementOption.id === sectionElementOption.id);
                                if (draftElementOption) {
                                  const product = products.find((product) => product.id === value!)!;
                                  if (draftElement.label === "" && (draftElement.type === SectionElementType.BOOLEAN || draftElement.type === SectionElementType.READONLY)) {
                                    draftElement.label = product.name;
                                  }
                                  if (products.map((product) => product.categoryId).unique().length > 1) {
                                    draftElement.hideProductCategory = false;
                                  }
                                  if (draftElement.isManagedByVibes) {
                                    draftElementOption.type = SectionElementOptionType.MIX_AND_MATCH;
                                  } else {
                                    draftElementOption.type = SectionElementOptionType.INCLUDED;
                                  }
                                  draftElementOption.productId = value!;
                                  draftElementOption.clientAmount = 0;
                                  draftElementOption.breakdownLines = [...product.activities]
                                    .sort((left, right) => left.index - right.index)
                                    .map(({ activityId }) => ({
                                      id: Guid.newGuid(),
                                      activityId: activityId,
                                      subcontractorAmount: 0,
                                      contractorAmount: 0,
                                      promoterAmount: 0,
                                    }));
                                }
                              }
                            })
                          );
                        }}
                        data={products.map((product) => ({
                          value: product.id,
                          label: product.name,
                        }))}
                      />
                    ),
                  },
                  {
                    name: "type",
                    component: (
                      <SelectPicker
                        size="sm"
                        cleanable={false}
                        value={sectionElementOption.type}
                        disabled={sectionElementOption.productId === ""}
                        readOnly={onSectionElementOptionTypeChange === undefined || sectionElementOptionTypes.length <= 1}
                        onChange={
                          onSectionElementOptionTypeChange
                            ? (value) => {
                                onSectionElementOptionTypeChange(value!, sectionElementOption);
                              }
                            : undefined
                        }
                        data={sectionElementOptionTypes.map((sectionElementOptionType) => ({
                          label: getEnumLabel(sectionElementOptionType),
                          value: sectionElementOptionType,
                        }))}
                      />
                    ),
                  },
                  {
                    name: "client-amount",
                    component: (
                      <InputNumber
                        size="sm"
                        disabled={sectionElementOption.type === SectionElementOptionType.INCLUDED || sectionElementOption.type === SectionElementOptionType.CREDIT_SUBCONTRACTOR}
                        min={0}
                        value={sectionElementOption.clientAmount}
                        onChange={(value) => {
                          // WARNING: This is very slow...
                          setValues(
                            produce(values, (draft) => {
                              const draftElement = draft.elements.find((draftElement) => draftElement.id === sectionElement.id);
                              if (draftElement) {
                                const draftElementOption = draftElement.options.find((draftElementOption) => draftElementOption.id === sectionElementOption.id);
                                if (draftElementOption) {
                                  draftElementOption.clientAmount = Number(value);
                                }
                              }
                            })
                          );
                        }}
                      />
                    ),
                  },
                  {
                    name: "cost-amount",
                    component: (
                      <InputGroup size="sm">
                        <Input
                          disabled={sectionElementOption.type !== SectionElementOptionType.EXTRA && sectionElementOption.type !== SectionElementOptionType.CREDIT_SUBCONTRACTOR}
                          value={costAmount.toCurrencyFormat()}
                          readOnly
                        />
                        <InputGroup.Button
                          appearance="default"
                          disabled={sectionElementOption.type !== SectionElementOptionType.EXTRA && sectionElementOption.type !== SectionElementOptionType.CREDIT_SUBCONTRACTOR}
                          onClick={() => {
                            onSectionElementOptionCostAmountUpdated(sectionElement, sectionElementOption);
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </InputGroup.Button>
                      </InputGroup>
                    ),
                  },
                  {
                    name: "contingency-amount",
                    component: (
                      <Input
                        size="sm"
                        disabled={sectionElementOption.type === SectionElementOptionType.INCLUDED}
                        value={(sectionElementOption.clientAmount - costAmount).toCurrencyFormat()}
                        readOnly
                      />
                    ),
                  },
                ],
                actions: [
                  {
                    __type: "Button",
                    content: "Supprimer l'option",
                    show: true,
                    disabled: isDeleteOptionDisabled(sectionElement, sectionElementOption),
                    security: {},
                    onClick: () => {
                      setValues(
                        produce(values, (draft) => {
                          const draftElement = draft.elements.find((draftElement) => draftElement.id === sectionElement.id);
                          if (draftElement) {
                            draftElement.options = draftElement.options
                              .filter((draftElementOption) => draftElementOption.id !== sectionElementOption.id)
                              .map((draftElementOption, draftElementOptionIndex) => ({
                                ...draftElementOption,
                                index: draftElementOptionIndex,
                              }));
                          }
                        })
                      );
                    },
                  },
                ],
              };
            }),
          bodyRowGroups: [],
        },
      }}
    />
  );
};
