import "rsuite/dist/rsuite.min.css";
import "styles/index.scss";
import "config";
import "utils";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "reportWebVitals";
import { Auth0Provider, ApolloProvider, LocaleProvider, NotificationProvider, AppProvider } from "providers";
import { App } from "./App";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <LocaleProvider>
      <NotificationProvider>
        <BrowserRouter
          future={{
            v7_startTransition: true, // https://reactrouter.com/en/6.29.0/upgrading/future#v7_starttransition
            v7_relativeSplatPath: true, // https://reactrouter.com/en/6.29.0/upgrading/future#v7_relativesplatpath
          }}
        >
          <Auth0Provider>
            <ApolloProvider>
              <AppProvider>
                <App />
              </AppProvider>
            </ApolloProvider>
          </Auth0Provider>
        </BrowserRouter>
      </NotificationProvider>
    </LocaleProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
