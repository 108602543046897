import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { DateTime, EnumLabel, SearchInputGroup, ViewTab, ViewTabList, Text } from "buildingBlocks";
import { InvoiceStatus, PermissionName } from "graphql/schema";
import { useRouting, useProjectContractContext } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { ContractInvoiceRefundUpdateModal, ContractRefundUpdateModal } from "modals";
import { ContractInvoiceRefundDeleteDialog, ContractRefundDeleteDialog } from "dialogs";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

const useProjectContractRefundsViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback(
      (contractRefunds: ReadonlyArray<ProjectProviderTypes.MappedProjectRefund>) => contractRefunds.filter((contractRefund) => contractRefund.label.toSearchFormat().includes(search.toSearchFormat())),
      [search]
    ),
  };
};

export const ProjectContractRefundsView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useProjectContractRefundsViewFilters();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { contract } = useProjectContractContext();
  const [refundToUpdate, setRefundToUpdate] = useState<ProjectProviderTypes.MappedProjectRefund | null>(null);
  const [refundToDelete, setRefundToDelete] = useState<ProjectProviderTypes.MappedProjectRefund | null>(null);
  const [invoiceRefundToUpdate, setInvoiceRefundToUpdate] = useState<ProjectProviderTypes.MappedProjectRefund | null>(null);
  const [invoiceRefundToDelete, setInvoiceRefundToDelete] = useState<ProjectProviderTypes.MappedProjectRefund | null>(null);
  return (
    <ViewTab id="project-contract-refunds-view">
      <ViewTabList
        header={{
          filter: {
            left: {
              inputs: [<SearchInputGroup size="xs" placeholder="Recherche par étiquette" value={filterValues.search} onChange={filterFunctions.setSearch} />],
              applyButton: null,
            },
            right: {
              inputs: [],
            },
          },
        }}
        content={{
          table: {
            emptyBodies: [
              {
                show: contract.refunds.length === 0,
                title: "Aucun remboursement",
                content: contract.invoices.some((invoice) => invoice.balanceAmount < 0)
                  ? "Pour créer un remboursement, cliquez sur le bouton d'actions supplémentaires d'une facture, puis cliquer sur 'Créer un remboursement'."
                  : "Aucune facture n'a de solde négatif.",
              },
            ],
            headColumns: [
              {
                name: "date",
                value: "DATE",
              },
              {
                name: "label",
                value: "ÉTIQUETTE",
              },
              {
                name: "mode",
                value: "MODE",
              },
              {
                name: "amount",
                value: "MONTANT",
              },
              {
                name: "reference-number",
                value: "NUMÉRO DE RÉFÉRENCE",
              },
              {
                name: "notes",
                value: "NOTES",
              },
              {
                name: "invoice",
                value: "FACTURE",
              },
              {
                name: "selection",
                value: "Sélection",
              },
            ],
            bodyRows: applyFilters(contract.refunds).map((refund) => {
              return {
                key: refund.id,
                columns: [
                  {
                    name: "date",
                    component: <DateTime value={refund.date} />,
                  },
                  {
                    name: "label",
                    component: <Text highlight>{refund.label}</Text>,
                  },
                  {
                    name: "mode",
                    component: <EnumLabel value={refund.mode} uppercase bold block />,
                  },
                  {
                    name: "amount",
                    component: refund.amount.toCurrencyFormat(),
                  },
                  {
                    name: "reference-number",
                    component: refund.referenceNumber ?? "--",
                  },
                  {
                    name: "notes",
                    component: refund.notes ?? "--",
                  },
                  {
                    name: "invoice",
                    component: refund.invoice?.label ?? "--",
                  },
                  {
                    name: "selection",
                    component: refund.invoice?.contractSelection?.label ?? "--",
                  },
                ],
                actions: [
                  {
                    __type: "Button",
                    content: "Modifier le remboursement",
                    show: contract.isCanceled && refund.invoice === null,
                    security: {
                      permissions: [[PermissionName.CONTRACT_REFUND_UPDATE]],
                    },
                    onClick: () => {
                      setRefundToUpdate(refund);
                    },
                  },
                  {
                    __type: "Button",
                    content: "Supprimer le remboursement",
                    show: contract.isCanceled && refund.invoice === null,
                    security: {
                      permissions: [[PermissionName.CONTRACT_REFUND_DELETE]],
                    },
                    onClick: () => {
                      setRefundToDelete(refund);
                    },
                  },
                  {
                    __type: "Button",
                    content: "Modifier le remboursement",
                    show:
                      !contract.isCanceled &&
                      refund.invoice !== null &&
                      refund.invoice.contractSelection !== null &&
                      refund.invoice.status !== InvoiceStatus.CREDITED &&
                      refund.invoice.status !== InvoiceStatus.CANCELED &&
                      refund.invoice.status !== InvoiceStatus.NO_CHARGE &&
                      contract.currentSelectionId === refund.invoice.contractSelection.id,
                    security: {
                      permissions: [[PermissionName.CONTRACT_INVOICE_REFUND_UPDATE]],
                    },
                    onClick: () => {
                      setInvoiceRefundToUpdate(refund);
                    },
                  },
                  {
                    __type: "Button",
                    content: "Supprimer le remboursement",
                    show:
                      !contract.isCanceled &&
                      refund.invoice !== null &&
                      refund.invoice.contractSelection !== null &&
                      refund.invoice.status !== InvoiceStatus.CREDITED &&
                      refund.invoice.status !== InvoiceStatus.CANCELED &&
                      refund.invoice.status !== InvoiceStatus.NO_CHARGE &&
                      contract.currentSelectionId === refund.invoice.contractSelection.id,
                    security: {
                      permissions: [[PermissionName.CONTRACT_INVOICE_REFUND_DELETE]],
                    },
                    onClick: () => {
                      setInvoiceRefundToDelete(refund);
                    },
                  },
                ],
              };
            }),
            bodyRowGroups: [],
          },
        }}
      />
      {refundToUpdate && (
        <ContractRefundUpdateModal
          tenantId={tenant.id}
          projectId={project.id}
          contract={contract}
          refund={refundToUpdate}
          onCompleted={() => {
            setRefundToUpdate(null);
          }}
          onCancel={() => {
            setRefundToUpdate(null);
          }}
        />
      )}
      {refundToDelete && (
        <ContractRefundDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          contract={contract}
          refund={refundToDelete}
          onCompleted={() => {
            setRefundToDelete(null);
          }}
          onClose={() => {
            setRefundToDelete(null);
          }}
        />
      )}
      {invoiceRefundToUpdate && (
        <ContractInvoiceRefundUpdateModal
          tenantId={tenant.id}
          projectId={project.id}
          contract={contract}
          refund={invoiceRefundToUpdate}
          onCompleted={() => {
            setInvoiceRefundToUpdate(null);
          }}
          onCancel={() => {
            setInvoiceRefundToUpdate(null);
          }}
        />
      )}
      {invoiceRefundToDelete && (
        <ContractInvoiceRefundDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          contract={contract}
          refund={invoiceRefundToDelete}
          onCompleted={() => {
            setInvoiceRefundToDelete(null);
          }}
          onClose={() => {
            setInvoiceRefundToDelete(null);
          }}
        />
      )}
    </ViewTab>
  );
};
