import "./index.scss";
import { faCaretDown, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent, useState } from "react";
import { Animation } from "rsuite";
import { Types } from "types";

type Props = {
  readonly onHeaderClick: () => void;
  readonly disabled?: boolean;
  readonly navs: ReadonlyArray<Types.SidebarNav>;
};

export const Sidebar: FunctionComponent<Props> = ({ onHeaderClick, disabled = false, navs }) => (
  <aside className="sidebar">
    <div className={`sidebar-header${disabled ? " sidebar-header-disabled" : ""}`} onClick={disabled ? undefined : onHeaderClick}>
      <img src="/logo.png" alt="logo" />
    </div>
    <div className="sidebar-body">
      {navs.map((nav, navIndex) => (
        <div key={navIndex} className="nav">
          <div className="nav-title">{nav.title}</div>
          {nav.items
            .filter((navItem) => navItem.__type === "NavItem" && navItem.show)
            .map((item, itemIndex) => {
              if (item.__type === "NavMenu") {
                return <NavMenu key={itemIndex} menu={item} />;
              }
              return <NavItem key={itemIndex} item={item} />;
            })}
        </div>
      ))}
    </div>
    <div className="sidebar-footer"></div>
  </aside>
);

const NavMenu: FunctionComponent<{
  readonly menu: Types.SidebarNavMenu;
}> = ({ menu }) => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <>
      <NavItem
        dropdown={{ show: show }}
        item={{
          name: menu.name,
          icon: menu.icon,
          active: false,
          show: true,
          disabled: false,
          security: {},
          onClick: () => {
            setShow(!show);
          },
        }}
      />
      <Animation.Collapse in={show} className="nav-menu">
        {(props, ref) => (
          <div {...props} ref={ref}>
            {menu.items.map((item, itemIndex) => (
              <NavItem key={itemIndex} item={item} />
            ))}
          </div>
        )}
      </Animation.Collapse>
    </>
  );
};

const NavItem: FunctionComponent<{
  readonly dropdown?: {
    readonly show: boolean;
  };
  readonly item: Omit<Types.SidebarNavItem, "__type">;
}> = ({ item, dropdown }) => (
  <div className={`nav-item${item.active ? " nav-item-active" : ""}${item.disabled ? " nav-item-disabled" : ""}`} onClick={item.active || item.disabled ? undefined : item.onClick}>
    <div className="nav-content">
      {item.icon && <FontAwesomeIcon icon={item.icon} />}
      {item.name}
    </div>
    {dropdown && (
      <div className="nav-dropdown">
        <FontAwesomeIcon icon={dropdown.show ? faCaretDown : faCaretLeft} />
      </div>
    )}
  </div>
);
