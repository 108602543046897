import "./index.scss";
import { FunctionComponent } from "react";
import { ViewForm } from "buildingBlocks";
import { useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { useCreateSectionMutation } from "graphql/mutations";
import { PermissionName, SectionElementOptionType } from "graphql/schema";
import { SectionForm, useSectionForm } from "forms";

export const ProjectSectionCreateView: FunctionComponent = () => {
  const { navigate, toProjectSectionsView, toProjectSectionView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, models, activities, products } = useProjectContext();
  const { values, setValues } = useSectionForm({
    title: "",
    modelLink: null,
    elementGroups: [],
    elements: [],
  });
  const { createSection, createSectionLoading, createSectionErrors } = useCreateSectionMutation(({ tenantId, projectId, section }) => {
    navigate(
      toProjectSectionView({
        tenantId: tenantId,
        projectId: projectId,
        sectionId: section.id,
      })
    );
  });
  return (
    <ViewForm
      id="project-section-create-view"
      title="Créer une section"
      security={{
        permissions: [[PermissionName.SECTION_READ, PermissionName.SECTION_CREATE]],
      }}
      errors={createSectionErrors}
      loading={createSectionLoading}
      onSubmit={async () => {
        await createSection({
          tenantId: tenant.id,
          projectId: project.id,
          title: values.title,
          modelLink: values.modelLink
            ? {
                modelId: values.modelLink.modelId,
                modelRoomId: values.modelLink.modelRoomId,
              }
            : null,
          elementGroups: values.elementGroups
            .filter((elementGroup) => !elementGroup.isDeleted)
            .map((elementGroup) => ({
              id: elementGroup.id,
              name: elementGroup.name,
              index: elementGroup.index,
            })),
          elements: values.elements
            .filter((element) => !element.isDeleted)
            .map((element) => ({
              id: element.id,
              index: element.index,
              type: element.type,
              label: element.label,
              notes: element.notes?.trim() || null,
              modelLink: element.modelLink
                ? {
                    modelId: element.modelLink.modelId,
                    modelRoomId: element.modelLink.modelRoomId,
                    modelRoomElementId: element.modelLink.modelRoomElementId,
                  }
                : null,
              isOptional: element.isOptional,
              isManagedByVibes: element.isManagedByVibes,
              hideProductCategory: element.hideProductCategory,
              groupId: element.groupId,
              options: element.options
                .filter((elementOption) => !elementOption.isDeleted)
                .map((elementOption) => ({
                  id: elementOption.id,
                  index: elementOption.index,
                  type: elementOption.type,
                  clientAmount: elementOption.clientAmount,
                  productId: elementOption.productId,
                  modelLink: elementOption.modelLink
                    ? {
                        modelId: elementOption.modelLink.modelId,
                        modelRoomId: elementOption.modelLink.modelRoomId,
                        modelRoomElementId: elementOption.modelLink.modelRoomElementId,
                        modelRoomElementOptionId: elementOption.modelLink.modelRoomElementOptionId,
                      }
                    : null,
                  breakdownLines:
                    elementOption.type !== SectionElementOptionType.EXTRA && elementOption.type !== SectionElementOptionType.CREDIT_SUBCONTRACTOR
                      ? []
                      : elementOption.breakdownLines
                          .filter((breakdownLine) => breakdownLine.subcontractorAmount !== 0 || breakdownLine.contractorAmount !== 0 || breakdownLine.promoterAmount !== 0)
                          .map((elementOptionBreakdownLine) => ({
                            activityId: elementOptionBreakdownLine.activityId,
                            subcontractorAmount: elementOptionBreakdownLine.subcontractorAmount,
                            contractorAmount: elementOptionBreakdownLine.contractorAmount,
                            promoterAmount: elementOptionBreakdownLine.promoterAmount,
                          })),
                })),
            })),
        });
      }}
      onCancel={() => {
        navigate(
          toProjectSectionsView({
            tenantId: tenant.id,
            projectId: project.id,
          })
        );
      }}
    >
      <SectionForm values={values} setValues={setValues} models={models} activities={activities} products={products} />
    </ViewForm>
  );
};
