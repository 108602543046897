import "./index.scss";
import { FunctionComponent } from "react";
import { useSecurityContext } from "providers";
import { Types } from "types";

type Props = {
  readonly tabs: ReadonlyArray<Types.HeaderTab>;
};

export const HeaderTabs: FunctionComponent<Props> = ({ tabs }) => {
  const { isAuthorized } = useSecurityContext();
  return (
    <div className="header-tabs">
      {tabs
        .filter((tab) => tab.show)
        .filter((tab) => isAuthorized({ security: tab.security }))
        .map((tab, tabIndex) => (
          <div key={tabIndex} className={`header-tab${tab.active ? " header-tab-active" : ""}`} onClick={tab.active ? undefined : tab.onClick}>
            {tab.name}
          </div>
        ))}
    </div>
  );
};
