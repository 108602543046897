import "./index.scss";
import { useCreateContractRevisionMutation } from "graphql/mutations";
import { FunctionComponent } from "react";
import { ModalForm } from "buildingBlocks";
import { ContractRevisionCreateForm, useContractRevisionCreateForm } from "forms";
import { ContractSelectionWorkflow, InvoiceTermsOfPayment } from "graphql/schema";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";
import { MappedTaxGroup } from "providers";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly contract: ProjectProviderTypes.MappedProjectContract;
  readonly taxGroups: ReadonlyArray<MappedTaxGroup>;
  readonly onCompleted: () => void;
  readonly onCancel: () => void;
};

export const ContractRevisionCreateModal: FunctionComponent<Props> = ({ tenantId, projectId, contract, taxGroups, onCompleted, onCancel }) => {
  const { values, setValues } = useContractRevisionCreateForm({
    label: `Révision ${contract.selections.map((contractSelection) => contractSelection.revisionNumber).max()! + 1}`,
    reason: "",
    terms: InvoiceTermsOfPayment.NET7,
    creditAmount: 0,
    revisionAmount: 0,
    mixAndMatchAmount: 250,
    taxGroupId: taxGroups[0].id,
    workflow: ContractSelectionWorkflow.WITH_CUSTOMER,
  });
  const { createContractRevision, createContractRevisionErrors, createContractRevisionLoading } = useCreateContractRevisionMutation(onCompleted, undefined, false);
  return (
    <ModalForm
      id="contract-revision-create-modal"
      title="Créer une révision"
      errors={createContractRevisionErrors}
      loading={createContractRevisionLoading}
      disabled={false}
      submitButton={{
        onClick: async () => {
          await createContractRevision({
            tenantId: tenantId,
            projectId: projectId,
            contractId: contract.id,
            taxGroupId: values.taxGroupId,
            reason: values.reason.trim() || null,
            terms: values.terms,
            creditAmount: values.creditAmount,
            revisionAmount: values.revisionAmount,
            mixAndMatchAmount: values.mixAndMatchAmount,
            workflow: values.workflow,
          });
        },
      }}
      closeButton={{
        text: "Annuler",
        onClick: onCancel,
      }}
    >
      <ContractRevisionCreateForm values={values} setValues={setValues} taxGroups={taxGroups} />
    </ModalForm>
  );
};
