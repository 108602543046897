import "./index.scss";
import { FunctionComponent, PropsWithChildren } from "react";
import { Modal } from "rsuite";

type Props = PropsWithChildren & {
  readonly className?: string;
};

export const DialogBody: FunctionComponent<Props> = ({ children, className = undefined }) => (
  <Modal.Body className={`dialog-body${className ? ` ${className}` : ""}`}>{children}</Modal.Body>
);
