import "./index.scss";
import { useConfigureSectionMutation } from "graphql/mutations";
import { FunctionComponent } from "react";
import { Dialog, DialogBody, DialogFooter } from "buildingBlocks";
import { Button } from "rsuite";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly section: ProjectProviderTypes.MappedProjectSection;
  readonly onCompleted: () => void;
  readonly onClose: () => void;
};

export const SectionConfigureDialog: FunctionComponent<Props> = ({ tenantId, projectId, section, onCompleted, onClose }) => {
  const { configureSection, configureSectionLoading } = useConfigureSectionMutation(onCompleted, onClose, true);
  return (
    <Dialog id="section-configure-dialog" title="Configurer la section">
      <DialogBody>Les ambiances peuvent être ajoutées à la section configuré.</DialogBody>
      <DialogFooter>
        <Button
          appearance="primary"
          size="sm"
          loading={configureSectionLoading}
          onClick={async () => {
            await configureSection({
              tenantId: tenantId,
              projectId: projectId,
              sectionId: section.id,
            });
          }}
        >
          Configuré
        </Button>
        <Button appearance="default" size="sm" disabled={configureSectionLoading} onClick={onClose}>
          Fermer
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
