import "./index.scss";
import { FormContainer, Uploader } from "buildingBlocks";
import { FunctionComponent, useState } from "react";
import { Form, SelectPicker, TagPicker, Toggle } from "rsuite";
import produce from "immer";
import { CustomerFragment, FormState } from "graphql/schema";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type ContractFormValues = {
  readonly customers: {
    readonly customerId: string;
    readonly index: number;
  }[];
  readonly unitId: string;
  readonly formId: string | null;
  readonly document: File | undefined;
  readonly isCustomized: boolean;
};

type Props = {
  readonly values: ContractFormValues;
  readonly setValues: (values: ContractFormValues) => void;
  readonly customers: ReadonlyArray<CustomerFragment>;
  readonly forms: ReadonlyArray<ProjectProviderTypes.MappedProjectForm>;
  readonly units: ReadonlyArray<ProjectProviderTypes.MappedProjectUnit>;
  readonly readonlyUnit?: boolean;
  readonly readonlyForm?: boolean;
};

export const useContractForm = (initialValues: ContractFormValues) => {
  const [values, setValues] = useState<ContractFormValues>(initialValues);
  return { values, setValues };
};

export const ContractForm: FunctionComponent<Props> = ({ values, setValues, customers, forms, units, readonlyUnit = false, readonlyForm = false }) => {
  const customerIds = values.customers.map(({ customerId }) => customerId);
  return (
    <Form id="contract-form">
      <FormContainer highlight>
        <Form.Group>
          <Form.ControlLabel>Unité</Form.ControlLabel>
          <SelectPicker
            readOnly={readonlyUnit}
            value={values.unitId}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.unitId = value!;
                })
              );
            }}
            cleanable={false}
            data={units.map((unit) => ({
              label: unit.label,
              value: unit.id,
            }))}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Clients</Form.ControlLabel>
          <TagPicker
            value={[...values.customers].sort((left, right) => left.index - right.index).map(({ customerId }) => customerId)}
            data={customers.map((customer) => ({
              label: customer.label,
              value: customer.id,
            }))}
            readOnly={false}
            cleanable={false}
            disabledItemValues={values.customers.length >= 2 ? customers.filter((customer) => !customerIds.includes(customer.id)).map((customer) => customer.id) : []}
            onChange={(value: string[]) => {
              setValues(
                produce(values, (draft) => {
                  draft.customers = value.map((customerId, index) => ({
                    customerId: customerId,
                    index: index,
                  }));
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Document (.pdf)</Form.ControlLabel>
          <Uploader
            accept=".pdf"
            disabled={values.document !== undefined}
            onChange={(fileTypes) => {
              const fileType = fileTypes[0];
              if (fileType) {
                setValues(
                  produce(values, (draft) => {
                    draft.document = fileType.blobFile;
                  })
                );
              }
            }}
            onRemove={() => {
              setValues(
                produce(values, (draft) => {
                  draft.document = undefined;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>En personnalisation</Form.ControlLabel>
          <Toggle
            checked={values.isCustomized}
            onChange={(checked) => {
              setValues(
                produce(values, (draft) => {
                  draft.isCustomized = checked;
                })
              );
            }}
          />
        </Form.Group>
      </FormContainer>
      {!values.isCustomized && (
        <FormContainer
          header={{
            title: "Formulaire",
            actions: [],
          }}
        >
          <Form.Group>
            <SelectPicker
              value={values.formId}
              readOnly={readonlyForm}
              onChange={(value) => {
                setValues(
                  produce(values, (draft) => {
                    draft.formId = value;
                  })
                );
              }}
              cleanable
              data={forms
                .filter((form) => form.state === FormState.PUBLISHED)
                .map((form) => ({
                  value: form.id,
                  label: form.name,
                }))}
            />
          </Form.Group>
        </FormContainer>
      )}
    </Form>
  );
};
