import "./index.scss";
import { Types } from "types";
import { FunctionComponent, PropsWithChildren } from "react";
import { useSecurityContext } from "providers";
import { Button } from "rsuite";

type Props = PropsWithChildren & {
  readonly highlight?: boolean | undefined;
  readonly lineThrough?: boolean | undefined;
  readonly disabled?: boolean | undefined;
  readonly href?: string | undefined;
  readonly hasOverflow?: boolean;
  readonly empty?: Types.TableRowEmpty;
};

export const TableRow: FunctionComponent<Props> = ({ highlight, lineThrough = false, disabled, href, children, empty, hasOverflow = false }) => {
  const { isAuthorized } = useSecurityContext();
  if (empty) {
    return (
      <div className={`table-row table-row-empty`}>
        <span className="table-row-empty-title">{empty.title}</span>
        {isAuthorized({ security: empty.security }) ? (
          <div className="table-row-empty-content">
            <p>{empty.content.text}</p>
            <Button size="md" appearance="primary" onClick={empty.content.button.onClick}>
              {empty.content.button.text}
            </Button>
          </div>
        ) : (
          <span className="table-row-empty-denied-text">{empty.deniedText}</span>
        )}
      </div>
    );
  }
  return (
    <div
      className={`table-row${highlight ? ` table-row-highlight` : ""}${hasOverflow ? ` table-row-has-overflow` : ""}${disabled ? ` table-row-disabled` : ""}${href ? ` table-row-link` : ""}${lineThrough ? " table-row-line-through" : ""}`}
    >
      {children}
    </div>
  );
};
