import { ProjectProviderTypes } from "providers/ProjectProvider/types";
import "./index.scss";
import { FunctionComponent } from "react";
import { Toggle } from "rsuite";

type Props = {
  readonly contractSelectionGroupItemSet: ProjectProviderTypes.MappedProjectContractSelectionGroupItemSet;
  readonly values: ReadonlyArray<{
    readonly sectionElementId: string;
    readonly sectionElementOptionId: string | null;
    readonly clientAmount: number;
  }>;
  readonly onClick: (sectionElement: ProjectProviderTypes.MappedProjectSectionElement, sectionElementOption: ProjectProviderTypes.MappedProjectSectionElementOption | null) => void;
};

export const ContractSelectionGroupItemSet: FunctionComponent<Props> = ({ contractSelectionGroupItemSet, values, onClick }) => {
  const totalAmount = values.reduce((previousValue, currentValue) => previousValue + currentValue.clientAmount, 0);
  return (
    <div className="contract-selection-group-item-set">
      <div className="contract-selection-group-item-set-header">
        <span className="section-element-group-name">{contractSelectionGroupItemSet.sectionElementGroupName}</span>
        <span className="total-amount">{totalAmount !== 0 ? totalAmount.toCurrencyFormat() : "SANS FRAIS"}</span>
      </div>
      <div className="contract-selection-group-item-set-body">
        {contractSelectionGroupItemSet.items.map((contractSelectionGroupItem, contractSelectionGroupItemIndex) => {
          const value = values.find((value) => value.sectionElementId === contractSelectionGroupItem.sectionElement.id);
          const sectionElementOption = contractSelectionGroupItem.sectionElement.getOptionsWithoutSets()[0];
          return (
            <div className="contract-selection-group-item" key={contractSelectionGroupItemIndex}>
              <span className="section-element-label">{contractSelectionGroupItem.sectionElement.label}</span>
              <Toggle
                size="md"
                disabled={!sectionElementOption.isUnlocked}
                checked={sectionElementOption.id === value?.sectionElementOptionId}
                unCheckedChildren="EXCLU"
                checkedChildren={sectionElementOption.clientAmount.toCurrencyFormat()}
                onChange={(checked) => {
                  if (checked) {
                    onClick(contractSelectionGroupItem.sectionElement, sectionElementOption);
                  } else {
                    onClick(contractSelectionGroupItem.sectionElement, null);
                  }
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
