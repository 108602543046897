import "./index.scss";
import { FormContainer } from "buildingBlocks";
import { FunctionComponent, useState } from "react";
import { Form, Input } from "rsuite";
import { produce } from "immer";

type ActivityFormValues = {
  readonly number: string;
  readonly title: string;
  readonly description: string;
};

type Props = {
  readonly values: ActivityFormValues;
  readonly setValues: (values: ActivityFormValues) => void;
};

export const useActivityForm = (initialValues: ActivityFormValues) => {
  const [values, setValues] = useState<ActivityFormValues>(initialValues);
  return { values, setValues };
};

export const ActivityForm: FunctionComponent<Props> = ({ values, setValues }) => (
  <Form id="activity-form">
    <FormContainer highlight>
      <Form.Group>
        <Form.ControlLabel>Numéro</Form.ControlLabel>
        <Input
          value={values.number}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.number = value;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Titre</Form.ControlLabel>
        <Input
          value={values.title}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.title = value;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Description</Form.ControlLabel>
        <Input
          as="textarea"
          rows={3}
          value={values.description}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.description = value;
              })
            );
          }}
        />
      </Form.Group>
    </FormContainer>
  </Form>
);
