import { DialogFooter } from "buildingBlocks/DialogFooter";
import "./index.scss";
import { FunctionComponent, PropsWithChildren } from "react";
import { Button } from "rsuite";

type Props = PropsWithChildren & {
  readonly disabled: boolean;
  readonly loading: boolean;
  readonly onClick: () => void;
  readonly onClose: () => void;
};

export const DialogFooterDelete: FunctionComponent<Props> = ({ disabled, loading, onClick, onClose }) => (
  <DialogFooter className="dialog-footer-delete">
    <Button disabled={disabled} loading={loading} onClick={onClick} appearance="primary">
      Supprimer
    </Button>
    <Button disabled={loading} onClick={onClose} appearance="default">
      Fermer
    </Button>
  </DialogFooter>
);
