import "./index.scss";
import { FunctionComponent, useState } from "react";
import { Dialog, DialogBodyDelete, DialogFooterDelete } from "buildingBlocks";
import { useDeleteUnitMutation } from "graphql/mutations";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly unit: ProjectProviderTypes.MappedProjectUnit;
  readonly onCompleted: () => void;
  readonly onClose: () => void;
};

export const UnitDeleteDialog: FunctionComponent<Props> = ({ tenantId, projectId, unit, onCompleted, onClose }) => {
  const [value, setValue] = useState<string>("");
  const { deleteUnit, deleteUnitLoading } = useDeleteUnitMutation(onCompleted, onClose, true);
  return (
    <Dialog id="unit-delete-dialog" title="Supprimer l'unité">
      <DialogBodyDelete objectName={unit.label} dictionaryList={[]} value={value} setValue={setValue} />
      <DialogFooterDelete
        disabled={unit.label !== value}
        loading={deleteUnitLoading}
        onClose={onClose}
        onClick={async () => {
          await deleteUnit({
            tenantId: tenantId,
            projectId: projectId,
            unitId: unit.id,
          });
        }}
      />
    </Dialog>
  );
};
