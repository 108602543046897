import "./index.scss";
import { FunctionComponent } from "react";
import { ViewForm } from "buildingBlocks";
import { useProjectProductCategoryContext, useRouting } from "hooks";
import { useCreateProductMutation } from "graphql/mutations";
import { useProjectContext, useTenantContext } from "providers";
import { PermissionName } from "graphql/schema";
import { ProductForm, useProductForm } from "forms";

export const ProjectProductCategoryProductCreateView: FunctionComponent = () => {
  const { navigate, toProjectProductsView, toProjectProductView } = useRouting();
  const { tenant, providers } = useTenantContext();
  const { project, activities, productCategories } = useProjectContext();
  const { productCategory } = useProjectProductCategoryContext();
  const { values, setValues } = useProductForm({
    categoryId: productCategory.id,
    variant: "",
    description: "",
    format: "",
    managedByModels: false,
    usedBySections: false,
    hasThumbnail: false,
    thumbnailUrl: "",
    thumbnail: undefined,
    provider: {
      id: null,
      productModel: "",
      productCode: "",
      productUrl: "",
    },
    activities: [],
  });
  const { createProduct, createProductLoading, createProductErrors } = useCreateProductMutation(({ tenantId, projectId, product }) => {
    navigate(
      toProjectProductView({
        tenantId: tenantId,
        projectId: projectId,
        productId: product.id,
      })
    );
  });
  return (
    <ViewForm
      id="project-product-category-product-create-view"
      title="Créer un produit"
      security={{
        permissions: [[PermissionName.PRODUCT_READ, PermissionName.PRODUCT_CREATE]],
      }}
      errors={createProductErrors}
      loading={createProductLoading}
      onSubmit={async () => {
        await createProduct({
          tenantId: tenant.id,
          projectId: project.id,
          variant: values.variant,
          description: values.description.trim() || null,
          format: values.format.trim() || null,
          thumbnail: values.thumbnail ?? null,
          categoryId: values.categoryId,
          provider: {
            id: values.provider.id,
            productModel: values.provider.productModel.trim() || null,
            productCode: values.provider.productCode.trim() || null,
            productUrl: values.provider.productUrl.trim() || null,
          },
          activities: values.activities.map((activity) => ({
            activityId: activity.activityId,
            index: activity.index,
          })),
        });
      }}
      onCancel={() => {
        navigate(
          toProjectProductsView({
            tenantId: tenant.id,
            projectId: project.id,
          })
        );
      }}
    >
      <ProductForm values={values} setValues={setValues} activities={activities} providers={providers} productCategories={productCategories} readonlyProductCategory />
    </ViewForm>
  );
};
