import "./index.scss";
import { FunctionComponent } from "react";
import { Document, DocumentTitle, getEnumLabel, Report, Table, TableBody, TableColumn, TableContent, TableHead, TableHeader, TableRow, Text } from "buildingBlocks";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";
import { BillingReportFragment, BillingReportTableFragment } from "graphql/schema";

type MappedReportBillingTable = BillingReportTableFragment & {
  readonly contract: ProjectProviderTypes.MappedProjectContract;
  readonly contractSelection: ProjectProviderTypes.MappedProjectContractSelection;
};

type MappedReportBilling = Omit<BillingReportFragment, "tables"> & {
  readonly tables: ReadonlyArray<MappedReportBillingTable>;
};

type Props = {
  readonly report: MappedReportBilling;
};

export const ReportBilling: FunctionComponent<Props> = ({ report }) => (
  <Report id="report-billing">
    <Document>
      <DocumentTitle subtitle="Ventes" value="Facturation" />
      <Table fluid size="sm">
        <TableHead>
          <TableRow>
            <TableColumn name="element"></TableColumn>
            <TableColumn name="provider"></TableColumn>
            <TableColumn name="type"></TableColumn>
            <TableColumn name="subcontractor">SOUS-TRAITANT</TableColumn>
            <TableColumn name="contractor">ENTREPRENEUR</TableColumn>
            <TableColumn name="promoter">PROMOTEUR</TableColumn>
            <TableColumn name="customer">CLIENT</TableColumn>
            <TableColumn name="contengency">CONTINGENCE</TableColumn>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableColumn name="element">TOTAL</TableColumn>
            <TableColumn name="provider"></TableColumn>
            <TableColumn name="type"></TableColumn>
            <TableColumn name="subcontractor">{report.subcontractorAmount.toCurrencyFormat()}</TableColumn>
            <TableColumn name="contractor">{report.contractorAmount.toCurrencyFormat()}</TableColumn>
            <TableColumn name="promoter">{report.promoterAmount.toCurrencyFormat()}</TableColumn>
            <TableColumn name="customer">{report.clientAmount.toCurrencyFormat()}</TableColumn>
            <TableColumn name="contengency">{report.contingencyAmount.toCurrencyFormat()}</TableColumn>
          </TableRow>
        </TableBody>
      </Table>
      {report.tables.map((table) => (
        <Table fluid className="table-main" size="sm" key={table.unitNumber}>
          <TableHeader>
            <div>
              <span>Unité #{table.unitNumber}</span>
              {table.isRevisionPending && <Text subtile>(Révision en attente)</Text>}
            </div>
            <div>
              <span>Sélection</span>
              <span>{table.contractSelection.label}</span>
            </div>
          </TableHeader>
          <TableContent>
            <TableHead>
              <TableRow>
                <TableColumn name="element">ÉLÉMENT</TableColumn>
                <TableColumn name="provider">FOURNISSEUR</TableColumn>
                <TableColumn name="type">TYPE</TableColumn>
                <TableColumn name="subcontractor">SOUS-TRAITANT</TableColumn>
                <TableColumn name="contractor">ENTREPRENEUR</TableColumn>
                <TableColumn name="promoter">PROMOTEUR</TableColumn>
                <TableColumn name="customer">CLIENT</TableColumn>
                <TableColumn name="contengency">CONTINGENCE</TableColumn>
              </TableRow>
            </TableHead>
            <TableBody>
              {table.rows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableColumn name="element">{row.elementFullName}</TableColumn>
                  <TableColumn name="provider">{row.providerName}</TableColumn>
                  <TableColumn name="type">{getEnumLabel(row.type)}</TableColumn>
                  <TableColumn name="subcontractor">{row.subcontractorAmount.toCurrencyFormat()}</TableColumn>
                  <TableColumn name="contractor">{row.contractorAmount.toCurrencyFormat()}</TableColumn>
                  <TableColumn name="promoter">{row.promoterAmount.toCurrencyFormat()}</TableColumn>
                  <TableColumn name="customer">{row.clientAmount.toCurrencyFormat()}</TableColumn>
                  <TableColumn name="contengency">{row.contingencyAmount.toCurrencyFormat()}</TableColumn>
                </TableRow>
              ))}
              <TableRow>
                <TableColumn name="element">TOTAL</TableColumn>
                <TableColumn name="provider"></TableColumn>
                <TableColumn name="type"></TableColumn>
                <TableColumn name="subcontractor">{table.subcontractorAmount.toCurrencyFormat()}</TableColumn>
                <TableColumn name="contractor">{table.contractorAmount.toCurrencyFormat()}</TableColumn>
                <TableColumn name="promoter">{table.promoterAmount.toCurrencyFormat()}</TableColumn>
                <TableColumn name="customer">{table.clientAmount.toCurrencyFormat()}</TableColumn>
                <TableColumn name="contengency">{table.contingencyAmount.toCurrencyFormat()}</TableColumn>
              </TableRow>
            </TableBody>
          </TableContent>
        </Table>
      ))}
    </Document>
  </Report>
);
