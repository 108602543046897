import "./index.scss";
import { FunctionComponent, PropsWithChildren } from "react";

type Props = PropsWithChildren & {
  readonly id: string;
};

export const Report: FunctionComponent<Props> = ({ id, children }) => (
  <div id={id} className="report">
    {children}
  </div>
);
