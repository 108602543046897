import "./index.scss";
import { FunctionComponent, PropsWithChildren, ReactNode } from "react";
import { DateTime, Document, EnumLabel, Report } from "buildingBlocks";
import { ReportCellType, SummaryReportCellFragment, SummaryReportElementColumnFragment, SummaryReportFragment, SummaryReportRoomColumnGroupFragment, UnitState } from "graphql/schema";

type Props = {
  readonly report: SummaryReportFragment;
  readonly withDetails: boolean;
};

export const ReportSummary: FunctionComponent<Props> = ({ report, withDetails }) => {
  const baseRoomColumnGroups = report.baseActivityColumnGroups.reduce<ReadonlyArray<SummaryReportRoomColumnGroupFragment>>(
    (previousValue, activityColumnGroup) => previousValue.concat(activityColumnGroup.roomColumnGroups),
    []
  );
  const baseElementColumns = baseRoomColumnGroups.reduce<ReadonlyArray<SummaryReportElementColumnFragment>>(
    (previousValue, roomColumnGroup) => previousValue.concat(roomColumnGroup.elementColumns),
    []
  );
  const extraRoomColumnGroups = report.extraActivityColumnGroups.reduce<ReadonlyArray<SummaryReportRoomColumnGroupFragment>>(
    (previousValue, activityColumnGroup) => previousValue.concat(activityColumnGroup.roomColumnGroups),
    []
  );
  const extraElementColumns = extraRoomColumnGroups.reduce<ReadonlyArray<SummaryReportElementColumnFragment>>(
    (previousValue, roomColumnGroup) => previousValue.concat(roomColumnGroup.elementColumns),
    []
  );
  return (
    <Report id="report-summary">
      <Document>
        <table border={1} className="report-summary-table">
          <thead>
            <TableHeadRow layer={1}>
              {withDetails ? (
                <>
                  <th rowSpan={2} colSpan={4 + 1}>
                    INFORMATION SUR L'UNITÉ
                  </th>
                  <th rowSpan={2} colSpan={10}>
                    INFORMATION SUR LE CLIENT
                  </th>
                  <th rowSpan={3} colSpan={1}>
                    NOMBRE D'EXTRAS
                  </th>
                  <th rowSpan={2} className="table-head-vibe" colSpan={report.vibeColumns.length}>
                    AMBIANCES
                  </th>
                </>
              ) : (
                <th rowSpan={3} colSpan={1}>
                  NUMÉRO D'UNITÉ
                </th>
              )}
              {report.baseActivityColumnGroups.map((activityColumnGroup) => (
                <th rowSpan={1} className="table-head-base" colSpan={activityColumnGroup.colspan} key={activityColumnGroup.index}>
                  {activityColumnGroup.name}
                </th>
              ))}
              {report.extraActivityColumnGroups.map((activityColumnGroup) => (
                <th rowSpan={1} className="table-head-extra" colSpan={activityColumnGroup.colspan} key={activityColumnGroup.index}>
                  {activityColumnGroup.name}
                </th>
              ))}
            </TableHeadRow>
            <TableHeadRow layer={2}>
              {baseRoomColumnGroups.map((roomColumnGroup) => (
                <th className="table-head-base" colSpan={roomColumnGroup.colspan} key={roomColumnGroup.index}>
                  {roomColumnGroup.name}
                </th>
              ))}
              {extraRoomColumnGroups.map((roomColumnGroup) => (
                <th className="table-head-extra" colSpan={roomColumnGroup.colspan} key={roomColumnGroup.index}>
                  {roomColumnGroup.name}
                </th>
              ))}
            </TableHeadRow>
            <TableHeadRow layer={3}>
              {withDetails && (
                <>
                  <th>NUMÉRO</th>
                  <th>ÉTAGE</th>
                  <th>NB DE CHAMBRES</th>
                  <th>NB DE SDB</th>
                  <th>ÉTAT</th>
                  <th>NOM (1)</th>
                  <th>TÉLÉPHONE (1)</th>
                  <th>COURRIEL (1)</th>
                  <th>NOM (2)</th>
                  <th>TÉLÉPHONE (2)</th>
                  <th>COURRIEL (2)</th>
                  <th>PREMIÈRE RENCONTRE</th>
                  <th>RÉVISION 1</th>
                  <th>RÉVISION 2</th>
                  <th>RÉVISION 3</th>
                  {report.vibeColumns.map((column) => (
                    <th className="table-head-vibe" key={column.index}>
                      {column.name}
                    </th>
                  ))}
                </>
              )}
              {baseElementColumns.map((elementColumn) => (
                <th className="table-head-base" key={elementColumn.index}>
                  {elementColumn.name}
                </th>
              ))}
              {extraElementColumns.map((elementColumn) => (
                <th className="table-head-extra" key={elementColumn.index}>
                  {elementColumn.name}
                </th>
              ))}
            </TableHeadRow>
          </thead>
          <tbody>
            {report.rows.map((row) => {
              const cells = new Map<number, SummaryReportCellFragment>();
              for (const cell of row.cells) {
                cells.set(cell.index, cell);
              }
              const customer0 = row.customerInfos.find((customerInfo) => customerInfo.index === 0);
              const customer1 = row.customerInfos.find((customerInfo) => customerInfo.index === 1);
              const contractSelectionInfo0 = row.contractSelectionInfos.find((contractSelectionInfo) => contractSelectionInfo.revisionNumber === 0);
              const contractSelectionInfo1 = row.contractSelectionInfos.find((contractSelectionInfo) => contractSelectionInfo.revisionNumber === 1);
              const contractSelectionInfo2 = row.contractSelectionInfos.find((contractSelectionInfo) => contractSelectionInfo.revisionNumber === 2);
              const contractSelectionInfo3 = row.contractSelectionInfos.find((contractSelectionInfo) => contractSelectionInfo.revisionNumber === 3);
              return (
                <tr key={row.unitNumber}>
                  <th>{row.unitNumber}</th>
                  {withDetails && (
                    <>
                      <th>{row.unitFloor}</th>
                      <th>{row.unitNumberOfBedrooms}</th>
                      <th>{row.unitNumberOfBathrooms}</th>
                      <th>
                        <EnumLabel value={row.unitState} uppercase bold plaintext />
                      </th>
                      <TableCell disabled={customer0 === undefined} value={customer0?.fullName ?? ""} />
                      <TableCell disabled={customer0 === undefined} value={customer0?.phone.toPhoneNumberFormat() ?? ""} />
                      <TableCell disabled={customer0 === undefined} value={customer0?.email ?? ""} />
                      <TableCell disabled={customer1 === undefined} value={customer1?.fullName ?? ""} />
                      <TableCell disabled={customer1 === undefined} value={customer1?.phone.toPhoneNumberFormat() ?? ""} />
                      <TableCell disabled={customer1 === undefined} value={customer1?.email ?? ""} />
                      <TableCell disabled={contractSelectionInfo0 === undefined} value={contractSelectionInfo0 ? <DateTime value={contractSelectionInfo0.date} /> : ""} />
                      <TableCell disabled={contractSelectionInfo1 === undefined} value={contractSelectionInfo1 ? <DateTime value={contractSelectionInfo1.date} /> : ""} />
                      <TableCell disabled={contractSelectionInfo2 === undefined} value={contractSelectionInfo2 ? <DateTime value={contractSelectionInfo2.date} /> : ""} />
                      <TableCell disabled={contractSelectionInfo3 === undefined} value={contractSelectionInfo3 ? <DateTime value={contractSelectionInfo3.date} /> : ""} />
                      <TableCell
                        disabled={
                          row.unitState === UnitState.NO_CONTRACT ||
                          row.unitState === UnitState.CONTRACT_IS_CUSTOMIZED ||
                          row.unitState === UnitState.CONTRACT_WITHOUT_FORM ||
                          row.unitState === UnitState.CONTRACT_WITHOUT_SELECTION ||
                          row.unitState === UnitState.CONTRACT_SELECTION_PENDING
                        }
                        value={
                          row.unitState !== UnitState.NO_CONTRACT &&
                          row.unitState !== UnitState.CONTRACT_IS_CUSTOMIZED &&
                          row.unitState !== UnitState.CONTRACT_WITHOUT_FORM &&
                          row.unitState !== UnitState.CONTRACT_WITHOUT_SELECTION &&
                          row.unitState !== UnitState.CONTRACT_SELECTION_PENDING
                            ? row.numberOfExtras
                            : ""
                        }
                      />
                      {report.vibeColumns.map((column) => {
                        const cell = cells.get(column.index);
                        return (
                          <TableCell
                            key={column.index}
                            disabled={
                              cell === undefined ||
                              cell.type === ReportCellType.NONE ||
                              row.unitState === UnitState.NO_CONTRACT ||
                              row.unitState === UnitState.CONTRACT_IS_CUSTOMIZED ||
                              row.unitState === UnitState.CONTRACT_WITHOUT_FORM
                            }
                            value={cell?.value ?? ""}
                          />
                        );
                      })}
                    </>
                  )}
                  {baseElementColumns.map((elementColumn) => {
                    const cell = cells.get(elementColumn.index);
                    return (
                      <TableCell
                        key={elementColumn.index}
                        disabled={
                          cell === undefined ||
                          cell.type === ReportCellType.NONE ||
                          row.unitState === UnitState.NO_CONTRACT ||
                          row.unitState === UnitState.CONTRACT_IS_CUSTOMIZED ||
                          row.unitState === UnitState.CONTRACT_WITHOUT_FORM
                        }
                        value={cell?.value ?? ""}
                      />
                    );
                  })}
                  {extraElementColumns.map((elementColumn) => {
                    const cell = cells.get(elementColumn.index);
                    return (
                      <TableCell
                        key={elementColumn.index}
                        disabled={
                          cell === undefined ||
                          cell.type === ReportCellType.NONE ||
                          row.unitState === UnitState.NO_CONTRACT ||
                          row.unitState === UnitState.CONTRACT_IS_CUSTOMIZED ||
                          row.unitState === UnitState.CONTRACT_WITHOUT_FORM
                        }
                        value={cell?.value ?? ""}
                      />
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Document>
    </Report>
  );
};

type TableCellProps = {
  readonly value: ReactNode;
  readonly disabled?: boolean;
};

const TableCell: FunctionComponent<TableCellProps> = ({ value, disabled }) => {
  return <td className={`table-cell${disabled ? " table-cell-disabled" : ""}`}>{value}</td>;
};

type TableHeadRowProps = PropsWithChildren & {
  readonly layer: number;
};

const TableHeadRow: FunctionComponent<TableHeadRowProps> = ({ layer, children }) => {
  return <tr className={`table-head-row table-head-row-${layer}`}>{children}</tr>;
};
