import { CustomerFragment, CustomerType, Gender, Language } from "graphql/schema";
import { useTenantContext } from "providers";
import { createContext, FunctionComponent, useContext } from "react";
import { Outlet, useParams } from "react-router-dom";

type CustomerContextValue = {
  readonly customer: CustomerFragment;
};

const initialValue: CustomerContextValue = {
  customer: {
    __typename: "Customer",
    id: "",
    firstName: null,
    lastName: null,
    fullName: null,
    companyName: null,
    displayName: "",
    type: CustomerType.INDIVIDUAL,
    label: "",
    email: "",
    phone: "",
    gender: Gender.MALE,
    language: Language.FR,
    notes: null,
    isDefault: false,
    address: {
      __typename: "Address",
      line: "",
      city: "",
      subdivisionCode: "CA-QC",
      countryCode: "CA",
      postalCode: "",
    },
  },
};

const CustomerContext = createContext<CustomerContextValue>(initialValue);

const useCustomerContext = () => useContext(CustomerContext);

const CustomerProvider: FunctionComponent = () => {
  const { customerId } = useParams<{ readonly customerId: string }>();
  const { customers } = useTenantContext();
  return (
    <CustomerContext.Provider
      value={{
        customer: customers.find((customer) => customer.id === customerId)!,
      }}
    >
      <Outlet />
    </CustomerContext.Provider>
  );
};

export { CustomerProvider, useCustomerContext };
