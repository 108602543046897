import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { SearchInputGroup, TooltipWrapper, ViewList, Text } from "buildingBlocks";
import { useRouting } from "hooks";
import { useProjectContext, useTenantContext, useSecurityContext } from "providers";
import { PermissionName } from "graphql/schema";
import { SelectPicker } from "rsuite";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Types } from "types";

type ReportGroup = {
  readonly name: string;
  readonly reports: ReadonlyArray<Report>;
};

type Report = {
  readonly name: string;
  readonly description: string;
  readonly href: string | undefined;
  readonly permissions: Types.Permissions;
};

const useProjectReportsViewFilters = () => {
  const { isAuthorized } = useSecurityContext();
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback(
      (reportGroups: ReadonlyArray<ReportGroup>) =>
        reportGroups
          .filter((reportGroup) =>
            reportGroup.reports.some((report) =>
              isAuthorized({
                security: {
                  permissions: report.permissions,
                },
              })
            )
          )
          .filter((reportGroup) => reportGroup.reports.some((report) => report.name.toSearchFormat().includes(search.toSearchFormat())))
          .map((reportGroup) => ({
            ...reportGroup,
            reports: reportGroup.reports
              .filter((report) =>
                isAuthorized({
                  security: {
                    permissions: report.permissions,
                  },
                })
              )
              .filter((report) => report.name.toSearchFormat().includes(search.toSearchFormat())),
          })),
      [isAuthorized, search]
    ),
  };
};

export const ProjectReportsView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useProjectReportsViewFilters();
  const { navigate, toProjectView, toProjectReportSummaryView, toProjectReportUnitConstructionView, toProjectReportUnitCustomerView, toProjectReportsBillingView, toProjectReportUpdateView } =
    useRouting();
  const { tenant } = useTenantContext();
  const { project, reports, units } = useProjectContext();
  const [reportId, setReportId] = useState<string>(reports[0].id);
  const unit = units.find(() => true) ?? undefined;
  const reportGroups: ReportGroup[] = [
    {
      name: "Contrats",
      reports: [
        {
          name: "Résumé",
          description: "Résumé complet de toutes les sélections des clients.",
          href: unit
            ? toProjectReportSummaryView({
                tenantId: tenant.id,
                projectId: project.id,
                reportId: reportId,
              })
            : undefined,
          permissions: [[PermissionName.REPORT_READ], [PermissionName.REPORT_SUMMARY_READ]],
        },
        {
          name: "Fiche de construction",
          description: "Représentation d'une sélection d'un client pour le chantier.",
          href: unit
            ? toProjectReportUnitConstructionView({
                tenantId: tenant.id,
                projectId: project.id,
                reportId: reportId,
                unitId: unit.id,
              })
            : undefined,
          permissions: [[PermissionName.REPORT_READ], [PermissionName.REPORT_CONSTRUCTION_READ]],
        },
        {
          name: "Fiche du client",
          description: "Représentation de la sélection d'un client pour ces dossiers.",
          href: unit
            ? toProjectReportUnitCustomerView({
                tenantId: tenant.id,
                projectId: project.id,
                reportId: reportId,
                unitId: unit.id,
              })
            : undefined,
          permissions: [[PermissionName.REPORT_READ], [PermissionName.REPORT_CUSTOMER_READ]],
        },
      ],
    },
    {
      name: "Ventes",
      reports: [
        {
          name: "Facturation",
          description: "Une liste des éléments facturés pour chaque unité.",
          href: unit
            ? toProjectReportsBillingView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            : undefined,
          permissions: [[PermissionName.REPORT_READ], [PermissionName.REPORT_SUMMARY_READ]],
        },
      ],
    },
  ];
  return (
    <ViewList
      id="project-reports-view"
      security={{
        permissions: [
          [PermissionName.REPORT_READ],
          [PermissionName.REPORT_CONSTRUCTION_READ],
          [PermissionName.REPORT_CUSTOMER_READ],
          [PermissionName.REPORT_BILLING_READ],
          [PermissionName.REPORT_SUMMARY_READ],
        ],
      }}
      header={{
        title: "Tous les rapports",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              text: "Configurer les rapports",
              show: true,
              security: {
                permissions: [[PermissionName.REPORT_UPDATE]],
              },
              onClick: () => {
                navigate(
                  toProjectReportUpdateView({
                    tenantId: tenant.id,
                    projectId: project.id,
                    reportId: reportId,
                  })
                );
              },
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [<SearchInputGroup size="xs" placeholder="Recherche par nom" value={filterValues.search} onChange={filterFunctions.setSearch} />],
            applyButton: null,
          },
          right: {
            inputs: [
              <SelectPicker
                size="xs"
                label="Rapport"
                value={reportId}
                searchable={false}
                onChange={(value) => {
                  if (reportId !== value) {
                    setReportId(value!);
                  }
                }}
                cleanable={false}
                data={reports.map((report) => ({
                  label: report.name,
                  value: report.id,
                }))}
              />,
            ],
          },
        },
      }}
      content={{
        table: {
          headColumns: [
            {
              name: "name",
              value: "NOM",
            },
            {
              name: "is-valid",
              value: "",
            },
            {
              name: "description",
              value: "DESCRIPTION",
            },
          ],
          bodyRows: [],
          bodyRowGroups: applyFilters(reportGroups).map((reportGroup, reportGroupIndex) => {
            return {
              key: reportGroupIndex.toString(),
              actions: [],
              columns: [
                {
                  name: "name",
                  component: reportGroup.name,
                },
              ],
              rows: reportGroup.reports.map((report, reportIndex) => ({
                key: reportIndex.toString(),
                href: report.href,
                actions: [],
                columns: [
                  {
                    name: "name",
                    component: <Text highlight>{report.name}</Text>,
                  },
                  {
                    name: "is-valid",
                    component:
                      units.length === 0 ? (
                        <TooltipWrapper tooltip="Ajouter des unités dans le projet pour accéder au rapport." highlight>
                          <FontAwesomeIcon color="red" icon={faWarning} />
                        </TooltipWrapper>
                      ) : (
                        ""
                      ),
                  },
                  {
                    name: "description",
                    component: report.description,
                  },
                ],
              })),
            };
          }),
        },
      }}
    />
  );
};
