import "./index.scss";
import { FormErrors } from "buildingBlocks/FormErrors";
import { FunctionComponent, PropsWithChildren } from "react";
import { Button, Modal } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";

type Props = PropsWithChildren & {
  readonly id: string;
  readonly title: string;
  readonly size?: TypeAttributes.Size;
  readonly errors: string[];
  readonly disabled: boolean;
  readonly loading: boolean;
  readonly submitButton: {
    readonly text?: string;
    readonly onClick: () => void;
  };
  readonly closeButton: {
    readonly text?: string;
    readonly onClick: () => void;
  };
};

export const ModalForm: FunctionComponent<Props> = ({ id, title, errors, loading, disabled, submitButton, closeButton, children, size = "sm" }) => (
  <Modal id={id} className="modal modal-form" overflow backdrop="static" open={true} size={size}>
    <Modal.Header className="modal-header">
      <Modal.Title className="modal-title">{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body className="modal-body">
      <FormErrors errors={errors} />
      {children}
    </Modal.Body>
    <Modal.Footer className="modal-footer">
      <Button disabled={disabled} loading={loading} onClick={submitButton.onClick} appearance="primary">
        {submitButton.text ?? "Enregistrer"}
      </Button>
      <Button disabled={loading} onClick={closeButton.onClick} appearance="default">
        {closeButton.text ?? "Fermer"}
      </Button>
    </Modal.Footer>
  </Modal>
);
