import "./index.scss";
import { useDownloadTemplateMutation, useImportUnitsMutation } from "graphql/mutations";
import { FunctionComponent, useState } from "react";
import { FormContainer, ModalForm, Uploader } from "buildingBlocks";
import produce from "immer";
import { Button, Form } from "rsuite";
import { TemplateName } from "graphql/schema";
import { useFile } from "hooks";

type UnitsImportFormValues = {
  readonly file: File | undefined;
};

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly onCompleted: () => void;
  readonly onCancel: () => void;
};

export const UnitsImportModal: FunctionComponent<Props> = ({ tenantId, projectId, onCompleted, onCancel }) => {
  const [values, setValues] = useState<UnitsImportFormValues>({
    file: undefined,
  });
  const { open } = useFile();
  const { downloadTemplate, downloadTemplateLoading } = useDownloadTemplateMutation(({ templateUrl }) => {
    open(templateUrl);
  });
  const { importUnits, importUnitsLoading, importUnitsErrors } = useImportUnitsMutation(onCompleted, undefined, false);
  return (
    <ModalForm
      id="units-upload-modal"
      title="Importer des unités"
      errors={importUnitsErrors}
      loading={importUnitsLoading}
      disabled={values.file === undefined}
      submitButton={{
        onClick: async () => {
          await importUnits({
            tenantId: tenantId,
            projectId: projectId,
            file: values.file!,
          });
        },
      }}
      closeButton={{
        text: "Annuler",
        onClick: onCancel,
      }}
    >
      <Form id="upload-units-form">
        <FormContainer highlight withoutBorder>
          <Form.Group>
            <Button
              size="xs"
              disabled={downloadTemplateLoading || values.file !== undefined}
              appearance="primary"
              onClick={async () => {
                await downloadTemplate({
                  tenantId: tenantId,
                  projectId: projectId,
                  name: TemplateName.BULK_CREATE_UNITS,
                });
              }}
            >
              Télécharger le gabarit
            </Button>
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Fichier (.csv)</Form.ControlLabel>
            <Uploader
              accept=".csv"
              disabled={values.file !== undefined}
              onChange={(fileTypes) => {
                const fileType = fileTypes[0];
                if (fileType) {
                  setValues(
                    produce(values, (draft) => {
                      draft.file = fileType.blobFile;
                    })
                  );
                }
              }}
              onRemove={() => {
                setValues(
                  produce(values, (draft) => {
                    draft.file = undefined;
                  })
                );
              }}
            />
          </Form.Group>
        </FormContainer>
      </Form>
    </ModalForm>
  );
};
