import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { SearchInputGroup, Tag, ViewList, Text } from "buildingBlocks";
import { useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { PermissionName } from "graphql/schema";
import { ModelDeleteDialog } from "dialogs";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

const useProjectModelsViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback(
      (models: ReadonlyArray<ProjectProviderTypes.MappedProjectModel>) =>
        models
          .filter((model) => model.name.toSearchFormat().includes(search.toSearchFormat()))
          .map((model) => {
            const roomIds: string[] = [];
            const roomElementIds: string[] = [];
            const roomElementOptionIds: string[] = [];
            const productIds: string[] = [];
            for (const modelRoom of model.rooms) {
              roomIds.push(modelRoom.id);
              for (const modelRoomElement of modelRoom.elements) {
                roomElementIds.push(modelRoomElement.id);
                for (const modelRoomElementOption of modelRoomElement.options) {
                  roomElementOptionIds.push(modelRoomElementOption.id);
                  productIds.push(modelRoomElementOption.productId);
                }
              }
            }
            return {
              ...model,
              roomCount: roomIds.unique().length,
              roomElementCount: roomElementIds.unique().length,
              roomElementOptionCount: roomElementOptionIds.unique().length,
              productCount: productIds.unique().length,
            };
          }),
      [search]
    ),
  };
};

export const ProjectModelsView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useProjectModelsViewFilters();
  const { navigate, toProjectView, toProjectModelView, toProjectModelImportView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, models } = useProjectContext();
  const [modelToDelete, setModelToDelete] = useState<ProjectProviderTypes.MappedProjectModel | null>(null);
  return (
    <ViewList
      id="project-models-view"
      security={{
        permissions: [[PermissionName.MODEL_READ]],
      }}
      header={{
        title: "Tous les modèles",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              text: "Importer un modèle",
              show: models.length !== 0,
              security: {
                permissions: [[PermissionName.MODEL_IMPORT]],
              },
              onClick: () => {
                navigate(
                  toProjectModelImportView({
                    tenantId: tenant.id,
                    projectId: project.id,
                  })
                );
              },
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [<SearchInputGroup size="xs" placeholder="Recherche par nom" value={filterValues.search} onChange={filterFunctions.setSearch} />],
            applyButton: null,
          },
          right: {
            inputs: [],
          },
        },
      }}
      content={{
        table: {
          emptyBodies: [
            {
              show: models.length === 0,
              title: "Pas de modèle",
              content: "Pour téléverser un modèle, cliquez sur le bouton ci-dessous.",
              button: {
                text: "Importer un modèle",
                security: {
                  permissions: [[PermissionName.MODEL_IMPORT]],
                },
                onClick: () => {
                  navigate(
                    toProjectModelImportView({
                      tenantId: tenant.id,
                      projectId: project.id,
                    })
                  );
                },
              },
            },
          ],
          headColumns: [
            {
              name: "index",
              value: "#",
            },
            {
              name: "name",
              value: "NOM",
            },
            {
              name: "room-count",
              value: "NB PIÈCES",
            },
            {
              name: "element-count",
              value: "NB ÉLÉMENTS",
            },
            {
              name: "option-count",
              value: "NB OPTIONS",
            },
            {
              name: "product-count",
              value: "NB PRODUITS",
            },
          ],
          bodyRows: applyFilters(models).map((model, modelIndex) => {
            return {
              key: model.id,
              href: toProjectModelView({
                tenantId: tenant.id,
                projectId: project.id,
                modelId: model.id,
              }),
              columns: [
                {
                  name: "index",
                  component: <Tag className="model-index">{modelIndex + 1}</Tag>,
                },
                {
                  name: "name",
                  component: <Text highlight>{model.name}</Text>,
                },
                {
                  name: "room-count",
                  component: model.roomCount,
                },
                {
                  name: "element-count",
                  component: model.roomElementCount,
                },
                {
                  name: "option-count",
                  component: model.roomElementOptionCount,
                },
                {
                  name: "product-count",
                  component: model.productCount,
                },
              ],
              actions: [
                {
                  __type: "Button",
                  content: "Supprimer le modèle",
                  show: true,
                  security: {
                    permissions: [[PermissionName.MODEL_DELETE]],
                  },
                  onClick: () => {
                    setModelToDelete(model);
                  },
                },
              ],
            };
          }),
          bodyRowGroups: [],
        },
      }}
    >
      {modelToDelete && (
        <ModelDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          model={modelToDelete}
          onCompleted={() => {
            setModelToDelete(null);
          }}
          onClose={() => {
            setModelToDelete(null);
          }}
        />
      )}
    </ViewList>
  );
};
