import { useLoadModelMutation } from "graphql/mutations";
import { useProjectContractContext } from "hooks";
import { useProjectContext } from "providers/ProjectProvider";
import { useTenantContext } from "providers/TenantProvider";
import { createContext, FunctionComponent, useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { UnityConfig } from "react-unity-webgl";

type ContractSelectionGroupContextValue = {
  readonly unityConfig: UnityConfig | null;
};

const initialValue: ContractSelectionGroupContextValue = {
  unityConfig: null,
};

const ContractSelectionGroupContext = createContext<ContractSelectionGroupContextValue>(initialValue);

const useContractSelectionGroupContext = () => useContext(ContractSelectionGroupContext);

const ContractSelectionGroupProvider: FunctionComponent = () => {
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { contract } = useProjectContractContext();
  const [unityConfig, setUnityConfig] = useState<UnityConfig | null>(null);
  const { loadModel } = useLoadModelMutation(({ unityLoaderUrl, unityDataUrl, unityFrameworkUrl, unityCodeUrl }) => {
    setUnityConfig({
      loaderUrl: unityLoaderUrl,
      dataUrl: unityDataUrl,
      frameworkUrl: unityFrameworkUrl,
      codeUrl: unityCodeUrl,
    });
  });
  useEffect(() => {
    if (contract.form && contract.form.modelId) {
      loadModel({
        tenantId: tenant.id,
        projectId: project.id,
        modelId: contract.form.modelId,
      });
    }
    // eslint-disable-next-line
  }, []);
  return (
    <ContractSelectionGroupContext.Provider
      value={{
        unityConfig: unityConfig,
      }}
    >
      <Outlet />
    </ContractSelectionGroupContext.Provider>
  );
};

export { ContractSelectionGroupProvider, useContractSelectionGroupContext };
