import "./index.scss";
import { FunctionComponent } from "react";
import { SectionElementFormValues, SectionElementOptionFormValues, SectionFormValues } from "forms";
import { SectionElementFormPanel } from "components/SectionElementFormPanel";
import { SectionElementOptionType } from "graphql/schema";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly sectionElement: SectionElementFormValues;
  readonly values: SectionFormValues;
  readonly setValues: (values: SectionFormValues) => void;
  readonly model: ProjectProviderTypes.MappedProjectModel | null;
  readonly products: ReadonlyArray<ProjectProviderTypes.MappedProjectProduct>;
  readonly onSectionElementOptionCostAmountUpdated: (sectionElement: SectionElementFormValues, sectionElementOption: SectionElementOptionFormValues) => void;
};

export const SectionElementTypeReadonlyFormPanel: FunctionComponent<Props> = ({ sectionElement, values, setValues, model, products, onSectionElementOptionCostAmountUpdated }) => (
  <SectionElementFormPanel
    sectionElement={sectionElement}
    values={values}
    setValues={setValues}
    products={model ? products.filter((product) => !product.managedByModels || model.productIds.includes(product.id)) : products}
    isOptionalDisabled
    isManagedByVibesButtonDisabled
    isHideProductCategoryDisabled={false}
    isDeleteOptionDisabled={() => {
      return true;
    }}
    isAddOptionButtonDisabled={values.elements.find((element) => element.id === sectionElement.id)!.options.length === 1}
    sectionElementOptionTypes={[SectionElementOptionType.INCLUDED]}
    onSectionElementOptionCostAmountUpdated={onSectionElementOptionCostAmountUpdated}
  />
);
