import "./index.scss";
import { FunctionComponent, useState } from "react";

type Props = {
  readonly size: "sm" | "md" | "lg";
  readonly hasThumbnail: boolean;
  readonly thumbnailUrl: string;
  readonly preview?: string;
  readonly bordered?: boolean;
};

export const ProductThumbnail: FunctionComponent<Props> = ({ size, hasThumbnail, thumbnailUrl, bordered = false, preview = undefined }) => {
  const [isValid, setIsValid] = useState<boolean>(true);
  if (!hasThumbnail && preview === undefined) {
    return null;
  }
  if (isValid || preview !== undefined) {
    return (
      <img
        className={`product-thumbnail product-thumbnail-${size}${bordered ? " product-thumbnail-bordered" : ""}`}
        onError={() => {
          setIsValid(false);
        }}
        alt="Vignette du produit"
        src={preview ? preview : thumbnailUrl}
      />
    );
  }
  return <div className={`product-thumbnail product-thumbnail-${size}`}>La vignette est introuvable.</div>;
};
