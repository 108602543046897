import "./index.scss";
import { FunctionComponent, useState } from "react";
import { Panel, PanelControl, ViewDetail } from "buildingBlocks";
import { useRouting, useTenantSettingsUserContext } from "hooks";
import { MappedUser, useNotificationContext, useTenantContext } from "providers";
import { useResendUserInvitationMutation } from "graphql/mutations";
import { UserBlockDialog } from "dialogs/UserBlockDialog";
import { UserUnblockDialog } from "dialogs/UserUnblockDialog";

export const TenantSettingsUserView: FunctionComponent = () => {
  const { navigate, toTenantView, toTenantSettingsUsersView, toTenantSettingsUserUpdateView } = useRouting();
  const { tenant } = useTenantContext();
  const { user } = useTenantSettingsUserContext();
  const [userToBlock, setUserToBlock] = useState<MappedUser | null>(null);
  const [userToUnblock, setUserToUnblock] = useState<MappedUser | null>(null);
  const { push } = useNotificationContext();
  const { resendUserInvitation } = useResendUserInvitationMutation(
    ({ user }) => {
      push("Invitation renvoyé", `Une invitation a été renvoyé à l'adresse suivante: ${user.email}`, "success", "topCenter");
    },
    () => {},
    true
  );
  return (
    <ViewDetail
      id="tenant-settings-user-view"
      security={{
        isTenantSecurityManager: true,
      }}
      header={{
        title: user.fullName,
        subtitle: "Utilisateur",
        returnButton: {
          onClick: () => {
            navigate(
              toTenantSettingsUsersView({
                tenantId: tenant.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              appearance: "primary",
              text: "Modifier l'utilisateur",
              security: {
                isTenantSecurityManager: true,
              },
              show: true,
              onClick: () => {
                navigate(
                  toTenantSettingsUserUpdateView({
                    tenantId: tenant.id,
                    userId: user.id,
                  })
                );
              },
            },
            {
              __type: "Button",
              appearance: "default",
              text: "Fermer les paramètres",
              show: true,
              security: {},
              onClick: () => {
                navigate(
                  toTenantView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
          ],
          otherActions: [
            {
              __type: "Button",
              content: "Bloquer l'utilisateur",
              security: {
                isTenantSecurityManager: true,
              },
              show: !user.isBlocked,
              onClick: () => {
                setUserToBlock(user);
              },
            },
            {
              __type: "Button",
              content: "Débloquer l'utilisateur",
              security: {
                isTenantSecurityManager: true,
              },
              show: user.isBlocked,
              onClick: () => {
                setUserToUnblock(user);
              },
            },
            {
              __type: "Divider",
              show: true,
            },
            {
              __type: "Button",
              content: "Renvoyer une invitation",
              security: {
                isTenantSecurityManager: true,
              },
              show: true,
              onClick: async () => {
                await resendUserInvitation({
                  tenantId: tenant.id,
                  userId: user.id,
                });
              },
            },
          ],
        },
      }}
    >
      <Panel>
        <PanelControl label="Courriel" values={[user.email]} />
        <PanelControl
          label="Accès au(x) projet(s)"
          values={
            user.hasAccessToAllProjects
              ? ["Tous les projets"]
              : user.projects.length === 0 && !user.hasAccessToAllProjects
                ? ["--"]
                : user.projects.filter((project) => user.projects.map((project) => project.id).includes(project.id)).map((project) => project.label)
          }
        />
        <PanelControl label="Accès à l'organisation de test" values={[user.hasAccessToTestTenant ? "Oui" : "Non"]} />
        <PanelControl label="Bloqué" values={[user.isBlocked ? "Oui" : "Non"]} />
      </Panel>
      <Panel header={{ title: "Rôle" }}>
        <PanelControl label="Nom" values={[user.role.name]} />
        <PanelControl label="Description" values={[user.role.description]} />
        <PanelControl label="Gestionnaire du profil de l'organisation" values={[user.role.isTenantProfileManager ? "Oui" : "Non"]} />
        <PanelControl label="Gestionnaire de la sécurité de l'organisation" values={[user.role.isTenantSecurityManager ? "Oui" : "Non"]} />
        <PanelControl label="Gestionnaire de la comptabilité de l'organisation" values={[user.role.isTenantAccountingManager ? "Oui" : "Non"]} />
        <PanelControl label="Gestionnaire des projets de l'organisation" values={[user.role.isTenantProjectManager ? "Oui" : "Non"]} />
        <PanelControl label="Permissions" values={user.role.permissions.map((permission) => permission.displayName)} />
      </Panel>
      {userToBlock && (
        <UserBlockDialog
          tenantId={tenant.id}
          user={userToBlock}
          onCompleted={() => {
            setUserToBlock(null);
          }}
          onClose={() => {
            setUserToBlock(null);
          }}
        />
      )}
      {userToUnblock && (
        <UserUnblockDialog
          tenantId={tenant.id}
          user={userToUnblock}
          onCompleted={() => {
            setUserToUnblock(null);
          }}
          onClose={() => {
            setUserToUnblock(null);
          }}
        />
      )}
    </ViewDetail>
  );
};
