import "./index.scss";
import { FunctionComponent } from "react";
import { ViewDetail } from "buildingBlocks";
import { useRouting } from "hooks";
import { useTenantContext } from "providers";

export const TenantSettingsView: FunctionComponent = () => {
  const { tenant } = useTenantContext();
  const {
    navigate,
    toTenantView,
    toTenantSettingsProfileView,
    toTenantSettingsUsersView,
    toTenantSettingsRolesView,
    toTenantSettingsPermissionsView,
    toTenantSettingsTaxesView,
    toTenantSettingsTaxAgenciesView,
    toTenantSettingsTaxGroupsView,
  } = useRouting();
  return (
    <ViewDetail
      id="tenant-settings-view"
      security={{
        isTenantSettingsManager: true,
      }}
      header={{
        title: "Tous les paramètres",
        toolbar: {
          buttons: [
            {
              __type: "Button",
              appearance: "default",
              text: "Fermer les paramètres",
              show: true,
              security: {},
              onClick: () => {
                navigate(
                  toTenantView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
          ],
          otherActions: [],
        },
      }}
    >
      <div className="tenant-settings-panel">
        <div className="tenant-settings-header">Organisation</div>
        <div className="tenant-settings-body">
          <div
            onClick={() => {
              navigate(
                toTenantSettingsProfileView({
                  tenantId: tenant.id,
                })
              );
            }}
          >
            Profil
          </div>
        </div>
      </div>
      <div className="tenant-settings-panel">
        <div className="tenant-settings-header">Sécurité</div>
        <div className="tenant-settings-body">
          <div
            onClick={() => {
              navigate(
                toTenantSettingsUsersView({
                  tenantId: tenant.id,
                })
              );
            }}
          >
            Utilisateurs
          </div>
          <div
            onClick={() => {
              navigate(
                toTenantSettingsRolesView({
                  tenantId: tenant.id,
                })
              );
            }}
          >
            Rôles
          </div>
          <div
            onClick={() => {
              navigate(
                toTenantSettingsPermissionsView({
                  tenantId: tenant.id,
                })
              );
            }}
          >
            Permissions
          </div>
        </div>
      </div>
      <div className="tenant-settings-panel">
        <div className="tenant-settings-header">Taxes & conformité</div>
        <div className="tenant-settings-body">
          <div
            onClick={() => {
              navigate(
                toTenantSettingsTaxesView({
                  tenantId: tenant.id,
                })
              );
            }}
          >
            Taxes
          </div>
          <div
            onClick={() => {
              navigate(
                toTenantSettingsTaxGroupsView({
                  tenantId: tenant.id,
                })
              );
            }}
          >
            Groupes de taxes
          </div>
          <div
            onClick={() => {
              navigate(
                toTenantSettingsTaxAgenciesView({
                  tenantId: tenant.id,
                })
              );
            }}
          >
            Organismes fiscaux
          </div>
        </div>
      </div>
    </ViewDetail>
  );
};
