import "./index.scss";
import { FunctionComponent, PropsWithChildren, ReactNode } from "react";

type Props = PropsWithChildren & {
  readonly label: string;
  readonly values: ReadonlyArray<ReactNode>;
};

export const PanelControl: FunctionComponent<Props> = ({ label, values }) => (
  <div className="panel-control">
    <span className="panel-control-label">{label}</span>
    {values.length === 0 ? (
      <span className="panel-control-value">--</span>
    ) : (
      <div className="panel-control-list">
        {values.map((value, index) => (
          <div key={index} className="panel-control-list-item">
            {value}
          </div>
        ))}
      </div>
    )}
  </div>
);
