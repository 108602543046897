import "./index.scss";
import React from "react";
import { FunctionComponent, PropsWithChildren } from "react";
import { Button } from "rsuite";
import { useSecurityContext } from "providers";
import { Types } from "types";
import { useOverflowObserver } from "hooks";

type Props = PropsWithChildren & {
  readonly emptyBodies?: ReadonlyArray<Types.TableEmptyBody>;
  readonly onResize?: (isOverflowed: boolean) => void;
};

export const TableBody: FunctionComponent<Props> = ({ children, onResize, emptyBodies }) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  useOverflowObserver(ref, onResize);
  const { isAuthorized } = useSecurityContext();
  const emptyBody = emptyBodies?.filter((emptyBody) => emptyBody.show)[0] ?? undefined;
  const isUserAuthorized = emptyBody === undefined || emptyBody.button === undefined || isAuthorized({ security: emptyBody.button.security });
  return (
    <div ref={ref} className={`table-body${emptyBody && emptyBody.show ? " table-body-is-empty" : ""}`}>
      {emptyBody && emptyBody.show && (
        <div className="table-body-empty">
          <h2>{emptyBody.title}</h2>
          <p>{isUserAuthorized ? emptyBody.content : "Vous n'avez pas les permissions requises pour gérer cette liste. Veuillez contacter un administrateur pour obtenir les accès."}</p>
          {isUserAuthorized && emptyBody.button && (
            <Button size="lg" appearance="primary" onClick={emptyBody.button.onClick}>
              {emptyBody.button.text}
            </Button>
          )}
        </div>
      )}
      {children}
    </div>
  );
};
