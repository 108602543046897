import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { SearchInputGroup, ViewList, Text } from "buildingBlocks";
import { useRouting } from "hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { MappedRole, useTenantContext } from "providers";

const useTenantSettingsRolesViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback((roles: ReadonlyArray<MappedRole>) => roles.filter((role) => role.name.toSearchFormat().includes(search.toSearchFormat())), [search]),
  };
};

export const TenantSettingsRolesView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useTenantSettingsRolesViewFilters();
  const { navigate, toTenantView, toTenantSettingsView, toTenantSettingsRoleView } = useRouting();
  const { tenant, roles } = useTenantContext();
  return (
    <ViewList
      id="tenant-settings-roles-view"
      security={{
        isTenantSecurityManager: true,
      }}
      header={{
        title: "Tous les rôles",
        returnButton: {
          onClick: () => {
            navigate(
              toTenantSettingsView({
                tenantId: tenant.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              appearance: "default",
              text: "Fermer les paramètres",
              show: true,
              security: {},
              onClick: () => {
                navigate(
                  toTenantView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [<SearchInputGroup size="xs" placeholder="Recherche par nom" value={filterValues.search} onChange={filterFunctions.setSearch} />],
            applyButton: null,
          },
          right: {
            inputs: [],
          },
        },
      }}
      content={{
        table: {
          headColumns: [
            {
              name: "name",
              value: "NOM",
            },
            {
              name: "description",
              value: "DESCRIPTION",
            },
          ],
          bodyRows: applyFilters(roles).map((role) => {
            return {
              key: role.id,
              href: toTenantSettingsRoleView({
                tenantId: tenant.id,
                roleId: role.id,
              }),
              columns: [
                {
                  name: "name",
                  component: (
                    <div className="role-name">
                      {role.isLocked && <FontAwesomeIcon icon={faLock} />}
                      <Text highlight>{role.name}</Text>
                    </div>
                  ),
                },
                {
                  name: "description",
                  component: role.description,
                },
              ],
              actions: [],
            };
          }),
          bodyRowGroups: [],
        },
      }}
    />
  );
};
