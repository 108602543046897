import "./index.scss";
import { FunctionComponent, useState } from "react";
import { Form, Input, InputNumber, SelectPicker } from "rsuite";
import { SectionElementOptionType, SectionVibeMode } from "graphql/schema";
import { FormContainer, Table, TableBody, TableColumn, TableContent, TableHead, TableRow } from "buildingBlocks";
import produce from "immer";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type SectionVibeFormValues = {
  readonly name: string;
  readonly mode: SectionVibeMode;
  readonly clientAmount: number;
  readonly costAmount: number;
  readonly contingencyAmount: number;
  readonly items: ReadonlyArray<{
    readonly id: string;
    readonly index: number;
    readonly sectionElementId: string;
    readonly sectionElementOptionId: string;
    readonly subcontractorAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
  }>;
};

type Props = {
  readonly values: SectionVibeFormValues;
  readonly setValues: (values: SectionVibeFormValues) => void;
  readonly section: ProjectProviderTypes.MappedProjectSection;
};

export const useSectionVibeForm = (initialValues: SectionVibeFormValues) => {
  const [values, setValues] = useState<SectionVibeFormValues>(initialValues);
  return { values, setValues };
};

export const SectionVibeForm: FunctionComponent<Props> = ({ values, setValues, section }) => (
  <Form id="section-vibe-form">
    <FormContainer highlight>
      <Form.Group>
        <Form.ControlLabel>Nom</Form.ControlLabel>
        <Input
          size="sm"
          value={values.name}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.name = value;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Mode</Form.ControlLabel>
        <SelectPicker
          size="sm"
          value={values.mode}
          cleanable={false}
          searchable={false}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.mode = value!;
                draft.clientAmount = 0;
                draft.costAmount = 0;
                draft.contingencyAmount = 0;
                draft.items = draft.items.map((draftItem) => ({
                  id: draftItem.id,
                  index: draftItem.index,
                  sectionElementId: draftItem.sectionElementId,
                  sectionElementOptionId: "",
                  subcontractorAmount: 0,
                  contractorAmount: 0,
                  promoterAmount: 0,
                }));
              })
            );
          }}
          data={[
            {
              label: "Standard",
              value: SectionVibeMode.STANDARD,
            },
            {
              label: "Upgrade",
              value: SectionVibeMode.UPGRADE,
            },
          ]}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Montant du client</Form.ControlLabel>
        <InputNumber
          size="sm"
          value={values.clientAmount}
          min={0}
          readOnly={values.mode === SectionVibeMode.STANDARD}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.clientAmount = Number(value);
                draft.contingencyAmount = draft.clientAmount - draft.costAmount;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Montant du coût</Form.ControlLabel>
        <Input size="sm" value={values.costAmount.toCurrencyFormat()} readOnly />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Montant de contingence</Form.ControlLabel>
        <Input size="sm" value={values.contingencyAmount.toCurrencyFormat()} readOnly />
      </Form.Group>
    </FormContainer>
    <FormContainer
      fluid
      header={{
        title: "Éléments",
        actions: [],
      }}
      withPadding
    >
      <Table fluid size="sm">
        <TableContent>
          <TableHead>
            <TableRow>
              <TableColumn name="index">#</TableColumn>
              <TableColumn name="section-element">Nom</TableColumn>
              <TableColumn name="section-element-option">Option</TableColumn>
              <TableColumn name="section-element-option-type">Type</TableColumn>
              <TableColumn name="subcontractor-amount">Montant sout-traitant</TableColumn>
              <TableColumn name="contractor-amount">Montant entrepreneur</TableColumn>
              <TableColumn name="promoter-amount">Montant promoteur</TableColumn>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.items.map((sectionVibeItem) => {
              const sectionElement = section.getElementsWithoutSets().find((sectionElement) => sectionElement.id === sectionVibeItem.sectionElementId)!;
              let sectionElementOptions = sectionElement.getOptionsWithoutSets();
              if (values.mode === SectionVibeMode.STANDARD) {
                sectionElementOptions = sectionElementOptions.filter((sectionElementOption) => sectionElementOption.type === SectionElementOptionType.MIX_AND_MATCH);
              }
              if (values.mode === SectionVibeMode.UPGRADE) {
                sectionElementOptions = sectionElementOptions.filter(
                  (sectionElementOption) => sectionElementOption.type === SectionElementOptionType.MIX_AND_MATCH || sectionElementOption.type === SectionElementOptionType.EXTRA
                );
              }
              const sectionElementOption = sectionElementOptions.find((sectionElementOption) => sectionElementOption.id === sectionVibeItem.sectionElementOptionId);
              return (
                <TableRow key={sectionVibeItem.id}>
                  <TableColumn name="index">{sectionVibeItem.index + 1}</TableColumn>
                  <TableColumn name="section-element">{sectionElement.label}</TableColumn>
                  <TableColumn name="section-element-option">
                    <SelectPicker
                      size="sm"
                      cleanable={false}
                      searchable={false}
                      value={sectionVibeItem.sectionElementOptionId}
                      onChange={(value) => {
                        setValues(
                          produce(values, (draft) => {
                            const draftSectionVibeItem = draft.items.find((draftSectionVibeItem) => draftSectionVibeItem.id === sectionVibeItem.id);
                            if (draftSectionVibeItem) {
                              const sectionElementOption = sectionElementOptions.find((sectionElementOption) => sectionElementOption.id === value!)!;
                              draftSectionVibeItem.sectionElementOptionId = sectionElementOption.id;
                              draftSectionVibeItem.subcontractorAmount = sectionElementOption.subcontractorAmount;
                              draftSectionVibeItem.contractorAmount = sectionElementOption.contractorAmount;
                              draftSectionVibeItem.promoterAmount = sectionElementOption.promoterAmount;
                              draft.costAmount = draft.items.reduce(
                                (previousValue, currentValue) => previousValue + currentValue.subcontractorAmount + currentValue.contractorAmount + currentValue.promoterAmount,
                                0
                              );
                              draft.contingencyAmount = draft.clientAmount - draft.costAmount;
                            }
                          })
                        );
                      }}
                      data={sectionElementOptions.map((sectionElementOption) => ({
                        label: sectionElementOption.product.name,
                        value: sectionElementOption.id,
                      }))}
                    />
                  </TableColumn>
                  <TableColumn name="section-element-option-type">{sectionElementOption?.type ?? "--"}</TableColumn>
                  <TableColumn name="subcontractor-amount">
                    <Input
                      size="sm"
                      readOnly
                      value={sectionVibeItem.subcontractorAmount.toCurrencyFormat()}
                      onChange={(value) => {
                        setValues(
                          produce(values, (draft) => {
                            const draftSectionVibeItem = draft.items.find((draftSectionVibeItem) => draftSectionVibeItem.id === sectionVibeItem.id);
                            if (draftSectionVibeItem) {
                              draftSectionVibeItem.subcontractorAmount = Number(value);
                              draft.costAmount = draft.items.reduce(
                                (previousValue, currentValue) => previousValue + currentValue.subcontractorAmount + currentValue.contractorAmount + currentValue.promoterAmount,
                                0
                              );
                              draft.contingencyAmount = draft.clientAmount - draft.costAmount;
                            }
                          })
                        );
                      }}
                    />
                  </TableColumn>
                  <TableColumn name="contractor-amount">
                    <Input
                      size="sm"
                      readOnly
                      value={sectionVibeItem.contractorAmount.toCurrencyFormat()}
                      onChange={(value) => {
                        setValues(
                          produce(values, (draft) => {
                            const draftSectionVibeItem = draft.items.find((draftSectionVibeItem) => draftSectionVibeItem.id === sectionVibeItem.id);
                            if (draftSectionVibeItem) {
                              draftSectionVibeItem.contractorAmount = Number(value);
                              draft.costAmount = draft.items.reduce(
                                (previousValue, currentValue) => previousValue + currentValue.subcontractorAmount + currentValue.contractorAmount + currentValue.promoterAmount,
                                0
                              );
                              draft.contingencyAmount = draft.clientAmount - draft.costAmount;
                            }
                          })
                        );
                      }}
                    />
                  </TableColumn>
                  <TableColumn name="promoter-amount">
                    <Input
                      size="sm"
                      readOnly
                      value={sectionVibeItem.promoterAmount.toCurrencyFormat()}
                      onChange={(value) => {
                        setValues(
                          produce(values, (draft) => {
                            const draftSectionVibeItem = draft.items.find((draftSectionVibeItem) => draftSectionVibeItem.id === sectionVibeItem.id);
                            if (draftSectionVibeItem) {
                              draftSectionVibeItem.promoterAmount = Number(value);
                              draft.costAmount = draft.items.reduce(
                                (previousValue, currentValue) => previousValue + currentValue.subcontractorAmount + currentValue.contractorAmount + currentValue.promoterAmount,
                                0
                              );
                              draft.contingencyAmount = draft.clientAmount - draft.costAmount;
                            }
                          })
                        );
                      }}
                    />
                  </TableColumn>
                </TableRow>
              );
            })}
          </TableBody>
        </TableContent>
      </Table>
    </FormContainer>
  </Form>
);
