import "./index.scss";
import { Whisper, Tooltip } from "rsuite";
import { FunctionComponent, PropsWithChildren, ReactNode } from "react";

type Props = PropsWithChildren & {
  readonly tooltip: ReactNode;
  readonly highlight?: boolean;
};

export const TooltipWrapper: FunctionComponent<Props> = ({ children, tooltip, highlight = false }) => (
  <Whisper trigger="hover" placement="autoVertical" speaker={<Tooltip>{tooltip}</Tooltip>}>
    <button className={`tooltip${highlight ? " tooltip-highlight" : ""}`}>{children}</button>
  </Whisper>
);
