import { useContractSelectionGroupContext } from "providers/ContractSelectionGroupProvider";
import { createContext, FunctionComponent, useContext } from "react";
import { Outlet } from "react-router-dom";
import { UnityConfig, useUnityContext } from "react-unity-webgl";
import { UnityContextHook } from "react-unity-webgl/distribution/types/unity-context-hook";

type ContractSelectionGroupFormContextValue = {
  readonly unityContextHook: UnityContextHook | null;
};

const initialValue: ContractSelectionGroupFormContextValue = {
  unityContextHook: null,
};

const ContractSelectionGroupFormContext = createContext<ContractSelectionGroupFormContextValue>(initialValue);

const useContractSelectionGroupFormContext = () => useContext(ContractSelectionGroupFormContext);

const ContractSelectionGroupFormProvider: FunctionComponent = () => {
  const { unityConfig } = useContractSelectionGroupContext();
  if (unityConfig === null) {
    return (
      <ContractSelectionGroupFormContext.Provider
        value={{
          unityContextHook: null,
        }}
      >
        <Outlet />
      </ContractSelectionGroupFormContext.Provider>
    );
  }
  return <Component unityConfig={unityConfig} />;
};

const Component: FunctionComponent<{
  readonly unityConfig: UnityConfig;
}> = ({ unityConfig }) => {
  const unityContextHook = useUnityContext(unityConfig);
  return (
    <ContractSelectionGroupFormContext.Provider
      value={{
        unityContextHook: unityContextHook,
      }}
    >
      <Outlet />
    </ContractSelectionGroupFormContext.Provider>
  );
};

export { ContractSelectionGroupFormProvider, useContractSelectionGroupFormContext };
