import "./index.scss";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FormContainer, FormPanel } from "buildingBlocks";
import { SectionElementOptionType, SectionElementType } from "graphql/schema";
import produce from "immer";
import { SectionElementAddModal } from "./_modals/SectionElementAddModal";
import { SectionElementOptionEditModal } from "./_modals/SectionElementOptionEditModal";
import { FunctionComponent, useCallback, useState } from "react";
import { Form, Input, InputGroup, SelectPicker } from "rsuite";
import { Guid } from "utils";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";
import { SectionElementTypeBooleanFormPanel } from "./_components/SectionElementTypeBooleanFormPanel";
import { SectionElementTypeReadonlyFormPanel } from "./_components/SectionElementTypeReadonlyFormPanel";
import { SectionElementTypeUniqueFormPanel } from "./_components/SectionElementTypeUniqueFormPanel";

export type SectionElementOptionFormValues = {
  readonly id: string;
  readonly index: number;
  readonly type: SectionElementOptionType;
  readonly clientAmount: number;
  readonly productId: string;
  readonly modelLink: {
    readonly modelId: string;
    readonly modelRoomId: string;
    readonly modelRoomElementId: string;
    readonly modelRoomElementOptionId: string;
  } | null;
  readonly breakdownLines: ReadonlyArray<{
    readonly activityId: string;
    readonly subcontractorAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
  }>;
  readonly isDeleted: boolean;
};

export type SectionElementFormValues = {
  readonly id: string;
  readonly index: number;
  readonly type: SectionElementType;
  readonly label: string;
  readonly notes: string | null;
  readonly isOptional: boolean;
  readonly isManagedByVibes: boolean;
  readonly hideProductCategory: boolean;
  readonly groupId: string | null;
  readonly isDeleted: boolean;
  readonly modelLink: {
    readonly modelId: string;
    readonly modelRoomId: string;
    readonly modelRoomElementId: string;
  } | null;
  readonly options: ReadonlyArray<SectionElementOptionFormValues>;
};

export type SectionFormValues = {
  readonly title: string;
  readonly modelLink: {
    readonly modelId: string;
    readonly modelRoomId: string;
  } | null;
  readonly elementGroups: ReadonlyArray<{
    readonly id: string;
    readonly index: number;
    readonly name: string;
    readonly isDeleted: boolean;
  }>;
  readonly elements: ReadonlyArray<SectionElementFormValues>;
};

type Props = {
  readonly values: SectionFormValues;
  readonly setValues: (values: SectionFormValues) => void;
  readonly models: ReadonlyArray<ProjectProviderTypes.MappedProjectModel>;
  readonly activities: ReadonlyArray<ProjectProviderTypes.MappedProjectActivity>;
  readonly products: ReadonlyArray<ProjectProviderTypes.MappedProjectProduct>;
};

export const useSectionForm = (initialValues: SectionFormValues) => {
  const [values, setValues] = useState<SectionFormValues>(initialValues);
  return { values, setValues };
};

export const SectionForm: FunctionComponent<Props> = ({ values, setValues, models, activities, products }) => {
  const [modelId, setModelId] = useState<string | null>(values.modelLink ? values.modelLink.modelId : null);
  const [modelRoomId, setModelRoomId] = useState<string | null>(values.modelLink ? values.modelLink.modelRoomId : null);
  const model = models.find((model) => model.id === modelId) ?? null;
  const modelRoom = model?.rooms.find((modelRoom) => modelRoom.id === modelRoomId) ?? null;
  const [showAddSectionElementModelForm, setShowAddSectionElementModelForm] = useState<boolean>(false);
  const [showEditSectionElementOptionCostAmountModelForm, setShowEditSectionElementOptionCostAmountModelForm] = useState<{
    readonly sectionElement: SectionElementFormValues;
    readonly sectionElementOption: SectionElementOptionFormValues;
  } | null>(null);
  const onSectionElementOptionCostAmountUpdated = useCallback(
    (sectionElement: SectionElementFormValues, sectionElementOption: SectionElementOptionFormValues) => {
      setShowEditSectionElementOptionCostAmountModelForm({
        sectionElement: sectionElement,
        sectionElementOption: sectionElementOption,
      });
    },
    [setShowEditSectionElementOptionCostAmountModelForm]
  );
  return (
    <Form id="section-form">
      <FormContainer highlight>
        <Form.Group>
          <Form.ControlLabel>Titre</Form.ControlLabel>
          <Input
            value={values.title}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.title = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Pièce du modèle (optionnel)</Form.ControlLabel>
          {values.modelLink && model && modelRoom ? (
            <Input value={`${model.name} - ${modelRoom.name}`} readOnly />
          ) : (
            <InputGroup>
              <SelectPicker
                value={modelId}
                onChange={setModelId}
                data={models.map((model) => ({
                  label: model.name,
                  value: model.id,
                }))}
              />
              <SelectPicker
                value={modelRoomId}
                onChange={setModelRoomId}
                data={
                  model?.rooms.map((modelRoom) => ({
                    label: modelRoom.name,
                    value: modelRoom.id,
                  })) ?? []
                }
              />
              <InputGroup.Button
                onClick={() => {
                  setValues(
                    produce(values, (draft) => {
                      if (model && modelRoom) {
                        draft.title = modelRoom.name;
                        draft.modelLink = {
                          modelId: model.id,
                          modelRoomId: modelRoom.id,
                        };
                        let index = values.elements.length;
                        for (const modelRoomElement of modelRoom.elements) {
                          draft.elements.push({
                            id: Guid.newGuid(),
                            index: index,
                            type: SectionElementType.UNIQUE,
                            label: modelRoomElement.name,
                            notes: "",
                            isManagedByVibes: true,
                            isOptional: false,
                            hideProductCategory: false,
                            groupId: null,
                            modelLink: {
                              modelId: model.id,
                              modelRoomId: modelRoom.id,
                              modelRoomElementId: modelRoomElement.id,
                            },
                            isDeleted: false,
                            options: modelRoomElement.options.map((modelRoomElementOption) => {
                              const product = products.find((product) => product.id === modelRoomElementOption.productId)!;
                              return {
                                id: Guid.newGuid(),
                                index: modelRoomElementOption.index,
                                type: SectionElementOptionType.MIX_AND_MATCH,
                                clientAmount: 0,
                                productId: modelRoomElementOption.productId,
                                modelLink: {
                                  modelId: model.id,
                                  modelRoomId: modelRoom.id,
                                  modelRoomElementId: modelRoomElement.id,
                                  modelRoomElementOptionId: modelRoomElementOption.id,
                                },
                                breakdownLines: [...product.activities]
                                  .sort((left, right) => left.index - right.index)
                                  .map(({ activityId }) => ({
                                    id: Guid.newGuid(),
                                    activityId: activityId,
                                    subcontractorAmount: 0,
                                    contractorAmount: 0,
                                    promoterAmount: 0,
                                  })),
                                isDeleted: false,
                              };
                            }),
                          });
                          index++;
                        }
                      }
                    })
                  );
                }}
              >
                Appliquer
              </InputGroup.Button>
            </InputGroup>
          )}
        </Form.Group>
      </FormContainer>
      <FormContainer
        fluid
        empty={{
          show: values.elementGroups.length === 0,
          label: "Aucun groupe d'élément",
          content: "Pour créer un groupe d'élément cliquez sur le bouton 'Ajouter un groupe d'élément' en haut à droite.",
        }}
        header={{
          title: "Groupes d'éléments",
          actions: [
            {
              name: "Ajouter un groupe d'élément",
              icon: faAdd,
              disabled: false,
              onClick: () => {
                setValues(
                  produce(values, (draft) => {
                    draft.elementGroups.push({
                      id: Guid.newGuid(),
                      index: draft.elementGroups.length,
                      name: "",
                      isDeleted: false,
                    });
                  })
                );
              },
            },
          ],
        }}
      >
        {[...values.elementGroups]
          .sort((left, right) => left.index - right.index)
          .map((sectionElementGroup) => (
            <FormPanel
              key={sectionElementGroup.id}
              header={{
                start: {
                  index: sectionElementGroup.index,
                  buttons: {
                    increase: {
                      disabled: sectionElementGroup.index === 0,
                      onClick: () => {
                        setValues(
                          produce(values, (draft) => {
                            const draftElementGroupToIncrease = draft.elementGroups.find((draftElementGroup) => draftElementGroup.index === sectionElementGroup.index);
                            const draftElementGroupToDecrease = draft.elementGroups.find((draftElementGroup) => draftElementGroup.index === sectionElementGroup.index - 1);
                            if (draftElementGroupToIncrease && draftElementGroupToDecrease) {
                              draftElementGroupToIncrease.index = draftElementGroupToIncrease.index - 1;
                              draftElementGroupToDecrease.index = draftElementGroupToDecrease.index + 1;
                            }
                          })
                        );
                      },
                    },
                    decrease: {
                      disabled: sectionElementGroup.index === values.elementGroups.length - 1,
                      onClick: () => {
                        setValues(
                          produce(values, (draft) => {
                            const draftElementGroupToDecrease = draft.elementGroups.find((draftElementGroup) => draftElementGroup.index === sectionElementGroup.index);
                            const draftElementGroupToIncrease = draft.elementGroups.find((draftElementGroup) => draftElementGroup.index === sectionElementGroup.index + 1);
                            if (draftElementGroupToIncrease && draftElementGroupToDecrease) {
                              draftElementGroupToIncrease.index = draftElementGroupToIncrease.index - 1;
                              draftElementGroupToDecrease.index = draftElementGroupToDecrease.index + 1;
                            }
                          })
                        );
                      },
                    },
                  },
                },
                center: {
                  inputs: [
                    {
                      name: "name",
                      label: "Nom",
                      node: (
                        <Input
                          size="sm"
                          value={sectionElementGroup.name}
                          onChange={(value) => {
                            setValues(
                              produce(values, (draft) => {
                                const draftElementGroup = draft.elementGroups.find((draftElementGroup) => draftElementGroup.id === sectionElementGroup.id);
                                if (draftElementGroup) {
                                  draftElementGroup.name = value;
                                }
                              })
                            );
                          }}
                        />
                      ),
                    },
                  ],
                },
                end: {
                  buttons: [
                    {
                      name: "Supprimer le groupe d'élément",
                      onClick: () => {
                        setValues(
                          produce(values, (draft) => {
                            draft.elementGroups = draft.elementGroups.filter((draftElementGroup) => draftElementGroup.id !== sectionElementGroup.id);
                          })
                        );
                      },
                    },
                  ],
                },
              }}
            ></FormPanel>
          ))}
      </FormContainer>
      <FormContainer
        fluid
        empty={{
          show: values.elements.length === 0,
          label: "Aucun élément",
          content: "Pour créer un élément cliquez sur le bouton 'Ajouter un élément' en haut à droite.",
        }}
        header={{
          title: "Éléments",
          actions: [
            {
              name: "Ajouter un élément",
              icon: faAdd,
              disabled: false,
              onClick: () => {
                setShowAddSectionElementModelForm(true);
              },
            },
          ],
        }}
      >
        {[...values.elements]
          .sort((left, right) => left.index - right.index)
          .map((sectionElement) => {
            switch (sectionElement.type) {
              case SectionElementType.BOOLEAN: {
                return (
                  <SectionElementTypeBooleanFormPanel
                    key={sectionElement.id}
                    sectionElement={sectionElement}
                    values={values}
                    setValues={setValues}
                    products={products}
                    onSectionElementOptionCostAmountUpdated={onSectionElementOptionCostAmountUpdated}
                  />
                );
              }
              case SectionElementType.UNIQUE: {
                return (
                  <SectionElementTypeUniqueFormPanel
                    key={sectionElement.id}
                    sectionElement={sectionElement}
                    values={values}
                    setValues={setValues}
                    model={model}
                    products={products}
                    onSectionElementOptionCostAmountUpdated={onSectionElementOptionCostAmountUpdated}
                  />
                );
              }
              case SectionElementType.READONLY: {
                return (
                  <SectionElementTypeReadonlyFormPanel
                    key={sectionElement.id}
                    sectionElement={sectionElement}
                    values={values}
                    setValues={setValues}
                    model={model}
                    products={products}
                    onSectionElementOptionCostAmountUpdated={onSectionElementOptionCostAmountUpdated}
                  />
                );
              }
              default: {
                throw new Error("Le type d'élément n'existe pas.");
              }
            }
          })}
      </FormContainer>
      {showAddSectionElementModelForm && (
        <SectionElementAddModal
          onAdd={({ type }) => {
            switch (type) {
              case SectionElementType.UNIQUE: {
                setValues(
                  produce(values, (draft) => {
                    draft.elements.push({
                      id: Guid.newGuid(),
                      index: values.elements.length,
                      type: type,
                      label: "",
                      notes: "",
                      isOptional: false,
                      isManagedByVibes: false,
                      hideProductCategory: false,
                      groupId: null,
                      modelLink: null,
                      isDeleted: false,
                      options: [
                        {
                          id: Guid.newGuid(),
                          index: 0,
                          type: SectionElementOptionType.INCLUDED,
                          clientAmount: 0,
                          productId: "",
                          modelLink: null,
                          breakdownLines: [],
                          isDeleted: false,
                        },
                      ],
                    });
                  })
                );
                break;
              }
              case SectionElementType.BOOLEAN: {
                setValues(
                  produce(values, (draft) => {
                    draft.elements.push({
                      id: Guid.newGuid(),
                      index: values.elements.length,
                      type: type,
                      label: "",
                      notes: "",
                      isOptional: true,
                      isManagedByVibes: false,
                      hideProductCategory: false,
                      groupId: null,
                      modelLink: null,
                      isDeleted: false,
                      options: [
                        {
                          id: Guid.newGuid(),
                          index: 0,
                          type: SectionElementOptionType.INCLUDED,
                          clientAmount: 0,
                          productId: "",
                          modelLink: null,
                          breakdownLines: [],
                          isDeleted: false,
                        },
                      ],
                    });
                  })
                );
                break;
              }
              case SectionElementType.READONLY: {
                setValues(
                  produce(values, (draft) => {
                    draft.elements.push({
                      id: Guid.newGuid(),
                      index: values.elements.length,
                      type: type,
                      label: "",
                      notes: "",
                      isOptional: false,
                      isManagedByVibes: false,
                      hideProductCategory: false,
                      groupId: null,
                      modelLink: null,
                      isDeleted: false,
                      options: [
                        {
                          id: Guid.newGuid(),
                          index: 0,
                          type: SectionElementOptionType.INCLUDED,
                          clientAmount: 0,
                          productId: "",
                          modelLink: null,
                          breakdownLines: [],
                          isDeleted: false,
                        },
                      ],
                    });
                  })
                );
                break;
              }
              default: {
                throw new Error("Le type de l'élément n'existe pas.");
              }
            }
            setShowAddSectionElementModelForm(false);
          }}
          onCancel={() => {
            setShowAddSectionElementModelForm(false);
          }}
        />
      )}
      {showEditSectionElementOptionCostAmountModelForm && (
        <SectionElementOptionEditModal
          sectionElement={showEditSectionElementOptionCostAmountModelForm.sectionElement}
          sectionElementOption={showEditSectionElementOptionCostAmountModelForm.sectionElementOption}
          activities={activities}
          products={products}
          onEdit={(sectionElement, sectionElementOption, costAmountValues) => {
            setValues(
              produce(values, (draft) => {
                const draftElement = draft.elements.find((draftElement) => draftElement.id === sectionElement.id);
                if (draftElement) {
                  const draftElementOption = draftElement.options.find((draftElementOption) => draftElementOption.id === sectionElementOption.id);
                  if (draftElementOption) {
                    draftElementOption.clientAmount = costAmountValues.clientAmount;
                    draftElementOption.breakdownLines = costAmountValues.breakdownLines;
                  }
                }
              })
            );
            setShowEditSectionElementOptionCostAmountModelForm(null);
          }}
          onCancel={() => {
            setShowEditSectionElementOptionCostAmountModelForm(null);
          }}
        />
      )}
    </Form>
  );
};
