import "./index.scss";
import { FunctionComponent, PropsWithChildren } from "react";

type Props = PropsWithChildren & {
  readonly id: string;
};

export const ViewTab: FunctionComponent<Props> = ({ children, id }) => (
  <section id={id} className="view-tab">
    {children}
  </section>
);
