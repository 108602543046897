import "./index.scss";
import { FormContainer } from "buildingBlocks/FormContainer";
import { Fragment, FunctionComponent, useState } from "react";
import { Form, Input, SelectPicker } from "rsuite";
import produce from "immer";
import { CountryFragment } from "graphql/schema";

type ProjectFormValues = {
  readonly code: string;
  readonly name: string;
  readonly description: string;
  readonly contactInfo: {
    readonly companyName: string;
    readonly email: string;
    readonly phone: string;
    readonly websiteUrl: string;
    readonly federalTaxNumber: string;
    readonly provincialTaxNumber: string;
    readonly address: {
      readonly line: string;
      readonly city: string;
      readonly subdivisionCode: string;
      readonly countryCode: string;
      readonly postalCode: string;
    };
  };
};

type Props = {
  readonly values: ProjectFormValues;
  readonly setValues: (values: ProjectFormValues) => void;
  readonly countries: ReadonlyArray<CountryFragment>;
};

export const useProjectForm = (initialValues: ProjectFormValues) => {
  const [values, setValues] = useState<ProjectFormValues>(initialValues);
  return { values, setValues };
};

export const ProjectForm: FunctionComponent<Props> = ({ values, setValues, countries }) => {
  const country = countries.find((country) => country.code === values.contactInfo.address.countryCode);
  return (
    <Form id="project-form">
      <FormContainer highlight>
        <Form.Group>
          <Form.ControlLabel>Code</Form.ControlLabel>
          <Input
            value={values.code}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.code = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Nom</Form.ControlLabel>
          <Input
            value={values.name}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.name = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Description</Form.ControlLabel>
          <Input
            as="textarea"
            rows={3}
            value={values.description}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.description = value;
                })
              );
            }}
          />
        </Form.Group>
      </FormContainer>
      <FormContainer
        header={{
          title: "Information de contact",
          actions: [],
        }}
      >
        <Form.Group>
          <Form.ControlLabel>Nom de la société</Form.ControlLabel>
          <Input
            value={values.contactInfo.companyName}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.contactInfo.companyName = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Courriel</Form.ControlLabel>
          <Input
            value={values.contactInfo.email}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.contactInfo.email = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Téléphone</Form.ControlLabel>
          <Input
            value={values.contactInfo.phone}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.contactInfo.phone = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Site web</Form.ControlLabel>
          <Input
            value={values.contactInfo.websiteUrl}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.contactInfo.websiteUrl = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Numéro de taxe fédéral</Form.ControlLabel>
          <Input
            value={values.contactInfo.federalTaxNumber}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.contactInfo.federalTaxNumber = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Numéro de taxe provinciale</Form.ControlLabel>
          <Input
            value={values.contactInfo.provincialTaxNumber}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.contactInfo.provincialTaxNumber = value;
                })
              );
            }}
          />
        </Form.Group>
        <FormContainer
          header={{
            title: "Adresse",
            actions: [],
          }}
        >
          <Form.Group>
            <Form.ControlLabel>Pays</Form.ControlLabel>
            <SelectPicker
              value={values.contactInfo.address.countryCode}
              onChange={(value) => {
                setValues(
                  produce(values, (draft) => {
                    draft.contactInfo.address.countryCode = value!;
                  })
                );
              }}
              cleanable={false}
              data={countries.map((country) => ({
                label: country.name,
                value: country.code,
              }))}
            />
          </Form.Group>
          {country && (
            <Fragment>
              <Form.Group>
                <Form.ControlLabel>Ligne</Form.ControlLabel>
                <Input
                  value={values.contactInfo.address.line}
                  onChange={(value) => {
                    setValues(
                      produce(values, (draft) => {
                        draft.contactInfo.address.line = value;
                      })
                    );
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Ville</Form.ControlLabel>
                <Input
                  value={values.contactInfo.address.city}
                  onChange={(value) => {
                    setValues(
                      produce(values, (draft) => {
                        draft.contactInfo.address.city = value;
                      })
                    );
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>État/Province/Région</Form.ControlLabel>
                <SelectPicker
                  value={values.contactInfo.address.subdivisionCode}
                  onChange={(value) => {
                    setValues(
                      produce(values, (draft) => {
                        draft.contactInfo.address.subdivisionCode = value!;
                      })
                    );
                  }}
                  cleanable={false}
                  disabled={country === undefined}
                  data={country.subdivisions.map((subdivision) => ({
                    label: subdivision.name,
                    value: subdivision.code,
                  }))}
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Code postal</Form.ControlLabel>
                <Input
                  value={values.contactInfo.address.postalCode}
                  onChange={(value) => {
                    setValues(
                      produce(values, (draft) => {
                        draft.contactInfo.address.postalCode = value;
                      })
                    );
                  }}
                />
              </Form.Group>
            </Fragment>
          )}
        </FormContainer>
      </FormContainer>
    </Form>
  );
};
