import "./index.scss";
import { FunctionComponent } from "react";
import { Uploader as RsUploader } from "rsuite";
import { FileType } from "rsuite/esm/Uploader";

type Props = {
  readonly accept?: string;
  readonly disabled?: boolean;
  readonly multiple?: boolean;
  readonly onChange: (fileList: FileType[]) => void;
  readonly onRemove: () => void;
};

export const Uploader: FunctionComponent<Props> = ({ multiple = false, accept = undefined, disabled = false, onChange, onRemove }) => (
  <RsUploader
    className="uploader"
    draggable
    multiple={multiple}
    action=""
    accept={accept}
    autoUpload={false}
    disabled={disabled}
    onChange={onChange}
    onRemove={onRemove}
  >
    <div>Cliquez ou faites glisser le fichier dans cette zone pour le téléverse</div>
  </RsUploader>
);
