import "./index.scss";
import { FunctionComponent, PropsWithChildren } from "react";
import { Document, DocumentTitle, EnumLabel, getEnumLabel, Report } from "buildingBlocks";
import { ProductThumbnail, ContractSelectionTotal } from "components";
import { ContractSelectionGroupVibeMode, SectionElementOptionType, SectionElementType } from "graphql/schema";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = PropsWithChildren & {
  readonly contract: ProjectProviderTypes.MappedProjectContract;
  readonly contractSelection: ProjectProviderTypes.MappedProjectContractSelection;
};

export const ReportSelection: FunctionComponent<Props> = ({ contract, contractSelection, children }) => (
  <Report id="report-selection">
    <Document>
      <DocumentTitle subtitle={contract.unit.label} value={contractSelection.label} />
      <ContractSelectionTotal contractSelection={contractSelection} />
      {contractSelection.groups.map((contractSelectionGroup, contractSelectionGroupIndex) => (
        <div className="document-selection-group" key={contractSelectionGroupIndex}>
          <div className="document-selection-group-header">
            <span className="document-selection-group-title">{contractSelectionGroup.formSection.title}</span>
            {contractSelectionGroup.vibe && (
              <div className="document-selection-group-vibe">
                <span className="document-selection-group-vibe-label">Ambiance</span>
                <div>
                  {contractSelectionGroup.vibe.mode !== ContractSelectionGroupVibeMode.STANDARD && <small>({getEnumLabel(contractSelectionGroup.vibe.mode)})</small>}
                  <span>{contractSelectionGroup.vibe.sectionVibe.name}</span>
                </div>
                {contractSelectionGroup.vibe.clientAmount !== 0 && <span>{contractSelectionGroup.vibe.clientAmount.toCurrencyFormat()}</span>}
              </div>
            )}
          </div>
          {contractSelectionGroup
            .getItemsWithoutSets()
            .filter((contractSelectionGroupItem) => contractSelectionGroupItem.sectionElement.type !== SectionElementType.READONLY)
            .map((contractSelectionGroupItem, contractSelectionGroupItemIndex) => (
              <div className="document-selection-group-item" key={contractSelectionGroupItemIndex}>
                <div className="document-selection-group-item-section-element">
                  {contractSelectionGroupItem.sectionElement.group !== null
                    ? `${contractSelectionGroupItem.sectionElement.group.name} - ${contractSelectionGroupItem.sectionElement.label}`
                    : contractSelectionGroupItem.sectionElement.label}
                </div>
                <div className="document-selection-group-item-section-element-option">
                  {contractSelectionGroupItem.sectionElement.type === SectionElementType.BOOLEAN ? (
                    <div className="document-selection-group-item-section-element-option-card">
                      <span>
                        <EnumLabel value={contractSelectionGroupItem.sectionElementOptionType} uppercase block bold width={100} />
                      </span>
                      {contractSelectionGroupItem.clientAmount !== 0 && <span>{contractSelectionGroupItem.clientAmount.toCurrencyFormat()}</span>}
                    </div>
                  ) : (
                    <div className="document-selection-group-item-section-element-option-card">
                      {contractSelectionGroupItem.sectionElementOption && contractSelectionGroupItem.sectionElementOptionType !== SectionElementOptionType.CREDIT_SUBCONTRACTOR && (
                        <span>
                          {!contractSelectionGroupItem.sectionElement.hideProductCategory
                            ? contractSelectionGroupItem.sectionElementOption.product.name
                            : (contractSelectionGroupItem.sectionElementOption.product.variant ?? contractSelectionGroupItem.sectionElementOption.product.name)}
                        </span>
                      )}
                      <span>
                        <EnumLabel
                          value={
                            contractSelectionGroupItem.sectionElementOptionType === SectionElementOptionType.CREDIT_SUBCONTRACTOR
                              ? SectionElementOptionType.EXCLUDED
                              : contractSelectionGroupItem.sectionElementOptionType
                          }
                          uppercase
                          block
                          bold
                          width={100}
                        />
                      </span>
                      {contractSelectionGroupItem.clientAmount !== 0 && <span>{contractSelectionGroupItem.clientAmount.toCurrencyFormat()}</span>}
                    </div>
                  )}
                  {contractSelectionGroupItem.sectionElementOption && (
                    <ProductThumbnail
                      size="md"
                      hasThumbnail={contractSelectionGroupItem.sectionElementOption.product.hasThumbnail}
                      thumbnailUrl={contractSelectionGroupItem.sectionElementOption.product.thumbnailUrl}
                      bordered
                    />
                  )}
                </div>
              </div>
            ))}
          <div className="document-selection-group-subtotal">
            <div className="document-selection-group-subtotal-label">
              <span>SOUS-TOTAL</span>
              <small>(Taxe non comprise)</small>
            </div>
            <div className="document-selection-group-subtotal-amount">{contractSelectionGroup.clientAmount.toCurrencyFormat()}</div>
          </div>
        </div>
      ))}
      <ContractSelectionTotal title="Résumé" contractSelection={contractSelection} withSummary withBillingSettings />
      {children}
    </Document>
  </Report>
);
