import "./index.scss";
import { FunctionComponent, useState } from "react";
import { Form, Input, InputNumber } from "rsuite";
import { FormContainer, Table, TableBody, TableColumn, TableContent, TableHead, TableRow } from "buildingBlocks";
import { SectionElementOptionFormValues } from "forms/SectionForm";
import produce from "immer";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";
import { SectionElementOptionType } from "graphql/schema";

export type SectionElementOptionEditFormValues = {
  readonly clientAmount: number;
  readonly breakdownLines: {
    readonly activityId: string;
    readonly subcontractorAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
  }[];
};

type Props = {
  readonly values: SectionElementOptionEditFormValues;
  readonly setValues: (values: SectionElementOptionEditFormValues) => void;
  readonly activities: ReadonlyArray<ProjectProviderTypes.MappedProjectActivity>;
  readonly products: ReadonlyArray<ProjectProviderTypes.MappedProjectProduct>;
  readonly sectionElementOption: SectionElementOptionFormValues;
};

export const useSectionElementOptionEditForm = (initialValues: SectionElementOptionEditFormValues) => {
  const [values, setValues] = useState<SectionElementOptionEditFormValues>(initialValues);
  return { values, setValues };
};

export const SectionElementOptionEditForm: FunctionComponent<Props> = ({ sectionElementOption, values, setValues, products, activities }) => {
  const product = products.find((product) => product.id === sectionElementOption.productId)!;
  const costAmount: number = values.breakdownLines.reduce(
    (previousValue, currentValue) => previousValue + currentValue.subcontractorAmount + currentValue.contractorAmount + currentValue.promoterAmount,
    0
  );
  const contingencyAmount: number = values.clientAmount - costAmount;
  return (
    <Form id="section-element-option-edit-form">
      <FormContainer highlight>
        <Form.Group>
          <Form.ControlLabel>Nom du produit</Form.ControlLabel>
          <Input value={product.name} readOnly />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Montant du client</Form.ControlLabel>
          <InputNumber
            size="sm"
            min={0}
            readOnly={sectionElementOption.type === SectionElementOptionType.CREDIT_SUBCONTRACTOR}
            value={values.clientAmount}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.clientAmount = Number(value);
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Coût</Form.ControlLabel>
          <Input size="sm" value={costAmount.toCurrencyFormat()} readOnly />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Contingence</Form.ControlLabel>
          <Input size="sm" value={contingencyAmount.toCurrencyFormat()} readOnly />
        </Form.Group>
      </FormContainer>
      <FormContainer header={{ title: "Ventilation des coûts", actions: [] }} fluid withPadding>
        <Table fluid size="md">
          <TableContent>
            <TableHead>
              <TableRow>
                <TableColumn name="activity">Activité</TableColumn>
                <TableColumn name="subcontractor-amount">Montant sout-traitant</TableColumn>
                <TableColumn name="contractor-amount">Montant entrepreneur</TableColumn>
                <TableColumn name="promoter-amount">Montant promoteur</TableColumn>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.breakdownLines.map((sectionElementOptionBreakdownLine) => {
                const activity = activities.find((activity) => activity.id === sectionElementOptionBreakdownLine.activityId)!;
                return (
                  <TableRow key={sectionElementOptionBreakdownLine.activityId}>
                    <TableColumn name="activity">{activity.title}</TableColumn>
                    <TableColumn name="subcontractor-amount">
                      <InputNumber
                        size="sm"
                        min={sectionElementOption.type === SectionElementOptionType.EXTRA ? 0 : undefined}
                        max={sectionElementOption.type === SectionElementOptionType.CREDIT_SUBCONTRACTOR ? 0 : undefined}
                        value={sectionElementOptionBreakdownLine.subcontractorAmount}
                        onChange={(value) => {
                          setValues(
                            produce(values, (draft) => {
                              const draftBreakdownLine = draft.breakdownLines.find((draftBreakdownLine) => draftBreakdownLine.activityId === sectionElementOptionBreakdownLine.activityId);
                              if (draftBreakdownLine) {
                                draftBreakdownLine.subcontractorAmount = Number(value);
                              }
                            })
                          );
                        }}
                      />
                    </TableColumn>
                    <TableColumn name="contractor-amount">
                      <InputNumber
                        size="sm"
                        min={0}
                        max={sectionElementOption.type === SectionElementOptionType.CREDIT_SUBCONTRACTOR ? 0 : undefined}
                        disabled={sectionElementOption.type === SectionElementOptionType.CREDIT_SUBCONTRACTOR}
                        value={sectionElementOptionBreakdownLine.contractorAmount}
                        onChange={(value) => {
                          setValues(
                            produce(values, (draft) => {
                              const draftBreakdownLine = draft.breakdownLines.find((draftBreakdownLine) => draftBreakdownLine.activityId === sectionElementOptionBreakdownLine.activityId);
                              if (draftBreakdownLine) {
                                draftBreakdownLine.contractorAmount = Number(value);
                              }
                            })
                          );
                        }}
                      />
                    </TableColumn>
                    <TableColumn name="promoter-amount">
                      <InputNumber
                        size="sm"
                        min={0}
                        max={sectionElementOption.type === SectionElementOptionType.CREDIT_SUBCONTRACTOR ? 0 : undefined}
                        disabled={sectionElementOption.type === SectionElementOptionType.CREDIT_SUBCONTRACTOR}
                        value={sectionElementOptionBreakdownLine.promoterAmount}
                        onChange={(value) => {
                          setValues(
                            produce(values, (draft) => {
                              const draftBreakdownLine = draft.breakdownLines.find((draftBreakdownLine) => draftBreakdownLine.activityId === sectionElementOptionBreakdownLine.activityId);
                              if (draftBreakdownLine) {
                                draftBreakdownLine.promoterAmount = Number(value);
                              }
                            })
                          );
                        }}
                      />
                    </TableColumn>
                  </TableRow>
                );
              })}
            </TableBody>
          </TableContent>
        </Table>
      </FormContainer>
    </Form>
  );
};
