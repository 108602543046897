import "./index.scss";
import { FunctionComponent, PropsWithChildren } from "react";
import { useRouting } from "hooks";

type Props = PropsWithChildren & {
  readonly disabled?: boolean;
  readonly name?: string;
  readonly href?: string;
};

export const TableColumn: FunctionComponent<Props> = ({ name = undefined, disabled, children, href }) => {
  const { navigate } = useRouting();
  return (
    <div
      onClick={
        href
          ? () => {
              navigate(href);
            }
          : undefined
      }
      className={`table-column${name ? ` table-column-${name}` : ""}${disabled ? ` table-column-disabled` : ""}`}
    >
      {children}
    </div>
  );
};
