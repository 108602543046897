import "./index.scss";
import { FunctionComponent } from "react";
import { getEnumLabel, Panel, PanelControl, ViewTab } from "buildingBlocks";
import { useCustomerContext } from "providers/CustomerProvider";
import { useAppContext } from "providers";

export const CustomerOverviewView: FunctionComponent = () => {
  const { customer } = useCustomerContext();
  const { countries } = useAppContext();
  const country = countries.find((country) => country.code === customer.address.countryCode)!;
  const countrySubdivision = country.subdivisions.find((countrySubdivision) => countrySubdivision.code === customer.address.subdivisionCode)!;
  return (
    <ViewTab id="customer-overview-view">
      <Panel>
        <PanelControl label="Type" values={[getEnumLabel(customer.type)]} />
        {customer.firstName && <PanelControl label="Prénom" values={[customer.firstName]} />}
        {customer.lastName && <PanelControl label="Nom" values={[customer.lastName]} />}
        {customer.companyName && <PanelControl label="Entreprise" values={[customer.companyName]} />}
        <PanelControl label="Courriel" values={[customer.email]} />
        <PanelControl label="Téléphone" values={[customer.phone.toPhoneNumberFormat()]} />
        <PanelControl label="Genre" values={[getEnumLabel(customer.gender)]} />
        <PanelControl label="Langue" values={[getEnumLabel(customer.language)]} />
        <PanelControl
          label="Adresse"
          values={[
            <address>
              {customer.address.line}
              <br />
              {customer.address.city}, {countrySubdivision.name.toUpperCase()}, {customer.address.postalCode}
              <br />
              {country.name.toUpperCase()}
            </address>,
          ]}
        />
        <PanelControl label="Notes" values={[customer.notes ?? "--"]} />
      </Panel>
    </ViewTab>
  );
};
