import { FunctionComponent } from "react";
import { ViewForm } from "buildingBlocks";
import { useRouting } from "hooks";
import { useCreateProjectMutation } from "graphql/mutations";
import { useAppContext, useTenantContext } from "providers";
import { ProjectForm, useProjectForm } from "forms";

export const ProjectCreateView: FunctionComponent = () => {
  const { navigate, toProjectsView, toProjectView } = useRouting();
  const { countries } = useAppContext();
  const { tenant } = useTenantContext();
  const { values, setValues } = useProjectForm({
    code: "",
    name: "",
    description: "",
    contactInfo: {
      companyName: "",
      email: "",
      phone: "",
      websiteUrl: "",
      federalTaxNumber: "",
      provincialTaxNumber: "",
      address: {
        countryCode: "",
        line: "",
        city: "",
        subdivisionCode: "",
        postalCode: "",
      },
    },
  });
  const { createProject, createProjectLoading, createProjectErrors } = useCreateProjectMutation(({ project }) => {
    navigate(
      toProjectView({
        tenantId: tenant.id,
        projectId: project.id,
      })
    );
  });

  return (
    <ViewForm
      id="project-create-view"
      title="Créer un projet"
      security={{
        isTenantProjectManager: true,
      }}
      errors={createProjectErrors}
      loading={createProjectLoading}
      onSubmit={async () => {
        await createProject({
          tenantId: tenant.id,
          code: values.code,
          name: values.name,
          description: values.description.trim() || null,
          contactInfo: {
            companyName: values.contactInfo.companyName,
            email: values.contactInfo.email,
            phone: values.contactInfo.phone,
            websiteUrl: values.contactInfo.websiteUrl,
            federalTaxNumber: values.contactInfo.federalTaxNumber.trim() || null,
            provincialTaxNumber: values.contactInfo.provincialTaxNumber.trim() || null,
            address: {
              countryCode: values.contactInfo.address.countryCode,
              line: values.contactInfo.address.line,
              city: values.contactInfo.address.city,
              subdivisionCode: values.contactInfo.address.subdivisionCode,
              postalCode: values.contactInfo.address.postalCode,
            },
          },
        });
      }}
      onCancel={() => {
        navigate(
          toProjectsView({
            tenantId: tenant.id,
          })
        );
      }}
    >
      <ProjectForm values={values} setValues={setValues} countries={countries} />
    </ViewForm>
  );
};
