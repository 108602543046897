import "./index.scss";
import { FunctionComponent } from "react";
import { ViewForm } from "buildingBlocks";
import { useCreateContractMutation } from "graphql/mutations";
import { useProjectUnitContext, useRouting } from "hooks";
import { useTenantContext, useProjectContext } from "providers";
import { ContractForm, useContractForm } from "forms";
import { ContractState, PermissionName } from "graphql/schema";

export const ProjectUnitContractCreateView: FunctionComponent = () => {
  const { navigate, toProjectView, toProjectContractSelectionsView, toProjectContractInvoicesView } = useRouting();
  const { tenant, customers } = useTenantContext();
  const { project, units, forms } = useProjectContext();
  const { unit } = useProjectUnitContext();
  const { values, setValues } = useContractForm({
    unitId: unit.id,
    customers: [],
    document: undefined,
    formId: unit.formId,
    isCustomized: false,
  });
  const { createContract, createContractLoading, createContractErrors } = useCreateContractMutation(({ tenantId, projectId, contract }) => {
    if (contract.state === ContractState.IS_CUSTOMIZED) {
      navigate(
        toProjectContractInvoicesView({
          tenantId: tenantId,
          projectId: projectId,
          contractId: contract.id,
        })
      );
    } else {
      navigate(
        toProjectContractSelectionsView({
          tenantId: tenantId,
          projectId: projectId,
          contractId: contract.id,
        })
      );
    }
  });
  return (
    <ViewForm
      id="project-unit-contract-create-view"
      title="Créer un contrat"
      security={{
        permissions: [[PermissionName.CONTRACT_READ, PermissionName.CONTRACT_CREATE]],
      }}
      errors={createContractErrors}
      loading={createContractLoading}
      disabled={values.document === undefined}
      onSubmit={async () => {
        await createContract({
          tenantId: tenant.id,
          projectId: project.id,
          unitId: values.unitId,
          customers: values.customers.map(({ customerId, index }) => ({
            customerId: customerId,
            index: index,
          })),
          document: values.document!,
          isCustomized: values.isCustomized,
          formId: values.isCustomized ? null : values.formId,
        });
      }}
      onCancel={() => {
        navigate(
          toProjectView({
            tenantId: tenant.id,
            projectId: project.id,
          })
        );
      }}
    >
      <ContractForm values={values} setValues={setValues} customers={customers} forms={forms} units={units} readonlyUnit readonlyForm={unit.formId !== null} />
    </ViewForm>
  );
};
