import "./index.scss";
import { FunctionComponent } from "react";
import { ViewForm } from "buildingBlocks";
import { useProjectFormContext, useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { useUpdateFormMutation } from "graphql/mutations";
import { PermissionName } from "graphql/schema";
import { FormForm, useFormForm } from "forms";

export const ProjectFormUpdateView: FunctionComponent = () => {
  const { navigate, toProjectFormsView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, sections, models } = useProjectContext();
  const { form } = useProjectFormContext();
  const { values, setValues } = useFormForm({
    name: form.name,
    modelId: form.modelId,
    sections: form.sections.map((formSection) => ({
      id: formSection.id,
      index: formSection.index,
      title: formSection.title,
      sectionId: formSection.sectionId,
      isDeleted: false,
    })),
  });
  const { updateForm, updateFormLoading, updateFormErrors } = useUpdateFormMutation(({ tenantId, projectId }) => {
    navigate(
      toProjectFormsView({
        tenantId: tenantId,
        projectId: projectId,
      })
    );
  });
  return (
    <ViewForm
      id="project-form-update-view"
      title="Modifier le formulaire"
      security={{
        permissions: [[PermissionName.FORM_READ, PermissionName.FORM_UPDATE]],
      }}
      errors={updateFormErrors}
      loading={updateFormLoading}
      onSubmit={async () => {
        await updateForm({
          tenantId: tenant.id,
          projectId: project.id,
          formId: form.id,
          name: values.name,
          modelId: values.modelId,
          sections: values.sections
            .filter((section) => !section.isDeleted)
            .map((section) => ({
              id: section.id,
              index: section.index,
              title: section.title,
              sectionId: section.sectionId,
            })),
        });
      }}
      onCancel={() => {
        navigate(
          toProjectFormsView({
            tenantId: tenant.id,
            projectId: project.id,
          })
        );
      }}
    >
      <FormForm values={values} setValues={setValues} sections={sections} models={models} />
    </ViewForm>
  );
};
