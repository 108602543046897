import "./index.scss";
import { FormContainer, getEnumLabel } from "buildingBlocks";
import { RefundMode } from "graphql/schema";
import produce from "immer";
import { FunctionComponent, useState } from "react";
import { DatePicker, Form, Input, InputGroup, InputNumber, SelectPicker } from "rsuite";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type ContractRefundFormValues = {
  readonly label: string;
  readonly date: Date;
  readonly referenceNumber: string;
  readonly mode: RefundMode;
  readonly amount: number;
  readonly notes: string;
};

type Props = {
  readonly values: ContractRefundFormValues;
  readonly setValues: (values: ContractRefundFormValues) => void;
  readonly refundId?: string;
  readonly contract: ProjectProviderTypes.MappedProjectContract;
};

export const useContractRefundForm = (initialValues: ContractRefundFormValues) => {
  const [values, setValues] = useState<ContractRefundFormValues>(initialValues);
  return { values, setValues };
};

export const ContractRefundForm: FunctionComponent<Props> = ({ values, setValues, contract, refundId }) => {
  const [initialRefundAmount] = useState<number>(values.amount);
  const [totalAmount] = useState<number>(
    contract.refunds
      .filter((refund) => refund.invoiceId === null)
      .filter((refund) => refund.id !== refundId)
      .reduce((previousValue, currentValue) => previousValue + currentValue.amount, 0) +
      Math.abs(contract.balanceAmount) +
      initialRefundAmount
  );
  const refundAmount =
    contract.refunds
      .filter((refund) => refund.invoiceId === null)
      .filter((refund) => refund.id !== refundId)
      .reduce((previousValue, currentValue) => previousValue + currentValue.amount, 0) + values.amount;
  return (
    <Form id="contract-refund-form">
      <FormContainer highlight>
        <Form.Group>
          <Form.ControlLabel>Numéro</Form.ControlLabel>
          <Input value={values.label} readOnly />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Mode</Form.ControlLabel>
          <SelectPicker
            disabled
            cleanable={false}
            block
            value={values.mode}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.mode = value!;
                })
              );
            }}
            data={[
              { label: getEnumLabel(RefundMode.BANK_TRANSFER), value: RefundMode.BANK_TRANSFER },
              { label: getEnumLabel(RefundMode.CASH), value: RefundMode.CASH },
              { label: getEnumLabel(RefundMode.CHECK), value: RefundMode.CHECK },
              { label: getEnumLabel(RefundMode.CREDIT_CARD), value: RefundMode.CREDIT_CARD },
            ]}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Date</Form.ControlLabel>
          <DatePicker
            oneTap
            block
            value={values.date}
            cleanable={false}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.date = value!;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Montant</Form.ControlLabel>
          <InputGroup>
            <InputNumber
              min={0}
              value={values.amount}
              onChange={(value) => {
                setValues(
                  produce(values, (draft) => {
                    draft.amount = Number(value);
                  })
                );
              }}
            />
            <InputGroup.Button
              appearance="default"
              disabled={refundAmount >= totalAmount}
              onClick={() => {
                setValues(
                  produce(values, (draft) => {
                    if (Math.abs(contract.balanceAmount) === 0) {
                      draft.amount = initialRefundAmount;
                    } else {
                      draft.amount = draft.amount + Math.abs(contract.balanceAmount);
                    }
                  })
                );
              }}
            >
              Montant du solde
            </InputGroup.Button>
          </InputGroup>
        </Form.Group>
      </FormContainer>
      <FormContainer>
        <Form.Group>
          <Form.ControlLabel>Numéro de référence</Form.ControlLabel>
          <Input
            value={values.referenceNumber}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.referenceNumber = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Notes</Form.ControlLabel>
          <Input
            as="textarea"
            rows={3}
            value={values.notes}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.notes = value;
                })
              );
            }}
          />
        </Form.Group>
      </FormContainer>
    </Form>
  );
};
