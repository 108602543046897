import "./index.scss";
import { FunctionComponent } from "react";
import { ModalForm } from "buildingBlocks";

type Props = {
  readonly onAccept: () => void;
  readonly onRefuse: () => void;
};

export const ContractSelectionConditionsAcceptModal: FunctionComponent<Props> = ({ onAccept, onRefuse }) => (
  <ModalForm
    id="contract-selection-conditions-accept-modal"
    title="Conditions relatives aux choix personnalisés"
    size="md"
    errors={[]}
    disabled={false}
    loading={false}
    submitButton={{
      text: "J'accepte",
      onClick: onAccept,
    }}
    closeButton={{
      text: "Je refuse",
      onClick: onRefuse,
    }}
  >
    <p>Avant de finaliser vos sélections, veuillez lire attentivement les informations suivantes :</p>
    <p>
      <span>1. Caractère définitif et responsabilité des choix</span>
      Une fois vos options validées, elles ne pourront plus être modifiées. Vous assumez l'entière responsabilité des choix sélectionnés, y compris leur impact sur l’esthétique ou la fonctionnalité de
      votre unité. Assurez-vous que chaque sélection correspond à vos attentes avant de confirmer.
    </p>
    <p>
      <span>2. Modèle 3D informatif</span>
      Le modèle 3D présenté est à titre indicatif uniquement. Il est générique et ne représente pas avec précision votre unité de condo spécifique. Les proportions, agencements et éléments visuels
      peuvent différer de la version finale livrée. Veuillez également noter que Dév Méta inc. ne peut être tenue responsable de toute inexactitude, variation ou différence entre la représentation en
      3D affichée dans l'Application et l'unité de condo réelle. Les caractéristiques et éléments réels de l'unité de condo seront spécifiés dans les documents contractuels appropriés.
    </p>
    <p>
      <span>3. Facturation et frais supplémentaires</span>
      Certaines personnalisations peuvent engendrer des frais additionnels. Ces frais vous ont été communiqués avant la sélection et sont inclus dans le prix final. Un montant crédité ou un frais de
      révision peut également être ajouté à la facture en cas d'ajustements ou de modifications. Par ailleurs, la facture finale ne contiendra que les éléments ayant un frais associé. Les
      personnalisations gratuites ou incluses dans le forfait standard ne seront pas détaillées sur la facture.
    </p>
    <p>
      <span>4. Paiement intégral requis</span>
      Le montant total, incluant les personnalisations sélectionnées, doit être réglé avant la date d'échéance de la facture. Celle-ci est habituellement fixée à 7 jours après la confirmation de vos
      choix. En cas de non-paiement, vos sélections personnalisées pourraient être annulées, et une ambiance générique sans frais pourrait être appliquée à votre unité. Cependant, la gestion des cas
      de non-paiement demeure à la discrétion de Dév Méta inc., et des ajustements ou alternatives pourraient être proposés selon la situation.
    </p>
    <p>
      <span>5. Modification des produits</span>
      Dév Méta inc. se réserve le droit de remplacer tout produit sélectionné par un autre produit équivalent à tout moment, si le produit initial n'est pas disponible ou pour toute autre raison que
      Dév Méta inc. juge nécessaire. Toutes les modifications seront effectuées en veillant à ce que l'équivalence esthétique et fonctionnelle soit maintenue.
    </p>
    <p>
      En cliquant sur le bouton Accepter, vous confirmez avoir lu, compris et accepté toutes les conditions mentionnées ci-dessus, y compris les limitations du modèle 3D, les modalités de paiement,
      les conséquences en cas de non-paiement, et la possibilité de modifications des produits si nécessaire.
    </p>
  </ModalForm>
);
