import "./index.scss";
import { ViewReport } from "buildingBlocks";
import { FunctionComponent } from "react";
import { PermissionName } from "graphql/schema";
import { useRouting, useProjectContractContext, useProjectContractInvoiceContext } from "hooks";
import { useProjectContext, useAppContext, useTenantContext } from "providers";
import { ReportInvoice } from "components/ReportInvoice";

export const ProjectContractInvoiceView: FunctionComponent = () => {
  const { navigate, toProjectContractSelectionsView } = useRouting();
  const { countries } = useAppContext();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { contract } = useProjectContractContext();
  const { invoice } = useProjectContractInvoiceContext();
  return (
    <ViewReport
      id="project-contract-invoice-view"
      security={{
        permissions: [[PermissionName.CONTRACT_READ]],
      }}
      header={{
        subtitle: "Facture",
        title: invoice.label,
        returnButton: {
          onClick: () => {
            navigate(
              toProjectContractSelectionsView({
                tenantId: tenant.id,
                projectId: project.id,
                contractId: contract.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Dropdown",
              appearance: "primary",
              size: "sm",
              title: "Exporter",
              trigger: "click",
              placement: "bottomEnd",
              security: {},
              show: true,
              items: [
                {
                  __type: "DropdownItem",
                  text: "Télécharger (PDF)",
                  disabled: false,
                  onClick: () => {},
                },
                {
                  __type: "DropdownDivider",
                },
                {
                  __type: "DropdownItem",
                  text: "Imprimer",
                  disabled: false,
                  onClick: () => {},
                },
              ],
            },
          ],
          otherActions: [],
        },
      }}
    >
      <ReportInvoice project={project} contract={contract} invoice={invoice} countries={countries} />
    </ViewReport>
  );
};
