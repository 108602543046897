import "./index.scss";
import { FunctionComponent, PropsWithChildren } from "react";
import { useEnsureSecurity } from "hooks";
import { HeaderToolbar, HeaderFilter } from "buildingBlocks";
import { Button, IconButton } from "rsuite";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Types } from "types";

type Props = PropsWithChildren & {
  readonly id: string;
  readonly security: Types.Security;
  readonly header: {
    readonly subtitle: string;
    readonly title: string;
    readonly returnButton?: {
      readonly onClick: () => void;
    };
    readonly toolbar: Types.HeaderToolbar;
    readonly filter?: Types.HeaderFilter;
  };
  readonly form?: {
    readonly loading: boolean;
    readonly disabled?: boolean;
    readonly onSubmit: () => void;
    readonly onCancel: () => void;
  };
};

export const ViewReport: FunctionComponent<Props> = ({ id, header, security, children, form }) => {
  useEnsureSecurity(security);
  return (
    <section id={id} className={`view-report${header.filter ? ` view-report-has-filter` : ""}${form ? " view-report-has-form" : ""}`}>
      <header className="view-header">
        <div className="left">
          <div>
            {header.returnButton && <IconButton className="btn-return" size="sm" icon={<FontAwesomeIcon icon={faAngleLeft} />} appearance="default" onClick={header.returnButton.onClick} />}
            <div>
              {header.subtitle && <small className="subtitle">{header.subtitle}</small>}
              <h1>{header.title}</h1>
            </div>
          </div>
          <HeaderToolbar buttons={header.toolbar.buttons} otherActions={header.toolbar.otherActions} />
        </div>
        {header.filter && <HeaderFilter leftInputs={header.filter.left.inputs} rightInputs={header.filter.right.inputs} applyButton={header.filter.left.applyButton} />}
      </header>
      <main className="view-content">{children}</main>
      {form && (
        <footer className="view-footer">
          <div>
            <Button size="lg" appearance="primary" onClick={form.onSubmit} disabled={form.disabled} loading={form.loading}>
              Enregistrer
            </Button>
            <Button size="lg" appearance="ghost" onClick={form.onCancel} disabled={form.loading}>
              Annuler
            </Button>
          </div>
        </footer>
      )}
    </section>
  );
};
