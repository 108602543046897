import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { SearchInputGroup, ViewList, Text, EnumLabel } from "buildingBlocks";
import { useRouting } from "hooks";
import { MappedUser, useNotificationContext, useTenantContext } from "providers";
import { useResendUserInvitationMutation } from "graphql/mutations";
import { Toggle } from "rsuite";
import { UserBlockDialog } from "dialogs/UserBlockDialog";
import { UserUnblockDialog } from "dialogs/UserUnblockDialog";

const useTenantSettingsUsersViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  const [showTest, setShowTest] = useState<boolean>(false);
  return {
    filterValues: {
      search,
      showTest,
    },
    filterFunctions: {
      setSearch,
      setShowTest,
    },
    applyFilters: useCallback(
      (users: ReadonlyArray<MappedUser>) =>
        users
          .filter((user) => !user.isTest || (user.isTest && showTest))
          .filter((user) => user.fullName.toSearchFormat().includes(search.toSearchFormat()) || user.email.toSearchFormat().includes(search.toSearchFormat())),
      [search, showTest]
    ),
  };
};

export const TenantSettingsUsersView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useTenantSettingsUsersViewFilters();
  const { navigate, toTenantView, toTenantSettingsView, toTenantSettingsUserCreateView, toTenantSettingsUserUpdateView, toTenantSettingsUserView } = useRouting();
  const { tenant, users } = useTenantContext();
  const [userToBlock, setUserToBlock] = useState<MappedUser | null>(null);
  const [userToUnblock, setUserToUnblock] = useState<MappedUser | null>(null);
  const { push } = useNotificationContext();
  const { resendUserInvitation } = useResendUserInvitationMutation(
    ({ user }) => {
      push("Invitation renvoyé", `Une invitation a été renvoyé à l'adresse suivante: ${user.email}`, "success", "topCenter");
    },
    () => {},
    true
  );
  return (
    <ViewList
      id="tenant-settings-users-view"
      security={{
        isTenantSecurityManager: true,
      }}
      header={{
        title: "Tous les utilisateurs",
        returnButton: {
          onClick: () => {
            navigate(
              toTenantSettingsView({
                tenantId: tenant.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              appearance: "default",
              text: "Fermer les paramètres",
              show: true,
              security: {},
              onClick: () => {
                navigate(
                  toTenantView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
            {
              __type: "Button",
              appearance: "primary",
              text: "Créer un utilisateur",
              security: {
                isTenantSecurityManager: true,
              },
              show: applyFilters(users).length !== 0,
              onClick: () => {
                navigate(
                  toTenantSettingsUserCreateView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [
              <SearchInputGroup size="xs" placeholder="Recherche par nom ou courriel" value={filterValues.search} onChange={filterFunctions.setSearch} />,
              <Toggle unCheckedChildren="Utilisateurs de test caché" checkedChildren="Utilsateurs de test affiché" checked={filterValues.showTest} onChange={filterFunctions.setShowTest} />,
            ],
            applyButton: null,
          },
          right: {
            inputs: [],
          },
        },
      }}
      content={{
        table: {
          emptyBodies: [
            {
              show: applyFilters(users).length === 0,
              title: "Aucun utilisateur",
              content: "Pour créer un utilisateur, cliquez sur le bouton ci-dessous.",
              button: {
                text: "Créer un utilisateur",
                security: {
                  isTenantSecurityManager: true,
                },
                onClick: () => {
                  navigate(
                    toTenantSettingsUserCreateView({
                      tenantId: tenant.id,
                    })
                  );
                },
              },
            },
          ],
          headColumns: [
            {
              name: "details",
              value: "DÉTAILS",
            },
            {
              name: "tag",
              value: "",
            },
            {
              name: "role",
              value: "RÔLE",
            },
            {
              name: "projects",
              value: "ACCÈS AU(X) PROJET(S)",
            },
          ],
          bodyRows: applyFilters(users).map((user) => {
            return {
              key: user.id,
              href: toTenantSettingsUserView({
                tenantId: tenant.id,
                userId: user.id,
              }),
              columns: [
                {
                  name: "details",
                  component: (
                    <div className="user-details">
                      <Text highlight>{user.fullName}</Text>
                      <span>{user.email}</span>
                    </div>
                  ),
                },
                {
                  name: "tag",
                  component: user.isTest ? (
                    <EnumLabel
                      appearances={[
                        {
                          value: "TEST",
                          appearance: "primary",
                        },
                      ]}
                      value="TEST"
                    />
                  ) : user.isBlocked ? (
                    <EnumLabel
                      appearances={[
                        {
                          value: "BLOQUÉ",
                          appearance: "warning",
                        },
                      ]}
                      value="BLOQUÉ"
                    />
                  ) : (
                    ""
                  ),
                },
                {
                  name: "role",
                  component: user.role.name,
                },
                {
                  name: "projects",
                  component: (
                    <>
                      {user.projects.length !== 0 && !user.hasAccessToAllProjects && (
                        <ul>
                          {user.projects
                            .filter((project) => user.projects.map((project) => project.id).includes(project.id))
                            .map((project) => (
                              <li key={project.id}>{project.label}</li>
                            ))}
                        </ul>
                      )}
                      {user.hasAccessToAllProjects && (
                        <ul>
                          <li>Tous les projets</li>
                        </ul>
                      )}
                      {user.projects.length === 0 && !user.hasAccessToAllProjects && (
                        <ul>
                          <li>--</li>
                        </ul>
                      )}
                    </>
                  ),
                },
              ],
              actions: [
                {
                  __type: "Button",
                  content: "Modifier l'utilisateur",
                  security: {
                    isTenantSecurityManager: true,
                  },
                  show: true,
                  onClick: () => {
                    navigate(
                      toTenantSettingsUserUpdateView({
                        tenantId: tenant.id,
                        userId: user.id,
                      })
                    );
                  },
                },
                {
                  __type: "Button",
                  content: "Bloquer l'utilisateur",
                  security: {
                    isTenantSecurityManager: true,
                  },
                  show: !user.isBlocked,
                  onClick: () => {
                    setUserToBlock(user);
                  },
                },
                {
                  __type: "Button",
                  content: "Débloquer l'utilisateur",
                  security: {
                    isTenantSecurityManager: true,
                  },
                  show: user.isBlocked,
                  onClick: () => {
                    setUserToUnblock(user);
                  },
                },
                {
                  __type: "Divider",
                  show: true,
                },
                {
                  __type: "Button",
                  content: "Renvoyer une invitation",
                  security: {
                    isTenantSecurityManager: true,
                  },
                  show: true,
                  onClick: async () => {
                    await resendUserInvitation({
                      tenantId: tenant.id,
                      userId: user.id,
                    });
                  },
                },
              ],
            };
          }),
          bodyRowGroups: [],
        },
      }}
    >
      {userToBlock && (
        <UserBlockDialog
          tenantId={tenant.id}
          user={userToBlock}
          onCompleted={() => {
            setUserToBlock(null);
          }}
          onClose={() => {
            setUserToBlock(null);
          }}
        />
      )}
      {userToUnblock && (
        <UserUnblockDialog
          tenantId={tenant.id}
          user={userToUnblock}
          onCompleted={() => {
            setUserToUnblock(null);
          }}
          onClose={() => {
            setUserToUnblock(null);
          }}
        />
      )}
    </ViewList>
  );
};
