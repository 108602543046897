import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { EnumLabel, SearchInputGroup, ViewList, Text } from "buildingBlocks";
import { useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { FormState, PermissionName } from "graphql/schema";
import { Tag } from "rsuite";
import { FormDeleteDialog, FormPublishDialog } from "dialogs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

const useProjectFormsViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback(
      (forms: ReadonlyArray<ProjectProviderTypes.MappedProjectForm>) =>
        forms
          .filter(
            (form) =>
              form.name.toSearchFormat().includes(search.toSearchFormat()) ||
              form.sections.some((formSection) => formSection.title.toSearchFormat().includes(search.toSearchFormat()) || formSection.section.title.toSearchFormat().includes(search.toSearchFormat()))
          )
          .map((form) => ({
            ...form,
            sections: form.name.toSearchFormat().includes(search.toSearchFormat())
              ? form.sections
              : form.sections.filter(
                  (formSection) => formSection.title.toSearchFormat().includes(search.toSearchFormat()) || formSection.section.title.toSearchFormat().includes(search.toSearchFormat())
                ),
          })),
      [search]
    ),
  };
};

export const ProjectFormsView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useProjectFormsViewFilters();
  const { navigate, toProjectView, toProjectFormCreateView, toProjectFormUpdateView, toProjectFormLinkView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, forms } = useProjectContext();
  const [formToPublish, setFormToPublish] = useState<ProjectProviderTypes.MappedProjectForm | null>(null);
  const [formToDelete, setFormToDelete] = useState<ProjectProviderTypes.MappedProjectForm | null>(null);
  return (
    <ViewList
      id="project-forms-view"
      security={{
        permissions: [[PermissionName.FORM_READ]],
      }}
      header={{
        title: "Tous les formulaires",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              text: "Créer un formulaire",
              show: forms.length !== 0,
              security: {
                permissions: [[PermissionName.FORM_CREATE]],
              },
              onClick: () => {
                navigate(
                  toProjectFormCreateView({
                    tenantId: tenant.id,
                    projectId: project.id,
                  })
                );
              },
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [<SearchInputGroup size="xs" placeholder="Recherche par titre" value={filterValues.search} onChange={filterFunctions.setSearch} />],
            applyButton: null,
          },
          right: {
            inputs: [],
          },
        },
      }}
      content={{
        table: {
          emptyBodies: [
            {
              show: forms.length === 0,
              title: "Aucun formulaire",
              content: "Pour créer un formulaire, cliquez sur le bouton ci-dessous.",
              button: {
                text: "Créer un formulaire",
                security: {
                  permissions: [[PermissionName.FORM_CREATE]],
                },
                onClick: () => {
                  navigate(
                    toProjectFormCreateView({
                      tenantId: tenant.id,
                      projectId: project.id,
                    })
                  );
                },
              },
            },
          ],
          headColumns: [
            {
              name: "number",
              value: "#",
            },
            {
              name: "name",
              value: "FORMULAIRE / SECTION",
            },
            {
              name: "state-or-reference",
              value: "STATUT / RÉFÉRENCE",
            },
          ],
          bodyRows: [],
          bodyRowGroups: applyFilters(forms).map((form) => {
            return {
              key: form.id,
              columns: [
                {
                  name: "number",
                  component: <Tag className="form-index">{form.index + 1}</Tag>,
                },
                {
                  name: "name",
                  component: (
                    <div className="name">
                      {form.name}
                      {!form.isLinkedToReport && <FontAwesomeIcon color="red" icon={faWarning} />}
                    </div>
                  ),
                },
                {
                  name: "state-or-reference",
                  component: (
                    <EnumLabel
                      value={form.state}
                      appearances={[
                        {
                          value: FormState.CREATED,
                          appearance: "primary",
                        },
                        {
                          value: FormState.PUBLISHED,
                          appearance: "primary",
                        },
                        {
                          value: FormState.ARCHIVED,
                          appearance: "primary",
                        },
                      ]}
                      block
                      uppercase
                      bold
                      width={200}
                    />
                  ),
                },
              ],
              actions: [
                {
                  __type: "Button",
                  content: "Lier le formulaire",
                  show: form.state === FormState.PUBLISHED,
                  security: {
                    permissions: [[PermissionName.FORM_LINK]],
                  },
                  onClick: () => {
                    navigate(
                      toProjectFormLinkView({
                        tenantId: tenant.id,
                        projectId: project.id,
                        formId: form.id,
                      })
                    );
                  },
                },
                {
                  __type: "Button",
                  content: "Publier le formulaire",
                  show: form.state === FormState.CREATED,
                  security: {
                    permissions: [[PermissionName.FORM_PUBLISH]],
                  },
                  onClick: () => {
                    setFormToPublish(form);
                  },
                },
                {
                  __type: "Button",
                  content: "Modifier le formulaire",
                  show: form.state === FormState.CREATED,
                  security: {
                    permissions: [[PermissionName.FORM_UPDATE]],
                  },
                  onClick: () => {
                    navigate(
                      toProjectFormUpdateView({
                        tenantId: tenant.id,
                        projectId: project.id,
                        formId: form.id,
                      })
                    );
                  },
                },
                {
                  __type: "Button",
                  content: "Supprimer le formulaire",
                  show: form.state === FormState.CREATED,
                  security: {
                    permissions: [[PermissionName.FORM_DELETE]],
                  },
                  onClick: () => {
                    setFormToDelete(form);
                  },
                },
              ],
              rows: form.sections.map((formSection) => {
                return {
                  key: formSection.id,
                  columns: [
                    {
                      name: "number",
                      component: <Tag className="form-section-index">{formSection.index + 1}</Tag>,
                    },
                    {
                      name: "name",
                      component: <Text highlight>{formSection.title}</Text>,
                    },
                    {
                      name: "state-or-reference",
                      component: formSection.section.title,
                    },
                  ],
                  actions: [],
                };
              }),
            };
          }),
        },
      }}
    >
      {formToPublish && (
        <FormPublishDialog
          tenantId={tenant.id}
          projectId={project.id}
          form={formToPublish}
          onCompleted={() => {
            setFormToPublish(null);
          }}
          onClose={() => {
            setFormToPublish(null);
          }}
        />
      )}
      {formToDelete && (
        <FormDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          form={formToDelete}
          onCompleted={() => {
            setFormToDelete(null);
          }}
          onClose={() => {
            setFormToDelete(null);
          }}
        />
      )}
    </ViewList>
  );
};
