import "./index.scss";
import { FunctionComponent } from "react";
import { useRouting } from "hooks";
import { Button } from "rsuite";
import { useAuth0 } from "@auth0/auth0-react";

export const Unauthorized: FunctionComponent = () => {
  const { navigate, toTenantsView } = useRouting();
  const { logout } = useAuth0();
  return (
    <div className="unauthorized">
      <h1>Dév Méta</h1>
      <h2>ACCÈS REFUSÉ</h2>
      <p>Veuillez contacter un administrateur pour avoir accès à la plateforme.</p>
      <div>
        <Button
          onClick={() => {
            navigate(toTenantsView());
          }}
        >
          Accueil
        </Button>
        <Button
          onClick={() => {
            logout();
          }}
        >
          Se déconnecter
        </Button>
      </div>
      <small>
        <pre>Error Code: 401 Unauthorized</pre>
      </small>
    </div>
  );
};
