import "./index.scss";
import { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Button, Form, IconButton, SelectPicker, Toggle } from "rsuite";
import { convertMappedSectionElementOptionUnionsAsList, convertMappedSelectionGroupItemUnionsAsList } from "providers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { UnityModel, ContractSelectionGroupItem, ContractSelectionGroupItemSet } from "components";
import { SectionElementOptionType, SectionVibeMode, ContractSelectionGroupState, ContractSelectionGroupVibeMode } from "graphql/schema";
import produce from "immer";
import equal from "deep-equal";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";
import { UnityContextHook } from "react-unity-webgl/distribution/types/unity-context-hook";

type ContractSelectionGroupFormValues_vibe = {
  readonly sectionVibe: ProjectProviderTypes.MappedProjectSectionVibe;
  readonly mode: ContractSelectionGroupVibeMode;
  readonly clientAmount: number;
} | null;

type ContractSelectionGroupFormValues_items = ReadonlyArray<{
  readonly sectionElementId: string;
  readonly sectionElementOptionId: string | null;
  readonly clientAmount: number;
  readonly modelLink: {
    readonly modelRoomSharedId: string;
    readonly modelRoomElementSharedId: string;
    readonly modelRoomElementOptionSharedId: string;
  } | null;
}>;

type ContractSelectionGroupFormValues = {
  readonly vibe: ContractSelectionGroupFormValues_vibe;
  readonly items: ContractSelectionGroupFormValues_items;
};

type Props = {
  readonly values: ContractSelectionGroupFormValues;
  readonly setValues: (values: ContractSelectionGroupFormValues) => void;
  readonly unityContextHook: UnityContextHook | null;
  readonly contractSelection: ProjectProviderTypes.MappedProjectContractSelection;
  readonly contractSelectionGroup: ProjectProviderTypes.MappedProjectContractSelectionGroup;
  readonly loading: boolean;
  readonly onNext: (hasValuesChanged: boolean) => void;
  readonly onPrevious: (hasValuesChanged: boolean) => void;
  readonly onCompleted: () => void;
  readonly onSave: () => void;
};

export const useContractSelectionGroupForm = (initialValues: ContractSelectionGroupFormValues) => {
  const [values, setValues] = useState<ContractSelectionGroupFormValues>(initialValues);
  return { values, setValues };
};

const transformSectionElementOption = (
  sectionElement: ProjectProviderTypes.MappedProjectSectionElement,
  sectionElementOption: ProjectProviderTypes.MappedProjectSectionElementOption,
  vibe: ContractSelectionGroupFormValues_vibe
): ProjectProviderTypes.MappedProjectSectionElementOption => {
  if (sectionElement.isManagedByVibes && vibe) {
    if (vibe.sectionVibe.items.some((sectionVibeItem) => sectionVibeItem.sectionElementId === sectionElement.id && sectionVibeItem.sectionElementOptionId === sectionElementOption.id)) {
      return {
        ...sectionElementOption,
        type: SectionElementOptionType.INCLUDED,
        subcontractorAmount: 0,
        contractorAmount: 0,
        promoterAmount: 0,
        costAmount: 0,
        clientAmount: 0,
        contingencyAmount: 0,
        breakdownLines: [],
      };
    }
    if (vibe.mode === ContractSelectionGroupVibeMode.STANDARD && sectionElementOption.type === SectionElementOptionType.MIX_AND_MATCH) {
      return {
        ...sectionElementOption,
        isUnlocked: false,
      };
    }
    if (vibe.mode === ContractSelectionGroupVibeMode.UPGRADE) {
      return {
        ...sectionElementOption,
        type: SectionElementOptionType.MIX_AND_MATCH,
        subcontractorAmount: 0,
        contractorAmount: 0,
        promoterAmount: 0,
        costAmount: 0,
        clientAmount: 0,
        contingencyAmount: 0,
        breakdownLines: [],
      };
    }
  }
  return sectionElementOption;
};

export const ContractSelectionGroupForm: FunctionComponent<Props> = ({ values, setValues, unityContextHook, contractSelection, contractSelectionGroup, onPrevious, onNext, onCompleted, onSave }) => {
  const getContractSelectionGroup = useCallback(
    (vibe: ContractSelectionGroupFormValues_vibe) => {
      const contractSelectionGroupItems = contractSelectionGroup.items.map((contractSelectionGroupItem) => {
        if (contractSelectionGroupItem.__typename === "ContractSelectionGroupItemSet") {
          const items = contractSelectionGroupItem.items.map((item) => {
            const options = item.sectionElement.options.map((sectionElementOption) => {
              if (sectionElementOption.__typename === "SectionElementOptionSet") {
                return {
                  ...sectionElementOption,
                  options: sectionElementOption.options.map((option) => transformSectionElementOption(item.sectionElement, option, vibe)),
                };
              }
              return transformSectionElementOption(item.sectionElement, sectionElementOption, vibe);
            });
            const element = {
              ...item.sectionElement,
              options: options,
              getOptionsWithoutSets: () => {
                return convertMappedSectionElementOptionUnionsAsList(options);
              },
            };
            return {
              ...item,
              sectionElement: element,
              sectionElementOption: element.getOptionsWithoutSets().find((sectionElementOption) => sectionElementOption.id === item.sectionElementOptionId) ?? null,
            };
          });
          return {
            ...contractSelectionGroupItem,
            items: items,
          };
        }
        const sectionElementOptions = contractSelectionGroupItem.sectionElement.options.map((sectionElementOption) => {
          if (sectionElementOption.__typename === "SectionElementOptionSet") {
            return {
              ...sectionElementOption,
              options: sectionElementOption.options.map((option) => transformSectionElementOption(contractSelectionGroupItem.sectionElement, option, vibe)),
            };
          }
          return transformSectionElementOption(contractSelectionGroupItem.sectionElement, sectionElementOption, vibe);
        });
        const sectionElement = {
          ...contractSelectionGroupItem.sectionElement,
          options: sectionElementOptions,
          getOptionsWithoutSets: () => {
            return convertMappedSectionElementOptionUnionsAsList(sectionElementOptions);
          },
        };
        return {
          ...contractSelectionGroupItem,
          sectionElement: sectionElement,
          sectionElementOption: sectionElement.getOptionsWithoutSets().find((sectionElementOption) => sectionElementOption.id === contractSelectionGroupItem.sectionElementOptionId) ?? null,
        };
      });
      return {
        ...contractSelectionGroup,
        items: contractSelectionGroupItems,
        getItemsWithoutSets: () => {
          return convertMappedSelectionGroupItemUnionsAsList(contractSelectionGroupItems);
        },
      };
    },
    [contractSelectionGroup]
  );
  const setSectionVibe = useCallback(
    (vibe: ContractSelectionGroupFormValues_vibe) => {
      const contractSelectionGroupItems = getContractSelectionGroup(vibe).getItemsWithoutSets();
      setValues({
        vibe: vibe,
        items: values.items.map((item) => {
          const contractSelectionGroupItem = contractSelectionGroupItems.find((contractSelectionGroupItem) => contractSelectionGroupItem.sectionElementId === item.sectionElementId)!;
          if (contractSelectionGroupItem.sectionElement.isManagedByVibes) {
            const sectionElementOption = contractSelectionGroupItem.sectionElement
              .getOptionsWithoutSets()
              .find((sectionElementOption) => sectionElementOption.type === SectionElementOptionType.INCLUDED)!;
            return {
              sectionElementId: item.sectionElementId,
              sectionElementOptionId: sectionElementOption.id,
              modelLink: sectionElementOption.modelLink
                ? {
                    modelRoomSharedId: sectionElementOption.modelLink.modelRoom.sharedId,
                    modelRoomElementSharedId: sectionElementOption.modelLink.modelRoomElement.sharedId,
                    modelRoomElementOptionSharedId: sectionElementOption.modelLink.modelRoomElementOption.sharedId,
                  }
                : null,
              clientAmount: sectionElementOption.clientAmount,
            };
          } else {
            return item;
          }
        }),
      });
    },
    [getContractSelectionGroup, values.items, setValues]
  );
  const hasInitialValuesChanged = useCallback(() => {
    const vibe: ContractSelectionGroupFormValues_vibe = contractSelectionGroup.vibe
      ? {
          sectionVibe: contractSelectionGroup.formSection.section.vibes.find((sectionVibe) => sectionVibe.id === contractSelectionGroup.vibe!.sectionVibeId)!,
          mode: contractSelectionGroup.vibe.mode,
          clientAmount: contractSelectionGroup.vibe.clientAmount,
        }
      : null;
    const initialValues: ContractSelectionGroupFormValues = {
      vibe: vibe,
      items: getContractSelectionGroup(vibe)
        .getItemsWithoutSets()
        .map((contractSelectionGroupItem) => ({
          sectionElementId: contractSelectionGroupItem.sectionElementId,
          sectionElementOptionId: contractSelectionGroupItem.sectionElementOptionId,
          modelLink:
            contractSelectionGroupItem.sectionElementOption && contractSelectionGroupItem.sectionElementOption.modelLink
              ? {
                  modelRoomSharedId: contractSelectionGroupItem.sectionElementOption.modelLink.modelRoom.sharedId,
                  modelRoomElementSharedId: contractSelectionGroupItem.sectionElementOption.modelLink.modelRoomElement.sharedId,
                  modelRoomElementOptionSharedId: contractSelectionGroupItem.sectionElementOption.modelLink.modelRoomElementOption.sharedId,
                }
              : null,
          clientAmount: contractSelectionGroupItem.sectionElementOption?.clientAmount ?? 0,
        })),
    };
    return !equal(values, initialValues, { strict: true });
  }, [getContractSelectionGroup, contractSelectionGroup, values]);
  const resetForm = useCallback(() => {
    const vibe: ContractSelectionGroupFormValues_vibe = contractSelectionGroup.vibe
      ? {
          sectionVibe: contractSelectionGroup.formSection.section.vibes.find((sectionVibe) => sectionVibe.id === contractSelectionGroup.vibe!.sectionVibeId)!,
          mode: contractSelectionGroup.vibe.mode,
          clientAmount: contractSelectionGroup.vibe.clientAmount,
        }
      : null;
    const items = getContractSelectionGroup(vibe)
      .getItemsWithoutSets()
      .map((contractSelectionGroupItem) => ({
        sectionElementId: contractSelectionGroupItem.sectionElementId,
        sectionElementOptionId: contractSelectionGroupItem.sectionElementOptionId,
        modelLink:
          contractSelectionGroupItem.sectionElementOption && contractSelectionGroupItem.sectionElementOption.modelLink
            ? {
                modelRoomSharedId: contractSelectionGroupItem.sectionElementOption.modelLink.modelRoom.sharedId,
                modelRoomElementSharedId: contractSelectionGroupItem.sectionElementOption.modelLink.modelRoomElement.sharedId,
                modelRoomElementOptionSharedId: contractSelectionGroupItem.sectionElementOption.modelLink.modelRoomElementOption.sharedId,
              }
            : null,
        clientAmount: contractSelectionGroupItem.sectionElementOption?.clientAmount ?? 0,
      }));
    setValues({
      vibe: vibe,
      items: items,
    });
  }, [getContractSelectionGroup, contractSelectionGroup, setValues]);
  const formSideBarBodyRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    resetForm();
    const current = formSideBarBodyRef.current;
    if (current) {
      current.scrollTop = 0;
    }
  }, [resetForm, formSideBarBodyRef]);
  let contractSelectionGroupTotalAmount = values.items.reduce((previousValue, currentValue) => previousValue + currentValue.clientAmount, 0);
  if (values.vibe) {
    contractSelectionGroupTotalAmount += values.vibe.clientAmount;
  }
  const contractSelectionTotalAmount = contractSelection.groups
    .filter(({ id }) => id !== contractSelectionGroup.id)
    .reduce((totalAmount, contractSelectionGroup) => totalAmount + contractSelectionGroup.clientAmount, contractSelectionGroupTotalAmount);
  const modelLink = contractSelectionGroup.formSection.section.modelLink;
  const sectionVibes = contractSelectionGroup.formSection.section.vibes;
  const hasValuesChanged = hasInitialValuesChanged();
  return (
    <Form id="contract-selection-group-form" className={unityContextHook === null ? "contract-selection-group-form-without-unity-model" : undefined}>
      {unityContextHook && (
        <UnityModel
          unityContextHook={unityContextHook}
          link={
            modelLink
              ? {
                  modelRoomSharedId: modelLink.modelRoom.sharedId,
                  items: values.items
                    .filter((item) => item.modelLink !== null)
                    .map((item) => ({
                      modelRoomElementSharedId: item.modelLink!.modelRoomElementSharedId,
                      modelRoomElementOptionSharedId: item.modelLink!.modelRoomElementOptionSharedId,
                      metadata: null,
                    })),
                }
              : null
          }
        />
      )}
      <aside className={`form-sidebar${values.vibe ? " form-sidebar-has-vibe form-sidebar-has-mix-and-match" : ""}`}>
        <header className={`form-sidebar-header`}>
          <section className="selection-group-title">
            <div className="selection-group-redirection-buttons">
              <IconButton
                appearance="link"
                size="sm"
                onClick={() => onPrevious(hasValuesChanged)}
                disabled={contractSelectionGroup.index === 0}
                icon={<FontAwesomeIcon color="white" icon={faCaretLeft} />}
              />
              <span>
                {contractSelectionGroup.index + 1}/{contractSelection.groups.length}
              </span>
              <IconButton
                appearance="link"
                size="sm"
                onClick={() => onNext(hasValuesChanged)}
                disabled={contractSelectionGroup.index + 1 === contractSelection.groups.length || contractSelectionGroup.state === ContractSelectionGroupState.PENDING}
                icon={<FontAwesomeIcon color="white" icon={faCaretRight} />}
              />
            </div>
            <h2>{contractSelectionGroup.formSection.title}</h2>
            <div className="selection-group-reset">
              <Button
                size="xs"
                appearance="default"
                onClick={() => {
                  resetForm();
                }}
              >
                Réinitialiser
              </Button>
            </div>
          </section>
          {values.vibe && (
            <section className="selection-group-vibe">
              <span className="selection-group-vibe-label">Ambiance</span>
              <SelectPicker
                className="selection-group-vibe-input"
                size="md"
                searchable={false}
                cleanable={false}
                value={values.vibe.sectionVibe.id}
                onChange={(value) => {
                  const sectionVibe = sectionVibes.find((sectionVibe) => sectionVibe.id === value)!;
                  const vibe: ContractSelectionGroupFormValues_vibe = {
                    sectionVibe: sectionVibe,
                    mode: sectionVibe.mode === SectionVibeMode.UPGRADE ? ContractSelectionGroupVibeMode.UPGRADE : ContractSelectionGroupVibeMode.STANDARD,
                    clientAmount: sectionVibe.clientAmount,
                  };
                  setSectionVibe(vibe);
                }}
                data={sectionVibes.map((sectionVibe) => ({
                  label: sectionVibe.name,
                  value: sectionVibe.id,
                }))}
              />
            </section>
          )}
          {values.vibe && (
            <section className="selection-group-vibe-mode">
              {values.vibe.mode !== ContractSelectionGroupVibeMode.UPGRADE ? (
                <span className="selection-group-vibe-mode-label">Mix and match</span>
              ) : (
                <span className="selection-group-vibe-mode-label">Mise à niveau</span>
              )}
              {values.vibe.mode !== ContractSelectionGroupVibeMode.UPGRADE ? (
                <Toggle
                  className="selection-group-vibe-mode-input"
                  size="md"
                  checked={values.vibe.mode === ContractSelectionGroupVibeMode.MIX_AND_MATCH}
                  onChange={(checked) => {
                    if (checked) {
                      setValues({
                        vibe: {
                          sectionVibe: values.vibe!.sectionVibe,
                          mode: ContractSelectionGroupVibeMode.MIX_AND_MATCH,
                          clientAmount: contractSelection.billingSettings.mixAndMatchAmount,
                        },
                        items: values.items,
                      });
                    } else {
                      setSectionVibe({
                        sectionVibe: values.vibe!.sectionVibe,
                        mode: ContractSelectionGroupVibeMode.STANDARD,
                        clientAmount: 0,
                      });
                    }
                  }}
                  unCheckedChildren="DÉSACTIVÉ"
                  checkedChildren={contractSelection.billingSettings.mixAndMatchAmount.toCurrencyFormat()}
                />
              ) : (
                <span className="selection-group-vibe-mode-amount">{values.vibe.clientAmount.toCurrencyFormat()}</span>
              )}
            </section>
          )}
        </header>
        <section className="form-sidebar-body" ref={formSideBarBodyRef}>
          {getContractSelectionGroup(values.vibe).items.map((contractSelectionGroupItemSet, contractSelectionGroupItemSetIndex) => {
            if (contractSelectionGroupItemSet.__typename === "ContractSelectionGroupItemSet") {
              const sectionElementIds = contractSelectionGroupItemSet.items.map((contractSelectionGroupItem) => contractSelectionGroupItem.sectionElementId);
              return (
                <ContractSelectionGroupItemSet
                  key={contractSelectionGroupItemSetIndex}
                  contractSelectionGroupItemSet={contractSelectionGroupItemSet}
                  values={values.items
                    .filter((item) => sectionElementIds.includes(item.sectionElementId))
                    .map((item) => ({
                      sectionElementId: item.sectionElementId,
                      sectionElementOptionId: item.sectionElementOptionId,
                      clientAmount: item.clientAmount,
                    }))}
                  onClick={(sectionElement, sectionElementOption) => {
                    setValues(
                      produce(values, (draft) => {
                        const item = draft.items.find((item) => item.sectionElementId === sectionElement.id)!;
                        if (sectionElementOption) {
                          item.sectionElementOptionId = sectionElementOption.id;
                          item.clientAmount = sectionElementOption.clientAmount;
                          item.modelLink = sectionElementOption.modelLink
                            ? {
                                modelRoomSharedId: sectionElementOption.modelLink.modelRoom.sharedId,
                                modelRoomElementSharedId: sectionElementOption.modelLink.modelRoomElement.sharedId,
                                modelRoomElementOptionSharedId: sectionElementOption.modelLink.modelRoomElementOption.sharedId,
                              }
                            : null;
                        } else {
                          item.sectionElementOptionId = null;
                          item.clientAmount = 0;
                          item.modelLink = null;
                        }
                      })
                    );
                  }}
                />
              );
            }
            const contractSelectionGroupItem = contractSelectionGroupItemSet;
            const item = values.items.find((item) => item.sectionElementId === contractSelectionGroupItem.sectionElement.id);
            return (
              <ContractSelectionGroupItem
                key={contractSelectionGroupItemSetIndex}
                sectionElement={contractSelectionGroupItem.sectionElement}
                value={
                  item
                    ? {
                        sectionElementOptionId: item.sectionElementOptionId,
                        clientAmount: item.clientAmount,
                      }
                    : null
                }
                onClick={(sectionElementOption) => {
                  setValues(
                    produce(values, (draft) => {
                      const item = draft.items.find((item) => item.sectionElementId === contractSelectionGroupItem.sectionElement.id)!;
                      if (sectionElementOption) {
                        item.sectionElementOptionId = sectionElementOption.id;
                        item.clientAmount = sectionElementOption.clientAmount;
                        item.modelLink = sectionElementOption.modelLink
                          ? {
                              modelRoomSharedId: sectionElementOption.modelLink.modelRoom.sharedId,
                              modelRoomElementSharedId: sectionElementOption.modelLink.modelRoomElement.sharedId,
                              modelRoomElementOptionSharedId: sectionElementOption.modelLink.modelRoomElementOption.sharedId,
                            }
                          : null;
                      } else {
                        item.sectionElementOptionId = null;
                        item.clientAmount = 0;
                        item.modelLink = null;
                      }
                    })
                  );
                }}
              />
            );
          })}
        </section>
        <footer className="form-sidebar-footer">
          <section className="selection-summary">
            <div className="selection-summary-total-section">
              <span>MONTANT DE LA SECTION</span>
              <span>{contractSelectionGroupTotalAmount.toCurrencyFormat()}</span>
            </div>
            <div className="selection-summary-total">
              <span>MONTANT TOTAL</span>
              <span>{contractSelectionTotalAmount.toCurrencyFormat()}</span>
            </div>
          </section>
          <section className="selection-group-buttons">
            {hasValuesChanged || contractSelectionGroup.state === ContractSelectionGroupState.PENDING ? (
              <Button appearance="primary" onClick={onSave} size="lg">
                Enregistrer
              </Button>
            ) : (
              <Button
                disabled={contractSelectionGroup.index === contractSelection.groups.length - 1}
                appearance={
                  hasValuesChanged || contractSelection.groups.every((contractSelectionGroup) => contractSelectionGroup.state !== ContractSelectionGroupState.COMPLETED) ? "primary" : "ghost"
                }
                onClick={() => onNext(hasValuesChanged)}
                size="lg"
              >
                Continuer
              </Button>
            )}
            <Button
              disabled={hasValuesChanged || contractSelection.groups.some((contractSelectionGroup) => contractSelectionGroup.state !== ContractSelectionGroupState.COMPLETED)}
              onClick={onCompleted}
              appearance="primary"
              size="lg"
            >
              Terminer
            </Button>
          </section>
        </footer>
      </aside>
    </Form>
  );
};
