import "./index.scss";
import { FunctionComponent } from "react";
import { ViewForm } from "buildingBlocks";
import { ModelForm, useModelForm } from "forms";
import { useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { useImportModelMutation } from "graphql/mutations";
import { PermissionName } from "graphql/schema";

export const ProjectModelImportView: FunctionComponent = () => {
  const { navigate, toProjectModelsView, toProjectModelView } = useRouting();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { importModel, importModelLoading, importModelErrors } = useImportModelMutation(({ tenantId, projectId, model }) => {
    navigate(
      toProjectModelView({
        tenantId: tenantId,
        projectId: projectId,
        modelId: model.id,
      })
    );
  });
  const { values, setValues } = useModelForm({
    dataBlobFile: undefined,
    unityDataBlobFile: undefined,
    unityFrameworkBlobFile: undefined,
    unityLoaderBlobFile: undefined,
    unityWasmBlobFile: undefined,
    productThumbnailBlobFiles: [],
  });
  return (
    <ViewForm
      id="project-model-import-view"
      title="Importer le modèle"
      security={{
        permissions: [[PermissionName.MODEL_READ, PermissionName.MODEL_IMPORT]],
      }}
      errors={importModelErrors}
      loading={importModelLoading}
      disabled={
        values.dataBlobFile === undefined ||
        values.unityDataBlobFile === undefined ||
        values.unityFrameworkBlobFile === undefined ||
        values.unityLoaderBlobFile === undefined ||
        values.unityWasmBlobFile === undefined ||
        values.productThumbnailBlobFiles.length === 0
      }
      onSubmit={async () => {
        await importModel({
          tenantId: tenant.id,
          projectId: project.id,
          dataBlobFile: values.dataBlobFile!,
          unityDataBlobFile: values.unityDataBlobFile!,
          unityFrameworkBlobFile: values.unityFrameworkBlobFile!,
          unityLoaderBlobFile: values.unityLoaderBlobFile!,
          unityWasmBlobFile: values.unityWasmBlobFile!,
          productThumbnailBlobFiles: values.productThumbnailBlobFiles,
        });
      }}
      onCancel={() => {
        navigate(
          toProjectModelsView({
            tenantId: tenant.id,
            projectId: project.id,
          })
        );
      }}
    >
      <ModelForm values={values} setValues={setValues} />
    </ViewForm>
  );
};
