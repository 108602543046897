import "./index.scss";
import { useDeleteCustomerMutation } from "graphql/mutations";
import { FunctionComponent, useState } from "react";
import { Dialog, DialogBodyDelete, DialogFooterDelete } from "buildingBlocks";
import { CustomerFragment } from "graphql/schema";

type Props = {
  readonly tenantId: string;
  readonly customer: CustomerFragment;
  readonly onCompleted: () => void;
  readonly onClose: () => void;
};

export const CustomerDeleteDialog: FunctionComponent<Props> = ({ tenantId, customer, onCompleted, onClose }) => {
  const [value, setValue] = useState<string>("");
  const { deleteCustomer, deleteCustomerLoading } = useDeleteCustomerMutation(onCompleted, onClose, true);
  return (
    <Dialog id="customer-delete-dialog" title="Supprimer le client">
      <DialogBodyDelete
        objectName={customer.email}
        dictionaryList={[
          {
            title: "NOM",
            description: customer.fullName,
          },
          {
            title: "COURRIEL",
            description: customer.email,
          },
          {
            title: "TÉLÉPHONE",
            description: customer.phone.toPhoneNumberFormat(),
          },
        ]}
        value={value}
        setValue={setValue}
      />
      <DialogFooterDelete
        disabled={customer.email !== value}
        loading={deleteCustomerLoading}
        onClose={onClose}
        onClick={async () => {
          await deleteCustomer({
            tenantId: tenantId,
            customerId: customer.id,
          });
        }}
      />
    </Dialog>
  );
};
