import "./index.scss";
import { FunctionComponent, PropsWithChildren, ReactNode } from "react";

type Props = PropsWithChildren & {
  readonly header?: {
    readonly title: ReactNode;
  };
};

export const Panel: FunctionComponent<Props> = ({ header, children }) => (
  <div className="panel">
    {header && (
      <div className="panel-header">
        <h2 className="panel-title">{header.title}</h2>
      </div>
    )}
    <div className="panel-body">{children}</div>
  </div>
);
