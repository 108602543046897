import { createContext, FunctionComponent, PropsWithChildren, ReactNode, useCallback, useContext } from "react";
import { Notification, useToaster } from "rsuite";
import { MessageType } from "rsuite/esm/Notification/Notification";
import { PlacementType } from "rsuite/esm/toaster/ToastContainer";

type NotificationContextValue = {
  readonly push: (header: string, message: string, type: MessageType, placement: PlacementType) => void;
  readonly pushError: (message: string) => void;
  readonly pushErrors: (errors: string[]) => void;
};

const initialValue: NotificationContextValue = {
  push: () => {},
  pushError: () => {},
  pushErrors: () => {},
};

const NotificationContext = createContext<NotificationContextValue>(initialValue);

const useNotificationContext = () => useContext(NotificationContext);

const NotificationProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const toaster = useToaster();
  const push = useCallback(
    (header: string, message: ReactNode, type: MessageType, placement: PlacementType) => {
      toaster.push(
        <Notification className="notification" type={type} header={header} duration={0} closable>
          {message}
        </Notification>,
        { placement }
      );
    },
    [toaster]
  );
  const pushError = useCallback(
    (message: string) => {
      push("Erreur", message, "error", "topCenter");
    },
    [push]
  );
  const pushErrors = useCallback(
    (errors: string[]) => {
      push(
        "Erreur(s)",
        <ul>
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>,
        "error",
        "topCenter"
      );
    },
    [push]
  );
  return <NotificationContext.Provider value={{ push, pushError, pushErrors }}>{children}</NotificationContext.Provider>;
};

export { NotificationProvider, useNotificationContext };
