import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { SearchInputGroup, TooltipWrapper, ViewList, Text } from "buildingBlocks";
import { useRouting } from "hooks";
import { CheckPicker, SelectPicker } from "rsuite";
import { useList } from "react-use";
import { PermissionName } from "graphql/schema";
import { useTenantContext, useProjectContext } from "providers";
import { ProductThumbnail } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faWarning } from "@fortawesome/free-solid-svg-icons";
import { ProductDeleteDialog, ProductCategoryDeleteDialog } from "dialogs";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

const useProjectProductsViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  const [managedByModels, setManagedByModels] = useState<string | null>(null);
  const [providerIds, { set: setProviderIds }] = useList<string>(searchParams.get("providerIds")?.split(",") ?? []);
  return {
    filterValues: {
      search,
      managedByModels,
      providerIds,
    },
    filterFunctions: {
      setSearch,
      setManagedByModels,
      setProviderIds,
    },
    applyFilters: useCallback(
      (productCategories: ReadonlyArray<ProjectProviderTypes.MappedProjectProductCategory>) =>
        productCategories
          .filter((productCategory) => {
            if (providerIds.length === 0) {
              return true;
            }
            return productCategory.products
              .filter((product) => product.categoryId === productCategory.id)
              .some((product) => (providerIds.length !== 0 ? (product.provider && product.provider.id ? providerIds.includes(product.provider.id) : false) : true));
          })
          .filter((productCategory) => {
            if (managedByModels === null) {
              return true;
            }
            return (productCategory.managedByModels ? "1" : "0") === managedByModels;
          })
          .filter((productCategory) => {
            if (search.trim() === "") {
              return true;
            }
            return (
              productCategory.name.toSearchFormat().includes(search.toSearchFormat()) ||
              productCategory.products.filter((product) => product.categoryId === productCategory.id).some((product) => product.name.toSearchFormat().includes(search.toSearchFormat()))
            );
          })
          .map((productCategory) => ({
            ...productCategory,
            products: productCategory.products
              .filter((product) => product.categoryId === productCategory.id)
              .filter((product) => (providerIds.length !== 0 ? (product.provider && product.provider.id ? providerIds.includes(product.provider.id) : false) : true))
              .filter((product) => (managedByModels !== null ? (product.managedByModels ? "1" : "0") === managedByModels : true))
              .filter((product) => product.name.toSearchFormat().includes(search.toSearchFormat())),
          })),
      [search, managedByModels, providerIds]
    ),
  };
};

export const ProjectProductsView: FunctionComponent = () => {
  const {
    navigate,
    toProjectView,
    toProjectProductView,
    toProjectProductCategoryCreateView,
    toProjectProductCategoryUpdateView,
    toProjectProductCategoryProductCreateView,
    toProjectProductUpdateView,
    toProjectProductCreateView,
    toProjectProductsUpdateView,
  } = useRouting();
  const { tenant, providers } = useTenantContext();
  const { project, products, productCategories } = useProjectContext();
  const { filterValues, filterFunctions, applyFilters } = useProjectProductsViewFilters();
  const [productToDelete, setProductToDelete] = useState<ProjectProviderTypes.MappedProjectProduct | null>(null);
  const [productCategoryToDelete, setProductCategoryToDelete] = useState<ProjectProviderTypes.MappedProjectProductCategory | null>(null);
  return (
    <ViewList
      id="project-products-view"
      security={{
        permissions: [[PermissionName.PRODUCT_READ]],
      }}
      header={{
        title: "Tous les produits",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              text: "Créer un produit",
              show: productCategories.length !== 0,
              security: {
                permissions: [[PermissionName.PRODUCT_CREATE]],
              },
              onClick: () => {
                navigate(
                  toProjectProductCreateView({
                    tenantId: tenant.id,
                    projectId: project.id,
                  })
                );
              },
            },
          ],
          otherActions: [
            {
              __type: "Button",
              content: "Créer une catégorie",
              disabled: false,
              show: productCategories.length !== 0,
              security: {
                permissions: [[PermissionName.PRODUCT_CATEGORY_CREATE]],
              },
              onClick: () => {
                navigate(
                  toProjectProductCategoryCreateView({
                    tenantId: tenant.id,
                    projectId: project.id,
                  })
                );
              },
            },
            {
              __type: "Divider",
              show: productCategories.length !== 0 && products.length !== 0,
            },
            {
              __type: "Button",
              content: "Modifier des produits",
              disabled: false,
              show: products.length !== 0,
              security: {
                permissions: [[PermissionName.PRODUCT_UPDATE]],
              },
              onClick: () => {
                navigate(
                  toProjectProductsUpdateView({
                    tenantId: tenant.id,
                    projectId: project.id,
                  })
                );
              },
            },
          ],
        },
        filter: {
          left: {
            inputs: [
              <SearchInputGroup size="xs" placeholder="Recherche par nom" value={filterValues.search} onChange={filterFunctions.setSearch} />,
              <CheckPicker
                size="xs"
                label="Fournisseurs"
                value={filterValues.providerIds}
                onChange={filterFunctions.setProviderIds}
                data={providers
                  .filter((provider) => products.some((product) => product.provider.id === provider.id))
                  .map((provider) => ({
                    label: provider.name,
                    value: provider.id,
                  }))}
              />,
              <SelectPicker
                size="xs"
                label="État"
                value={filterValues.managedByModels}
                onChange={filterFunctions.setManagedByModels}
                cleanable
                searchable={false}
                data={[
                  { label: "Non géré par des modèles", value: "0" },
                  { label: "Géré par des modèles", value: "1" },
                ]}
              />,
            ],
            applyButton: null,
          },
          right: {
            inputs: [],
          },
        },
      }}
      content={{
        table: {
          emptyBodies: [
            {
              show: productCategories.length === 0,
              title: "Aucune categorie de produit",
              content: "Pour créer une catégorie de produit, cliquez sur le bouton ci-dessous.",
              button: {
                text: "Créer une catégorie",
                security: {
                  permissions: [[PermissionName.PRODUCT_CATEGORY_CREATE]],
                },
                onClick: () => {
                  navigate(
                    toProjectProductCategoryCreateView({
                      tenantId: tenant.id,
                      projectId: project.id,
                    })
                  );
                },
              },
            },
          ],
          headColumns: [
            {
              name: "label",
              value: "CATÉGORIE / ÉTIQUETTE DU PRODUIT",
            },
            {
              name: "is-valid",
              value: "",
            },
            {
              name: "activities",
              value: "ACTIVITÉS",
            },
            {
              name: "provider",
              value: "FOURNISSEUR",
            },
            {
              name: "provider-product-model",
              value: "MODÈLE",
            },
            {
              name: "provider-product-code",
              value: "CODE",
            },
          ],
          bodyRows: [],
          bodyRowGroups: applyFilters(productCategories).map((productCategory) => {
            return {
              key: productCategory.id,
              columns: [
                {
                  name: "label",
                  component: (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {productCategory.managedByModels && (
                        <TooltipWrapper tooltip="Lié au(x) modèle(s)">
                          <FontAwesomeIcon color="grey" size="sm" icon={faLink} />
                        </TooltipWrapper>
                      )}
                      {productCategory.description === null ? (
                        <span>{productCategory.name}</span>
                      ) : (
                        <TooltipWrapper tooltip={productCategory.description} highlight>
                          {productCategory.name}
                        </TooltipWrapper>
                      )}
                    </div>
                  ),
                },
              ],
              actions: [
                {
                  __type: "Button",
                  content: "Créer un produit",
                  show: !productCategory.managedByModels,
                  security: {
                    permissions: [[PermissionName.PRODUCT_CREATE]],
                  },
                  onClick: () => {
                    navigate(
                      toProjectProductCategoryProductCreateView({
                        tenantId: tenant.id,
                        projectId: project.id,
                        productCategoryId: productCategory.id,
                      })
                    );
                  },
                },
                {
                  __type: "Button",
                  content: "Modifier la catégorie",
                  show: true,
                  security: {
                    permissions: [[PermissionName.PRODUCT_CATEGORY_UPDATE]],
                  },
                  onClick: () => {
                    navigate(
                      toProjectProductCategoryUpdateView({
                        tenantId: tenant.id,
                        projectId: project.id,
                        productCategoryId: productCategory.id,
                      })
                    );
                  },
                },
                {
                  __type: "Button",
                  content: "Supprimer la catégorie",
                  show: !productCategory.managedByModels && productCategory.products.length === 0,
                  security: {
                    permissions: [[PermissionName.PRODUCT_CATEGORY_DELETE]],
                  },
                  onClick: () => {
                    setProductCategoryToDelete(productCategory);
                  },
                },
              ],
              rowEmpty: {
                title: "Aucun produit",
                security: {
                  permissions: [[PermissionName.PRODUCT_CREATE]],
                },
                deniedText: "Vous ne disposez pas des autorisations requises pour « Créer un produit ».",
                content: {
                  text: "Pour créer un produit, cliquez sur le bouton ci-dessous.",
                  button: {
                    text: "Créer un produit",
                    onClick: () => {
                      navigate(
                        toProjectProductCategoryProductCreateView({
                          tenantId: tenant.id,
                          projectId: project.id,
                          productCategoryId: productCategory.id,
                        })
                      );
                    },
                  },
                },
              },
              rows: productCategory.products.map((product) => ({
                key: product.id,
                href: toProjectProductView({
                  tenantId: tenant.id,
                  projectId: project.id,
                  productId: product.id,
                }),
                columns: [
                  {
                    name: "label",
                    component: (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                      >
                        <ProductThumbnail size="md" hasThumbnail={product.hasThumbnail} thumbnailUrl={product.thumbnailUrl} bordered />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            {product.managedByModels && (
                              <TooltipWrapper tooltip="Lié au(x) modèle(s)">
                                <FontAwesomeIcon color="grey" size="sm" icon={faLink} />
                              </TooltipWrapper>
                            )}
                            <Text highlight>{product.variant ?? product.name}</Text>
                          </div>
                          {product.format && <small>{product.format}</small>}
                          {product.description && <Text subtile>{product.description}</Text>}
                        </div>
                      </div>
                    ),
                  },
                  {
                    name: "is-valid",
                    component:
                      product.activities.length === 0 ? (
                        <TooltipWrapper tooltip="Ce produit doit être lié à au moins une activité." highlight>
                          <FontAwesomeIcon color="red" icon={faWarning} />
                        </TooltipWrapper>
                      ) : (
                        ""
                      ),
                  },
                  {
                    name: "activities",
                    component:
                      product.activities.length === 0 ? (
                        "--"
                      ) : (
                        <div className="product-activity-list">
                          {product.activities.map((activity) => (
                            <span key={activity.activityId}>{activity.activity.label}</span>
                          ))}
                        </div>
                      ),
                  },
                  {
                    name: "provider",
                    component: product.provider.provider?.name ?? "--",
                  },
                  {
                    name: "provider-product-model",
                    component: product.provider.productModel ?? "--",
                  },
                  {
                    name: "provider-product-code",
                    component: product.provider.productCode ?? "--",
                  },
                ],
                actions: [
                  {
                    __type: "Button",
                    content: "Modifier le produit",
                    show: true,
                    security: {
                      permissions: [[PermissionName.PRODUCT_UPDATE]],
                    },
                    onClick: () => {
                      navigate(
                        toProjectProductUpdateView({
                          tenantId: tenant.id,
                          projectId: project.id,
                          productId: product.id,
                        })
                      );
                    },
                  },
                  {
                    __type: "Button",
                    content: "Supprimer le produit",
                    show: !product.managedByModels,
                    security: {
                      permissions: [[PermissionName.PRODUCT_DELETE]],
                    },
                    onClick: () => {
                      setProductToDelete(product);
                    },
                  },
                ],
              })),
            };
          }),
        },
      }}
    >
      {productCategoryToDelete && (
        <ProductCategoryDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          productCategory={productCategoryToDelete}
          onCompleted={() => {
            setProductCategoryToDelete(null);
          }}
          onClose={() => {
            setProductCategoryToDelete(null);
          }}
        />
      )}
      {productToDelete && (
        <ProductDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          product={productToDelete}
          onCompleted={() => {
            setProductToDelete(null);
          }}
          onClose={() => {
            setProductToDelete(null);
          }}
        />
      )}
    </ViewList>
  );
};
