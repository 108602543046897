export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: Date; output: Date };
  Upload: { input: File; output: any };
};

export type Activity = {
  readonly __typename: "Activity";
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["ID"]["output"];
  readonly label: Scalars["String"]["output"];
  readonly number: Scalars["String"]["output"];
  readonly title: Scalars["String"]["output"];
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export type Address = {
  readonly __typename: "Address";
  readonly city: Scalars["String"]["output"];
  readonly countryCode: Scalars["String"]["output"];
  readonly line: Scalars["String"]["output"];
  readonly postalCode: Scalars["String"]["output"];
  readonly subdivisionCode: Scalars["String"]["output"];
};

export type AddressInput = {
  readonly city: Scalars["String"]["input"];
  readonly countryCode: Scalars["String"]["input"];
  readonly line: Scalars["String"]["input"];
  readonly postalCode: Scalars["String"]["input"];
  readonly subdivisionCode: Scalars["String"]["input"];
};

export type ArchiveFormInput = {
  readonly formId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type ArchiveFormPayload = {
  readonly __typename: "ArchiveFormPayload";
  readonly data: Maybe<ArchiveFormPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type ArchiveFormPayloadData = {
  readonly __typename: "ArchiveFormPayloadData";
  readonly form: Form;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type ArchiveSectionInput = {
  readonly projectId: Scalars["ID"]["input"];
  readonly sectionId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type ArchiveSectionPayload = {
  readonly __typename: "ArchiveSectionPayload";
  readonly data: Maybe<ArchiveSectionPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type ArchiveSectionPayloadData = {
  readonly __typename: "ArchiveSectionPayloadData";
  readonly projectId: Scalars["ID"]["output"];
  readonly section: Section;
  readonly tenantId: Scalars["ID"]["output"];
};

export type BillingReport = {
  readonly __typename: "BillingReport";
  readonly clientAmount: Scalars["Float"]["output"];
  readonly contingencyAmount: Scalars["Float"]["output"];
  readonly contractorAmount: Scalars["Float"]["output"];
  readonly promoterAmount: Scalars["Float"]["output"];
  readonly subcontractorAmount: Scalars["Float"]["output"];
  readonly tables: ReadonlyArray<BillingReportTable>;
};

export type BillingReportTable = {
  readonly __typename: "BillingReportTable";
  readonly clientAmount: Scalars["Float"]["output"];
  readonly contingencyAmount: Scalars["Float"]["output"];
  readonly contractId: Scalars["String"]["output"];
  readonly contractorAmount: Scalars["Float"]["output"];
  readonly isRevisionPending: Scalars["Boolean"]["output"];
  readonly promoterAmount: Scalars["Float"]["output"];
  readonly revisionNumber: Scalars["Int"]["output"];
  readonly rows: ReadonlyArray<BillingReportTableRow>;
  readonly subcontractorAmount: Scalars["Float"]["output"];
  readonly unitNumber: Scalars["String"]["output"];
};

export type BillingReportTableRow = {
  readonly __typename: "BillingReportTableRow";
  readonly clientAmount: Scalars["Float"]["output"];
  readonly contingencyAmount: Scalars["Float"]["output"];
  readonly contractorAmount: Scalars["Float"]["output"];
  readonly elementFullName: Scalars["String"]["output"];
  readonly promoterAmount: Scalars["Float"]["output"];
  readonly providerName: Maybe<Scalars["String"]["output"]>;
  readonly subcontractorAmount: Scalars["Float"]["output"];
  readonly type: BillingReportTableRowType;
};

export enum BillingReportTableRowType {
  CREDIT_SUBCONTRACTOR = "CREDIT_SUBCONTRACTOR",
  EXTRA = "EXTRA",
  MIX_AND_MATCH = "MIX_AND_MATCH",
  OTHER = "OTHER",
}

export type BlockUserInput = {
  readonly tenantId: Scalars["ID"]["input"];
  readonly userId: Scalars["ID"]["input"];
};

export type BlockUserPayload = {
  readonly __typename: "BlockUserPayload";
  readonly data: Maybe<BlockUserPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type BlockUserPayloadData = {
  readonly __typename: "BlockUserPayloadData";
  readonly tenantId: Scalars["ID"]["output"];
  readonly user: User;
};

export type CancelContractInput = {
  readonly contractId: Scalars["ID"]["input"];
  readonly isRefundable: Scalars["Boolean"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type CancelContractPayload = {
  readonly __typename: "CancelContractPayload";
  readonly data: Maybe<CancelContractPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type CancelContractPayloadData = {
  readonly __typename: "CancelContractPayloadData";
  readonly contract: Contract;
  readonly invoices: ReadonlyArray<Invoice>;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
  readonly unit: Unit;
};

export type ConfigureSectionInput = {
  readonly projectId: Scalars["ID"]["input"];
  readonly sectionId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type ConfigureSectionPayload = {
  readonly __typename: "ConfigureSectionPayload";
  readonly data: Maybe<ConfigureSectionPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type ConfigureSectionPayloadData = {
  readonly __typename: "ConfigureSectionPayloadData";
  readonly projectId: Scalars["ID"]["output"];
  readonly section: Section;
  readonly tenantId: Scalars["ID"]["output"];
};

export type ConfirmContractSelectionInput = {
  readonly contractId: Scalars["ID"]["input"];
  readonly isTermsAndConditionsAccepted: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  readonly projectId: Scalars["ID"]["input"];
  readonly selectionId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type ConfirmContractSelectionPayload = {
  readonly __typename: "ConfirmContractSelectionPayload";
  readonly data: Maybe<ConfirmContractSelectionPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type ConfirmContractSelectionPayloadData = {
  readonly __typename: "ConfirmContractSelectionPayloadData";
  readonly contract: Contract;
  readonly invoices: ReadonlyArray<Invoice>;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
  readonly unit: Unit;
};

export type ConstructionReport = {
  readonly __typename: "ConstructionReport";
  readonly baseTables: ReadonlyArray<ConstructionReportTable>;
  readonly contractSelectionInfos: ReadonlyArray<ReportContractSelectionInfo>;
  readonly customerInfos: ReadonlyArray<ReportCustomerInfo>;
  readonly extraTables: ReadonlyArray<ConstructionReportTable>;
  readonly numberOfExtras: Scalars["Int"]["output"];
  readonly unitFloor: Scalars["Int"]["output"];
  readonly unitNumber: Scalars["String"]["output"];
  readonly unitNumberOfBathrooms: Scalars["Int"]["output"];
  readonly unitNumberOfBedrooms: Scalars["Int"]["output"];
  readonly unitState: UnitState;
  readonly vibeTableRows: ReadonlyArray<ReportVibeTableRow>;
};

export type ConstructionReportTable = {
  readonly __typename: "ConstructionReportTable";
  readonly activityTitle: Scalars["String"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly rows: ReadonlyArray<ConstructionReportTableRow>;
};

export type ConstructionReportTableRow = {
  readonly __typename: "ConstructionReportTableRow";
  readonly comments: Scalars["String"]["output"];
  readonly elementName: Scalars["String"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly productCode: Scalars["String"]["output"];
  readonly productFormat: Scalars["String"]["output"];
  readonly productModel: Scalars["String"]["output"];
  readonly providerName: Scalars["String"]["output"];
  readonly roomName: Scalars["String"]["output"];
  readonly type: ReportCellType;
  readonly value: Scalars["String"]["output"];
};

export type Contract = {
  readonly __typename: "Contract";
  readonly balanceAmount: Scalars["Float"]["output"];
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly currentSelectionId: Maybe<Scalars["ID"]["output"]>;
  readonly customers: ReadonlyArray<ContractCustomer>;
  readonly formId: Maybe<Scalars["ID"]["output"]>;
  readonly id: Scalars["ID"]["output"];
  readonly isCanceled: Scalars["Boolean"]["output"];
  readonly nonRefundableAmount: Scalars["Float"]["output"];
  readonly previousSelectionId: Maybe<Scalars["ID"]["output"]>;
  readonly selections: ReadonlyArray<ContractSelection>;
  readonly state: ContractState;
  readonly totalAmount: Scalars["Float"]["output"];
  readonly unitId: Scalars["ID"]["output"];
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export type ContractCustomer = {
  readonly __typename: "ContractCustomer";
  readonly customerId: Scalars["String"]["output"];
  readonly index: Scalars["Int"]["output"];
};

export type ContractCustomerInput = {
  readonly customerId: Scalars["ID"]["input"];
  readonly index: Scalars["Int"]["input"];
};

export type ContractSelection = {
  readonly __typename: "ContractSelection";
  readonly billingSettings: ContractSelectionBillingSettings;
  readonly clientAmount: Scalars["Float"]["output"];
  readonly contingencyAmount: Scalars["Float"]["output"];
  readonly contractorAmount: Scalars["Float"]["output"];
  readonly costAmount: Scalars["Float"]["output"];
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly groups: ReadonlyArray<ContractSelectionGroup>;
  readonly id: Scalars["ID"]["output"];
  readonly invoiceId: Maybe<Scalars["ID"]["output"]>;
  readonly isTermsAndConditionsAccepted: Maybe<Scalars["Boolean"]["output"]>;
  readonly label: Scalars["String"]["output"];
  readonly numberOfExtras: Scalars["Int"]["output"];
  readonly promoterAmount: Scalars["Float"]["output"];
  readonly revisionNumber: Scalars["Int"]["output"];
  readonly revisionReason: Maybe<Scalars["String"]["output"]>;
  readonly state: ContractSelectionState;
  readonly subcontractorAmount: Scalars["Float"]["output"];
  readonly updatedAt: Scalars["DateTime"]["output"];
  readonly workflow: ContractSelectionWorkflow;
};

export type ContractSelectionBillingSettings = {
  readonly __typename: "ContractSelectionBillingSettings";
  readonly creditAmount: Scalars["Float"]["output"];
  readonly feePercent: Scalars["Float"]["output"];
  readonly mixAndMatchAmount: Scalars["Float"]["output"];
  readonly revisionAmount: Scalars["Float"]["output"];
  readonly taxGroupId: Scalars["ID"]["output"];
  readonly terms: InvoiceTermsOfPayment;
};

export type ContractSelectionGroup = {
  readonly __typename: "ContractSelectionGroup";
  readonly clientAmount: Scalars["Float"]["output"];
  readonly contingencyAmount: Scalars["Float"]["output"];
  readonly contractorAmount: Scalars["Float"]["output"];
  readonly costAmount: Scalars["Float"]["output"];
  readonly formSectionId: Scalars["ID"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly items: ReadonlyArray<ContractSelectionGroupItemUnion>;
  readonly numberOfExtras: Scalars["Int"]["output"];
  readonly promoterAmount: Scalars["Float"]["output"];
  readonly state: ContractSelectionGroupState;
  readonly subcontractorAmount: Scalars["Float"]["output"];
  readonly vibe: Maybe<ContractSelectionGroupVibe>;
};

export type ContractSelectionGroupItem = {
  readonly __typename: "ContractSelectionGroupItem";
  readonly breakdownLines: ReadonlyArray<ContractSelectionGroupItemBreakdownLine>;
  readonly clientAmount: Scalars["Float"]["output"];
  readonly contingencyAmount: Scalars["Float"]["output"];
  readonly contractorAmount: Scalars["Float"]["output"];
  readonly costAmount: Scalars["Float"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly promoterAmount: Scalars["Float"]["output"];
  readonly sectionElementId: Scalars["ID"]["output"];
  readonly sectionElementOptionId: Maybe<Scalars["ID"]["output"]>;
  readonly sectionElementOptionType: SectionElementOptionType;
  readonly sectionId: Scalars["ID"]["output"];
  readonly subcontractorAmount: Scalars["Float"]["output"];
};

export type ContractSelectionGroupItemBreakdownLine = {
  readonly __typename: "ContractSelectionGroupItemBreakdownLine";
  readonly activityId: Scalars["ID"]["output"];
  readonly contractorAmount: Scalars["Float"]["output"];
  readonly costAmount: Scalars["Float"]["output"];
  readonly promoterAmount: Scalars["Float"]["output"];
  readonly subcontractorAmount: Scalars["Float"]["output"];
};

export type ContractSelectionGroupItemInput = {
  readonly sectionElementId: Scalars["ID"]["input"];
  readonly sectionElementOptionId: InputMaybe<Scalars["ID"]["input"]>;
};

export type ContractSelectionGroupItemSet = {
  readonly __typename: "ContractSelectionGroupItemSet";
  readonly items: ReadonlyArray<ContractSelectionGroupItem>;
  readonly maxIndex: Scalars["Int"]["output"];
  readonly minIndex: Scalars["Int"]["output"];
  readonly sectionElementGroupId: Scalars["ID"]["output"];
};

export type ContractSelectionGroupItemUnion =
  | ContractSelectionGroupItem
  | ContractSelectionGroupItemSet;

export enum ContractSelectionGroupState {
  COMPLETED = "COMPLETED",
  PENDING = "PENDING",
}

export type ContractSelectionGroupVibe = {
  readonly __typename: "ContractSelectionGroupVibe";
  readonly clientAmount: Scalars["Float"]["output"];
  readonly contingencyAmount: Scalars["Float"]["output"];
  readonly contractorAmount: Scalars["Float"]["output"];
  readonly costAmount: Scalars["Float"]["output"];
  readonly mode: ContractSelectionGroupVibeMode;
  readonly promoterAmount: Scalars["Float"]["output"];
  readonly sectionVibeId: Scalars["ID"]["output"];
  readonly subcontractorAmount: Scalars["Float"]["output"];
};

export type ContractSelectionGroupVibeInput = {
  readonly mode: ContractSelectionGroupVibeMode;
  readonly sectionVibeId: Scalars["ID"]["input"];
};

export enum ContractSelectionGroupVibeMode {
  MIX_AND_MATCH = "MIX_AND_MATCH",
  STANDARD = "STANDARD",
  UPGRADE = "UPGRADE",
}

export enum ContractSelectionState {
  CANCELED = "CANCELED",
  FINISHED = "FINISHED",
  PENDING = "PENDING",
}

export enum ContractSelectionWorkflow {
  WITHOUT_CUSTOMER = "WITHOUT_CUSTOMER",
  WITH_CUSTOMER = "WITH_CUSTOMER",
}

export enum ContractState {
  COMPLETED = "COMPLETED",
  IS_CUSTOMIZED = "IS_CUSTOMIZED",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  REFUND_PENDING = "REFUND_PENDING",
  REVISION_PENDING = "REVISION_PENDING",
  SELECTION_PENDING = "SELECTION_PENDING",
  WITHOUT_FORM = "WITHOUT_FORM",
  WITHOUT_SELECTION = "WITHOUT_SELECTION",
}

export type Country = {
  readonly __typename: "Country";
  readonly callingCode: Scalars["String"]["output"];
  readonly code: Scalars["String"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly subdivisions: ReadonlyArray<CountrySubdivision>;
};

export type CountrySubdivision = {
  readonly __typename: "CountrySubdivision";
  readonly code: Scalars["String"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly type: CountrySubdivisionType;
};

export enum CountrySubdivisionType {
  DISTRICT = "DISTRICT",
  OUTLYING_AREA = "OUTLYING_AREA",
  PROVINCE = "PROVINCE",
  STATE = "STATE",
  TERRITORY = "TERRITORY",
}

export type CreateActivityInput = {
  readonly description: InputMaybe<Scalars["String"]["input"]>;
  readonly number: Scalars["String"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
  readonly title: Scalars["String"]["input"];
};

export type CreateActivityPayload = {
  readonly __typename: "CreateActivityPayload";
  readonly data: Maybe<CreateActivityPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type CreateActivityPayloadData = {
  readonly __typename: "CreateActivityPayloadData";
  readonly activity: Activity;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type CreateContractInput = {
  readonly customers: ReadonlyArray<ContractCustomerInput>;
  readonly document: Scalars["Upload"]["input"];
  readonly formId: InputMaybe<Scalars["ID"]["input"]>;
  readonly isCustomized: Scalars["Boolean"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
  readonly unitId: Scalars["ID"]["input"];
};

export type CreateContractInvoicePaymentInput = {
  readonly amount: Scalars["Float"]["input"];
  readonly contractId: Scalars["ID"]["input"];
  readonly date: Scalars["DateTime"]["input"];
  readonly invoiceId: Scalars["ID"]["input"];
  readonly mode: PaymentMode;
  readonly notes: InputMaybe<Scalars["String"]["input"]>;
  readonly projectId: Scalars["ID"]["input"];
  readonly referenceNumber: InputMaybe<Scalars["String"]["input"]>;
  readonly tenantId: Scalars["ID"]["input"];
};

export type CreateContractInvoicePaymentPayload = {
  readonly __typename: "CreateContractInvoicePaymentPayload";
  readonly data: Maybe<CreateContractInvoicePaymentPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type CreateContractInvoicePaymentPayloadData = {
  readonly __typename: "CreateContractInvoicePaymentPayloadData";
  readonly contract: Contract;
  readonly invoice: Invoice;
  readonly payment: Payment;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
  readonly unit: Unit;
};

export type CreateContractInvoiceRefundInput = {
  readonly amount: Scalars["Float"]["input"];
  readonly contractId: Scalars["ID"]["input"];
  readonly date: Scalars["DateTime"]["input"];
  readonly invoiceId: Scalars["ID"]["input"];
  readonly mode: RefundMode;
  readonly notes: InputMaybe<Scalars["String"]["input"]>;
  readonly projectId: Scalars["ID"]["input"];
  readonly referenceNumber: InputMaybe<Scalars["String"]["input"]>;
  readonly tenantId: Scalars["ID"]["input"];
};

export type CreateContractInvoiceRefundPayload = {
  readonly __typename: "CreateContractInvoiceRefundPayload";
  readonly data: Maybe<CreateContractInvoiceRefundPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type CreateContractInvoiceRefundPayloadData = {
  readonly __typename: "CreateContractInvoiceRefundPayloadData";
  readonly contract: Contract;
  readonly invoice: Invoice;
  readonly projectId: Scalars["ID"]["output"];
  readonly refund: Refund;
  readonly tenantId: Scalars["ID"]["output"];
  readonly unit: Unit;
};

export type CreateContractPayload = {
  readonly __typename: "CreateContractPayload";
  readonly data: Maybe<CreateContractPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type CreateContractPayloadData = {
  readonly __typename: "CreateContractPayloadData";
  readonly contract: Contract;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
  readonly unit: Unit;
};

export type CreateContractRefundInput = {
  readonly amount: Scalars["Float"]["input"];
  readonly contractId: Scalars["ID"]["input"];
  readonly date: Scalars["DateTime"]["input"];
  readonly mode: RefundMode;
  readonly notes: InputMaybe<Scalars["String"]["input"]>;
  readonly projectId: Scalars["ID"]["input"];
  readonly referenceNumber: InputMaybe<Scalars["String"]["input"]>;
  readonly tenantId: Scalars["ID"]["input"];
};

export type CreateContractRefundPayload = {
  readonly __typename: "CreateContractRefundPayload";
  readonly data: Maybe<CreateContractRefundPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type CreateContractRefundPayloadData = {
  readonly __typename: "CreateContractRefundPayloadData";
  readonly contract: Contract;
  readonly projectId: Scalars["ID"]["output"];
  readonly refund: Refund;
  readonly tenantId: Scalars["ID"]["output"];
  readonly unit: Unit;
};

export type CreateContractRevisionInput = {
  readonly contractId: Scalars["ID"]["input"];
  readonly creditAmount: Scalars["Float"]["input"];
  readonly mixAndMatchAmount: Scalars["Float"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly reason: InputMaybe<Scalars["String"]["input"]>;
  readonly revisionAmount: Scalars["Float"]["input"];
  readonly taxGroupId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
  readonly terms: InvoiceTermsOfPayment;
  readonly workflow: ContractSelectionWorkflow;
};

export type CreateContractRevisionPayload = {
  readonly __typename: "CreateContractRevisionPayload";
  readonly data: Maybe<CreateContractRevisionPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type CreateContractRevisionPayloadData = {
  readonly __typename: "CreateContractRevisionPayloadData";
  readonly contract: Contract;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
  readonly unit: Unit;
};

export type CreateContractSelectionInput = {
  readonly contractId: Scalars["ID"]["input"];
  readonly creditAmount: Scalars["Float"]["input"];
  readonly mixAndMatchAmount: Scalars["Float"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly taxGroupId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
  readonly terms: InvoiceTermsOfPayment;
  readonly workflow: ContractSelectionWorkflow;
};

export type CreateContractSelectionPayload = {
  readonly __typename: "CreateContractSelectionPayload";
  readonly data: Maybe<CreateContractSelectionPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type CreateContractSelectionPayloadData = {
  readonly __typename: "CreateContractSelectionPayloadData";
  readonly contract: Contract;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
  readonly unit: Unit;
};

export type CreateCustomerInput = {
  readonly address: AddressInput;
  readonly email: Scalars["String"]["input"];
  readonly firstName: Scalars["String"]["input"];
  readonly gender: Gender;
  readonly language: Language;
  readonly lastName: Scalars["String"]["input"];
  readonly notes: InputMaybe<Scalars["String"]["input"]>;
  readonly phone: Scalars["String"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type CreateCustomerPayload = {
  readonly __typename: "CreateCustomerPayload";
  readonly data: Maybe<CreateCustomerPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type CreateCustomerPayloadData = {
  readonly __typename: "CreateCustomerPayloadData";
  readonly customer: Customer;
  readonly tenantId: Scalars["ID"]["output"];
};

export type CreateFormInput = {
  readonly modelId: InputMaybe<Scalars["ID"]["input"]>;
  readonly name: Scalars["String"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly sections: ReadonlyArray<FormSectionInput>;
  readonly tenantId: Scalars["ID"]["input"];
};

export type CreateFormPayload = {
  readonly __typename: "CreateFormPayload";
  readonly data: Maybe<CreateFormPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type CreateFormPayloadData = {
  readonly __typename: "CreateFormPayloadData";
  readonly form: Form;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type CreateProductCategoryInput = {
  readonly description: InputMaybe<Scalars["String"]["input"]>;
  readonly displayName: InputMaybe<Scalars["String"]["input"]>;
  readonly name: Scalars["String"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type CreateProductCategoryPayload = {
  readonly __typename: "CreateProductCategoryPayload";
  readonly data: Maybe<CreateProductCategoryPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type CreateProductCategoryPayloadData = {
  readonly __typename: "CreateProductCategoryPayloadData";
  readonly productCategory: ProductCategory;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type CreateProductInput = {
  readonly activities: ReadonlyArray<ProductActivityInput>;
  readonly categoryId: Scalars["ID"]["input"];
  readonly description: InputMaybe<Scalars["String"]["input"]>;
  readonly format: InputMaybe<Scalars["String"]["input"]>;
  readonly projectId: Scalars["ID"]["input"];
  readonly provider: ProductProviderInput;
  readonly tenantId: Scalars["ID"]["input"];
  readonly thumbnail: InputMaybe<Scalars["Upload"]["input"]>;
  readonly variant: Scalars["String"]["input"];
};

export type CreateProductPayload = {
  readonly __typename: "CreateProductPayload";
  readonly data: Maybe<CreateProductPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type CreateProductPayloadData = {
  readonly __typename: "CreateProductPayloadData";
  readonly product: Product;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type CreateProjectInput = {
  readonly code: Scalars["String"]["input"];
  readonly contactInfo: ProjectContactInfoInput;
  readonly description: InputMaybe<Scalars["String"]["input"]>;
  readonly name: Scalars["String"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type CreateProjectPayload = {
  readonly __typename: "CreateProjectPayload";
  readonly data: Maybe<CreateProjectPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type CreateProjectPayloadData = {
  readonly __typename: "CreateProjectPayloadData";
  readonly project: Project;
  readonly tenantId: Scalars["ID"]["output"];
};

export type CreateProviderInput = {
  readonly description: InputMaybe<Scalars["String"]["input"]>;
  readonly name: Scalars["String"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
  readonly websiteUrl: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateProviderPayload = {
  readonly __typename: "CreateProviderPayload";
  readonly data: Maybe<CreateProviderPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type CreateProviderPayloadData = {
  readonly __typename: "CreateProviderPayloadData";
  readonly provider: Provider;
  readonly tenantId: Scalars["ID"]["output"];
};

export type CreateSectionElementInput = {
  readonly groupId: InputMaybe<Scalars["ID"]["input"]>;
  readonly hideProductCategory: Scalars["Boolean"]["input"];
  readonly id: Scalars["ID"]["input"];
  readonly index: Scalars["Int"]["input"];
  readonly isManagedByVibes: Scalars["Boolean"]["input"];
  readonly isOptional: Scalars["Boolean"]["input"];
  readonly label: Scalars["String"]["input"];
  readonly modelLink: InputMaybe<CreateSectionElementModelLinkInput>;
  readonly notes: InputMaybe<Scalars["String"]["input"]>;
  readonly options: ReadonlyArray<SectionElementOptionInput>;
  readonly type: SectionElementType;
};

export type CreateSectionElementModelLinkInput = {
  readonly modelId: Scalars["ID"]["input"];
  readonly modelRoomElementId: Scalars["ID"]["input"];
  readonly modelRoomId: Scalars["ID"]["input"];
};

export type CreateSectionInput = {
  readonly elementGroups: ReadonlyArray<SectionElementGroupInput>;
  readonly elements: ReadonlyArray<CreateSectionElementInput>;
  readonly modelLink: InputMaybe<CreateSectionModelLinkInput>;
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
  readonly title: Scalars["String"]["input"];
};

export type CreateSectionModelLinkInput = {
  readonly modelId: Scalars["ID"]["input"];
  readonly modelRoomId: Scalars["ID"]["input"];
};

export type CreateSectionPayload = {
  readonly __typename: "CreateSectionPayload";
  readonly data: Maybe<CreateSectionPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type CreateSectionPayloadData = {
  readonly __typename: "CreateSectionPayloadData";
  readonly products: ReadonlyArray<Product>;
  readonly projectId: Scalars["ID"]["output"];
  readonly section: Section;
  readonly tenantId: Scalars["ID"]["output"];
};

export type CreateSectionVibeInput = {
  readonly clientAmount: Scalars["Float"]["input"];
  readonly items: ReadonlyArray<SectionVibeItemInput>;
  readonly mode: SectionVibeMode;
  readonly name: Scalars["String"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly sectionId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type CreateSectionVibePayload = {
  readonly __typename: "CreateSectionVibePayload";
  readonly data: Maybe<CreateSectionVibePayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type CreateSectionVibePayloadData = {
  readonly __typename: "CreateSectionVibePayloadData";
  readonly projectId: Scalars["ID"]["output"];
  readonly section: Section;
  readonly tenantId: Scalars["ID"]["output"];
};

export type CreateUnitInput = {
  readonly floor: Scalars["Int"]["input"];
  readonly formId: InputMaybe<Scalars["ID"]["input"]>;
  readonly number: Scalars["String"]["input"];
  readonly numberOfBathrooms: Scalars["Int"]["input"];
  readonly numberOfBedrooms: Scalars["Int"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type CreateUnitPayload = {
  readonly __typename: "CreateUnitPayload";
  readonly data: Maybe<CreateUnitPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type CreateUnitPayloadData = {
  readonly __typename: "CreateUnitPayloadData";
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
  readonly unit: Unit;
};

export type CreateUserInput = {
  readonly email: Scalars["String"]["input"];
  readonly firstName: Scalars["String"]["input"];
  readonly hasAccessToAllProjects: Scalars["Boolean"]["input"];
  readonly hasAccessToTestTenant: Scalars["Boolean"]["input"];
  readonly lastName: Scalars["String"]["input"];
  readonly projectIds: ReadonlyArray<Scalars["ID"]["input"]>;
  readonly roleId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type CreateUserPayload = {
  readonly __typename: "CreateUserPayload";
  readonly data: Maybe<CreateUserPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type CreateUserPayloadData = {
  readonly __typename: "CreateUserPayloadData";
  readonly tenantId: Scalars["ID"]["output"];
  readonly user: User;
};

export enum CurrencyCode {
  CAD = "CAD",
}

export type Customer = {
  readonly __typename: "Customer";
  readonly address: Address;
  readonly companyName: Maybe<Scalars["String"]["output"]>;
  readonly displayName: Scalars["String"]["output"];
  readonly email: Scalars["String"]["output"];
  readonly firstName: Maybe<Scalars["String"]["output"]>;
  readonly fullName: Maybe<Scalars["String"]["output"]>;
  readonly gender: Gender;
  readonly id: Scalars["ID"]["output"];
  readonly isDefault: Scalars["Boolean"]["output"];
  readonly label: Scalars["String"]["output"];
  readonly language: Language;
  readonly lastName: Maybe<Scalars["String"]["output"]>;
  readonly notes: Maybe<Scalars["String"]["output"]>;
  readonly phone: Scalars["String"]["output"];
  readonly type: CustomerType;
};

export type CustomerReport = {
  readonly __typename: "CustomerReport";
  readonly baseTables: ReadonlyArray<CustomerReportTable>;
  readonly contractSelectionInfos: ReadonlyArray<ReportContractSelectionInfo>;
  readonly customerInfos: ReadonlyArray<ReportCustomerInfo>;
  readonly extraTables: ReadonlyArray<CustomerReportTable>;
  readonly numberOfExtras: Scalars["Int"]["output"];
  readonly unitFloor: Scalars["Int"]["output"];
  readonly unitNumber: Scalars["String"]["output"];
  readonly unitNumberOfBathrooms: Scalars["Int"]["output"];
  readonly unitNumberOfBedrooms: Scalars["Int"]["output"];
  readonly unitState: UnitState;
  readonly vibeTableRows: ReadonlyArray<ReportVibeTableRow>;
};

export type CustomerReportTable = {
  readonly __typename: "CustomerReportTable";
  readonly activityTitle: Scalars["String"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly rows: ReadonlyArray<CustomerReportTableRow>;
};

export type CustomerReportTableRow = {
  readonly __typename: "CustomerReportTableRow";
  readonly comments: Scalars["String"]["output"];
  readonly elementName: Scalars["String"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly roomName: Scalars["String"]["output"];
  readonly type: ReportCellType;
  readonly value: Scalars["String"]["output"];
};

export enum CustomerType {
  BUSINESS = "BUSINESS",
  INDIVIDUAL = "INDIVIDUAL",
}

export type DeleteActivityInput = {
  readonly activityId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type DeleteActivityPayload = {
  readonly __typename: "DeleteActivityPayload";
  readonly data: Maybe<DeleteActivityPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DeleteActivityPayloadData = {
  readonly __typename: "DeleteActivityPayloadData";
  readonly activityId: Scalars["ID"]["output"];
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type DeleteContractInput = {
  readonly contractId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type DeleteContractInvoicePaymentInput = {
  readonly contractId: Scalars["ID"]["input"];
  readonly invoiceId: Scalars["ID"]["input"];
  readonly paymentId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type DeleteContractInvoicePaymentPayload = {
  readonly __typename: "DeleteContractInvoicePaymentPayload";
  readonly data: Maybe<DeleteContractInvoicePaymentPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DeleteContractInvoicePaymentPayloadData = {
  readonly __typename: "DeleteContractInvoicePaymentPayloadData";
  readonly contract: Contract;
  readonly invoice: Invoice;
  readonly paymentId: Scalars["ID"]["output"];
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type DeleteContractInvoiceRefundInput = {
  readonly contractId: Scalars["ID"]["input"];
  readonly invoiceId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly refundId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type DeleteContractInvoiceRefundPayload = {
  readonly __typename: "DeleteContractInvoiceRefundPayload";
  readonly data: Maybe<DeleteContractInvoiceRefundPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DeleteContractInvoiceRefundPayloadData = {
  readonly __typename: "DeleteContractInvoiceRefundPayloadData";
  readonly contract: Contract;
  readonly invoice: Invoice;
  readonly projectId: Scalars["ID"]["output"];
  readonly refundId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type DeleteContractPayload = {
  readonly __typename: "DeleteContractPayload";
  readonly data: Maybe<DeleteContractPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DeleteContractPayloadData = {
  readonly __typename: "DeleteContractPayloadData";
  readonly contractId: Scalars["ID"]["output"];
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
  readonly unit: Unit;
};

export type DeleteContractRefundInput = {
  readonly contractId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly refundId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type DeleteContractRefundPayload = {
  readonly __typename: "DeleteContractRefundPayload";
  readonly data: Maybe<DeleteContractRefundPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DeleteContractRefundPayloadData = {
  readonly __typename: "DeleteContractRefundPayloadData";
  readonly contract: Contract;
  readonly projectId: Scalars["ID"]["output"];
  readonly refundId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type DeleteContractRevisionInput = {
  readonly contractId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly selectionId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type DeleteContractRevisionPayload = {
  readonly __typename: "DeleteContractRevisionPayload";
  readonly data: Maybe<DeleteContractRevisionPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DeleteContractRevisionPayloadData = {
  readonly __typename: "DeleteContractRevisionPayloadData";
  readonly contract: Contract;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
  readonly unit: Unit;
};

export type DeleteContractSelectionInput = {
  readonly contractId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly selectionId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type DeleteContractSelectionPayload = {
  readonly __typename: "DeleteContractSelectionPayload";
  readonly data: Maybe<DeleteContractSelectionPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DeleteContractSelectionPayloadData = {
  readonly __typename: "DeleteContractSelectionPayloadData";
  readonly contract: Contract;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
  readonly unit: Unit;
};

export type DeleteCustomerInput = {
  readonly customerId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type DeleteCustomerPayload = {
  readonly __typename: "DeleteCustomerPayload";
  readonly data: Maybe<DeleteCustomerPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DeleteCustomerPayloadData = {
  readonly __typename: "DeleteCustomerPayloadData";
  readonly customerId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type DeleteFormInput = {
  readonly formId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type DeleteFormPayload = {
  readonly __typename: "DeleteFormPayload";
  readonly data: Maybe<DeleteFormPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DeleteFormPayloadData = {
  readonly __typename: "DeleteFormPayloadData";
  readonly formId: Scalars["ID"]["output"];
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type DeleteModelInput = {
  readonly modelId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type DeleteModelPayload = {
  readonly __typename: "DeleteModelPayload";
  readonly data: Maybe<DeleteModelPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DeleteModelPayloadData = {
  readonly __typename: "DeleteModelPayloadData";
  readonly modelId: Scalars["ID"]["output"];
  readonly productCategoryIds: ReadonlyArray<Scalars["ID"]["output"]>;
  readonly productIds: ReadonlyArray<Scalars["ID"]["output"]>;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type DeleteProductCategoryInput = {
  readonly productCategoryId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type DeleteProductCategoryPayload = {
  readonly __typename: "DeleteProductCategoryPayload";
  readonly data: Maybe<DeleteProductCategoryPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DeleteProductCategoryPayloadData = {
  readonly __typename: "DeleteProductCategoryPayloadData";
  readonly productCategoryId: Scalars["ID"]["output"];
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type DeleteProductInput = {
  readonly productId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type DeleteProductPayload = {
  readonly __typename: "DeleteProductPayload";
  readonly data: Maybe<DeleteProductPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DeleteProductPayloadData = {
  readonly __typename: "DeleteProductPayloadData";
  readonly productId: Scalars["ID"]["output"];
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type DeleteProjectInput = {
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type DeleteProjectPayload = {
  readonly __typename: "DeleteProjectPayload";
  readonly data: Maybe<DeleteProjectPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DeleteProjectPayloadData = {
  readonly __typename: "DeleteProjectPayloadData";
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type DeleteProviderInput = {
  readonly providerId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type DeleteProviderPayload = {
  readonly __typename: "DeleteProviderPayload";
  readonly data: Maybe<DeleteProviderPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DeleteProviderPayloadData = {
  readonly __typename: "DeleteProviderPayloadData";
  readonly providerId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type DeleteSectionInput = {
  readonly projectId: Scalars["ID"]["input"];
  readonly sectionId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type DeleteSectionPayload = {
  readonly __typename: "DeleteSectionPayload";
  readonly data: Maybe<DeleteSectionPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DeleteSectionPayloadData = {
  readonly __typename: "DeleteSectionPayloadData";
  readonly products: ReadonlyArray<Product>;
  readonly projectId: Scalars["ID"]["output"];
  readonly sectionId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type DeleteSectionVibeInput = {
  readonly projectId: Scalars["ID"]["input"];
  readonly sectionId: Scalars["ID"]["input"];
  readonly sectionVibeId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type DeleteSectionVibePayload = {
  readonly __typename: "DeleteSectionVibePayload";
  readonly data: Maybe<DeleteSectionVibePayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DeleteSectionVibePayloadData = {
  readonly __typename: "DeleteSectionVibePayloadData";
  readonly projectId: Scalars["ID"]["output"];
  readonly section: Section;
  readonly tenantId: Scalars["ID"]["output"];
};

export type DeleteUnitInput = {
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
  readonly unitId: Scalars["ID"]["input"];
};

export type DeleteUnitPayload = {
  readonly __typename: "DeleteUnitPayload";
  readonly data: Maybe<DeleteUnitPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DeleteUnitPayloadData = {
  readonly __typename: "DeleteUnitPayloadData";
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
  readonly unitId: Scalars["ID"]["output"];
};

export type DownloadContractInput = {
  readonly contractId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type DownloadContractPayload = {
  readonly __typename: "DownloadContractPayload";
  readonly data: Maybe<DownloadContractPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DownloadContractPayloadData = {
  readonly __typename: "DownloadContractPayloadData";
  readonly documentUrl: Scalars["String"]["output"];
};

export type DownloadTemplateInput = {
  readonly name: TemplateName;
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type DownloadTemplatePayload = {
  readonly __typename: "DownloadTemplatePayload";
  readonly data: Maybe<DownloadTemplatePayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type DownloadTemplatePayloadData = {
  readonly __typename: "DownloadTemplatePayloadData";
  readonly templateUrl: Scalars["String"]["output"];
};

export type Error = {
  readonly __typename: "Error";
  readonly fieldName: Maybe<Scalars["String"]["output"]>;
  readonly message: Scalars["String"]["output"];
};

export type Form = {
  readonly __typename: "Form";
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly modelId: Maybe<Scalars["ID"]["output"]>;
  readonly name: Scalars["String"]["output"];
  readonly reportRoomElementLinks: ReadonlyArray<FormReportRoomElementLink>;
  readonly reportVibeRoomLinks: ReadonlyArray<FormReportVibeRoomLink>;
  readonly sections: ReadonlyArray<FormSection>;
  readonly state: FormState;
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export type FormReportRoomElementLink = {
  readonly __typename: "FormReportRoomElementLink";
  readonly formSectionId: Scalars["ID"]["output"];
  readonly reportId: Scalars["ID"]["output"];
  readonly reportRoomElementId: Scalars["ID"]["output"];
  readonly reportRoomId: Scalars["ID"]["output"];
  readonly sectionElementId: Scalars["ID"]["output"];
};

export type FormReportRoomElementLinkInput = {
  readonly formSectionId: Scalars["ID"]["input"];
  readonly reportRoomElementId: Scalars["ID"]["input"];
  readonly reportRoomId: Scalars["ID"]["input"];
  readonly sectionElementId: Scalars["ID"]["input"];
};

export type FormReportVibeRoomLink = {
  readonly __typename: "FormReportVibeRoomLink";
  readonly formSectionId: Scalars["ID"]["output"];
  readonly reportId: Scalars["ID"]["output"];
  readonly reportRoomId: Scalars["ID"]["output"];
};

export type FormReportVibeRoomLinkInput = {
  readonly formSectionId: Scalars["ID"]["input"];
  readonly reportRoomId: Scalars["ID"]["input"];
};

export type FormSection = {
  readonly __typename: "FormSection";
  readonly id: Scalars["ID"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly sectionId: Scalars["ID"]["output"];
  readonly title: Scalars["String"]["output"];
};

export type FormSectionInput = {
  readonly id: Scalars["ID"]["input"];
  readonly index: Scalars["Int"]["input"];
  readonly sectionId: Scalars["ID"]["input"];
  readonly title: Scalars["String"]["input"];
};

export enum FormState {
  ARCHIVED = "ARCHIVED",
  CREATED = "CREATED",
  PUBLISHED = "PUBLISHED",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  NONE = "NONE",
}

export type ImportModelInput = {
  readonly dataBlobFile: Scalars["Upload"]["input"];
  readonly productThumbnailBlobFiles: ReadonlyArray<Scalars["Upload"]["input"]>;
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
  readonly unityDataBlobFile: Scalars["Upload"]["input"];
  readonly unityFrameworkBlobFile: Scalars["Upload"]["input"];
  readonly unityLoaderBlobFile: Scalars["Upload"]["input"];
  readonly unityWasmBlobFile: Scalars["Upload"]["input"];
};

export type ImportModelPayload = {
  readonly __typename: "ImportModelPayload";
  readonly data: Maybe<ImportModelPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type ImportModelPayloadData = {
  readonly __typename: "ImportModelPayloadData";
  readonly model: Model;
  readonly productCategories: ReadonlyArray<ProductCategory>;
  readonly products: ReadonlyArray<Product>;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type ImportUnitsInput = {
  readonly file: Scalars["Upload"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type ImportUnitsPayload = {
  readonly __typename: "ImportUnitsPayload";
  readonly data: Maybe<ImportUnitsPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type ImportUnitsPayloadData = {
  readonly __typename: "ImportUnitsPayloadData";
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
  readonly units: ReadonlyArray<Unit>;
};

export type Invoice = {
  readonly __typename: "Invoice";
  readonly balanceAmount: Scalars["Float"]["output"];
  readonly contractId: Maybe<Scalars["ID"]["output"]>;
  readonly contractSelectionId: Maybe<Scalars["ID"]["output"]>;
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly creditAfterTaxAmount: Scalars["Float"]["output"];
  readonly creditBeforeTaxAmount: Scalars["Float"]["output"];
  readonly customerInfo: InvoiceCustomerInfo;
  readonly customers: ReadonlyArray<InvoiceCustomer>;
  readonly date: Scalars["DateTime"]["output"];
  readonly due: Scalars["DateTime"]["output"];
  readonly feeAmount: Scalars["Float"]["output"];
  readonly feePercent: Scalars["Float"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly items: ReadonlyArray<InvoiceItem>;
  readonly label: Scalars["String"]["output"];
  readonly number: Scalars["Int"]["output"];
  readonly status: InvoiceStatus;
  readonly subtotalAmount: Scalars["Float"]["output"];
  readonly taxAmount: Scalars["Float"]["output"];
  readonly taxPercent: Scalars["Float"]["output"];
  readonly terms: InvoiceTermsOfPayment;
  readonly totalAmount: Scalars["Float"]["output"];
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export type InvoiceCustomer = {
  readonly __typename: "InvoiceCustomer";
  readonly customerId: Scalars["String"]["output"];
  readonly index: Scalars["Int"]["output"];
};

export type InvoiceCustomerInfo = {
  readonly __typename: "InvoiceCustomerInfo";
  readonly address: Address;
  readonly name: Scalars["String"]["output"];
};

export type InvoiceItem = {
  readonly __typename: "InvoiceItem";
  readonly amount: Scalars["Float"]["output"];
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["ID"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly quantity: Scalars["Float"]["output"];
  readonly type: InvoiceItemType;
  readonly unitPrice: Scalars["Float"]["output"];
};

export enum InvoiceItemType {
  EXTRA = "EXTRA",
  MIX_AND_MATCH = "MIX_AND_MATCH",
  OTHER = "OTHER",
}

export enum InvoiceStatus {
  CANCELED = "CANCELED",
  CREDITED = "CREDITED",
  NO_CHARGE = "NO_CHARGE",
  PAID = "PAID",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
}

export enum InvoiceTermsOfPayment {
  NET0 = "NET0",
  NET7 = "NET7",
  NET15 = "NET15",
  NET21 = "NET21",
  NET30 = "NET30",
  NET45 = "NET45",
  NET60 = "NET60",
}

export enum Language {
  EN = "EN",
  FR = "FR",
}

export type LinkFormInput = {
  readonly formId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly reportId: Scalars["ID"]["input"];
  readonly reportRoomElementLinks: ReadonlyArray<FormReportRoomElementLinkInput>;
  readonly reportVibeRoomLinks: ReadonlyArray<FormReportVibeRoomLinkInput>;
  readonly tenantId: Scalars["ID"]["input"];
};

export type LinkFormPayload = {
  readonly __typename: "LinkFormPayload";
  readonly data: Maybe<LinkFormPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type LinkFormPayloadData = {
  readonly __typename: "LinkFormPayloadData";
  readonly form: Form;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type LoadModelInput = {
  readonly modelId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type LoadModelPayload = {
  readonly __typename: "LoadModelPayload";
  readonly data: Maybe<LoadModelPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type LoadModelPayloadData = {
  readonly __typename: "LoadModelPayloadData";
  readonly unityCodeUrl: Scalars["String"]["output"];
  readonly unityDataUrl: Scalars["String"]["output"];
  readonly unityFrameworkUrl: Scalars["String"]["output"];
  readonly unityLoaderUrl: Scalars["String"]["output"];
};

export enum LogOperation {
  MUTATION = "MUTATION",
  QUERY = "QUERY",
}

export enum LogType {
  ERROR = "ERROR",
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
}

export type Model = {
  readonly __typename: "Model";
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly rooms: ReadonlyArray<ModelRoom>;
  readonly sharedId: Scalars["ID"]["output"];
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export type ModelRoom = {
  readonly __typename: "ModelRoom";
  readonly elements: ReadonlyArray<ModelRoomElement>;
  readonly id: Scalars["ID"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly sharedId: Scalars["ID"]["output"];
};

export type ModelRoomElement = {
  readonly __typename: "ModelRoomElement";
  readonly id: Scalars["ID"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly options: ReadonlyArray<ModelRoomElementOption>;
  readonly sharedId: Scalars["ID"]["output"];
  readonly type: ModelRoomElementType;
};

export type ModelRoomElementOption = {
  readonly __typename: "ModelRoomElementOption";
  readonly id: Scalars["ID"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly productId: Scalars["ID"]["output"];
  readonly sharedId: Scalars["ID"]["output"];
};

export enum ModelRoomElementType {
  ACCESSORY = "ACCESSORY",
  MATERIAL = "MATERIAL",
  NONE = "NONE",
}

export type Mutation = {
  readonly __typename: "Mutation";
  readonly archiveForm: ArchiveFormPayload;
  readonly archiveSection: ArchiveSectionPayload;
  readonly blockUser: BlockUserPayload;
  readonly cancelContract: CancelContractPayload;
  readonly configureSection: ConfigureSectionPayload;
  readonly confirmContractSelection: ConfirmContractSelectionPayload;
  readonly createActivity: CreateActivityPayload;
  readonly createContract: CreateContractPayload;
  readonly createContractInvoicePayment: CreateContractInvoicePaymentPayload;
  readonly createContractInvoiceRefund: CreateContractInvoiceRefundPayload;
  readonly createContractRefund: CreateContractRefundPayload;
  readonly createContractRevision: CreateContractRevisionPayload;
  readonly createContractSelection: CreateContractSelectionPayload;
  readonly createCustomer: CreateCustomerPayload;
  readonly createForm: CreateFormPayload;
  readonly createProduct: CreateProductPayload;
  readonly createProductCategory: CreateProductCategoryPayload;
  readonly createProject: CreateProjectPayload;
  readonly createProvider: CreateProviderPayload;
  readonly createSection: CreateSectionPayload;
  readonly createSectionVibe: CreateSectionVibePayload;
  readonly createUnit: CreateUnitPayload;
  readonly createUser: CreateUserPayload;
  readonly deleteActivity: DeleteActivityPayload;
  readonly deleteContract: DeleteContractPayload;
  readonly deleteContractInvoicePayment: DeleteContractInvoicePaymentPayload;
  readonly deleteContractInvoiceRefund: DeleteContractInvoiceRefundPayload;
  readonly deleteContractRefund: DeleteContractRefundPayload;
  readonly deleteContractRevision: DeleteContractRevisionPayload;
  readonly deleteContractSelection: DeleteContractSelectionPayload;
  readonly deleteCustomer: DeleteCustomerPayload;
  readonly deleteForm: DeleteFormPayload;
  readonly deleteModel: DeleteModelPayload;
  readonly deleteProduct: DeleteProductPayload;
  readonly deleteProductCategory: DeleteProductCategoryPayload;
  readonly deleteProject: DeleteProjectPayload;
  readonly deleteProvider: DeleteProviderPayload;
  readonly deleteSection: DeleteSectionPayload;
  readonly deleteSectionVibe: DeleteSectionVibePayload;
  readonly deleteUnit: DeleteUnitPayload;
  readonly downloadContract: DownloadContractPayload;
  readonly downloadTemplate: DownloadTemplatePayload;
  readonly importModel: ImportModelPayload;
  readonly importUnits: ImportUnitsPayload;
  readonly linkForm: LinkFormPayload;
  readonly loadModel: LoadModelPayload;
  readonly publishForm: PublishFormPayload;
  readonly publishSection: PublishSectionPayload;
  readonly resendUserInvitation: ResendUserInvitationPayload;
  readonly unblockUser: UnblockUserPayload;
  readonly updateActivity: UpdateActivityPayload;
  readonly updateContractInvoicePayment: UpdateContractInvoicePaymentPayload;
  readonly updateContractInvoiceRefund: UpdateContractInvoiceRefundPayload;
  readonly updateContractRefund: UpdateContractRefundPayload;
  readonly updateContractRevision: UpdateContractRevisionPayload;
  readonly updateContractSelection: UpdateContractSelectionPayload;
  readonly updateContractSelectionGroup: UpdateContractSelectionGroupPayload;
  readonly updateCustomer: UpdateCustomerPayload;
  readonly updateForm: UpdateFormPayload;
  readonly updateProduct: UpdateProductPayload;
  readonly updateProductCategory: UpdateProductCategoryPayload;
  readonly updateProducts: UpdateProductsPayload;
  readonly updateProject: UpdateProjectPayload;
  readonly updateProvider: UpdateProviderPayload;
  readonly updateReport: UpdateReportPayload;
  readonly updateSection: UpdateSectionPayload;
  readonly updateSectionVibe: UpdateSectionVibePayload;
  readonly updateUnit: UpdateUnitPayload;
  readonly updateUnits: UpdateUnitsPayload;
  readonly updateUser: UpdateUserPayload;
};

export type MutationArchiveFormArgs = {
  input: ArchiveFormInput;
};

export type MutationArchiveSectionArgs = {
  input: ArchiveSectionInput;
};

export type MutationBlockUserArgs = {
  input: BlockUserInput;
};

export type MutationCancelContractArgs = {
  input: CancelContractInput;
};

export type MutationConfigureSectionArgs = {
  input: ConfigureSectionInput;
};

export type MutationConfirmContractSelectionArgs = {
  input: ConfirmContractSelectionInput;
};

export type MutationCreateActivityArgs = {
  input: CreateActivityInput;
};

export type MutationCreateContractArgs = {
  input: CreateContractInput;
};

export type MutationCreateContractInvoicePaymentArgs = {
  input: CreateContractInvoicePaymentInput;
};

export type MutationCreateContractInvoiceRefundArgs = {
  input: CreateContractInvoiceRefundInput;
};

export type MutationCreateContractRefundArgs = {
  input: CreateContractRefundInput;
};

export type MutationCreateContractRevisionArgs = {
  input: CreateContractRevisionInput;
};

export type MutationCreateContractSelectionArgs = {
  input: CreateContractSelectionInput;
};

export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};

export type MutationCreateFormArgs = {
  input: CreateFormInput;
};

export type MutationCreateProductArgs = {
  input: CreateProductInput;
};

export type MutationCreateProductCategoryArgs = {
  input: CreateProductCategoryInput;
};

export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};

export type MutationCreateProviderArgs = {
  input: CreateProviderInput;
};

export type MutationCreateSectionArgs = {
  input: CreateSectionInput;
};

export type MutationCreateSectionVibeArgs = {
  input: CreateSectionVibeInput;
};

export type MutationCreateUnitArgs = {
  input: CreateUnitInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeleteActivityArgs = {
  input: DeleteActivityInput;
};

export type MutationDeleteContractArgs = {
  input: DeleteContractInput;
};

export type MutationDeleteContractInvoicePaymentArgs = {
  input: DeleteContractInvoicePaymentInput;
};

export type MutationDeleteContractInvoiceRefundArgs = {
  input: DeleteContractInvoiceRefundInput;
};

export type MutationDeleteContractRefundArgs = {
  input: DeleteContractRefundInput;
};

export type MutationDeleteContractRevisionArgs = {
  input: DeleteContractRevisionInput;
};

export type MutationDeleteContractSelectionArgs = {
  input: DeleteContractSelectionInput;
};

export type MutationDeleteCustomerArgs = {
  input: DeleteCustomerInput;
};

export type MutationDeleteFormArgs = {
  input: DeleteFormInput;
};

export type MutationDeleteModelArgs = {
  input: DeleteModelInput;
};

export type MutationDeleteProductArgs = {
  input: DeleteProductInput;
};

export type MutationDeleteProductCategoryArgs = {
  input: DeleteProductCategoryInput;
};

export type MutationDeleteProjectArgs = {
  input: DeleteProjectInput;
};

export type MutationDeleteProviderArgs = {
  input: DeleteProviderInput;
};

export type MutationDeleteSectionArgs = {
  input: DeleteSectionInput;
};

export type MutationDeleteSectionVibeArgs = {
  input: DeleteSectionVibeInput;
};

export type MutationDeleteUnitArgs = {
  input: DeleteUnitInput;
};

export type MutationDownloadContractArgs = {
  input: DownloadContractInput;
};

export type MutationDownloadTemplateArgs = {
  input: DownloadTemplateInput;
};

export type MutationImportModelArgs = {
  input: ImportModelInput;
};

export type MutationImportUnitsArgs = {
  input: ImportUnitsInput;
};

export type MutationLinkFormArgs = {
  input: LinkFormInput;
};

export type MutationLoadModelArgs = {
  input: LoadModelInput;
};

export type MutationPublishFormArgs = {
  input: PublishFormInput;
};

export type MutationPublishSectionArgs = {
  input: PublishSectionInput;
};

export type MutationResendUserInvitationArgs = {
  input: ResendUserInvitationInput;
};

export type MutationUnblockUserArgs = {
  input: UnblockUserInput;
};

export type MutationUpdateActivityArgs = {
  input: UpdateActivityInput;
};

export type MutationUpdateContractInvoicePaymentArgs = {
  input: UpdateContractInvoicePaymentInput;
};

export type MutationUpdateContractInvoiceRefundArgs = {
  input: UpdateContractInvoiceRefundInput;
};

export type MutationUpdateContractRefundArgs = {
  input: UpdateContractRefundInput;
};

export type MutationUpdateContractRevisionArgs = {
  input: UpdateContractRevisionInput;
};

export type MutationUpdateContractSelectionArgs = {
  input: UpdateContractSelectionInput;
};

export type MutationUpdateContractSelectionGroupArgs = {
  input: UpdateContractSelectionGroupInput;
};

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUpdateFormArgs = {
  input: UpdateFormInput;
};

export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};

export type MutationUpdateProductCategoryArgs = {
  input: UpdateProductCategoryInput;
};

export type MutationUpdateProductsArgs = {
  input: UpdateProductsInput;
};

export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};

export type MutationUpdateProviderArgs = {
  input: UpdateProviderInput;
};

export type MutationUpdateReportArgs = {
  input: UpdateReportInput;
};

export type MutationUpdateSectionArgs = {
  input: UpdateSectionInput;
};

export type MutationUpdateSectionVibeArgs = {
  input: UpdateSectionVibeInput;
};

export type MutationUpdateUnitArgs = {
  input: UpdateUnitInput;
};

export type MutationUpdateUnitsArgs = {
  input: UpdateUnitsInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type Payment = {
  readonly __typename: "Payment";
  readonly amount: Scalars["Float"]["output"];
  readonly contractId: Maybe<Scalars["ID"]["output"]>;
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly date: Scalars["DateTime"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly invoiceId: Maybe<Scalars["ID"]["output"]>;
  readonly isLocked: Scalars["Boolean"]["output"];
  readonly label: Scalars["String"]["output"];
  readonly mode: PaymentMode;
  readonly notes: Maybe<Scalars["String"]["output"]>;
  readonly number: Scalars["Int"]["output"];
  readonly referenceNumber: Maybe<Scalars["String"]["output"]>;
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export enum PaymentMode {
  BANK_TRANSFER = "BANK_TRANSFER",
  CASH = "CASH",
  CHECK = "CHECK",
  CREDIT_CARD = "CREDIT_CARD",
}

export type Permission = {
  readonly __typename: "Permission";
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly description: Scalars["String"]["output"];
  readonly displayName: Scalars["String"]["output"];
  readonly groupName: PermissionGroupName;
  readonly index: Scalars["Int"]["output"];
  readonly name: PermissionName;
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export type PermissionGroup = {
  readonly __typename: "PermissionGroup";
  readonly displayName: Scalars["String"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly name: PermissionGroupName;
};

export enum PermissionGroupName {
  ACTIVITY = "ACTIVITY",
  CONTRACT = "CONTRACT",
  CUSTOMER = "CUSTOMER",
  FORM = "FORM",
  MODEL = "MODEL",
  PRODUCT = "PRODUCT",
  PROVIDER = "PROVIDER",
  REPORT = "REPORT",
  SECTION = "SECTION",
  UNIT = "UNIT",
}

export enum PermissionName {
  ACTIVITY_CREATE = "ACTIVITY_CREATE",
  ACTIVITY_DELETE = "ACTIVITY_DELETE",
  ACTIVITY_READ = "ACTIVITY_READ",
  ACTIVITY_UPDATE = "ACTIVITY_UPDATE",
  CONTRACT_CANCEL = "CONTRACT_CANCEL",
  CONTRACT_CREATE = "CONTRACT_CREATE",
  CONTRACT_DELETE = "CONTRACT_DELETE",
  CONTRACT_DOWNLOAD = "CONTRACT_DOWNLOAD",
  CONTRACT_INVOICE_PAYMENT_CREATE = "CONTRACT_INVOICE_PAYMENT_CREATE",
  CONTRACT_INVOICE_PAYMENT_DELETE = "CONTRACT_INVOICE_PAYMENT_DELETE",
  CONTRACT_INVOICE_PAYMENT_UPDATE = "CONTRACT_INVOICE_PAYMENT_UPDATE",
  CONTRACT_INVOICE_REFUND_CREATE = "CONTRACT_INVOICE_REFUND_CREATE",
  CONTRACT_INVOICE_REFUND_DELETE = "CONTRACT_INVOICE_REFUND_DELETE",
  CONTRACT_INVOICE_REFUND_UPDATE = "CONTRACT_INVOICE_REFUND_UPDATE",
  CONTRACT_READ = "CONTRACT_READ",
  CONTRACT_REFUND_CREATE = "CONTRACT_REFUND_CREATE",
  CONTRACT_REFUND_DELETE = "CONTRACT_REFUND_DELETE",
  CONTRACT_REFUND_UPDATE = "CONTRACT_REFUND_UPDATE",
  CONTRACT_SELECTION_CONFIRM = "CONTRACT_SELECTION_CONFIRM",
  CONTRACT_SELECTION_CREATE = "CONTRACT_SELECTION_CREATE",
  CONTRACT_SELECTION_DELETE = "CONTRACT_SELECTION_DELETE",
  CONTRACT_SELECTION_UPDATE = "CONTRACT_SELECTION_UPDATE",
  CUSTOMER_CREATE = "CUSTOMER_CREATE",
  CUSTOMER_DELETE = "CUSTOMER_DELETE",
  CUSTOMER_READ = "CUSTOMER_READ",
  CUSTOMER_UPDATE = "CUSTOMER_UPDATE",
  FORM_ARCHIVE = "FORM_ARCHIVE",
  FORM_CREATE = "FORM_CREATE",
  FORM_DELETE = "FORM_DELETE",
  FORM_LINK = "FORM_LINK",
  FORM_PUBLISH = "FORM_PUBLISH",
  FORM_READ = "FORM_READ",
  FORM_UPDATE = "FORM_UPDATE",
  MODEL_DELETE = "MODEL_DELETE",
  MODEL_IMPORT = "MODEL_IMPORT",
  MODEL_LOAD = "MODEL_LOAD",
  MODEL_READ = "MODEL_READ",
  PRODUCT_CATEGORY_CREATE = "PRODUCT_CATEGORY_CREATE",
  PRODUCT_CATEGORY_DELETE = "PRODUCT_CATEGORY_DELETE",
  PRODUCT_CATEGORY_UPDATE = "PRODUCT_CATEGORY_UPDATE",
  PRODUCT_CREATE = "PRODUCT_CREATE",
  PRODUCT_DELETE = "PRODUCT_DELETE",
  PRODUCT_READ = "PRODUCT_READ",
  PRODUCT_UPDATE = "PRODUCT_UPDATE",
  PROVIDER_CREATE = "PROVIDER_CREATE",
  PROVIDER_DELETE = "PROVIDER_DELETE",
  PROVIDER_READ = "PROVIDER_READ",
  PROVIDER_UPDATE = "PROVIDER_UPDATE",
  REPORT_BILLING_READ = "REPORT_BILLING_READ",
  REPORT_CONSTRUCTION_READ = "REPORT_CONSTRUCTION_READ",
  REPORT_CUSTOMER_READ = "REPORT_CUSTOMER_READ",
  REPORT_READ = "REPORT_READ",
  REPORT_SUMMARY_READ = "REPORT_SUMMARY_READ",
  REPORT_UPDATE = "REPORT_UPDATE",
  SECTION_ARCHIVE = "SECTION_ARCHIVE",
  SECTION_CONFIGURE = "SECTION_CONFIGURE",
  SECTION_CREATE = "SECTION_CREATE",
  SECTION_DELETE = "SECTION_DELETE",
  SECTION_PUBLISH = "SECTION_PUBLISH",
  SECTION_READ = "SECTION_READ",
  SECTION_UPDATE = "SECTION_UPDATE",
  SECTION_VIBE_CREATE = "SECTION_VIBE_CREATE",
  SECTION_VIBE_DELETE = "SECTION_VIBE_DELETE",
  SECTION_VIBE_UPDATE = "SECTION_VIBE_UPDATE",
  UNIT_CREATE = "UNIT_CREATE",
  UNIT_DELETE = "UNIT_DELETE",
  UNIT_IMPORT = "UNIT_IMPORT",
  UNIT_READ = "UNIT_READ",
  UNIT_UPDATE = "UNIT_UPDATE",
}

export type Product = {
  readonly __typename: "Product";
  readonly activities: ReadonlyArray<ProductActivity>;
  readonly categoryId: Scalars["ID"]["output"];
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly format: Maybe<Scalars["String"]["output"]>;
  readonly hasThumbnail: Scalars["Boolean"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly managedByModels: Scalars["Boolean"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly provider: ProductProvider;
  readonly thumbnailUrl: Scalars["String"]["output"];
  readonly updatedAt: Scalars["DateTime"]["output"];
  readonly usedBySections: Scalars["Boolean"]["output"];
  readonly variant: Scalars["String"]["output"];
};

export type ProductActivity = {
  readonly __typename: "ProductActivity";
  readonly activityId: Scalars["ID"]["output"];
  readonly index: Scalars["Int"]["output"];
};

export type ProductActivityInput = {
  readonly activityId: Scalars["ID"]["input"];
  readonly index: Scalars["Int"]["input"];
};

export type ProductCategory = {
  readonly __typename: "ProductCategory";
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly displayName: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["ID"]["output"];
  readonly managedByModels: Scalars["Boolean"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export type ProductInput = {
  readonly activities: ReadonlyArray<ProductActivityInput>;
  readonly id: Scalars["ID"]["input"];
  readonly provider: ProductProviderInput;
};

export type ProductProvider = {
  readonly __typename: "ProductProvider";
  readonly id: Maybe<Scalars["ID"]["output"]>;
  readonly productCode: Maybe<Scalars["String"]["output"]>;
  readonly productModel: Maybe<Scalars["String"]["output"]>;
  readonly productUrl: Maybe<Scalars["String"]["output"]>;
};

export type ProductProviderInput = {
  readonly id: InputMaybe<Scalars["ID"]["input"]>;
  readonly productCode: InputMaybe<Scalars["String"]["input"]>;
  readonly productModel: InputMaybe<Scalars["String"]["input"]>;
  readonly productUrl: InputMaybe<Scalars["String"]["input"]>;
};

export type Project = {
  readonly __typename: "Project";
  readonly activities: ReadonlyArray<Activity>;
  readonly code: Scalars["String"]["output"];
  readonly contactInfo: ProjectContactInfo;
  readonly contracts: ReadonlyArray<Contract>;
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly forms: ReadonlyArray<Form>;
  readonly id: Scalars["ID"]["output"];
  readonly invoices: ReadonlyArray<Invoice>;
  readonly label: Scalars["String"]["output"];
  readonly models: ReadonlyArray<Model>;
  readonly name: Scalars["String"]["output"];
  readonly payments: ReadonlyArray<Payment>;
  readonly productCategories: ReadonlyArray<ProductCategory>;
  readonly products: ReadonlyArray<Product>;
  readonly refunds: ReadonlyArray<Refund>;
  readonly reports: ReadonlyArray<Report>;
  readonly sections: ReadonlyArray<Section>;
  readonly tenantId: Scalars["ID"]["output"];
  readonly units: ReadonlyArray<Unit>;
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export type ProjectContactInfo = {
  readonly __typename: "ProjectContactInfo";
  readonly address: Address;
  readonly companyName: Scalars["String"]["output"];
  readonly email: Scalars["String"]["output"];
  readonly federalTaxNumber: Maybe<Scalars["String"]["output"]>;
  readonly phone: Scalars["String"]["output"];
  readonly provincialTaxNumber: Maybe<Scalars["String"]["output"]>;
  readonly websiteUrl: Scalars["String"]["output"];
};

export type ProjectContactInfoInput = {
  readonly address: AddressInput;
  readonly companyName: Scalars["String"]["input"];
  readonly email: Scalars["String"]["input"];
  readonly federalTaxNumber: InputMaybe<Scalars["String"]["input"]>;
  readonly phone: Scalars["String"]["input"];
  readonly provincialTaxNumber: InputMaybe<Scalars["String"]["input"]>;
  readonly websiteUrl: Scalars["String"]["input"];
};

export type Provider = {
  readonly __typename: "Provider";
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["ID"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly updatedAt: Scalars["DateTime"]["output"];
  readonly websiteUrl: Maybe<Scalars["String"]["output"]>;
};

export type PublishFormInput = {
  readonly formId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type PublishFormPayload = {
  readonly __typename: "PublishFormPayload";
  readonly data: Maybe<PublishFormPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type PublishFormPayloadData = {
  readonly __typename: "PublishFormPayloadData";
  readonly form: Form;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type PublishSectionInput = {
  readonly projectId: Scalars["ID"]["input"];
  readonly sectionId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type PublishSectionPayload = {
  readonly __typename: "PublishSectionPayload";
  readonly data: Maybe<PublishSectionPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type PublishSectionPayloadData = {
  readonly __typename: "PublishSectionPayloadData";
  readonly projectId: Scalars["ID"]["output"];
  readonly section: Section;
  readonly tenantId: Scalars["ID"]["output"];
};

export type Query = {
  readonly __typename: "Query";
  readonly contract: Contract;
  readonly countries: ReadonlyArray<Country>;
  readonly permissions: ReadonlyArray<Permission>;
  readonly project: Project;
  readonly reportBilling: BillingReport;
  readonly reportConstruction: ConstructionReport;
  readonly reportCustomer: CustomerReport;
  readonly reportSummary: SummaryReport;
  readonly tenant: Tenant;
  readonly tenants: ReadonlyArray<Tenant>;
  readonly userInfo: UserInfo;
};

export type QueryContractArgs = {
  contractId: Scalars["ID"]["input"];
  projectId: Scalars["ID"]["input"];
  tenantId: Scalars["ID"]["input"];
};

export type QueryProjectArgs = {
  projectId: Scalars["ID"]["input"];
  tenantId: Scalars["ID"]["input"];
};

export type QueryReportBillingArgs = {
  projectId: Scalars["ID"]["input"];
  tenantId: Scalars["ID"]["input"];
  unitIds: ReadonlyArray<Scalars["ID"]["input"]>;
};

export type QueryReportConstructionArgs = {
  projectId: Scalars["ID"]["input"];
  reportId: Scalars["ID"]["input"];
  tenantId: Scalars["ID"]["input"];
  unitId: Scalars["ID"]["input"];
};

export type QueryReportCustomerArgs = {
  projectId: Scalars["ID"]["input"];
  reportId: Scalars["ID"]["input"];
  tenantId: Scalars["ID"]["input"];
  unitId: Scalars["ID"]["input"];
};

export type QueryReportSummaryArgs = {
  activityId: InputMaybe<Scalars["ID"]["input"]>;
  projectId: Scalars["ID"]["input"];
  reportId: Scalars["ID"]["input"];
  tenantId: Scalars["ID"]["input"];
  unitFloor: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryTenantArgs = {
  tenantId: Scalars["ID"]["input"];
};

export type Refund = {
  readonly __typename: "Refund";
  readonly amount: Scalars["Float"]["output"];
  readonly contractId: Maybe<Scalars["ID"]["output"]>;
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly date: Scalars["DateTime"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly invoiceId: Maybe<Scalars["ID"]["output"]>;
  readonly isLocked: Scalars["Boolean"]["output"];
  readonly label: Scalars["String"]["output"];
  readonly mode: RefundMode;
  readonly notes: Maybe<Scalars["String"]["output"]>;
  readonly number: Scalars["Int"]["output"];
  readonly referenceNumber: Maybe<Scalars["String"]["output"]>;
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export enum RefundMode {
  BANK_TRANSFER = "BANK_TRANSFER",
  CASH = "CASH",
  CHECK = "CHECK",
  CREDIT_CARD = "CREDIT_CARD",
}

export type Report = {
  readonly __typename: "Report";
  readonly activities: ReadonlyArray<ReportActivity>;
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly rooms: ReadonlyArray<ReportRoom>;
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export type ReportActivity = {
  readonly __typename: "ReportActivity";
  readonly activityId: Scalars["String"]["output"];
  readonly index: Scalars["Int"]["output"];
};

export type ReportActivityInput = {
  readonly activityId: Scalars["ID"]["input"];
  readonly index: Scalars["Int"]["input"];
};

export enum ReportCellType {
  CREDIT_SUBCONTRACTOR = "CREDIT_SUBCONTRACTOR",
  EXTRA = "EXTRA",
  INCLUDED = "INCLUDED",
  MIX_AND_MATCH = "MIX_AND_MATCH",
  NONE = "NONE",
  REFERENCE = "REFERENCE",
}

export type ReportContractSelectionInfo = {
  readonly __typename: "ReportContractSelectionInfo";
  readonly contractSelectionId: Scalars["ID"]["output"];
  readonly date: Scalars["DateTime"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly revisionNumber: Scalars["Int"]["output"];
};

export type ReportCustomerInfo = {
  readonly __typename: "ReportCustomerInfo";
  readonly customerId: Scalars["ID"]["output"];
  readonly email: Scalars["String"]["output"];
  readonly fullName: Scalars["String"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly phone: Scalars["String"]["output"];
};

export type ReportRoom = {
  readonly __typename: "ReportRoom";
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly elements: ReadonlyArray<ReportRoomElement>;
  readonly id: Scalars["ID"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export type ReportRoomElement = {
  readonly __typename: "ReportRoomElement";
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly display: ReportRoomElementDisplay;
  readonly id: Scalars["ID"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export enum ReportRoomElementDisplay {
  DEFAULT = "DEFAULT",
  EXTRA_ONLY = "EXTRA_ONLY",
}

export type ReportRoomElementInput = {
  readonly display: ReportRoomElementDisplay;
  readonly id: Scalars["ID"]["input"];
  readonly index: Scalars["Int"]["input"];
  readonly name: Scalars["String"]["input"];
};

export type ReportRoomInput = {
  readonly elements: ReadonlyArray<ReportRoomElementInput>;
  readonly id: Scalars["ID"]["input"];
  readonly index: Scalars["Int"]["input"];
  readonly name: Scalars["String"]["input"];
};

export type ReportVibeTableRow = {
  readonly __typename: "ReportVibeTableRow";
  readonly index: Scalars["Int"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly type: ReportCellType;
  readonly value: Scalars["String"]["output"];
};

export type ResendUserInvitationInput = {
  readonly tenantId: Scalars["ID"]["input"];
  readonly userId: Scalars["ID"]["input"];
};

export type ResendUserInvitationPayload = {
  readonly __typename: "ResendUserInvitationPayload";
  readonly data: Maybe<ResendUserInvitationPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type ResendUserInvitationPayloadData = {
  readonly __typename: "ResendUserInvitationPayloadData";
  readonly tenantId: Scalars["ID"]["output"];
  readonly user: User;
};

export type Role = {
  readonly __typename: "Role";
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly description: Scalars["String"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly isLocked: Scalars["Boolean"]["output"];
  readonly isTenantAccountingManager: Scalars["Boolean"]["output"];
  readonly isTenantProfileManager: Scalars["Boolean"]["output"];
  readonly isTenantProjectManager: Scalars["Boolean"]["output"];
  readonly isTenantSecurityManager: Scalars["Boolean"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly permissions: ReadonlyArray<PermissionName>;
  readonly template: RoleTemplate;
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export enum RoleTemplate {
  ADMINISTRATOR = "ADMINISTRATOR",
  NONE = "NONE",
  PROJECT_ADMINISTRATOR = "PROJECT_ADMINISTRATOR",
  PROJECT_MANAGER = "PROJECT_MANAGER",
  READER = "READER",
  SELLER = "SELLER",
}

export type Section = {
  readonly __typename: "Section";
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly elementGroups: ReadonlyArray<SectionElementGroup>;
  readonly elements: ReadonlyArray<SectionElementUnion>;
  readonly id: Scalars["ID"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly modelLink: Maybe<SectionModelLink>;
  readonly state: SectionState;
  readonly title: Scalars["String"]["output"];
  readonly updatedAt: Scalars["DateTime"]["output"];
  readonly vibes: ReadonlyArray<SectionVibe>;
};

export type SectionElement = {
  readonly __typename: "SectionElement";
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly groupId: Maybe<Scalars["ID"]["output"]>;
  readonly hideProductCategory: Scalars["Boolean"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly isManagedByVibes: Scalars["Boolean"]["output"];
  readonly isOptional: Scalars["Boolean"]["output"];
  readonly label: Scalars["String"]["output"];
  readonly modelLink: Maybe<SectionElementModelLink>;
  readonly notes: Maybe<Scalars["String"]["output"]>;
  readonly options: ReadonlyArray<SectionElementOptionUnion>;
  readonly type: SectionElementType;
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export type SectionElementGroup = {
  readonly __typename: "SectionElementGroup";
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export type SectionElementGroupInput = {
  readonly id: Scalars["ID"]["input"];
  readonly index: Scalars["Int"]["input"];
  readonly name: Scalars["String"]["input"];
};

export type SectionElementModelLink = {
  readonly __typename: "SectionElementModelLink";
  readonly modelId: Scalars["ID"]["output"];
  readonly modelRoomElementId: Scalars["ID"]["output"];
  readonly modelRoomId: Scalars["ID"]["output"];
};

export type SectionElementOption = {
  readonly __typename: "SectionElementOption";
  readonly breakdownLines: ReadonlyArray<SectionElementOptionBreakdownLine>;
  readonly clientAmount: Scalars["Float"]["output"];
  readonly contingencyAmount: Scalars["Float"]["output"];
  readonly contractorAmount: Scalars["Float"]["output"];
  readonly costAmount: Scalars["Float"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly isUnlocked: Scalars["Boolean"]["output"];
  readonly modelLink: Maybe<SectionElementOptionModelLink>;
  readonly name: Scalars["String"]["output"];
  readonly productId: Scalars["ID"]["output"];
  readonly promoterAmount: Scalars["Float"]["output"];
  readonly subcontractorAmount: Scalars["Float"]["output"];
  readonly type: SectionElementOptionType;
};

export type SectionElementOptionBreakdownLine = {
  readonly __typename: "SectionElementOptionBreakdownLine";
  readonly activityId: Scalars["ID"]["output"];
  readonly contractorAmount: Scalars["Float"]["output"];
  readonly costAmount: Scalars["Float"]["output"];
  readonly promoterAmount: Scalars["Float"]["output"];
  readonly subcontractorAmount: Scalars["Float"]["output"];
};

export type SectionElementOptionBreakdownLineInput = {
  readonly activityId: Scalars["ID"]["input"];
  readonly contractorAmount: Scalars["Float"]["input"];
  readonly promoterAmount: Scalars["Float"]["input"];
  readonly subcontractorAmount: Scalars["Float"]["input"];
};

export type SectionElementOptionInput = {
  readonly breakdownLines: ReadonlyArray<SectionElementOptionBreakdownLineInput>;
  readonly clientAmount: Scalars["Float"]["input"];
  readonly id: Scalars["ID"]["input"];
  readonly index: Scalars["Int"]["input"];
  readonly modelLink: InputMaybe<SectionElementOptionModelLinkInput>;
  readonly productId: Scalars["ID"]["input"];
  readonly type: SectionElementOptionType;
};

export type SectionElementOptionModelLink = {
  readonly __typename: "SectionElementOptionModelLink";
  readonly modelId: Scalars["ID"]["output"];
  readonly modelRoomElementId: Scalars["ID"]["output"];
  readonly modelRoomElementOptionId: Scalars["ID"]["output"];
  readonly modelRoomId: Scalars["ID"]["output"];
};

export type SectionElementOptionModelLinkInput = {
  readonly modelId: Scalars["ID"]["input"];
  readonly modelRoomElementId: Scalars["ID"]["input"];
  readonly modelRoomElementOptionId: Scalars["ID"]["input"];
  readonly modelRoomId: Scalars["ID"]["input"];
};

export type SectionElementOptionSet = {
  readonly __typename: "SectionElementOptionSet";
  readonly maxIndex: Scalars["Int"]["output"];
  readonly minIndex: Scalars["Int"]["output"];
  readonly options: ReadonlyArray<SectionElementOption>;
  readonly productCategoryId: Scalars["ID"]["output"];
};

export enum SectionElementOptionType {
  CREDIT_SUBCONTRACTOR = "CREDIT_SUBCONTRACTOR",
  EXCLUDED = "EXCLUDED",
  EXTRA = "EXTRA",
  INCLUDED = "INCLUDED",
  MIX_AND_MATCH = "MIX_AND_MATCH",
}

export type SectionElementOptionUnion =
  | SectionElementOption
  | SectionElementOptionSet;

export type SectionElementSet = {
  readonly __typename: "SectionElementSet";
  readonly elements: ReadonlyArray<SectionElement>;
  readonly maxIndex: Scalars["Int"]["output"];
  readonly minIndex: Scalars["Int"]["output"];
  readonly sectionElementGroupId: Scalars["ID"]["output"];
};

export enum SectionElementType {
  BOOLEAN = "BOOLEAN",
  NONE = "NONE",
  READONLY = "READONLY",
  UNIQUE = "UNIQUE",
}

export type SectionElementUnion = SectionElement | SectionElementSet;

export type SectionModelLink = {
  readonly __typename: "SectionModelLink";
  readonly modelId: Scalars["ID"]["output"];
  readonly modelRoomId: Scalars["ID"]["output"];
};

export enum SectionState {
  ARCHIVED = "ARCHIVED",
  CONFIGURED = "CONFIGURED",
  CREATED = "CREATED",
  PUBLISHED = "PUBLISHED",
}

export type SectionVibe = {
  readonly __typename: "SectionVibe";
  readonly clientAmount: Scalars["Float"]["output"];
  readonly contingencyAmount: Scalars["Float"]["output"];
  readonly contractorAmount: Scalars["Float"]["output"];
  readonly costAmount: Scalars["Float"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly items: ReadonlyArray<SectionVibeItem>;
  readonly mode: SectionVibeMode;
  readonly name: Scalars["String"]["output"];
  readonly promoterAmount: Scalars["Float"]["output"];
  readonly subcontractorAmount: Scalars["Float"]["output"];
};

export type SectionVibeItem = {
  readonly __typename: "SectionVibeItem";
  readonly contractorAmount: Scalars["Float"]["output"];
  readonly costAmount: Scalars["Float"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly promoterAmount: Scalars["Float"]["output"];
  readonly sectionElementId: Scalars["ID"]["output"];
  readonly sectionElementOptionId: Scalars["ID"]["output"];
  readonly subcontractorAmount: Scalars["Float"]["output"];
};

export type SectionVibeItemInput = {
  readonly sectionElementId: Scalars["ID"]["input"];
  readonly sectionElementOptionId: Scalars["ID"]["input"];
};

export enum SectionVibeMode {
  STANDARD = "STANDARD",
  UPGRADE = "UPGRADE",
}

export type SummaryReport = {
  readonly __typename: "SummaryReport";
  readonly baseActivityColumnGroups: ReadonlyArray<SummaryReportActivityColumnGroup>;
  readonly extraActivityColumnGroups: ReadonlyArray<SummaryReportActivityColumnGroup>;
  readonly rows: ReadonlyArray<SummaryReportRow>;
  readonly vibeColumns: ReadonlyArray<SummaryReportVibeColumn>;
};

export type SummaryReportActivityColumnGroup = {
  readonly __typename: "SummaryReportActivityColumnGroup";
  readonly activityId: Scalars["ID"]["output"];
  readonly colspan: Scalars["Int"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly roomColumnGroups: ReadonlyArray<SummaryReportRoomColumnGroup>;
};

export type SummaryReportCell = {
  readonly __typename: "SummaryReportCell";
  readonly index: Scalars["Int"]["output"];
  readonly metadata: Maybe<SummaryReportCellMetadata>;
  readonly type: ReportCellType;
  readonly value: Scalars["String"]["output"];
};

export type SummaryReportCellMetadata = {
  readonly __typename: "SummaryReportCellMetadata";
  readonly activityTitle: Scalars["String"]["output"];
  readonly comments: Scalars["String"]["output"];
  readonly productCode: Scalars["String"]["output"];
  readonly productFormat: Scalars["String"]["output"];
  readonly productModel: Scalars["String"]["output"];
  readonly providerName: Scalars["String"]["output"];
  readonly reportRoomElementName: Scalars["String"]["output"];
  readonly reportRoomName: Scalars["String"]["output"];
};

export type SummaryReportElementColumn = {
  readonly __typename: "SummaryReportElementColumn";
  readonly index: Scalars["Int"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly reportRoomElementId: Scalars["ID"]["output"];
};

export type SummaryReportRoomColumnGroup = {
  readonly __typename: "SummaryReportRoomColumnGroup";
  readonly colspan: Scalars["Int"]["output"];
  readonly elementColumns: ReadonlyArray<SummaryReportElementColumn>;
  readonly index: Scalars["Int"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly reportRoomId: Scalars["ID"]["output"];
};

export type SummaryReportRow = {
  readonly __typename: "SummaryReportRow";
  readonly cells: ReadonlyArray<SummaryReportCell>;
  readonly contractSelectionInfos: ReadonlyArray<ReportContractSelectionInfo>;
  readonly customerInfos: ReadonlyArray<ReportCustomerInfo>;
  readonly numberOfExtras: Scalars["Int"]["output"];
  readonly unitFloor: Scalars["Int"]["output"];
  readonly unitNumber: Scalars["String"]["output"];
  readonly unitNumberOfBathrooms: Scalars["Int"]["output"];
  readonly unitNumberOfBedrooms: Scalars["Int"]["output"];
  readonly unitState: UnitState;
};

export type SummaryReportVibeColumn = {
  readonly __typename: "SummaryReportVibeColumn";
  readonly index: Scalars["Int"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly reportRoomId: Scalars["ID"]["output"];
};

export type Tax = {
  readonly __typename: "Tax";
  readonly agencyId: Scalars["ID"]["output"];
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly rate: Scalars["Float"]["output"];
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export type TaxAgency = {
  readonly __typename: "TaxAgency";
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["ID"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export type TaxGroup = {
  readonly __typename: "TaxGroup";
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly taxIds: ReadonlyArray<Scalars["ID"]["output"]>;
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export enum TemplateName {
  BULK_CREATE_UNITS = "BULK_CREATE_UNITS",
}

export type Tenant = {
  readonly __typename: "Tenant";
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly customers: ReadonlyArray<Customer>;
  readonly id: Scalars["ID"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly profile: TenantProfile;
  readonly projects: ReadonlyArray<Project>;
  readonly providers: ReadonlyArray<Provider>;
  readonly roles: ReadonlyArray<Role>;
  readonly status: TenantStatus;
  readonly taxAgencies: ReadonlyArray<TaxAgency>;
  readonly taxGroups: ReadonlyArray<TaxGroup>;
  readonly taxes: ReadonlyArray<Tax>;
  readonly type: TenantType;
  readonly updatedAt: Scalars["DateTime"]["output"];
  readonly users: ReadonlyArray<User>;
};

export type TenantProfile = {
  readonly __typename: "TenantProfile";
  readonly address: Address;
  readonly companyName: Scalars["String"]["output"];
  readonly currencyCode: CurrencyCode;
  readonly email: Scalars["String"]["output"];
  readonly language: Language;
  readonly phone: Scalars["String"]["output"];
  readonly websiteUrl: Maybe<Scalars["String"]["output"]>;
};

export enum TenantStatus {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
}

export enum TenantType {
  PRINCIPAL = "PRINCIPAL",
  TEST = "TEST",
}

export type UnblockUserInput = {
  readonly tenantId: Scalars["ID"]["input"];
  readonly userId: Scalars["ID"]["input"];
};

export type UnblockUserPayload = {
  readonly __typename: "UnblockUserPayload";
  readonly data: Maybe<UnblockUserPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UnblockUserPayloadData = {
  readonly __typename: "UnblockUserPayloadData";
  readonly tenantId: Scalars["ID"]["output"];
  readonly user: User;
};

export type Unit = {
  readonly __typename: "Unit";
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly currentContractId: Maybe<Scalars["ID"]["output"]>;
  readonly floor: Scalars["Int"]["output"];
  readonly formId: Maybe<Scalars["ID"]["output"]>;
  readonly id: Scalars["ID"]["output"];
  readonly label: Scalars["String"]["output"];
  readonly number: Scalars["String"]["output"];
  readonly numberOfBathrooms: Scalars["Int"]["output"];
  readonly numberOfBedrooms: Scalars["Int"]["output"];
  readonly state: UnitState;
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export type UnitInput = {
  readonly formId: InputMaybe<Scalars["ID"]["input"]>;
  readonly id: Scalars["ID"]["input"];
};

export enum UnitState {
  CONTRACT_COMPLETED = "CONTRACT_COMPLETED",
  CONTRACT_IS_CUSTOMIZED = "CONTRACT_IS_CUSTOMIZED",
  CONTRACT_PAYMENT_PENDING = "CONTRACT_PAYMENT_PENDING",
  CONTRACT_REFUND_PENDING = "CONTRACT_REFUND_PENDING",
  CONTRACT_REVISION_PENDING = "CONTRACT_REVISION_PENDING",
  CONTRACT_SELECTION_PENDING = "CONTRACT_SELECTION_PENDING",
  CONTRACT_WITHOUT_FORM = "CONTRACT_WITHOUT_FORM",
  CONTRACT_WITHOUT_SELECTION = "CONTRACT_WITHOUT_SELECTION",
  NO_CONTRACT = "NO_CONTRACT",
}

export type UpdateActivityInput = {
  readonly activityId: InputMaybe<Scalars["ID"]["input"]>;
  readonly description: InputMaybe<Scalars["String"]["input"]>;
  readonly number: Scalars["String"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
  readonly title: Scalars["String"]["input"];
};

export type UpdateActivityPayload = {
  readonly __typename: "UpdateActivityPayload";
  readonly data: Maybe<UpdateActivityPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateActivityPayloadData = {
  readonly __typename: "UpdateActivityPayloadData";
  readonly activity: Activity;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type UpdateContractInvoicePaymentInput = {
  readonly amount: Scalars["Float"]["input"];
  readonly contractId: Scalars["ID"]["input"];
  readonly date: Scalars["DateTime"]["input"];
  readonly invoiceId: Scalars["ID"]["input"];
  readonly mode: PaymentMode;
  readonly notes: InputMaybe<Scalars["String"]["input"]>;
  readonly paymentId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly referenceNumber: InputMaybe<Scalars["String"]["input"]>;
  readonly tenantId: Scalars["ID"]["input"];
};

export type UpdateContractInvoicePaymentPayload = {
  readonly __typename: "UpdateContractInvoicePaymentPayload";
  readonly data: Maybe<UpdateContractInvoicePaymentPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateContractInvoicePaymentPayloadData = {
  readonly __typename: "UpdateContractInvoicePaymentPayloadData";
  readonly contract: Contract;
  readonly invoice: Invoice;
  readonly payment: Payment;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
  readonly unit: Unit;
};

export type UpdateContractInvoiceRefundInput = {
  readonly amount: Scalars["Float"]["input"];
  readonly contractId: Scalars["ID"]["input"];
  readonly date: Scalars["DateTime"]["input"];
  readonly invoiceId: Scalars["ID"]["input"];
  readonly mode: RefundMode;
  readonly notes: InputMaybe<Scalars["String"]["input"]>;
  readonly projectId: Scalars["ID"]["input"];
  readonly referenceNumber: InputMaybe<Scalars["String"]["input"]>;
  readonly refundId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type UpdateContractInvoiceRefundPayload = {
  readonly __typename: "UpdateContractInvoiceRefundPayload";
  readonly data: Maybe<UpdateContractInvoiceRefundPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateContractInvoiceRefundPayloadData = {
  readonly __typename: "UpdateContractInvoiceRefundPayloadData";
  readonly contract: Contract;
  readonly invoice: Invoice;
  readonly projectId: Scalars["ID"]["output"];
  readonly refund: Refund;
  readonly tenantId: Scalars["ID"]["output"];
  readonly unit: Unit;
};

export type UpdateContractRefundInput = {
  readonly amount: Scalars["Float"]["input"];
  readonly contractId: Scalars["ID"]["input"];
  readonly date: Scalars["DateTime"]["input"];
  readonly mode: RefundMode;
  readonly notes: InputMaybe<Scalars["String"]["input"]>;
  readonly projectId: Scalars["ID"]["input"];
  readonly referenceNumber: InputMaybe<Scalars["String"]["input"]>;
  readonly refundId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type UpdateContractRefundPayload = {
  readonly __typename: "UpdateContractRefundPayload";
  readonly data: Maybe<UpdateContractRefundPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateContractRefundPayloadData = {
  readonly __typename: "UpdateContractRefundPayloadData";
  readonly contract: Contract;
  readonly projectId: Scalars["ID"]["output"];
  readonly refund: Refund;
  readonly tenantId: Scalars["ID"]["output"];
  readonly unit: Unit;
};

export type UpdateContractRevisionInput = {
  readonly contractId: Scalars["ID"]["input"];
  readonly creditAmount: Scalars["Float"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly reason: InputMaybe<Scalars["String"]["input"]>;
  readonly revisionAmount: Scalars["Float"]["input"];
  readonly selectionId: Scalars["ID"]["input"];
  readonly taxGroupId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
  readonly terms: InvoiceTermsOfPayment;
};

export type UpdateContractRevisionPayload = {
  readonly __typename: "UpdateContractRevisionPayload";
  readonly data: Maybe<UpdateContractRevisionPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateContractRevisionPayloadData = {
  readonly __typename: "UpdateContractRevisionPayloadData";
  readonly contract: Contract;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
  readonly unit: Unit;
};

export type UpdateContractSelectionGroupInput = {
  readonly contractId: Scalars["ID"]["input"];
  readonly formSectionId: Scalars["ID"]["input"];
  readonly items: ReadonlyArray<ContractSelectionGroupItemInput>;
  readonly projectId: Scalars["ID"]["input"];
  readonly selectionId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
  readonly vibe: InputMaybe<ContractSelectionGroupVibeInput>;
};

export type UpdateContractSelectionGroupPayload = {
  readonly __typename: "UpdateContractSelectionGroupPayload";
  readonly data: Maybe<UpdateContractSelectionGroupPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateContractSelectionGroupPayloadData = {
  readonly __typename: "UpdateContractSelectionGroupPayloadData";
  readonly contract: Contract;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type UpdateContractSelectionInput = {
  readonly contractId: Scalars["ID"]["input"];
  readonly creditAmount: Scalars["Float"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly selectionId: Scalars["ID"]["input"];
  readonly taxGroupId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
  readonly terms: InvoiceTermsOfPayment;
};

export type UpdateContractSelectionPayload = {
  readonly __typename: "UpdateContractSelectionPayload";
  readonly data: Maybe<UpdateContractSelectionPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateContractSelectionPayloadData = {
  readonly __typename: "UpdateContractSelectionPayloadData";
  readonly contract: Contract;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
  readonly unit: Unit;
};

export type UpdateCustomerInput = {
  readonly address: AddressInput;
  readonly customerId: Scalars["ID"]["input"];
  readonly email: Scalars["String"]["input"];
  readonly firstName: Scalars["String"]["input"];
  readonly gender: Gender;
  readonly language: Language;
  readonly lastName: Scalars["String"]["input"];
  readonly notes: InputMaybe<Scalars["String"]["input"]>;
  readonly phone: Scalars["String"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type UpdateCustomerPayload = {
  readonly __typename: "UpdateCustomerPayload";
  readonly data: Maybe<UpdateCustomerPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateCustomerPayloadData = {
  readonly __typename: "UpdateCustomerPayloadData";
  readonly customer: Customer;
  readonly tenantId: Scalars["ID"]["output"];
};

export type UpdateFormInput = {
  readonly formId: Scalars["ID"]["input"];
  readonly modelId: InputMaybe<Scalars["ID"]["input"]>;
  readonly name: Scalars["String"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly sections: ReadonlyArray<FormSectionInput>;
  readonly tenantId: Scalars["ID"]["input"];
};

export type UpdateFormPayload = {
  readonly __typename: "UpdateFormPayload";
  readonly data: Maybe<UpdateFormPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateFormPayloadData = {
  readonly __typename: "UpdateFormPayloadData";
  readonly form: Form;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type UpdateProductCategoryInput = {
  readonly description: InputMaybe<Scalars["String"]["input"]>;
  readonly displayName: InputMaybe<Scalars["String"]["input"]>;
  readonly name: Scalars["String"]["input"];
  readonly productCategoryId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type UpdateProductCategoryPayload = {
  readonly __typename: "UpdateProductCategoryPayload";
  readonly data: Maybe<UpdateProductCategoryPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateProductCategoryPayloadData = {
  readonly __typename: "UpdateProductCategoryPayloadData";
  readonly productCategory: ProductCategory;
  readonly products: ReadonlyArray<Product>;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type UpdateProductInput = {
  readonly activities: ReadonlyArray<ProductActivityInput>;
  readonly categoryId: Scalars["ID"]["input"];
  readonly description: InputMaybe<Scalars["String"]["input"]>;
  readonly format: InputMaybe<Scalars["String"]["input"]>;
  readonly productId: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly provider: ProductProviderInput;
  readonly tenantId: Scalars["ID"]["input"];
  readonly thumbnail: InputMaybe<Scalars["Upload"]["input"]>;
  readonly variant: Scalars["String"]["input"];
};

export type UpdateProductPayload = {
  readonly __typename: "UpdateProductPayload";
  readonly data: Maybe<UpdateProductPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateProductPayloadData = {
  readonly __typename: "UpdateProductPayloadData";
  readonly product: Product;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type UpdateProductsInput = {
  readonly products: ReadonlyArray<ProductInput>;
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type UpdateProductsPayload = {
  readonly __typename: "UpdateProductsPayload";
  readonly data: Maybe<UpdateProductsPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateProductsPayloadData = {
  readonly __typename: "UpdateProductsPayloadData";
  readonly products: ReadonlyArray<Product>;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
};

export type UpdateProjectInput = {
  readonly code: Scalars["String"]["input"];
  readonly contactInfo: ProjectContactInfoInput;
  readonly description: InputMaybe<Scalars["String"]["input"]>;
  readonly name: Scalars["String"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type UpdateProjectPayload = {
  readonly __typename: "UpdateProjectPayload";
  readonly data: Maybe<UpdateProjectPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateProjectPayloadData = {
  readonly __typename: "UpdateProjectPayloadData";
  readonly project: Project;
  readonly tenantId: Scalars["ID"]["output"];
};

export type UpdateProviderInput = {
  readonly description: InputMaybe<Scalars["String"]["input"]>;
  readonly name: Scalars["String"]["input"];
  readonly providerId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
  readonly websiteUrl: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateProviderPayload = {
  readonly __typename: "UpdateProviderPayload";
  readonly data: Maybe<UpdateProviderPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateProviderPayloadData = {
  readonly __typename: "UpdateProviderPayloadData";
  readonly provider: Provider;
  readonly tenantId: Scalars["ID"]["output"];
};

export type UpdateReportInput = {
  readonly activities: ReadonlyArray<ReportActivityInput>;
  readonly projectId: Scalars["ID"]["input"];
  readonly reportId: Scalars["ID"]["input"];
  readonly rooms: ReadonlyArray<ReportRoomInput>;
  readonly tenantId: Scalars["ID"]["input"];
};

export type UpdateReportPayload = {
  readonly __typename: "UpdateReportPayload";
  readonly data: Maybe<UpdateReportPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateReportPayloadData = {
  readonly __typename: "UpdateReportPayloadData";
  readonly forms: ReadonlyArray<Form>;
  readonly projectId: Scalars["ID"]["output"];
  readonly report: Report;
  readonly tenantId: Scalars["ID"]["output"];
};

export type UpdateSectionElementInput = {
  readonly groupId: InputMaybe<Scalars["ID"]["input"]>;
  readonly hideProductCategory: Scalars["Boolean"]["input"];
  readonly id: Scalars["ID"]["input"];
  readonly index: Scalars["Int"]["input"];
  readonly isManagedByVibes: Scalars["Boolean"]["input"];
  readonly isOptional: Scalars["Boolean"]["input"];
  readonly label: Scalars["String"]["input"];
  readonly notes: InputMaybe<Scalars["String"]["input"]>;
  readonly options: ReadonlyArray<SectionElementOptionInput>;
  readonly type: SectionElementType;
};

export type UpdateSectionInput = {
  readonly elementGroups: ReadonlyArray<SectionElementGroupInput>;
  readonly elements: ReadonlyArray<UpdateSectionElementInput>;
  readonly projectId: Scalars["ID"]["input"];
  readonly sectionId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
  readonly title: Scalars["String"]["input"];
};

export type UpdateSectionPayload = {
  readonly __typename: "UpdateSectionPayload";
  readonly data: Maybe<UpdateSectionPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateSectionPayloadData = {
  readonly __typename: "UpdateSectionPayloadData";
  readonly products: ReadonlyArray<Product>;
  readonly projectId: Scalars["ID"]["output"];
  readonly section: Section;
  readonly tenantId: Scalars["ID"]["output"];
};

export type UpdateSectionVibeInput = {
  readonly clientAmount: Scalars["Float"]["input"];
  readonly items: ReadonlyArray<SectionVibeItemInput>;
  readonly mode: SectionVibeMode;
  readonly name: Scalars["String"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly sectionId: Scalars["ID"]["input"];
  readonly sectionVibeId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
};

export type UpdateSectionVibePayload = {
  readonly __typename: "UpdateSectionVibePayload";
  readonly data: Maybe<UpdateSectionVibePayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateSectionVibePayloadData = {
  readonly __typename: "UpdateSectionVibePayloadData";
  readonly projectId: Scalars["ID"]["output"];
  readonly section: Section;
  readonly tenantId: Scalars["ID"]["output"];
};

export type UpdateUnitInput = {
  readonly floor: Scalars["Int"]["input"];
  readonly formId: InputMaybe<Scalars["ID"]["input"]>;
  readonly number: Scalars["String"]["input"];
  readonly numberOfBathrooms: Scalars["Int"]["input"];
  readonly numberOfBedrooms: Scalars["Int"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
  readonly unitId: Scalars["ID"]["input"];
};

export type UpdateUnitPayload = {
  readonly __typename: "UpdateUnitPayload";
  readonly data: Maybe<UpdateUnitPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateUnitPayloadData = {
  readonly __typename: "UpdateUnitPayloadData";
  readonly contracts: ReadonlyArray<Contract>;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
  readonly unit: Unit;
};

export type UpdateUnitsInput = {
  readonly projectId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
  readonly units: ReadonlyArray<UnitInput>;
};

export type UpdateUnitsPayload = {
  readonly __typename: "UpdateUnitsPayload";
  readonly data: Maybe<UpdateUnitsPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateUnitsPayloadData = {
  readonly __typename: "UpdateUnitsPayloadData";
  readonly contracts: ReadonlyArray<Contract>;
  readonly projectId: Scalars["ID"]["output"];
  readonly tenantId: Scalars["ID"]["output"];
  readonly units: ReadonlyArray<Unit>;
};

export type UpdateUserInput = {
  readonly firstName: Scalars["String"]["input"];
  readonly hasAccessToAllProjects: Scalars["Boolean"]["input"];
  readonly hasAccessToTestTenant: Scalars["Boolean"]["input"];
  readonly lastName: Scalars["String"]["input"];
  readonly projectIds: ReadonlyArray<Scalars["ID"]["input"]>;
  readonly roleId: Scalars["ID"]["input"];
  readonly tenantId: Scalars["ID"]["input"];
  readonly userId: Scalars["ID"]["input"];
};

export type UpdateUserPayload = {
  readonly __typename: "UpdateUserPayload";
  readonly data: Maybe<UpdateUserPayloadData>;
  readonly errors: Maybe<ReadonlyArray<Error>>;
};

export type UpdateUserPayloadData = {
  readonly __typename: "UpdateUserPayloadData";
  readonly tenantId: Scalars["ID"]["output"];
  readonly user: User;
};

export type User = {
  readonly __typename: "User";
  readonly createdAt: Scalars["DateTime"]["output"];
  readonly email: Scalars["String"]["output"];
  readonly firstName: Scalars["String"]["output"];
  readonly fullName: Scalars["String"]["output"];
  readonly hasAccessToAllProjects: Scalars["Boolean"]["output"];
  readonly hasAccessToTestTenant: Scalars["Boolean"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly isBlocked: Scalars["Boolean"]["output"];
  readonly isTest: Scalars["Boolean"]["output"];
  readonly lastName: Scalars["String"]["output"];
  readonly projectIds: ReadonlyArray<Scalars["ID"]["output"]>;
  readonly roleId: Scalars["ID"]["output"];
  readonly updatedAt: Scalars["DateTime"]["output"];
};

export type UserInfo = {
  readonly __typename: "UserInfo";
  readonly email: Scalars["String"]["output"];
  readonly isSuperAdmin: Scalars["Boolean"]["output"];
  readonly tenants: ReadonlyArray<UserInfoTenant>;
  readonly userId: Scalars["ID"]["output"];
};

export type UserInfoTenant = {
  readonly __typename: "UserInfoTenant";
  readonly hasAccessToAllProjects: Scalars["Boolean"]["output"];
  readonly hasAccessToTestTenant: Scalars["Boolean"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly isAccountingManager: Scalars["Boolean"]["output"];
  readonly isBlocked: Scalars["Boolean"]["output"];
  readonly isProfileManager: Scalars["Boolean"]["output"];
  readonly isProjectManager: Scalars["Boolean"]["output"];
  readonly isSecurityManager: Scalars["Boolean"]["output"];
  readonly permissions: ReadonlyArray<PermissionName>;
  readonly projectIds: ReadonlyArray<Scalars["ID"]["output"]>;
  readonly testId: Scalars["ID"]["output"];
};

export type UserTenantInput = {
  readonly id: Scalars["ID"]["input"];
  readonly isAdmin: Scalars["Boolean"]["input"];
  readonly projects: ReadonlyArray<UserTenantProjectInput>;
  readonly roleId: InputMaybe<Scalars["ID"]["input"]>;
  readonly tenantId: Scalars["ID"]["input"];
};

export type UserTenantProjectInput = {
  readonly id: Scalars["ID"]["input"];
  readonly projectId: Scalars["ID"]["input"];
  readonly roleId: Scalars["ID"]["input"];
};

export type ErrorFragment = {
  readonly __typename: "Error";
  readonly message: string;
};

export type AddressFragment = {
  readonly __typename: "Address";
  readonly line: string;
  readonly city: string;
  readonly subdivisionCode: string;
  readonly countryCode: string;
  readonly postalCode: string;
};

export type RefundFragment = {
  readonly __typename: "Refund";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly date: Date;
  readonly number: number;
  readonly label: string;
  readonly referenceNumber: string | null;
  readonly isLocked: boolean;
  readonly mode: RefundMode;
  readonly amount: number;
  readonly notes: string | null;
  readonly invoiceId: string | null;
  readonly contractId: string | null;
};

export type PaymentFragment = {
  readonly __typename: "Payment";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly date: Date;
  readonly number: number;
  readonly label: string;
  readonly referenceNumber: string | null;
  readonly isLocked: boolean;
  readonly mode: PaymentMode;
  readonly amount: number;
  readonly notes: string | null;
  readonly invoiceId: string | null;
  readonly contractId: string | null;
};

export type InvoiceItemFragment = {
  readonly __typename: "InvoiceItem";
  readonly id: string;
  readonly index: number;
  readonly type: InvoiceItemType;
  readonly name: string;
  readonly description: string | null;
  readonly quantity: number;
  readonly unitPrice: number;
  readonly amount: number;
};

export type InvoiceCustomerInfoFragment = {
  readonly __typename: "InvoiceCustomerInfo";
  readonly name: string;
  readonly address: {
    readonly __typename: "Address";
    readonly line: string;
    readonly city: string;
    readonly subdivisionCode: string;
    readonly countryCode: string;
    readonly postalCode: string;
  };
};

export type InvoiceCustomerFragment = {
  readonly __typename: "InvoiceCustomer";
  readonly customerId: string;
  readonly index: number;
};

export type InvoiceFragment = {
  readonly __typename: "Invoice";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly number: number;
  readonly date: Date;
  readonly terms: InvoiceTermsOfPayment;
  readonly due: Date;
  readonly label: string;
  readonly status: InvoiceStatus;
  readonly feePercent: number;
  readonly feeAmount: number;
  readonly subtotalAmount: number;
  readonly taxPercent: number;
  readonly taxAmount: number;
  readonly totalAmount: number;
  readonly balanceAmount: number;
  readonly creditBeforeTaxAmount: number;
  readonly creditAfterTaxAmount: number;
  readonly contractId: string | null;
  readonly contractSelectionId: string | null;
  readonly customers: ReadonlyArray<{
    readonly __typename: "InvoiceCustomer";
    readonly customerId: string;
    readonly index: number;
  }>;
  readonly customerInfo: {
    readonly __typename: "InvoiceCustomerInfo";
    readonly name: string;
    readonly address: {
      readonly __typename: "Address";
      readonly line: string;
      readonly city: string;
      readonly subdivisionCode: string;
      readonly countryCode: string;
      readonly postalCode: string;
    };
  };
  readonly items: ReadonlyArray<{
    readonly __typename: "InvoiceItem";
    readonly id: string;
    readonly index: number;
    readonly type: InvoiceItemType;
    readonly name: string;
    readonly description: string | null;
    readonly quantity: number;
    readonly unitPrice: number;
    readonly amount: number;
  }>;
};

export type ContractSelectionGroupItemBreakdownLineFragment = {
  readonly __typename: "ContractSelectionGroupItemBreakdownLine";
  readonly activityId: string;
  readonly subcontractorAmount: number;
  readonly contractorAmount: number;
  readonly promoterAmount: number;
  readonly costAmount: number;
};

export type ContractSelectionGroupItemFragment = {
  readonly __typename: "ContractSelectionGroupItem";
  readonly id: string;
  readonly index: number;
  readonly sectionElementOptionType: SectionElementOptionType;
  readonly subcontractorAmount: number;
  readonly contractorAmount: number;
  readonly promoterAmount: number;
  readonly costAmount: number;
  readonly clientAmount: number;
  readonly contingencyAmount: number;
  readonly sectionId: string;
  readonly sectionElementId: string;
  readonly sectionElementOptionId: string | null;
  readonly breakdownLines: ReadonlyArray<{
    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
    readonly activityId: string;
    readonly subcontractorAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
    readonly costAmount: number;
  }>;
};

export type ContractSelectionGroupItemSetFragment = {
  readonly __typename: "ContractSelectionGroupItemSet";
  readonly sectionElementGroupId: string;
  readonly minIndex: number;
  readonly maxIndex: number;
  readonly items: ReadonlyArray<{
    readonly __typename: "ContractSelectionGroupItem";
    readonly id: string;
    readonly index: number;
    readonly sectionElementOptionType: SectionElementOptionType;
    readonly subcontractorAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
    readonly costAmount: number;
    readonly clientAmount: number;
    readonly contingencyAmount: number;
    readonly sectionId: string;
    readonly sectionElementId: string;
    readonly sectionElementOptionId: string | null;
    readonly breakdownLines: ReadonlyArray<{
      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
      readonly activityId: string;
      readonly subcontractorAmount: number;
      readonly contractorAmount: number;
      readonly promoterAmount: number;
      readonly costAmount: number;
    }>;
  }>;
};

type ContractSelectionGroupItemUnion_ContractSelectionGroupItem_Fragment = {
  readonly __typename: "ContractSelectionGroupItem";
  readonly id: string;
  readonly index: number;
  readonly sectionElementOptionType: SectionElementOptionType;
  readonly subcontractorAmount: number;
  readonly contractorAmount: number;
  readonly promoterAmount: number;
  readonly costAmount: number;
  readonly clientAmount: number;
  readonly contingencyAmount: number;
  readonly sectionId: string;
  readonly sectionElementId: string;
  readonly sectionElementOptionId: string | null;
  readonly breakdownLines: ReadonlyArray<{
    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
    readonly activityId: string;
    readonly subcontractorAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
    readonly costAmount: number;
  }>;
};

type ContractSelectionGroupItemUnion_ContractSelectionGroupItemSet_Fragment = {
  readonly __typename: "ContractSelectionGroupItemSet";
  readonly sectionElementGroupId: string;
  readonly minIndex: number;
  readonly maxIndex: number;
  readonly items: ReadonlyArray<{
    readonly __typename: "ContractSelectionGroupItem";
    readonly id: string;
    readonly index: number;
    readonly sectionElementOptionType: SectionElementOptionType;
    readonly subcontractorAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
    readonly costAmount: number;
    readonly clientAmount: number;
    readonly contingencyAmount: number;
    readonly sectionId: string;
    readonly sectionElementId: string;
    readonly sectionElementOptionId: string | null;
    readonly breakdownLines: ReadonlyArray<{
      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
      readonly activityId: string;
      readonly subcontractorAmount: number;
      readonly contractorAmount: number;
      readonly promoterAmount: number;
      readonly costAmount: number;
    }>;
  }>;
};

export type ContractSelectionGroupItemUnionFragment =
  | ContractSelectionGroupItemUnion_ContractSelectionGroupItem_Fragment
  | ContractSelectionGroupItemUnion_ContractSelectionGroupItemSet_Fragment;

export type ContractSelectionGroupVibeFragment = {
  readonly __typename: "ContractSelectionGroupVibe";
  readonly sectionVibeId: string;
  readonly mode: ContractSelectionGroupVibeMode;
  readonly subcontractorAmount: number;
  readonly contractorAmount: number;
  readonly promoterAmount: number;
  readonly costAmount: number;
  readonly clientAmount: number;
  readonly contingencyAmount: number;
};

export type ContractSelectionGroupFragment = {
  readonly __typename: "ContractSelectionGroup";
  readonly id: string;
  readonly index: number;
  readonly state: ContractSelectionGroupState;
  readonly numberOfExtras: number;
  readonly subcontractorAmount: number;
  readonly contractorAmount: number;
  readonly promoterAmount: number;
  readonly costAmount: number;
  readonly clientAmount: number;
  readonly contingencyAmount: number;
  readonly formSectionId: string;
  readonly vibe: {
    readonly __typename: "ContractSelectionGroupVibe";
    readonly sectionVibeId: string;
    readonly mode: ContractSelectionGroupVibeMode;
    readonly subcontractorAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
    readonly costAmount: number;
    readonly clientAmount: number;
    readonly contingencyAmount: number;
  } | null;
  readonly items: ReadonlyArray<
    | {
        readonly __typename: "ContractSelectionGroupItem";
        readonly id: string;
        readonly index: number;
        readonly sectionElementOptionType: SectionElementOptionType;
        readonly subcontractorAmount: number;
        readonly contractorAmount: number;
        readonly promoterAmount: number;
        readonly costAmount: number;
        readonly clientAmount: number;
        readonly contingencyAmount: number;
        readonly sectionId: string;
        readonly sectionElementId: string;
        readonly sectionElementOptionId: string | null;
        readonly breakdownLines: ReadonlyArray<{
          readonly __typename: "ContractSelectionGroupItemBreakdownLine";
          readonly activityId: string;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
        }>;
      }
    | {
        readonly __typename: "ContractSelectionGroupItemSet";
        readonly sectionElementGroupId: string;
        readonly minIndex: number;
        readonly maxIndex: number;
        readonly items: ReadonlyArray<{
          readonly __typename: "ContractSelectionGroupItem";
          readonly id: string;
          readonly index: number;
          readonly sectionElementOptionType: SectionElementOptionType;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly sectionId: string;
          readonly sectionElementId: string;
          readonly sectionElementOptionId: string | null;
          readonly breakdownLines: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroupItemBreakdownLine";
            readonly activityId: string;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
          }>;
        }>;
      }
  >;
};

export type ContractSelectionBillingSettingsFragment = {
  readonly __typename: "ContractSelectionBillingSettings";
  readonly taxGroupId: string;
  readonly terms: InvoiceTermsOfPayment;
  readonly revisionAmount: number;
  readonly creditAmount: number;
  readonly mixAndMatchAmount: number;
};

export type ContractSelectionFragment = {
  readonly __typename: "ContractSelection";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly label: string;
  readonly state: ContractSelectionState;
  readonly revisionNumber: number;
  readonly revisionReason: string | null;
  readonly numberOfExtras: number;
  readonly subcontractorAmount: number;
  readonly contractorAmount: number;
  readonly promoterAmount: number;
  readonly costAmount: number;
  readonly clientAmount: number;
  readonly contingencyAmount: number;
  readonly workflow: ContractSelectionWorkflow;
  readonly isTermsAndConditionsAccepted: boolean | null;
  readonly invoiceId: string | null;
  readonly billingSettings: {
    readonly __typename: "ContractSelectionBillingSettings";
    readonly taxGroupId: string;
    readonly terms: InvoiceTermsOfPayment;
    readonly revisionAmount: number;
    readonly creditAmount: number;
    readonly mixAndMatchAmount: number;
  };
  readonly groups: ReadonlyArray<{
    readonly __typename: "ContractSelectionGroup";
    readonly id: string;
    readonly index: number;
    readonly state: ContractSelectionGroupState;
    readonly numberOfExtras: number;
    readonly subcontractorAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
    readonly costAmount: number;
    readonly clientAmount: number;
    readonly contingencyAmount: number;
    readonly formSectionId: string;
    readonly vibe: {
      readonly __typename: "ContractSelectionGroupVibe";
      readonly sectionVibeId: string;
      readonly mode: ContractSelectionGroupVibeMode;
      readonly subcontractorAmount: number;
      readonly contractorAmount: number;
      readonly promoterAmount: number;
      readonly costAmount: number;
      readonly clientAmount: number;
      readonly contingencyAmount: number;
    } | null;
    readonly items: ReadonlyArray<
      | {
          readonly __typename: "ContractSelectionGroupItem";
          readonly id: string;
          readonly index: number;
          readonly sectionElementOptionType: SectionElementOptionType;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly sectionId: string;
          readonly sectionElementId: string;
          readonly sectionElementOptionId: string | null;
          readonly breakdownLines: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroupItemBreakdownLine";
            readonly activityId: string;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
          }>;
        }
      | {
          readonly __typename: "ContractSelectionGroupItemSet";
          readonly sectionElementGroupId: string;
          readonly minIndex: number;
          readonly maxIndex: number;
          readonly items: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroupItem";
            readonly id: string;
            readonly index: number;
            readonly sectionElementOptionType: SectionElementOptionType;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly sectionId: string;
            readonly sectionElementId: string;
            readonly sectionElementOptionId: string | null;
            readonly breakdownLines: ReadonlyArray<{
              readonly __typename: "ContractSelectionGroupItemBreakdownLine";
              readonly activityId: string;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
            }>;
          }>;
        }
    >;
  }>;
};

export type ContractCustomerFragment = {
  readonly __typename: "ContractCustomer";
  readonly customerId: string;
  readonly index: number;
};

export type ContractFragment = {
  readonly __typename: "Contract";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly state: ContractState;
  readonly isCanceled: boolean;
  readonly nonRefundableAmount: number;
  readonly totalAmount: number;
  readonly balanceAmount: number;
  readonly unitId: string;
  readonly formId: string | null;
  readonly currentSelectionId: string | null;
  readonly previousSelectionId: string | null;
  readonly customers: ReadonlyArray<{
    readonly __typename: "ContractCustomer";
    readonly customerId: string;
    readonly index: number;
  }>;
  readonly selections: ReadonlyArray<{
    readonly __typename: "ContractSelection";
    readonly id: string;
    readonly createdAt: Date;
    readonly updatedAt: Date;
    readonly label: string;
    readonly state: ContractSelectionState;
    readonly revisionNumber: number;
    readonly revisionReason: string | null;
    readonly numberOfExtras: number;
    readonly subcontractorAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
    readonly costAmount: number;
    readonly clientAmount: number;
    readonly contingencyAmount: number;
    readonly workflow: ContractSelectionWorkflow;
    readonly isTermsAndConditionsAccepted: boolean | null;
    readonly invoiceId: string | null;
    readonly billingSettings: {
      readonly __typename: "ContractSelectionBillingSettings";
      readonly taxGroupId: string;
      readonly terms: InvoiceTermsOfPayment;
      readonly revisionAmount: number;
      readonly creditAmount: number;
      readonly mixAndMatchAmount: number;
    };
    readonly groups: ReadonlyArray<{
      readonly __typename: "ContractSelectionGroup";
      readonly id: string;
      readonly index: number;
      readonly state: ContractSelectionGroupState;
      readonly numberOfExtras: number;
      readonly subcontractorAmount: number;
      readonly contractorAmount: number;
      readonly promoterAmount: number;
      readonly costAmount: number;
      readonly clientAmount: number;
      readonly contingencyAmount: number;
      readonly formSectionId: string;
      readonly vibe: {
        readonly __typename: "ContractSelectionGroupVibe";
        readonly sectionVibeId: string;
        readonly mode: ContractSelectionGroupVibeMode;
        readonly subcontractorAmount: number;
        readonly contractorAmount: number;
        readonly promoterAmount: number;
        readonly costAmount: number;
        readonly clientAmount: number;
        readonly contingencyAmount: number;
      } | null;
      readonly items: ReadonlyArray<
        | {
            readonly __typename: "ContractSelectionGroupItem";
            readonly id: string;
            readonly index: number;
            readonly sectionElementOptionType: SectionElementOptionType;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly sectionId: string;
            readonly sectionElementId: string;
            readonly sectionElementOptionId: string | null;
            readonly breakdownLines: ReadonlyArray<{
              readonly __typename: "ContractSelectionGroupItemBreakdownLine";
              readonly activityId: string;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
            }>;
          }
        | {
            readonly __typename: "ContractSelectionGroupItemSet";
            readonly sectionElementGroupId: string;
            readonly minIndex: number;
            readonly maxIndex: number;
            readonly items: ReadonlyArray<{
              readonly __typename: "ContractSelectionGroupItem";
              readonly id: string;
              readonly index: number;
              readonly sectionElementOptionType: SectionElementOptionType;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
              readonly sectionId: string;
              readonly sectionElementId: string;
              readonly sectionElementOptionId: string | null;
              readonly breakdownLines: ReadonlyArray<{
                readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                readonly activityId: string;
                readonly subcontractorAmount: number;
                readonly contractorAmount: number;
                readonly promoterAmount: number;
                readonly costAmount: number;
              }>;
            }>;
          }
      >;
    }>;
  }>;
};

export type UnitFragment = {
  readonly __typename: "Unit";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly state: UnitState;
  readonly number: string;
  readonly floor: number;
  readonly numberOfBathrooms: number;
  readonly numberOfBedrooms: number;
  readonly label: string;
  readonly formId: string | null;
  readonly currentContractId: string | null;
};

export type SectionVibeItemFragment = {
  readonly __typename: "SectionVibeItem";
  readonly id: string;
  readonly index: number;
  readonly subcontractorAmount: number;
  readonly contractorAmount: number;
  readonly promoterAmount: number;
  readonly costAmount: number;
  readonly sectionElementId: string;
  readonly sectionElementOptionId: string;
};

export type SectionVibeFragment = {
  readonly __typename: "SectionVibe";
  readonly id: string;
  readonly index: number;
  readonly name: string;
  readonly mode: SectionVibeMode;
  readonly subcontractorAmount: number;
  readonly contractorAmount: number;
  readonly promoterAmount: number;
  readonly costAmount: number;
  readonly clientAmount: number;
  readonly contingencyAmount: number;
  readonly items: ReadonlyArray<{
    readonly __typename: "SectionVibeItem";
    readonly id: string;
    readonly index: number;
    readonly subcontractorAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
    readonly costAmount: number;
    readonly sectionElementId: string;
    readonly sectionElementOptionId: string;
  }>;
};

export type SectionElementOptionBreakdownLineFragment = {
  readonly __typename: "SectionElementOptionBreakdownLine";
  readonly activityId: string;
  readonly subcontractorAmount: number;
  readonly contractorAmount: number;
  readonly promoterAmount: number;
  readonly costAmount: number;
};

export type SectionElementOptionModelLinkFragment = {
  readonly __typename: "SectionElementOptionModelLink";
  readonly modelId: string;
  readonly modelRoomId: string;
  readonly modelRoomElementId: string;
  readonly modelRoomElementOptionId: string;
};

export type SectionElementOptionFragment = {
  readonly __typename: "SectionElementOption";
  readonly id: string;
  readonly index: number;
  readonly type: SectionElementOptionType;
  readonly subcontractorAmount: number;
  readonly contractorAmount: number;
  readonly promoterAmount: number;
  readonly costAmount: number;
  readonly clientAmount: number;
  readonly contingencyAmount: number;
  readonly isUnlocked: boolean;
  readonly productId: string;
  readonly modelLink: {
    readonly __typename: "SectionElementOptionModelLink";
    readonly modelId: string;
    readonly modelRoomId: string;
    readonly modelRoomElementId: string;
    readonly modelRoomElementOptionId: string;
  } | null;
  readonly breakdownLines: ReadonlyArray<{
    readonly __typename: "SectionElementOptionBreakdownLine";
    readonly activityId: string;
    readonly subcontractorAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
    readonly costAmount: number;
  }>;
};

export type SectionElementOptionSetFragment = {
  readonly __typename: "SectionElementOptionSet";
  readonly productCategoryId: string;
  readonly minIndex: number;
  readonly maxIndex: number;
  readonly options: ReadonlyArray<{
    readonly __typename: "SectionElementOption";
    readonly id: string;
    readonly index: number;
    readonly type: SectionElementOptionType;
    readonly subcontractorAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
    readonly costAmount: number;
    readonly clientAmount: number;
    readonly contingencyAmount: number;
    readonly isUnlocked: boolean;
    readonly productId: string;
    readonly modelLink: {
      readonly __typename: "SectionElementOptionModelLink";
      readonly modelId: string;
      readonly modelRoomId: string;
      readonly modelRoomElementId: string;
      readonly modelRoomElementOptionId: string;
    } | null;
    readonly breakdownLines: ReadonlyArray<{
      readonly __typename: "SectionElementOptionBreakdownLine";
      readonly activityId: string;
      readonly subcontractorAmount: number;
      readonly contractorAmount: number;
      readonly promoterAmount: number;
      readonly costAmount: number;
    }>;
  }>;
};

type SectionElementOptionUnion_SectionElementOption_Fragment = {
  readonly __typename: "SectionElementOption";
  readonly id: string;
  readonly index: number;
  readonly type: SectionElementOptionType;
  readonly subcontractorAmount: number;
  readonly contractorAmount: number;
  readonly promoterAmount: number;
  readonly costAmount: number;
  readonly clientAmount: number;
  readonly contingencyAmount: number;
  readonly isUnlocked: boolean;
  readonly productId: string;
  readonly modelLink: {
    readonly __typename: "SectionElementOptionModelLink";
    readonly modelId: string;
    readonly modelRoomId: string;
    readonly modelRoomElementId: string;
    readonly modelRoomElementOptionId: string;
  } | null;
  readonly breakdownLines: ReadonlyArray<{
    readonly __typename: "SectionElementOptionBreakdownLine";
    readonly activityId: string;
    readonly subcontractorAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
    readonly costAmount: number;
  }>;
};

type SectionElementOptionUnion_SectionElementOptionSet_Fragment = {
  readonly __typename: "SectionElementOptionSet";
  readonly productCategoryId: string;
  readonly minIndex: number;
  readonly maxIndex: number;
  readonly options: ReadonlyArray<{
    readonly __typename: "SectionElementOption";
    readonly id: string;
    readonly index: number;
    readonly type: SectionElementOptionType;
    readonly subcontractorAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
    readonly costAmount: number;
    readonly clientAmount: number;
    readonly contingencyAmount: number;
    readonly isUnlocked: boolean;
    readonly productId: string;
    readonly modelLink: {
      readonly __typename: "SectionElementOptionModelLink";
      readonly modelId: string;
      readonly modelRoomId: string;
      readonly modelRoomElementId: string;
      readonly modelRoomElementOptionId: string;
    } | null;
    readonly breakdownLines: ReadonlyArray<{
      readonly __typename: "SectionElementOptionBreakdownLine";
      readonly activityId: string;
      readonly subcontractorAmount: number;
      readonly contractorAmount: number;
      readonly promoterAmount: number;
      readonly costAmount: number;
    }>;
  }>;
};

export type SectionElementOptionUnionFragment =
  | SectionElementOptionUnion_SectionElementOption_Fragment
  | SectionElementOptionUnion_SectionElementOptionSet_Fragment;

export type SectionElementModelLinkFragment = {
  readonly __typename: "SectionElementModelLink";
  readonly modelId: string;
  readonly modelRoomId: string;
  readonly modelRoomElementId: string;
};

export type SectionElementFragment = {
  readonly __typename: "SectionElement";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly index: number;
  readonly type: SectionElementType;
  readonly label: string;
  readonly notes: string | null;
  readonly isOptional: boolean;
  readonly isManagedByVibes: boolean;
  readonly hideProductCategory: boolean;
  readonly groupId: string | null;
  readonly modelLink: {
    readonly __typename: "SectionElementModelLink";
    readonly modelId: string;
    readonly modelRoomId: string;
    readonly modelRoomElementId: string;
  } | null;
  readonly options: ReadonlyArray<
    | {
        readonly __typename: "SectionElementOption";
        readonly id: string;
        readonly index: number;
        readonly type: SectionElementOptionType;
        readonly subcontractorAmount: number;
        readonly contractorAmount: number;
        readonly promoterAmount: number;
        readonly costAmount: number;
        readonly clientAmount: number;
        readonly contingencyAmount: number;
        readonly isUnlocked: boolean;
        readonly productId: string;
        readonly modelLink: {
          readonly __typename: "SectionElementOptionModelLink";
          readonly modelId: string;
          readonly modelRoomId: string;
          readonly modelRoomElementId: string;
          readonly modelRoomElementOptionId: string;
        } | null;
        readonly breakdownLines: ReadonlyArray<{
          readonly __typename: "SectionElementOptionBreakdownLine";
          readonly activityId: string;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
        }>;
      }
    | {
        readonly __typename: "SectionElementOptionSet";
        readonly productCategoryId: string;
        readonly minIndex: number;
        readonly maxIndex: number;
        readonly options: ReadonlyArray<{
          readonly __typename: "SectionElementOption";
          readonly id: string;
          readonly index: number;
          readonly type: SectionElementOptionType;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly isUnlocked: boolean;
          readonly productId: string;
          readonly modelLink: {
            readonly __typename: "SectionElementOptionModelLink";
            readonly modelId: string;
            readonly modelRoomId: string;
            readonly modelRoomElementId: string;
            readonly modelRoomElementOptionId: string;
          } | null;
          readonly breakdownLines: ReadonlyArray<{
            readonly __typename: "SectionElementOptionBreakdownLine";
            readonly activityId: string;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
          }>;
        }>;
      }
  >;
};

export type SectionElementSetFragment = {
  readonly __typename: "SectionElementSet";
  readonly sectionElementGroupId: string;
  readonly minIndex: number;
  readonly maxIndex: number;
  readonly elements: ReadonlyArray<{
    readonly __typename: "SectionElement";
    readonly id: string;
    readonly createdAt: Date;
    readonly updatedAt: Date;
    readonly index: number;
    readonly type: SectionElementType;
    readonly label: string;
    readonly notes: string | null;
    readonly isOptional: boolean;
    readonly isManagedByVibes: boolean;
    readonly hideProductCategory: boolean;
    readonly groupId: string | null;
    readonly modelLink: {
      readonly __typename: "SectionElementModelLink";
      readonly modelId: string;
      readonly modelRoomId: string;
      readonly modelRoomElementId: string;
    } | null;
    readonly options: ReadonlyArray<
      | {
          readonly __typename: "SectionElementOption";
          readonly id: string;
          readonly index: number;
          readonly type: SectionElementOptionType;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly isUnlocked: boolean;
          readonly productId: string;
          readonly modelLink: {
            readonly __typename: "SectionElementOptionModelLink";
            readonly modelId: string;
            readonly modelRoomId: string;
            readonly modelRoomElementId: string;
            readonly modelRoomElementOptionId: string;
          } | null;
          readonly breakdownLines: ReadonlyArray<{
            readonly __typename: "SectionElementOptionBreakdownLine";
            readonly activityId: string;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
          }>;
        }
      | {
          readonly __typename: "SectionElementOptionSet";
          readonly productCategoryId: string;
          readonly minIndex: number;
          readonly maxIndex: number;
          readonly options: ReadonlyArray<{
            readonly __typename: "SectionElementOption";
            readonly id: string;
            readonly index: number;
            readonly type: SectionElementOptionType;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly isUnlocked: boolean;
            readonly productId: string;
            readonly modelLink: {
              readonly __typename: "SectionElementOptionModelLink";
              readonly modelId: string;
              readonly modelRoomId: string;
              readonly modelRoomElementId: string;
              readonly modelRoomElementOptionId: string;
            } | null;
            readonly breakdownLines: ReadonlyArray<{
              readonly __typename: "SectionElementOptionBreakdownLine";
              readonly activityId: string;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
            }>;
          }>;
        }
    >;
  }>;
};

type SectionElementUnion_SectionElement_Fragment = {
  readonly __typename: "SectionElement";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly index: number;
  readonly type: SectionElementType;
  readonly label: string;
  readonly notes: string | null;
  readonly isOptional: boolean;
  readonly isManagedByVibes: boolean;
  readonly hideProductCategory: boolean;
  readonly groupId: string | null;
  readonly modelLink: {
    readonly __typename: "SectionElementModelLink";
    readonly modelId: string;
    readonly modelRoomId: string;
    readonly modelRoomElementId: string;
  } | null;
  readonly options: ReadonlyArray<
    | {
        readonly __typename: "SectionElementOption";
        readonly id: string;
        readonly index: number;
        readonly type: SectionElementOptionType;
        readonly subcontractorAmount: number;
        readonly contractorAmount: number;
        readonly promoterAmount: number;
        readonly costAmount: number;
        readonly clientAmount: number;
        readonly contingencyAmount: number;
        readonly isUnlocked: boolean;
        readonly productId: string;
        readonly modelLink: {
          readonly __typename: "SectionElementOptionModelLink";
          readonly modelId: string;
          readonly modelRoomId: string;
          readonly modelRoomElementId: string;
          readonly modelRoomElementOptionId: string;
        } | null;
        readonly breakdownLines: ReadonlyArray<{
          readonly __typename: "SectionElementOptionBreakdownLine";
          readonly activityId: string;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
        }>;
      }
    | {
        readonly __typename: "SectionElementOptionSet";
        readonly productCategoryId: string;
        readonly minIndex: number;
        readonly maxIndex: number;
        readonly options: ReadonlyArray<{
          readonly __typename: "SectionElementOption";
          readonly id: string;
          readonly index: number;
          readonly type: SectionElementOptionType;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly isUnlocked: boolean;
          readonly productId: string;
          readonly modelLink: {
            readonly __typename: "SectionElementOptionModelLink";
            readonly modelId: string;
            readonly modelRoomId: string;
            readonly modelRoomElementId: string;
            readonly modelRoomElementOptionId: string;
          } | null;
          readonly breakdownLines: ReadonlyArray<{
            readonly __typename: "SectionElementOptionBreakdownLine";
            readonly activityId: string;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
          }>;
        }>;
      }
  >;
};

type SectionElementUnion_SectionElementSet_Fragment = {
  readonly __typename: "SectionElementSet";
  readonly sectionElementGroupId: string;
  readonly minIndex: number;
  readonly maxIndex: number;
  readonly elements: ReadonlyArray<{
    readonly __typename: "SectionElement";
    readonly id: string;
    readonly createdAt: Date;
    readonly updatedAt: Date;
    readonly index: number;
    readonly type: SectionElementType;
    readonly label: string;
    readonly notes: string | null;
    readonly isOptional: boolean;
    readonly isManagedByVibes: boolean;
    readonly hideProductCategory: boolean;
    readonly groupId: string | null;
    readonly modelLink: {
      readonly __typename: "SectionElementModelLink";
      readonly modelId: string;
      readonly modelRoomId: string;
      readonly modelRoomElementId: string;
    } | null;
    readonly options: ReadonlyArray<
      | {
          readonly __typename: "SectionElementOption";
          readonly id: string;
          readonly index: number;
          readonly type: SectionElementOptionType;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly isUnlocked: boolean;
          readonly productId: string;
          readonly modelLink: {
            readonly __typename: "SectionElementOptionModelLink";
            readonly modelId: string;
            readonly modelRoomId: string;
            readonly modelRoomElementId: string;
            readonly modelRoomElementOptionId: string;
          } | null;
          readonly breakdownLines: ReadonlyArray<{
            readonly __typename: "SectionElementOptionBreakdownLine";
            readonly activityId: string;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
          }>;
        }
      | {
          readonly __typename: "SectionElementOptionSet";
          readonly productCategoryId: string;
          readonly minIndex: number;
          readonly maxIndex: number;
          readonly options: ReadonlyArray<{
            readonly __typename: "SectionElementOption";
            readonly id: string;
            readonly index: number;
            readonly type: SectionElementOptionType;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly isUnlocked: boolean;
            readonly productId: string;
            readonly modelLink: {
              readonly __typename: "SectionElementOptionModelLink";
              readonly modelId: string;
              readonly modelRoomId: string;
              readonly modelRoomElementId: string;
              readonly modelRoomElementOptionId: string;
            } | null;
            readonly breakdownLines: ReadonlyArray<{
              readonly __typename: "SectionElementOptionBreakdownLine";
              readonly activityId: string;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
            }>;
          }>;
        }
    >;
  }>;
};

export type SectionElementUnionFragment =
  | SectionElementUnion_SectionElement_Fragment
  | SectionElementUnion_SectionElementSet_Fragment;

export type SectionElementGroupFragment = {
  readonly __typename: "SectionElementGroup";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly index: number;
  readonly name: string;
};

export type SectionModelLinkFragment = {
  readonly __typename: "SectionModelLink";
  readonly modelId: string;
  readonly modelRoomId: string;
};

export type SectionFragment = {
  readonly __typename: "Section";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly state: SectionState;
  readonly title: string;
  readonly index: number;
  readonly modelLink: {
    readonly __typename: "SectionModelLink";
    readonly modelId: string;
    readonly modelRoomId: string;
  } | null;
  readonly elements: ReadonlyArray<
    | {
        readonly __typename: "SectionElement";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly index: number;
        readonly type: SectionElementType;
        readonly label: string;
        readonly notes: string | null;
        readonly isOptional: boolean;
        readonly isManagedByVibes: boolean;
        readonly hideProductCategory: boolean;
        readonly groupId: string | null;
        readonly modelLink: {
          readonly __typename: "SectionElementModelLink";
          readonly modelId: string;
          readonly modelRoomId: string;
          readonly modelRoomElementId: string;
        } | null;
        readonly options: ReadonlyArray<
          | {
              readonly __typename: "SectionElementOption";
              readonly id: string;
              readonly index: number;
              readonly type: SectionElementOptionType;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
              readonly isUnlocked: boolean;
              readonly productId: string;
              readonly modelLink: {
                readonly __typename: "SectionElementOptionModelLink";
                readonly modelId: string;
                readonly modelRoomId: string;
                readonly modelRoomElementId: string;
                readonly modelRoomElementOptionId: string;
              } | null;
              readonly breakdownLines: ReadonlyArray<{
                readonly __typename: "SectionElementOptionBreakdownLine";
                readonly activityId: string;
                readonly subcontractorAmount: number;
                readonly contractorAmount: number;
                readonly promoterAmount: number;
                readonly costAmount: number;
              }>;
            }
          | {
              readonly __typename: "SectionElementOptionSet";
              readonly productCategoryId: string;
              readonly minIndex: number;
              readonly maxIndex: number;
              readonly options: ReadonlyArray<{
                readonly __typename: "SectionElementOption";
                readonly id: string;
                readonly index: number;
                readonly type: SectionElementOptionType;
                readonly subcontractorAmount: number;
                readonly contractorAmount: number;
                readonly promoterAmount: number;
                readonly costAmount: number;
                readonly clientAmount: number;
                readonly contingencyAmount: number;
                readonly isUnlocked: boolean;
                readonly productId: string;
                readonly modelLink: {
                  readonly __typename: "SectionElementOptionModelLink";
                  readonly modelId: string;
                  readonly modelRoomId: string;
                  readonly modelRoomElementId: string;
                  readonly modelRoomElementOptionId: string;
                } | null;
                readonly breakdownLines: ReadonlyArray<{
                  readonly __typename: "SectionElementOptionBreakdownLine";
                  readonly activityId: string;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                }>;
              }>;
            }
        >;
      }
    | {
        readonly __typename: "SectionElementSet";
        readonly sectionElementGroupId: string;
        readonly minIndex: number;
        readonly maxIndex: number;
        readonly elements: ReadonlyArray<{
          readonly __typename: "SectionElement";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly index: number;
          readonly type: SectionElementType;
          readonly label: string;
          readonly notes: string | null;
          readonly isOptional: boolean;
          readonly isManagedByVibes: boolean;
          readonly hideProductCategory: boolean;
          readonly groupId: string | null;
          readonly modelLink: {
            readonly __typename: "SectionElementModelLink";
            readonly modelId: string;
            readonly modelRoomId: string;
            readonly modelRoomElementId: string;
          } | null;
          readonly options: ReadonlyArray<
            | {
                readonly __typename: "SectionElementOption";
                readonly id: string;
                readonly index: number;
                readonly type: SectionElementOptionType;
                readonly subcontractorAmount: number;
                readonly contractorAmount: number;
                readonly promoterAmount: number;
                readonly costAmount: number;
                readonly clientAmount: number;
                readonly contingencyAmount: number;
                readonly isUnlocked: boolean;
                readonly productId: string;
                readonly modelLink: {
                  readonly __typename: "SectionElementOptionModelLink";
                  readonly modelId: string;
                  readonly modelRoomId: string;
                  readonly modelRoomElementId: string;
                  readonly modelRoomElementOptionId: string;
                } | null;
                readonly breakdownLines: ReadonlyArray<{
                  readonly __typename: "SectionElementOptionBreakdownLine";
                  readonly activityId: string;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                }>;
              }
            | {
                readonly __typename: "SectionElementOptionSet";
                readonly productCategoryId: string;
                readonly minIndex: number;
                readonly maxIndex: number;
                readonly options: ReadonlyArray<{
                  readonly __typename: "SectionElementOption";
                  readonly id: string;
                  readonly index: number;
                  readonly type: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly isUnlocked: boolean;
                  readonly productId: string;
                  readonly modelLink: {
                    readonly __typename: "SectionElementOptionModelLink";
                    readonly modelId: string;
                    readonly modelRoomId: string;
                    readonly modelRoomElementId: string;
                    readonly modelRoomElementOptionId: string;
                  } | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "SectionElementOptionBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }>;
              }
          >;
        }>;
      }
  >;
  readonly elementGroups: ReadonlyArray<{
    readonly __typename: "SectionElementGroup";
    readonly id: string;
    readonly createdAt: Date;
    readonly updatedAt: Date;
    readonly index: number;
    readonly name: string;
  }>;
  readonly vibes: ReadonlyArray<{
    readonly __typename: "SectionVibe";
    readonly id: string;
    readonly index: number;
    readonly name: string;
    readonly mode: SectionVibeMode;
    readonly subcontractorAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
    readonly costAmount: number;
    readonly clientAmount: number;
    readonly contingencyAmount: number;
    readonly items: ReadonlyArray<{
      readonly __typename: "SectionVibeItem";
      readonly id: string;
      readonly index: number;
      readonly subcontractorAmount: number;
      readonly contractorAmount: number;
      readonly promoterAmount: number;
      readonly costAmount: number;
      readonly sectionElementId: string;
      readonly sectionElementOptionId: string;
    }>;
  }>;
};

export type FormReportVibeRoomLinkFragment = {
  readonly __typename: "FormReportVibeRoomLink";
  readonly formSectionId: string;
  readonly reportId: string;
  readonly reportRoomId: string;
};

export type FormReportRoomElementLinkFragment = {
  readonly __typename: "FormReportRoomElementLink";
  readonly formSectionId: string;
  readonly sectionElementId: string;
  readonly reportId: string;
  readonly reportRoomId: string;
  readonly reportRoomElementId: string;
};

export type FormSectionFragment = {
  readonly __typename: "FormSection";
  readonly id: string;
  readonly index: number;
  readonly title: string;
  readonly sectionId: string;
};

export type FormFragment = {
  readonly __typename: "Form";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly index: number;
  readonly name: string;
  readonly state: FormState;
  readonly modelId: string | null;
  readonly sections: ReadonlyArray<{
    readonly __typename: "FormSection";
    readonly id: string;
    readonly index: number;
    readonly title: string;
    readonly sectionId: string;
  }>;
  readonly reportRoomElementLinks: ReadonlyArray<{
    readonly __typename: "FormReportRoomElementLink";
    readonly formSectionId: string;
    readonly sectionElementId: string;
    readonly reportId: string;
    readonly reportRoomId: string;
    readonly reportRoomElementId: string;
  }>;
  readonly reportVibeRoomLinks: ReadonlyArray<{
    readonly __typename: "FormReportVibeRoomLink";
    readonly formSectionId: string;
    readonly reportId: string;
    readonly reportRoomId: string;
  }>;
};

export type ProductCategoryFragment = {
  readonly __typename: "ProductCategory";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly name: string;
  readonly displayName: string | null;
  readonly description: string | null;
  readonly managedByModels: boolean;
};

export type ProductActivityFragment = {
  readonly __typename: "ProductActivity";
  readonly activityId: string;
  readonly index: number;
};

export type ProductProviderFragment = {
  readonly __typename: "ProductProvider";
  readonly id: string | null;
  readonly productModel: string | null;
  readonly productCode: string | null;
  readonly productUrl: string | null;
};

export type ProductFragment = {
  readonly __typename: "Product";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly name: string;
  readonly variant: string;
  readonly format: string | null;
  readonly description: string | null;
  readonly thumbnailUrl: string;
  readonly hasThumbnail: boolean;
  readonly managedByModels: boolean;
  readonly usedBySections: boolean;
  readonly categoryId: string;
  readonly provider: {
    readonly __typename: "ProductProvider";
    readonly id: string | null;
    readonly productModel: string | null;
    readonly productCode: string | null;
    readonly productUrl: string | null;
  };
  readonly activities: ReadonlyArray<{
    readonly __typename: "ProductActivity";
    readonly activityId: string;
    readonly index: number;
  }>;
};

export type ModelRoomElementOptionFragment = {
  readonly __typename: "ModelRoomElementOption";
  readonly id: string;
  readonly sharedId: string;
  readonly index: number;
  readonly productId: string;
};

export type ModelRoomElementFragment = {
  readonly __typename: "ModelRoomElement";
  readonly id: string;
  readonly sharedId: string;
  readonly name: string;
  readonly index: number;
  readonly type: ModelRoomElementType;
  readonly options: ReadonlyArray<{
    readonly __typename: "ModelRoomElementOption";
    readonly id: string;
    readonly sharedId: string;
    readonly index: number;
    readonly productId: string;
  }>;
};

export type ModelRoomFragment = {
  readonly __typename: "ModelRoom";
  readonly id: string;
  readonly sharedId: string;
  readonly index: number;
  readonly name: string;
  readonly elements: ReadonlyArray<{
    readonly __typename: "ModelRoomElement";
    readonly id: string;
    readonly sharedId: string;
    readonly name: string;
    readonly index: number;
    readonly type: ModelRoomElementType;
    readonly options: ReadonlyArray<{
      readonly __typename: "ModelRoomElementOption";
      readonly id: string;
      readonly sharedId: string;
      readonly index: number;
      readonly productId: string;
    }>;
  }>;
};

export type ModelFragment = {
  readonly __typename: "Model";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly sharedId: string;
  readonly name: string;
  readonly rooms: ReadonlyArray<{
    readonly __typename: "ModelRoom";
    readonly id: string;
    readonly sharedId: string;
    readonly index: number;
    readonly name: string;
    readonly elements: ReadonlyArray<{
      readonly __typename: "ModelRoomElement";
      readonly id: string;
      readonly sharedId: string;
      readonly name: string;
      readonly index: number;
      readonly type: ModelRoomElementType;
      readonly options: ReadonlyArray<{
        readonly __typename: "ModelRoomElementOption";
        readonly id: string;
        readonly sharedId: string;
        readonly index: number;
        readonly productId: string;
      }>;
    }>;
  }>;
};

export type ReportRoomElementFragment = {
  readonly __typename: "ReportRoomElement";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly index: number;
  readonly name: string;
  readonly display: ReportRoomElementDisplay;
};

export type ReportRoomFragment = {
  readonly __typename: "ReportRoom";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly index: number;
  readonly name: string;
  readonly elements: ReadonlyArray<{
    readonly __typename: "ReportRoomElement";
    readonly id: string;
    readonly createdAt: Date;
    readonly updatedAt: Date;
    readonly index: number;
    readonly name: string;
    readonly display: ReportRoomElementDisplay;
  }>;
};

export type ReportActivityFragment = {
  readonly __typename: "ReportActivity";
  readonly activityId: string;
  readonly index: number;
};

export type ReportFragment = {
  readonly __typename: "Report";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly name: string;
  readonly activities: ReadonlyArray<{
    readonly __typename: "ReportActivity";
    readonly activityId: string;
    readonly index: number;
  }>;
  readonly rooms: ReadonlyArray<{
    readonly __typename: "ReportRoom";
    readonly id: string;
    readonly createdAt: Date;
    readonly updatedAt: Date;
    readonly index: number;
    readonly name: string;
    readonly elements: ReadonlyArray<{
      readonly __typename: "ReportRoomElement";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly index: number;
      readonly name: string;
      readonly display: ReportRoomElementDisplay;
    }>;
  }>;
};

export type ActivityFragment = {
  readonly __typename: "Activity";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly number: string;
  readonly title: string;
  readonly label: string;
  readonly description: string | null;
};

export type ProjectContactInfoFragment = {
  readonly __typename: "ProjectContactInfo";
  readonly companyName: string;
  readonly email: string;
  readonly phone: string;
  readonly websiteUrl: string;
  readonly federalTaxNumber: string | null;
  readonly provincialTaxNumber: string | null;
  readonly address: {
    readonly __typename: "Address";
    readonly line: string;
    readonly city: string;
    readonly subdivisionCode: string;
    readonly countryCode: string;
    readonly postalCode: string;
  };
};

export type ProjectFragment = {
  readonly __typename: "Project";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly code: string;
  readonly name: string;
  readonly label: string;
  readonly description: string | null;
  readonly tenantId: string;
  readonly contactInfo: {
    readonly __typename: "ProjectContactInfo";
    readonly companyName: string;
    readonly email: string;
    readonly phone: string;
    readonly websiteUrl: string;
    readonly federalTaxNumber: string | null;
    readonly provincialTaxNumber: string | null;
    readonly address: {
      readonly __typename: "Address";
      readonly line: string;
      readonly city: string;
      readonly subdivisionCode: string;
      readonly countryCode: string;
      readonly postalCode: string;
    };
  };
};

export type ProviderFragment = {
  readonly __typename: "Provider";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly name: string;
  readonly websiteUrl: string | null;
  readonly description: string | null;
};

export type CustomerFragment = {
  readonly __typename: "Customer";
  readonly id: string;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly fullName: string | null;
  readonly companyName: string | null;
  readonly displayName: string;
  readonly type: CustomerType;
  readonly label: string;
  readonly email: string;
  readonly phone: string;
  readonly gender: Gender;
  readonly language: Language;
  readonly notes: string | null;
  readonly isDefault: boolean;
  readonly address: {
    readonly __typename: "Address";
    readonly line: string;
    readonly city: string;
    readonly subdivisionCode: string;
    readonly countryCode: string;
    readonly postalCode: string;
  };
};

export type TaxFragment = {
  readonly __typename: "Tax";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly name: string;
  readonly rate: number;
  readonly agencyId: string;
};

export type TaxGroupFragment = {
  readonly __typename: "TaxGroup";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly name: string;
  readonly taxIds: ReadonlyArray<string>;
};

export type TaxAgencyFragment = {
  readonly __typename: "TaxAgency";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly name: string;
  readonly description: string | null;
};

export type PermissionFragment = {
  readonly __typename: "Permission";
  readonly name: PermissionName;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly index: number;
  readonly displayName: string;
  readonly description: string;
  readonly groupName: PermissionGroupName;
};

export type RoleFragment = {
  readonly __typename: "Role";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly name: string;
  readonly description: string;
  readonly template: RoleTemplate;
  readonly isLocked: boolean;
  readonly isTenantProfileManager: boolean;
  readonly isTenantSecurityManager: boolean;
  readonly isTenantAccountingManager: boolean;
  readonly isTenantProjectManager: boolean;
  readonly permissions: ReadonlyArray<PermissionName>;
};

export type UserFragment = {
  readonly __typename: "User";
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly fullName: string;
  readonly isTest: boolean;
  readonly isBlocked: boolean;
  readonly roleId: string;
  readonly hasAccessToAllProjects: boolean;
  readonly hasAccessToTestTenant: boolean;
  readonly projectIds: ReadonlyArray<string>;
};

export type UserInfoTenantFragment = {
  readonly __typename: "UserInfoTenant";
  readonly id: string;
  readonly testId: string;
  readonly isProfileManager: boolean;
  readonly isProjectManager: boolean;
  readonly isSecurityManager: boolean;
  readonly isAccountingManager: boolean;
  readonly isBlocked: boolean;
  readonly hasAccessToAllProjects: boolean;
  readonly hasAccessToTestTenant: boolean;
  readonly permissions: ReadonlyArray<PermissionName>;
  readonly projectIds: ReadonlyArray<string>;
};

export type UserInfoFragment = {
  readonly __typename: "UserInfo";
  readonly userId: string;
  readonly email: string;
  readonly isSuperAdmin: boolean;
  readonly tenants: ReadonlyArray<{
    readonly __typename: "UserInfoTenant";
    readonly id: string;
    readonly testId: string;
    readonly isProfileManager: boolean;
    readonly isProjectManager: boolean;
    readonly isSecurityManager: boolean;
    readonly isAccountingManager: boolean;
    readonly isBlocked: boolean;
    readonly hasAccessToAllProjects: boolean;
    readonly hasAccessToTestTenant: boolean;
    readonly permissions: ReadonlyArray<PermissionName>;
    readonly projectIds: ReadonlyArray<string>;
  }>;
};

export type TenantProfileFragment = {
  readonly __typename: "TenantProfile";
  readonly companyName: string;
  readonly email: string;
  readonly phone: string;
  readonly language: Language;
  readonly currencyCode: CurrencyCode;
  readonly websiteUrl: string | null;
  readonly address: {
    readonly __typename: "Address";
    readonly line: string;
    readonly city: string;
    readonly subdivisionCode: string;
    readonly countryCode: string;
    readonly postalCode: string;
  };
};

export type TenantFragment = {
  readonly __typename: "Tenant";
  readonly id: string;
  readonly type: TenantType;
  readonly status: TenantStatus;
  readonly name: string;
};

export type CountrySubdivisionFragment = {
  readonly __typename: "CountrySubdivision";
  readonly code: string;
  readonly name: string;
  readonly type: CountrySubdivisionType;
};

export type CountryFragment = {
  readonly __typename: "Country";
  readonly code: string;
  readonly name: string;
  readonly callingCode: string;
  readonly subdivisions: ReadonlyArray<{
    readonly __typename: "CountrySubdivision";
    readonly code: string;
    readonly name: string;
    readonly type: CountrySubdivisionType;
  }>;
};

export type ReportVibeTableRowFragment = {
  readonly __typename: "ReportVibeTableRow";
  readonly index: number;
  readonly name: string;
  readonly value: string;
  readonly type: ReportCellType;
};

export type ReportCustomerInfoFragment = {
  readonly __typename: "ReportCustomerInfo";
  readonly customerId: string;
  readonly index: number;
  readonly fullName: string;
  readonly phone: string;
  readonly email: string;
};

export type ReportContractSelectionInfoFragment = {
  readonly __typename: "ReportContractSelectionInfo";
  readonly contractSelectionId: string;
  readonly revisionNumber: number;
  readonly name: string;
  readonly date: Date;
};

export type SummaryReportVibeColumnFragment = {
  readonly __typename: "SummaryReportVibeColumn";
  readonly index: number;
  readonly name: string;
  readonly reportRoomId: string;
};

export type SummaryReportElementColumnFragment = {
  readonly __typename: "SummaryReportElementColumn";
  readonly index: number;
  readonly name: string;
  readonly reportRoomElementId: string;
};

export type SummaryReportRoomColumnGroupFragment = {
  readonly __typename: "SummaryReportRoomColumnGroup";
  readonly index: number;
  readonly name: string;
  readonly colspan: number;
  readonly reportRoomId: string;
  readonly elementColumns: ReadonlyArray<{
    readonly __typename: "SummaryReportElementColumn";
    readonly index: number;
    readonly name: string;
    readonly reportRoomElementId: string;
  }>;
};

export type SummaryReportActivityColumnGroupFragment = {
  readonly __typename: "SummaryReportActivityColumnGroup";
  readonly index: number;
  readonly name: string;
  readonly colspan: number;
  readonly activityId: string;
  readonly roomColumnGroups: ReadonlyArray<{
    readonly __typename: "SummaryReportRoomColumnGroup";
    readonly index: number;
    readonly name: string;
    readonly colspan: number;
    readonly reportRoomId: string;
    readonly elementColumns: ReadonlyArray<{
      readonly __typename: "SummaryReportElementColumn";
      readonly index: number;
      readonly name: string;
      readonly reportRoomElementId: string;
    }>;
  }>;
};

export type SummaryReportCellMetadataFragment = {
  readonly __typename: "SummaryReportCellMetadata";
  readonly activityTitle: string;
  readonly reportRoomName: string;
  readonly reportRoomElementName: string;
  readonly providerName: string;
  readonly productModel: string;
  readonly productCode: string;
  readonly productFormat: string;
  readonly comments: string;
};

export type SummaryReportCellFragment = {
  readonly __typename: "SummaryReportCell";
  readonly index: number;
  readonly type: ReportCellType;
  readonly value: string;
  readonly metadata: {
    readonly __typename: "SummaryReportCellMetadata";
    readonly activityTitle: string;
    readonly reportRoomName: string;
    readonly reportRoomElementName: string;
    readonly providerName: string;
    readonly productModel: string;
    readonly productCode: string;
    readonly productFormat: string;
    readonly comments: string;
  } | null;
};

export type SummaryReportRowFragment = {
  readonly __typename: "SummaryReportRow";
  readonly unitNumber: string;
  readonly unitFloor: number;
  readonly unitNumberOfBathrooms: number;
  readonly unitNumberOfBedrooms: number;
  readonly unitState: UnitState;
  readonly numberOfExtras: number;
  readonly customerInfos: ReadonlyArray<{
    readonly __typename: "ReportCustomerInfo";
    readonly customerId: string;
    readonly index: number;
    readonly fullName: string;
    readonly phone: string;
    readonly email: string;
  }>;
  readonly contractSelectionInfos: ReadonlyArray<{
    readonly __typename: "ReportContractSelectionInfo";
    readonly contractSelectionId: string;
    readonly revisionNumber: number;
    readonly name: string;
    readonly date: Date;
  }>;
  readonly cells: ReadonlyArray<{
    readonly __typename: "SummaryReportCell";
    readonly index: number;
    readonly type: ReportCellType;
    readonly value: string;
    readonly metadata: {
      readonly __typename: "SummaryReportCellMetadata";
      readonly activityTitle: string;
      readonly reportRoomName: string;
      readonly reportRoomElementName: string;
      readonly providerName: string;
      readonly productModel: string;
      readonly productCode: string;
      readonly productFormat: string;
      readonly comments: string;
    } | null;
  }>;
};

export type SummaryReportFragment = {
  readonly __typename: "SummaryReport";
  readonly vibeColumns: ReadonlyArray<{
    readonly __typename: "SummaryReportVibeColumn";
    readonly index: number;
    readonly name: string;
    readonly reportRoomId: string;
  }>;
  readonly baseActivityColumnGroups: ReadonlyArray<{
    readonly __typename: "SummaryReportActivityColumnGroup";
    readonly index: number;
    readonly name: string;
    readonly colspan: number;
    readonly activityId: string;
    readonly roomColumnGroups: ReadonlyArray<{
      readonly __typename: "SummaryReportRoomColumnGroup";
      readonly index: number;
      readonly name: string;
      readonly colspan: number;
      readonly reportRoomId: string;
      readonly elementColumns: ReadonlyArray<{
        readonly __typename: "SummaryReportElementColumn";
        readonly index: number;
        readonly name: string;
        readonly reportRoomElementId: string;
      }>;
    }>;
  }>;
  readonly extraActivityColumnGroups: ReadonlyArray<{
    readonly __typename: "SummaryReportActivityColumnGroup";
    readonly index: number;
    readonly name: string;
    readonly colspan: number;
    readonly activityId: string;
    readonly roomColumnGroups: ReadonlyArray<{
      readonly __typename: "SummaryReportRoomColumnGroup";
      readonly index: number;
      readonly name: string;
      readonly colspan: number;
      readonly reportRoomId: string;
      readonly elementColumns: ReadonlyArray<{
        readonly __typename: "SummaryReportElementColumn";
        readonly index: number;
        readonly name: string;
        readonly reportRoomElementId: string;
      }>;
    }>;
  }>;
  readonly rows: ReadonlyArray<{
    readonly __typename: "SummaryReportRow";
    readonly unitNumber: string;
    readonly unitFloor: number;
    readonly unitNumberOfBathrooms: number;
    readonly unitNumberOfBedrooms: number;
    readonly unitState: UnitState;
    readonly numberOfExtras: number;
    readonly customerInfos: ReadonlyArray<{
      readonly __typename: "ReportCustomerInfo";
      readonly customerId: string;
      readonly index: number;
      readonly fullName: string;
      readonly phone: string;
      readonly email: string;
    }>;
    readonly contractSelectionInfos: ReadonlyArray<{
      readonly __typename: "ReportContractSelectionInfo";
      readonly contractSelectionId: string;
      readonly revisionNumber: number;
      readonly name: string;
      readonly date: Date;
    }>;
    readonly cells: ReadonlyArray<{
      readonly __typename: "SummaryReportCell";
      readonly index: number;
      readonly type: ReportCellType;
      readonly value: string;
      readonly metadata: {
        readonly __typename: "SummaryReportCellMetadata";
        readonly activityTitle: string;
        readonly reportRoomName: string;
        readonly reportRoomElementName: string;
        readonly providerName: string;
        readonly productModel: string;
        readonly productCode: string;
        readonly productFormat: string;
        readonly comments: string;
      } | null;
    }>;
  }>;
};

export type BillingReportTableRowFragment = {
  readonly __typename: "BillingReportTableRow";
  readonly type: BillingReportTableRowType;
  readonly elementFullName: string;
  readonly providerName: string | null;
  readonly clientAmount: number;
  readonly subcontractorAmount: number;
  readonly contractorAmount: number;
  readonly promoterAmount: number;
  readonly contingencyAmount: number;
};

export type BillingReportTableFragment = {
  readonly __typename: "BillingReportTable";
  readonly contractId: string;
  readonly revisionNumber: number;
  readonly isRevisionPending: boolean;
  readonly unitNumber: string;
  readonly clientAmount: number;
  readonly subcontractorAmount: number;
  readonly contractorAmount: number;
  readonly promoterAmount: number;
  readonly contingencyAmount: number;
  readonly rows: ReadonlyArray<{
    readonly __typename: "BillingReportTableRow";
    readonly type: BillingReportTableRowType;
    readonly elementFullName: string;
    readonly providerName: string | null;
    readonly clientAmount: number;
    readonly subcontractorAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
    readonly contingencyAmount: number;
  }>;
};

export type BillingReportFragment = {
  readonly __typename: "BillingReport";
  readonly subcontractorAmount: number;
  readonly clientAmount: number;
  readonly contractorAmount: number;
  readonly promoterAmount: number;
  readonly contingencyAmount: number;
  readonly tables: ReadonlyArray<{
    readonly __typename: "BillingReportTable";
    readonly contractId: string;
    readonly revisionNumber: number;
    readonly isRevisionPending: boolean;
    readonly unitNumber: string;
    readonly clientAmount: number;
    readonly subcontractorAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
    readonly contingencyAmount: number;
    readonly rows: ReadonlyArray<{
      readonly __typename: "BillingReportTableRow";
      readonly type: BillingReportTableRowType;
      readonly elementFullName: string;
      readonly providerName: string | null;
      readonly clientAmount: number;
      readonly subcontractorAmount: number;
      readonly contractorAmount: number;
      readonly promoterAmount: number;
      readonly contingencyAmount: number;
    }>;
  }>;
};

export type ConstructionReportTableRowFragment = {
  readonly __typename: "ConstructionReportTableRow";
  readonly index: number;
  readonly roomName: string;
  readonly elementName: string;
  readonly type: ReportCellType;
  readonly value: string;
  readonly providerName: string;
  readonly productModel: string;
  readonly productCode: string;
  readonly productFormat: string;
  readonly comments: string;
};

export type ConstructionReportTableFragment = {
  readonly __typename: "ConstructionReportTable";
  readonly index: number;
  readonly activityTitle: string;
  readonly rows: ReadonlyArray<{
    readonly __typename: "ConstructionReportTableRow";
    readonly index: number;
    readonly roomName: string;
    readonly elementName: string;
    readonly type: ReportCellType;
    readonly value: string;
    readonly providerName: string;
    readonly productModel: string;
    readonly productCode: string;
    readonly productFormat: string;
    readonly comments: string;
  }>;
};

export type ConstructionReportFragment = {
  readonly __typename: "ConstructionReport";
  readonly unitNumber: string;
  readonly unitFloor: number;
  readonly unitNumberOfBathrooms: number;
  readonly unitNumberOfBedrooms: number;
  readonly unitState: UnitState;
  readonly numberOfExtras: number;
  readonly customerInfos: ReadonlyArray<{
    readonly __typename: "ReportCustomerInfo";
    readonly customerId: string;
    readonly index: number;
    readonly fullName: string;
    readonly phone: string;
    readonly email: string;
  }>;
  readonly contractSelectionInfos: ReadonlyArray<{
    readonly __typename: "ReportContractSelectionInfo";
    readonly contractSelectionId: string;
    readonly revisionNumber: number;
    readonly name: string;
    readonly date: Date;
  }>;
  readonly vibeTableRows: ReadonlyArray<{
    readonly __typename: "ReportVibeTableRow";
    readonly index: number;
    readonly name: string;
    readonly value: string;
    readonly type: ReportCellType;
  }>;
  readonly baseTables: ReadonlyArray<{
    readonly __typename: "ConstructionReportTable";
    readonly index: number;
    readonly activityTitle: string;
    readonly rows: ReadonlyArray<{
      readonly __typename: "ConstructionReportTableRow";
      readonly index: number;
      readonly roomName: string;
      readonly elementName: string;
      readonly type: ReportCellType;
      readonly value: string;
      readonly providerName: string;
      readonly productModel: string;
      readonly productCode: string;
      readonly productFormat: string;
      readonly comments: string;
    }>;
  }>;
  readonly extraTables: ReadonlyArray<{
    readonly __typename: "ConstructionReportTable";
    readonly index: number;
    readonly activityTitle: string;
    readonly rows: ReadonlyArray<{
      readonly __typename: "ConstructionReportTableRow";
      readonly index: number;
      readonly roomName: string;
      readonly elementName: string;
      readonly type: ReportCellType;
      readonly value: string;
      readonly providerName: string;
      readonly productModel: string;
      readonly productCode: string;
      readonly productFormat: string;
      readonly comments: string;
    }>;
  }>;
};

export type CustomerReportTableRowFragment = {
  readonly __typename: "CustomerReportTableRow";
  readonly index: number;
  readonly roomName: string;
  readonly elementName: string;
  readonly type: ReportCellType;
  readonly value: string;
  readonly comments: string;
};

export type CustomerReportTableFragment = {
  readonly __typename: "CustomerReportTable";
  readonly index: number;
  readonly activityTitle: string;
  readonly rows: ReadonlyArray<{
    readonly __typename: "CustomerReportTableRow";
    readonly index: number;
    readonly roomName: string;
    readonly elementName: string;
    readonly type: ReportCellType;
    readonly value: string;
    readonly comments: string;
  }>;
};

export type CustomerReportFragment = {
  readonly __typename: "CustomerReport";
  readonly unitNumber: string;
  readonly unitFloor: number;
  readonly unitNumberOfBathrooms: number;
  readonly unitNumberOfBedrooms: number;
  readonly unitState: UnitState;
  readonly numberOfExtras: number;
  readonly customerInfos: ReadonlyArray<{
    readonly __typename: "ReportCustomerInfo";
    readonly customerId: string;
    readonly index: number;
    readonly fullName: string;
    readonly phone: string;
    readonly email: string;
  }>;
  readonly contractSelectionInfos: ReadonlyArray<{
    readonly __typename: "ReportContractSelectionInfo";
    readonly contractSelectionId: string;
    readonly revisionNumber: number;
    readonly name: string;
    readonly date: Date;
  }>;
  readonly vibeTableRows: ReadonlyArray<{
    readonly __typename: "ReportVibeTableRow";
    readonly index: number;
    readonly name: string;
    readonly value: string;
    readonly type: ReportCellType;
  }>;
  readonly baseTables: ReadonlyArray<{
    readonly __typename: "CustomerReportTable";
    readonly index: number;
    readonly activityTitle: string;
    readonly rows: ReadonlyArray<{
      readonly __typename: "CustomerReportTableRow";
      readonly index: number;
      readonly roomName: string;
      readonly elementName: string;
      readonly type: ReportCellType;
      readonly value: string;
      readonly comments: string;
    }>;
  }>;
  readonly extraTables: ReadonlyArray<{
    readonly __typename: "CustomerReportTable";
    readonly index: number;
    readonly activityTitle: string;
    readonly rows: ReadonlyArray<{
      readonly __typename: "CustomerReportTableRow";
      readonly index: number;
      readonly roomName: string;
      readonly elementName: string;
      readonly type: ReportCellType;
      readonly value: string;
      readonly comments: string;
    }>;
  }>;
};

export type CreateActivityMutationVariables = Exact<{
  input: CreateActivityInput;
}>;

export type CreateActivityMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "CreateActivityPayload";
    readonly data: {
      readonly __typename: "CreateActivityPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly activity: {
        readonly __typename: "Activity";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly number: string;
        readonly title: string;
        readonly label: string;
        readonly description: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateActivityMutationVariables = Exact<{
  input: UpdateActivityInput;
}>;

export type UpdateActivityMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateActivityPayload";
    readonly data: {
      readonly __typename: "UpdateActivityPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly activity: {
        readonly __typename: "Activity";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly number: string;
        readonly title: string;
        readonly label: string;
        readonly description: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DeleteActivityMutationVariables = Exact<{
  input: DeleteActivityInput;
}>;

export type DeleteActivityMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DeleteActivityPayload";
    readonly data: {
      readonly __typename: "DeleteActivityPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly activityId: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type CreateContractMutationVariables = Exact<{
  input: CreateContractInput;
}>;

export type CreateContractMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "CreateContractPayload";
    readonly data: {
      readonly __typename: "CreateContractPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
      readonly unit: {
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type CancelContractMutationVariables = Exact<{
  input: CancelContractInput;
}>;

export type CancelContractMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "CancelContractPayload";
    readonly data: {
      readonly __typename: "CancelContractPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
      readonly unit: {
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      };
      readonly invoices: ReadonlyArray<{
        readonly __typename: "Invoice";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly number: number;
        readonly date: Date;
        readonly terms: InvoiceTermsOfPayment;
        readonly due: Date;
        readonly label: string;
        readonly status: InvoiceStatus;
        readonly feePercent: number;
        readonly feeAmount: number;
        readonly subtotalAmount: number;
        readonly taxPercent: number;
        readonly taxAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly creditBeforeTaxAmount: number;
        readonly creditAfterTaxAmount: number;
        readonly contractId: string | null;
        readonly contractSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "InvoiceCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly customerInfo: {
          readonly __typename: "InvoiceCustomerInfo";
          readonly name: string;
          readonly address: {
            readonly __typename: "Address";
            readonly line: string;
            readonly city: string;
            readonly subdivisionCode: string;
            readonly countryCode: string;
            readonly postalCode: string;
          };
        };
        readonly items: ReadonlyArray<{
          readonly __typename: "InvoiceItem";
          readonly id: string;
          readonly index: number;
          readonly type: InvoiceItemType;
          readonly name: string;
          readonly description: string | null;
          readonly quantity: number;
          readonly unitPrice: number;
          readonly amount: number;
        }>;
      }>;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DeleteContractMutationVariables = Exact<{
  input: DeleteContractInput;
}>;

export type DeleteContractMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DeleteContractPayload";
    readonly data: {
      readonly __typename: "DeleteContractPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly contractId: string;
      readonly unit: {
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DownloadContractMutationVariables = Exact<{
  input: DownloadContractInput;
}>;

export type DownloadContractMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DownloadContractPayload";
    readonly data: {
      readonly __typename: "DownloadContractPayloadData";
      readonly documentUrl: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type ConfirmContractSelectionMutationVariables = Exact<{
  input: ConfirmContractSelectionInput;
}>;

export type ConfirmContractSelectionMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "ConfirmContractSelectionPayload";
    readonly data: {
      readonly __typename: "ConfirmContractSelectionPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
      readonly unit: {
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      };
      readonly invoices: ReadonlyArray<{
        readonly __typename: "Invoice";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly number: number;
        readonly date: Date;
        readonly terms: InvoiceTermsOfPayment;
        readonly due: Date;
        readonly label: string;
        readonly status: InvoiceStatus;
        readonly feePercent: number;
        readonly feeAmount: number;
        readonly subtotalAmount: number;
        readonly taxPercent: number;
        readonly taxAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly creditBeforeTaxAmount: number;
        readonly creditAfterTaxAmount: number;
        readonly contractId: string | null;
        readonly contractSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "InvoiceCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly customerInfo: {
          readonly __typename: "InvoiceCustomerInfo";
          readonly name: string;
          readonly address: {
            readonly __typename: "Address";
            readonly line: string;
            readonly city: string;
            readonly subdivisionCode: string;
            readonly countryCode: string;
            readonly postalCode: string;
          };
        };
        readonly items: ReadonlyArray<{
          readonly __typename: "InvoiceItem";
          readonly id: string;
          readonly index: number;
          readonly type: InvoiceItemType;
          readonly name: string;
          readonly description: string | null;
          readonly quantity: number;
          readonly unitPrice: number;
          readonly amount: number;
        }>;
      }>;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type CreateContractSelectionMutationVariables = Exact<{
  input: CreateContractSelectionInput;
}>;

export type CreateContractSelectionMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "CreateContractSelectionPayload";
    readonly data: {
      readonly __typename: "CreateContractSelectionPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
      readonly unit: {
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateContractSelectionMutationVariables = Exact<{
  input: UpdateContractSelectionInput;
}>;

export type UpdateContractSelectionMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateContractSelectionPayload";
    readonly data: {
      readonly __typename: "UpdateContractSelectionPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
      readonly unit: {
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DeleteContractSelectionMutationVariables = Exact<{
  input: DeleteContractSelectionInput;
}>;

export type DeleteContractSelectionMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DeleteContractSelectionPayload";
    readonly data: {
      readonly __typename: "DeleteContractSelectionPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
      readonly unit: {
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type CreateContractRevisionMutationVariables = Exact<{
  input: CreateContractRevisionInput;
}>;

export type CreateContractRevisionMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "CreateContractRevisionPayload";
    readonly data: {
      readonly __typename: "CreateContractRevisionPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
      readonly unit: {
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateContractRevisionMutationVariables = Exact<{
  input: UpdateContractRevisionInput;
}>;

export type UpdateContractRevisionMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateContractRevisionPayload";
    readonly data: {
      readonly __typename: "UpdateContractRevisionPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
      readonly unit: {
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DeleteContractRevisionMutationVariables = Exact<{
  input: DeleteContractRevisionInput;
}>;

export type DeleteContractRevisionMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DeleteContractRevisionPayload";
    readonly data: {
      readonly __typename: "DeleteContractRevisionPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
      readonly unit: {
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateContractSelectionGroupMutationVariables = Exact<{
  input: UpdateContractSelectionGroupInput;
}>;

export type UpdateContractSelectionGroupMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateContractSelectionGroupPayload";
    readonly data: {
      readonly __typename: "UpdateContractSelectionGroupPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type CreateCustomerMutationVariables = Exact<{
  input: CreateCustomerInput;
}>;

export type CreateCustomerMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "CreateCustomerPayload";
    readonly data: {
      readonly __typename: "CreateCustomerPayloadData";
      readonly tenantId: string;
      readonly customer: {
        readonly __typename: "Customer";
        readonly id: string;
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly fullName: string | null;
        readonly companyName: string | null;
        readonly displayName: string;
        readonly type: CustomerType;
        readonly label: string;
        readonly email: string;
        readonly phone: string;
        readonly gender: Gender;
        readonly language: Language;
        readonly notes: string | null;
        readonly isDefault: boolean;
        readonly address: {
          readonly __typename: "Address";
          readonly line: string;
          readonly city: string;
          readonly subdivisionCode: string;
          readonly countryCode: string;
          readonly postalCode: string;
        };
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateCustomerMutationVariables = Exact<{
  input: UpdateCustomerInput;
}>;

export type UpdateCustomerMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateCustomerPayload";
    readonly data: {
      readonly __typename: "UpdateCustomerPayloadData";
      readonly tenantId: string;
      readonly customer: {
        readonly __typename: "Customer";
        readonly id: string;
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly fullName: string | null;
        readonly companyName: string | null;
        readonly displayName: string;
        readonly type: CustomerType;
        readonly label: string;
        readonly email: string;
        readonly phone: string;
        readonly gender: Gender;
        readonly language: Language;
        readonly notes: string | null;
        readonly isDefault: boolean;
        readonly address: {
          readonly __typename: "Address";
          readonly line: string;
          readonly city: string;
          readonly subdivisionCode: string;
          readonly countryCode: string;
          readonly postalCode: string;
        };
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DeleteCustomerMutationVariables = Exact<{
  input: DeleteCustomerInput;
}>;

export type DeleteCustomerMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DeleteCustomerPayload";
    readonly data: {
      readonly __typename: "DeleteCustomerPayloadData";
      readonly tenantId: string;
      readonly customerId: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "CreateUserPayload";
    readonly data: {
      readonly __typename: "CreateUserPayloadData";
      readonly tenantId: string;
      readonly user: {
        readonly __typename: "User";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly email: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly fullName: string;
        readonly isTest: boolean;
        readonly isBlocked: boolean;
        readonly roleId: string;
        readonly hasAccessToAllProjects: boolean;
        readonly hasAccessToTestTenant: boolean;
        readonly projectIds: ReadonlyArray<string>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateUserPayload";
    readonly data: {
      readonly __typename: "UpdateUserPayloadData";
      readonly tenantId: string;
      readonly user: {
        readonly __typename: "User";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly email: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly fullName: string;
        readonly isTest: boolean;
        readonly isBlocked: boolean;
        readonly roleId: string;
        readonly hasAccessToAllProjects: boolean;
        readonly hasAccessToTestTenant: boolean;
        readonly projectIds: ReadonlyArray<string>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type BlockUserMutationVariables = Exact<{
  input: BlockUserInput;
}>;

export type BlockUserMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "BlockUserPayload";
    readonly data: {
      readonly __typename: "BlockUserPayloadData";
      readonly tenantId: string;
      readonly user: {
        readonly __typename: "User";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly email: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly fullName: string;
        readonly isTest: boolean;
        readonly isBlocked: boolean;
        readonly roleId: string;
        readonly hasAccessToAllProjects: boolean;
        readonly hasAccessToTestTenant: boolean;
        readonly projectIds: ReadonlyArray<string>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UnblockUserMutationVariables = Exact<{
  input: UnblockUserInput;
}>;

export type UnblockUserMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UnblockUserPayload";
    readonly data: {
      readonly __typename: "UnblockUserPayloadData";
      readonly tenantId: string;
      readonly user: {
        readonly __typename: "User";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly email: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly fullName: string;
        readonly isTest: boolean;
        readonly isBlocked: boolean;
        readonly roleId: string;
        readonly hasAccessToAllProjects: boolean;
        readonly hasAccessToTestTenant: boolean;
        readonly projectIds: ReadonlyArray<string>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type ResendUserInvitationMutationVariables = Exact<{
  input: ResendUserInvitationInput;
}>;

export type ResendUserInvitationMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "ResendUserInvitationPayload";
    readonly data: {
      readonly __typename: "ResendUserInvitationPayloadData";
      readonly tenantId: string;
      readonly user: {
        readonly __typename: "User";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly email: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly fullName: string;
        readonly isTest: boolean;
        readonly isBlocked: boolean;
        readonly roleId: string;
        readonly hasAccessToAllProjects: boolean;
        readonly hasAccessToTestTenant: boolean;
        readonly projectIds: ReadonlyArray<string>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type CreateFormMutationVariables = Exact<{
  input: CreateFormInput;
}>;

export type CreateFormMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "CreateFormPayload";
    readonly data: {
      readonly __typename: "CreateFormPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly form: {
        readonly __typename: "Form";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly index: number;
        readonly name: string;
        readonly state: FormState;
        readonly modelId: string | null;
        readonly sections: ReadonlyArray<{
          readonly __typename: "FormSection";
          readonly id: string;
          readonly index: number;
          readonly title: string;
          readonly sectionId: string;
        }>;
        readonly reportRoomElementLinks: ReadonlyArray<{
          readonly __typename: "FormReportRoomElementLink";
          readonly formSectionId: string;
          readonly sectionElementId: string;
          readonly reportId: string;
          readonly reportRoomId: string;
          readonly reportRoomElementId: string;
        }>;
        readonly reportVibeRoomLinks: ReadonlyArray<{
          readonly __typename: "FormReportVibeRoomLink";
          readonly formSectionId: string;
          readonly reportId: string;
          readonly reportRoomId: string;
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateFormMutationVariables = Exact<{
  input: UpdateFormInput;
}>;

export type UpdateFormMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateFormPayload";
    readonly data: {
      readonly __typename: "UpdateFormPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly form: {
        readonly __typename: "Form";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly index: number;
        readonly name: string;
        readonly state: FormState;
        readonly modelId: string | null;
        readonly sections: ReadonlyArray<{
          readonly __typename: "FormSection";
          readonly id: string;
          readonly index: number;
          readonly title: string;
          readonly sectionId: string;
        }>;
        readonly reportRoomElementLinks: ReadonlyArray<{
          readonly __typename: "FormReportRoomElementLink";
          readonly formSectionId: string;
          readonly sectionElementId: string;
          readonly reportId: string;
          readonly reportRoomId: string;
          readonly reportRoomElementId: string;
        }>;
        readonly reportVibeRoomLinks: ReadonlyArray<{
          readonly __typename: "FormReportVibeRoomLink";
          readonly formSectionId: string;
          readonly reportId: string;
          readonly reportRoomId: string;
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DeleteFormMutationVariables = Exact<{
  input: DeleteFormInput;
}>;

export type DeleteFormMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DeleteFormPayload";
    readonly data: {
      readonly __typename: "DeleteFormPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly formId: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type LinkFormMutationVariables = Exact<{
  input: LinkFormInput;
}>;

export type LinkFormMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "LinkFormPayload";
    readonly data: {
      readonly __typename: "LinkFormPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly form: {
        readonly __typename: "Form";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly index: number;
        readonly name: string;
        readonly state: FormState;
        readonly modelId: string | null;
        readonly sections: ReadonlyArray<{
          readonly __typename: "FormSection";
          readonly id: string;
          readonly index: number;
          readonly title: string;
          readonly sectionId: string;
        }>;
        readonly reportRoomElementLinks: ReadonlyArray<{
          readonly __typename: "FormReportRoomElementLink";
          readonly formSectionId: string;
          readonly sectionElementId: string;
          readonly reportId: string;
          readonly reportRoomId: string;
          readonly reportRoomElementId: string;
        }>;
        readonly reportVibeRoomLinks: ReadonlyArray<{
          readonly __typename: "FormReportVibeRoomLink";
          readonly formSectionId: string;
          readonly reportId: string;
          readonly reportRoomId: string;
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type PublishFormMutationVariables = Exact<{
  input: PublishFormInput;
}>;

export type PublishFormMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "PublishFormPayload";
    readonly data: {
      readonly __typename: "PublishFormPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly form: {
        readonly __typename: "Form";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly index: number;
        readonly name: string;
        readonly state: FormState;
        readonly modelId: string | null;
        readonly sections: ReadonlyArray<{
          readonly __typename: "FormSection";
          readonly id: string;
          readonly index: number;
          readonly title: string;
          readonly sectionId: string;
        }>;
        readonly reportRoomElementLinks: ReadonlyArray<{
          readonly __typename: "FormReportRoomElementLink";
          readonly formSectionId: string;
          readonly sectionElementId: string;
          readonly reportId: string;
          readonly reportRoomId: string;
          readonly reportRoomElementId: string;
        }>;
        readonly reportVibeRoomLinks: ReadonlyArray<{
          readonly __typename: "FormReportVibeRoomLink";
          readonly formSectionId: string;
          readonly reportId: string;
          readonly reportRoomId: string;
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type ArchiveFormMutationVariables = Exact<{
  input: ArchiveFormInput;
}>;

export type ArchiveFormMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "ArchiveFormPayload";
    readonly data: {
      readonly __typename: "ArchiveFormPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly form: {
        readonly __typename: "Form";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly index: number;
        readonly name: string;
        readonly state: FormState;
        readonly modelId: string | null;
        readonly sections: ReadonlyArray<{
          readonly __typename: "FormSection";
          readonly id: string;
          readonly index: number;
          readonly title: string;
          readonly sectionId: string;
        }>;
        readonly reportRoomElementLinks: ReadonlyArray<{
          readonly __typename: "FormReportRoomElementLink";
          readonly formSectionId: string;
          readonly sectionElementId: string;
          readonly reportId: string;
          readonly reportRoomId: string;
          readonly reportRoomElementId: string;
        }>;
        readonly reportVibeRoomLinks: ReadonlyArray<{
          readonly __typename: "FormReportVibeRoomLink";
          readonly formSectionId: string;
          readonly reportId: string;
          readonly reportRoomId: string;
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type ImportModelMutationVariables = Exact<{
  input: ImportModelInput;
}>;

export type ImportModelMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "ImportModelPayload";
    readonly data: {
      readonly __typename: "ImportModelPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly model: {
        readonly __typename: "Model";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly sharedId: string;
        readonly name: string;
        readonly rooms: ReadonlyArray<{
          readonly __typename: "ModelRoom";
          readonly id: string;
          readonly sharedId: string;
          readonly index: number;
          readonly name: string;
          readonly elements: ReadonlyArray<{
            readonly __typename: "ModelRoomElement";
            readonly id: string;
            readonly sharedId: string;
            readonly name: string;
            readonly index: number;
            readonly type: ModelRoomElementType;
            readonly options: ReadonlyArray<{
              readonly __typename: "ModelRoomElementOption";
              readonly id: string;
              readonly sharedId: string;
              readonly index: number;
              readonly productId: string;
            }>;
          }>;
        }>;
      };
      readonly productCategories: ReadonlyArray<{
        readonly __typename: "ProductCategory";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly name: string;
        readonly displayName: string | null;
        readonly description: string | null;
        readonly managedByModels: boolean;
      }>;
      readonly products: ReadonlyArray<{
        readonly __typename: "Product";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly name: string;
        readonly variant: string;
        readonly format: string | null;
        readonly description: string | null;
        readonly thumbnailUrl: string;
        readonly hasThumbnail: boolean;
        readonly managedByModels: boolean;
        readonly usedBySections: boolean;
        readonly categoryId: string;
        readonly provider: {
          readonly __typename: "ProductProvider";
          readonly id: string | null;
          readonly productModel: string | null;
          readonly productCode: string | null;
          readonly productUrl: string | null;
        };
        readonly activities: ReadonlyArray<{
          readonly __typename: "ProductActivity";
          readonly activityId: string;
          readonly index: number;
        }>;
      }>;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DeleteModelMutationVariables = Exact<{
  input: DeleteModelInput;
}>;

export type DeleteModelMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DeleteModelPayload";
    readonly data: {
      readonly __typename: "DeleteModelPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly modelId: string;
      readonly productIds: ReadonlyArray<string>;
      readonly productCategoryIds: ReadonlyArray<string>;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type LoadModelMutationVariables = Exact<{
  input: LoadModelInput;
}>;

export type LoadModelMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "LoadModelPayload";
    readonly data: {
      readonly __typename: "LoadModelPayloadData";
      readonly unityLoaderUrl: string;
      readonly unityDataUrl: string;
      readonly unityFrameworkUrl: string;
      readonly unityCodeUrl: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type CreateContractInvoicePaymentMutationVariables = Exact<{
  input: CreateContractInvoicePaymentInput;
}>;

export type CreateContractInvoicePaymentMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "CreateContractInvoicePaymentPayload";
    readonly data: {
      readonly __typename: "CreateContractInvoicePaymentPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly payment: {
        readonly __typename: "Payment";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly date: Date;
        readonly number: number;
        readonly label: string;
        readonly referenceNumber: string | null;
        readonly isLocked: boolean;
        readonly mode: PaymentMode;
        readonly amount: number;
        readonly notes: string | null;
        readonly invoiceId: string | null;
        readonly contractId: string | null;
      };
      readonly invoice: {
        readonly __typename: "Invoice";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly number: number;
        readonly date: Date;
        readonly terms: InvoiceTermsOfPayment;
        readonly due: Date;
        readonly label: string;
        readonly status: InvoiceStatus;
        readonly feePercent: number;
        readonly feeAmount: number;
        readonly subtotalAmount: number;
        readonly taxPercent: number;
        readonly taxAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly creditBeforeTaxAmount: number;
        readonly creditAfterTaxAmount: number;
        readonly contractId: string | null;
        readonly contractSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "InvoiceCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly customerInfo: {
          readonly __typename: "InvoiceCustomerInfo";
          readonly name: string;
          readonly address: {
            readonly __typename: "Address";
            readonly line: string;
            readonly city: string;
            readonly subdivisionCode: string;
            readonly countryCode: string;
            readonly postalCode: string;
          };
        };
        readonly items: ReadonlyArray<{
          readonly __typename: "InvoiceItem";
          readonly id: string;
          readonly index: number;
          readonly type: InvoiceItemType;
          readonly name: string;
          readonly description: string | null;
          readonly quantity: number;
          readonly unitPrice: number;
          readonly amount: number;
        }>;
      };
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
      readonly unit: {
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateContractInvoicePaymentMutationVariables = Exact<{
  input: UpdateContractInvoicePaymentInput;
}>;

export type UpdateContractInvoicePaymentMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateContractInvoicePaymentPayload";
    readonly data: {
      readonly __typename: "UpdateContractInvoicePaymentPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly payment: {
        readonly __typename: "Payment";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly date: Date;
        readonly number: number;
        readonly label: string;
        readonly referenceNumber: string | null;
        readonly isLocked: boolean;
        readonly mode: PaymentMode;
        readonly amount: number;
        readonly notes: string | null;
        readonly invoiceId: string | null;
        readonly contractId: string | null;
      };
      readonly invoice: {
        readonly __typename: "Invoice";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly number: number;
        readonly date: Date;
        readonly terms: InvoiceTermsOfPayment;
        readonly due: Date;
        readonly label: string;
        readonly status: InvoiceStatus;
        readonly feePercent: number;
        readonly feeAmount: number;
        readonly subtotalAmount: number;
        readonly taxPercent: number;
        readonly taxAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly creditBeforeTaxAmount: number;
        readonly creditAfterTaxAmount: number;
        readonly contractId: string | null;
        readonly contractSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "InvoiceCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly customerInfo: {
          readonly __typename: "InvoiceCustomerInfo";
          readonly name: string;
          readonly address: {
            readonly __typename: "Address";
            readonly line: string;
            readonly city: string;
            readonly subdivisionCode: string;
            readonly countryCode: string;
            readonly postalCode: string;
          };
        };
        readonly items: ReadonlyArray<{
          readonly __typename: "InvoiceItem";
          readonly id: string;
          readonly index: number;
          readonly type: InvoiceItemType;
          readonly name: string;
          readonly description: string | null;
          readonly quantity: number;
          readonly unitPrice: number;
          readonly amount: number;
        }>;
      };
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
      readonly unit: {
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DeleteContractInvoicePaymentMutationVariables = Exact<{
  input: DeleteContractInvoicePaymentInput;
}>;

export type DeleteContractInvoicePaymentMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DeleteContractInvoicePaymentPayload";
    readonly data: {
      readonly __typename: "DeleteContractInvoicePaymentPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly paymentId: string;
      readonly invoice: {
        readonly __typename: "Invoice";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly number: number;
        readonly date: Date;
        readonly terms: InvoiceTermsOfPayment;
        readonly due: Date;
        readonly label: string;
        readonly status: InvoiceStatus;
        readonly feePercent: number;
        readonly feeAmount: number;
        readonly subtotalAmount: number;
        readonly taxPercent: number;
        readonly taxAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly creditBeforeTaxAmount: number;
        readonly creditAfterTaxAmount: number;
        readonly contractId: string | null;
        readonly contractSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "InvoiceCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly customerInfo: {
          readonly __typename: "InvoiceCustomerInfo";
          readonly name: string;
          readonly address: {
            readonly __typename: "Address";
            readonly line: string;
            readonly city: string;
            readonly subdivisionCode: string;
            readonly countryCode: string;
            readonly postalCode: string;
          };
        };
        readonly items: ReadonlyArray<{
          readonly __typename: "InvoiceItem";
          readonly id: string;
          readonly index: number;
          readonly type: InvoiceItemType;
          readonly name: string;
          readonly description: string | null;
          readonly quantity: number;
          readonly unitPrice: number;
          readonly amount: number;
        }>;
      };
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type CreateProductMutationVariables = Exact<{
  input: CreateProductInput;
}>;

export type CreateProductMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "CreateProductPayload";
    readonly data: {
      readonly __typename: "CreateProductPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly product: {
        readonly __typename: "Product";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly name: string;
        readonly variant: string;
        readonly format: string | null;
        readonly description: string | null;
        readonly thumbnailUrl: string;
        readonly hasThumbnail: boolean;
        readonly managedByModels: boolean;
        readonly usedBySections: boolean;
        readonly categoryId: string;
        readonly provider: {
          readonly __typename: "ProductProvider";
          readonly id: string | null;
          readonly productModel: string | null;
          readonly productCode: string | null;
          readonly productUrl: string | null;
        };
        readonly activities: ReadonlyArray<{
          readonly __typename: "ProductActivity";
          readonly activityId: string;
          readonly index: number;
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateProductMutationVariables = Exact<{
  input: UpdateProductInput;
}>;

export type UpdateProductMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateProductPayload";
    readonly data: {
      readonly __typename: "UpdateProductPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly product: {
        readonly __typename: "Product";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly name: string;
        readonly variant: string;
        readonly format: string | null;
        readonly description: string | null;
        readonly thumbnailUrl: string;
        readonly hasThumbnail: boolean;
        readonly managedByModels: boolean;
        readonly usedBySections: boolean;
        readonly categoryId: string;
        readonly provider: {
          readonly __typename: "ProductProvider";
          readonly id: string | null;
          readonly productModel: string | null;
          readonly productCode: string | null;
          readonly productUrl: string | null;
        };
        readonly activities: ReadonlyArray<{
          readonly __typename: "ProductActivity";
          readonly activityId: string;
          readonly index: number;
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateProductsMutationVariables = Exact<{
  input: UpdateProductsInput;
}>;

export type UpdateProductsMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateProductsPayload";
    readonly data: {
      readonly __typename: "UpdateProductsPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly products: ReadonlyArray<{
        readonly __typename: "Product";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly name: string;
        readonly variant: string;
        readonly format: string | null;
        readonly description: string | null;
        readonly thumbnailUrl: string;
        readonly hasThumbnail: boolean;
        readonly managedByModels: boolean;
        readonly usedBySections: boolean;
        readonly categoryId: string;
        readonly provider: {
          readonly __typename: "ProductProvider";
          readonly id: string | null;
          readonly productModel: string | null;
          readonly productCode: string | null;
          readonly productUrl: string | null;
        };
        readonly activities: ReadonlyArray<{
          readonly __typename: "ProductActivity";
          readonly activityId: string;
          readonly index: number;
        }>;
      }>;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DeleteProductMutationVariables = Exact<{
  input: DeleteProductInput;
}>;

export type DeleteProductMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DeleteProductPayload";
    readonly data: {
      readonly __typename: "DeleteProductPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly productId: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type CreateProductCategoryMutationVariables = Exact<{
  input: CreateProductCategoryInput;
}>;

export type CreateProductCategoryMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "CreateProductCategoryPayload";
    readonly data: {
      readonly __typename: "CreateProductCategoryPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly productCategory: {
        readonly __typename: "ProductCategory";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly name: string;
        readonly displayName: string | null;
        readonly description: string | null;
        readonly managedByModels: boolean;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateProductCategoryMutationVariables = Exact<{
  input: UpdateProductCategoryInput;
}>;

export type UpdateProductCategoryMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateProductCategoryPayload";
    readonly data: {
      readonly __typename: "UpdateProductCategoryPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly productCategory: {
        readonly __typename: "ProductCategory";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly name: string;
        readonly displayName: string | null;
        readonly description: string | null;
        readonly managedByModels: boolean;
      };
      readonly products: ReadonlyArray<{
        readonly __typename: "Product";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly name: string;
        readonly variant: string;
        readonly format: string | null;
        readonly description: string | null;
        readonly thumbnailUrl: string;
        readonly hasThumbnail: boolean;
        readonly managedByModels: boolean;
        readonly usedBySections: boolean;
        readonly categoryId: string;
        readonly provider: {
          readonly __typename: "ProductProvider";
          readonly id: string | null;
          readonly productModel: string | null;
          readonly productCode: string | null;
          readonly productUrl: string | null;
        };
        readonly activities: ReadonlyArray<{
          readonly __typename: "ProductActivity";
          readonly activityId: string;
          readonly index: number;
        }>;
      }>;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DeleteProductCategoryMutationVariables = Exact<{
  input: DeleteProductCategoryInput;
}>;

export type DeleteProductCategoryMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DeleteProductCategoryPayload";
    readonly data: {
      readonly __typename: "DeleteProductCategoryPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly productCategoryId: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInput;
}>;

export type CreateProjectMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "CreateProjectPayload";
    readonly data: {
      readonly __typename: "CreateProjectPayloadData";
      readonly tenantId: string;
      readonly project: {
        readonly __typename: "Project";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly code: string;
        readonly name: string;
        readonly label: string;
        readonly description: string | null;
        readonly tenantId: string;
        readonly contactInfo: {
          readonly __typename: "ProjectContactInfo";
          readonly companyName: string;
          readonly email: string;
          readonly phone: string;
          readonly websiteUrl: string;
          readonly federalTaxNumber: string | null;
          readonly provincialTaxNumber: string | null;
          readonly address: {
            readonly __typename: "Address";
            readonly line: string;
            readonly city: string;
            readonly subdivisionCode: string;
            readonly countryCode: string;
            readonly postalCode: string;
          };
        };
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateProjectInput;
}>;

export type UpdateProjectMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateProjectPayload";
    readonly data: {
      readonly __typename: "UpdateProjectPayloadData";
      readonly tenantId: string;
      readonly project: {
        readonly __typename: "Project";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly code: string;
        readonly name: string;
        readonly label: string;
        readonly description: string | null;
        readonly tenantId: string;
        readonly contactInfo: {
          readonly __typename: "ProjectContactInfo";
          readonly companyName: string;
          readonly email: string;
          readonly phone: string;
          readonly websiteUrl: string;
          readonly federalTaxNumber: string | null;
          readonly provincialTaxNumber: string | null;
          readonly address: {
            readonly __typename: "Address";
            readonly line: string;
            readonly city: string;
            readonly subdivisionCode: string;
            readonly countryCode: string;
            readonly postalCode: string;
          };
        };
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DeleteProjectMutationVariables = Exact<{
  input: DeleteProjectInput;
}>;

export type DeleteProjectMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DeleteProjectPayload";
    readonly data: {
      readonly __typename: "DeleteProjectPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type CreateProviderMutationVariables = Exact<{
  input: CreateProviderInput;
}>;

export type CreateProviderMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "CreateProviderPayload";
    readonly data: {
      readonly __typename: "CreateProviderPayloadData";
      readonly tenantId: string;
      readonly provider: {
        readonly __typename: "Provider";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly name: string;
        readonly websiteUrl: string | null;
        readonly description: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateProviderMutationVariables = Exact<{
  input: UpdateProviderInput;
}>;

export type UpdateProviderMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateProviderPayload";
    readonly data: {
      readonly __typename: "UpdateProviderPayloadData";
      readonly tenantId: string;
      readonly provider: {
        readonly __typename: "Provider";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly name: string;
        readonly websiteUrl: string | null;
        readonly description: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DeleteProviderMutationVariables = Exact<{
  input: DeleteProviderInput;
}>;

export type DeleteProviderMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DeleteProviderPayload";
    readonly data: {
      readonly __typename: "DeleteProviderPayloadData";
      readonly tenantId: string;
      readonly providerId: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type CreateContractRefundMutationVariables = Exact<{
  input: CreateContractRefundInput;
}>;

export type CreateContractRefundMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "CreateContractRefundPayload";
    readonly data: {
      readonly __typename: "CreateContractRefundPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly refund: {
        readonly __typename: "Refund";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly date: Date;
        readonly number: number;
        readonly label: string;
        readonly referenceNumber: string | null;
        readonly isLocked: boolean;
        readonly mode: RefundMode;
        readonly amount: number;
        readonly notes: string | null;
        readonly invoiceId: string | null;
        readonly contractId: string | null;
      };
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
      readonly unit: {
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateContractRefundMutationVariables = Exact<{
  input: UpdateContractRefundInput;
}>;

export type UpdateContractRefundMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateContractRefundPayload";
    readonly data: {
      readonly __typename: "UpdateContractRefundPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly refund: {
        readonly __typename: "Refund";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly date: Date;
        readonly number: number;
        readonly label: string;
        readonly referenceNumber: string | null;
        readonly isLocked: boolean;
        readonly mode: RefundMode;
        readonly amount: number;
        readonly notes: string | null;
        readonly invoiceId: string | null;
        readonly contractId: string | null;
      };
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
      readonly unit: {
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DeleteContractRefundMutationVariables = Exact<{
  input: DeleteContractRefundInput;
}>;

export type DeleteContractRefundMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DeleteContractRefundPayload";
    readonly data: {
      readonly __typename: "DeleteContractRefundPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly refundId: string;
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type CreateContractInvoiceRefundMutationVariables = Exact<{
  input: CreateContractInvoiceRefundInput;
}>;

export type CreateContractInvoiceRefundMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "CreateContractInvoiceRefundPayload";
    readonly data: {
      readonly __typename: "CreateContractInvoiceRefundPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly refund: {
        readonly __typename: "Refund";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly date: Date;
        readonly number: number;
        readonly label: string;
        readonly referenceNumber: string | null;
        readonly isLocked: boolean;
        readonly mode: RefundMode;
        readonly amount: number;
        readonly notes: string | null;
        readonly invoiceId: string | null;
        readonly contractId: string | null;
      };
      readonly invoice: {
        readonly __typename: "Invoice";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly number: number;
        readonly date: Date;
        readonly terms: InvoiceTermsOfPayment;
        readonly due: Date;
        readonly label: string;
        readonly status: InvoiceStatus;
        readonly feePercent: number;
        readonly feeAmount: number;
        readonly subtotalAmount: number;
        readonly taxPercent: number;
        readonly taxAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly creditBeforeTaxAmount: number;
        readonly creditAfterTaxAmount: number;
        readonly contractId: string | null;
        readonly contractSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "InvoiceCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly customerInfo: {
          readonly __typename: "InvoiceCustomerInfo";
          readonly name: string;
          readonly address: {
            readonly __typename: "Address";
            readonly line: string;
            readonly city: string;
            readonly subdivisionCode: string;
            readonly countryCode: string;
            readonly postalCode: string;
          };
        };
        readonly items: ReadonlyArray<{
          readonly __typename: "InvoiceItem";
          readonly id: string;
          readonly index: number;
          readonly type: InvoiceItemType;
          readonly name: string;
          readonly description: string | null;
          readonly quantity: number;
          readonly unitPrice: number;
          readonly amount: number;
        }>;
      };
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
      readonly unit: {
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateContractInvoiceRefundMutationVariables = Exact<{
  input: UpdateContractInvoiceRefundInput;
}>;

export type UpdateContractInvoiceRefundMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateContractInvoiceRefundPayload";
    readonly data: {
      readonly __typename: "UpdateContractInvoiceRefundPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly refund: {
        readonly __typename: "Refund";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly date: Date;
        readonly number: number;
        readonly label: string;
        readonly referenceNumber: string | null;
        readonly isLocked: boolean;
        readonly mode: RefundMode;
        readonly amount: number;
        readonly notes: string | null;
        readonly invoiceId: string | null;
        readonly contractId: string | null;
      };
      readonly invoice: {
        readonly __typename: "Invoice";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly number: number;
        readonly date: Date;
        readonly terms: InvoiceTermsOfPayment;
        readonly due: Date;
        readonly label: string;
        readonly status: InvoiceStatus;
        readonly feePercent: number;
        readonly feeAmount: number;
        readonly subtotalAmount: number;
        readonly taxPercent: number;
        readonly taxAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly creditBeforeTaxAmount: number;
        readonly creditAfterTaxAmount: number;
        readonly contractId: string | null;
        readonly contractSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "InvoiceCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly customerInfo: {
          readonly __typename: "InvoiceCustomerInfo";
          readonly name: string;
          readonly address: {
            readonly __typename: "Address";
            readonly line: string;
            readonly city: string;
            readonly subdivisionCode: string;
            readonly countryCode: string;
            readonly postalCode: string;
          };
        };
        readonly items: ReadonlyArray<{
          readonly __typename: "InvoiceItem";
          readonly id: string;
          readonly index: number;
          readonly type: InvoiceItemType;
          readonly name: string;
          readonly description: string | null;
          readonly quantity: number;
          readonly unitPrice: number;
          readonly amount: number;
        }>;
      };
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
      readonly unit: {
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DeleteContractInvoiceRefundMutationVariables = Exact<{
  input: DeleteContractInvoiceRefundInput;
}>;

export type DeleteContractInvoiceRefundMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DeleteContractInvoiceRefundPayload";
    readonly data: {
      readonly __typename: "DeleteContractInvoiceRefundPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly refundId: string;
      readonly invoice: {
        readonly __typename: "Invoice";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly number: number;
        readonly date: Date;
        readonly terms: InvoiceTermsOfPayment;
        readonly due: Date;
        readonly label: string;
        readonly status: InvoiceStatus;
        readonly feePercent: number;
        readonly feeAmount: number;
        readonly subtotalAmount: number;
        readonly taxPercent: number;
        readonly taxAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly creditBeforeTaxAmount: number;
        readonly creditAfterTaxAmount: number;
        readonly contractId: string | null;
        readonly contractSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "InvoiceCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly customerInfo: {
          readonly __typename: "InvoiceCustomerInfo";
          readonly name: string;
          readonly address: {
            readonly __typename: "Address";
            readonly line: string;
            readonly city: string;
            readonly subdivisionCode: string;
            readonly countryCode: string;
            readonly postalCode: string;
          };
        };
        readonly items: ReadonlyArray<{
          readonly __typename: "InvoiceItem";
          readonly id: string;
          readonly index: number;
          readonly type: InvoiceItemType;
          readonly name: string;
          readonly description: string | null;
          readonly quantity: number;
          readonly unitPrice: number;
          readonly amount: number;
        }>;
      };
      readonly contract: {
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateReportMutationVariables = Exact<{
  input: UpdateReportInput;
}>;

export type UpdateReportMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateReportPayload";
    readonly data: {
      readonly __typename: "UpdateReportPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly report: {
        readonly __typename: "Report";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly name: string;
        readonly activities: ReadonlyArray<{
          readonly __typename: "ReportActivity";
          readonly activityId: string;
          readonly index: number;
        }>;
        readonly rooms: ReadonlyArray<{
          readonly __typename: "ReportRoom";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly index: number;
          readonly name: string;
          readonly elements: ReadonlyArray<{
            readonly __typename: "ReportRoomElement";
            readonly id: string;
            readonly createdAt: Date;
            readonly updatedAt: Date;
            readonly index: number;
            readonly name: string;
            readonly display: ReportRoomElementDisplay;
          }>;
        }>;
      };
      readonly forms: ReadonlyArray<{
        readonly __typename: "Form";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly index: number;
        readonly name: string;
        readonly state: FormState;
        readonly modelId: string | null;
        readonly sections: ReadonlyArray<{
          readonly __typename: "FormSection";
          readonly id: string;
          readonly index: number;
          readonly title: string;
          readonly sectionId: string;
        }>;
        readonly reportRoomElementLinks: ReadonlyArray<{
          readonly __typename: "FormReportRoomElementLink";
          readonly formSectionId: string;
          readonly sectionElementId: string;
          readonly reportId: string;
          readonly reportRoomId: string;
          readonly reportRoomElementId: string;
        }>;
        readonly reportVibeRoomLinks: ReadonlyArray<{
          readonly __typename: "FormReportVibeRoomLink";
          readonly formSectionId: string;
          readonly reportId: string;
          readonly reportRoomId: string;
        }>;
      }>;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type CreateSectionMutationVariables = Exact<{
  input: CreateSectionInput;
}>;

export type CreateSectionMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "CreateSectionPayload";
    readonly data: {
      readonly __typename: "CreateSectionPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly section: {
        readonly __typename: "Section";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: SectionState;
        readonly title: string;
        readonly index: number;
        readonly modelLink: {
          readonly __typename: "SectionModelLink";
          readonly modelId: string;
          readonly modelRoomId: string;
        } | null;
        readonly elements: ReadonlyArray<
          | {
              readonly __typename: "SectionElement";
              readonly id: string;
              readonly createdAt: Date;
              readonly updatedAt: Date;
              readonly index: number;
              readonly type: SectionElementType;
              readonly label: string;
              readonly notes: string | null;
              readonly isOptional: boolean;
              readonly isManagedByVibes: boolean;
              readonly hideProductCategory: boolean;
              readonly groupId: string | null;
              readonly modelLink: {
                readonly __typename: "SectionElementModelLink";
                readonly modelId: string;
                readonly modelRoomId: string;
                readonly modelRoomElementId: string;
              } | null;
              readonly options: ReadonlyArray<
                | {
                    readonly __typename: "SectionElementOption";
                    readonly id: string;
                    readonly index: number;
                    readonly type: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly isUnlocked: boolean;
                    readonly productId: string;
                    readonly modelLink: {
                      readonly __typename: "SectionElementOptionModelLink";
                      readonly modelId: string;
                      readonly modelRoomId: string;
                      readonly modelRoomElementId: string;
                      readonly modelRoomElementOptionId: string;
                    } | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "SectionElementOptionBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }
                | {
                    readonly __typename: "SectionElementOptionSet";
                    readonly productCategoryId: string;
                    readonly minIndex: number;
                    readonly maxIndex: number;
                    readonly options: ReadonlyArray<{
                      readonly __typename: "SectionElementOption";
                      readonly id: string;
                      readonly index: number;
                      readonly type: SectionElementOptionType;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                      readonly clientAmount: number;
                      readonly contingencyAmount: number;
                      readonly isUnlocked: boolean;
                      readonly productId: string;
                      readonly modelLink: {
                        readonly __typename: "SectionElementOptionModelLink";
                        readonly modelId: string;
                        readonly modelRoomId: string;
                        readonly modelRoomElementId: string;
                        readonly modelRoomElementOptionId: string;
                      } | null;
                      readonly breakdownLines: ReadonlyArray<{
                        readonly __typename: "SectionElementOptionBreakdownLine";
                        readonly activityId: string;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                      }>;
                    }>;
                  }
              >;
            }
          | {
              readonly __typename: "SectionElementSet";
              readonly sectionElementGroupId: string;
              readonly minIndex: number;
              readonly maxIndex: number;
              readonly elements: ReadonlyArray<{
                readonly __typename: "SectionElement";
                readonly id: string;
                readonly createdAt: Date;
                readonly updatedAt: Date;
                readonly index: number;
                readonly type: SectionElementType;
                readonly label: string;
                readonly notes: string | null;
                readonly isOptional: boolean;
                readonly isManagedByVibes: boolean;
                readonly hideProductCategory: boolean;
                readonly groupId: string | null;
                readonly modelLink: {
                  readonly __typename: "SectionElementModelLink";
                  readonly modelId: string;
                  readonly modelRoomId: string;
                  readonly modelRoomElementId: string;
                } | null;
                readonly options: ReadonlyArray<
                  | {
                      readonly __typename: "SectionElementOption";
                      readonly id: string;
                      readonly index: number;
                      readonly type: SectionElementOptionType;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                      readonly clientAmount: number;
                      readonly contingencyAmount: number;
                      readonly isUnlocked: boolean;
                      readonly productId: string;
                      readonly modelLink: {
                        readonly __typename: "SectionElementOptionModelLink";
                        readonly modelId: string;
                        readonly modelRoomId: string;
                        readonly modelRoomElementId: string;
                        readonly modelRoomElementOptionId: string;
                      } | null;
                      readonly breakdownLines: ReadonlyArray<{
                        readonly __typename: "SectionElementOptionBreakdownLine";
                        readonly activityId: string;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                      }>;
                    }
                  | {
                      readonly __typename: "SectionElementOptionSet";
                      readonly productCategoryId: string;
                      readonly minIndex: number;
                      readonly maxIndex: number;
                      readonly options: ReadonlyArray<{
                        readonly __typename: "SectionElementOption";
                        readonly id: string;
                        readonly index: number;
                        readonly type: SectionElementOptionType;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                        readonly clientAmount: number;
                        readonly contingencyAmount: number;
                        readonly isUnlocked: boolean;
                        readonly productId: string;
                        readonly modelLink: {
                          readonly __typename: "SectionElementOptionModelLink";
                          readonly modelId: string;
                          readonly modelRoomId: string;
                          readonly modelRoomElementId: string;
                          readonly modelRoomElementOptionId: string;
                        } | null;
                        readonly breakdownLines: ReadonlyArray<{
                          readonly __typename: "SectionElementOptionBreakdownLine";
                          readonly activityId: string;
                          readonly subcontractorAmount: number;
                          readonly contractorAmount: number;
                          readonly promoterAmount: number;
                          readonly costAmount: number;
                        }>;
                      }>;
                    }
                >;
              }>;
            }
        >;
        readonly elementGroups: ReadonlyArray<{
          readonly __typename: "SectionElementGroup";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly index: number;
          readonly name: string;
        }>;
        readonly vibes: ReadonlyArray<{
          readonly __typename: "SectionVibe";
          readonly id: string;
          readonly index: number;
          readonly name: string;
          readonly mode: SectionVibeMode;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly items: ReadonlyArray<{
            readonly __typename: "SectionVibeItem";
            readonly id: string;
            readonly index: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly sectionElementId: string;
            readonly sectionElementOptionId: string;
          }>;
        }>;
      };
      readonly products: ReadonlyArray<{
        readonly __typename: "Product";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly name: string;
        readonly variant: string;
        readonly format: string | null;
        readonly description: string | null;
        readonly thumbnailUrl: string;
        readonly hasThumbnail: boolean;
        readonly managedByModels: boolean;
        readonly usedBySections: boolean;
        readonly categoryId: string;
        readonly provider: {
          readonly __typename: "ProductProvider";
          readonly id: string | null;
          readonly productModel: string | null;
          readonly productCode: string | null;
          readonly productUrl: string | null;
        };
        readonly activities: ReadonlyArray<{
          readonly __typename: "ProductActivity";
          readonly activityId: string;
          readonly index: number;
        }>;
      }>;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateSectionMutationVariables = Exact<{
  input: UpdateSectionInput;
}>;

export type UpdateSectionMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateSectionPayload";
    readonly data: {
      readonly __typename: "UpdateSectionPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly section: {
        readonly __typename: "Section";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: SectionState;
        readonly title: string;
        readonly index: number;
        readonly modelLink: {
          readonly __typename: "SectionModelLink";
          readonly modelId: string;
          readonly modelRoomId: string;
        } | null;
        readonly elements: ReadonlyArray<
          | {
              readonly __typename: "SectionElement";
              readonly id: string;
              readonly createdAt: Date;
              readonly updatedAt: Date;
              readonly index: number;
              readonly type: SectionElementType;
              readonly label: string;
              readonly notes: string | null;
              readonly isOptional: boolean;
              readonly isManagedByVibes: boolean;
              readonly hideProductCategory: boolean;
              readonly groupId: string | null;
              readonly modelLink: {
                readonly __typename: "SectionElementModelLink";
                readonly modelId: string;
                readonly modelRoomId: string;
                readonly modelRoomElementId: string;
              } | null;
              readonly options: ReadonlyArray<
                | {
                    readonly __typename: "SectionElementOption";
                    readonly id: string;
                    readonly index: number;
                    readonly type: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly isUnlocked: boolean;
                    readonly productId: string;
                    readonly modelLink: {
                      readonly __typename: "SectionElementOptionModelLink";
                      readonly modelId: string;
                      readonly modelRoomId: string;
                      readonly modelRoomElementId: string;
                      readonly modelRoomElementOptionId: string;
                    } | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "SectionElementOptionBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }
                | {
                    readonly __typename: "SectionElementOptionSet";
                    readonly productCategoryId: string;
                    readonly minIndex: number;
                    readonly maxIndex: number;
                    readonly options: ReadonlyArray<{
                      readonly __typename: "SectionElementOption";
                      readonly id: string;
                      readonly index: number;
                      readonly type: SectionElementOptionType;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                      readonly clientAmount: number;
                      readonly contingencyAmount: number;
                      readonly isUnlocked: boolean;
                      readonly productId: string;
                      readonly modelLink: {
                        readonly __typename: "SectionElementOptionModelLink";
                        readonly modelId: string;
                        readonly modelRoomId: string;
                        readonly modelRoomElementId: string;
                        readonly modelRoomElementOptionId: string;
                      } | null;
                      readonly breakdownLines: ReadonlyArray<{
                        readonly __typename: "SectionElementOptionBreakdownLine";
                        readonly activityId: string;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                      }>;
                    }>;
                  }
              >;
            }
          | {
              readonly __typename: "SectionElementSet";
              readonly sectionElementGroupId: string;
              readonly minIndex: number;
              readonly maxIndex: number;
              readonly elements: ReadonlyArray<{
                readonly __typename: "SectionElement";
                readonly id: string;
                readonly createdAt: Date;
                readonly updatedAt: Date;
                readonly index: number;
                readonly type: SectionElementType;
                readonly label: string;
                readonly notes: string | null;
                readonly isOptional: boolean;
                readonly isManagedByVibes: boolean;
                readonly hideProductCategory: boolean;
                readonly groupId: string | null;
                readonly modelLink: {
                  readonly __typename: "SectionElementModelLink";
                  readonly modelId: string;
                  readonly modelRoomId: string;
                  readonly modelRoomElementId: string;
                } | null;
                readonly options: ReadonlyArray<
                  | {
                      readonly __typename: "SectionElementOption";
                      readonly id: string;
                      readonly index: number;
                      readonly type: SectionElementOptionType;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                      readonly clientAmount: number;
                      readonly contingencyAmount: number;
                      readonly isUnlocked: boolean;
                      readonly productId: string;
                      readonly modelLink: {
                        readonly __typename: "SectionElementOptionModelLink";
                        readonly modelId: string;
                        readonly modelRoomId: string;
                        readonly modelRoomElementId: string;
                        readonly modelRoomElementOptionId: string;
                      } | null;
                      readonly breakdownLines: ReadonlyArray<{
                        readonly __typename: "SectionElementOptionBreakdownLine";
                        readonly activityId: string;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                      }>;
                    }
                  | {
                      readonly __typename: "SectionElementOptionSet";
                      readonly productCategoryId: string;
                      readonly minIndex: number;
                      readonly maxIndex: number;
                      readonly options: ReadonlyArray<{
                        readonly __typename: "SectionElementOption";
                        readonly id: string;
                        readonly index: number;
                        readonly type: SectionElementOptionType;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                        readonly clientAmount: number;
                        readonly contingencyAmount: number;
                        readonly isUnlocked: boolean;
                        readonly productId: string;
                        readonly modelLink: {
                          readonly __typename: "SectionElementOptionModelLink";
                          readonly modelId: string;
                          readonly modelRoomId: string;
                          readonly modelRoomElementId: string;
                          readonly modelRoomElementOptionId: string;
                        } | null;
                        readonly breakdownLines: ReadonlyArray<{
                          readonly __typename: "SectionElementOptionBreakdownLine";
                          readonly activityId: string;
                          readonly subcontractorAmount: number;
                          readonly contractorAmount: number;
                          readonly promoterAmount: number;
                          readonly costAmount: number;
                        }>;
                      }>;
                    }
                >;
              }>;
            }
        >;
        readonly elementGroups: ReadonlyArray<{
          readonly __typename: "SectionElementGroup";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly index: number;
          readonly name: string;
        }>;
        readonly vibes: ReadonlyArray<{
          readonly __typename: "SectionVibe";
          readonly id: string;
          readonly index: number;
          readonly name: string;
          readonly mode: SectionVibeMode;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly items: ReadonlyArray<{
            readonly __typename: "SectionVibeItem";
            readonly id: string;
            readonly index: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly sectionElementId: string;
            readonly sectionElementOptionId: string;
          }>;
        }>;
      };
      readonly products: ReadonlyArray<{
        readonly __typename: "Product";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly name: string;
        readonly variant: string;
        readonly format: string | null;
        readonly description: string | null;
        readonly thumbnailUrl: string;
        readonly hasThumbnail: boolean;
        readonly managedByModels: boolean;
        readonly usedBySections: boolean;
        readonly categoryId: string;
        readonly provider: {
          readonly __typename: "ProductProvider";
          readonly id: string | null;
          readonly productModel: string | null;
          readonly productCode: string | null;
          readonly productUrl: string | null;
        };
        readonly activities: ReadonlyArray<{
          readonly __typename: "ProductActivity";
          readonly activityId: string;
          readonly index: number;
        }>;
      }>;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DeleteSectionMutationVariables = Exact<{
  input: DeleteSectionInput;
}>;

export type DeleteSectionMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DeleteSectionPayload";
    readonly data: {
      readonly __typename: "DeleteSectionPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly sectionId: string;
      readonly products: ReadonlyArray<{
        readonly __typename: "Product";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly name: string;
        readonly variant: string;
        readonly format: string | null;
        readonly description: string | null;
        readonly thumbnailUrl: string;
        readonly hasThumbnail: boolean;
        readonly managedByModels: boolean;
        readonly usedBySections: boolean;
        readonly categoryId: string;
        readonly provider: {
          readonly __typename: "ProductProvider";
          readonly id: string | null;
          readonly productModel: string | null;
          readonly productCode: string | null;
          readonly productUrl: string | null;
        };
        readonly activities: ReadonlyArray<{
          readonly __typename: "ProductActivity";
          readonly activityId: string;
          readonly index: number;
        }>;
      }>;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type ConfigureSectionMutationVariables = Exact<{
  input: ConfigureSectionInput;
}>;

export type ConfigureSectionMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "ConfigureSectionPayload";
    readonly data: {
      readonly __typename: "ConfigureSectionPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly section: {
        readonly __typename: "Section";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: SectionState;
        readonly title: string;
        readonly index: number;
        readonly modelLink: {
          readonly __typename: "SectionModelLink";
          readonly modelId: string;
          readonly modelRoomId: string;
        } | null;
        readonly elements: ReadonlyArray<
          | {
              readonly __typename: "SectionElement";
              readonly id: string;
              readonly createdAt: Date;
              readonly updatedAt: Date;
              readonly index: number;
              readonly type: SectionElementType;
              readonly label: string;
              readonly notes: string | null;
              readonly isOptional: boolean;
              readonly isManagedByVibes: boolean;
              readonly hideProductCategory: boolean;
              readonly groupId: string | null;
              readonly modelLink: {
                readonly __typename: "SectionElementModelLink";
                readonly modelId: string;
                readonly modelRoomId: string;
                readonly modelRoomElementId: string;
              } | null;
              readonly options: ReadonlyArray<
                | {
                    readonly __typename: "SectionElementOption";
                    readonly id: string;
                    readonly index: number;
                    readonly type: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly isUnlocked: boolean;
                    readonly productId: string;
                    readonly modelLink: {
                      readonly __typename: "SectionElementOptionModelLink";
                      readonly modelId: string;
                      readonly modelRoomId: string;
                      readonly modelRoomElementId: string;
                      readonly modelRoomElementOptionId: string;
                    } | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "SectionElementOptionBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }
                | {
                    readonly __typename: "SectionElementOptionSet";
                    readonly productCategoryId: string;
                    readonly minIndex: number;
                    readonly maxIndex: number;
                    readonly options: ReadonlyArray<{
                      readonly __typename: "SectionElementOption";
                      readonly id: string;
                      readonly index: number;
                      readonly type: SectionElementOptionType;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                      readonly clientAmount: number;
                      readonly contingencyAmount: number;
                      readonly isUnlocked: boolean;
                      readonly productId: string;
                      readonly modelLink: {
                        readonly __typename: "SectionElementOptionModelLink";
                        readonly modelId: string;
                        readonly modelRoomId: string;
                        readonly modelRoomElementId: string;
                        readonly modelRoomElementOptionId: string;
                      } | null;
                      readonly breakdownLines: ReadonlyArray<{
                        readonly __typename: "SectionElementOptionBreakdownLine";
                        readonly activityId: string;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                      }>;
                    }>;
                  }
              >;
            }
          | {
              readonly __typename: "SectionElementSet";
              readonly sectionElementGroupId: string;
              readonly minIndex: number;
              readonly maxIndex: number;
              readonly elements: ReadonlyArray<{
                readonly __typename: "SectionElement";
                readonly id: string;
                readonly createdAt: Date;
                readonly updatedAt: Date;
                readonly index: number;
                readonly type: SectionElementType;
                readonly label: string;
                readonly notes: string | null;
                readonly isOptional: boolean;
                readonly isManagedByVibes: boolean;
                readonly hideProductCategory: boolean;
                readonly groupId: string | null;
                readonly modelLink: {
                  readonly __typename: "SectionElementModelLink";
                  readonly modelId: string;
                  readonly modelRoomId: string;
                  readonly modelRoomElementId: string;
                } | null;
                readonly options: ReadonlyArray<
                  | {
                      readonly __typename: "SectionElementOption";
                      readonly id: string;
                      readonly index: number;
                      readonly type: SectionElementOptionType;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                      readonly clientAmount: number;
                      readonly contingencyAmount: number;
                      readonly isUnlocked: boolean;
                      readonly productId: string;
                      readonly modelLink: {
                        readonly __typename: "SectionElementOptionModelLink";
                        readonly modelId: string;
                        readonly modelRoomId: string;
                        readonly modelRoomElementId: string;
                        readonly modelRoomElementOptionId: string;
                      } | null;
                      readonly breakdownLines: ReadonlyArray<{
                        readonly __typename: "SectionElementOptionBreakdownLine";
                        readonly activityId: string;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                      }>;
                    }
                  | {
                      readonly __typename: "SectionElementOptionSet";
                      readonly productCategoryId: string;
                      readonly minIndex: number;
                      readonly maxIndex: number;
                      readonly options: ReadonlyArray<{
                        readonly __typename: "SectionElementOption";
                        readonly id: string;
                        readonly index: number;
                        readonly type: SectionElementOptionType;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                        readonly clientAmount: number;
                        readonly contingencyAmount: number;
                        readonly isUnlocked: boolean;
                        readonly productId: string;
                        readonly modelLink: {
                          readonly __typename: "SectionElementOptionModelLink";
                          readonly modelId: string;
                          readonly modelRoomId: string;
                          readonly modelRoomElementId: string;
                          readonly modelRoomElementOptionId: string;
                        } | null;
                        readonly breakdownLines: ReadonlyArray<{
                          readonly __typename: "SectionElementOptionBreakdownLine";
                          readonly activityId: string;
                          readonly subcontractorAmount: number;
                          readonly contractorAmount: number;
                          readonly promoterAmount: number;
                          readonly costAmount: number;
                        }>;
                      }>;
                    }
                >;
              }>;
            }
        >;
        readonly elementGroups: ReadonlyArray<{
          readonly __typename: "SectionElementGroup";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly index: number;
          readonly name: string;
        }>;
        readonly vibes: ReadonlyArray<{
          readonly __typename: "SectionVibe";
          readonly id: string;
          readonly index: number;
          readonly name: string;
          readonly mode: SectionVibeMode;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly items: ReadonlyArray<{
            readonly __typename: "SectionVibeItem";
            readonly id: string;
            readonly index: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly sectionElementId: string;
            readonly sectionElementOptionId: string;
          }>;
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type PublishSectionMutationVariables = Exact<{
  input: PublishSectionInput;
}>;

export type PublishSectionMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "PublishSectionPayload";
    readonly data: {
      readonly __typename: "PublishSectionPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly section: {
        readonly __typename: "Section";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: SectionState;
        readonly title: string;
        readonly index: number;
        readonly modelLink: {
          readonly __typename: "SectionModelLink";
          readonly modelId: string;
          readonly modelRoomId: string;
        } | null;
        readonly elements: ReadonlyArray<
          | {
              readonly __typename: "SectionElement";
              readonly id: string;
              readonly createdAt: Date;
              readonly updatedAt: Date;
              readonly index: number;
              readonly type: SectionElementType;
              readonly label: string;
              readonly notes: string | null;
              readonly isOptional: boolean;
              readonly isManagedByVibes: boolean;
              readonly hideProductCategory: boolean;
              readonly groupId: string | null;
              readonly modelLink: {
                readonly __typename: "SectionElementModelLink";
                readonly modelId: string;
                readonly modelRoomId: string;
                readonly modelRoomElementId: string;
              } | null;
              readonly options: ReadonlyArray<
                | {
                    readonly __typename: "SectionElementOption";
                    readonly id: string;
                    readonly index: number;
                    readonly type: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly isUnlocked: boolean;
                    readonly productId: string;
                    readonly modelLink: {
                      readonly __typename: "SectionElementOptionModelLink";
                      readonly modelId: string;
                      readonly modelRoomId: string;
                      readonly modelRoomElementId: string;
                      readonly modelRoomElementOptionId: string;
                    } | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "SectionElementOptionBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }
                | {
                    readonly __typename: "SectionElementOptionSet";
                    readonly productCategoryId: string;
                    readonly minIndex: number;
                    readonly maxIndex: number;
                    readonly options: ReadonlyArray<{
                      readonly __typename: "SectionElementOption";
                      readonly id: string;
                      readonly index: number;
                      readonly type: SectionElementOptionType;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                      readonly clientAmount: number;
                      readonly contingencyAmount: number;
                      readonly isUnlocked: boolean;
                      readonly productId: string;
                      readonly modelLink: {
                        readonly __typename: "SectionElementOptionModelLink";
                        readonly modelId: string;
                        readonly modelRoomId: string;
                        readonly modelRoomElementId: string;
                        readonly modelRoomElementOptionId: string;
                      } | null;
                      readonly breakdownLines: ReadonlyArray<{
                        readonly __typename: "SectionElementOptionBreakdownLine";
                        readonly activityId: string;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                      }>;
                    }>;
                  }
              >;
            }
          | {
              readonly __typename: "SectionElementSet";
              readonly sectionElementGroupId: string;
              readonly minIndex: number;
              readonly maxIndex: number;
              readonly elements: ReadonlyArray<{
                readonly __typename: "SectionElement";
                readonly id: string;
                readonly createdAt: Date;
                readonly updatedAt: Date;
                readonly index: number;
                readonly type: SectionElementType;
                readonly label: string;
                readonly notes: string | null;
                readonly isOptional: boolean;
                readonly isManagedByVibes: boolean;
                readonly hideProductCategory: boolean;
                readonly groupId: string | null;
                readonly modelLink: {
                  readonly __typename: "SectionElementModelLink";
                  readonly modelId: string;
                  readonly modelRoomId: string;
                  readonly modelRoomElementId: string;
                } | null;
                readonly options: ReadonlyArray<
                  | {
                      readonly __typename: "SectionElementOption";
                      readonly id: string;
                      readonly index: number;
                      readonly type: SectionElementOptionType;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                      readonly clientAmount: number;
                      readonly contingencyAmount: number;
                      readonly isUnlocked: boolean;
                      readonly productId: string;
                      readonly modelLink: {
                        readonly __typename: "SectionElementOptionModelLink";
                        readonly modelId: string;
                        readonly modelRoomId: string;
                        readonly modelRoomElementId: string;
                        readonly modelRoomElementOptionId: string;
                      } | null;
                      readonly breakdownLines: ReadonlyArray<{
                        readonly __typename: "SectionElementOptionBreakdownLine";
                        readonly activityId: string;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                      }>;
                    }
                  | {
                      readonly __typename: "SectionElementOptionSet";
                      readonly productCategoryId: string;
                      readonly minIndex: number;
                      readonly maxIndex: number;
                      readonly options: ReadonlyArray<{
                        readonly __typename: "SectionElementOption";
                        readonly id: string;
                        readonly index: number;
                        readonly type: SectionElementOptionType;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                        readonly clientAmount: number;
                        readonly contingencyAmount: number;
                        readonly isUnlocked: boolean;
                        readonly productId: string;
                        readonly modelLink: {
                          readonly __typename: "SectionElementOptionModelLink";
                          readonly modelId: string;
                          readonly modelRoomId: string;
                          readonly modelRoomElementId: string;
                          readonly modelRoomElementOptionId: string;
                        } | null;
                        readonly breakdownLines: ReadonlyArray<{
                          readonly __typename: "SectionElementOptionBreakdownLine";
                          readonly activityId: string;
                          readonly subcontractorAmount: number;
                          readonly contractorAmount: number;
                          readonly promoterAmount: number;
                          readonly costAmount: number;
                        }>;
                      }>;
                    }
                >;
              }>;
            }
        >;
        readonly elementGroups: ReadonlyArray<{
          readonly __typename: "SectionElementGroup";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly index: number;
          readonly name: string;
        }>;
        readonly vibes: ReadonlyArray<{
          readonly __typename: "SectionVibe";
          readonly id: string;
          readonly index: number;
          readonly name: string;
          readonly mode: SectionVibeMode;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly items: ReadonlyArray<{
            readonly __typename: "SectionVibeItem";
            readonly id: string;
            readonly index: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly sectionElementId: string;
            readonly sectionElementOptionId: string;
          }>;
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type ArchiveSectionMutationVariables = Exact<{
  input: ArchiveSectionInput;
}>;

export type ArchiveSectionMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "ArchiveSectionPayload";
    readonly data: {
      readonly __typename: "ArchiveSectionPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly section: {
        readonly __typename: "Section";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: SectionState;
        readonly title: string;
        readonly index: number;
        readonly modelLink: {
          readonly __typename: "SectionModelLink";
          readonly modelId: string;
          readonly modelRoomId: string;
        } | null;
        readonly elements: ReadonlyArray<
          | {
              readonly __typename: "SectionElement";
              readonly id: string;
              readonly createdAt: Date;
              readonly updatedAt: Date;
              readonly index: number;
              readonly type: SectionElementType;
              readonly label: string;
              readonly notes: string | null;
              readonly isOptional: boolean;
              readonly isManagedByVibes: boolean;
              readonly hideProductCategory: boolean;
              readonly groupId: string | null;
              readonly modelLink: {
                readonly __typename: "SectionElementModelLink";
                readonly modelId: string;
                readonly modelRoomId: string;
                readonly modelRoomElementId: string;
              } | null;
              readonly options: ReadonlyArray<
                | {
                    readonly __typename: "SectionElementOption";
                    readonly id: string;
                    readonly index: number;
                    readonly type: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly isUnlocked: boolean;
                    readonly productId: string;
                    readonly modelLink: {
                      readonly __typename: "SectionElementOptionModelLink";
                      readonly modelId: string;
                      readonly modelRoomId: string;
                      readonly modelRoomElementId: string;
                      readonly modelRoomElementOptionId: string;
                    } | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "SectionElementOptionBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }
                | {
                    readonly __typename: "SectionElementOptionSet";
                    readonly productCategoryId: string;
                    readonly minIndex: number;
                    readonly maxIndex: number;
                    readonly options: ReadonlyArray<{
                      readonly __typename: "SectionElementOption";
                      readonly id: string;
                      readonly index: number;
                      readonly type: SectionElementOptionType;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                      readonly clientAmount: number;
                      readonly contingencyAmount: number;
                      readonly isUnlocked: boolean;
                      readonly productId: string;
                      readonly modelLink: {
                        readonly __typename: "SectionElementOptionModelLink";
                        readonly modelId: string;
                        readonly modelRoomId: string;
                        readonly modelRoomElementId: string;
                        readonly modelRoomElementOptionId: string;
                      } | null;
                      readonly breakdownLines: ReadonlyArray<{
                        readonly __typename: "SectionElementOptionBreakdownLine";
                        readonly activityId: string;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                      }>;
                    }>;
                  }
              >;
            }
          | {
              readonly __typename: "SectionElementSet";
              readonly sectionElementGroupId: string;
              readonly minIndex: number;
              readonly maxIndex: number;
              readonly elements: ReadonlyArray<{
                readonly __typename: "SectionElement";
                readonly id: string;
                readonly createdAt: Date;
                readonly updatedAt: Date;
                readonly index: number;
                readonly type: SectionElementType;
                readonly label: string;
                readonly notes: string | null;
                readonly isOptional: boolean;
                readonly isManagedByVibes: boolean;
                readonly hideProductCategory: boolean;
                readonly groupId: string | null;
                readonly modelLink: {
                  readonly __typename: "SectionElementModelLink";
                  readonly modelId: string;
                  readonly modelRoomId: string;
                  readonly modelRoomElementId: string;
                } | null;
                readonly options: ReadonlyArray<
                  | {
                      readonly __typename: "SectionElementOption";
                      readonly id: string;
                      readonly index: number;
                      readonly type: SectionElementOptionType;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                      readonly clientAmount: number;
                      readonly contingencyAmount: number;
                      readonly isUnlocked: boolean;
                      readonly productId: string;
                      readonly modelLink: {
                        readonly __typename: "SectionElementOptionModelLink";
                        readonly modelId: string;
                        readonly modelRoomId: string;
                        readonly modelRoomElementId: string;
                        readonly modelRoomElementOptionId: string;
                      } | null;
                      readonly breakdownLines: ReadonlyArray<{
                        readonly __typename: "SectionElementOptionBreakdownLine";
                        readonly activityId: string;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                      }>;
                    }
                  | {
                      readonly __typename: "SectionElementOptionSet";
                      readonly productCategoryId: string;
                      readonly minIndex: number;
                      readonly maxIndex: number;
                      readonly options: ReadonlyArray<{
                        readonly __typename: "SectionElementOption";
                        readonly id: string;
                        readonly index: number;
                        readonly type: SectionElementOptionType;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                        readonly clientAmount: number;
                        readonly contingencyAmount: number;
                        readonly isUnlocked: boolean;
                        readonly productId: string;
                        readonly modelLink: {
                          readonly __typename: "SectionElementOptionModelLink";
                          readonly modelId: string;
                          readonly modelRoomId: string;
                          readonly modelRoomElementId: string;
                          readonly modelRoomElementOptionId: string;
                        } | null;
                        readonly breakdownLines: ReadonlyArray<{
                          readonly __typename: "SectionElementOptionBreakdownLine";
                          readonly activityId: string;
                          readonly subcontractorAmount: number;
                          readonly contractorAmount: number;
                          readonly promoterAmount: number;
                          readonly costAmount: number;
                        }>;
                      }>;
                    }
                >;
              }>;
            }
        >;
        readonly elementGroups: ReadonlyArray<{
          readonly __typename: "SectionElementGroup";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly index: number;
          readonly name: string;
        }>;
        readonly vibes: ReadonlyArray<{
          readonly __typename: "SectionVibe";
          readonly id: string;
          readonly index: number;
          readonly name: string;
          readonly mode: SectionVibeMode;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly items: ReadonlyArray<{
            readonly __typename: "SectionVibeItem";
            readonly id: string;
            readonly index: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly sectionElementId: string;
            readonly sectionElementOptionId: string;
          }>;
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type CreateSectionVibeMutationVariables = Exact<{
  input: CreateSectionVibeInput;
}>;

export type CreateSectionVibeMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "CreateSectionVibePayload";
    readonly data: {
      readonly __typename: "CreateSectionVibePayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly section: {
        readonly __typename: "Section";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: SectionState;
        readonly title: string;
        readonly index: number;
        readonly modelLink: {
          readonly __typename: "SectionModelLink";
          readonly modelId: string;
          readonly modelRoomId: string;
        } | null;
        readonly elements: ReadonlyArray<
          | {
              readonly __typename: "SectionElement";
              readonly id: string;
              readonly createdAt: Date;
              readonly updatedAt: Date;
              readonly index: number;
              readonly type: SectionElementType;
              readonly label: string;
              readonly notes: string | null;
              readonly isOptional: boolean;
              readonly isManagedByVibes: boolean;
              readonly hideProductCategory: boolean;
              readonly groupId: string | null;
              readonly modelLink: {
                readonly __typename: "SectionElementModelLink";
                readonly modelId: string;
                readonly modelRoomId: string;
                readonly modelRoomElementId: string;
              } | null;
              readonly options: ReadonlyArray<
                | {
                    readonly __typename: "SectionElementOption";
                    readonly id: string;
                    readonly index: number;
                    readonly type: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly isUnlocked: boolean;
                    readonly productId: string;
                    readonly modelLink: {
                      readonly __typename: "SectionElementOptionModelLink";
                      readonly modelId: string;
                      readonly modelRoomId: string;
                      readonly modelRoomElementId: string;
                      readonly modelRoomElementOptionId: string;
                    } | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "SectionElementOptionBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }
                | {
                    readonly __typename: "SectionElementOptionSet";
                    readonly productCategoryId: string;
                    readonly minIndex: number;
                    readonly maxIndex: number;
                    readonly options: ReadonlyArray<{
                      readonly __typename: "SectionElementOption";
                      readonly id: string;
                      readonly index: number;
                      readonly type: SectionElementOptionType;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                      readonly clientAmount: number;
                      readonly contingencyAmount: number;
                      readonly isUnlocked: boolean;
                      readonly productId: string;
                      readonly modelLink: {
                        readonly __typename: "SectionElementOptionModelLink";
                        readonly modelId: string;
                        readonly modelRoomId: string;
                        readonly modelRoomElementId: string;
                        readonly modelRoomElementOptionId: string;
                      } | null;
                      readonly breakdownLines: ReadonlyArray<{
                        readonly __typename: "SectionElementOptionBreakdownLine";
                        readonly activityId: string;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                      }>;
                    }>;
                  }
              >;
            }
          | {
              readonly __typename: "SectionElementSet";
              readonly sectionElementGroupId: string;
              readonly minIndex: number;
              readonly maxIndex: number;
              readonly elements: ReadonlyArray<{
                readonly __typename: "SectionElement";
                readonly id: string;
                readonly createdAt: Date;
                readonly updatedAt: Date;
                readonly index: number;
                readonly type: SectionElementType;
                readonly label: string;
                readonly notes: string | null;
                readonly isOptional: boolean;
                readonly isManagedByVibes: boolean;
                readonly hideProductCategory: boolean;
                readonly groupId: string | null;
                readonly modelLink: {
                  readonly __typename: "SectionElementModelLink";
                  readonly modelId: string;
                  readonly modelRoomId: string;
                  readonly modelRoomElementId: string;
                } | null;
                readonly options: ReadonlyArray<
                  | {
                      readonly __typename: "SectionElementOption";
                      readonly id: string;
                      readonly index: number;
                      readonly type: SectionElementOptionType;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                      readonly clientAmount: number;
                      readonly contingencyAmount: number;
                      readonly isUnlocked: boolean;
                      readonly productId: string;
                      readonly modelLink: {
                        readonly __typename: "SectionElementOptionModelLink";
                        readonly modelId: string;
                        readonly modelRoomId: string;
                        readonly modelRoomElementId: string;
                        readonly modelRoomElementOptionId: string;
                      } | null;
                      readonly breakdownLines: ReadonlyArray<{
                        readonly __typename: "SectionElementOptionBreakdownLine";
                        readonly activityId: string;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                      }>;
                    }
                  | {
                      readonly __typename: "SectionElementOptionSet";
                      readonly productCategoryId: string;
                      readonly minIndex: number;
                      readonly maxIndex: number;
                      readonly options: ReadonlyArray<{
                        readonly __typename: "SectionElementOption";
                        readonly id: string;
                        readonly index: number;
                        readonly type: SectionElementOptionType;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                        readonly clientAmount: number;
                        readonly contingencyAmount: number;
                        readonly isUnlocked: boolean;
                        readonly productId: string;
                        readonly modelLink: {
                          readonly __typename: "SectionElementOptionModelLink";
                          readonly modelId: string;
                          readonly modelRoomId: string;
                          readonly modelRoomElementId: string;
                          readonly modelRoomElementOptionId: string;
                        } | null;
                        readonly breakdownLines: ReadonlyArray<{
                          readonly __typename: "SectionElementOptionBreakdownLine";
                          readonly activityId: string;
                          readonly subcontractorAmount: number;
                          readonly contractorAmount: number;
                          readonly promoterAmount: number;
                          readonly costAmount: number;
                        }>;
                      }>;
                    }
                >;
              }>;
            }
        >;
        readonly elementGroups: ReadonlyArray<{
          readonly __typename: "SectionElementGroup";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly index: number;
          readonly name: string;
        }>;
        readonly vibes: ReadonlyArray<{
          readonly __typename: "SectionVibe";
          readonly id: string;
          readonly index: number;
          readonly name: string;
          readonly mode: SectionVibeMode;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly items: ReadonlyArray<{
            readonly __typename: "SectionVibeItem";
            readonly id: string;
            readonly index: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly sectionElementId: string;
            readonly sectionElementOptionId: string;
          }>;
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateSectionVibeMutationVariables = Exact<{
  input: UpdateSectionVibeInput;
}>;

export type UpdateSectionVibeMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateSectionVibePayload";
    readonly data: {
      readonly __typename: "UpdateSectionVibePayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly section: {
        readonly __typename: "Section";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: SectionState;
        readonly title: string;
        readonly index: number;
        readonly modelLink: {
          readonly __typename: "SectionModelLink";
          readonly modelId: string;
          readonly modelRoomId: string;
        } | null;
        readonly elements: ReadonlyArray<
          | {
              readonly __typename: "SectionElement";
              readonly id: string;
              readonly createdAt: Date;
              readonly updatedAt: Date;
              readonly index: number;
              readonly type: SectionElementType;
              readonly label: string;
              readonly notes: string | null;
              readonly isOptional: boolean;
              readonly isManagedByVibes: boolean;
              readonly hideProductCategory: boolean;
              readonly groupId: string | null;
              readonly modelLink: {
                readonly __typename: "SectionElementModelLink";
                readonly modelId: string;
                readonly modelRoomId: string;
                readonly modelRoomElementId: string;
              } | null;
              readonly options: ReadonlyArray<
                | {
                    readonly __typename: "SectionElementOption";
                    readonly id: string;
                    readonly index: number;
                    readonly type: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly isUnlocked: boolean;
                    readonly productId: string;
                    readonly modelLink: {
                      readonly __typename: "SectionElementOptionModelLink";
                      readonly modelId: string;
                      readonly modelRoomId: string;
                      readonly modelRoomElementId: string;
                      readonly modelRoomElementOptionId: string;
                    } | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "SectionElementOptionBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }
                | {
                    readonly __typename: "SectionElementOptionSet";
                    readonly productCategoryId: string;
                    readonly minIndex: number;
                    readonly maxIndex: number;
                    readonly options: ReadonlyArray<{
                      readonly __typename: "SectionElementOption";
                      readonly id: string;
                      readonly index: number;
                      readonly type: SectionElementOptionType;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                      readonly clientAmount: number;
                      readonly contingencyAmount: number;
                      readonly isUnlocked: boolean;
                      readonly productId: string;
                      readonly modelLink: {
                        readonly __typename: "SectionElementOptionModelLink";
                        readonly modelId: string;
                        readonly modelRoomId: string;
                        readonly modelRoomElementId: string;
                        readonly modelRoomElementOptionId: string;
                      } | null;
                      readonly breakdownLines: ReadonlyArray<{
                        readonly __typename: "SectionElementOptionBreakdownLine";
                        readonly activityId: string;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                      }>;
                    }>;
                  }
              >;
            }
          | {
              readonly __typename: "SectionElementSet";
              readonly sectionElementGroupId: string;
              readonly minIndex: number;
              readonly maxIndex: number;
              readonly elements: ReadonlyArray<{
                readonly __typename: "SectionElement";
                readonly id: string;
                readonly createdAt: Date;
                readonly updatedAt: Date;
                readonly index: number;
                readonly type: SectionElementType;
                readonly label: string;
                readonly notes: string | null;
                readonly isOptional: boolean;
                readonly isManagedByVibes: boolean;
                readonly hideProductCategory: boolean;
                readonly groupId: string | null;
                readonly modelLink: {
                  readonly __typename: "SectionElementModelLink";
                  readonly modelId: string;
                  readonly modelRoomId: string;
                  readonly modelRoomElementId: string;
                } | null;
                readonly options: ReadonlyArray<
                  | {
                      readonly __typename: "SectionElementOption";
                      readonly id: string;
                      readonly index: number;
                      readonly type: SectionElementOptionType;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                      readonly clientAmount: number;
                      readonly contingencyAmount: number;
                      readonly isUnlocked: boolean;
                      readonly productId: string;
                      readonly modelLink: {
                        readonly __typename: "SectionElementOptionModelLink";
                        readonly modelId: string;
                        readonly modelRoomId: string;
                        readonly modelRoomElementId: string;
                        readonly modelRoomElementOptionId: string;
                      } | null;
                      readonly breakdownLines: ReadonlyArray<{
                        readonly __typename: "SectionElementOptionBreakdownLine";
                        readonly activityId: string;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                      }>;
                    }
                  | {
                      readonly __typename: "SectionElementOptionSet";
                      readonly productCategoryId: string;
                      readonly minIndex: number;
                      readonly maxIndex: number;
                      readonly options: ReadonlyArray<{
                        readonly __typename: "SectionElementOption";
                        readonly id: string;
                        readonly index: number;
                        readonly type: SectionElementOptionType;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                        readonly clientAmount: number;
                        readonly contingencyAmount: number;
                        readonly isUnlocked: boolean;
                        readonly productId: string;
                        readonly modelLink: {
                          readonly __typename: "SectionElementOptionModelLink";
                          readonly modelId: string;
                          readonly modelRoomId: string;
                          readonly modelRoomElementId: string;
                          readonly modelRoomElementOptionId: string;
                        } | null;
                        readonly breakdownLines: ReadonlyArray<{
                          readonly __typename: "SectionElementOptionBreakdownLine";
                          readonly activityId: string;
                          readonly subcontractorAmount: number;
                          readonly contractorAmount: number;
                          readonly promoterAmount: number;
                          readonly costAmount: number;
                        }>;
                      }>;
                    }
                >;
              }>;
            }
        >;
        readonly elementGroups: ReadonlyArray<{
          readonly __typename: "SectionElementGroup";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly index: number;
          readonly name: string;
        }>;
        readonly vibes: ReadonlyArray<{
          readonly __typename: "SectionVibe";
          readonly id: string;
          readonly index: number;
          readonly name: string;
          readonly mode: SectionVibeMode;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly items: ReadonlyArray<{
            readonly __typename: "SectionVibeItem";
            readonly id: string;
            readonly index: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly sectionElementId: string;
            readonly sectionElementOptionId: string;
          }>;
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DeleteSectionVibeMutationVariables = Exact<{
  input: DeleteSectionVibeInput;
}>;

export type DeleteSectionVibeMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DeleteSectionVibePayload";
    readonly data: {
      readonly __typename: "DeleteSectionVibePayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly section: {
        readonly __typename: "Section";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: SectionState;
        readonly title: string;
        readonly index: number;
        readonly modelLink: {
          readonly __typename: "SectionModelLink";
          readonly modelId: string;
          readonly modelRoomId: string;
        } | null;
        readonly elements: ReadonlyArray<
          | {
              readonly __typename: "SectionElement";
              readonly id: string;
              readonly createdAt: Date;
              readonly updatedAt: Date;
              readonly index: number;
              readonly type: SectionElementType;
              readonly label: string;
              readonly notes: string | null;
              readonly isOptional: boolean;
              readonly isManagedByVibes: boolean;
              readonly hideProductCategory: boolean;
              readonly groupId: string | null;
              readonly modelLink: {
                readonly __typename: "SectionElementModelLink";
                readonly modelId: string;
                readonly modelRoomId: string;
                readonly modelRoomElementId: string;
              } | null;
              readonly options: ReadonlyArray<
                | {
                    readonly __typename: "SectionElementOption";
                    readonly id: string;
                    readonly index: number;
                    readonly type: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly isUnlocked: boolean;
                    readonly productId: string;
                    readonly modelLink: {
                      readonly __typename: "SectionElementOptionModelLink";
                      readonly modelId: string;
                      readonly modelRoomId: string;
                      readonly modelRoomElementId: string;
                      readonly modelRoomElementOptionId: string;
                    } | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "SectionElementOptionBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }
                | {
                    readonly __typename: "SectionElementOptionSet";
                    readonly productCategoryId: string;
                    readonly minIndex: number;
                    readonly maxIndex: number;
                    readonly options: ReadonlyArray<{
                      readonly __typename: "SectionElementOption";
                      readonly id: string;
                      readonly index: number;
                      readonly type: SectionElementOptionType;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                      readonly clientAmount: number;
                      readonly contingencyAmount: number;
                      readonly isUnlocked: boolean;
                      readonly productId: string;
                      readonly modelLink: {
                        readonly __typename: "SectionElementOptionModelLink";
                        readonly modelId: string;
                        readonly modelRoomId: string;
                        readonly modelRoomElementId: string;
                        readonly modelRoomElementOptionId: string;
                      } | null;
                      readonly breakdownLines: ReadonlyArray<{
                        readonly __typename: "SectionElementOptionBreakdownLine";
                        readonly activityId: string;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                      }>;
                    }>;
                  }
              >;
            }
          | {
              readonly __typename: "SectionElementSet";
              readonly sectionElementGroupId: string;
              readonly minIndex: number;
              readonly maxIndex: number;
              readonly elements: ReadonlyArray<{
                readonly __typename: "SectionElement";
                readonly id: string;
                readonly createdAt: Date;
                readonly updatedAt: Date;
                readonly index: number;
                readonly type: SectionElementType;
                readonly label: string;
                readonly notes: string | null;
                readonly isOptional: boolean;
                readonly isManagedByVibes: boolean;
                readonly hideProductCategory: boolean;
                readonly groupId: string | null;
                readonly modelLink: {
                  readonly __typename: "SectionElementModelLink";
                  readonly modelId: string;
                  readonly modelRoomId: string;
                  readonly modelRoomElementId: string;
                } | null;
                readonly options: ReadonlyArray<
                  | {
                      readonly __typename: "SectionElementOption";
                      readonly id: string;
                      readonly index: number;
                      readonly type: SectionElementOptionType;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                      readonly clientAmount: number;
                      readonly contingencyAmount: number;
                      readonly isUnlocked: boolean;
                      readonly productId: string;
                      readonly modelLink: {
                        readonly __typename: "SectionElementOptionModelLink";
                        readonly modelId: string;
                        readonly modelRoomId: string;
                        readonly modelRoomElementId: string;
                        readonly modelRoomElementOptionId: string;
                      } | null;
                      readonly breakdownLines: ReadonlyArray<{
                        readonly __typename: "SectionElementOptionBreakdownLine";
                        readonly activityId: string;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                      }>;
                    }
                  | {
                      readonly __typename: "SectionElementOptionSet";
                      readonly productCategoryId: string;
                      readonly minIndex: number;
                      readonly maxIndex: number;
                      readonly options: ReadonlyArray<{
                        readonly __typename: "SectionElementOption";
                        readonly id: string;
                        readonly index: number;
                        readonly type: SectionElementOptionType;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                        readonly clientAmount: number;
                        readonly contingencyAmount: number;
                        readonly isUnlocked: boolean;
                        readonly productId: string;
                        readonly modelLink: {
                          readonly __typename: "SectionElementOptionModelLink";
                          readonly modelId: string;
                          readonly modelRoomId: string;
                          readonly modelRoomElementId: string;
                          readonly modelRoomElementOptionId: string;
                        } | null;
                        readonly breakdownLines: ReadonlyArray<{
                          readonly __typename: "SectionElementOptionBreakdownLine";
                          readonly activityId: string;
                          readonly subcontractorAmount: number;
                          readonly contractorAmount: number;
                          readonly promoterAmount: number;
                          readonly costAmount: number;
                        }>;
                      }>;
                    }
                >;
              }>;
            }
        >;
        readonly elementGroups: ReadonlyArray<{
          readonly __typename: "SectionElementGroup";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly index: number;
          readonly name: string;
        }>;
        readonly vibes: ReadonlyArray<{
          readonly __typename: "SectionVibe";
          readonly id: string;
          readonly index: number;
          readonly name: string;
          readonly mode: SectionVibeMode;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly items: ReadonlyArray<{
            readonly __typename: "SectionVibeItem";
            readonly id: string;
            readonly index: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly sectionElementId: string;
            readonly sectionElementOptionId: string;
          }>;
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DownloadTemplateMutationVariables = Exact<{
  input: DownloadTemplateInput;
}>;

export type DownloadTemplateMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DownloadTemplatePayload";
    readonly data: {
      readonly __typename: "DownloadTemplatePayloadData";
      readonly templateUrl: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type CreateUnitMutationVariables = Exact<{
  input: CreateUnitInput;
}>;

export type CreateUnitMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "CreateUnitPayload";
    readonly data: {
      readonly __typename: "CreateUnitPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly unit: {
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateUnitMutationVariables = Exact<{
  input: UpdateUnitInput;
}>;

export type UpdateUnitMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateUnitPayload";
    readonly data: {
      readonly __typename: "UpdateUnitPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly unit: {
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      };
      readonly contracts: ReadonlyArray<{
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      }>;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type DeleteUnitMutationVariables = Exact<{
  input: DeleteUnitInput;
}>;

export type DeleteUnitMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "DeleteUnitPayload";
    readonly data: {
      readonly __typename: "DeleteUnitPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly unitId: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type UpdateUnitsMutationVariables = Exact<{
  input: UpdateUnitsInput;
}>;

export type UpdateUnitsMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "UpdateUnitsPayload";
    readonly data: {
      readonly __typename: "UpdateUnitsPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly units: ReadonlyArray<{
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      }>;
      readonly contracts: ReadonlyArray<{
        readonly __typename: "Contract";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: ContractState;
        readonly isCanceled: boolean;
        readonly nonRefundableAmount: number;
        readonly totalAmount: number;
        readonly balanceAmount: number;
        readonly unitId: string;
        readonly formId: string | null;
        readonly currentSelectionId: string | null;
        readonly previousSelectionId: string | null;
        readonly customers: ReadonlyArray<{
          readonly __typename: "ContractCustomer";
          readonly customerId: string;
          readonly index: number;
        }>;
        readonly selections: ReadonlyArray<{
          readonly __typename: "ContractSelection";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly label: string;
          readonly state: ContractSelectionState;
          readonly revisionNumber: number;
          readonly revisionReason: string | null;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly workflow: ContractSelectionWorkflow;
          readonly isTermsAndConditionsAccepted: boolean | null;
          readonly invoiceId: string | null;
          readonly billingSettings: {
            readonly __typename: "ContractSelectionBillingSettings";
            readonly taxGroupId: string;
            readonly terms: InvoiceTermsOfPayment;
            readonly revisionAmount: number;
            readonly creditAmount: number;
            readonly mixAndMatchAmount: number;
          };
          readonly groups: ReadonlyArray<{
            readonly __typename: "ContractSelectionGroup";
            readonly id: string;
            readonly index: number;
            readonly state: ContractSelectionGroupState;
            readonly numberOfExtras: number;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
            readonly formSectionId: string;
            readonly vibe: {
              readonly __typename: "ContractSelectionGroupVibe";
              readonly sectionVibeId: string;
              readonly mode: ContractSelectionGroupVibeMode;
              readonly subcontractorAmount: number;
              readonly contractorAmount: number;
              readonly promoterAmount: number;
              readonly costAmount: number;
              readonly clientAmount: number;
              readonly contingencyAmount: number;
            } | null;
            readonly items: ReadonlyArray<
              | {
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "ContractSelectionGroupItemSet";
                  readonly sectionElementGroupId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly items: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItem";
                    readonly id: string;
                    readonly index: number;
                    readonly sectionElementOptionType: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly sectionId: string;
                    readonly sectionElementId: string;
                    readonly sectionElementOptionId: string | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }>;
        }>;
      }>;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type ImportUnitsMutationVariables = Exact<{
  input: ImportUnitsInput;
}>;

export type ImportUnitsMutation = {
  readonly __typename: "Mutation";
  readonly payload: {
    readonly __typename: "ImportUnitsPayload";
    readonly data: {
      readonly __typename: "ImportUnitsPayloadData";
      readonly tenantId: string;
      readonly projectId: string;
      readonly units: ReadonlyArray<{
        readonly __typename: "Unit";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly state: UnitState;
        readonly number: string;
        readonly floor: number;
        readonly numberOfBathrooms: number;
        readonly numberOfBedrooms: number;
        readonly label: string;
        readonly formId: string | null;
        readonly currentContractId: string | null;
      }>;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "Error";
      readonly message: string;
    }> | null;
  };
};

export type FetchCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type FetchCountriesQuery = {
  readonly __typename: "Query";
  readonly countries: ReadonlyArray<{
    readonly __typename: "Country";
    readonly code: string;
    readonly name: string;
    readonly callingCode: string;
    readonly subdivisions: ReadonlyArray<{
      readonly __typename: "CountrySubdivision";
      readonly code: string;
      readonly name: string;
      readonly type: CountrySubdivisionType;
    }>;
  }>;
};

export type FetchPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchPermissionsQuery = {
  readonly __typename: "Query";
  readonly permissions: ReadonlyArray<{
    readonly __typename: "Permission";
    readonly name: PermissionName;
    readonly createdAt: Date;
    readonly updatedAt: Date;
    readonly index: number;
    readonly displayName: string;
    readonly description: string;
    readonly groupName: PermissionGroupName;
  }>;
};

export type FetchTenantsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchTenantsQuery = {
  readonly __typename: "Query";
  readonly tenants: ReadonlyArray<{
    readonly __typename: "Tenant";
    readonly id: string;
    readonly type: TenantType;
    readonly status: TenantStatus;
    readonly name: string;
  }>;
};

export type FindTenantQueryVariables = Exact<{
  tenantId: Scalars["ID"]["input"];
}>;

export type FindTenantQuery = {
  readonly __typename: "Query";
  readonly tenant: {
    readonly __typename: "Tenant";
    readonly id: string;
    readonly type: TenantType;
    readonly status: TenantStatus;
    readonly name: string;
    readonly profile: {
      readonly __typename: "TenantProfile";
      readonly companyName: string;
      readonly email: string;
      readonly phone: string;
      readonly language: Language;
      readonly currencyCode: CurrencyCode;
      readonly websiteUrl: string | null;
      readonly address: {
        readonly __typename: "Address";
        readonly line: string;
        readonly city: string;
        readonly subdivisionCode: string;
        readonly countryCode: string;
        readonly postalCode: string;
      };
    };
    readonly taxes: ReadonlyArray<{
      readonly __typename: "Tax";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly name: string;
      readonly rate: number;
      readonly agencyId: string;
    }>;
    readonly taxGroups: ReadonlyArray<{
      readonly __typename: "TaxGroup";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly name: string;
      readonly taxIds: ReadonlyArray<string>;
    }>;
    readonly taxAgencies: ReadonlyArray<{
      readonly __typename: "TaxAgency";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly name: string;
      readonly description: string | null;
    }>;
    readonly roles: ReadonlyArray<{
      readonly __typename: "Role";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly name: string;
      readonly description: string;
      readonly template: RoleTemplate;
      readonly isLocked: boolean;
      readonly isTenantProfileManager: boolean;
      readonly isTenantSecurityManager: boolean;
      readonly isTenantAccountingManager: boolean;
      readonly isTenantProjectManager: boolean;
      readonly permissions: ReadonlyArray<PermissionName>;
    }>;
    readonly users: ReadonlyArray<{
      readonly __typename: "User";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly email: string;
      readonly firstName: string;
      readonly lastName: string;
      readonly fullName: string;
      readonly isTest: boolean;
      readonly isBlocked: boolean;
      readonly roleId: string;
      readonly hasAccessToAllProjects: boolean;
      readonly hasAccessToTestTenant: boolean;
      readonly projectIds: ReadonlyArray<string>;
    }>;
    readonly customers: ReadonlyArray<{
      readonly __typename: "Customer";
      readonly id: string;
      readonly firstName: string | null;
      readonly lastName: string | null;
      readonly fullName: string | null;
      readonly companyName: string | null;
      readonly displayName: string;
      readonly type: CustomerType;
      readonly label: string;
      readonly email: string;
      readonly phone: string;
      readonly gender: Gender;
      readonly language: Language;
      readonly notes: string | null;
      readonly isDefault: boolean;
      readonly address: {
        readonly __typename: "Address";
        readonly line: string;
        readonly city: string;
        readonly subdivisionCode: string;
        readonly countryCode: string;
        readonly postalCode: string;
      };
    }>;
    readonly providers: ReadonlyArray<{
      readonly __typename: "Provider";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly name: string;
      readonly websiteUrl: string | null;
      readonly description: string | null;
    }>;
    readonly projects: ReadonlyArray<{
      readonly __typename: "Project";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly code: string;
      readonly name: string;
      readonly label: string;
      readonly description: string | null;
      readonly tenantId: string;
      readonly contactInfo: {
        readonly __typename: "ProjectContactInfo";
        readonly companyName: string;
        readonly email: string;
        readonly phone: string;
        readonly websiteUrl: string;
        readonly federalTaxNumber: string | null;
        readonly provincialTaxNumber: string | null;
        readonly address: {
          readonly __typename: "Address";
          readonly line: string;
          readonly city: string;
          readonly subdivisionCode: string;
          readonly countryCode: string;
          readonly postalCode: string;
        };
      };
    }>;
  };
};

export type FindProjectQueryVariables = Exact<{
  tenantId: Scalars["ID"]["input"];
  projectId: Scalars["ID"]["input"];
}>;

export type FindProjectQuery = {
  readonly __typename: "Query";
  readonly project: {
    readonly __typename: "Project";
    readonly id: string;
    readonly createdAt: Date;
    readonly updatedAt: Date;
    readonly code: string;
    readonly name: string;
    readonly label: string;
    readonly description: string | null;
    readonly tenantId: string;
    readonly activities: ReadonlyArray<{
      readonly __typename: "Activity";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly number: string;
      readonly title: string;
      readonly label: string;
      readonly description: string | null;
    }>;
    readonly contracts: ReadonlyArray<{
      readonly __typename: "Contract";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly state: ContractState;
      readonly isCanceled: boolean;
      readonly nonRefundableAmount: number;
      readonly totalAmount: number;
      readonly balanceAmount: number;
      readonly unitId: string;
      readonly formId: string | null;
      readonly currentSelectionId: string | null;
      readonly previousSelectionId: string | null;
      readonly customers: ReadonlyArray<{
        readonly __typename: "ContractCustomer";
        readonly customerId: string;
        readonly index: number;
      }>;
      readonly selections: ReadonlyArray<{
        readonly __typename: "ContractSelection";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly label: string;
        readonly state: ContractSelectionState;
        readonly revisionNumber: number;
        readonly revisionReason: string | null;
        readonly numberOfExtras: number;
        readonly subcontractorAmount: number;
        readonly contractorAmount: number;
        readonly promoterAmount: number;
        readonly costAmount: number;
        readonly clientAmount: number;
        readonly contingencyAmount: number;
        readonly workflow: ContractSelectionWorkflow;
        readonly isTermsAndConditionsAccepted: boolean | null;
        readonly invoiceId: string | null;
        readonly billingSettings: {
          readonly __typename: "ContractSelectionBillingSettings";
          readonly taxGroupId: string;
          readonly terms: InvoiceTermsOfPayment;
          readonly revisionAmount: number;
          readonly creditAmount: number;
          readonly mixAndMatchAmount: number;
        };
        readonly groups: ReadonlyArray<{
          readonly __typename: "ContractSelectionGroup";
          readonly id: string;
          readonly index: number;
          readonly state: ContractSelectionGroupState;
          readonly numberOfExtras: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly clientAmount: number;
          readonly contingencyAmount: number;
          readonly formSectionId: string;
          readonly vibe: {
            readonly __typename: "ContractSelectionGroupVibe";
            readonly sectionVibeId: string;
            readonly mode: ContractSelectionGroupVibeMode;
            readonly subcontractorAmount: number;
            readonly contractorAmount: number;
            readonly promoterAmount: number;
            readonly costAmount: number;
            readonly clientAmount: number;
            readonly contingencyAmount: number;
          } | null;
          readonly items: ReadonlyArray<
            | {
                readonly __typename: "ContractSelectionGroupItem";
                readonly id: string;
                readonly index: number;
                readonly sectionElementOptionType: SectionElementOptionType;
                readonly subcontractorAmount: number;
                readonly contractorAmount: number;
                readonly promoterAmount: number;
                readonly costAmount: number;
                readonly clientAmount: number;
                readonly contingencyAmount: number;
                readonly sectionId: string;
                readonly sectionElementId: string;
                readonly sectionElementOptionId: string | null;
                readonly breakdownLines: ReadonlyArray<{
                  readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                  readonly activityId: string;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                }>;
              }
            | {
                readonly __typename: "ContractSelectionGroupItemSet";
                readonly sectionElementGroupId: string;
                readonly minIndex: number;
                readonly maxIndex: number;
                readonly items: ReadonlyArray<{
                  readonly __typename: "ContractSelectionGroupItem";
                  readonly id: string;
                  readonly index: number;
                  readonly sectionElementOptionType: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly sectionId: string;
                  readonly sectionElementId: string;
                  readonly sectionElementOptionId: string | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "ContractSelectionGroupItemBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }>;
              }
          >;
        }>;
      }>;
    }>;
    readonly forms: ReadonlyArray<{
      readonly __typename: "Form";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly index: number;
      readonly name: string;
      readonly state: FormState;
      readonly modelId: string | null;
      readonly sections: ReadonlyArray<{
        readonly __typename: "FormSection";
        readonly id: string;
        readonly index: number;
        readonly title: string;
        readonly sectionId: string;
      }>;
      readonly reportRoomElementLinks: ReadonlyArray<{
        readonly __typename: "FormReportRoomElementLink";
        readonly formSectionId: string;
        readonly sectionElementId: string;
        readonly reportId: string;
        readonly reportRoomId: string;
        readonly reportRoomElementId: string;
      }>;
      readonly reportVibeRoomLinks: ReadonlyArray<{
        readonly __typename: "FormReportVibeRoomLink";
        readonly formSectionId: string;
        readonly reportId: string;
        readonly reportRoomId: string;
      }>;
    }>;
    readonly invoices: ReadonlyArray<{
      readonly __typename: "Invoice";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly number: number;
      readonly date: Date;
      readonly terms: InvoiceTermsOfPayment;
      readonly due: Date;
      readonly label: string;
      readonly status: InvoiceStatus;
      readonly feePercent: number;
      readonly feeAmount: number;
      readonly subtotalAmount: number;
      readonly taxPercent: number;
      readonly taxAmount: number;
      readonly totalAmount: number;
      readonly balanceAmount: number;
      readonly creditBeforeTaxAmount: number;
      readonly creditAfterTaxAmount: number;
      readonly contractId: string | null;
      readonly contractSelectionId: string | null;
      readonly customers: ReadonlyArray<{
        readonly __typename: "InvoiceCustomer";
        readonly customerId: string;
        readonly index: number;
      }>;
      readonly customerInfo: {
        readonly __typename: "InvoiceCustomerInfo";
        readonly name: string;
        readonly address: {
          readonly __typename: "Address";
          readonly line: string;
          readonly city: string;
          readonly subdivisionCode: string;
          readonly countryCode: string;
          readonly postalCode: string;
        };
      };
      readonly items: ReadonlyArray<{
        readonly __typename: "InvoiceItem";
        readonly id: string;
        readonly index: number;
        readonly type: InvoiceItemType;
        readonly name: string;
        readonly description: string | null;
        readonly quantity: number;
        readonly unitPrice: number;
        readonly amount: number;
      }>;
    }>;
    readonly models: ReadonlyArray<{
      readonly __typename: "Model";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly sharedId: string;
      readonly name: string;
      readonly rooms: ReadonlyArray<{
        readonly __typename: "ModelRoom";
        readonly id: string;
        readonly sharedId: string;
        readonly index: number;
        readonly name: string;
        readonly elements: ReadonlyArray<{
          readonly __typename: "ModelRoomElement";
          readonly id: string;
          readonly sharedId: string;
          readonly name: string;
          readonly index: number;
          readonly type: ModelRoomElementType;
          readonly options: ReadonlyArray<{
            readonly __typename: "ModelRoomElementOption";
            readonly id: string;
            readonly sharedId: string;
            readonly index: number;
            readonly productId: string;
          }>;
        }>;
      }>;
    }>;
    readonly products: ReadonlyArray<{
      readonly __typename: "Product";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly name: string;
      readonly variant: string;
      readonly format: string | null;
      readonly description: string | null;
      readonly thumbnailUrl: string;
      readonly hasThumbnail: boolean;
      readonly managedByModels: boolean;
      readonly usedBySections: boolean;
      readonly categoryId: string;
      readonly provider: {
        readonly __typename: "ProductProvider";
        readonly id: string | null;
        readonly productModel: string | null;
        readonly productCode: string | null;
        readonly productUrl: string | null;
      };
      readonly activities: ReadonlyArray<{
        readonly __typename: "ProductActivity";
        readonly activityId: string;
        readonly index: number;
      }>;
    }>;
    readonly productCategories: ReadonlyArray<{
      readonly __typename: "ProductCategory";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly name: string;
      readonly displayName: string | null;
      readonly description: string | null;
      readonly managedByModels: boolean;
    }>;
    readonly payments: ReadonlyArray<{
      readonly __typename: "Payment";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly date: Date;
      readonly number: number;
      readonly label: string;
      readonly referenceNumber: string | null;
      readonly isLocked: boolean;
      readonly mode: PaymentMode;
      readonly amount: number;
      readonly notes: string | null;
      readonly invoiceId: string | null;
      readonly contractId: string | null;
    }>;
    readonly reports: ReadonlyArray<{
      readonly __typename: "Report";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly name: string;
      readonly activities: ReadonlyArray<{
        readonly __typename: "ReportActivity";
        readonly activityId: string;
        readonly index: number;
      }>;
      readonly rooms: ReadonlyArray<{
        readonly __typename: "ReportRoom";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly index: number;
        readonly name: string;
        readonly elements: ReadonlyArray<{
          readonly __typename: "ReportRoomElement";
          readonly id: string;
          readonly createdAt: Date;
          readonly updatedAt: Date;
          readonly index: number;
          readonly name: string;
          readonly display: ReportRoomElementDisplay;
        }>;
      }>;
    }>;
    readonly refunds: ReadonlyArray<{
      readonly __typename: "Refund";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly date: Date;
      readonly number: number;
      readonly label: string;
      readonly referenceNumber: string | null;
      readonly isLocked: boolean;
      readonly mode: RefundMode;
      readonly amount: number;
      readonly notes: string | null;
      readonly invoiceId: string | null;
      readonly contractId: string | null;
    }>;
    readonly sections: ReadonlyArray<{
      readonly __typename: "Section";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly state: SectionState;
      readonly title: string;
      readonly index: number;
      readonly modelLink: {
        readonly __typename: "SectionModelLink";
        readonly modelId: string;
        readonly modelRoomId: string;
      } | null;
      readonly elements: ReadonlyArray<
        | {
            readonly __typename: "SectionElement";
            readonly id: string;
            readonly createdAt: Date;
            readonly updatedAt: Date;
            readonly index: number;
            readonly type: SectionElementType;
            readonly label: string;
            readonly notes: string | null;
            readonly isOptional: boolean;
            readonly isManagedByVibes: boolean;
            readonly hideProductCategory: boolean;
            readonly groupId: string | null;
            readonly modelLink: {
              readonly __typename: "SectionElementModelLink";
              readonly modelId: string;
              readonly modelRoomId: string;
              readonly modelRoomElementId: string;
            } | null;
            readonly options: ReadonlyArray<
              | {
                  readonly __typename: "SectionElementOption";
                  readonly id: string;
                  readonly index: number;
                  readonly type: SectionElementOptionType;
                  readonly subcontractorAmount: number;
                  readonly contractorAmount: number;
                  readonly promoterAmount: number;
                  readonly costAmount: number;
                  readonly clientAmount: number;
                  readonly contingencyAmount: number;
                  readonly isUnlocked: boolean;
                  readonly productId: string;
                  readonly modelLink: {
                    readonly __typename: "SectionElementOptionModelLink";
                    readonly modelId: string;
                    readonly modelRoomId: string;
                    readonly modelRoomElementId: string;
                    readonly modelRoomElementOptionId: string;
                  } | null;
                  readonly breakdownLines: ReadonlyArray<{
                    readonly __typename: "SectionElementOptionBreakdownLine";
                    readonly activityId: string;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                  }>;
                }
              | {
                  readonly __typename: "SectionElementOptionSet";
                  readonly productCategoryId: string;
                  readonly minIndex: number;
                  readonly maxIndex: number;
                  readonly options: ReadonlyArray<{
                    readonly __typename: "SectionElementOption";
                    readonly id: string;
                    readonly index: number;
                    readonly type: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly isUnlocked: boolean;
                    readonly productId: string;
                    readonly modelLink: {
                      readonly __typename: "SectionElementOptionModelLink";
                      readonly modelId: string;
                      readonly modelRoomId: string;
                      readonly modelRoomElementId: string;
                      readonly modelRoomElementOptionId: string;
                    } | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "SectionElementOptionBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }>;
                }
            >;
          }
        | {
            readonly __typename: "SectionElementSet";
            readonly sectionElementGroupId: string;
            readonly minIndex: number;
            readonly maxIndex: number;
            readonly elements: ReadonlyArray<{
              readonly __typename: "SectionElement";
              readonly id: string;
              readonly createdAt: Date;
              readonly updatedAt: Date;
              readonly index: number;
              readonly type: SectionElementType;
              readonly label: string;
              readonly notes: string | null;
              readonly isOptional: boolean;
              readonly isManagedByVibes: boolean;
              readonly hideProductCategory: boolean;
              readonly groupId: string | null;
              readonly modelLink: {
                readonly __typename: "SectionElementModelLink";
                readonly modelId: string;
                readonly modelRoomId: string;
                readonly modelRoomElementId: string;
              } | null;
              readonly options: ReadonlyArray<
                | {
                    readonly __typename: "SectionElementOption";
                    readonly id: string;
                    readonly index: number;
                    readonly type: SectionElementOptionType;
                    readonly subcontractorAmount: number;
                    readonly contractorAmount: number;
                    readonly promoterAmount: number;
                    readonly costAmount: number;
                    readonly clientAmount: number;
                    readonly contingencyAmount: number;
                    readonly isUnlocked: boolean;
                    readonly productId: string;
                    readonly modelLink: {
                      readonly __typename: "SectionElementOptionModelLink";
                      readonly modelId: string;
                      readonly modelRoomId: string;
                      readonly modelRoomElementId: string;
                      readonly modelRoomElementOptionId: string;
                    } | null;
                    readonly breakdownLines: ReadonlyArray<{
                      readonly __typename: "SectionElementOptionBreakdownLine";
                      readonly activityId: string;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                    }>;
                  }
                | {
                    readonly __typename: "SectionElementOptionSet";
                    readonly productCategoryId: string;
                    readonly minIndex: number;
                    readonly maxIndex: number;
                    readonly options: ReadonlyArray<{
                      readonly __typename: "SectionElementOption";
                      readonly id: string;
                      readonly index: number;
                      readonly type: SectionElementOptionType;
                      readonly subcontractorAmount: number;
                      readonly contractorAmount: number;
                      readonly promoterAmount: number;
                      readonly costAmount: number;
                      readonly clientAmount: number;
                      readonly contingencyAmount: number;
                      readonly isUnlocked: boolean;
                      readonly productId: string;
                      readonly modelLink: {
                        readonly __typename: "SectionElementOptionModelLink";
                        readonly modelId: string;
                        readonly modelRoomId: string;
                        readonly modelRoomElementId: string;
                        readonly modelRoomElementOptionId: string;
                      } | null;
                      readonly breakdownLines: ReadonlyArray<{
                        readonly __typename: "SectionElementOptionBreakdownLine";
                        readonly activityId: string;
                        readonly subcontractorAmount: number;
                        readonly contractorAmount: number;
                        readonly promoterAmount: number;
                        readonly costAmount: number;
                      }>;
                    }>;
                  }
              >;
            }>;
          }
      >;
      readonly elementGroups: ReadonlyArray<{
        readonly __typename: "SectionElementGroup";
        readonly id: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly index: number;
        readonly name: string;
      }>;
      readonly vibes: ReadonlyArray<{
        readonly __typename: "SectionVibe";
        readonly id: string;
        readonly index: number;
        readonly name: string;
        readonly mode: SectionVibeMode;
        readonly subcontractorAmount: number;
        readonly contractorAmount: number;
        readonly promoterAmount: number;
        readonly costAmount: number;
        readonly clientAmount: number;
        readonly contingencyAmount: number;
        readonly items: ReadonlyArray<{
          readonly __typename: "SectionVibeItem";
          readonly id: string;
          readonly index: number;
          readonly subcontractorAmount: number;
          readonly contractorAmount: number;
          readonly promoterAmount: number;
          readonly costAmount: number;
          readonly sectionElementId: string;
          readonly sectionElementOptionId: string;
        }>;
      }>;
    }>;
    readonly units: ReadonlyArray<{
      readonly __typename: "Unit";
      readonly id: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly state: UnitState;
      readonly number: string;
      readonly floor: number;
      readonly numberOfBathrooms: number;
      readonly numberOfBedrooms: number;
      readonly label: string;
      readonly formId: string | null;
      readonly currentContractId: string | null;
    }>;
    readonly contactInfo: {
      readonly __typename: "ProjectContactInfo";
      readonly companyName: string;
      readonly email: string;
      readonly phone: string;
      readonly websiteUrl: string;
      readonly federalTaxNumber: string | null;
      readonly provincialTaxNumber: string | null;
      readonly address: {
        readonly __typename: "Address";
        readonly line: string;
        readonly city: string;
        readonly subdivisionCode: string;
        readonly countryCode: string;
        readonly postalCode: string;
      };
    };
  };
};

export type GetReportSummaryQueryVariables = Exact<{
  tenantId: Scalars["ID"]["input"];
  projectId: Scalars["ID"]["input"];
  reportId: Scalars["ID"]["input"];
  unitFloor: InputMaybe<Scalars["Int"]["input"]>;
  activityId: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type GetReportSummaryQuery = {
  readonly __typename: "Query";
  readonly report: {
    readonly __typename: "SummaryReport";
    readonly vibeColumns: ReadonlyArray<{
      readonly __typename: "SummaryReportVibeColumn";
      readonly index: number;
      readonly name: string;
      readonly reportRoomId: string;
    }>;
    readonly baseActivityColumnGroups: ReadonlyArray<{
      readonly __typename: "SummaryReportActivityColumnGroup";
      readonly index: number;
      readonly name: string;
      readonly colspan: number;
      readonly activityId: string;
      readonly roomColumnGroups: ReadonlyArray<{
        readonly __typename: "SummaryReportRoomColumnGroup";
        readonly index: number;
        readonly name: string;
        readonly colspan: number;
        readonly reportRoomId: string;
        readonly elementColumns: ReadonlyArray<{
          readonly __typename: "SummaryReportElementColumn";
          readonly index: number;
          readonly name: string;
          readonly reportRoomElementId: string;
        }>;
      }>;
    }>;
    readonly extraActivityColumnGroups: ReadonlyArray<{
      readonly __typename: "SummaryReportActivityColumnGroup";
      readonly index: number;
      readonly name: string;
      readonly colspan: number;
      readonly activityId: string;
      readonly roomColumnGroups: ReadonlyArray<{
        readonly __typename: "SummaryReportRoomColumnGroup";
        readonly index: number;
        readonly name: string;
        readonly colspan: number;
        readonly reportRoomId: string;
        readonly elementColumns: ReadonlyArray<{
          readonly __typename: "SummaryReportElementColumn";
          readonly index: number;
          readonly name: string;
          readonly reportRoomElementId: string;
        }>;
      }>;
    }>;
    readonly rows: ReadonlyArray<{
      readonly __typename: "SummaryReportRow";
      readonly unitNumber: string;
      readonly unitFloor: number;
      readonly unitNumberOfBathrooms: number;
      readonly unitNumberOfBedrooms: number;
      readonly unitState: UnitState;
      readonly numberOfExtras: number;
      readonly customerInfos: ReadonlyArray<{
        readonly __typename: "ReportCustomerInfo";
        readonly customerId: string;
        readonly index: number;
        readonly fullName: string;
        readonly phone: string;
        readonly email: string;
      }>;
      readonly contractSelectionInfos: ReadonlyArray<{
        readonly __typename: "ReportContractSelectionInfo";
        readonly contractSelectionId: string;
        readonly revisionNumber: number;
        readonly name: string;
        readonly date: Date;
      }>;
      readonly cells: ReadonlyArray<{
        readonly __typename: "SummaryReportCell";
        readonly index: number;
        readonly type: ReportCellType;
        readonly value: string;
        readonly metadata: {
          readonly __typename: "SummaryReportCellMetadata";
          readonly activityTitle: string;
          readonly reportRoomName: string;
          readonly reportRoomElementName: string;
          readonly providerName: string;
          readonly productModel: string;
          readonly productCode: string;
          readonly productFormat: string;
          readonly comments: string;
        } | null;
      }>;
    }>;
  };
};

export type GetReportBillingQueryVariables = Exact<{
  tenantId: Scalars["ID"]["input"];
  projectId: Scalars["ID"]["input"];
  unitIds: ReadonlyArray<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
}>;

export type GetReportBillingQuery = {
  readonly __typename: "Query";
  readonly report: {
    readonly __typename: "BillingReport";
    readonly subcontractorAmount: number;
    readonly clientAmount: number;
    readonly contractorAmount: number;
    readonly promoterAmount: number;
    readonly contingencyAmount: number;
    readonly tables: ReadonlyArray<{
      readonly __typename: "BillingReportTable";
      readonly contractId: string;
      readonly revisionNumber: number;
      readonly isRevisionPending: boolean;
      readonly unitNumber: string;
      readonly clientAmount: number;
      readonly subcontractorAmount: number;
      readonly contractorAmount: number;
      readonly promoterAmount: number;
      readonly contingencyAmount: number;
      readonly rows: ReadonlyArray<{
        readonly __typename: "BillingReportTableRow";
        readonly type: BillingReportTableRowType;
        readonly elementFullName: string;
        readonly providerName: string | null;
        readonly clientAmount: number;
        readonly subcontractorAmount: number;
        readonly contractorAmount: number;
        readonly promoterAmount: number;
        readonly contingencyAmount: number;
      }>;
    }>;
  };
};

export type GetReportConstructionQueryVariables = Exact<{
  tenantId: Scalars["ID"]["input"];
  projectId: Scalars["ID"]["input"];
  reportId: Scalars["ID"]["input"];
  unitId: Scalars["ID"]["input"];
}>;

export type GetReportConstructionQuery = {
  readonly __typename: "Query";
  readonly report: {
    readonly __typename: "ConstructionReport";
    readonly unitNumber: string;
    readonly unitFloor: number;
    readonly unitNumberOfBathrooms: number;
    readonly unitNumberOfBedrooms: number;
    readonly unitState: UnitState;
    readonly numberOfExtras: number;
    readonly customerInfos: ReadonlyArray<{
      readonly __typename: "ReportCustomerInfo";
      readonly customerId: string;
      readonly index: number;
      readonly fullName: string;
      readonly phone: string;
      readonly email: string;
    }>;
    readonly contractSelectionInfos: ReadonlyArray<{
      readonly __typename: "ReportContractSelectionInfo";
      readonly contractSelectionId: string;
      readonly revisionNumber: number;
      readonly name: string;
      readonly date: Date;
    }>;
    readonly vibeTableRows: ReadonlyArray<{
      readonly __typename: "ReportVibeTableRow";
      readonly index: number;
      readonly name: string;
      readonly value: string;
      readonly type: ReportCellType;
    }>;
    readonly baseTables: ReadonlyArray<{
      readonly __typename: "ConstructionReportTable";
      readonly index: number;
      readonly activityTitle: string;
      readonly rows: ReadonlyArray<{
        readonly __typename: "ConstructionReportTableRow";
        readonly index: number;
        readonly roomName: string;
        readonly elementName: string;
        readonly type: ReportCellType;
        readonly value: string;
        readonly providerName: string;
        readonly productModel: string;
        readonly productCode: string;
        readonly productFormat: string;
        readonly comments: string;
      }>;
    }>;
    readonly extraTables: ReadonlyArray<{
      readonly __typename: "ConstructionReportTable";
      readonly index: number;
      readonly activityTitle: string;
      readonly rows: ReadonlyArray<{
        readonly __typename: "ConstructionReportTableRow";
        readonly index: number;
        readonly roomName: string;
        readonly elementName: string;
        readonly type: ReportCellType;
        readonly value: string;
        readonly providerName: string;
        readonly productModel: string;
        readonly productCode: string;
        readonly productFormat: string;
        readonly comments: string;
      }>;
    }>;
  };
};

export type GetReportCustomerQueryVariables = Exact<{
  tenantId: Scalars["ID"]["input"];
  projectId: Scalars["ID"]["input"];
  reportId: Scalars["ID"]["input"];
  unitId: Scalars["ID"]["input"];
}>;

export type GetReportCustomerQuery = {
  readonly __typename: "Query";
  readonly report: {
    readonly __typename: "CustomerReport";
    readonly unitNumber: string;
    readonly unitFloor: number;
    readonly unitNumberOfBathrooms: number;
    readonly unitNumberOfBedrooms: number;
    readonly unitState: UnitState;
    readonly numberOfExtras: number;
    readonly customerInfos: ReadonlyArray<{
      readonly __typename: "ReportCustomerInfo";
      readonly customerId: string;
      readonly index: number;
      readonly fullName: string;
      readonly phone: string;
      readonly email: string;
    }>;
    readonly contractSelectionInfos: ReadonlyArray<{
      readonly __typename: "ReportContractSelectionInfo";
      readonly contractSelectionId: string;
      readonly revisionNumber: number;
      readonly name: string;
      readonly date: Date;
    }>;
    readonly vibeTableRows: ReadonlyArray<{
      readonly __typename: "ReportVibeTableRow";
      readonly index: number;
      readonly name: string;
      readonly value: string;
      readonly type: ReportCellType;
    }>;
    readonly baseTables: ReadonlyArray<{
      readonly __typename: "CustomerReportTable";
      readonly index: number;
      readonly activityTitle: string;
      readonly rows: ReadonlyArray<{
        readonly __typename: "CustomerReportTableRow";
        readonly index: number;
        readonly roomName: string;
        readonly elementName: string;
        readonly type: ReportCellType;
        readonly value: string;
        readonly comments: string;
      }>;
    }>;
    readonly extraTables: ReadonlyArray<{
      readonly __typename: "CustomerReportTable";
      readonly index: number;
      readonly activityTitle: string;
      readonly rows: ReadonlyArray<{
        readonly __typename: "CustomerReportTableRow";
        readonly index: number;
        readonly roomName: string;
        readonly elementName: string;
        readonly type: ReportCellType;
        readonly value: string;
        readonly comments: string;
      }>;
    }>;
  };
};

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserInfoQuery = {
  readonly __typename: "Query";
  readonly userInfo: {
    readonly __typename: "UserInfo";
    readonly userId: string;
    readonly email: string;
    readonly isSuperAdmin: boolean;
    readonly tenants: ReadonlyArray<{
      readonly __typename: "UserInfoTenant";
      readonly id: string;
      readonly testId: string;
      readonly isProfileManager: boolean;
      readonly isProjectManager: boolean;
      readonly isSecurityManager: boolean;
      readonly isAccountingManager: boolean;
      readonly isBlocked: boolean;
      readonly hasAccessToAllProjects: boolean;
      readonly hasAccessToTestTenant: boolean;
      readonly permissions: ReadonlyArray<PermissionName>;
      readonly projectIds: ReadonlyArray<string>;
    }>;
  };
};
