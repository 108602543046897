import "./index.scss";
import { FunctionComponent, ReactNode, useState } from "react";
import { ViewDetail } from "buildingBlocks";
import { useFile, useProjectContractContext, useRouting } from "hooks";
import { ContractCancelDialog, ContractDeleteDialog } from "dialogs";
import { ContractState, PermissionName, ContractSelectionGroupState, ContractSelectionState } from "graphql/schema";
import { useProjectContext, useTenantContext } from "providers";
import { ContractRefundCreateModal, ContractRevisionCreateModal, ContractSelectionCreateModal } from "modals";
import { ContractCard, CustomerCard, UnitCard } from "components";
import { useDownloadContractMutation } from "graphql/mutations";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

export const ProjectContractView: FunctionComponent = () => {
  const {
    navigate,
    matchPath,
    toProjectView,
    toProjectUnitUpdateView,
    toProjectContractsView,
    toProjectContractSelectionsView,
    toProjectContractInvoicesView,
    toProjectContractPaymentsView,
    toProjectContractRefundsView,
    toProjectContractSelectionGroupUpdateView,
  } = useRouting();
  const { tenant, taxGroups } = useTenantContext();
  const { project, refunds } = useProjectContext();
  const { contract } = useProjectContractContext();
  const { open } = useFile();
  const { downloadContract, downloadContractLoading } = useDownloadContractMutation(({ documentUrl }) => {
    open(documentUrl);
  });
  const [contractToCreateSelection, setContractToCreateSelection] = useState<ProjectProviderTypes.MappedProjectContract | null>(null);
  const [contractToCreateRevision, setContractToCreateRevision] = useState<ProjectProviderTypes.MappedProjectContract | null>(null);
  const [contractToCreateRefund, setContractToCreateRefund] = useState<ProjectProviderTypes.MappedProjectContract | null>(null);
  const [contractToCancel, setContractToCancel] = useState<ProjectProviderTypes.MappedProjectContract | null>(null);
  const [contractToDelete, setContractToDelete] = useState<ProjectProviderTypes.MappedProjectContract | null>(null);
  const primaryCustomer = contract.customers.find((contractCustomer) => contractCustomer.index === 0)!.customer;
  const secondaryCustomer = contract.customers.find((contractCustomer) => contractCustomer.index === 1)?.customer ?? null;
  const cards: ReactNode[] = [<ContractCard contract={contract} />, <UnitCard unit={contract.unit} />, <CustomerCard customer={primaryCustomer} primary />];
  if (secondaryCustomer) {
    cards.push(<CustomerCard customer={secondaryCustomer} />);
  }
  return (
    <ViewDetail
      id="project-contract-view"
      security={{
        permissions: [[PermissionName.CONTRACT_READ]],
      }}
      header={{
        title: `${contract.unit.label}${contract.isCanceled ? " (ANNULÉ)" : ""}`,
        subtitle: "Contrat",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              text: "Créer une sélection",
              show:
                !matchPath(
                  toProjectContractSelectionsView({
                    tenantId: null,
                    projectId: null,
                    contractId: null,
                  })
                ) &&
                !contract.isCanceled &&
                contract.state !== ContractState.IS_CUSTOMIZED &&
                contract.state === ContractState.WITHOUT_SELECTION &&
                contract.form !== null &&
                contract.selections.length === 0,
              security: {
                permissions: [[PermissionName.CONTRACT_SELECTION_CREATE]],
              },
              onClick: () => {
                setContractToCreateSelection(contract);
              },
            },
            {
              __type: "Button",
              text: "Créer une révision",
              show:
                !contract.isCanceled &&
                contract.state !== ContractState.IS_CUSTOMIZED &&
                contract.selections.length !== 0 &&
                contract.selections.every((contractSelection) => contractSelection.state === ContractSelectionState.FINISHED) &&
                contract.currentSelection !== null &&
                contract.currentSelection.state === ContractSelectionState.FINISHED &&
                contract.currentSelection.revisionNumber < 3,
              security: {
                permissions: [[PermissionName.CONTRACT_SELECTION_CREATE]],
              },
              onClick: () => {
                setContractToCreateRevision(contract);
              },
            },
            {
              __type: "Button",
              text: "Créer un remboursement",
              show: contract.isCanceled && contract.balanceAmount < 0,
              security: {
                permissions: [[PermissionName.CONTRACT_REFUND_CREATE]],
              },
              onClick: () => {
                setContractToCreateRefund(contract);
              },
            },
            {
              __type: "Button",
              text: "Commencer la sélection",
              show:
                contract.currentSelection !== null &&
                contract.currentSelection.state === ContractSelectionState.PENDING &&
                contract.currentSelection.groups.every((contractSelectionGroup) => contractSelectionGroup.state === ContractSelectionGroupState.PENDING),
              security: {
                permissions: [[PermissionName.CONTRACT_SELECTION_CONFIRM]],
              },
              onClick: () => {
                const contractSelection = contract.currentSelection;
                if (contractSelection) {
                  navigate(
                    toProjectContractSelectionGroupUpdateView({
                      tenantId: tenant.id,
                      projectId: project.id,
                      contractId: contract.id,
                      contractSelectionId: contractSelection.id,
                      contractSelectionGroupId: contractSelection.groups[0].id,
                    })
                  );
                }
              },
            },
            {
              __type: "Button",
              text: "Continuer la sélection",
              show:
                contract.currentSelection !== null &&
                contract.currentSelection.state === ContractSelectionState.PENDING &&
                !contract.currentSelection.groups.every((contractSelectionGroup) => contractSelectionGroup.state === ContractSelectionGroupState.PENDING),
              security: {
                permissions: [[PermissionName.CONTRACT_SELECTION_CONFIRM]],
              },
              onClick: () => {
                const contractSelection = contract.currentSelection;
                if (contractSelection) {
                  navigate(
                    toProjectContractSelectionGroupUpdateView({
                      tenantId: tenant.id,
                      projectId: project.id,
                      contractId: contract.id,
                      contractSelectionId: contractSelection.id,
                      contractSelectionGroupId:
                        contractSelection.groups.filter((contractSelectionGroup) => contractSelectionGroup.state === ContractSelectionGroupState.PENDING)[0]?.id ?? contractSelection.groups[0].id,
                    })
                  );
                }
              },
            },
          ],
          otherActions: [
            {
              __type: "Button",
              content: "Télécharger le contrat",
              disabled: downloadContractLoading,
              show: true,
              security: {
                permissions: [[PermissionName.CONTRACT_DOWNLOAD]],
              },
              onClick: async () => {
                await downloadContract({
                  tenantId: tenant.id,
                  projectId: project.id,
                  contractId: contract.id,
                });
              },
            },
            {
              __type: "Button",
              content: "Modifier l'unité",
              show: !contract.isCanceled,
              security: {
                permissions: [[PermissionName.UNIT_READ, PermissionName.UNIT_UPDATE]],
              },
              onClick: () => {
                navigate(
                  toProjectUnitUpdateView({
                    tenantId: tenant.id,
                    projectId: project.id,
                    unitId: contract.unitId,
                  })
                );
              },
            },
            {
              __type: "Button",
              content: "Annuler le contrat",
              show: !contract.isCanceled,
              security: {
                permissions: [[PermissionName.CONTRACT_CANCEL]],
              },
              onClick: () => {
                setContractToCancel(contract);
              },
            },
            {
              __type: "Button",
              content: "Supprimer le contrat",
              show: !contract.isCanceled && (contract.selections.length === 0 || contract.selections.every((contractSelection) => contractSelection.state === ContractSelectionState.PENDING)),
              security: {
                permissions: [[PermissionName.CONTRACT_DELETE]],
              },
              onClick: () => {
                setContractToDelete(contract);
              },
            },
          ],
        },
        overview: {
          cards: cards,
        },
        tabs: [
          {
            name: "Sélections",
            active: matchPath(
              toProjectContractSelectionsView({
                tenantId: null,
                projectId: null,
                contractId: null,
              })
            ),
            show: contract.state !== ContractState.IS_CUSTOMIZED,
            security: {},
            onClick: () => {
              navigate(
                toProjectContractSelectionsView({
                  tenantId: tenant.id,
                  projectId: project.id,
                  contractId: contract.id,
                })
              );
            },
          },
          {
            name: "Factures",
            active: matchPath(
              toProjectContractInvoicesView({
                tenantId: null,
                projectId: null,
                contractId: null,
              })
            ),
            show: true,
            security: {},
            onClick: () => {
              navigate(
                toProjectContractInvoicesView({
                  tenantId: tenant.id,
                  projectId: project.id,
                  contractId: contract.id,
                })
              );
            },
          },
          {
            name: "Paiements",
            active: matchPath(
              toProjectContractPaymentsView({
                tenantId: null,
                projectId: null,
                contractId: null,
              })
            ),
            show: true,
            security: {},
            onClick: () => {
              navigate(
                toProjectContractPaymentsView({
                  tenantId: tenant.id,
                  projectId: project.id,
                  contractId: contract.id,
                })
              );
            },
          },
          {
            name: "Remboursements",
            active: matchPath(
              toProjectContractRefundsView({
                tenantId: null,
                projectId: null,
                contractId: null,
              })
            ),
            show: true,
            security: {},
            onClick: () => {
              navigate(
                toProjectContractRefundsView({
                  tenantId: tenant.id,
                  projectId: project.id,
                  contractId: contract.id,
                })
              );
            },
          },
        ],
      }}
    >
      {contractToCancel && (
        <ContractCancelDialog
          tenantId={tenant.id}
          projectId={project.id}
          contract={contractToCancel}
          onCompleted={() => {
            setContractToCancel(null);
          }}
          onClose={() => {
            setContractToCancel(null);
          }}
        />
      )}
      {contractToDelete && (
        <ContractDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          contract={contractToDelete}
          onCompleted={() => {
            navigate(
              toProjectContractsView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          }}
          onClose={() => {
            setContractToDelete(null);
          }}
        />
      )}
      {contractToCreateSelection && (
        <ContractSelectionCreateModal
          tenantId={tenant.id}
          projectId={project.id}
          contract={contractToCreateSelection}
          taxGroups={taxGroups}
          onCompleted={() => {
            setContractToCreateSelection(null);
          }}
          onCancel={() => {
            setContractToCreateSelection(null);
          }}
        />
      )}
      {contractToCreateRevision && (
        <ContractRevisionCreateModal
          tenantId={tenant.id}
          projectId={project.id}
          contract={contractToCreateRevision}
          taxGroups={taxGroups}
          onCompleted={() => {
            setContractToCreateRevision(null);
          }}
          onCancel={() => {
            setContractToCreateRevision(null);
          }}
        />
      )}
      {contractToCreateRefund && (
        <ContractRefundCreateModal
          tenantId={tenant.id}
          projectId={project.id}
          contract={contractToCreateRefund}
          refunds={refunds}
          onCompleted={() => {
            setContractToCreateRefund(null);
          }}
          onCancel={() => {
            setContractToCreateRefund(null);
          }}
        />
      )}
    </ViewDetail>
  );
};
