import { FunctionComponent } from "react";
import { ViewForm } from "buildingBlocks";
import { useRouting, useTenantProviderContext } from "hooks";
import { useUpdateProviderMutation } from "graphql/mutations";
import { PermissionName } from "graphql/schema";
import { ProviderForm, useProviderForm } from "forms";
import { useTenantContext } from "providers";

export const ProviderUpdateView: FunctionComponent = () => {
  const { navigate, toTenantProviderView } = useRouting();
  const { tenant } = useTenantContext();
  const { provider } = useTenantProviderContext();
  const { values, setValues } = useProviderForm({
    name: provider.name,
    description: provider.description ?? "",
    websiteUrl: provider.websiteUrl ?? "",
  });
  const { updateProvider, updateProviderLoading, updateProviderErrors } = useUpdateProviderMutation(() => {
    navigate(
      toTenantProviderView({
        tenantId: tenant.id,
        providerId: provider.id,
      })
    );
  });
  return (
    <ViewForm
      id="provider-update-view"
      title="Modifier le fournisseur"
      security={{
        permissions: [[PermissionName.PROVIDER_READ, PermissionName.PROVIDER_UPDATE]],
      }}
      errors={updateProviderErrors}
      loading={updateProviderLoading}
      onSubmit={async () => {
        await updateProvider({
          tenantId: tenant.id,
          providerId: provider.id,
          name: values.name,
          description: values.description || null,
          websiteUrl: values.websiteUrl || null,
        });
      }}
      onCancel={() => {
        navigate(
          toTenantProviderView({
            tenantId: tenant.id,
            providerId: provider.id,
          })
        );
      }}
    >
      <ProviderForm values={values} setValues={setValues} />
    </ViewForm>
  );
};
