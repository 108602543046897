import "./index.scss";
import { FunctionComponent } from "react";
import { Message } from "rsuite";

type Props = {
  readonly errors: string[];
};

export const FormErrors: FunctionComponent<Props> = ({ errors }) => {
  if (errors.length !== 0) {
    return (
      <Message className="form-errors" showIcon type="error">
        <ul>
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      </Message>
    );
  }
  return null;
};
