import "./index.scss";
import { FunctionComponent, PropsWithChildren } from "react";

type Props = PropsWithChildren & {
  readonly className?: string;
  readonly border?: boolean;
  readonly fluid?: boolean;
  readonly size?: "md" | "sm";
};

export const Table: FunctionComponent<Props> = ({ className, children, fluid = false, border = false, size = "md" }) => (
  <div className={`${className ? `${className} ` : ""}table${border ? " table-border" : ""}${fluid ? " table-fluid" : ""}${size ? ` table-${size}` : ""}`}>
    {children}
  </div>
);
