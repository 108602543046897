import { createContext, FunctionComponent, useContext } from "react";
import { Outlet } from "react-router-dom";

type SettingsContextValue = {};

const initialValue: SettingsContextValue = {};

const SettingsContext = createContext<SettingsContextValue>(initialValue);

const useSettingsContext = () => useContext(SettingsContext);

const SettingsProvider: FunctionComponent = () => {
  // const { navigate, toNotFoundPage } = useRouting();
  // const { tenant } = useTenantContext();
  // useEffect(() => {
  //   if (tenant.type === TenantType.TEST) {
  //     navigate(toNotFoundPage());
  //   }
  // }, [navigate, tenant, toNotFoundPage]);
  return (
    <SettingsContext.Provider value={{}}>
      <Outlet />
    </SettingsContext.Provider>
  );
};

export { SettingsProvider, useSettingsContext };
