import { createContext, FunctionComponent, useContext } from "react";
import { Outlet } from "react-router-dom";

type ContractContextValue = {};

const initialValue: ContractContextValue = {};

const ContractContext = createContext<ContractContextValue>(initialValue);

const useContractContext = () => useContext(ContractContext);

const ContractProvider: FunctionComponent = () => {
  return (
    <ContractContext.Provider value={{}}>
      <Outlet />
    </ContractContext.Provider>
  );
};

export { ContractProvider, useContractContext };
