import "./index.scss";
import { SectionElementType } from "graphql/schema";
import { FunctionComponent, useState } from "react";
import { Form, SelectPicker } from "rsuite";
import { FormContainer, getEnumLabel } from "buildingBlocks";
import produce from "immer";

export type SectionElementAddFormValues = {
  readonly type: SectionElementType;
};

type Props = {
  readonly values: SectionElementAddFormValues;
  readonly setValues: (values: SectionElementAddFormValues) => void;
};

export const useSectionElementAddForm = (initialValues: SectionElementAddFormValues) => {
  const [values, setValues] = useState<SectionElementAddFormValues>(initialValues);
  return { values, setValues };
};

export const SectionElementAddForm: FunctionComponent<Props> = ({ values, setValues }) => (
  <Form id="section-element-add-form">
    <FormContainer highlight withoutBorder>
      <Form.Group>
        <Form.ControlLabel>Type</Form.ControlLabel>
        <SelectPicker
          value={values.type}
          cleanable={false}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.type = value!;
              })
            );
          }}
          data={[
            { label: getEnumLabel(SectionElementType.READONLY), value: SectionElementType.READONLY },
            { label: getEnumLabel(SectionElementType.BOOLEAN), value: SectionElementType.BOOLEAN },
            { label: getEnumLabel(SectionElementType.UNIQUE), value: SectionElementType.UNIQUE },
          ]}
        />
      </Form.Group>
    </FormContainer>
  </Form>
);
