import "./index.scss";
import { useDeleteActivityMutation } from "graphql/mutations";
import { FunctionComponent, useState } from "react";
import { Dialog, DialogBodyDelete, DialogFooterDelete } from "buildingBlocks";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly activity: ProjectProviderTypes.MappedProjectActivity;
  readonly onCompleted: () => void;
  readonly onClose: () => void;
};

export const ActivityDeleteDialog: FunctionComponent<Props> = ({ tenantId, projectId, activity, onCompleted, onClose }) => {
  const [value, setValue] = useState<string>("");
  const { deleteActivity, deleteActivityLoading } = useDeleteActivityMutation(onCompleted, onClose, true);
  return (
    <Dialog id="activity-delete-dialog" title="Supprimer l'activité">
      <DialogBodyDelete objectName={activity.label} dictionaryList={[]} value={value} setValue={setValue} />
      <DialogFooterDelete
        disabled={activity.label !== value}
        loading={deleteActivityLoading}
        onClose={onClose}
        onClick={async () => {
          await deleteActivity({
            tenantId: tenantId,
            projectId: projectId,
            activityId: activity.id,
          });
        }}
      />
    </Dialog>
  );
};
