import { FunctionComponent } from "react";
import { useRouting } from "hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext, useSecurityContext, useTenantContext } from "providers";
import { Layout } from "buildingBlocks/Layout";
import { PermissionName } from "graphql/schema";

export const TenantLayout: FunctionComponent = () => {
  const { navigate, matchPath, toProjectsView, toProjectView, toTenantsView, toTenantView, toTenantCustomersView, toTenantCustomerView, toTenantProvidersView, toTenantProviderView } = useRouting();
  const { tenants } = useAppContext();
  const { tenant, projects } = useTenantContext();
  const { logout } = useAuth0();
  const { hasTenant } = useSecurityContext();
  return (
    <Layout
      onHeaderClick={() => {
        navigate(toTenantsView());
      }}
      navs={[
        {
          title: "Organisation",
          items: [
            {
              __type: "NavItem",
              name: "Projets",
              disabled: false,
              show: true,
              active:
                matchPath(
                  toProjectsView({
                    tenantId: null,
                  })
                ) ||
                matchPath(
                  toTenantView({
                    tenantId: null,
                  })
                ),
              security: {},
              onClick: () => {
                navigate(
                  toProjectsView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
          ],
        },
        {
          items: [
            {
              __type: "NavItem",
              name: "Clients",
              disabled: false,
              show: true,
              active:
                matchPath(
                  toTenantCustomersView({
                    tenantId: null,
                  })
                ) ||
                matchPath(
                  toTenantCustomerView({
                    tenantId: null,
                    customerId: null,
                  })
                ),
              security: {
                permissions: [[PermissionName.CUSTOMER_READ]],
              },
              onClick: () => {
                navigate(
                  toTenantCustomersView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
            {
              __type: "NavItem",
              name: "Fournisseurs",
              disabled: false,
              show: true,
              active:
                matchPath(
                  toTenantProvidersView({
                    tenantId: null,
                  })
                ) ||
                matchPath(
                  toTenantProviderView({
                    tenantId: null,
                    providerId: null,
                  })
                ),
              security: {
                permissions: [[PermissionName.PROVIDER_READ]],
              },
              onClick: () => {
                navigate(
                  toTenantProvidersView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
          ],
        },
      ]}
      tenant={tenant}
      headerNav={{
        tenants: tenants.filter((tenant) =>
          hasTenant({
            tenantId: tenant.id,
          })
        ),
        onTenantChange: (tenantId) => {
          navigate(
            toTenantView({
              tenantId: tenantId,
            })
          );
        },
        onTenantClean: () => {
          navigate(toTenantsView());
        },
        projectId: null,
        projects: projects,
        onProjectChange: (projectId) => {
          navigate(
            toProjectView({
              tenantId: tenant.id,
              projectId: projectId,
            })
          );
        },
        onProjectClean: () => {
          navigate(
            toProjectsView({
              tenantId: tenant.id,
            })
          );
        },
      }}
      onLogout={() => {
        logout();
      }}
    />
  );
};
