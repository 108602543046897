import "./index.scss";
import { FunctionComponent, useState } from "react";
import { Dialog, DialogBodyDelete, DialogFooterDelete } from "buildingBlocks";
import { useDeleteSectionVibeMutation } from "graphql/mutations";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly section: ProjectProviderTypes.MappedProjectSection;
  readonly sectionVibe: ProjectProviderTypes.MappedProjectSectionVibe;
  readonly onCompleted: () => void;
  readonly onClose: () => void;
};

export const SectionVibeDeleteDialog: FunctionComponent<Props> = ({ tenantId, projectId, section, sectionVibe, onCompleted, onClose }) => {
  const [value, setValue] = useState<string>("");
  const { deleteSectionVibe, deleteSectionVibeLoading } = useDeleteSectionVibeMutation(onCompleted, onClose, true);
  return (
    <Dialog id="section-vibe-delete-dialog" title="Supprimer l'ambiance">
      <DialogBodyDelete objectName={sectionVibe.name} dictionaryList={[]} value={value} setValue={setValue} />
      <DialogFooterDelete
        disabled={sectionVibe.name !== value}
        loading={deleteSectionVibeLoading}
        onClose={onClose}
        onClick={async () => {
          await deleteSectionVibe({
            tenantId: tenantId,
            projectId: projectId,
            sectionId: section.id,
            sectionVibeId: sectionVibe.id,
          });
        }}
      />
    </Dialog>
  );
};
