import { useCallback } from "react";
import { useLocation, useNavigate, useSearchParams, matchPath as routeMatchPath } from "react-router-dom";

export const useRouting = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const matchPath = useCallback(
    (pathname: string) => {
      return !!routeMatchPath(pathname, location.pathname);
    },
    [location]
  );
  const toUnauthorizedPage = () => {
    return `/unauthorized`;
  };
  const toForbiddenPage = () => {
    return `/forbidden`;
  };
  const toErrorPage = () => {
    return `/error`;
  };
  const toNotFoundPage = () => {
    return `/not-found`;
  };

  // Tenant
  const toTenantsView = (children: boolean = false) => {
    if (children) {
      return `tenants`;
    }
    return `/tenants`;
  };
  const toTenantView = (params: { tenantId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}`;
    }
    return `tenants/:tenantId`;
  };

  // Customer
  const toTenantCustomersView = (params: { tenantId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/customers`;
    }
    return `customers`;
  };
  const toTenantCustomerCreateView = (params: { tenantId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/customers/create`;
    }
    return `customers/create`;
  };
  const toTenantCustomerView = (params: { tenantId: string | null; customerId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/customers/${params.customerId ?? ":customerId"}`;
    }
    return `customers/:customerId`;
  };
  const toTenantCustomerUpdateView = (params: { tenantId: string | null; customerId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/customers/${params.customerId ?? ":customerId"}/update`;
    }
    return `customers/:customerId/update`;
  };
  const toTenantCustomerContractsView = (params: { tenantId: string | null; customerId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/customers/${params.customerId ?? ":customerId"}/contracts`;
    }
    return `contracts`;
  };

  // Settings
  const toTenantSettingsView = (params: { tenantId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/settings`;
    }
    return `settings`;
  };
  const toTenantSettingsProfileView = (params: { tenantId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/settings/profile`;
    }
    return `profile`;
  };
  const toTenantSettingsTaxesView = (params: { tenantId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/settings/taxes`;
    }
    return `taxes`;
  };
  const toTenantSettingsTaxGroupsView = (params: { tenantId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/settings/tax-groups`;
    }
    return `tax-groups`;
  };
  const toTenantSettingsTaxAgenciesView = (params: { tenantId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/settings/tax-agencies`;
    }
    return `tax-agencies`;
  };
  const toTenantSettingsPermissionsView = (params: { tenantId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/settings/permissions`;
    }
    return `permissions`;
  };
  const toTenantSettingsUsersView = (params: { tenantId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/settings/users`;
    }
    return `users`;
  };
  const toTenantSettingsUserCreateView = (params: { tenantId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/settings/users/create`;
    }
    return `users/create`;
  };
  const toTenantSettingsUserView = (params: { tenantId: string | null; userId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/settings/users/${params.userId ?? ":userId"}`;
    }
    return `users/:userId`;
  };
  const toTenantSettingsUserUpdateView = (params: { tenantId: string | null; userId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/settings/users/${params.userId ?? ":userId"}/update`;
    }
    return `users/:userId/update`;
  };
  const toTenantSettingsRolesView = (params: { tenantId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/settings/roles`;
    }
    return `roles`;
  };
  const toTenantSettingsRoleView = (params: { tenantId: string | null; roleId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/settings/roles/${params.roleId ?? ":roleId"}`;
    }
    return `roles/:roleId`;
  };

  // Provider
  const toTenantProvidersView = (params: { tenantId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/providers`;
    }
    return `providers`;
  };
  const toTenantProviderCreateView = (params: { tenantId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/providers/create`;
    }
    return `providers/create`;
  };
  const toTenantProviderView = (params: { tenantId: string | null; providerId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/providers/${params.providerId ?? ":providerId"}`;
    }
    return `providers/:providerId`;
  };
  const toTenantProviderUpdateView = (params: { tenantId: string | null; providerId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/providers/${params.providerId ?? ":providerId"}/update`;
    }
    return `providers/:providerId/update`;
  };

  // Project
  const toProjectsView = (params: { tenantId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects`;
    }
    return `projects`;
  };
  const toProjectCreateView = (params: { tenantId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/create`;
    }
    return `projects/create`;
  };
  const toProjectView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}`;
    }
    return `projects/:projectId/`;
  };
  const toProjectUpdateView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/update`;
    }
    return `projects/:projectId/update`;
  };

  // Activity
  const toProjectActivitiesView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/activities`;
    }
    return `activities`;
  };
  const toProjectActivityCreateView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/activities/create`;
    }
    return `activities/create`;
  };
  const toProjectActivityUpdateView = (params: { tenantId: string | null; projectId: string | null; activityId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/activities/${params.activityId ?? ":activityId"}/update`;
    }
    return `activities/:activityId/update`;
  };

  // Report
  const toProjectReportsView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/reports`;
    }
    return `reports`;
  };
  const toProjectReportsBillingView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/reports/billing`;
    }
    return `reports/billing`;
  };
  const toProjectReportUpdateView = (params: { tenantId: string | null; projectId: string | null; reportId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/reports/${params.reportId ?? ":reportId"}/update`;
    }
    return `reports/:reportId/update`;
  };
  const toProjectReportSummaryView = (params: { tenantId: string | null; projectId: string | null; reportId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/reports/${params.reportId ?? ":reportId"}/summary`;
    }
    return `reports/:reportId/summary`;
  };
  const toProjectReportUnitConstructionView = (params: { tenantId: string | null; projectId: string | null; reportId: string | null; unitId: string } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/reports/${params.reportId ?? ":reportId"}/units/${params.unitId ?? ":unitId"}/construction`;
    }
    return `reports/:reportId/units/:unitId/construction`;
  };
  const toProjectReportUnitCustomerView = (params: { tenantId: string | null; projectId: string | null; reportId: string | null; unitId: string } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/reports/${params.reportId ?? ":reportId"}/units/${params.unitId ?? ":unitId"}/customer`;
    }
    return `reports/:reportId/units/:unitId/customer`;
  };

  // Product
  const toProjectProductsView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/products`;
    }
    return `products`;
  };
  const toProjectProductView = (params: { tenantId: string | null; projectId: string | null; productId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/products/${params.productId ?? ":productId"}`;
    }
    return `products/:productId`;
  };
  const toProjectProductCreateView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/products/create`;
    }
    return `products/create`;
  };
  const toProjectProductsUpdateView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/products/update`;
    }
    return `products/update`;
  };
  const toProjectProductUpdateView = (params: { tenantId: string | null; projectId: string | null; productId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/products/${params.productId ?? ":productId"}/update`;
    }
    return `products/:productId/update`;
  };

  // ProductCategory
  const toProjectProductCategoryCreateView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/product-categories/create`;
    }
    return `product-categories/create`;
  };
  const toProjectProductCategoryUpdateView = (params: { tenantId: string | null; projectId: string | null; productCategoryId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/product-categories/${params.productCategoryId ?? ":productCategoryId"}/update`;
    }
    return `product-categories/:productCategoryId/update`;
  };
  const toProjectProductCategoryProductCreateView = (params: { tenantId: string | null; projectId: string | null; productCategoryId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/product-categories/${params.productCategoryId ?? ":productCategoryId"}/products/create`;
    }
    return `product-categories/:productCategoryId/products/create`;
  };

  // Model
  const toProjectModelsView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/models`;
    }
    return `models`;
  };
  const toProjectModelView = (params: { tenantId: string | null; projectId: string | null; modelId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/models/${params.modelId ?? ":modelId"}`;
    }
    return `models/:modelId`;
  };
  const toProjectModelImportView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/models/import`;
    }
    return `models/import`;
  };

  // Form
  const toProjectFormsView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/forms`;
    }
    return `forms`;
  };
  const toProjectFormCreateView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/forms/create`;
    }
    return `forms/create`;
  };
  const toProjectFormUpdateView = (params: { tenantId: string | null; projectId: string | null; formId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/forms/${params.formId ?? ":formId"}/update`;
    }
    return `forms/:formId/update`;
  };
  const toProjectFormLinkView = (params: { tenantId: string | null; projectId: string | null; formId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/forms/${params.formId ?? ":formId"}/link`;
    }
    return `forms/:formId/link`;
  };

  // Section
  const toProjectSectionsView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/sections`;
    }
    return `sections`;
  };
  const toProjectSectionCreateView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/sections/create`;
    }
    return `sections/create`;
  };
  const toProjectSectionView = (params: { tenantId: string | null; projectId: string | null; sectionId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/sections/${params.sectionId ?? ":sectionId"}`;
    }
    return `sections/:sectionId`;
  };
  const toProjectSectionUpdateView = (params: { tenantId: string | null; projectId: string | null; sectionId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/sections/${params.sectionId ?? ":sectionId"}/update`;
    }
    return `sections/:sectionId/update`;
  };
  const toProjectSectionVibesView = (params: { tenantId: string | null; projectId: string | null; sectionId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/sections/${params.sectionId ?? ":sectionId"}/vibes`;
    }
    return `sections/:sectionId/vibes`;
  };

  // Unit
  const toProjectUnitsUpdateView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/units/update`;
    }
    return `units/update`;
  };
  const toProjectUnitCreateView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/units/create`;
    }
    return `units/create`;
  };
  const toProjectUnitUpdateView = (params: { tenantId: string | null; projectId: string | null; unitId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/units/${params.unitId ?? ":unitId"}/update`;
    }
    return `units/:unitId/update`;
  };
  const toProjectUnitContractCreateView = (params: { tenantId: string | null; projectId: string | null; unitId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/units/${params.unitId ?? ":unitId"}/contracts/create`;
    }
    return "units/:unitId/contracts/create";
  };

  // Contract
  const toProjectContractsView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/contracts`;
    }
    return `contracts`;
  };
  const toProjectContractCreateView = (params: { tenantId: string | null; projectId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/contracts/create`;
    }
    return "contracts/create";
  };
  const toProjectContractView = (params: { tenantId: string | null; projectId: string | null; contractId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/contracts/${params.contractId ?? ":contractId"}`;
    }
    return `contracts/:contractId`;
  };
  const toProjectContractSelectionsView = (params: { tenantId: string | null; projectId: string | null; contractId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/contracts/${params.contractId ?? ":contractId"}/selections`;
    }
    return `selections`;
  };
  const toProjectContractInvoicesView = (params: { tenantId: string | null; projectId: string | null; contractId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/contracts/${params.contractId ?? ":contractId"}/invoices`;
    }
    return `invoices`;
  };
  const toProjectContractPaymentsView = (params: { tenantId: string | null; projectId: string | null; contractId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/contracts/${params.contractId ?? ":contractId"}/payments`;
    }
    return `payments`;
  };
  const toProjectContractRefundsView = (params: { tenantId: string | null; projectId: string | null; contractId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/contracts/${params.contractId ?? ":contractId"}/refunds`;
    }
    return `refunds`;
  };
  const toProjectContractInvoiceView = (params: { tenantId: string | null; projectId: string | null; contractId: string | null; invoiceId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/contracts/${params.contractId ?? ":contractId"}/invoices/${params.invoiceId ?? ":invoiceId"}`;
    }
    return `contracts/:contractId/invoices/:invoiceId`;
  };
  const toProjectContractSelectionView = (params: { tenantId: string | null; projectId: string | null; contractId: string | null; contractSelectionId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/contracts/${params.contractId ?? ":contractId"}/selections/${
        params.contractSelectionId ?? ":contractSelectionId"
      }`;
    }
    return `contracts/:contractId/selections/:contractSelectionId`;
  };
  const toProjectContractSelectionUpdateView = (params: { tenantId: string | null; projectId: string | null; contractId: string | null; contractSelectionId: string | null } | null) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/contracts/${params.contractId ?? ":contractId"}/selections/${
        params.contractSelectionId ?? ":contractSelectionId"
      }/update`;
    }
    return `contracts/:contractId/selections/:contractSelectionId/update`;
  };
  const toProjectContractSelectionGroupView = (
    params: { tenantId: string | null; projectId: string | null; contractId: string | null; contractSelectionId: string | null; contractSelectionGroupId: string | null } | null
  ) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/contracts/${params.contractId ?? ":contractId"}/selections/${
        params.contractSelectionId ?? ":contractSelectionId"
      }/groups/${params.contractSelectionGroupId ?? ":contractSelectionGroupId"}`;
    }
    return `contracts/:contractId/selections/:contractSelectionId/groups/:contractSelectionGroupId`;
  };
  const toProjectContractSelectionGroupUpdateView = (
    params: { tenantId: string | null; projectId: string | null; contractId: string | null; contractSelectionId: string | null; contractSelectionGroupId: string | null } | null
  ) => {
    if (params) {
      return `/tenants/${params.tenantId ?? ":tenantId"}/projects/${params.projectId ?? ":projectId"}/contracts/${params.contractId ?? ":contractId"}/selections/${
        params.contractSelectionId ?? ":contractSelectionId"
      }/groups/${params.contractSelectionGroupId ?? ":contractSelectionGroupId"}/update`;
    }
    return `contracts/:contractId/selections/:contractSelectionId/groups/:contractSelectionGroupId/update`;
  };
  return {
    searchParams,
    navigate,
    matchPath,
    toUnauthorizedPage,
    toForbiddenPage,
    toErrorPage,
    toNotFoundPage,
    toTenantsView,
    toTenantView,
    toTenantCustomersView,
    toTenantCustomerCreateView,
    toTenantCustomerView,
    toTenantCustomerContractsView,
    toTenantCustomerUpdateView,
    toTenantProviderView,
    toTenantProvidersView,
    toTenantProviderCreateView,
    toTenantProviderUpdateView,
    toProjectsView,
    toProjectCreateView,
    toProjectView,
    toProjectUpdateView,
    toProjectFormsView,
    toProjectFormCreateView,
    toProjectFormUpdateView,
    toProjectFormLinkView,
    toProjectSectionView,
    toProjectSectionsView,
    toProjectSectionCreateView,
    toProjectSectionUpdateView,
    toProjectSectionVibesView,
    toProjectUnitCreateView,
    toProjectUnitsUpdateView,
    toProjectUnitUpdateView,
    toProjectUnitContractCreateView,
    toProjectProductsView,
    toProjectProductsUpdateView,
    toProjectProductCreateView,
    toProjectProductView,
    toProjectProductUpdateView,
    toProjectProductCategoryCreateView,
    toProjectProductCategoryUpdateView,
    toProjectProductCategoryProductCreateView,
    toProjectModelsView,
    toProjectModelView,
    toProjectModelImportView,
    toProjectActivitiesView,
    toProjectActivityCreateView,
    toProjectActivityUpdateView,
    toProjectContractsView,
    toProjectContractView,
    toProjectContractCreateView,
    toProjectContractSelectionsView,
    toProjectContractInvoicesView,
    toProjectContractPaymentsView,
    toProjectContractRefundsView,
    toProjectContractInvoiceView,
    toProjectContractSelectionView,
    toProjectContractSelectionUpdateView,
    toProjectContractSelectionGroupView,
    toProjectContractSelectionGroupUpdateView,
    toProjectReportsView,
    toProjectReportsBillingView,
    toProjectReportUpdateView,
    toProjectReportSummaryView,
    toProjectReportUnitConstructionView,
    toProjectReportUnitCustomerView,
    toTenantSettingsRolesView,
    toTenantSettingsRoleView,
    toTenantSettingsUsersView,
    toTenantSettingsUserView,
    toTenantSettingsUserCreateView,
    toTenantSettingsUserUpdateView,
    toTenantSettingsView,
    toTenantSettingsProfileView,
    toTenantSettingsTaxesView,
    toTenantSettingsTaxGroupsView,
    toTenantSettingsTaxAgenciesView,
    toTenantSettingsPermissionsView,
  };
};
