import { useParams } from "react-router-dom";
import { useProjectContext, useSecurityContext, useTenantContext } from "providers";
import React, { MutableRefObject, useEffect } from "react";
import { useRouting } from "./useRouting";
import { Types } from "types";

export * from "./useFile";
export * from "./useRouting";

export const useTenantCustomerContext = () => {
  const { customerId } = useParams<{ readonly customerId: string }>();
  const { customers } = useTenantContext();
  return {
    customer: customers.find((customer) => customer.id === customerId)!,
  };
};

export const useTenantProviderContext = () => {
  const { providerId } = useParams<{ readonly providerId: string }>();
  const { providers } = useTenantContext();
  return {
    provider: providers.find((provider) => provider.id === providerId)!,
  };
};

export const useTenantSettingsUserContext = () => {
  const { userId } = useParams<{ readonly userId: string }>();
  const { users } = useTenantContext();
  return {
    user: users.find((user) => user.id === userId)!,
  };
};

export const useTenantSettingsRoleContext = () => {
  const { roleId } = useParams<{ readonly roleId: string }>();
  const { roles } = useTenantContext();
  return {
    role: roles.find((role) => role.id === roleId)!,
  };
};

export const useProjectModelContext = () => {
  const { modelId } = useParams<{ readonly modelId: string }>();
  const { models } = useProjectContext();
  return {
    model: models.find((model) => model.id === modelId)!,
  };
};

export const useProjectReportContext = () => {
  const { reportId } = useParams<{ readonly reportId: string }>();
  const { reports } = useProjectContext();
  return {
    report: reports.find((report) => report.id === reportId)!,
  };
};

export const useProjectActivityContext = () => {
  const { activityId } = useParams<{ readonly activityId: string }>();
  const { activities } = useProjectContext();
  return {
    activity: activities.find((activity) => activity.id === activityId)!,
  };
};

export const useProjectFormContext = () => {
  const { formId } = useParams<{ readonly formId: string }>();
  const { forms } = useProjectContext();
  return {
    form: forms.find((form) => form.id === formId)!,
  };
};

export const useProjectProductCategoryContext = () => {
  const { productCategoryId } = useParams<{ readonly productCategoryId: string }>();
  const { productCategories } = useProjectContext();
  return {
    productCategory: productCategories.find((productCategory) => productCategory.id === productCategoryId)!,
  };
};

export const useProjectProductContext = () => {
  const { productId } = useParams<{ readonly productId: string }>();
  const { products } = useProjectContext();
  return {
    product: products.find((product) => product.id === productId)!,
  };
};

export const useProjectSectionContext = () => {
  const { sectionId } = useParams<{ readonly sectionId: string }>();
  const { sections } = useProjectContext();
  return {
    section: sections.find((section) => section.id === sectionId)!,
  };
};

export const useProjectUnitContext = () => {
  const { unitId } = useParams<{ readonly unitId: string }>();
  const { units } = useProjectContext();
  return {
    unit: units.find((unit) => unit.id === unitId)!,
  };
};

export const useProjectContractContext = () => {
  const { contractId } = useParams<{ readonly contractId: string }>();
  const { contracts } = useProjectContext();
  return {
    contract: contracts.find((contract) => contract.id === contractId)!,
  };
};

export const useProjectContractInvoiceContext = () => {
  const { invoiceId } = useParams<{ readonly invoiceId: string }>();
  const { contract } = useProjectContractContext();
  return {
    invoice: contract.invoices.find((invoice) => invoice.id === invoiceId)!,
  };
};

export const useProjectContractSelectionContext = () => {
  const { contractSelectionId } = useParams<{ readonly contractSelectionId: string }>();
  const { contract } = useProjectContractContext();
  return {
    contractSelection: contract.selections.find((contractSelection) => contractSelection.id === contractSelectionId)!,
  };
};

export const useProjectContractSelectionGroupContext = () => {
  const { contractSelectionGroupId } = useParams<{ readonly contractSelectionGroupId: string }>();
  const { contractSelection } = useProjectContractSelectionContext();
  return {
    contractSelectionGroup: contractSelection.groups.find((contractSelectionGroup) => contractSelectionGroup.id === contractSelectionGroupId)!,
  };
};

export const useOverflowObserver = (ref: MutableRefObject<HTMLDivElement | null>, callback: ((isOverflowed: boolean) => void) | undefined) => {
  React.useLayoutEffect(() => {
    const { current } = ref;
    if (current) {
      const trigger = () => {
        if (callback) {
          callback(current.scrollHeight > current.clientHeight);
        }
      };
      if ("ResizeObserver" in window) {
        new ResizeObserver(trigger).observe(current);
      }
      trigger();
    }
  }, [callback, ref]);
};

export const useEnsureSecurity = (security: Types.Security) => {
  const { navigate, toUnauthorizedPage } = useRouting();
  const { isAuthorized } = useSecurityContext();
  useEffect(() => {
    if (!isAuthorized({ security: security })) {
      navigate(toUnauthorizedPage());
    }
  }, [security, isAuthorized, navigate, toUnauthorizedPage]);
};
