import { FunctionComponent, PropsWithChildren, useEffect, useState } from "react";
import { ApolloClient, ApolloProvider as Provider, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { ContainerLoader } from "buildingBlocks";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

type Props = PropsWithChildren;

export const Component: FunctionComponent<Props> = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [accessToken, setAccessToken] = useState<string>();
  useEffect(() => {
    if (accessToken || !isAuthenticated) {
      return;
    }
    (async () => {
      try {
        var accessToken = await getAccessTokenSilently();
        setAccessToken(accessToken);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [accessToken, isAuthenticated, setAccessToken, getAccessTokenSilently]);
  if (accessToken) {
    const link = createUploadLink({
      uri: process.env.REACT_APP_APOLLO_SERVER_URL,
      headers: {
        authorization: `Bearer ${accessToken}`,
        "Apollo-Require-Preflight": "true",
      },
    });
    const client = new ApolloClient({
      link: link,
      cache: new InMemoryCache(),
      devtools: {
        enabled: process.env.NODE_ENV !== "production",
      },
    });
    return <Provider client={client}>{children}</Provider>;
  }
  return <ContainerLoader backdrop />;
};

export const ApolloProvider = withAuthenticationRequired(Component);
