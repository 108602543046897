import "./index.scss";
import { FunctionComponent } from "react";
import { ViewReport } from "buildingBlocks";
import { useProjectContractContext, useProjectContractSelectionContext, useRouting } from "hooks";
import { PermissionName } from "graphql/schema";
import { useProjectContext, useTenantContext } from "providers";
import { ReportSelection } from "components/ReportSelection";

export const ProjectContractSelectionView: FunctionComponent = () => {
  const { navigate, toProjectContractSelectionsView } = useRouting();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { contract } = useProjectContractContext();
  const { contractSelection } = useProjectContractSelectionContext();
  return (
    <ViewReport
      id="project-contract-selection-view"
      security={{
        permissions: [[PermissionName.CONTRACT_READ]],
      }}
      header={{
        title: contractSelection.label,
        subtitle: contract.unit.label,
        returnButton: {
          onClick: () => {
            navigate(
              toProjectContractSelectionsView({
                tenantId: tenant.id,
                projectId: project.id,
                contractId: contract.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [],
          otherActions: [],
        },
      }}
    >
      <ReportSelection contract={contract} contractSelection={contractSelection} />
    </ViewReport>
  );
};
