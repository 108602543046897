import "./index.scss";
import { FunctionComponent } from "react";
import { ModalForm } from "buildingBlocks";
import { SectionElementType } from "graphql/schema";
import { SectionElementAddFormValues, useSectionElementAddForm, SectionElementAddForm } from "forms/SectionForm/_forms/SectionElementAddForm";

type Props = {
  readonly onAdd: (values: SectionElementAddFormValues) => void;
  readonly onCancel: () => void;
};

export const SectionElementAddModal: FunctionComponent<Props> = ({ onAdd, onCancel }) => {
  const { values, setValues } = useSectionElementAddForm({
    type: SectionElementType.UNIQUE,
  });
  return (
    <ModalForm
      id="section-element-add-modal"
      title="Ajouter un élément"
      errors={[]}
      loading={false}
      disabled={values.type === SectionElementType.NONE}
      submitButton={{
        text: "Ajouter",
        onClick: () => {
          onAdd(values);
        },
      }}
      closeButton={{
        text: "Annuler",
        onClick: onCancel,
      }}
    >
      <SectionElementAddForm values={values} setValues={setValues} />
    </ModalForm>
  );
};
