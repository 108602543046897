import { ViewForm } from "buildingBlocks";
import { UserForm, useUserForm } from "forms";
import { useUpdateUserMutation } from "graphql/mutations";
import { useTenantSettingsUserContext, useRouting } from "hooks";
import { useTenantContext } from "providers";
import { FunctionComponent } from "react";

export const TenantSettingsUserUpdateView: FunctionComponent = () => {
  const { navigate, toTenantSettingsUsersView } = useRouting();
  const { tenant, projects, roles } = useTenantContext();
  const { user } = useTenantSettingsUserContext();
  const { values, setValues } = useUserForm({
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    hasAccessToAllProjects: user.hasAccessToAllProjects,
    hasAccessToTestTenant: user.hasAccessToTestTenant,
    roleId: user.role.id,
    projectIds: user.projects.map((project) => project.id),
  });
  const { updateUser, updateUserLoading, updateUserErrors } = useUpdateUserMutation(({ tenantId }) => {
    navigate(
      toTenantSettingsUsersView({
        tenantId: tenantId,
      })
    );
  });
  return (
    <ViewForm
      id="tenant-settings-user-update-view"
      title="Modifier l'utilisateur"
      security={{
        isTenantSecurityManager: true,
      }}
      disabled={values.roleId === null}
      errors={updateUserErrors}
      loading={updateUserLoading}
      onSubmit={async () => {
        await updateUser({
          tenantId: tenant.id,
          userId: user.id,
          firstName: values.firstName,
          lastName: values.lastName,
          hasAccessToAllProjects: values.hasAccessToAllProjects,
          hasAccessToTestTenant: values.hasAccessToTestTenant,
          roleId: values.roleId!,
          projectIds: values.projectIds,
        });
      }}
      onCancel={() => {
        navigate(
          toTenantSettingsUsersView({
            tenantId: tenant.id,
          })
        );
      }}
    >
      <UserForm values={values} setValues={setValues} roles={roles} projects={projects} readonlyEmail />
    </ViewForm>
  );
};
