import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { SearchInputGroup, ViewList, Text } from "buildingBlocks";
import { useRouting } from "hooks";
import { useTenantContext } from "providers";
import { TaxAgencyFragment } from "graphql/schema";

const useTenantSettingsTaxAgenciesViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback((taxAgencies: ReadonlyArray<TaxAgencyFragment>) => taxAgencies.filter((taxAgency) => taxAgency.name.toSearchFormat().includes(search.toSearchFormat())), [search]),
  };
};

export const TenantSettingsTaxAgenciesView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useTenantSettingsTaxAgenciesViewFilters();
  const { navigate, toTenantView, toTenantSettingsView } = useRouting();
  const { tenant, taxAgencies } = useTenantContext();
  return (
    <ViewList
      id="tenant-settings-tax-agencies-view"
      security={{
        isTenantAccountingManager: true,
      }}
      header={{
        title: "Tous les organismes fiscaux",
        returnButton: {
          onClick: () => {
            navigate(
              toTenantSettingsView({
                tenantId: tenant.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              appearance: "default",
              text: "Fermer les paramètres",
              show: true,
              security: {},
              onClick: () => {
                navigate(
                  toTenantView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [<SearchInputGroup size="xs" placeholder="Recherche par nom" value={filterValues.search} onChange={filterFunctions.setSearch} />],
            applyButton: null,
          },
          right: {
            inputs: [],
          },
        },
      }}
      content={{
        table: {
          headColumns: [
            {
              name: "name",
              value: "NOM",
            },
            {
              name: "description",
              value: "DESCRIPTION",
            },
          ],
          bodyRows: applyFilters(taxAgencies).map((taxAgency) => {
            return {
              key: taxAgency.id,
              columns: [
                {
                  name: "name",
                  component: <Text highlight>{taxAgency.name}</Text>,
                },
                {
                  name: "description",
                  component: taxAgency.description ?? "--",
                },
              ],
              actions: [],
            };
          }),
          bodyRowGroups: [],
        },
      }}
    />
  );
};
