import "./index.scss";
import { FunctionComponent } from "react";
import { Container, Loader } from "rsuite";

type Props = {
  readonly backdrop?: boolean;
};

export const ContainerLoader: FunctionComponent<Props> = ({ backdrop = false }) => (
  <Container className="container-loader">
    <Loader size="lg" backdrop={backdrop} />
  </Container>
);
