import { FunctionComponent } from "react";
import { ViewForm } from "buildingBlocks";
import { useCreateProviderMutation } from "graphql/mutations";
import { useRouting } from "hooks";
import { PermissionName } from "graphql/schema";
import { ProviderForm, useProviderForm } from "forms";
import { useTenantContext } from "providers";

export const ProviderCreateView: FunctionComponent = () => {
  const { navigate, toTenantProvidersView, toTenantProviderView } = useRouting();
  const { tenant } = useTenantContext();
  const { values, setValues } = useProviderForm({
    name: "",
    description: "",
    websiteUrl: "",
  });
  const { createProvider, createProviderLoading, createProviderErrors } = useCreateProviderMutation(({ provider }) => {
    navigate(
      toTenantProviderView({
        tenantId: tenant.id,
        providerId: provider.id,
      })
    );
  });
  return (
    <ViewForm
      id="provider-create-view"
      title="Créer un fournisseur"
      security={{
        permissions: [[PermissionName.PROVIDER_READ, PermissionName.PROVIDER_CREATE]],
      }}
      errors={createProviderErrors}
      loading={createProviderLoading}
      onSubmit={async () => {
        await createProvider({
          tenantId: tenant.id,
          name: values.name,
          description: values.description || null,
          websiteUrl: values.websiteUrl || null,
        });
      }}
      onCancel={() => {
        navigate(
          toTenantProvidersView({
            tenantId: tenant.id,
          })
        );
      }}
    >
      <ProviderForm values={values} setValues={setValues} />
    </ViewForm>
  );
};
