import "./index.scss";
import { Button } from "rsuite";
import { FunctionComponent, PropsWithChildren } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type Action = {
  readonly name: string;
  readonly icon?: IconProp;
  readonly disabled: boolean;
  readonly onClick: () => void;
};

type Props = PropsWithChildren & {
  readonly highlight?: boolean;
  readonly withPadding?: boolean;
  readonly fluid?: boolean;
  readonly withoutBorder?: boolean;
  readonly empty?: {
    readonly show: boolean;
    readonly label: string;
    readonly content: string;
  };
  readonly header?: {
    readonly title: string;
    readonly actions: ReadonlyArray<Action>;
  };
};

export const FormContainer: FunctionComponent<Props> = ({
  children,
  header = undefined,
  highlight = false,
  withPadding = false,
  fluid = false,
  withoutBorder = false,
  empty = undefined,
}) => (
  <div
    className={`form-container${fluid ? " form-container-fluid" : ""}${highlight ? " form-container-highlight" : ""}${
      withPadding ? " form-container-with-padding" : ""
    }${withoutBorder ? " form-container-without-border" : ""}`}
  >
    {header && (
      <div className="form-container-header">
        <div className="form-container-title">{header.title}</div>
        {header.actions.filter((action) => !action.disabled).length !== 0 && (
          <div className="form-container-actions">
            {header.actions.map((action, index) => (
              <Button key={index} appearance="link" onClick={action.onClick}>
                {action.icon && <FontAwesomeIcon icon={action.icon} />}
                {action.name}
              </Button>
            ))}
          </div>
        )}
      </div>
    )}
    <div className="form-container-body">
      {empty && empty.show && (
        <div className="form-container-empty">
          <span className="form-container-empty-label">{empty.label}</span>
          <span className="form-container-empty-content">{empty.content}</span>
        </div>
      )}
      {children}
    </div>
  </div>
);
