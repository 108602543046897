import { FunctionComponent } from "react";
import { ViewForm } from "buildingBlocks";
import { useRouting } from "hooks";
import { useCreateProductCategoryMutation } from "graphql/mutations";
import { useProjectContext, useTenantContext } from "providers";
import { PermissionName } from "graphql/schema";
import { ProductCategoryForm, useProductCategoryForm } from "forms";

export const ProjectProductCategoryCreateView: FunctionComponent = () => {
  const { navigate, toProjectProductsView } = useRouting();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { values, setValues } = useProductCategoryForm({
    name: "",
    displayName: "",
    description: "",
  });
  const { createProductCategory, createProductCategoryLoading, createProductCategoryErrors } = useCreateProductCategoryMutation(({ tenantId, projectId }) => {
    navigate(
      toProjectProductsView({
        tenantId: tenantId,
        projectId: projectId,
      })
    );
  });
  return (
    <ViewForm
      id="project-product-category-create-view"
      title="Créer une catégorie de produit"
      security={{
        permissions: [[PermissionName.PRODUCT_READ, PermissionName.PRODUCT_CATEGORY_CREATE]],
      }}
      errors={createProductCategoryErrors}
      loading={createProductCategoryLoading}
      onSubmit={async () => {
        await createProductCategory({
          tenantId: tenant.id,
          projectId: project.id,
          name: values.name,
          displayName: values.displayName.trim() || null,
          description: values.description.trim() || null,
        });
      }}
      onCancel={() => {
        navigate(
          toProjectProductsView({
            tenantId: tenant.id,
            projectId: project.id,
          })
        );
      }}
    >
      <ProductCategoryForm values={values} setValues={setValues} />
    </ViewForm>
  );
};
