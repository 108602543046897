import "./index.scss";
import { Fragment, FunctionComponent, useState } from "react";
import { Table, TableBody, TableColumn, TableColumnActions, TableContent, TableHead, TableRow } from "buildingBlocks";
import { Form } from "rsuite";
import { Types } from "types";

type Props = {
  readonly id: string;
  readonly table: Types.Table;
};

export const FormTable: FunctionComponent<Props> = ({ id, table }) => {
  const [isTableBodyOverflow, setIsTableBodyOverflow] = useState<boolean>(false);
  return (
    <Form id={id} className="form-table">
      <Table fluid size="md">
        <TableContent>
          <TableHead>
            <TableRow hasOverflow={isTableBodyOverflow}>
              {table.headColumns.map((column, index) => (
                <TableColumn key={index} name={column.name}>
                  {column.value.toUpperCase()}
                </TableColumn>
              ))}
              <TableColumnActions />
            </TableRow>
          </TableHead>
          <TableBody onResize={setIsTableBodyOverflow}>
            {table.bodyRows.map((row) => (
              <TableRow key={row.key} href={row.href}>
                {row.columns.map((column) => (
                  <TableColumn key={column.name} name={column.name} href={row.href}>
                    {column.component}
                  </TableColumn>
                ))}
                {row.actions && <TableColumnActions actions={row.actions} />}
              </TableRow>
            ))}
            {table.bodyRowGroups.map((group) => (
              <Fragment key={group.key}>
                <TableRow highlight>
                  {group.columns.map((column) => (
                    <TableColumn key={column.name} name={column.name}>
                      {column.component}
                    </TableColumn>
                  ))}
                  <TableColumnActions actions={group.actions} />
                </TableRow>
                {group.rows.map((row) => (
                  <TableRow key={row.key} href={row.href}>
                    {row.columns.map((column) => (
                      <TableColumn key={column.name} name={column.name} href={row.href}>
                        {column.component}
                      </TableColumn>
                    ))}
                    {row.actions && <TableColumnActions actions={row.actions} />}
                  </TableRow>
                ))}
              </Fragment>
            ))}
          </TableBody>
        </TableContent>
      </Table>
    </Form>
  );
};
