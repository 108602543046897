import "./index.scss";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ContainerLoader, ViewReport } from "buildingBlocks";
import { CustomerReportFragment, PermissionName, UnitState } from "graphql/schema";
import { useProjectContext, useTenantContext } from "providers";
import { SelectPicker } from "rsuite";
import { ReportMessage, ReportUnit } from "components";
import { useProjectReportContext, useProjectUnitContext, useRouting } from "hooks";
import { useGetReportCustomerLazyQuery } from "graphql/queries";

export const ProjectReportCustomerView: FunctionComponent = () => {
  const { navigate, toProjectReportsView, toProjectReportUnitCustomerView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, reports, units } = useProjectContext();
  const { report } = useProjectReportContext();
  const { unit } = useProjectUnitContext();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [customerReport, setCustomerReport] = useState<CustomerReportFragment | null>(null);
  const { getReportCustomer, reportCustomerLoading } = useGetReportCustomerLazyQuery((customerReport) => {
    setCustomerReport(customerReport);
    setIsLoaded(true);
  });
  const execute = useCallback(async () => {
    await getReportCustomer({
      variables: {
        tenantId: tenant.id,
        projectId: project.id,
        reportId: report.id,
        unitId: unit.id,
      },
    });
  }, [tenant, project, report, unit, getReportCustomer]);
  useEffect(() => {
    execute();
  }, [execute]);
  if (!isLoaded || customerReport === null || reportCustomerLoading) {
    return <ContainerLoader />;
  }
  return (
    <ViewReport
      id="project-report-customer-view"
      security={{
        permissions: [[PermissionName.REPORT_READ], [PermissionName.REPORT_CUSTOMER_READ]],
      }}
      header={{
        subtitle: "Contrats",
        title: "Fiche du client",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectReportsView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Dropdown",
              appearance: "primary",
              size: "sm",
              title: "Exporter",
              trigger: "click",
              placement: "bottomEnd",
              security: {},
              show:
                customerReport.unitState === UnitState.CONTRACT_COMPLETED ||
                customerReport.unitState === UnitState.CONTRACT_PAYMENT_PENDING ||
                customerReport.unitState === UnitState.CONTRACT_REFUND_PENDING,
              items: [
                {
                  __type: "DropdownItem",
                  text: "Télécharger (PDF)",
                  disabled: false,
                  onClick: () => {},
                },
                {
                  __type: "DropdownDivider",
                },
                {
                  __type: "DropdownItem",
                  text: "Imprimer",
                  disabled: false,
                  onClick: () => {},
                },
              ],
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [
              <SelectPicker
                size="xs"
                label="Unité #"
                value={unit.id}
                onChange={(value) => {
                  navigate(
                    toProjectReportUnitCustomerView({
                      tenantId: tenant.id,
                      projectId: project.id,
                      reportId: report.id,
                      unitId: value!,
                    })
                  );
                }}
                data={units.map((unit) => ({
                  label: unit.number,
                  value: unit.id,
                }))}
                cleanable={false}
              />,
            ],
            applyButton: null,
          },
          right: {
            inputs: [
              <SelectPicker
                size="xs"
                label="Rapport"
                value={report.id}
                searchable={false}
                onChange={(value) => {
                  if (value !== report.id) {
                    navigate(
                      toProjectReportUnitCustomerView({
                        tenantId: tenant.id,
                        projectId: project.id,
                        reportId: value!,
                        unitId: unit.id,
                      })
                    );
                  }
                }}
                cleanable={false}
                data={reports.map((report) => ({
                  label: report.name,
                  value: report.id,
                }))}
              />,
            ],
          },
        },
      }}
    >
      <ReportMessage unitState={customerReport.unitState} />
      <ReportUnit report={customerReport} />
    </ViewReport>
  );
};
