import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { DateTime, EnumLabel, SearchInputGroup, ViewTab, ViewTabList, Text } from "buildingBlocks";
import { InvoiceStatus, PermissionName } from "graphql/schema";
import { useRouting, useProjectContractContext } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { ContractInvoicePaymentUpdateModal } from "modals";
import { ContractInvoicePaymentDeleteDialog } from "dialogs";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

const useProjectContractPaymentsViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback(
      (contractPayments: ReadonlyArray<ProjectProviderTypes.MappedProjectPayment>) =>
        contractPayments.filter((contractPayment) => contractPayment.label.toSearchFormat().includes(search.toSearchFormat())),
      [search]
    ),
  };
};

export const ProjectContractPaymentsView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useProjectContractPaymentsViewFilters();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { contract } = useProjectContractContext();
  const [paymentToUpdate, setPaymentToUpdate] = useState<ProjectProviderTypes.MappedProjectPayment | null>(null);
  const [paymentToDelete, setPaymentToDelete] = useState<ProjectProviderTypes.MappedProjectPayment | null>(null);
  return (
    <ViewTab id="project-contract-payments-view">
      <ViewTabList
        header={{
          filter: {
            left: {
              inputs: [<SearchInputGroup size="xs" placeholder="Recherche par étiquette" value={filterValues.search} onChange={filterFunctions.setSearch} />],
              applyButton: null,
            },
            right: {
              inputs: [],
            },
          },
        }}
        content={{
          table: {
            emptyBodies: [
              {
                show: contract.payments.length === 0,
                title: "Aucun paiement",
                content: contract.invoices.some((invoice) => invoice.balanceAmount > 0)
                  ? "Pour créer un paiement, cliquez sur le bouton d'actions supplémentaires d'une facture, puis cliquer sur 'Créer un paiement'."
                  : "Aucune facture n'a de solde à payer.",
              },
            ],
            headColumns: [
              {
                name: "date",
                value: "DATE",
              },
              {
                name: "label",
                value: "ÉTIQUETTE",
              },
              {
                name: "mode",
                value: "MODE",
              },
              {
                name: "amount",
                value: "MONTANT",
              },
              {
                name: "reference-number",
                value: "NUMÉRO DE RÉFÉRENCE",
              },
              {
                name: "notes",
                value: "NOTES",
              },
              {
                name: "invoice",
                value: "FACTURE",
              },
              {
                name: "selection",
                value: "Sélection",
              },
            ],
            bodyRows: applyFilters(contract.payments).map((payment) => {
              return {
                key: payment.id,
                columns: [
                  {
                    name: "date",
                    component: <DateTime value={payment.date} />,
                  },
                  {
                    name: "label",
                    component: <Text highlight>{payment.label}</Text>,
                  },
                  {
                    name: "mode",
                    component: <EnumLabel value={payment.mode} uppercase bold block />,
                  },
                  {
                    name: "amount",
                    component: payment.amount.toCurrencyFormat(),
                  },
                  {
                    name: "reference-number",
                    component: payment.referenceNumber ?? "--",
                  },
                  {
                    name: "notes",
                    component: payment.notes ?? "--",
                  },
                  {
                    name: "invoice",
                    component: payment.invoice?.label ?? "--",
                  },
                  {
                    name: "selection",
                    component: payment.invoice?.contractSelection?.label ?? "--",
                  },
                ],
                actions: [
                  {
                    __type: "Button",
                    content: "Modifier le paiement",
                    show:
                      !contract.isCanceled &&
                      payment.invoice !== null &&
                      payment.invoice.contractSelection !== null &&
                      payment.invoice.status !== InvoiceStatus.CREDITED &&
                      payment.invoice.status !== InvoiceStatus.CANCELED &&
                      payment.invoice.status !== InvoiceStatus.NO_CHARGE &&
                      contract.currentSelectionId === payment.invoice.contractSelection.id,
                    security: {
                      permissions: [[PermissionName.CONTRACT_INVOICE_PAYMENT_UPDATE]],
                    },
                    onClick: () => {
                      setPaymentToUpdate(payment);
                    },
                  },
                  {
                    __type: "Button",
                    content: "Supprimer le paiement",

                    show:
                      !contract.isCanceled &&
                      payment.invoice !== null &&
                      payment.invoice.contractSelection !== null &&
                      payment.invoice.status !== InvoiceStatus.CREDITED &&
                      payment.invoice.status !== InvoiceStatus.CANCELED &&
                      payment.invoice.status !== InvoiceStatus.NO_CHARGE &&
                      contract.currentSelectionId === payment.invoice.contractSelection.id,
                    security: {
                      permissions: [[PermissionName.CONTRACT_INVOICE_PAYMENT_DELETE]],
                    },
                    onClick: () => {
                      setPaymentToDelete(payment);
                    },
                  },
                ],
              };
            }),
            bodyRowGroups: [],
          },
        }}
      />
      {paymentToUpdate && (
        <ContractInvoicePaymentUpdateModal
          tenantId={tenant.id}
          projectId={project.id}
          contract={contract}
          payment={paymentToUpdate}
          onCompleted={() => {
            setPaymentToUpdate(null);
          }}
          onCancel={() => {
            setPaymentToUpdate(null);
          }}
        />
      )}
      {paymentToDelete && (
        <ContractInvoicePaymentDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          contract={contract}
          payment={paymentToDelete}
          onCompleted={() => {
            setPaymentToDelete(null);
          }}
          onClose={() => {
            setPaymentToDelete(null);
          }}
        />
      )}
    </ViewTab>
  );
};
