import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { useProjectContractContext, useProjectContractSelectionContext, useProjectContractSelectionGroupContext, useRouting } from "hooks";
import { ContractSelectionGroupFragment, PermissionName } from "graphql/schema";
import { useProjectContext, useTenantContext } from "providers";
import { ViewModel } from "buildingBlocks/ViewModel";
import { ContractSelectionGroupForm, useContractSelectionGroupForm } from "forms";
import { useUpdateContractSelectionGroupMutation } from "graphql/mutations";
import { ContractSelectionGroupUpdateDialog, UpdateContractSelectionGroupDialogMode } from "dialogs";
import { useContractSelectionGroupFormContext } from "providers/ContractSelectionGroupFormProvider";

export const ProjectContractSelectionGroupUpdateView: FunctionComponent = () => {
  const [contractSelectionGroupToUpdate, setContractSelectionGroupToUpdate] = useState<{
    contractSelectionGroup: ContractSelectionGroupFragment;
    mode: UpdateContractSelectionGroupDialogMode;
  } | null>(null);
  const { navigate, toProjectContractSelectionsView, toProjectContractSelectionUpdateView, toProjectContractSelectionGroupUpdateView } = useRouting();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { contract } = useProjectContractContext();
  const { contractSelection } = useProjectContractSelectionContext();
  const { contractSelectionGroup } = useProjectContractSelectionGroupContext();
  const { unityContextHook } = useContractSelectionGroupFormContext();
  const { values, setValues } = useContractSelectionGroupForm({
    vibe: contractSelectionGroup.vibe
      ? {
          sectionVibe: contractSelectionGroup.formSection.section.vibes.find((sectionVibe) => sectionVibe.id === contractSelectionGroup.vibe!.sectionVibeId)!,
          mode: contractSelectionGroup.vibe.mode,
          clientAmount: contractSelectionGroup.vibe.clientAmount,
        }
      : null,
    items: contractSelectionGroup.getItemsWithoutSets().map((contractSelectionGroupItem) => ({
      sectionElementId: contractSelectionGroupItem.sectionElementId,
      sectionElementOptionId: contractSelectionGroupItem.sectionElementOptionId,
      modelLink: null,
      clientAmount: 0,
    })),
  });
  const { updateContractSelectionGroup, updateContractSelectionGroupLoading } = useUpdateContractSelectionGroupMutation(() => {});
  const onPrevious = useCallback(() => {
    const previousContractSelectionGroup = contractSelection.groups.find(({ index }) => index === contractSelectionGroup.index - 1);
    if (previousContractSelectionGroup) {
      navigate(
        toProjectContractSelectionGroupUpdateView({
          tenantId: tenant.id,
          projectId: project.id,
          contractId: contract.id,
          contractSelectionId: contractSelection.id,
          contractSelectionGroupId: previousContractSelectionGroup.id,
        })
      );
    }
  }, [tenant, project, contract, contractSelection, contractSelectionGroup, navigate, toProjectContractSelectionGroupUpdateView]);
  const onNext = useCallback(() => {
    const nextContractSelectionGroup = contractSelection.groups.find(({ index }) => index === contractSelectionGroup.index + 1);
    if (nextContractSelectionGroup) {
      navigate(
        toProjectContractSelectionGroupUpdateView({
          tenantId: tenant.id,
          projectId: project.id,
          contractId: contract.id,
          contractSelectionId: contractSelection.id,
          contractSelectionGroupId: nextContractSelectionGroup.id,
        })
      );
    }
  }, [tenant, project, contract, contractSelection, contractSelectionGroup, navigate, toProjectContractSelectionGroupUpdateView]);
  const updateSelectionGroupHandler = useCallback(async () => {
    await updateContractSelectionGroup({
      tenantId: tenant.id,
      projectId: project.id,
      contractId: contract.id,
      selectionId: contractSelection.id,
      formSectionId: contractSelectionGroup.formSectionId,
      vibe: values.vibe
        ? {
            sectionVibeId: values.vibe.sectionVibe.id,
            mode: values.vibe.mode,
          }
        : null,
      items: values.items.map((item) => ({
        sectionElementId: item.sectionElementId,
        sectionElementOptionId: item.sectionElementOptionId,
      })),
    });
  }, [updateContractSelectionGroup, tenant, project, contract, contractSelection, contractSelectionGroup, values]);
  return (
    <ViewModel
      id="project-contract-selection-group-update-view"
      security={{
        permissions: [[PermissionName.CONTRACT_SELECTION_CONFIRM]],
      }}
      header={{
        title: contractSelection.label,
        subtitle: contract.unit.label,
        returnButton: {
          onClick: async () => {
            if (unityContextHook) {
              await unityContextHook.unload();
            }
            navigate(
              toProjectContractSelectionsView({
                tenantId: tenant.id,
                projectId: project.id,
                contractId: contract.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [],
          otherActions: [],
        },
      }}
    >
      <ContractSelectionGroupForm
        values={values}
        setValues={setValues}
        unityContextHook={unityContextHook}
        contractSelection={contractSelection}
        contractSelectionGroup={contractSelectionGroup}
        loading={updateContractSelectionGroupLoading}
        onPrevious={(hasValuesChanged) => {
          if (hasValuesChanged) {
            setContractSelectionGroupToUpdate({
              contractSelectionGroup: contractSelectionGroup,
              mode: UpdateContractSelectionGroupDialogMode.PREVIOUS,
            });
          } else {
            onPrevious();
          }
        }}
        onNext={(hasValuesChanged) => {
          if (hasValuesChanged) {
            setContractSelectionGroupToUpdate({
              contractSelectionGroup: contractSelectionGroup,
              mode: UpdateContractSelectionGroupDialogMode.NEXT,
            });
          } else {
            onNext();
          }
        }}
        onCompleted={async () => {
          if (unityContextHook) {
            await unityContextHook.unload();
          }
          navigate(
            toProjectContractSelectionUpdateView({
              tenantId: tenant.id,
              projectId: project.id,
              contractId: contract.id,
              contractSelectionId: contractSelection.id,
            })
          );
        }}
        onSave={async () => {
          await updateSelectionGroupHandler();
        }}
      />
      {contractSelectionGroupToUpdate && (
        <ContractSelectionGroupUpdateDialog
          mode={contractSelectionGroupToUpdate.mode}
          loading={updateContractSelectionGroupLoading}
          onSave={async (mode) => {
            await updateSelectionGroupHandler();
            setContractSelectionGroupToUpdate(null);
            if (mode === UpdateContractSelectionGroupDialogMode.NEXT) {
              onNext();
            }
            if (mode === UpdateContractSelectionGroupDialogMode.PREVIOUS) {
              onPrevious();
            }
          }}
          onClose={(mode) => {
            setContractSelectionGroupToUpdate(null);
            if (mode === UpdateContractSelectionGroupDialogMode.NEXT) {
              onNext();
            }
            if (mode === UpdateContractSelectionGroupDialogMode.PREVIOUS) {
              onPrevious();
            }
          }}
        />
      )}
    </ViewModel>
  );
};
