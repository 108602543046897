import "./index.scss";
import { useUpdateContractSelectionMutation } from "graphql/mutations";
import { FunctionComponent } from "react";
import { ModalForm } from "buildingBlocks";
import { ContractSelectionUpdateForm, useContractSelectionUpdateForm } from "forms";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";
import { MappedTaxGroup } from "providers";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly contract: ProjectProviderTypes.MappedProjectContract;
  readonly contractSelection: ProjectProviderTypes.MappedProjectContractSelection;
  readonly taxGroups: ReadonlyArray<MappedTaxGroup>;
  readonly onCompleted: () => void;
  readonly onCancel: () => void;
};

export const ContractSelectionUpdateModal: FunctionComponent<Props> = ({ tenantId, projectId, contract, contractSelection, taxGroups, onCompleted, onCancel }) => {
  const { values, setValues } = useContractSelectionUpdateForm({
    taxGroupId: taxGroups[0].id,
    label: contractSelection.label,
    terms: contractSelection.billingSettings.terms,
    creditAmount: contractSelection.billingSettings.creditAmount,
  });
  const { updateContractSelection, updateContractSelectionErrors, updateContractSelectionLoading } = useUpdateContractSelectionMutation(onCompleted, undefined, false);
  return (
    <ModalForm
      id="contract-selection-update-modal"
      title="Modifier une sélection"
      errors={updateContractSelectionErrors}
      loading={updateContractSelectionLoading}
      disabled={false}
      submitButton={{
        onClick: async () => {
          await updateContractSelection({
            tenantId: tenantId,
            projectId: projectId,
            contractId: contract.id,
            selectionId: contractSelection.id,
            taxGroupId: values.taxGroupId,
            terms: values.terms,
            creditAmount: values.creditAmount,
          });
        },
      }}
      closeButton={{
        text: "Annuler",
        onClick: onCancel,
      }}
    >
      <ContractSelectionUpdateForm values={values} setValues={setValues} taxGroups={taxGroups} />
    </ModalForm>
  );
};
