import "./index.scss";
import { FunctionComponent, useState } from "react";
import { Dialog, DialogBodyDelete, DialogFooterDelete } from "buildingBlocks";
import { useDeleteModelMutation } from "graphql/mutations";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly model: ProjectProviderTypes.MappedProjectModel;
  readonly onCompleted: () => void;
  readonly onClose: () => void;
};

export const ModelDeleteDialog: FunctionComponent<Props> = ({ tenantId, projectId, model, onCompleted, onClose }) => {
  const [value, setValue] = useState<string>("");
  const { deleteModel, deleteModelLoading } = useDeleteModelMutation(onCompleted, onClose, true);
  return (
    <Dialog id="model-delete-dialog" title="Supprimer le modèle">
      <DialogBodyDelete objectName={model.name} dictionaryList={[]} value={value} setValue={setValue} />
      <DialogFooterDelete
        disabled={model.name !== value}
        loading={deleteModelLoading}
        onClose={onClose}
        onClick={async () => {
          await deleteModel({
            tenantId: tenantId,
            projectId: projectId,
            modelId: model.id,
          });
        }}
      />
    </Dialog>
  );
};
