import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { Tag } from "rsuite";
import { EnumLabel, SearchInputGroup, ViewList, Text } from "buildingBlocks";
import { useProjectSectionContext, useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { PermissionName, SectionState, SectionVibeMode } from "graphql/schema";
import { SectionVibeCreateModal, SectionVibeUpdateModal } from "modals";
import { SectionVibeDeleteDialog } from "dialogs/SectionVibeDeleteDialog";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

const useProjectSectionVibesViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback(
      (sectionVibes: ReadonlyArray<ProjectProviderTypes.MappedProjectSectionVibe>) =>
        sectionVibes
          .filter(
            (sectionVibe) =>
              sectionVibe.name.toSearchFormat().includes(search.toSearchFormat()) ||
              sectionVibe.items.some(
                (sectionVibeItem) =>
                  sectionVibeItem.sectionElement.label.toSearchFormat().includes(search.toSearchFormat()) ||
                  sectionVibeItem.sectionElementOption.product.name.toSearchFormat().includes(search.toSearchFormat())
              )
          )
          .map((sectionVibe) => ({
            ...sectionVibe,
            items: sectionVibe.name.toSearchFormat().includes(search.toSearchFormat())
              ? sectionVibe.items
              : sectionVibe.items.filter(
                  (sectionVibeItem) =>
                    sectionVibeItem.sectionElement.label.toSearchFormat().includes(search.toSearchFormat()) ||
                    sectionVibeItem.sectionElementOption.product.name.toSearchFormat().includes(search.toSearchFormat())
                ),
          })),
      [search]
    ),
  };
};

export const ProjectSectionVibesView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useProjectSectionVibesViewFilters();
  const { navigate, toProjectSectionView } = useRouting();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { section } = useProjectSectionContext();
  const [sectionVibeToCreate, setSectionVibeToCreate] = useState<boolean>(false);
  const [sectionVibeToUpdate, setSectionVibeToUpdate] = useState<ProjectProviderTypes.MappedProjectSectionVibe | null>(null);
  const [sectionVibeToDelete, setSectionVibeToDelete] = useState<ProjectProviderTypes.MappedProjectSectionVibe | null>(null);
  return (
    <ViewList
      id="project-section-vibes-view"
      security={{
        permissions: [[PermissionName.SECTION_READ]],
      }}
      header={{
        title: `${section.title} | Toutes les ambiances`,
        subtitle: "Section",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectSectionView({
                tenantId: tenant.id,
                projectId: project.id,
                sectionId: section.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              text: "Créer une ambiance",
              show: section.vibes.length !== 0 && section.state === SectionState.CONFIGURED,
              security: {
                permissions: [[PermissionName.SECTION_VIBE_CREATE]],
              },
              onClick: () => {
                setSectionVibeToCreate(true);
              },
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [<SearchInputGroup size="xs" placeholder="Recherche par étiquette" value={filterValues.search} onChange={filterFunctions.setSearch} />],
            applyButton: null,
          },
          right: {
            inputs: [],
          },
        },
      }}
      content={{
        table: {
          emptyBodies: [
            {
              show: section.vibes.length === 0,
              title: "Aucune ambiance",
              content: "Pour créer une ambiance, cliquez sur le bouton ci-dessous.",
              button: {
                text: "Créer une ambiance",
                security: {
                  permissions: [[PermissionName.SECTION_VIBE_CREATE]],
                },
                onClick: () => {
                  setSectionVibeToCreate(true);
                },
              },
            },
          ],
          headColumns: [
            {
              name: "number",
              value: "#",
            },
            {
              name: "name",
              value: "ÉLÉMENT / OPTION",
            },
            {
              name: "product",
              value: "MODE / PRODUIT",
            },
            {
              name: "client-amount",
              value: "MONTANT CLIENT",
            },
            {
              name: "subcontractor-amount",
              value: "MONTANT SOUS-TRAITANT",
            },
            {
              name: "contractor-amount",
              value: "MONTANT ENTREPRENEUR",
            },
            {
              name: "promoter-amount",
              value: "MONTANT PROMOTEUR",
            },
            {
              name: "contingency-amount",
              value: "MONTANT CONTINGENCE",
            },
          ],
          bodyRows: [],
          bodyRowGroups: applyFilters(section.vibes).map((sectionVibe) => {
            return {
              key: sectionVibe.id,
              columns: [
                {
                  name: "number",
                  component: <Tag className="section-index">{sectionVibe.index + 1}</Tag>,
                },
                {
                  name: "name",
                  component: sectionVibe.name,
                },
                {
                  name: "product",
                  component: (
                    <EnumLabel
                      value={sectionVibe.mode}
                      appearances={[
                        {
                          value: SectionVibeMode.STANDARD,
                          appearance: "primary",
                        },
                        {
                          value: SectionVibeMode.UPGRADE,
                          appearance: "primary",
                        },
                      ]}
                      block
                      bold
                      uppercase
                      width={200}
                    />
                  ),
                },
                {
                  name: "client-amount",
                  component: sectionVibe.clientAmount.toCurrencyFormat(),
                },
                {
                  name: "subcontractor-amount",
                  component: sectionVibe.subcontractorAmount.toCurrencyFormat(),
                },
                {
                  name: "contractor-amount",
                  component: sectionVibe.contractorAmount.toCurrencyFormat(),
                },
                {
                  name: "promoter-amount",
                  component: sectionVibe.promoterAmount.toCurrencyFormat(),
                },
                {
                  name: "contingency-amount",
                  component: sectionVibe.contingencyAmount.toCurrencyFormat(),
                },
              ],
              actions: [
                {
                  __type: "Button",
                  content: "Modifier l'ambiance",
                  show: section.state === SectionState.CONFIGURED,
                  security: {
                    permissions: [[PermissionName.SECTION_VIBE_UPDATE]],
                  },
                  onClick: () => {
                    setSectionVibeToUpdate(sectionVibe);
                  },
                },
                {
                  __type: "Button",
                  content: "Suprimer l'ambiance",
                  show: section.state === SectionState.CONFIGURED,
                  security: {
                    permissions: [[PermissionName.SECTION_VIBE_DELETE]],
                  },
                  onClick: () => {
                    setSectionVibeToDelete(sectionVibe);
                  },
                },
              ],
              rows: sectionVibe.items.map((sectionVibeItem) => {
                return {
                  key: sectionVibeItem.id,
                  columns: [
                    {
                      name: "number",
                      component: <Tag className="section-element-index">{sectionVibeItem.index + 1}</Tag>,
                    },
                    {
                      name: "name",
                      component: <Text highlight>{sectionVibeItem.sectionElement.label}</Text>,
                    },
                    {
                      name: "product",
                      component: sectionVibeItem.sectionElementOption.product.name,
                    },
                    {
                      name: "client-amount",
                      component: "--",
                    },
                    {
                      name: "subcontractor-amount",
                      component: sectionVibeItem.subcontractorAmount.toCurrencyFormat(),
                    },
                    {
                      name: "contractor-amount",
                      component: sectionVibeItem.contractorAmount.toCurrencyFormat(),
                    },
                    {
                      name: "promoter-amount",
                      component: sectionVibeItem.promoterAmount.toCurrencyFormat(),
                    },
                    {
                      name: "contingency-amount",
                      component: "--",
                    },
                  ],
                  actions: [],
                };
              }),
            };
          }),
        },
      }}
    >
      {sectionVibeToCreate && (
        <SectionVibeCreateModal
          tenantId={tenant.id}
          projectId={project.id}
          section={section}
          onCompleted={() => {
            setSectionVibeToCreate(false);
          }}
          onCancel={() => {
            setSectionVibeToCreate(false);
          }}
        />
      )}
      {sectionVibeToUpdate && (
        <SectionVibeUpdateModal
          tenantId={tenant.id}
          projectId={project.id}
          section={section}
          sectionVibe={sectionVibeToUpdate}
          onCompleted={() => {
            setSectionVibeToUpdate(null);
          }}
          onCancel={() => {
            setSectionVibeToUpdate(null);
          }}
        />
      )}
      {sectionVibeToDelete && (
        <SectionVibeDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          section={section}
          sectionVibe={sectionVibeToDelete}
          onCompleted={() => {
            setSectionVibeToDelete(null);
          }}
          onClose={() => {
            setSectionVibeToDelete(null);
          }}
        />
      )}
    </ViewList>
  );
};
