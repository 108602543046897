import { v4 as uuid } from "uuid";

declare global {
  interface String {
    toSearchFormat(): string;
    toPhoneNumberFormat(): string;
  }
  interface Number {
    toCurrencyFormat(currencyIsoCode?: "CAD"): string;
    toPercent(): string;
  }
  interface Array<T> {
    min(): number | undefined;
    max(): number | undefined;
    unique(): T[];
    uniqueBy<K>(keyGetter: (item: T) => K): T[];
  }
}

// eslint-disable-next-line
Number.prototype.toCurrencyFormat = function (currencyIsoCode = "CAD") {
  return this.toLocaleString("fr-CA", {
    style: "currency",
    currency: currencyIsoCode,
  });
};

// eslint-disable-next-line
Number.prototype.toPercent = function () {
  return (Number(this) / 100).toLocaleString("fr-CA", {
    style: "percent",
    minimumFractionDigits: 2,
  });
};

// eslint-disable-next-line
String.prototype.toSearchFormat = function (): string {
  return this.toLowerCase()
    .replace("é", "e")
    .replace("è", "e")
    .replace("à", "a")
    .replace(/[^a-z0-9]/g, "");
};

// eslint-disable-next-line
String.prototype.toPhoneNumberFormat = function (): string {
  //Filter only numbers from the input
  const cleaned = ("" + this).replace(/\D/g, "");
  //Check if the input is of correct
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "";
};

// eslint-disable-next-line
Array.prototype.max = function () {
  return this.length === 0 ? undefined : Math.max(...this);
};

// eslint-disable-next-line
Array.prototype.min = function () {
  return this.length === 0 ? undefined : Math.min(...this);
};

// eslint-disable-next-line
Array.prototype.unique = function <T>() {
  return (this as T[]).filter((value, index, array) => array.indexOf(value) === index);
};

// eslint-disable-next-line
Array.prototype.uniqueBy = function <T, K>(keyGetter: (type: T) => K) {
  const array = this as T[];
  return array.reduce<T[]>((previousArray, item) => {
    const key = keyGetter(item);
    if (!previousArray.map((item) => keyGetter(item)).includes(key)) {
      previousArray.push(item);
    }
    return previousArray;
  }, []);
};

export class Guid {
  public static newGuid = () => uuid();
}

export {};
