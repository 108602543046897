import "./index.scss";
import { FunctionComponent, PropsWithChildren } from "react";
import { Modal } from "rsuite";
import { ModalSize } from "rsuite/esm/Modal/Modal";

type Props = PropsWithChildren & {
  readonly id: string;
  readonly title: string;
  readonly centered?: boolean;
  readonly size?: ModalSize;
};

export const Dialog: FunctionComponent<Props> = ({ id, title, children, centered = false, size = "sm" }) => (
  <Modal id={id} className={`dialog${centered ? " dialog-centered" : ""}`} overflow backdrop="static" role="alertdialog" open={true} size={size}>
    <Modal.Header className="dialog-header">
      <Modal.Title className="dialog-title">{title}</Modal.Title>
    </Modal.Header>
    {children}
  </Modal>
);
