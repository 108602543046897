import "./index.scss";
import { FunctionComponent, useState } from "react";
import { Dialog, DialogBodyDelete, DialogFooterDelete } from "buildingBlocks";
import { useDeleteContractSelectionMutation } from "graphql/mutations";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly contractId: string;
  readonly contractSelection: ProjectProviderTypes.MappedProjectContractSelection;
  readonly onCompleted: () => void;
  readonly onClose: () => void;
};

export const ContractSelectionDeleteDialog: FunctionComponent<Props> = ({ tenantId, projectId, contractId, contractSelection, onCompleted, onClose }) => {
  const [value, setValue] = useState<string>("");
  const { deleteContractSelection, deleteContractSelectionLoading } = useDeleteContractSelectionMutation(onCompleted, onClose, true);
  return (
    <Dialog id="contract-selection-delete-dialog" title="Supprimer la sélection">
      <DialogBodyDelete objectName={contractSelection.label} dictionaryList={[]} value={value} setValue={setValue} />
      <DialogFooterDelete
        disabled={contractSelection.label !== value}
        loading={deleteContractSelectionLoading}
        onClose={onClose}
        onClick={async () => {
          await deleteContractSelection({
            tenantId: tenantId,
            projectId: projectId,
            contractId: contractId,
            selectionId: contractSelection.id,
          });
        }}
      />
    </Dialog>
  );
};
