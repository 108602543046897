import { FunctionComponent } from "react";
import { useCreateActivityMutation } from "graphql/mutations";
import { ViewForm } from "buildingBlocks";
import { useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { PermissionName } from "graphql/schema";
import { ActivityForm, useActivityForm } from "forms";

export const ProjectActivityCreateView: FunctionComponent = () => {
  const { navigate, toProjectActivitiesView } = useRouting();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { values, setValues } = useActivityForm({
    number: "",
    title: "",
    description: "",
  });
  const { createActivity, createActivityLoading, createActivityErrors } = useCreateActivityMutation(({ tenantId, projectId }) => {
    navigate(
      toProjectActivitiesView({
        tenantId: tenantId,
        projectId: projectId,
      })
    );
  });
  return (
    <ViewForm
      id="project-activity-create-view"
      title="Créer une activité"
      security={{
        permissions: [[PermissionName.ACTIVITY_READ, PermissionName.ACTIVITY_CREATE]],
      }}
      errors={createActivityErrors}
      loading={createActivityLoading}
      onSubmit={async () => {
        await createActivity({
          tenantId: tenant.id,
          projectId: project.id,
          number: values.number,
          title: values.title,
          description: values.description.trim() || null,
        });
      }}
      onCancel={() => {
        navigate(
          toProjectActivitiesView({
            tenantId: tenant.id,
            projectId: project.id,
          })
        );
      }}
    >
      <ActivityForm values={values} setValues={setValues} />
    </ViewForm>
  );
};
