import "./index.scss";
import { FormContainer, getEnumLabel } from "buildingBlocks";
import produce from "immer";
import { FunctionComponent, useState } from "react";
import { Form, Input, InputNumber, SelectPicker } from "rsuite";
import { ContractSelectionWorkflow, InvoiceTermsOfPayment } from "graphql/schema";
import { MappedTaxGroup } from "providers";

type ContractSelectionCreateFormValues = {
  readonly taxGroupId: string;
  readonly label: string;
  readonly terms: InvoiceTermsOfPayment;
  readonly creditAmount: number;
  readonly mixAndMatchAmount: number;
  readonly workflow: ContractSelectionWorkflow;
};

type Props = {
  readonly values: ContractSelectionCreateFormValues;
  readonly setValues: (values: ContractSelectionCreateFormValues) => void;
  readonly taxGroups: ReadonlyArray<MappedTaxGroup>;
};

export const useContractSelectionCreateForm = (initialValues: ContractSelectionCreateFormValues) => {
  const [values, setValues] = useState<ContractSelectionCreateFormValues>(initialValues);
  return { values, setValues };
};

export const ContractSelectionCreateForm: FunctionComponent<Props> = ({ values, setValues, taxGroups }) => (
  <Form id="contract-selection-create-form">
    <FormContainer highlight>
      <Form.Group>
        <Form.ControlLabel>Étiquette</Form.ControlLabel>
        <Input readOnly value={values.label} />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Groupe de taxe(s)</Form.ControlLabel>
        <SelectPicker
          readOnly
          value={values.taxGroupId}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.taxGroupId = value!;
              })
            );
          }}
          cleanable
          data={taxGroups.map((taxGroup) => ({
            value: taxGroup.id,
            label: taxGroup.name,
          }))}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Condition de paiement</Form.ControlLabel>
        <SelectPicker
          value={values.terms}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.terms = value as InvoiceTermsOfPayment;
              })
            );
          }}
          cleanable
          data={[
            {
              label: getEnumLabel(InvoiceTermsOfPayment.NET0),
              value: InvoiceTermsOfPayment.NET0,
            },
            {
              label: getEnumLabel(InvoiceTermsOfPayment.NET7),
              value: InvoiceTermsOfPayment.NET7,
            },
            {
              label: getEnumLabel(InvoiceTermsOfPayment.NET15),
              value: InvoiceTermsOfPayment.NET15,
            },
            {
              label: getEnumLabel(InvoiceTermsOfPayment.NET21),
              value: InvoiceTermsOfPayment.NET21,
            },
            {
              label: getEnumLabel(InvoiceTermsOfPayment.NET30),
              value: InvoiceTermsOfPayment.NET30,
            },
            {
              label: getEnumLabel(InvoiceTermsOfPayment.NET45),
              value: InvoiceTermsOfPayment.NET45,
            },
            {
              label: getEnumLabel(InvoiceTermsOfPayment.NET60),
              value: InvoiceTermsOfPayment.NET60,
            },
          ]}
        />
      </Form.Group>
    </FormContainer>
    <FormContainer>
      <Form.Group>
        <Form.ControlLabel>Flux de travail</Form.ControlLabel>
        <SelectPicker
          value={values.workflow}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.workflow = value as ContractSelectionWorkflow;
              })
            );
          }}
          cleanable
          data={[
            {
              label: getEnumLabel(ContractSelectionWorkflow.WITH_CUSTOMER),
              value: ContractSelectionWorkflow.WITH_CUSTOMER,
            },
            {
              label: getEnumLabel(ContractSelectionWorkflow.WITHOUT_CUSTOMER),
              value: ContractSelectionWorkflow.WITHOUT_CUSTOMER,
            },
          ]}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Montant d'un mix and match</Form.ControlLabel>
        <InputNumber
          min={0}
          value={values.mixAndMatchAmount}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.mixAndMatchAmount = Number(value);
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Montant du crédit</Form.ControlLabel>
        <InputNumber
          min={0}
          value={values.creditAmount}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.creditAmount = Number(value);
              })
            );
          }}
        />
      </Form.Group>
    </FormContainer>
  </Form>
);
