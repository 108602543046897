import "./index.scss";
import { useDeleteContractInvoicePaymentMutation } from "graphql/mutations";
import { FunctionComponent, useState } from "react";
import { Dialog, DialogBodyDelete, DialogFooterDelete } from "buildingBlocks";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly contract: ProjectProviderTypes.MappedProjectContract;
  readonly payment: ProjectProviderTypes.MappedProjectPayment;
  readonly onCompleted: () => void;
  readonly onClose: () => void;
};

export const ContractInvoicePaymentDeleteDialog: FunctionComponent<Props> = ({ tenantId, projectId, contract, payment, onCompleted, onClose }) => {
  const [value, setValue] = useState<string>("");
  const { deleteContractInvoicePayment, deleteContractInvoicePaymentLoading } = useDeleteContractInvoicePaymentMutation(onCompleted, onClose, true);
  return (
    <Dialog id="contract-invoice-payment-delete-dialog" title="Supprimer le paiement">
      <DialogBodyDelete objectName={payment.label} dictionaryList={[]} value={value} setValue={setValue} />
      <DialogFooterDelete
        disabled={payment.label !== value}
        loading={deleteContractInvoicePaymentLoading}
        onClose={onClose}
        onClick={async () => {
          await deleteContractInvoicePayment({
            tenantId: tenantId,
            projectId: projectId,
            contractId: contract.id,
            invoiceId: payment.invoiceId!,
            paymentId: payment.id,
          });
        }}
      />
    </Dialog>
  );
};
