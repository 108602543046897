import "./index.scss";
import { FunctionComponent } from "react";
import { ViewDetail } from "buildingBlocks";
import { useProjectContractContext, useProjectContractSelectionContext, useRouting } from "hooks";
import { PermissionName } from "graphql/schema";
import { useProjectContext, useTenantContext } from "providers";

export const ProjectContractSelectionGroupView: FunctionComponent = () => {
  const { navigate, toProjectContractSelectionsView } = useRouting();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { contract } = useProjectContractContext();
  const { contractSelection } = useProjectContractSelectionContext();
  return (
    <ViewDetail
      id="project-contract-selection-group-view"
      security={{
        permissions: [[PermissionName.CONTRACT_READ]],
      }}
      header={{
        title: contractSelection.label,
        subtitle: contract.unit.label,
        returnButton: {
          onClick: () => {
            navigate(
              toProjectContractSelectionsView({
                tenantId: tenant.id,
                projectId: project.id,
                contractId: contract.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [],
          otherActions: [],
        },
      }}
    ></ViewDetail>
  );
};
