import "./index.scss";
import { FunctionComponent } from "react";
import { useAppContext } from "providers";
import { IconButton } from "rsuite";
import { useRouting } from "hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { TenantType } from "graphql/schema";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TenantsView: FunctionComponent = () => {
  const { navigate, toTenantView } = useRouting();
  const { logout } = useAuth0();
  const { tenants } = useAppContext();
  return (
    <main id="tenants-view">
      <nav>
        <div>Post Sales CRM</div>
        <IconButton
          className="btn-logout"
          appearance="link"
          icon={<FontAwesomeIcon icon={faRightFromBracket} />}
          onClick={() => {
            logout();
          }}
        />
      </nav>
      <section>
        <div>
          <h1>Organisations</h1>
          <p>Sélectionner une organisation ci-dessous...</p>
          <ul>
            {tenants
              .filter((tenant) => tenant.type === TenantType.PRINCIPAL)
              .map((tenant) => (
                <li
                  onClick={() => {
                    navigate(
                      toTenantView({
                        tenantId: tenant.id,
                      })
                    );
                  }}
                  key={tenant.id}
                >
                  <span>{tenant.name}</span>
                </li>
              ))}
          </ul>
        </div>
      </section>
    </main>
  );
};
