import "./index.scss";
import { useCreateSectionVibeMutation } from "graphql/mutations";
import { FunctionComponent } from "react";
import { ModalForm } from "buildingBlocks";
import { SectionVibeForm, useSectionVibeForm } from "forms";
import { SectionVibeMode } from "graphql/schema";
import { Guid } from "utils";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly section: ProjectProviderTypes.MappedProjectSection;
  readonly onCompleted: () => void;
  readonly onCancel: () => void;
};

export const SectionVibeCreateModal: FunctionComponent<Props> = ({ tenantId, projectId, section, onCompleted, onCancel }) => {
  const { values, setValues } = useSectionVibeForm({
    name: "",
    mode: SectionVibeMode.STANDARD,
    clientAmount: 0,
    costAmount: 0,
    contingencyAmount: 0,
    items: section
      .getElementsWithoutSets()
      .filter((sectionElement) => sectionElement.isManagedByVibes)
      .map((sectionElement) => ({
        id: Guid.newGuid(),
        index: sectionElement.index,
        sectionElementId: sectionElement.id,
        sectionElementOptionId: "",
        subcontractorAmount: 0,
        contractorAmount: 0,
        promoterAmount: 0,
      })),
  });
  const { createSectionVibe, createSectionVibeErrors, createSectionVibeLoading } = useCreateSectionVibeMutation(onCompleted, undefined, false);
  return (
    <ModalForm
      id="section-vibe-create-modal"
      title="Créer une ambiance"
      size="lg"
      errors={createSectionVibeErrors}
      loading={createSectionVibeLoading}
      disabled={values.name.trim() === "" || values.items.some((item) => item.sectionElementOptionId === "") || (values.mode === SectionVibeMode.UPGRADE && values.costAmount === 0)}
      submitButton={{
        onClick: async () => {
          await createSectionVibe({
            tenantId: tenantId,
            projectId: projectId,
            sectionId: section.id,
            name: values.name,
            mode: values.mode,
            clientAmount: values.clientAmount,
            items: values.items.map((item) => ({
              sectionElementId: item.sectionElementId,
              sectionElementOptionId: item.sectionElementOptionId,
            })),
          });
        },
      }}
      closeButton={{
        text: "Annuler",
        onClick: onCancel,
      }}
    >
      <SectionVibeForm values={values} setValues={setValues} section={section} />
    </ModalForm>
  );
};
