import "./index.scss";
import { FormContainer, FormPanel } from "buildingBlocks";
import produce from "immer";
import { FunctionComponent, useState } from "react";
import { Form, Input, SelectPicker } from "rsuite";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { Guid } from "utils";
import { SectionState } from "graphql/schema";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type FormFormValues = {
  readonly name: string;
  readonly modelId: string | null;
  readonly sections: ReadonlyArray<{
    readonly id: string;
    readonly index: number;
    readonly title: string;
    readonly sectionId: string;
    readonly isDeleted: boolean;
  }>;
};

type Props = {
  readonly values: FormFormValues;
  readonly setValues: (values: FormFormValues) => void;
  readonly sections: ReadonlyArray<ProjectProviderTypes.MappedProjectSection>;
  readonly models: ReadonlyArray<ProjectProviderTypes.MappedProjectModel>;
};

export const useFormForm = (initialValues: FormFormValues) => {
  const [values, setValues] = useState<FormFormValues>(initialValues);
  return { values, setValues };
};

export const FormForm: FunctionComponent<Props> = ({ values, setValues, sections, models }) => (
  <Form id="form-form">
    <FormContainer highlight>
      <Form.Group>
        <Form.ControlLabel>Nom</Form.ControlLabel>
        <Input
          value={values.name}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.name = value;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Modèle</Form.ControlLabel>
        <SelectPicker
          value={values.modelId}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.modelId = value;
              })
            );
          }}
          onClean={() => {
            setValues(
              produce(values, (draft) => {
                draft.modelId = null;
              })
            );
          }}
          cleanable
          data={models.map((model) => ({
            label: model.name,
            value: model.id,
          }))}
        />
      </Form.Group>
    </FormContainer>
    <FormContainer
      fluid
      empty={{
        show: values.sections.length === 0,
        label: "Aucune section",
        content: "Pour créer une section de formulaire cliquez sur le bouton 'Ajouter une section' en haut à droite.",
      }}
      header={{
        title: "Sections",
        actions: [
          {
            name: "Ajouter un section",
            icon: faAdd,
            disabled: false,
            onClick: () => {
              setValues(
                produce(values, (draft) => {
                  draft.sections.push({
                    id: Guid.newGuid(),
                    index: draft.sections.length,
                    title: "",
                    sectionId: "",
                    isDeleted: false,
                  });
                })
              );
            },
          },
        ],
      }}
    >
      {[...values.sections]
        .sort((left, right) => left.index - right.index)
        .map((section) => (
          <FormPanel
            key={section.id}
            header={{
              start: {
                index: section.index,
                buttons: {
                  increase: {
                    disabled: section.index === 0,
                    onClick: () => {
                      setValues(
                        produce(values, (draft) => {
                          const draftSectionToIncrease = draft.sections.find((draftSection) => draftSection.index === section.index);
                          const draftSectionToDecrease = draft.sections.find((draftSection) => draftSection.index === section.index - 1);
                          if (draftSectionToIncrease && draftSectionToDecrease) {
                            draftSectionToIncrease.index = draftSectionToIncrease.index - 1;
                            draftSectionToDecrease.index = draftSectionToDecrease.index + 1;
                          }
                        })
                      );
                    },
                  },
                  decrease: {
                    disabled: section.index === values.sections.length - 1,
                    onClick: () => {
                      setValues(
                        produce(values, (draft) => {
                          const draftSectionToDecrease = draft.sections.find((draftSection) => draftSection.index === section.index);
                          const draftSectionToIncrease = draft.sections.find((draftSection) => draftSection.index === section.index + 1);
                          if (draftSectionToIncrease && draftSectionToDecrease) {
                            draftSectionToIncrease.index = draftSectionToIncrease.index - 1;
                            draftSectionToDecrease.index = draftSectionToDecrease.index + 1;
                          }
                        })
                      );
                    },
                  },
                },
              },
              center: {
                inputs: [
                  {
                    name: "title",
                    label: "Titre",
                    node: (
                      <Input
                        size="sm"
                        value={section.title}
                        onChange={(value) => {
                          setValues(
                            produce(values, (draft) => {
                              const draftSection = draft.sections.find((draftSection) => draftSection.id === section.id);
                              if (draftSection) {
                                draftSection.title = value;
                              }
                            })
                          );
                        }}
                      />
                    ),
                  },
                  {
                    name: "ref",
                    label: "Référence",
                    node: (
                      <SelectPicker
                        size="sm"
                        value={section.sectionId}
                        onChange={(value) => {
                          setValues(
                            produce(values, (draft) => {
                              const draftSection = draft.sections.find((draftSection) => draftSection.id === section.id);
                              if (draftSection) {
                                draftSection.sectionId = value!;
                                const data = sections.find((section) => section.id === draftSection.sectionId);
                                if (data) {
                                  draftSection.title = data.title;
                                }
                              }
                            })
                          );
                        }}
                        cleanable={false}
                        data={sections
                          .filter((section) => section.state === SectionState.PUBLISHED)
                          .filter((section) => section.modelLink === null || section.modelLink.model.id === values.modelId)
                          .map((section) => ({
                            label: section.title,
                            value: section.id,
                          }))}
                      />
                    ),
                  },
                ],
              },
              end: {
                buttons: [
                  {
                    name: "Supprimer la section",
                    onClick: () => {
                      setValues(
                        produce(values, (draft) => {
                          draft.sections = draft.sections
                            .filter((draftSection) => draftSection.id !== section.id)
                            .map((draftSection, draftSectionIndex) => ({
                              ...draftSection,
                              index: draftSectionIndex,
                            }));
                        })
                      );
                    },
                  },
                ],
              },
            }}
          ></FormPanel>
        ))}
    </FormContainer>
  </Form>
);
