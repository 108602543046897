import "./index.scss";
import { ViewForm } from "buildingBlocks";
import { FormLinkForm, useFormLinkForm } from "forms";
import { useProjectFormContext, useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { FunctionComponent, useState } from "react";
import { PermissionName, ReportFragment } from "graphql/schema";
import { SelectPicker } from "rsuite";
import { useLinkFormMutation } from "graphql/mutations";

export const ProjectFormLinkView: FunctionComponent = () => {
  const { navigate, toProjectFormsView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, reports } = useProjectContext();
  const [report, setReport] = useState<ReportFragment>(reports[0]);
  const { form } = useProjectFormContext();
  const { values, setValues } = useFormLinkForm({
    reportRoomElementLinks: form.reportRoomElementLinks
      .filter((link) => link.reportId === report.id)
      .map((link) => ({
        formSectionId: link.formSectionId,
        sectionElementId: link.sectionElementId,
        reportRoomId: link.reportRoomId,
        reportRoomElementId: link.reportRoomElementId,
      })),
    reportVibeRoomLinks: form.reportVibeRoomLinks
      .filter((link) => link.reportId === report.id)
      .map((link) => ({
        formSectionId: link.formSectionId,
        reportRoomId: link.reportRoomId,
      })),
  });
  const { linkForm, linkFormLoading, linkFormErrors } = useLinkFormMutation(
    ({ tenantId, projectId }) => {
      navigate(
        toProjectFormsView({
          tenantId: tenantId,
          projectId: projectId,
        })
      );
    },
    undefined,
    true
  );
  return (
    <ViewForm
      id="project-form-link-view"
      title={
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Lier le formulaire au rapport
          <SelectPicker
            size="lg"
            value={report.id}
            onChange={(value) => {
              if (report.id !== value) {
                const report = reports.find((report) => report.id === value)!;
                setValues({
                  reportRoomElementLinks: form.reportRoomElementLinks
                    .filter((link) => link.reportId === report.id)
                    .map((link) => ({
                      formSectionId: link.formSectionId,
                      sectionElementId: link.sectionElementId,
                      reportRoomId: link.reportRoomId,
                      reportRoomElementId: link.reportRoomElementId,
                    })),
                  reportVibeRoomLinks: form.reportVibeRoomLinks
                    .filter((link) => link.reportId === report.id)
                    .map((link) => ({
                      formSectionId: link.formSectionId,
                      reportRoomId: link.reportRoomId,
                    })),
                });
                setReport(report);
              }
            }}
            data={reports.map((report) => ({
              label: report.name.toLowerCase(),
              value: report.id,
            }))}
            searchable={false}
            cleanable={false}
          />
        </div>
      }
      security={{
        permissions: [[PermissionName.FORM_READ, PermissionName.FORM_LINK]],
      }}
      loading={linkFormLoading}
      errors={linkFormErrors}
      disabled={values.reportVibeRoomLinks.some((link) => link.reportRoomId === "") || values.reportRoomElementLinks.some((link) => link.reportRoomElementId === "")}
      onSubmit={async () => {
        await linkForm({
          tenantId: tenant.id,
          projectId: project.id,
          formId: form.id,
          reportId: report.id,
          reportVibeRoomLinks: values.reportVibeRoomLinks.map((link) => ({
            formSectionId: link.formSectionId,
            reportRoomId: link.reportRoomId,
          })),
          reportRoomElementLinks: values.reportRoomElementLinks.map((link) => ({
            formSectionId: link.formSectionId,
            sectionElementId: link.sectionElementId,
            reportRoomId: link.reportRoomId,
            reportRoomElementId: link.reportRoomElementId,
          })),
        });
      }}
      onCancel={() => {
        navigate(
          toProjectFormsView({
            tenantId: tenant.id,
            projectId: project.id,
          })
        );
      }}
    >
      <FormLinkForm values={values} setValues={setValues} form={form} report={report} />
    </ViewForm>
  );
};
