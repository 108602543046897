import { FunctionComponent } from "react";
import { ViewForm } from "buildingBlocks";
import { useRouting } from "hooks";
import { useUpdateProjectMutation } from "graphql/mutations";
import { useAppContext, useTenantContext } from "providers";
import { ProjectForm, useProjectForm } from "forms";
import { useParams } from "react-router-dom";

export const useProjectContext = () => {
  const { projectId } = useParams<{ readonly projectId: string }>();
  const { projects } = useTenantContext();
  return {
    project: projects.find((project) => project.id === projectId)!,
  };
};

export const ProjectUpdateView: FunctionComponent = () => {
  const { navigate, toProjectsView } = useRouting();
  const { countries } = useAppContext();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { values, setValues } = useProjectForm({
    code: project.code,
    name: project.name,
    description: project.description ?? "",
    contactInfo: {
      companyName: project.contactInfo.companyName,
      email: project.contactInfo.email,
      phone: project.contactInfo.phone,
      websiteUrl: project.contactInfo.websiteUrl,
      federalTaxNumber: project.contactInfo.federalTaxNumber ?? "",
      provincialTaxNumber: project.contactInfo.provincialTaxNumber ?? "",
      address: {
        countryCode: project.contactInfo.address.countryCode,
        line: project.contactInfo.address.line,
        city: project.contactInfo.address.city,
        subdivisionCode: project.contactInfo.address.subdivisionCode,
        postalCode: project.contactInfo.address.postalCode,
      },
    },
  });
  const { updateProject, updateProjectLoading, updateProjectErrors } = useUpdateProjectMutation(() => {
    navigate(
      toProjectsView({
        tenantId: tenant.id,
      })
    );
  });
  return (
    <ViewForm
      id="project-update-view"
      title="Modifier le projet"
      security={{
        isTenantProjectManager: true,
      }}
      errors={updateProjectErrors}
      loading={updateProjectLoading}
      onSubmit={async () => {
        await updateProject({
          tenantId: tenant.id,
          projectId: project.id,
          code: values.code,
          name: values.name,
          description: values.description.trim() || null,
          contactInfo: {
            companyName: values.contactInfo.companyName,
            email: values.contactInfo.email,
            phone: values.contactInfo.phone,
            websiteUrl: values.contactInfo.websiteUrl,
            federalTaxNumber: values.contactInfo.federalTaxNumber.trim() || null,
            provincialTaxNumber: values.contactInfo.provincialTaxNumber.trim() || null,
            address: {
              countryCode: values.contactInfo.address.countryCode,
              line: values.contactInfo.address.line,
              city: values.contactInfo.address.city,
              subdivisionCode: values.contactInfo.address.subdivisionCode,
              postalCode: values.contactInfo.address.postalCode,
            },
          },
        });
      }}
      onCancel={() => {
        navigate(
          toProjectsView({
            tenantId: tenant.id,
          })
        );
      }}
    >
      <ProjectForm values={values} setValues={setValues} countries={countries} />
    </ViewForm>
  );
};
