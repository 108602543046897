import { FunctionComponent } from "react";
import { ViewForm } from "buildingBlocks";
import { useCreateCustomerMutation } from "graphql/mutations";
import { useRouting } from "hooks";
import { useAppContext, useTenantContext } from "providers";
import { Gender, Language, PermissionName } from "graphql/schema";
import { CustomerForm, useCustomerForm } from "forms";

export const CustomerCreateView: FunctionComponent = () => {
  const { navigate, toTenantCustomerView, toTenantCustomersView } = useRouting();
  const { countries } = useAppContext();
  const { tenant } = useTenantContext();
  const { values, setValues } = useCustomerForm({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: Gender.MALE,
    language: Language.FR,
    notes: "",
    address: {
      countryCode: "",
      line: "",
      city: "",
      subdivisionCode: "",
      postalCode: "",
    },
  });
  const { createCustomer, createCustomerLoading, createCustomerErrors } = useCreateCustomerMutation(({ customer }) => {
    navigate(
      toTenantCustomerView({
        tenantId: tenant.id,
        customerId: customer.id,
      })
    );
  });
  return (
    <ViewForm
      id="customer-create-view"
      title="Créer un client"
      security={{
        permissions: [[PermissionName.CUSTOMER_READ, PermissionName.CUSTOMER_CREATE]],
      }}
      errors={createCustomerErrors}
      loading={createCustomerLoading}
      onSubmit={async () => {
        await createCustomer({
          tenantId: tenant.id,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          language: values.language,
          gender: values.gender,
          notes: values.notes.trim() || null,
          address: {
            countryCode: values.address.countryCode,
            line: values.address.line,
            city: values.address.city,
            subdivisionCode: values.address.subdivisionCode,
            postalCode: values.address.postalCode,
          },
        });
      }}
      onCancel={() => {
        navigate(
          toTenantCustomersView({
            tenantId: tenant.id,
          })
        );
      }}
    >
      <CustomerForm values={values} setValues={setValues} countries={countries} />
    </ViewForm>
  );
};
