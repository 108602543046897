import "./index.scss";
import { FunctionComponent } from "react";
import { Button } from "rsuite";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  readonly index: number;
  readonly buttons: {
    readonly increase: {
      readonly disabled: boolean;
      readonly onClick: () => void;
    };
    readonly decrease: {
      readonly disabled: boolean;
      readonly onClick: () => void;
    };
  };
};

export const InputIndex: FunctionComponent<Props> = ({ index, buttons }) => {
  return (
    <div className="input-index">
      <div className="input-index-buttons">
        <Button tabIndex={-1} disabled={buttons.increase.disabled} size="xs" appearance="link" onClick={buttons.increase.onClick}>
          <FontAwesomeIcon size="xs" icon={faAngleUp} />
        </Button>
        <Button tabIndex={-1} disabled={buttons.decrease.disabled} size="xs" appearance="link" onClick={buttons.decrease.onClick}>
          <FontAwesomeIcon size="xs" icon={faAngleDown} />
        </Button>
      </div>
      <span className="input-index-value">{index + 1}</span>
    </div>
  );
};
