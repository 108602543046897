import { DialogBody } from "buildingBlocks/DialogBody";
import "./index.scss";
import { FunctionComponent, PropsWithChildren, ReactNode } from "react";
import { Form, Input, Tag } from "rsuite";
import FormControlLabel from "rsuite/esm/FormControlLabel";
import FormGroup from "rsuite/esm/FormGroup";

type Props = PropsWithChildren & {
  readonly value: string;
  readonly setValue: (value: string) => void;
  readonly objectName: string;
  readonly dictionaryList: ReadonlyArray<{
    readonly title: string;
    readonly description: ReactNode;
  }>;
};

export const DialogBodyDelete: FunctionComponent<Props> = ({ value, setValue, dictionaryList, objectName }) => (
  <DialogBody className="dialog-body-delete">
    <p className="dialog-danger-zone">Vous êtes sur le point de faire une action définitive. Cette action est irréversible et ne peut pas être annulée.</p>
    {dictionaryList.length !== 0 && (
      <dl className="dialog-definition-list">
        {dictionaryList.map((item, index) => (
          <div className="dialog-definition-item" key={index}>
            <dt>{item.title}</dt>
            <dd>{item.description}</dd>
          </div>
        ))}
      </dl>
    )}
    <Form>
      <FormGroup>
        <FormControlLabel>
          Pour confirmer la suppression, veuillez écrire{" "}
          <Tag className="dialog-object-name" size="sm">
            {objectName}
          </Tag>{" "}
          ci-dessous.
        </FormControlLabel>
        <Input value={value} onChange={setValue} size="md" />
      </FormGroup>
    </Form>
  </DialogBody>
);
