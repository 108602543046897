import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { SearchInputGroup, ViewList, Text } from "buildingBlocks";
import { useRouting } from "hooks";
import { useTenantContext } from "providers";
import { PermissionName, ProviderFragment } from "graphql/schema";
import { ProviderDeleteDialog } from "dialogs";

const useProvidersViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback((providers: ReadonlyArray<ProviderFragment>) => providers.filter((provider) => provider.name.toSearchFormat().includes(search.toSearchFormat())), [search]),
  };
};

export const ProvidersView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useProvidersViewFilters();
  const { navigate, toTenantProviderView, toTenantProviderCreateView, toTenantProviderUpdateView } = useRouting();
  const { tenant, providers } = useTenantContext();
  const [providerToDelete, setProviderToDelete] = useState<ProviderFragment | null>(null);
  return (
    <ViewList
      id="providers-view"
      security={{
        permissions: [[PermissionName.PROVIDER_READ]],
      }}
      header={{
        title: "Tous les fournisseurs",
        toolbar: {
          buttons: [
            {
              __type: "Button",
              text: "Créer un fournisseur",
              security: {
                permissions: [[PermissionName.PROVIDER_CREATE]],
              },
              show: true,
              onClick: () => {
                navigate(
                  toTenantProviderCreateView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [<SearchInputGroup size="xs" placeholder="Recherche par nom" value={filterValues.search} onChange={filterFunctions.setSearch} />],
            applyButton: null,
          },
          right: {
            inputs: [],
          },
        },
      }}
      content={{
        table: {
          emptyBodies: [
            {
              show: providers.length === 0,
              title: "Aucun fournisseur",
              content: "Pour créer un fournisseur, cliquez sur le bouton ci-dessous.",
              button: {
                text: "Créer un fournisseur",
                security: {
                  permissions: [[PermissionName.PROVIDER_CREATE]],
                },
                onClick: () => {
                  navigate(
                    toTenantProviderCreateView({
                      tenantId: tenant.id,
                    })
                  );
                },
              },
            },
          ],
          headColumns: [
            {
              name: "name",
              value: "NOM",
            },
            {
              name: "description",
              value: "DESCRIPTION",
            },
            {
              name: "websiteUrl",
              value: "SITE WEB",
            },
          ],
          bodyRows: applyFilters(providers).map((provider) => {
            return {
              key: provider.id,
              href: toTenantProviderView({
                tenantId: tenant.id,
                providerId: provider.id,
              }),
              columns: [
                {
                  name: "name",
                  component: <Text highlight>{provider.name}</Text>,
                },
                {
                  name: "description",
                  component: provider.description,
                },
                {
                  name: "websiteUrl",
                  component: provider.websiteUrl,
                },
              ],
              actions: [
                {
                  __type: "Button",
                  content: "Modifier le fournisseur",
                  security: {
                    permissions: [[PermissionName.PROVIDER_UPDATE]],
                  },
                  show: true,
                  onClick: () => {
                    navigate(
                      toTenantProviderUpdateView({
                        tenantId: tenant.id,
                        providerId: provider.id,
                      })
                    );
                  },
                },
                {
                  __type: "Button",
                  content: "Supprimer le fournisseur",
                  security: {
                    permissions: [[PermissionName.PROVIDER_DELETE]],
                  },
                  show: true,
                  onClick: () => {
                    setProviderToDelete(provider);
                  },
                },
              ],
            };
          }),
          bodyRowGroups: [],
        },
      }}
    >
      {providerToDelete && (
        <ProviderDeleteDialog
          tenantId={tenant.id}
          provider={providerToDelete}
          onCompleted={() => {
            setProviderToDelete(null);
          }}
          onClose={() => {
            setProviderToDelete(null);
          }}
        />
      )}
    </ViewList>
  );
};
