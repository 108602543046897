import "./index.scss";
import { ViewForm } from "buildingBlocks";
import { useUpdateUnitsMutation } from "graphql/mutations";
import { useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { FunctionComponent } from "react";
import { UnitsForm, useUnitsForm } from "forms";
import { PermissionName } from "graphql/schema";

export const ProjectUnitsUpdateView: FunctionComponent = () => {
  const { navigate, toProjectView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, forms, units } = useProjectContext();
  const { updateUnits, updateUnitsLoading } = useUpdateUnitsMutation(
    ({ tenantId, projectId }) => {
      navigate(
        toProjectView({
          tenantId: tenantId,
          projectId: projectId,
        })
      );
    },
    undefined,
    true
  );
  const { values, setValues } = useUnitsForm({
    units: units.map((unit) => ({
      id: unit.id,
      number: unit.number,
      floor: unit.floor,
      formId: unit.formId,
    })),
  });
  return (
    <ViewForm
      id="project-units-update-view"
      title="Modifier les unités"
      security={{
        permissions: [[PermissionName.UNIT_READ, PermissionName.UNIT_UPDATE]],
      }}
      loading={updateUnitsLoading}
      onSubmit={async () => {
        await updateUnits({
          tenantId: tenant.id,
          projectId: project.id,
          units: values.units.map((unit) => ({
            id: unit.id,
            formId: unit.formId,
          })),
        });
      }}
      onCancel={() => {
        navigate(
          toProjectView({
            tenantId: tenant.id,
            projectId: project.id,
          })
        );
      }}
    >
      <UnitsForm values={values} setValues={setValues} forms={forms} />
    </ViewForm>
  );
};
