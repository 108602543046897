import "./index.scss";
import { FunctionComponent } from "react";
import { SectionElementFormValues, SectionElementOptionFormValues, SectionFormValues } from "forms";
import { SectionElementFormPanel } from "components";
import { SectionElementOptionType } from "graphql/schema";
import produce from "immer";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly sectionElement: SectionElementFormValues;
  readonly values: SectionFormValues;
  readonly setValues: (values: SectionFormValues) => void;
  readonly model: ProjectProviderTypes.MappedProjectModel | null;
  readonly products: ReadonlyArray<ProjectProviderTypes.MappedProjectProduct>;
  readonly onSectionElementOptionCostAmountUpdated: (sectionElement: SectionElementFormValues, sectionElementOption: SectionElementOptionFormValues) => void;
};

export const SectionElementTypeUniqueFormPanel: FunctionComponent<Props> = ({ sectionElement, values, setValues, model, products, onSectionElementOptionCostAmountUpdated }) => {
  let filteredProducts = products;
  if (model && sectionElement.modelLink !== null) {
    const { modelRoomId, modelRoomElementId } = sectionElement.modelLink;
    const modelRoom = model.rooms.find((modelRoom) => modelRoom.id === modelRoomId)!;
    const modelRoomElement = modelRoom.elements.find((modelRoomElement) => modelRoomElement.id === modelRoomElementId)!;
    filteredProducts = filteredProducts.filter((product) => modelRoomElement.productIds.includes(product.id));
  }
  const productIds = values.elements.reduce<string[]>((previousValue, currentValue) => previousValue.concat(currentValue.options.map((option) => option.productId)), []);
  return (
    <SectionElementFormPanel
      sectionElement={sectionElement}
      values={values}
      setValues={setValues}
      products={filteredProducts}
      isHideProductCategoryDisabled={
        sectionElement.isManagedByVibes
          ? filteredProducts
              .filter((product) => productIds.includes(product.id))
              .map((product) => product.categoryId)
              .unique().length > 1
          : values.elements
              .find((element) => element.id === sectionElement.id)!
              .options.map((option) => products.find((product) => product.id === option.productId)?.categoryId ?? null)
              .filter((categoryId) => categoryId !== null)
              .unique().length > 1
      }
      isOptionalDisabled={sectionElement.isManagedByVibes}
      isDeleteOptionDisabled={(sectionElement, sectionElementOption) => {
        return false;
      }}
      isAddOptionButtonDisabled={
        sectionElement.isManagedByVibes
          ? filteredProducts.length === values.elements.find((element) => element.id === sectionElement.id)!.options.length
          : sectionElement.modelLink !== null
            ? filteredProducts.length === values.elements.find((element) => element.id === sectionElement.id)!.options.length
            : false
      }
      sectionElementOptionTypes={
        sectionElement.isManagedByVibes
          ? [SectionElementOptionType.MIX_AND_MATCH, SectionElementOptionType.EXTRA, SectionElementOptionType.CREDIT_SUBCONTRACTOR]
          : [SectionElementOptionType.INCLUDED, SectionElementOptionType.EXTRA, SectionElementOptionType.CREDIT_SUBCONTRACTOR]
      }
      onSectionElementOptionCostAmountUpdated={onSectionElementOptionCostAmountUpdated}
      // onSectionElementTypeChange={(type) => {
      //   setValues(
      //     produce(values, (draft) => {
      //       const draftElement = draft.elements.find((draftElement) => draftElement.id === sectionElement.id);
      //       if (draftElement) {
      //         draftElement.type = type;
      //         draftElement.groupId = null;
      //         draftElement.isOptional = false;
      //         if (draftElement.isManagedByVibes) {
      //           for (const draftElementOption of draftElement.options) {
      //             if (draftElementOption.type === SectionElementOptionType.MIX_AND_MATCH) {
      //               draftElementOption.type = SectionElementOptionType.INCLUDED;
      //               draftElementOption.clientAmount = 0;
      //               for (const draftElementOptionBbreakdownLine of draftElementOption.breakdownLines) {
      //                 draftElementOptionBbreakdownLine.subcontractorAmount = 0;
      //                 draftElementOptionBbreakdownLine.contractorAmount = 0;
      //                 draftElementOptionBbreakdownLine.promoterAmount = 0;
      //               }
      //             }
      //           }
      //         } else {
      //           for (const draftElementOption of draftElement.options) {
      //             if (draftElementOption.type === SectionElementOptionType.INCLUDED) {
      //               draftElementOption.type = SectionElementOptionType.MIX_AND_MATCH;
      //             }
      //           }
      //         }
      //       }
      //     })
      //   );
      // }}
      onSectionElementOptionTypeChange={(type, sectionElementOption) => {
        setValues(
          produce(values, (draft) => {
            const draftElement = draft.elements.find((draftElement) => draftElement.id === sectionElement.id);
            if (draftElement) {
              const draftElementOption = draftElement.options.find((draftElementOption) => draftElementOption.id === sectionElementOption.id);
              if (draftElementOption) {
                draftElementOption.type = type;
                if (!draftElement.isManagedByVibes) {
                  draftElementOption.clientAmount = 0;
                }
                draftElementOption.breakdownLines = draftElementOption.breakdownLines.map((draftElementOptionBbreakdownLine) => ({
                  ...draftElementOptionBbreakdownLine,
                  subcontractorAmount: 0,
                  contractorAmount: 0,
                  promoterAmount: 0,
                }));
              }
            }
          })
        );
      }}
    />
  );
};
