import { FormContainer, getEnumLabel } from "buildingBlocks";
import "./index.scss";
import { RefundMode } from "graphql/schema";
import produce from "immer";
import { FunctionComponent, useState } from "react";
import { DatePicker, Form, Input, InputGroup, InputNumber, SelectPicker } from "rsuite";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type ContractInvoiceRefundFormValues = {
  readonly label: string;
  readonly date: Date;
  readonly referenceNumber: string;
  readonly mode: RefundMode;
  readonly amount: number;
  readonly notes: string;
};

type Props = {
  readonly values: ContractInvoiceRefundFormValues;
  readonly setValues: (values: ContractInvoiceRefundFormValues) => void;
  readonly invoice: ProjectProviderTypes.MappedProjectInvoice;
  readonly refundId?: string;
};

export const useContractInvoiceRefundForm = (initialValues: ContractInvoiceRefundFormValues) => {
  const [values, setValues] = useState<ContractInvoiceRefundFormValues>(initialValues);
  return { values, setValues };
};

export const ContractInvoiceRefundForm: FunctionComponent<Props> = ({ values, setValues, invoice, refundId }) => {
  const [initialRefundAmount] = useState<number>(values.amount);
  const [invoiceTotalAmount] = useState<number>(
    invoice.refunds.filter((refund) => refund.id !== refundId).reduce((previousValue, currentValue) => previousValue + currentValue.amount, 0) + Math.abs(invoice.balanceAmount) + initialRefundAmount
  );
  const invoiceRefundAmount = invoice.refunds.filter((refund) => refund.id !== refundId).reduce((previousValue, currentValue) => previousValue + currentValue.amount, 0) + values.amount;
  return (
    <Form id="contract-invoice-refund-form">
      <FormContainer highlight>
        <Form.Group>
          <Form.ControlLabel>Numéro</Form.ControlLabel>
          <Input value={values.label} readOnly />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Mode</Form.ControlLabel>
          <SelectPicker
            disabled
            cleanable={false}
            block
            value={values.mode}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.mode = value!;
                })
              );
            }}
            data={[
              { label: getEnumLabel(RefundMode.BANK_TRANSFER), value: RefundMode.BANK_TRANSFER },
              { label: getEnumLabel(RefundMode.CASH), value: RefundMode.CASH },
              { label: getEnumLabel(RefundMode.CHECK), value: RefundMode.CHECK },
              { label: getEnumLabel(RefundMode.CREDIT_CARD), value: RefundMode.CREDIT_CARD },
            ]}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Date</Form.ControlLabel>
          <DatePicker
            oneTap
            block
            value={values.date}
            cleanable={false}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.date = value!;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Montant</Form.ControlLabel>
          <InputGroup>
            <InputNumber
              min={0}
              value={values.amount}
              onChange={(value) => {
                setValues(
                  produce(values, (draft) => {
                    draft.amount = Number(value);
                  })
                );
              }}
            />
            <InputGroup.Button
              appearance="default"
              disabled={invoiceRefundAmount >= invoiceTotalAmount}
              onClick={() => {
                setValues(
                  produce(values, (draft) => {
                    if (Math.abs(invoice.balanceAmount) === 0) {
                      draft.amount = initialRefundAmount;
                    } else {
                      draft.amount = draft.amount + Math.abs(invoice.balanceAmount);
                    }
                  })
                );
              }}
            >
              Montant du solde
            </InputGroup.Button>
          </InputGroup>
        </Form.Group>
      </FormContainer>
      <FormContainer>
        <Form.Group>
          <Form.ControlLabel>Numéro de référence</Form.ControlLabel>
          <Input
            value={values.referenceNumber}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.referenceNumber = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Notes</Form.ControlLabel>
          <Input
            as="textarea"
            rows={3}
            value={values.notes}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.notes = value;
                })
              );
            }}
          />
        </Form.Group>
      </FormContainer>
    </Form>
  );
};
