import "./index.scss";
import { useUnblockUserMutation } from "graphql/mutations";
import { FunctionComponent } from "react";
import { Dialog, DialogBody, DialogFooter } from "buildingBlocks";
import { Button } from "rsuite";
import { MappedUser } from "providers";

type Props = {
  readonly tenantId: string;
  readonly user: MappedUser;
  readonly onCompleted: () => void;
  readonly onClose: () => void;
};

export const UserUnblockDialog: FunctionComponent<Props> = ({ tenantId, user, onCompleted, onClose }) => {
  const { unblockUser, unblockUserLoading } = useUnblockUserMutation(onCompleted, onClose, true);
  return (
    <Dialog id="user-unblock-dialog" title="Débloquer l'utilisateur">
      <DialogBody>L'utilisateur aura à nouveau accès à cette organisation.</DialogBody>
      <DialogFooter>
        <Button
          appearance="primary"
          size="sm"
          loading={unblockUserLoading}
          onClick={async () => {
            await unblockUser({
              tenantId: tenantId,
              userId: user.id,
            });
          }}
        >
          Débloqué
        </Button>
        <Button appearance="default" size="sm" disabled={unblockUserLoading} onClick={onClose}>
          Fermer
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
