import "./index.scss";
import { FunctionComponent, PropsWithChildren } from "react";
import { Modal } from "rsuite";

type Props = PropsWithChildren & {
  readonly className?: string;
};

export const DialogFooter: FunctionComponent<Props> = ({ children, className = undefined }) => (
  <Modal.Footer className={`dialog-footer${className ? ` ${className}` : ""}`}>{children}</Modal.Footer>
);
