import "./index.scss";
import { FunctionComponent } from "react";
import { ViewReport } from "buildingBlocks";
import { useProjectContractContext, useProjectContractSelectionContext, useRouting } from "hooks";
import { ContractSelectionWorkflow, PermissionName } from "graphql/schema";
import { useProjectContext, useTenantContext } from "providers";
import { ReportSelection } from "components/ReportSelection";
import { ContractSelectionConfirmForm, useContractSelectionConfirmForm } from "forms";
import { useConfirmContractSelectionMutation } from "graphql/mutations";

export const ProjectContractSelectionUpdateView: FunctionComponent = () => {
  const { navigate, toProjectContractSelectionGroupUpdateView, toProjectContractSelectionsView, toProjectContractInvoiceView } = useRouting();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { contract } = useProjectContractContext();
  const { contractSelection } = useProjectContractSelectionContext();
  const { values, setValues } = useContractSelectionConfirmForm({
    isTermsAndConditionsAccepted: contractSelection.isTermsAndConditionsAccepted,
  });
  const { confirmContractSelection, confirmContractSelectionLoading } = useConfirmContractSelectionMutation(({ tenantId, projectId, contract, invoices }) => {
    const contractSelection = contract.selections.find((contractSelection) => contractSelection.id === contract.currentSelectionId);
    const invoice = invoices.find((invoice) => invoice.id === contractSelection?.invoiceId);
    if (invoice) {
      navigate(
        toProjectContractInvoiceView({
          tenantId: tenantId,
          projectId: projectId,
          contractId: contract.id,
          invoiceId: invoice.id,
        })
      );
    } else {
      navigate(
        toProjectContractSelectionsView({
          tenantId: tenantId,
          projectId: projectId,
          contractId: contract.id,
        })
      );
    }
  });
  return (
    <ViewReport
      id="project-contract-selection-update-view"
      security={{
        permissions: [[PermissionName.CONTRACT_SELECTION_CONFIRM]],
      }}
      header={{
        title: contractSelection.label,
        subtitle: contract.unit.label,
        toolbar: {
          buttons: [],
          otherActions: [],
        },
      }}
      form={{
        disabled: contractSelection.workflow === ContractSelectionWorkflow.WITH_CUSTOMER && !values.isTermsAndConditionsAccepted,
        loading: confirmContractSelectionLoading,
        onSubmit: async () => {
          await confirmContractSelection({
            tenantId: tenant.id,
            projectId: project.id,
            contractId: contract.id,
            selectionId: contractSelection.id,
            isTermsAndConditionsAccepted: values.isTermsAndConditionsAccepted,
          });
        },
        onCancel: () => {
          navigate(
            toProjectContractSelectionGroupUpdateView({
              tenantId: tenant.id,
              projectId: project.id,
              contractId: contract.id,
              contractSelectionId: contractSelection.id,
              contractSelectionGroupId: contractSelection.groups.find((contractSelectionGroup) => contractSelectionGroup.index === contractSelection.groups.length - 1)!.id,
            })
          );
        },
      }}
    >
      <ReportSelection contract={contract} contractSelection={contractSelection}>
        <ContractSelectionConfirmForm values={values} setValues={setValues} />
      </ReportSelection>
    </ViewReport>
  );
};
