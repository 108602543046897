import "./index.scss";
import { FunctionComponent } from "react";
import { ViewForm } from "buildingBlocks";
import { useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { useCreateFormMutation } from "graphql/mutations";
import { PermissionName } from "graphql/schema";
import { FormForm, useFormForm } from "forms";

export const ProjectFormCreateView: FunctionComponent = () => {
  const { navigate, toProjectFormsView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, sections, models } = useProjectContext();
  const { values, setValues } = useFormForm({
    name: "",
    modelId: null,
    sections: [],
  });
  const { createForm, createFormLoading, createFormErrors } = useCreateFormMutation(({ tenantId, projectId }) => {
    navigate(
      toProjectFormsView({
        tenantId: tenantId,
        projectId: projectId,
      })
    );
  });
  return (
    <ViewForm
      id="project-form-create-view"
      title="Créer un formulaire"
      security={{
        permissions: [[PermissionName.FORM_READ, PermissionName.FORM_CREATE]],
      }}
      errors={createFormErrors}
      loading={createFormLoading}
      onSubmit={async () => {
        await createForm({
          tenantId: tenant.id,
          projectId: project.id,
          name: values.name,
          modelId: values.modelId,
          sections: values.sections
            .filter((section) => !section.isDeleted)
            .map((section) => ({
              id: section.id,
              index: section.index,
              title: section.title,
              sectionId: section.sectionId,
            })),
        });
      }}
      onCancel={() => {
        navigate(
          toProjectFormsView({
            tenantId: tenant.id,
            projectId: project.id,
          })
        );
      }}
    >
      <FormForm values={values} setValues={setValues} sections={sections} models={models} />
    </ViewForm>
  );
};
