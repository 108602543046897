import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { SearchInputGroup, ViewList, Text } from "buildingBlocks";
import { useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { PermissionName } from "graphql/schema";
import { ActivityDeleteDialog } from "dialogs";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

const useProjectActivitiesViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback(
      (activities: ReadonlyArray<ProjectProviderTypes.MappedProjectActivity>) => activities.filter((activity) => activity.label.toSearchFormat().includes(search.toSearchFormat())),
      [search]
    ),
  };
};

export const ProjectActivitiesView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useProjectActivitiesViewFilters();
  const { navigate, toProjectView, toProjectActivityCreateView, toProjectActivityUpdateView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, activities } = useProjectContext();
  const [activityToDelete, setActivityToDelete] = useState<ProjectProviderTypes.MappedProjectActivity | null>(null);
  return (
    <ViewList
      id="project-activities-view"
      security={{
        permissions: [[PermissionName.ACTIVITY_READ]],
      }}
      header={{
        title: "Toutes les activités",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              text: "Créer une activité",
              show: activities.length !== 0,
              security: {
                permissions: [[PermissionName.ACTIVITY_CREATE]],
              },
              onClick: () => {
                navigate(
                  toProjectActivityCreateView({
                    tenantId: tenant.id,
                    projectId: project.id,
                  })
                );
              },
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [<SearchInputGroup size="xs" placeholder="Recherche par étiquette" value={filterValues.search} onChange={filterFunctions.setSearch} />],
            applyButton: null,
          },
          right: {
            inputs: [],
          },
        },
      }}
      content={{
        table: {
          emptyBodies: [
            {
              show: activities.length === 0,
              title: "Aucune activité",
              content: "Pour créer une activité, cliquez sur le bouton ci-dessous.",
              button: {
                text: "Créer une activité",
                security: {
                  permissions: [[PermissionName.ACTIVITY_CREATE]],
                },
                onClick: () => {
                  navigate(
                    toProjectActivityCreateView({
                      tenantId: tenant.id,
                      projectId: project.id,
                    })
                  );
                },
              },
            },
          ],
          headColumns: [
            {
              name: "number",
              value: "NUMÉRO",
            },
            {
              name: "title",
              value: "TITRE",
            },
            {
              name: "description",
              value: "DESCRIPTION",
            },
            {
              name: "number-of-products",
              value: "NB PRODUITS",
            },
          ],
          bodyRows: applyFilters(activities).map((activity) => ({
            key: activity.id,
            columns: [
              {
                name: "number",
                component: activity.number,
              },
              {
                name: "title",
                component: <Text highlight>{activity.title}</Text>,
              },
              {
                name: "description",
                component: activity.description ?? "--",
              },
              {
                name: "number-of-products",
                component: `${activity.products.length} produit(s)`,
              },
            ],
            actions: [
              {
                __type: "Button",
                content: "Modifier l'activité",
                show: true,
                security: {
                  permissions: [[PermissionName.ACTIVITY_UPDATE]],
                },
                onClick: () => {
                  navigate(
                    toProjectActivityUpdateView({
                      tenantId: tenant.id,
                      projectId: project.id,
                      activityId: activity.id,
                    })
                  );
                },
              },
              {
                __type: "Button",
                content: "Supprimer l'activité",
                show: true,
                security: {
                  permissions: [[PermissionName.ACTIVITY_DELETE]],
                },
                onClick: () => {
                  setActivityToDelete(activity);
                },
              },
            ],
          })),
          bodyRowGroups: [],
        },
      }}
    >
      {activityToDelete && (
        <ActivityDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          activity={activityToDelete}
          onCompleted={() => {
            setActivityToDelete(null);
          }}
          onClose={() => {
            setActivityToDelete(null);
          }}
        />
      )}
    </ViewList>
  );
};
