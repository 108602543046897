import "./index.scss";
import { useCreateContractInvoicePaymentMutation } from "graphql/mutations";
import { FunctionComponent } from "react";
import { ModalForm } from "buildingBlocks";
import { ContractInvoicePaymentForm, useContractInvoicePaymentForm } from "forms";
import { PaymentMode } from "graphql/schema";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly contract: ProjectProviderTypes.MappedProjectContract;
  readonly invoice: ProjectProviderTypes.MappedProjectInvoice;
  readonly payments: ReadonlyArray<ProjectProviderTypes.MappedProjectPayment>;
  readonly onCompleted: () => void;
  readonly onCancel: () => void;
};

export const ContractInvoicePaymentCreateModal: FunctionComponent<Props> = ({ tenantId, projectId, contract, invoice, payments, onCompleted, onCancel }) => {
  const { values, setValues } = useContractInvoicePaymentForm({
    label: `PMT-${("000000" + (payments.length === 0 ? 1 : payments.map((payment) => payment.number).max()! + 1)).slice(-6)}`,
    date: new Date(),
    referenceNumber: "",
    mode: PaymentMode.CHECK,
    amount: 0,
    notes: "",
  });
  const { createContractInvoicePayment, createContractInvoicePaymentLoading, createContractInvoicePaymentErrors } = useCreateContractInvoicePaymentMutation(onCompleted, undefined, false);
  return (
    <ModalForm
      id="contract-invoice-payment-create-modal"
      title="Créer un paiement"
      errors={createContractInvoicePaymentErrors}
      loading={createContractInvoicePaymentLoading}
      disabled={values.amount <= 0}
      submitButton={{
        onClick: async () => {
          await createContractInvoicePayment({
            tenantId: tenantId,
            projectId: projectId,
            contractId: contract.id,
            invoiceId: invoice.id,
            date: values.date,
            mode: values.mode,
            referenceNumber: values.referenceNumber.trim() || null,
            amount: values.amount,
            notes: values.notes.trim() || null,
          });
        },
      }}
      closeButton={{
        text: "Annuler",
        onClick: onCancel,
      }}
    >
      <ContractInvoicePaymentForm values={values} setValues={setValues} invoice={invoice} />
    </ModalForm>
  );
};
