import "./index.scss";
import { FunctionComponent } from "react";
import { Toggle } from "rsuite";
import { SectionElementOptionProductCard } from "components/SectionElementOptionProductCard";
import { SectionElementType } from "graphql/schema";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly sectionElement: ProjectProviderTypes.MappedProjectSectionElement;
  readonly value: {
    readonly sectionElementOptionId: string | null;
    readonly clientAmount: number;
  } | null;
  readonly onClick: (sectionElementOption: ProjectProviderTypes.MappedProjectSectionElementOption | null) => void;
};

export const ContractSelectionGroupItem: FunctionComponent<Props> = ({ sectionElement, value, onClick }) => (
  <div className="contract-selection-group-item">
    <div className="contract-selection-group-item-header">
      <span className="section-element-label">{sectionElement.label}</span>
      {(sectionElement.type === SectionElementType.UNIQUE && sectionElement.isOptional) || sectionElement.type === SectionElementType.BOOLEAN ? (
        <Toggle
          size="md"
          disabled={!sectionElement.getOptionsWithoutSets()[0].isUnlocked}
          unCheckedChildren="EXCLU"
          checkedChildren={
            sectionElement
              .getOptionsWithoutSets()
              .find((sectionElementOption) => sectionElementOption.id === value?.sectionElementOptionId)
              ?.clientAmount.toCurrencyFormat() ?? ""
          }
          checked={value?.sectionElementOptionId !== null}
          onChange={(checked) => {
            if (checked) {
              onClick(sectionElement.getOptionsWithoutSets()[0]);
            } else {
              onClick(null);
            }
          }}
        />
      ) : (
        <span className="total-amount">{value && value.clientAmount !== 0 ? value.clientAmount.toCurrencyFormat() : "SANS FRAIS"}</span>
      )}
    </div>
    <div className="contract-selection-group-item-body">
      {sectionElement.options.map((sectionElementOption, sectionElementOptionIndex) => {
        if (sectionElement.type === SectionElementType.BOOLEAN) {
          return null;
        }
        if (sectionElement.type === SectionElementType.UNIQUE && sectionElement.isOptional && value?.sectionElementOptionId === null) {
          return null;
        }
        if (sectionElementOption.__typename === "SectionElementOptionSet") {
          return (
            <div className="section-element-option-set" key={sectionElementOptionIndex}>
              {!sectionElement.hideProductCategory && <span className="product-category-name">{sectionElementOption.productCategoryName}</span>}
              <div className="section-element-options">
                {sectionElementOption.options.map((option, optionIndex) => (
                  <SectionElementOptionProductCard key={optionIndex} variantOnly sectionElementOption={option} checked={option.id === value?.sectionElementOptionId} onClick={onClick} />
                ))}
              </div>
            </div>
          );
        }
        return (
          <div key={sectionElementOptionIndex} className="section-element-options">
            <SectionElementOptionProductCard variantOnly sectionElementOption={sectionElementOption} checked={sectionElementOption.id === value?.sectionElementOptionId} onClick={onClick} />
          </div>
        );
      })}
    </div>
  </div>
);
