import { gql, useMutation } from "@apollo/client";
import { useTenantContext, useNotificationContext, useProjectContext } from "providers";
import { useCallback } from "react";
import { useList } from "react-use";
import {
  ERROR_FRAGMENT,
  ACTIVITY_FRAGMENT,
  CONTRACT_FRAGMENT,
  CUSTOMER_FRAGMENT,
  UNIT_FRAGMENT,
  USER_FRAGMENT,
  PROJECT_FRAGMENT,
  PRODUCT_FRAGMENT,
  REPORT_FRAGMENT,
  PRODUCT_CATEGORY_FRAGMENT,
  MODEL_FRAGMENT,
  PROVIDER_FRAGMENT,
  INVOICE_FRAGMENT,
  SECTION_FRAGMENT,
  FORM_FRAGMENT,
  PAYMENT_FRAGMENT,
  REFUND_FRAGMENT,
} from "./fragments";
import {
  CreateActivityInput,
  UpdateActivityInput,
  DeleteActivityInput,
  CreateContractInput,
  CancelContractInput,
  DeleteContractInput,
  DownloadContractInput,
  ConfirmContractSelectionInput,
  CreateContractSelectionInput,
  UpdateContractSelectionInput,
  DeleteContractSelectionInput,
  CreateContractRevisionInput,
  UpdateContractRevisionInput,
  DeleteContractRevisionInput,
  UpdateContractSelectionGroupInput,
  CreateCustomerInput,
  UpdateCustomerInput,
  DeleteCustomerInput,
  CreateUserInput,
  UpdateUserInput,
  BlockUserInput,
  UnblockUserInput,
  ResendUserInvitationInput,
  CreateFormInput,
  UpdateFormInput,
  DeleteFormInput,
  ImportModelInput,
  DeleteModelInput,
  CreateContractInvoicePaymentInput,
  UpdateContractInvoicePaymentInput,
  DeleteContractInvoicePaymentInput,
  CreateProductInput,
  UpdateProductInput,
  UpdateProductsInput,
  DeleteProductInput,
  CreateProductCategoryInput,
  UpdateProductCategoryInput,
  DeleteProductCategoryInput,
  CreateProjectInput,
  UpdateProjectInput,
  DeleteProjectInput,
  CreateProviderInput,
  UpdateProviderInput,
  DeleteProviderInput,
  CreateContractRefundInput,
  UpdateContractRefundInput,
  DeleteContractRefundInput,
  CreateContractInvoiceRefundInput,
  UpdateContractInvoiceRefundInput,
  DeleteContractInvoiceRefundInput,
  UpdateReportInput,
  CreateSectionInput,
  UpdateSectionInput,
  DeleteSectionInput,
  CreateSectionVibeInput,
  UpdateSectionVibeInput,
  DeleteSectionVibeInput,
  DownloadTemplateInput,
  CreateUnitInput,
  UpdateUnitInput,
  DeleteUnitInput,
  UpdateUnitsInput,
  ImportUnitsInput,
  CreateActivityMutation,
  CreateActivityMutationVariables,
  BlockUserMutation,
  CancelContractMutation,
  ConfirmContractSelectionMutation,
  CreateContractInvoicePaymentMutation,
  CreateContractInvoiceRefundMutation,
  CreateContractMutation,
  CreateContractRefundMutation,
  CreateContractRevisionMutation,
  CreateContractSelectionMutation,
  CreateCustomerMutation,
  CreateFormMutation,
  CreateProductCategoryMutation,
  CreateProductMutation,
  CreateProjectMutation,
  CreateProviderMutation,
  CreateSectionMutation,
  CreateSectionVibeMutation,
  CreateUnitMutation,
  CreateUserMutation,
  DeleteActivityMutation,
  DeleteContractInvoicePaymentMutation,
  DeleteContractInvoiceRefundMutation,
  DeleteContractMutation,
  DeleteContractRefundMutation,
  DeleteContractRevisionMutation,
  DeleteContractSelectionMutation,
  DeleteCustomerMutation,
  DeleteFormMutation,
  DeleteModelMutation,
  DeleteProductCategoryMutation,
  DeleteProductMutation,
  DeleteProjectMutation,
  DeleteProviderMutation,
  DeleteSectionMutation,
  DeleteSectionVibeMutation,
  DeleteUnitMutation,
  DownloadContractMutation,
  DownloadTemplateMutation,
  ImportModelMutation,
  ImportUnitsMutation,
  ResendUserInvitationMutation,
  UnblockUserMutation,
  UpdateActivityMutation,
  UpdateContractInvoicePaymentMutation,
  UpdateContractInvoiceRefundMutation,
  UpdateContractRefundMutation,
  UpdateContractRevisionMutation,
  UpdateContractSelectionGroupMutation,
  UpdateContractSelectionMutation,
  UpdateCustomerMutation,
  UpdateFormMutation,
  UpdateProductCategoryMutation,
  UpdateProductMutation,
  UpdateProductsMutation,
  UpdateProjectMutation,
  UpdateProviderMutation,
  UpdateReportMutation,
  UpdateSectionMutation,
  UpdateSectionVibeMutation,
  UpdateUnitMutation,
  UpdateUnitsMutation,
  UpdateUserMutation,
  BlockUserMutationVariables,
  CreateContractInvoicePaymentMutationVariables,
  CreateContractInvoiceRefundMutationVariables,
  CreateContractRefundMutationVariables,
  CreateContractRevisionMutationVariables,
  CreateCustomerMutationVariables,
  CreateFormMutationVariables,
  CreateProductCategoryMutationVariables,
  CreateProductMutationVariables,
  CreateProjectMutationVariables,
  CreateProviderMutationVariables,
  CreateSectionMutationVariables,
  CreateSectionVibeMutationVariables,
  CreateUnitMutationVariables,
  CreateUserMutationVariables,
  DeleteContractInvoicePaymentMutationVariables,
  DeleteContractInvoiceRefundMutationVariables,
  DeleteContractRefundMutationVariables,
  DeleteContractRevisionMutationVariables,
  DeleteContractSelectionMutationVariables,
  DeleteCustomerMutationVariables,
  DeleteFormMutationVariables,
  DeleteModelMutationVariables,
  DeleteProductCategoryMutationVariables,
  DeleteProductMutationVariables,
  DeleteProjectMutationVariables,
  DeleteProviderMutationVariables,
  DeleteSectionMutationVariables,
  DeleteSectionVibeMutationVariables,
  DeleteUnitMutationVariables,
  DownloadTemplateMutationVariables,
  ImportModelMutationVariables,
  ImportUnitsMutationVariables,
  ResendUserInvitationMutationVariables,
  UnblockUserMutationVariables,
  UpdateContractInvoicePaymentMutationVariables,
  UpdateContractInvoiceRefundMutationVariables,
  UpdateContractRefundMutationVariables,
  UpdateContractRevisionMutationVariables,
  UpdateContractSelectionGroupMutationVariables,
  UpdateContractSelectionMutationVariables,
  UpdateCustomerMutationVariables,
  UpdateFormMutationVariables,
  UpdateProductCategoryMutationVariables,
  UpdateProductMutationVariables,
  UpdateProductsMutationVariables,
  UpdateProjectMutationVariables,
  UpdateProviderMutationVariables,
  UpdateReportMutationVariables,
  UpdateSectionMutationVariables,
  UpdateSectionVibeMutationVariables,
  UpdateUnitMutationVariables,
  UpdateUnitsMutationVariables,
  UpdateUserMutationVariables,
  CreateContractSelectionMutationVariables,
  CancelContractMutationVariables,
  ConfirmContractSelectionMutationVariables,
  CreateContractMutationVariables,
  DeleteActivityMutationVariables,
  DeleteContractMutationVariables,
  DownloadContractMutationVariables,
  UpdateActivityMutationVariables,
  ArchiveFormInput,
  ArchiveFormMutation,
  ArchiveFormMutationVariables,
  ArchiveSectionInput,
  ArchiveSectionMutation,
  ArchiveSectionMutationVariables,
  ConfigureSectionInput,
  ConfigureSectionMutation,
  ConfigureSectionMutationVariables,
  LinkFormInput,
  LinkFormMutation,
  LinkFormMutationVariables,
  LoadModelInput,
  LoadModelMutation,
  LoadModelMutationVariables,
  PublishFormInput,
  PublishFormMutation,
  PublishFormMutationVariables,
  PublishSectionInput,
  PublishSectionMutation,
  PublishSectionMutationVariables,
} from "./schema";

const useErrorList = () => {
  const [errors, { set: setErrors }] = useList<string>();
  return { errors, setErrors };
};

// Activity
const CREATE_ACTIVITY_MUTATION = gql`
  ${ACTIVITY_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateActivity($input: CreateActivityInput!) {
    payload: createActivity(input: $input) {
      data {
        tenantId
        projectId
        activity {
          ...Activity
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateActivityMutation = (
  onCompleted: (data: NonNullable<CreateActivityMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: createActivityErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertActivities } = useProjectContext();
  const [createActivityMutation, { loading: createActivityLoading }] = useMutation<CreateActivityMutation, CreateActivityMutationVariables>(CREATE_ACTIVITY_MUTATION);
  const createActivity = useCallback(
    async (input: CreateActivityInput) => {
      await createActivityMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, activity } = data;
            upsertActivities(tenantId, projectId, [activity]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [createActivityMutation, onCompleted, onError, useNotification, setErrors, upsertActivities, pushErrors]
  );
  return { createActivity, createActivityLoading, createActivityErrors };
};

const UPDATE_ACTIVITY_MUTATION = gql`
  ${ACTIVITY_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateActivity($input: UpdateActivityInput!) {
    payload: updateActivity(input: $input) {
      data {
        tenantId
        projectId
        activity {
          ...Activity
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateActivityMutation = (
  onCompleted: (data: NonNullable<UpdateActivityMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateActivityErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertActivities } = useProjectContext();
  const [updateActivityMutation, { loading: updateActivityLoading }] = useMutation<UpdateActivityMutation, UpdateActivityMutationVariables>(UPDATE_ACTIVITY_MUTATION);
  const updateActivity = useCallback(
    async (input: UpdateActivityInput) => {
      await updateActivityMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, activity } = data;
            upsertActivities(tenantId, projectId, [activity]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateActivityMutation, onCompleted, onError, useNotification, setErrors, upsertActivities, pushErrors]
  );
  return { updateActivity, updateActivityLoading, updateActivityErrors };
};

const DELETE_ACTIVITY_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DeleteActivity($input: DeleteActivityInput!) {
    payload: deleteActivity(input: $input) {
      data {
        tenantId
        projectId
        activityId
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteActivityMutation = (
  onCompleted: (data: NonNullable<DeleteActivityMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: deleteActivityErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { removeActivities } = useProjectContext();
  const [deleteActivityMutation, { loading: deleteActivityLoading }] = useMutation<DeleteActivityMutation, DeleteActivityMutationVariables>(DELETE_ACTIVITY_MUTATION);
  const deleteActivity = useCallback(
    async (input: DeleteActivityInput) => {
      await deleteActivityMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, activityId } = data;
            removeActivities(tenantId, projectId, [activityId]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [deleteActivityMutation, onCompleted, onError, useNotification, setErrors, removeActivities, pushErrors]
  );
  return { deleteActivity, deleteActivityLoading, deleteActivityErrors };
};

// Contract
export const CREATE_CONTRACT_MUTATION = gql`
  ${CONTRACT_FRAGMENT}
  ${UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateContract($input: CreateContractInput!) {
    payload: createContract(input: $input) {
      data {
        tenantId
        projectId
        contract {
          ...Contract
        }
        unit {
          ...Unit
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateContractMutation = (
  onCompleted: (data: NonNullable<CreateContractMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: createContractErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertUnits, upsertContracts } = useProjectContext();
  const [createContractMutation, { loading: createContractLoading }] = useMutation<CreateContractMutation, CreateContractMutationVariables>(CREATE_CONTRACT_MUTATION);
  const createContract = useCallback(
    async (input: CreateContractInput) => {
      await createContractMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, contract, unit } = data;
            upsertContracts(tenantId, projectId, [contract]);
            upsertUnits(tenantId, projectId, [unit]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [createContractMutation, onCompleted, onError, useNotification, setErrors, upsertUnits, upsertContracts, pushErrors]
  );
  return { createContract, createContractLoading, createContractErrors };
};

export const CANCEL_CONTRACT_MUTATION = gql`
  ${CONTRACT_FRAGMENT}
  ${UNIT_FRAGMENT}
  ${INVOICE_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CancelContract($input: CancelContractInput!) {
    payload: cancelContract(input: $input) {
      data {
        tenantId
        projectId
        contract {
          ...Contract
        }
        unit {
          ...Unit
        }
        invoices {
          ...Invoice
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCancelContractMutation = (
  onCompleted: (data: NonNullable<CancelContractMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: cancelContractErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertContracts, upsertUnits, upsertInvoices } = useProjectContext();
  const [cancelContractMutation, { loading: cancelContractLoading }] = useMutation<CancelContractMutation, CancelContractMutationVariables>(CANCEL_CONTRACT_MUTATION);
  const cancelContract = useCallback(
    async (input: CancelContractInput) => {
      await cancelContractMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, contract, unit, invoices } = data;
            upsertUnits(tenantId, projectId, [unit]);
            upsertContracts(tenantId, projectId, [contract]);
            upsertInvoices(tenantId, projectId, invoices);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [cancelContractMutation, onCompleted, onError, useNotification, setErrors, upsertContracts, upsertInvoices, upsertUnits, pushErrors]
  );
  return { cancelContract, cancelContractLoading, cancelContractErrors };
};

export const DELETE_CONTRACT_MUTATION = gql`
  ${UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation DeleteContract($input: DeleteContractInput!) {
    payload: deleteContract(input: $input) {
      data {
        tenantId
        projectId
        contractId
        unit {
          ...Unit
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteContractMutation = (
  onCompleted: (data: NonNullable<DeleteContractMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: deleteContractErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { removeContracts, upsertUnits } = useProjectContext();
  const [deleteContractMutation, { loading: deleteContractLoading }] = useMutation<DeleteContractMutation, DeleteContractMutationVariables>(DELETE_CONTRACT_MUTATION);
  const deleteContract = useCallback(
    async (input: DeleteContractInput) => {
      await deleteContractMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, contractId, unit } = data;
            removeContracts(tenantId, projectId, [contractId]);
            upsertUnits(tenantId, projectId, [unit]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [deleteContractMutation, onCompleted, onError, useNotification, setErrors, removeContracts, upsertUnits, pushErrors]
  );
  return { deleteContract, deleteContractLoading, deleteContractErrors };
};

const DOWNLOAD_CONTRACT_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DownloadContract($input: DownloadContractInput!) {
    payload: downloadContract(input: $input) {
      data {
        documentUrl
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDownloadContractMutation = (
  onCompleted: (data: NonNullable<DownloadContractMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: downloadContractErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const [downloadContractMutation, { loading: downloadContractLoading }] = useMutation<DownloadContractMutation, DownloadContractMutationVariables>(DOWNLOAD_CONTRACT_MUTATION);
  const downloadContract = useCallback(
    async (input: DownloadContractInput) => {
      await downloadContractMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [downloadContractMutation, onCompleted, onError, useNotification, setErrors, pushErrors]
  );
  return { downloadContract, downloadContractLoading, downloadContractErrors };
};

const CONFIRM_CONTRACT_SELECTION_MUTATION = gql`
  ${CONTRACT_FRAGMENT}
  ${UNIT_FRAGMENT}
  ${INVOICE_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation ConfirmContractSelection($input: ConfirmContractSelectionInput!) {
    payload: confirmContractSelection(input: $input) {
      data {
        tenantId
        projectId
        contract {
          ...Contract
        }
        unit {
          ...Unit
        }
        invoices {
          ...Invoice
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useConfirmContractSelectionMutation = (
  onCompleted: (data: NonNullable<ConfirmContractSelectionMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: confirmContractSelectionErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertContracts, upsertUnits, upsertInvoices } = useProjectContext();
  const [confirmContractSelectionMutation, { loading: confirmContractSelectionLoading }] = useMutation<ConfirmContractSelectionMutation, ConfirmContractSelectionMutationVariables>(
    CONFIRM_CONTRACT_SELECTION_MUTATION
  );
  const confirmContractSelection = useCallback(
    async (input: ConfirmContractSelectionInput) => {
      await confirmContractSelectionMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, contract, unit, invoices } = data;
            upsertContracts(tenantId, projectId, [contract]);
            upsertUnits(tenantId, projectId, [unit]);
            upsertInvoices(tenantId, projectId, invoices);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [confirmContractSelectionMutation, onCompleted, onError, useNotification, setErrors, upsertContracts, upsertUnits, upsertInvoices, pushErrors]
  );
  return { confirmContractSelection, confirmContractSelectionLoading, confirmContractSelectionErrors };
};

const CREATE_CONTRACT_SELECTION_MUTATION = gql`
  ${CONTRACT_FRAGMENT}
  ${UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateContractSelection($input: CreateContractSelectionInput!) {
    payload: createContractSelection(input: $input) {
      data {
        tenantId
        projectId
        contract {
          ...Contract
        }
        unit {
          ...Unit
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateContractSelectionMutation = (
  onCompleted: (data: NonNullable<CreateContractSelectionMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: createContractSelectionErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertContracts, upsertUnits } = useProjectContext();
  const [createContractSelectionMutation, { loading: createContractSelectionLoading }] = useMutation<CreateContractSelectionMutation, CreateContractSelectionMutationVariables>(
    CREATE_CONTRACT_SELECTION_MUTATION
  );
  const createContractSelection = useCallback(
    async (input: CreateContractSelectionInput) => {
      await createContractSelectionMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, contract, unit } = data;
            upsertContracts(tenantId, projectId, [contract]);
            upsertUnits(tenantId, projectId, [unit]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [createContractSelectionMutation, onCompleted, onError, useNotification, setErrors, upsertContracts, upsertUnits, pushErrors]
  );
  return { createContractSelection, createContractSelectionLoading, createContractSelectionErrors };
};

const UPDATE_CONTRACT_SELECTION_MUTATION = gql`
  ${CONTRACT_FRAGMENT}
  ${UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateContractSelection($input: UpdateContractSelectionInput!) {
    payload: updateContractSelection(input: $input) {
      data {
        tenantId
        projectId
        contract {
          ...Contract
        }
        unit {
          ...Unit
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateContractSelectionMutation = (
  onCompleted: (data: NonNullable<UpdateContractSelectionMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateContractSelectionErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertContracts, upsertUnits } = useProjectContext();
  const [updateContractSelectionMutation, { loading: updateContractSelectionLoading }] = useMutation<UpdateContractSelectionMutation, UpdateContractSelectionMutationVariables>(
    UPDATE_CONTRACT_SELECTION_MUTATION
  );
  const updateContractSelection = useCallback(
    async (input: UpdateContractSelectionInput) => {
      await updateContractSelectionMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, contract, unit } = data;
            upsertContracts(tenantId, projectId, [contract]);
            upsertUnits(tenantId, projectId, [unit]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateContractSelectionMutation, onCompleted, onError, useNotification, setErrors, upsertContracts, upsertUnits, pushErrors]
  );
  return { updateContractSelection, updateContractSelectionLoading, updateContractSelectionErrors };
};

const DELETE_CONTRACT_SELECTION_MUTATION = gql`
  ${CONTRACT_FRAGMENT}
  ${UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation DeleteContractSelection($input: DeleteContractSelectionInput!) {
    payload: deleteContractSelection(input: $input) {
      data {
        tenantId
        projectId
        contract {
          ...Contract
        }
        unit {
          ...Unit
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteContractSelectionMutation = (
  onCompleted: (data: NonNullable<DeleteContractSelectionMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: deleteContractSelectionErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertContracts, upsertUnits } = useProjectContext();
  const [deleteContractSelectionMutation, { loading: deleteContractSelectionLoading }] = useMutation<DeleteContractSelectionMutation, DeleteContractSelectionMutationVariables>(
    DELETE_CONTRACT_SELECTION_MUTATION
  );
  const deleteContractSelection = useCallback(
    async (input: DeleteContractSelectionInput) => {
      await deleteContractSelectionMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, contract, unit } = data;
            upsertContracts(tenantId, projectId, [contract]);
            upsertUnits(tenantId, projectId, [unit]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [deleteContractSelectionMutation, onCompleted, onError, useNotification, setErrors, upsertContracts, upsertUnits, pushErrors]
  );
  return { deleteContractSelection, deleteContractSelectionLoading, deleteContractSelectionErrors };
};

const CREATE_CONTRACT_REVISION_MUTATION = gql`
  ${CONTRACT_FRAGMENT}
  ${UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateContractRevision($input: CreateContractRevisionInput!) {
    payload: createContractRevision(input: $input) {
      data {
        tenantId
        projectId
        contract {
          ...Contract
        }
        unit {
          ...Unit
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateContractRevisionMutation = (
  onCompleted: (data: NonNullable<CreateContractRevisionMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: createContractRevisionErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertContracts, upsertUnits } = useProjectContext();
  const [createContractRevisionMutation, { loading: createContractRevisionLoading }] = useMutation<CreateContractRevisionMutation, CreateContractRevisionMutationVariables>(
    CREATE_CONTRACT_REVISION_MUTATION
  );
  const createContractRevision = useCallback(
    async (input: CreateContractRevisionInput) => {
      await createContractRevisionMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, contract, unit } = data;
            upsertContracts(tenantId, projectId, [contract]);
            upsertUnits(tenantId, projectId, [unit]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [createContractRevisionMutation, onCompleted, onError, useNotification, setErrors, upsertContracts, upsertUnits, pushErrors]
  );
  return { createContractRevision, createContractRevisionLoading, createContractRevisionErrors };
};

const UPDATE_CONTRACT_REVISION_MUTATION = gql`
  ${CONTRACT_FRAGMENT}
  ${UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateContractRevision($input: UpdateContractRevisionInput!) {
    payload: updateContractRevision(input: $input) {
      data {
        tenantId
        projectId
        contract {
          ...Contract
        }
        unit {
          ...Unit
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateContractRevisionMutation = (
  onCompleted: (data: NonNullable<UpdateContractRevisionMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateContractRevisionErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertContracts, upsertUnits } = useProjectContext();
  const [updateContractRevisionMutation, { loading: updateContractRevisionLoading }] = useMutation<UpdateContractRevisionMutation, UpdateContractRevisionMutationVariables>(
    UPDATE_CONTRACT_REVISION_MUTATION
  );
  const updateContractRevision = useCallback(
    async (input: UpdateContractRevisionInput) => {
      await updateContractRevisionMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, contract, unit } = data;
            upsertContracts(tenantId, projectId, [contract]);
            upsertUnits(tenantId, projectId, [unit]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateContractRevisionMutation, onCompleted, onError, useNotification, setErrors, upsertContracts, upsertUnits, pushErrors]
  );
  return { updateContractRevision, updateContractRevisionLoading, updateContractRevisionErrors };
};

const DELETE_CONTRACT_REVISION_MUTATION = gql`
  ${CONTRACT_FRAGMENT}
  ${UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation DeleteContractRevision($input: DeleteContractRevisionInput!) {
    payload: deleteContractRevision(input: $input) {
      data {
        tenantId
        projectId
        contract {
          ...Contract
        }
        unit {
          ...Unit
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteContractRevisionMutation = (
  onCompleted: (data: NonNullable<DeleteContractRevisionMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: deleteContractRevisionErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertContracts, upsertUnits } = useProjectContext();
  const [deleteContractRevisionMutation, { loading: deleteContractRevisionLoading }] = useMutation<DeleteContractRevisionMutation, DeleteContractRevisionMutationVariables>(
    DELETE_CONTRACT_REVISION_MUTATION
  );
  const deleteContractRevision = useCallback(
    async (input: DeleteContractRevisionInput) => {
      await deleteContractRevisionMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, contract, unit } = data;
            upsertContracts(tenantId, projectId, [contract]);
            upsertUnits(tenantId, projectId, [unit]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [deleteContractRevisionMutation, onCompleted, onError, useNotification, setErrors, upsertContracts, upsertUnits, pushErrors]
  );
  return { deleteContractRevision, deleteContractRevisionLoading, deleteContractRevisionErrors };
};

const UPDATE_CONTRACT_SELECTION_GROUP_MUTATION = gql`
  ${CONTRACT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateContractSelectionGroup($input: UpdateContractSelectionGroupInput!) {
    payload: updateContractSelectionGroup(input: $input) {
      data {
        tenantId
        projectId
        contract {
          ...Contract
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateContractSelectionGroupMutation = (
  onCompleted: (data: NonNullable<UpdateContractSelectionGroupMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateContractSelectionGroupErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertContracts } = useProjectContext();
  const [updateContractSelectionGroupMutation, { loading: updateContractSelectionGroupLoading }] = useMutation<UpdateContractSelectionGroupMutation, UpdateContractSelectionGroupMutationVariables>(
    UPDATE_CONTRACT_SELECTION_GROUP_MUTATION
  );
  const updateContractSelectionGroup = useCallback(
    async (input: UpdateContractSelectionGroupInput) => {
      await updateContractSelectionGroupMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, contract } = data;
            upsertContracts(tenantId, projectId, [contract]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateContractSelectionGroupMutation, onCompleted, onError, useNotification, setErrors, upsertContracts, pushErrors]
  );
  return { updateContractSelectionGroup, updateContractSelectionGroupLoading, updateContractSelectionGroupErrors };
};

// Customer
const CREATE_CUSTOMER_MUTATION = gql`
  ${CUSTOMER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateCustomer($input: CreateCustomerInput!) {
    payload: createCustomer(input: $input) {
      data {
        tenantId
        customer {
          ...Customer
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateCustomerMutation = (
  onCompleted: (data: NonNullable<CreateCustomerMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: createCustomerErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertCustomers } = useTenantContext();
  const [createCustomerMutation, { loading: createCustomerLoading }] = useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CREATE_CUSTOMER_MUTATION);
  const createCustomer = useCallback(
    async (input: CreateCustomerInput) => {
      await createCustomerMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, customer } = data;
            upsertCustomers(tenantId, [customer]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [createCustomerMutation, onCompleted, onError, useNotification, setErrors, upsertCustomers, pushErrors]
  );
  return { createCustomer, createCustomerLoading, createCustomerErrors };
};

const UPDATE_CUSTOMER_MUTATION = gql`
  ${CUSTOMER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    payload: updateCustomer(input: $input) {
      data {
        tenantId
        customer {
          ...Customer
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateCustomerMutation = (
  onCompleted: (data: NonNullable<UpdateCustomerMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateCustomerErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertCustomers } = useTenantContext();
  const [updateCustomerMutation, { loading: updateCustomerLoading }] = useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UPDATE_CUSTOMER_MUTATION);
  const updateCustomer = useCallback(
    async (input: UpdateCustomerInput) => {
      await updateCustomerMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, customer } = data;
            upsertCustomers(tenantId, [customer]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateCustomerMutation, onCompleted, onError, useNotification, setErrors, upsertCustomers, pushErrors]
  );
  return { updateCustomer, updateCustomerLoading, updateCustomerErrors };
};

const DELETE_CUSTOMER_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DeleteCustomer($input: DeleteCustomerInput!) {
    payload: deleteCustomer(input: $input) {
      data {
        tenantId
        customerId
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteCustomerMutation = (
  onCompleted: (data: NonNullable<DeleteCustomerMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: deleteCustomerErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { removeCustomers } = useTenantContext();
  const [deleteCustomerMutation, { loading: deleteCustomerLoading }] = useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(DELETE_CUSTOMER_MUTATION);
  const deleteCustomer = useCallback(
    async (input: DeleteCustomerInput) => {
      await deleteCustomerMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, customerId } = data;
            removeCustomers(tenantId, [customerId]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [deleteCustomerMutation, onCompleted, onError, useNotification, setErrors, removeCustomers, pushErrors]
  );
  return { deleteCustomer, deleteCustomerLoading, deleteCustomerErrors };
};

// User
const CREATE_USER_MUTATION = gql`
  ${USER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateUser($input: CreateUserInput!) {
    payload: createUser(input: $input) {
      data {
        tenantId
        user {
          ...User
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateUserMutation = (
  onCompleted: (data: NonNullable<CreateUserMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: createUserErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertUsers } = useTenantContext();
  const [createUserMutation, { loading: createUserLoading }] = useMutation<CreateUserMutation, CreateUserMutationVariables>(CREATE_USER_MUTATION);
  const createUser = useCallback(
    async (input: CreateUserInput) => {
      await createUserMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, user } = data;
            upsertUsers(tenantId, [user]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [createUserMutation, onCompleted, onError, useNotification, setErrors, upsertUsers, pushErrors]
  );
  return { createUser, createUserLoading, createUserErrors };
};

const UPDATE_USER_MUTATION = gql`
  ${USER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateUser($input: UpdateUserInput!) {
    payload: updateUser(input: $input) {
      data {
        tenantId
        user {
          ...User
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateUserMutation = (
  onCompleted: (data: NonNullable<UpdateUserMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateUserErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertUsers } = useTenantContext();
  const [updateUserMutation, { loading: updateUserLoading }] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UPDATE_USER_MUTATION);
  const updateUser = useCallback(
    async (input: UpdateUserInput) => {
      await updateUserMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, user } = data;
            upsertUsers(tenantId, [user]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateUserMutation, onCompleted, onError, useNotification, setErrors, upsertUsers, pushErrors]
  );
  return { updateUser, updateUserLoading, updateUserErrors };
};

const BLOCK_USER_MUTATION = gql`
  ${USER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation BlockUser($input: BlockUserInput!) {
    payload: blockUser(input: $input) {
      data {
        tenantId
        user {
          ...User
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useBlockUserMutation = (
  onCompleted: (data: NonNullable<BlockUserMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: blockUserErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertUsers } = useTenantContext();
  const [blockUserMutation, { loading: blockUserLoading }] = useMutation<BlockUserMutation, BlockUserMutationVariables>(BLOCK_USER_MUTATION);
  const blockUser = useCallback(
    async (input: BlockUserInput) => {
      await blockUserMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, user } = data;
            upsertUsers(tenantId, [user]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [blockUserMutation, onCompleted, onError, useNotification, setErrors, upsertUsers, pushErrors]
  );
  return { blockUser, blockUserLoading, blockUserErrors };
};

const UNBLOCK_USER_MUTATION = gql`
  ${USER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UnblockUser($input: UnblockUserInput!) {
    payload: unblockUser(input: $input) {
      data {
        tenantId
        user {
          ...User
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUnblockUserMutation = (
  onCompleted: (data: NonNullable<UnblockUserMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: unblockUserErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertUsers } = useTenantContext();
  const [unblockUserMutation, { loading: unblockUserLoading }] = useMutation<UnblockUserMutation, UnblockUserMutationVariables>(UNBLOCK_USER_MUTATION);
  const unblockUser = useCallback(
    async (input: UnblockUserInput) => {
      await unblockUserMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, user } = data;
            upsertUsers(tenantId, [user]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [unblockUserMutation, onCompleted, onError, useNotification, setErrors, upsertUsers, pushErrors]
  );
  return { unblockUser, unblockUserLoading, unblockUserErrors };
};

const RESEND_USER_INVITATION_MUTATION = gql`
  ${USER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation ResendUserInvitation($input: ResendUserInvitationInput!) {
    payload: resendUserInvitation(input: $input) {
      data {
        tenantId
        user {
          ...User
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useResendUserInvitationMutation = (
  onCompleted: (data: NonNullable<ResendUserInvitationMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: resendUserInvitationErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertUsers } = useTenantContext();
  const [resendUserInvitationMutation, { loading: resendUserInvitationLoading }] = useMutation<ResendUserInvitationMutation, ResendUserInvitationMutationVariables>(RESEND_USER_INVITATION_MUTATION);
  const resendUserInvitation = useCallback(
    async (input: ResendUserInvitationInput) => {
      await resendUserInvitationMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, user } = data;
            upsertUsers(tenantId, [user]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [resendUserInvitationMutation, onCompleted, onError, useNotification, setErrors, upsertUsers, pushErrors]
  );
  return { resendUserInvitation, resendUserInvitationLoading, resendUserInvitationErrors };
};

// Form
const CREATE_FORM_MUTATION = gql`
  ${FORM_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateForm($input: CreateFormInput!) {
    payload: createForm(input: $input) {
      data {
        tenantId
        projectId
        form {
          ...Form
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateFormMutation = (
  onCompleted: (data: NonNullable<CreateFormMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: createFormErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertForms } = useProjectContext();
  const [createFormMutation, { loading: createFormLoading }] = useMutation<CreateFormMutation, CreateFormMutationVariables>(CREATE_FORM_MUTATION);
  const createForm = useCallback(
    async (input: CreateFormInput) => {
      await createFormMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, form } = data;
            upsertForms(tenantId, projectId, [form]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [createFormMutation, onCompleted, onError, useNotification, setErrors, upsertForms, pushErrors]
  );
  return { createForm, createFormLoading, createFormErrors };
};

const UPDATE_FORM_MUTATION = gql`
  ${FORM_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateForm($input: UpdateFormInput!) {
    payload: updateForm(input: $input) {
      data {
        tenantId
        projectId
        form {
          ...Form
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateFormMutation = (
  onCompleted: (data: NonNullable<UpdateFormMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateFormErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertForms } = useProjectContext();
  const [updateFormMutation, { loading: updateFormLoading }] = useMutation<UpdateFormMutation, UpdateFormMutationVariables>(UPDATE_FORM_MUTATION);
  const updateForm = useCallback(
    async (input: UpdateFormInput) => {
      await updateFormMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, form } = data;
            upsertForms(tenantId, projectId, [form]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateFormMutation, onCompleted, onError, useNotification, setErrors, upsertForms, pushErrors]
  );
  return { updateForm, updateFormLoading, updateFormErrors };
};

const DELETE_FORM_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DeleteForm($input: DeleteFormInput!) {
    payload: deleteForm(input: $input) {
      data {
        tenantId
        projectId
        formId
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteFormMutation = (
  onCompleted: (data: NonNullable<DeleteFormMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: deleteFormErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { removeForms } = useProjectContext();
  const [deleteFormMutation, { loading: deleteFormLoading }] = useMutation<DeleteFormMutation, DeleteFormMutationVariables>(DELETE_FORM_MUTATION);
  const deleteForm = useCallback(
    async (input: DeleteFormInput) => {
      await deleteFormMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, formId } = data;
            removeForms(tenantId, projectId, [formId]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [deleteFormMutation, onCompleted, onError, useNotification, setErrors, removeForms, pushErrors]
  );
  return { deleteForm, deleteFormLoading, deleteFormErrors };
};

const LINK_FORM_MUTATION = gql`
  ${FORM_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation LinkForm($input: LinkFormInput!) {
    payload: linkForm(input: $input) {
      data {
        tenantId
        projectId
        form {
          ...Form
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useLinkFormMutation = (
  onCompleted: (data: NonNullable<LinkFormMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: linkFormErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertForms } = useProjectContext();
  const [linkFormMutation, { loading: linkFormLoading }] = useMutation<LinkFormMutation, LinkFormMutationVariables>(LINK_FORM_MUTATION);
  const linkForm = useCallback(
    async (input: LinkFormInput) => {
      await linkFormMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, form } = data;
            upsertForms(tenantId, projectId, [form]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [linkFormMutation, onCompleted, onError, useNotification, setErrors, upsertForms, pushErrors]
  );
  return { linkForm, linkFormLoading, linkFormErrors };
};

const PUBLISH_FORM_MUTATION = gql`
  ${FORM_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation PublishForm($input: PublishFormInput!) {
    payload: publishForm(input: $input) {
      data {
        tenantId
        projectId
        form {
          ...Form
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const usePublishFormMutation = (
  onCompleted: (data: NonNullable<PublishFormMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: publishFormErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertForms } = useProjectContext();
  const [publishFormMutation, { loading: publishFormLoading }] = useMutation<PublishFormMutation, PublishFormMutationVariables>(PUBLISH_FORM_MUTATION);
  const publishForm = useCallback(
    async (input: PublishFormInput) => {
      await publishFormMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, form } = data;
            upsertForms(tenantId, projectId, [form]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [publishFormMutation, onCompleted, onError, useNotification, setErrors, upsertForms, pushErrors]
  );
  return { publishForm, publishFormLoading, publishFormErrors };
};

const ARCHIVE_FORM_MUTATION = gql`
  ${FORM_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation ArchiveForm($input: ArchiveFormInput!) {
    payload: archiveForm(input: $input) {
      data {
        tenantId
        projectId
        form {
          ...Form
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useArchiveFormMutation = (
  onCompleted: (data: NonNullable<ArchiveFormMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: archiveFormErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertForms } = useProjectContext();
  const [archiveFormMutation, { loading: archiveFormLoading }] = useMutation<ArchiveFormMutation, ArchiveFormMutationVariables>(ARCHIVE_FORM_MUTATION);
  const archiveForm = useCallback(
    async (input: ArchiveFormInput) => {
      await archiveFormMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, form } = data;
            upsertForms(tenantId, projectId, [form]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [archiveFormMutation, onCompleted, onError, useNotification, setErrors, upsertForms, pushErrors]
  );
  return { archiveForm, archiveFormLoading, archiveFormErrors };
};

// Model
export const IMPORT_MODEL_MUTATION = gql`
  ${MODEL_FRAGMENT}
  ${PRODUCT_CATEGORY_FRAGMENT}
  ${PRODUCT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation ImportModel($input: ImportModelInput!) {
    payload: importModel(input: $input) {
      data {
        tenantId
        projectId
        model {
          ...Model
        }
        productCategories {
          ...ProductCategory
        }
        products {
          ...Product
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useImportModelMutation = (
  onCompleted: (data: NonNullable<ImportModelMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: importModelErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertModels, upsertProductCategories, upsertProducts } = useProjectContext();
  const [importModelMutation, { loading: importModelLoading }] = useMutation<ImportModelMutation, ImportModelMutationVariables>(IMPORT_MODEL_MUTATION);
  const importModel = useCallback(
    async (input: ImportModelInput) => {
      await importModelMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, model, productCategories, products } = data;
            upsertModels(tenantId, projectId, [model]);
            upsertProductCategories(tenantId, projectId, productCategories);
            upsertProducts(tenantId, projectId, products);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [importModelMutation, onCompleted, onError, useNotification, setErrors, upsertModels, upsertProducts, upsertProductCategories, pushErrors]
  );
  return { importModel, importModelLoading, importModelErrors };
};

const DELETE_MODEL_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DeleteModel($input: DeleteModelInput!) {
    payload: deleteModel(input: $input) {
      data {
        tenantId
        projectId
        modelId
        productIds
        productCategoryIds
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteModelMutation = (
  onCompleted: (data: NonNullable<DeleteModelMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: deleteModelErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { removeModels, removeProductCategories, removeProducts } = useProjectContext();
  const [deleteModelMutation, { loading: deleteModelLoading }] = useMutation<DeleteModelMutation, DeleteModelMutationVariables>(DELETE_MODEL_MUTATION);
  const deleteModel = useCallback(
    async (input: DeleteModelInput) => {
      await deleteModelMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, modelId, productCategoryIds, productIds } = data;
            removeModels(tenantId, projectId, [modelId]);
            removeProducts(tenantId, projectId, productIds);
            removeProductCategories(tenantId, projectId, productCategoryIds);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [deleteModelMutation, onCompleted, onError, useNotification, setErrors, removeModels, removeProductCategories, removeProducts, pushErrors]
  );
  return { deleteModel, deleteModelLoading, deleteModelErrors };
};

const LOAD_MODEL_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation LoadModel($input: LoadModelInput!) {
    payload: loadModel(input: $input) {
      data {
        unityLoaderUrl
        unityDataUrl
        unityFrameworkUrl
        unityCodeUrl
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useLoadModelMutation = (
  onCompleted: (data: NonNullable<LoadModelMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: loadModelErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const [loadModelMutation, { loading: loadModelLoading }] = useMutation<LoadModelMutation, LoadModelMutationVariables>(LOAD_MODEL_MUTATION);
  const loadModel = useCallback(
    async (input: LoadModelInput) => {
      await loadModelMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [loadModelMutation, onCompleted, onError, useNotification, setErrors, pushErrors]
  );
  return { loadModel, loadModelLoading, loadModelErrors };
};

// Payment
const CREATE_CONTRACT_INVOICE_PAYMENT_MUTATION = gql`
  ${INVOICE_FRAGMENT}
  ${CONTRACT_FRAGMENT}
  ${PAYMENT_FRAGMENT}
  ${UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateContractInvoicePayment($input: CreateContractInvoicePaymentInput!) {
    payload: createContractInvoicePayment(input: $input) {
      data {
        tenantId
        projectId
        payment {
          ...Payment
        }
        invoice {
          ...Invoice
        }
        contract {
          ...Contract
        }
        unit {
          ...Unit
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateContractInvoicePaymentMutation = (
  onCompleted: (data: NonNullable<CreateContractInvoicePaymentMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: createContractInvoicePaymentErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertContracts, upsertInvoices, upsertPayments, upsertUnits } = useProjectContext();
  const [createContractInvoicePaymentMutation, { loading: createContractInvoicePaymentLoading }] = useMutation<CreateContractInvoicePaymentMutation, CreateContractInvoicePaymentMutationVariables>(
    CREATE_CONTRACT_INVOICE_PAYMENT_MUTATION
  );
  const createContractInvoicePayment = useCallback(
    async (input: CreateContractInvoicePaymentInput) => {
      await createContractInvoicePaymentMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, payment, invoice, contract, unit } = data;
            upsertPayments(tenantId, projectId, [payment]);
            upsertInvoices(tenantId, projectId, [invoice]);
            upsertContracts(tenantId, projectId, [contract]);
            upsertUnits(tenantId, projectId, [unit]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [createContractInvoicePaymentMutation, onCompleted, onError, useNotification, setErrors, upsertInvoices, upsertPayments, upsertUnits, upsertContracts, pushErrors]
  );
  return { createContractInvoicePayment, createContractInvoicePaymentLoading, createContractInvoicePaymentErrors };
};

const UPDATE_CONTRACT_INVOICE_PAYMENT_MUTATION = gql`
  ${INVOICE_FRAGMENT}
  ${CONTRACT_FRAGMENT}
  ${PAYMENT_FRAGMENT}
  ${UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateContractInvoicePayment($input: UpdateContractInvoicePaymentInput!) {
    payload: updateContractInvoicePayment(input: $input) {
      data {
        tenantId
        projectId
        payment {
          ...Payment
        }
        invoice {
          ...Invoice
        }
        contract {
          ...Contract
        }
        unit {
          ...Unit
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateContractInvoicePaymentMutation = (
  onCompleted: (data: NonNullable<UpdateContractInvoicePaymentMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateContractInvoicePaymentErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertContracts, upsertInvoices, upsertPayments, upsertUnits } = useProjectContext();
  const [updateContractInvoicePaymentMutation, { loading: updateContractInvoicePaymentLoading }] = useMutation<UpdateContractInvoicePaymentMutation, UpdateContractInvoicePaymentMutationVariables>(
    UPDATE_CONTRACT_INVOICE_PAYMENT_MUTATION
  );
  const updateContractInvoicePayment = useCallback(
    async (input: UpdateContractInvoicePaymentInput) => {
      await updateContractInvoicePaymentMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, payment, invoice, contract, unit } = data;
            upsertPayments(tenantId, projectId, [payment]);
            upsertInvoices(tenantId, projectId, [invoice]);
            upsertContracts(tenantId, projectId, [contract]);
            upsertUnits(tenantId, projectId, [unit]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateContractInvoicePaymentMutation, onCompleted, onError, useNotification, setErrors, upsertInvoices, upsertUnits, upsertPayments, upsertContracts, pushErrors]
  );
  return { updateContractInvoicePayment, updateContractInvoicePaymentLoading, updateContractInvoicePaymentErrors };
};

const DELETE_CONTRACT_INVOICE_PAYMENT_MUTATION = gql`
  ${INVOICE_FRAGMENT}
  ${CONTRACT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation DeleteContractInvoicePayment($input: DeleteContractInvoicePaymentInput!) {
    payload: deleteContractInvoicePayment(input: $input) {
      data {
        tenantId
        projectId
        paymentId
        invoice {
          ...Invoice
        }
        contract {
          ...Contract
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteContractInvoicePaymentMutation = (
  onCompleted: (data: NonNullable<DeleteContractInvoicePaymentMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: deleteContractInvoicePaymentErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertContracts, upsertInvoices, removePayments } = useProjectContext();
  const [deleteContractInvoicePaymentMutation, { loading: deleteContractInvoicePaymentLoading }] = useMutation<DeleteContractInvoicePaymentMutation, DeleteContractInvoicePaymentMutationVariables>(
    DELETE_CONTRACT_INVOICE_PAYMENT_MUTATION
  );
  const deleteContractInvoicePayment = useCallback(
    async (input: DeleteContractInvoicePaymentInput) => {
      await deleteContractInvoicePaymentMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, paymentId, invoice, contract } = data;
            removePayments(tenantId, projectId, [paymentId]);
            upsertInvoices(tenantId, projectId, [invoice]);
            upsertContracts(tenantId, projectId, [contract]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [deleteContractInvoicePaymentMutation, onCompleted, onError, useNotification, setErrors, upsertInvoices, removePayments, upsertContracts, pushErrors]
  );
  return { deleteContractInvoicePayment, deleteContractInvoicePaymentLoading, deleteContractInvoicePaymentErrors };
};

// Product
const CREATE_PRODUCT_MUTATION = gql`
  ${PRODUCT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateProduct($input: CreateProductInput!) {
    payload: createProduct(input: $input) {
      data {
        tenantId
        projectId
        product {
          ...Product
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateProductMutation = (
  onCompleted: (data: NonNullable<CreateProductMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: createProductErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertProducts } = useProjectContext();
  const [createProductMutation, { loading: createProductLoading }] = useMutation<CreateProductMutation, CreateProductMutationVariables>(CREATE_PRODUCT_MUTATION);
  const createProduct = useCallback(
    async (input: CreateProductInput) => {
      await createProductMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, product } = data;
            upsertProducts(tenantId, projectId, [product]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [createProductMutation, onCompleted, onError, useNotification, setErrors, upsertProducts, pushErrors]
  );
  return { createProduct, createProductLoading, createProductErrors };
};

const UPDATE_PRODUCT_MUTATION = gql`
  ${PRODUCT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateProduct($input: UpdateProductInput!) {
    payload: updateProduct(input: $input) {
      data {
        tenantId
        projectId
        product {
          ...Product
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateProductMutation = (
  onCompleted: (data: NonNullable<UpdateProductMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateProductErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertProducts } = useProjectContext();
  const [updateProductMutation, { loading: updateProductLoading }] = useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UPDATE_PRODUCT_MUTATION);
  const updateProduct = useCallback(
    async (input: UpdateProductInput) => {
      await updateProductMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, product } = data;
            upsertProducts(tenantId, projectId, [product]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateProductMutation, onCompleted, onError, useNotification, setErrors, upsertProducts, pushErrors]
  );
  return { updateProduct, updateProductLoading, updateProductErrors };
};

const UPDATE_PRODUCTS_MUTATION = gql`
  ${PRODUCT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateProducts($input: UpdateProductsInput!) {
    payload: updateProducts(input: $input) {
      data {
        tenantId
        projectId
        products {
          ...Product
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateProductsMutation = (
  onCompleted: (data: NonNullable<UpdateProductsMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateProductsErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertProducts } = useProjectContext();
  const [updateProductsMutation, { loading: updateProductsLoading }] = useMutation<UpdateProductsMutation, UpdateProductsMutationVariables>(UPDATE_PRODUCTS_MUTATION);
  const updateProducts = useCallback(
    async (input: UpdateProductsInput) => {
      await updateProductsMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, products } = data;
            upsertProducts(tenantId, projectId, products);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateProductsMutation, onCompleted, onError, useNotification, setErrors, upsertProducts, pushErrors]
  );
  return { updateProducts, updateProductsLoading, updateProductsErrors };
};

const DELETE_PRODUCT_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DeleteProduct($input: DeleteProductInput!) {
    payload: deleteProduct(input: $input) {
      data {
        tenantId
        projectId
        productId
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteProductMutation = (
  onCompleted: (data: NonNullable<DeleteProductMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: deleteProductErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { removeProducts } = useProjectContext();
  const [deleteProductMutation, { loading: deleteProductLoading }] = useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DELETE_PRODUCT_MUTATION);
  const deleteProduct = useCallback(
    async (input: DeleteProductInput) => {
      await deleteProductMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, productId } = data;
            removeProducts(tenantId, projectId, [productId]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [deleteProductMutation, onCompleted, onError, useNotification, setErrors, removeProducts, pushErrors]
  );
  return { deleteProduct, deleteProductLoading, deleteProductErrors };
};

// ProductCategory
const CREATE_PRODUCT_CATEGORY_MUTATION = gql`
  ${PRODUCT_CATEGORY_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateProductCategory($input: CreateProductCategoryInput!) {
    payload: createProductCategory(input: $input) {
      data {
        tenantId
        projectId
        productCategory {
          ...ProductCategory
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateProductCategoryMutation = (
  onCompleted: (data: NonNullable<CreateProductCategoryMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: createProductCategoryErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertProductCategories } = useProjectContext();
  const [createProductCategoryMutation, { loading: createProductCategoryLoading }] = useMutation<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>(
    CREATE_PRODUCT_CATEGORY_MUTATION
  );
  const createProductCategory = useCallback(
    async (input: CreateProductCategoryInput) => {
      await createProductCategoryMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, productCategory } = data;
            upsertProductCategories(tenantId, projectId, [productCategory]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [createProductCategoryMutation, onCompleted, onError, useNotification, setErrors, upsertProductCategories, pushErrors]
  );
  return { createProductCategory, createProductCategoryLoading, createProductCategoryErrors };
};

const UPDATE_PRODUCT_CATEGORY_MUTATION = gql`
  ${PRODUCT_FRAGMENT}
  ${PRODUCT_CATEGORY_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateProductCategory($input: UpdateProductCategoryInput!) {
    payload: updateProductCategory(input: $input) {
      data {
        tenantId
        projectId
        productCategory {
          ...ProductCategory
        }
        products {
          ...Product
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateProductCategoryMutation = (
  onCompleted: (data: NonNullable<UpdateProductCategoryMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateProductCategoryErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertProductCategories, upsertProducts } = useProjectContext();
  const [updateProductCategoryMutation, { loading: updateProductCategoryLoading }] = useMutation<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>(
    UPDATE_PRODUCT_CATEGORY_MUTATION
  );
  const updateProductCategory = useCallback(
    async (input: UpdateProductCategoryInput) => {
      await updateProductCategoryMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, productCategory, products } = data;
            upsertProductCategories(tenantId, projectId, [productCategory]);
            upsertProducts(tenantId, projectId, products);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateProductCategoryMutation, onCompleted, onError, useNotification, setErrors, upsertProducts, upsertProductCategories, pushErrors]
  );
  return { updateProductCategory, updateProductCategoryLoading, updateProductCategoryErrors };
};

const DELETE_PRODUCT_CATEGORY_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DeleteProductCategory($input: DeleteProductCategoryInput!) {
    payload: deleteProductCategory(input: $input) {
      data {
        tenantId
        projectId
        productCategoryId
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteProductCategoryMutation = (
  onCompleted: (data: NonNullable<DeleteProductCategoryMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: deleteProductCategoryErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { removeProductCategories } = useProjectContext();
  const [deleteProductCategoryMutation, { loading: deleteProductCategoryLoading }] = useMutation<DeleteProductCategoryMutation, DeleteProductCategoryMutationVariables>(
    DELETE_PRODUCT_CATEGORY_MUTATION
  );
  const deleteProductCategory = useCallback(
    async (input: DeleteProductCategoryInput) => {
      await deleteProductCategoryMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, productCategoryId } = data;
            removeProductCategories(tenantId, projectId, [productCategoryId]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [deleteProductCategoryMutation, onCompleted, onError, useNotification, setErrors, removeProductCategories, pushErrors]
  );
  return { deleteProductCategory, deleteProductCategoryLoading, deleteProductCategoryErrors };
};

// Project
const CREATE_PROJECT_MUTATION = gql`
  ${PROJECT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateProject($input: CreateProjectInput!) {
    payload: createProject(input: $input) {
      data {
        tenantId
        project {
          ...Project
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateProjectMutation = (
  onCompleted: (data: NonNullable<CreateProjectMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: createProjectErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertProjects } = useTenantContext();
  const [createProjectMutation, { loading: createProjectLoading }] = useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CREATE_PROJECT_MUTATION);
  const createProject = useCallback(
    async (input: CreateProjectInput) => {
      await createProjectMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, project } = data;
            upsertProjects(tenantId, [project]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [createProjectMutation, onCompleted, onError, useNotification, setErrors, upsertProjects, pushErrors]
  );
  return { createProject, createProjectLoading, createProjectErrors };
};

const UPDATE_PROJECT_MUTATION = gql`
  ${PROJECT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateProject($input: UpdateProjectInput!) {
    payload: updateProject(input: $input) {
      data {
        tenantId
        project {
          ...Project
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateProjectMutation = (
  onCompleted: (data: NonNullable<UpdateProjectMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateProjectErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertProjects } = useTenantContext();
  const [updateProjectMutation, { loading: updateProjectLoading }] = useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UPDATE_PROJECT_MUTATION);
  const updateProject = useCallback(
    async (input: UpdateProjectInput) => {
      await updateProjectMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, project } = data;
            upsertProjects(tenantId, [project]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateProjectMutation, onCompleted, onError, useNotification, setErrors, upsertProjects, pushErrors]
  );
  return { updateProject, updateProjectLoading, updateProjectErrors };
};

const DELETE_PROJECT_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DeleteProject($input: DeleteProjectInput!) {
    payload: deleteProject(input: $input) {
      data {
        tenantId
        projectId
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteProjectMutation = (
  onCompleted: (data: NonNullable<DeleteProjectMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: deleteProjectErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { removeProjects } = useTenantContext();
  const [deleteProjectMutation, { loading: deleteProjectLoading }] = useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DELETE_PROJECT_MUTATION);
  const deleteProject = useCallback(
    async (input: DeleteProjectInput) => {
      await deleteProjectMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId } = data;
            removeProjects(tenantId, [projectId]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [deleteProjectMutation, onCompleted, onError, useNotification, setErrors, removeProjects, pushErrors]
  );
  return { deleteProject, deleteProjectLoading, deleteProjectErrors };
};

// Provider
export const CREATE_PROVIDER_MUTATION = gql`
  ${PROVIDER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateProvider($input: CreateProviderInput!) {
    payload: createProvider(input: $input) {
      data {
        tenantId
        provider {
          ...Provider
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateProviderMutation = (
  onCompleted: (data: NonNullable<CreateProviderMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: createProviderErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertProviders } = useTenantContext();
  const [createProviderMutation, { loading: createProviderLoading }] = useMutation<CreateProviderMutation, CreateProviderMutationVariables>(CREATE_PROVIDER_MUTATION);
  const createProvider = useCallback(
    async (input: CreateProviderInput) => {
      await createProviderMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, provider } = data;
            upsertProviders(tenantId, [provider]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [createProviderMutation, onCompleted, onError, useNotification, setErrors, upsertProviders, pushErrors]
  );
  return { createProvider, createProviderLoading, createProviderErrors };
};

export const UPDATE_PROVIDER_MUTATION = gql`
  ${PROVIDER_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateProvider($input: UpdateProviderInput!) {
    payload: updateProvider(input: $input) {
      data {
        tenantId
        provider {
          ...Provider
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateProviderMutation = (
  onCompleted: (data: NonNullable<UpdateProviderMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateProviderErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertProviders } = useTenantContext();
  const [updateProviderMutation, { loading: updateProviderLoading }] = useMutation<UpdateProviderMutation, UpdateProviderMutationVariables>(UPDATE_PROVIDER_MUTATION);
  const updateProvider = useCallback(
    async (input: UpdateProviderInput) => {
      await updateProviderMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, provider } = data;
            upsertProviders(tenantId, [provider]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateProviderMutation, onCompleted, onError, useNotification, setErrors, upsertProviders, pushErrors]
  );
  return { updateProvider, updateProviderLoading, updateProviderErrors };
};

const DELETE_PROVIDER_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DeleteProvider($input: DeleteProviderInput!) {
    payload: deleteProvider(input: $input) {
      data {
        tenantId
        providerId
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteProviderMutation = (
  onCompleted: (data: NonNullable<DeleteProviderMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: deleteProviderErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { removeProviders } = useTenantContext();
  const [deleteProviderMutation, { loading: deleteProviderLoading }] = useMutation<DeleteProviderMutation, DeleteProviderMutationVariables>(DELETE_PROVIDER_MUTATION);
  const deleteProvider = useCallback(
    async (input: DeleteProviderInput) => {
      await deleteProviderMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, providerId } = data;
            removeProviders(tenantId, [providerId]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [deleteProviderMutation, onCompleted, onError, useNotification, setErrors, removeProviders, pushErrors]
  );
  return { deleteProvider, deleteProviderLoading, deleteProviderErrors };
};

// Refund
const CREATE_CONTRACT_REFUND_MUTATION = gql`
  ${CONTRACT_FRAGMENT}
  ${REFUND_FRAGMENT}
  ${UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateContractRefund($input: CreateContractRefundInput!) {
    payload: createContractRefund(input: $input) {
      data {
        tenantId
        projectId
        refund {
          ...Refund
        }
        contract {
          ...Contract
        }
        unit {
          ...Unit
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateContractRefundMutation = (
  onCompleted: (data: NonNullable<CreateContractRefundMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: createContractRefundErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertContracts, upsertRefunds, upsertUnits } = useProjectContext();
  const [createContractRefundMutation, { loading: createContractRefundLoading }] = useMutation<CreateContractRefundMutation, CreateContractRefundMutationVariables>(CREATE_CONTRACT_REFUND_MUTATION);
  const createContractRefund = useCallback(
    async (input: CreateContractRefundInput) => {
      await createContractRefundMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, refund, contract, unit } = data;
            upsertRefunds(tenantId, projectId, [refund]);
            upsertContracts(tenantId, projectId, [contract]);
            upsertUnits(tenantId, projectId, [unit]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [createContractRefundMutation, onCompleted, onError, useNotification, setErrors, upsertUnits, upsertRefunds, upsertContracts, pushErrors]
  );
  return { createContractRefund, createContractRefundLoading, createContractRefundErrors };
};

const UPDATE_CONTRACT_REFUND_MUTATION = gql`
  ${REFUND_FRAGMENT}
  ${CONTRACT_FRAGMENT}
  ${UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateContractRefund($input: UpdateContractRefundInput!) {
    payload: updateContractRefund(input: $input) {
      data {
        tenantId
        projectId
        refund {
          ...Refund
        }
        contract {
          ...Contract
        }
        unit {
          ...Unit
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateContractRefundMutation = (
  onCompleted: (data: NonNullable<UpdateContractRefundMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateContractRefundErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertContracts, upsertRefunds, upsertUnits } = useProjectContext();
  const [updateContractRefundMutation, { loading: updateContractRefundLoading }] = useMutation<UpdateContractRefundMutation, UpdateContractRefundMutationVariables>(UPDATE_CONTRACT_REFUND_MUTATION);
  const updateContractRefund = useCallback(
    async (input: UpdateContractRefundInput) => {
      await updateContractRefundMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, refund, contract, unit } = data;
            upsertRefunds(tenantId, projectId, [refund]);
            upsertContracts(tenantId, projectId, [contract]);
            upsertUnits(tenantId, projectId, [unit]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateContractRefundMutation, onCompleted, onError, useNotification, setErrors, upsertUnits, upsertRefunds, upsertContracts, pushErrors]
  );
  return { updateContractRefund, updateContractRefundLoading, updateContractRefundErrors };
};

const DELETE_CONTRACT_REFUND_MUTATION = gql`
  ${CONTRACT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation DeleteContractRefund($input: DeleteContractRefundInput!) {
    payload: deleteContractRefund(input: $input) {
      data {
        tenantId
        projectId
        refundId
        contract {
          ...Contract
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteContractRefundMutation = (
  onCompleted: (data: NonNullable<DeleteContractRefundMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: deleteContractRefundErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertContracts, removeRefunds } = useProjectContext();
  const [deleteContractRefundMutation, { loading: deleteContractRefundLoading }] = useMutation<DeleteContractRefundMutation, DeleteContractRefundMutationVariables>(DELETE_CONTRACT_REFUND_MUTATION);
  const deleteContractRefund = useCallback(
    async (input: DeleteContractRefundInput) => {
      await deleteContractRefundMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, refundId, contract } = data;
            removeRefunds(tenantId, projectId, [refundId]);
            upsertContracts(tenantId, projectId, [contract]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [deleteContractRefundMutation, onCompleted, onError, useNotification, setErrors, removeRefunds, upsertContracts, pushErrors]
  );
  return { deleteContractRefund, deleteContractRefundLoading, deleteContractRefundErrors };
};

const CREATE_CONTRACT_INVOICE_REFUND_MUTATION = gql`
  ${INVOICE_FRAGMENT}
  ${CONTRACT_FRAGMENT}
  ${REFUND_FRAGMENT}
  ${UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateContractInvoiceRefund($input: CreateContractInvoiceRefundInput!) {
    payload: createContractInvoiceRefund(input: $input) {
      data {
        tenantId
        projectId
        refund {
          ...Refund
        }
        invoice {
          ...Invoice
        }
        contract {
          ...Contract
        }
        unit {
          ...Unit
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateContractInvoiceRefundMutation = (
  onCompleted: (data: NonNullable<CreateContractInvoiceRefundMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: createContractInvoiceRefundErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertContracts, upsertInvoices, upsertRefunds, upsertUnits } = useProjectContext();
  const [createContractInvoiceRefundMutation, { loading: createContractInvoiceRefundLoading }] = useMutation<CreateContractInvoiceRefundMutation, CreateContractInvoiceRefundMutationVariables>(
    CREATE_CONTRACT_INVOICE_REFUND_MUTATION
  );
  const createContractInvoiceRefund = useCallback(
    async (input: CreateContractInvoiceRefundInput) => {
      await createContractInvoiceRefundMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, refund, invoice, contract, unit } = data;
            upsertRefunds(tenantId, projectId, [refund]);
            upsertInvoices(tenantId, projectId, [invoice]);
            upsertContracts(tenantId, projectId, [contract]);
            upsertUnits(tenantId, projectId, [unit]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [createContractInvoiceRefundMutation, onCompleted, onError, useNotification, setErrors, upsertInvoices, upsertUnits, upsertRefunds, upsertContracts, pushErrors]
  );
  return { createContractInvoiceRefund, createContractInvoiceRefundLoading, createContractInvoiceRefundErrors };
};

const UPDATE_CONTRACT_INVOICE_REFUND_MUTATION = gql`
  ${INVOICE_FRAGMENT}
  ${REPORT_FRAGMENT}
  ${CONTRACT_FRAGMENT}
  ${UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateContractInvoiceRefund($input: UpdateContractInvoiceRefundInput!) {
    payload: updateContractInvoiceRefund(input: $input) {
      data {
        tenantId
        projectId
        refund {
          ...Refund
        }
        invoice {
          ...Invoice
        }
        contract {
          ...Contract
        }
        unit {
          ...Unit
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateContractInvoiceRefundMutation = (
  onCompleted: (data: NonNullable<UpdateContractInvoiceRefundMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateContractInvoiceRefundErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertContracts, upsertInvoices, upsertRefunds, upsertUnits } = useProjectContext();
  const [updateContractInvoiceRefundMutation, { loading: updateContractInvoiceRefundLoading }] = useMutation<UpdateContractInvoiceRefundMutation, UpdateContractInvoiceRefundMutationVariables>(
    UPDATE_CONTRACT_INVOICE_REFUND_MUTATION
  );
  const updateContractInvoiceRefund = useCallback(
    async (input: UpdateContractInvoiceRefundInput) => {
      await updateContractInvoiceRefundMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, refund, invoice, contract, unit } = data;
            upsertRefunds(tenantId, projectId, [refund]);
            upsertInvoices(tenantId, projectId, [invoice]);
            upsertContracts(tenantId, projectId, [contract]);
            upsertUnits(tenantId, projectId, [unit]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateContractInvoiceRefundMutation, onCompleted, onError, useNotification, setErrors, upsertInvoices, upsertUnits, upsertRefunds, upsertContracts, pushErrors]
  );
  return { updateContractInvoiceRefund, updateContractInvoiceRefundLoading, updateContractInvoiceRefundErrors };
};

const DELETE_CONTRACT_INVOICE_REFUND_MUTATION = gql`
  ${INVOICE_FRAGMENT}
  ${CONTRACT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation DeleteContractInvoiceRefund($input: DeleteContractInvoiceRefundInput!) {
    payload: deleteContractInvoiceRefund(input: $input) {
      data {
        tenantId
        projectId
        refundId
        invoice {
          ...Invoice
        }
        contract {
          ...Contract
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteContractInvoiceRefundMutation = (
  onCompleted: (data: NonNullable<DeleteContractInvoiceRefundMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: deleteContractInvoiceRefundErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertContracts, upsertInvoices, removeRefunds } = useProjectContext();
  const [deleteContractInvoiceRefundMutation, { loading: deleteContractInvoiceRefundLoading }] = useMutation<DeleteContractInvoiceRefundMutation, DeleteContractInvoiceRefundMutationVariables>(
    DELETE_CONTRACT_INVOICE_REFUND_MUTATION
  );
  const deleteContractInvoiceRefund = useCallback(
    async (input: DeleteContractInvoiceRefundInput) => {
      await deleteContractInvoiceRefundMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, refundId, invoice, contract } = data;
            removeRefunds(tenantId, projectId, [refundId]);
            upsertInvoices(tenantId, projectId, [invoice]);
            upsertContracts(tenantId, projectId, [contract]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [deleteContractInvoiceRefundMutation, onCompleted, onError, useNotification, setErrors, upsertInvoices, removeRefunds, upsertContracts, pushErrors]
  );
  return { deleteContractInvoiceRefund, deleteContractInvoiceRefundLoading, deleteContractInvoiceRefundErrors };
};

// Report
const UPDATE_REPORT_MUTATION = gql`
  ${REPORT_FRAGMENT}
  ${FORM_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateReport($input: UpdateReportInput!) {
    payload: updateReport(input: $input) {
      data {
        tenantId
        projectId
        report {
          ...Report
        }
        forms {
          ...Form
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateReportMutation = (
  onCompleted: (data: NonNullable<UpdateReportMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateReportErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertForms, upsertReports } = useProjectContext();
  const [updateReportMutation, { loading: updateReportLoading }] = useMutation<UpdateReportMutation, UpdateReportMutationVariables>(UPDATE_REPORT_MUTATION);
  const updateReport = useCallback(
    async (input: UpdateReportInput) => {
      await updateReportMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, report, forms } = data;
            upsertReports(tenantId, projectId, [report]);
            upsertForms(tenantId, projectId, forms);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateReportMutation, onCompleted, upsertForms, onError, useNotification, setErrors, upsertReports, pushErrors]
  );
  return { updateReport, updateReportLoading, updateReportErrors };
};

// Section
const CREATE_SECTION_MUTATION = gql`
  ${SECTION_FRAGMENT}
  ${PRODUCT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateSection($input: CreateSectionInput!) {
    payload: createSection(input: $input) {
      data {
        tenantId
        projectId
        section {
          ...Section
        }
        products {
          ...Product
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateSectionMutation = (
  onCompleted: (data: NonNullable<CreateSectionMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: createSectionErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertSections, upsertProducts } = useProjectContext();
  const [createSectionMutation, { loading: createSectionLoading }] = useMutation<CreateSectionMutation, CreateSectionMutationVariables>(CREATE_SECTION_MUTATION);
  const createSection = useCallback(
    async (input: CreateSectionInput) => {
      await createSectionMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, section, products } = data;
            upsertSections(tenantId, projectId, [section]);
            upsertProducts(tenantId, projectId, products);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [createSectionMutation, onCompleted, onError, useNotification, upsertProducts, setErrors, upsertSections, pushErrors]
  );
  return { createSection, createSectionLoading, createSectionErrors };
};

const UPDATE_SECTION_MUTATION = gql`
  ${SECTION_FRAGMENT}
  ${PRODUCT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateSection($input: UpdateSectionInput!) {
    payload: updateSection(input: $input) {
      data {
        tenantId
        projectId
        section {
          ...Section
        }
        products {
          ...Product
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateSectionMutation = (
  onCompleted: (data: NonNullable<UpdateSectionMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateSectionErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertSections, upsertProducts } = useProjectContext();
  const [updateSectionMutation, { loading: updateSectionLoading }] = useMutation<UpdateSectionMutation, UpdateSectionMutationVariables>(UPDATE_SECTION_MUTATION);
  const updateSection = useCallback(
    async (input: UpdateSectionInput) => {
      await updateSectionMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, section, products } = data;
            upsertSections(tenantId, projectId, [section]);
            upsertProducts(tenantId, projectId, products);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateSectionMutation, onCompleted, onError, useNotification, setErrors, upsertProducts, upsertSections, pushErrors]
  );
  return { updateSection, updateSectionLoading, updateSectionErrors };
};

const DELETE_SECTION_MUTATION = gql`
  ${PRODUCT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation DeleteSection($input: DeleteSectionInput!) {
    payload: deleteSection(input: $input) {
      data {
        tenantId
        projectId
        sectionId
        products {
          ...Product
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteSectionMutation = (
  onCompleted: (data: NonNullable<DeleteSectionMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: deleteSectionErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { removeSections, upsertProducts } = useProjectContext();
  const [deleteSectionMutation, { loading: deleteSectionLoading }] = useMutation<DeleteSectionMutation, DeleteSectionMutationVariables>(DELETE_SECTION_MUTATION);
  const deleteSection = useCallback(
    async (input: DeleteSectionInput) => {
      await deleteSectionMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, sectionId, products } = data;
            removeSections(tenantId, projectId, [sectionId]);
            upsertProducts(tenantId, projectId, products);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [deleteSectionMutation, onCompleted, onError, useNotification, setErrors, removeSections, upsertProducts, pushErrors]
  );
  return { deleteSection, deleteSectionLoading, deleteSectionErrors };
};

const CONFIGURE_SECTION_MUTATION = gql`
  ${SECTION_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation ConfigureSection($input: ConfigureSectionInput!) {
    payload: configureSection(input: $input) {
      data {
        tenantId
        projectId
        section {
          ...Section
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useConfigureSectionMutation = (
  onCompleted: (data: NonNullable<ConfigureSectionMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: configureSectionErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertSections } = useProjectContext();
  const [configureSectionMutation, { loading: configureSectionLoading }] = useMutation<ConfigureSectionMutation, ConfigureSectionMutationVariables>(CONFIGURE_SECTION_MUTATION);
  const configureSection = useCallback(
    async (input: ConfigureSectionInput) => {
      await configureSectionMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, section } = data;
            upsertSections(tenantId, projectId, [section]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [configureSectionMutation, onCompleted, onError, useNotification, setErrors, upsertSections, pushErrors]
  );
  return { configureSection, configureSectionLoading, configureSectionErrors };
};

const PUBLISH_SECTION_MUTATION = gql`
  ${SECTION_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation PublishSection($input: PublishSectionInput!) {
    payload: publishSection(input: $input) {
      data {
        tenantId
        projectId
        section {
          ...Section
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const usePublishSectionMutation = (
  onCompleted: (data: NonNullable<PublishSectionMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: publishSectionErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertSections } = useProjectContext();
  const [publishSectionMutation, { loading: publishSectionLoading }] = useMutation<PublishSectionMutation, PublishSectionMutationVariables>(PUBLISH_SECTION_MUTATION);
  const publishSection = useCallback(
    async (input: PublishSectionInput) => {
      await publishSectionMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, section } = data;
            upsertSections(tenantId, projectId, [section]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [publishSectionMutation, onCompleted, onError, useNotification, setErrors, upsertSections, pushErrors]
  );
  return { publishSection, publishSectionLoading, publishSectionErrors };
};

const ARCHIVE_SECTION_MUTATION = gql`
  ${SECTION_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation ArchiveSection($input: ArchiveSectionInput!) {
    payload: archiveSection(input: $input) {
      data {
        tenantId
        projectId
        section {
          ...Section
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useArchiveSectionMutation = (
  onCompleted: (data: NonNullable<ArchiveSectionMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: archiveSectionErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertSections } = useProjectContext();
  const [archiveSectionMutation, { loading: archiveSectionLoading }] = useMutation<ArchiveSectionMutation, ArchiveSectionMutationVariables>(ARCHIVE_SECTION_MUTATION);
  const archiveSection = useCallback(
    async (input: ArchiveSectionInput) => {
      await archiveSectionMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, section } = data;
            upsertSections(tenantId, projectId, [section]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [archiveSectionMutation, onCompleted, onError, useNotification, setErrors, upsertSections, pushErrors]
  );
  return { archiveSection, archiveSectionLoading, archiveSectionErrors };
};

export const CREATE_SECTION_VIBE_MUTATION = gql`
  ${SECTION_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateSectionVibe($input: CreateSectionVibeInput!) {
    payload: createSectionVibe(input: $input) {
      data {
        tenantId
        projectId
        section {
          ...Section
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateSectionVibeMutation = (
  onCompleted: (data: NonNullable<CreateSectionVibeMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: createSectionVibeErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertSections } = useProjectContext();
  const [createSectionVibeMutation, { loading: createSectionVibeLoading }] = useMutation<CreateSectionVibeMutation, CreateSectionVibeMutationVariables>(CREATE_SECTION_VIBE_MUTATION);
  const createSectionVibe = useCallback(
    async (input: CreateSectionVibeInput) => {
      await createSectionVibeMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, section } = data;
            upsertSections(tenantId, projectId, [section]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [createSectionVibeMutation, onCompleted, onError, useNotification, setErrors, upsertSections, pushErrors]
  );
  return { createSectionVibe, createSectionVibeLoading, createSectionVibeErrors };
};

export const UPDATE_SECTION_VIBE_MUTATION = gql`
  ${SECTION_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateSectionVibe($input: UpdateSectionVibeInput!) {
    payload: updateSectionVibe(input: $input) {
      data {
        tenantId
        projectId
        section {
          ...Section
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateSectionVibeMutation = (
  onCompleted: (data: NonNullable<UpdateSectionVibeMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateSectionVibeErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertSections } = useProjectContext();
  const [updateSectionVibeMutation, { loading: updateSectionVibeLoading }] = useMutation<UpdateSectionVibeMutation, UpdateSectionVibeMutationVariables>(UPDATE_SECTION_VIBE_MUTATION);
  const updateSectionVibe = useCallback(
    async (input: UpdateSectionVibeInput) => {
      await updateSectionVibeMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, section } = data;
            upsertSections(tenantId, projectId, [section]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateSectionVibeMutation, onCompleted, onError, useNotification, setErrors, upsertSections, pushErrors]
  );
  return { updateSectionVibe, updateSectionVibeLoading, updateSectionVibeErrors };
};

const DELETE_SECTION_VIBE_MUTATION = gql`
  ${SECTION_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation DeleteSectionVibe($input: DeleteSectionVibeInput!) {
    payload: deleteSectionVibe(input: $input) {
      data {
        tenantId
        projectId
        section {
          ...Section
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteSectionVibeMutation = (
  onCompleted: (data: NonNullable<DeleteSectionVibeMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: deleteSectionVibeErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertSections } = useProjectContext();
  const [deleteSectionVibeMutation, { loading: deleteSectionVibeLoading }] = useMutation<DeleteSectionVibeMutation, DeleteSectionVibeMutationVariables>(DELETE_SECTION_VIBE_MUTATION);
  const deleteSectionVibe = useCallback(
    async (input: DeleteSectionVibeInput) => {
      await deleteSectionVibeMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, section } = data;
            upsertSections(tenantId, projectId, [section]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [deleteSectionVibeMutation, onCompleted, onError, useNotification, setErrors, upsertSections, pushErrors]
  );
  return { deleteSectionVibe, deleteSectionVibeLoading, deleteSectionVibeErrors };
};

// Template
const DOWNLOAD_TEMPLATE_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DownloadTemplate($input: DownloadTemplateInput!) {
    payload: downloadTemplate(input: $input) {
      data {
        templateUrl
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDownloadTemplateMutation = (
  onCompleted: (data: NonNullable<DownloadTemplateMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: downloadTemplateErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const [downloadTemplateMutation, { loading: downloadTemplateLoading }] = useMutation<DownloadTemplateMutation, DownloadTemplateMutationVariables>(DOWNLOAD_TEMPLATE_MUTATION);
  const downloadTemplate = useCallback(
    async (input: DownloadTemplateInput) => {
      await downloadTemplateMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [downloadTemplateMutation, onCompleted, onError, useNotification, setErrors, pushErrors]
  );
  return { downloadTemplate, downloadTemplateLoading, downloadTemplateErrors };
};

// Unit
export const CREATE_UNIT_MUTATION = gql`
  ${UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation CreateUnit($input: CreateUnitInput!) {
    payload: createUnit(input: $input) {
      data {
        tenantId
        projectId
        unit {
          ...Unit
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useCreateUnitMutation = (
  onCompleted: (data: NonNullable<CreateUnitMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: createUnitErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertUnits } = useProjectContext();
  const [createUnitMutation, { loading: createUnitLoading }] = useMutation<CreateUnitMutation, CreateUnitMutationVariables>(CREATE_UNIT_MUTATION);
  const createUnit = useCallback(
    async (input: CreateUnitInput) => {
      await createUnitMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, unit } = data;
            upsertUnits(tenantId, projectId, [unit]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [createUnitMutation, onCompleted, onError, useNotification, setErrors, upsertUnits, pushErrors]
  );
  return { createUnit, createUnitLoading, createUnitErrors };
};

export const UPDATE_UNIT_MUTATION = gql`
  ${UNIT_FRAGMENT}
  ${CONTRACT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateUnit($input: UpdateUnitInput!) {
    payload: updateUnit(input: $input) {
      data {
        tenantId
        projectId
        unit {
          ...Unit
        }
        contracts {
          ...Contract
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateUnitMutation = (
  onCompleted: (data: NonNullable<UpdateUnitMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateUnitErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertUnits, upsertContracts } = useProjectContext();
  const [updateUnitMutation, { loading: updateUnitLoading }] = useMutation<UpdateUnitMutation, UpdateUnitMutationVariables>(UPDATE_UNIT_MUTATION);
  const updateUnit = useCallback(
    async (input: UpdateUnitInput) => {
      await updateUnitMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, unit, contracts } = data;
            upsertUnits(tenantId, projectId, [unit]);
            upsertContracts(tenantId, projectId, contracts);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateUnitMutation, onCompleted, onError, useNotification, setErrors, upsertUnits, upsertContracts, pushErrors]
  );
  return { updateUnit, updateUnitLoading, updateUnitErrors };
};

const DELETE_UNIT_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DeleteUnit($input: DeleteUnitInput!) {
    payload: deleteUnit(input: $input) {
      data {
        tenantId
        projectId
        unitId
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useDeleteUnitMutation = (
  onCompleted: (data: NonNullable<DeleteUnitMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: deleteUnitErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { removeUnits } = useProjectContext();
  const [deleteUnitMutation, { loading: deleteUnitLoading }] = useMutation<DeleteUnitMutation, DeleteUnitMutationVariables>(DELETE_UNIT_MUTATION);
  const deleteUnit = useCallback(
    async (input: DeleteUnitInput) => {
      await deleteUnitMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, unitId } = data;
            removeUnits(tenantId, projectId, [unitId]);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [deleteUnitMutation, onCompleted, onError, useNotification, setErrors, removeUnits, pushErrors]
  );
  return { deleteUnit, deleteUnitLoading, deleteUnitErrors };
};

const UPDATE_UNITS_MUTATION = gql`
  ${UNIT_FRAGMENT}
  ${CONTRACT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateUnits($input: UpdateUnitsInput!) {
    payload: updateUnits(input: $input) {
      data {
        tenantId
        projectId
        units {
          ...Unit
        }
        contracts {
          ...Contract
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateUnitsMutation = (
  onCompleted: (data: NonNullable<UpdateUnitsMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: updateUnitsErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertUnits, upsertContracts } = useProjectContext();
  const [updateUnitsMutation, { loading: updateUnitsLoading }] = useMutation<UpdateUnitsMutation, UpdateUnitsMutationVariables>(UPDATE_UNITS_MUTATION);
  const updateUnits = useCallback(
    async (input: UpdateUnitsInput) => {
      await updateUnitsMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, units, contracts } = data;
            upsertUnits(tenantId, projectId, units);
            upsertContracts(tenantId, projectId, contracts);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [updateUnitsMutation, onCompleted, onError, useNotification, setErrors, upsertUnits, upsertContracts, pushErrors]
  );
  return { updateUnits, updateUnitsLoading, updateUnitsErrors };
};

export const IMPORT_UNITS_MUTATION = gql`
  ${UNIT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation ImportUnits($input: ImportUnitsInput!) {
    payload: importUnits(input: $input) {
      data {
        tenantId
        projectId
        units {
          ...Unit
        }
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useImportUnitsMutation = (
  onCompleted: (data: NonNullable<ImportUnitsMutation["payload"]["data"]>) => void,
  onError: ((messages: ReadonlyArray<string>) => void) | undefined = undefined,
  useNotification: boolean = true
) => {
  const { errors: importUnitsErrors, setErrors } = useErrorList();
  const { pushErrors } = useNotificationContext();
  const { upsertUnits } = useProjectContext();
  const [importUnitsMutation, { loading: importUnitsLoading }] = useMutation<ImportUnitsMutation, ImportUnitsMutationVariables>(IMPORT_UNITS_MUTATION);
  const importUnits = useCallback(
    async (input: ImportUnitsInput) => {
      await importUnitsMutation({
        variables: {
          input,
        },
        onCompleted: ({ payload }) => {
          const { data, errors } = payload;
          if (data) {
            const { tenantId, projectId, units } = data;
            upsertUnits(tenantId, projectId, units);
            onCompleted(data);
          }
          if (errors) {
            const messages = errors.map((error) => error.message);
            if (onError) {
              onError(messages);
            }
            if (useNotification) {
              pushErrors(messages);
            } else {
              setErrors(messages);
            }
          }
        },
      });
    },
    [importUnitsMutation, onCompleted, onError, useNotification, setErrors, upsertUnits, pushErrors]
  );
  return { importUnits, importUnitsLoading, importUnitsErrors };
};
