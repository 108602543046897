import "./index.scss";
import { useUpdateContractInvoicePaymentMutation } from "graphql/mutations";
import { FunctionComponent } from "react";
import { ModalForm } from "buildingBlocks";
import { ContractInvoicePaymentForm, useContractInvoicePaymentForm } from "forms";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly contract: ProjectProviderTypes.MappedProjectContract;
  readonly payment: ProjectProviderTypes.MappedProjectPayment;
  readonly onCompleted: () => void;
  readonly onCancel: () => void;
};

export const ContractInvoicePaymentUpdateModal: FunctionComponent<Props> = ({ tenantId, projectId, contract, payment, onCompleted, onCancel }) => {
  const { values, setValues } = useContractInvoicePaymentForm({
    label: payment.label,
    date: new Date(payment.date),
    referenceNumber: payment.referenceNumber ?? "",
    mode: payment.mode,
    amount: payment.amount,
    notes: payment.notes ?? "",
  });
  const { updateContractInvoicePayment, updateContractInvoicePaymentLoading, updateContractInvoicePaymentErrors } = useUpdateContractInvoicePaymentMutation(onCompleted, undefined, false);
  const invoice = contract.invoices.find((invoice) => invoice.id === payment.invoiceId)!;
  return (
    <ModalForm
      id="contract-invoice-payment-update-modal"
      title="Modifier un paiement"
      errors={updateContractInvoicePaymentErrors}
      loading={updateContractInvoicePaymentLoading}
      disabled={values.amount <= 0}
      submitButton={{
        onClick: async () => {
          await updateContractInvoicePayment({
            tenantId: tenantId,
            projectId: projectId,
            contractId: contract.id,
            paymentId: payment.id,
            invoiceId: invoice.id,
            date: values.date,
            mode: values.mode,
            referenceNumber: values.referenceNumber.trim() || null,
            amount: values.amount,
            notes: values.notes.trim() || null,
          });
        },
      }}
      closeButton={{
        text: "Annuler",
        onClick: onCancel,
      }}
    >
      <ContractInvoicePaymentForm values={values} setValues={setValues} invoice={invoice} paymentId={payment.id} />
    </ModalForm>
  );
};
