import "./index.scss";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { SelectPicker, Toggle } from "rsuite";
import { ContainerLoader, ViewReport } from "buildingBlocks";
import { PermissionName, SummaryReportFragment } from "graphql/schema";
import { ReportSummary } from "components";
import { useProjectContext, useTenantContext } from "providers";
import { useGetReportSummaryLazyQuery } from "graphql/queries";
import { useProjectReportContext, useRouting } from "hooks";

export const ProjectReportSummaryView: FunctionComponent = () => {
  const { navigate, toProjectReportsView, toProjectReportSummaryView } = useRouting();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [summaryReport, setSummaryReport] = useState<SummaryReportFragment | null>(null);
  const [unitFloor, setUnitFloor] = useState<string | null>(null);
  const [activityId, setActivityId] = useState<string | null>(null);
  const [withDetails, setWithDetails] = useState<boolean>(true);
  const { tenant } = useTenantContext();
  const { project, activities, reports, units } = useProjectContext();
  const { report } = useProjectReportContext();
  const { getReportSummary, reportSummaryLoading } = useGetReportSummaryLazyQuery((report) => {
    setSummaryReport(report);
    setIsLoaded(true);
  });
  const execute = useCallback(async () => {
    await getReportSummary({
      variables: {
        tenantId: tenant.id,
        projectId: project.id,
        reportId: report.id,
        activityId: activityId,
        unitFloor: unitFloor !== null ? Number(unitFloor) : null,
      },
    });
  }, [tenant, project, report, activityId, unitFloor, getReportSummary]);
  useEffect(() => {
    execute();
  }, [execute]);
  if (!isLoaded || summaryReport === null || reportSummaryLoading) {
    return <ContainerLoader />;
  }
  return (
    <ViewReport
      id="project-report-summary-view"
      security={{
        permissions: [[PermissionName.REPORT_READ], [PermissionName.REPORT_SUMMARY_READ]],
      }}
      header={{
        subtitle: "Contrats",
        title: "Résumé",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectReportsView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Dropdown",
              appearance: "primary",
              size: "sm",
              title: "Exporter",
              trigger: "click",
              placement: "bottomEnd",
              security: {},
              show: true,
              items: [
                {
                  __type: "DropdownItem",
                  text: "Télécharger (PDF)",
                  disabled: false,
                  onClick: () => {},
                },
                {
                  __type: "DropdownDivider",
                },
                {
                  __type: "DropdownItem",
                  text: "Imprimer",
                  disabled: false,
                  onClick: () => {},
                },
              ],
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [
              <SelectPicker
                size="xs"
                label="Étage"
                value={unitFloor}
                onChange={setUnitFloor}
                data={units
                  .map((unit) => unit.floor)
                  .filter((value, index, array) => array.indexOf(value) === index)
                  .map((unitFloor) => ({
                    label: unitFloor.toString(),
                    value: unitFloor.toString(),
                  }))}
                searchable={false}
                cleanable
              />,
              <SelectPicker
                size="xs"
                label="Activité"
                value={activityId}
                onChange={setActivityId}
                data={activities.map((activity) => ({
                  label: activity.label,
                  value: activity.id,
                }))}
                searchable={false}
                cleanable
              />,
              <Toggle size="md" checked={withDetails} checkedChildren="Détaillé" unCheckedChildren="Simplifié" onChange={setWithDetails} />,
            ],
            applyButton: null,
          },
          right: {
            inputs: [
              <SelectPicker
                size="xs"
                label="Rapport"
                value={report.id}
                searchable={false}
                onChange={(value) => {
                  if (value !== report.id) {
                    navigate(
                      toProjectReportSummaryView({
                        tenantId: tenant.id,
                        projectId: project.id,
                        reportId: value!,
                      })
                    );
                  }
                }}
                cleanable={false}
                data={reports.map((report) => ({
                  label: report.name,
                  value: report.id,
                }))}
              />,
            ],
          },
        },
      }}
    >
      <ReportSummary report={summaryReport} withDetails={withDetails} />
    </ViewReport>
  );
};
