import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { DateTime, EnumLabel, getEnumLabel, ViewList } from "buildingBlocks";
import { useRouting } from "hooks";
import { CheckPicker, SelectPicker } from "rsuite";
import { useList } from "react-use";
import { useTenantContext, useProjectContext } from "providers";
import { ContractState, PermissionName, ContractSelectionState } from "graphql/schema";
import { ContractCancelDialog, ContractDeleteDialog } from "dialogs";
import { BalanceAmount } from "components";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

const useProjectContractsViewFilters = () => {
  const { searchParams } = useRouting();
  const [customerIds, { set: setCustomerIds }] = useList<string>(searchParams.get("customerIds")?.split(",") ?? []);
  const [projectUnitIds, { set: setProjectUnitIds }] = useList<string>(searchParams.get("projectUnitIds")?.split(",") ?? []);
  const [states, { set: setStates }] = useList<ContractState>([]);
  const [isCanceled, setIsCanceled] = useState<string | null>(null);
  return {
    filterValues: {
      customerIds,
      projectUnitIds,
      states,
      isCanceled,
    },
    filterFunctions: {
      setCustomerIds,
      setProjectUnitIds,
      setStates,
      setIsCanceled,
    },
    applyFilters: useCallback(
      (units: ReadonlyArray<ProjectProviderTypes.MappedProjectUnit>) =>
        units
          .filter((unit) => unit.contracts.length !== 0)
          .filter((unit) => unit.contracts.some((contract) => contract.unitId === unit.id))
          .filter((unit) => unit.contracts.some((contract) => (projectUnitIds.length !== 0 ? projectUnitIds.includes(contract.unitId) : true)))
          .filter((unit) => unit.contracts.some((contract) => (customerIds.length !== 0 ? contract.customers.some(({ customerId }) => customerIds.includes(customerId)) : true)))
          .filter((unit) => unit.contracts.some((contract) => (states.length !== 0 ? states.includes(contract.state) : true)))
          .filter((unit) => unit.contracts.some((contract) => (isCanceled !== null ? (contract.isCanceled ? "1" : "0") === isCanceled : true)))
          .map((unit) => ({
            ...unit,
            contracts: unit.contracts
              .filter((contract) => contract.unitId === unit.id)
              .filter((contract) => (projectUnitIds.length !== 0 ? projectUnitIds.includes(contract.unitId) : true))
              .filter((contract) => (customerIds.length !== 0 ? contract.customers.some(({ customerId }) => customerIds.includes(customerId)) : true))
              .filter((contract) => (states.length !== 0 ? states.includes(contract.state) : true))
              .filter((contract) => (isCanceled !== null ? (contract.isCanceled ? "1" : "0") === isCanceled : true)),
          })),
      [customerIds, projectUnitIds, states, isCanceled]
    ),
  };
};

export const ProjectContractsView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useProjectContractsViewFilters();
  const { navigate, toProjectView, toProjectContractSelectionsView, toProjectContractInvoicesView, toProjectContractCreateView } = useRouting();
  const { tenant, customers } = useTenantContext();
  const { project, contracts, units } = useProjectContext();
  const [contractToCancel, setContractToCancel] = useState<ProjectProviderTypes.MappedProjectContract | null>(null);
  const [contractToDelete, setContractToDelete] = useState<ProjectProviderTypes.MappedProjectContract | null>(null);
  return (
    <ViewList
      id="project-contracts-view"
      security={{
        permissions: [[PermissionName.CONTRACT_READ]],
      }}
      header={{
        title: "Tous les contrats",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              text: "Créer un contrat",
              show: contracts.length !== 0,
              security: {
                permissions: [[PermissionName.CONTRACT_CREATE]],
              },
              onClick: () => {
                navigate(
                  toProjectContractCreateView({
                    tenantId: tenant.id,
                    projectId: project.id,
                  })
                );
              },
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [
              <CheckPicker
                label="Unités"
                size="xs"
                value={filterValues.projectUnitIds}
                onChange={filterFunctions.setProjectUnitIds}
                data={units
                  .filter((unit) => contracts.some((contract) => contract.unitId === unit.id))
                  .map((unit) => ({
                    label: `#${unit.number}`,
                    value: unit.id,
                  }))}
              />,
              <CheckPicker
                label="Clients"
                size="xs"
                value={filterValues.customerIds}
                onChange={filterFunctions.setCustomerIds}
                data={customers.map((customer) => ({
                  label: customer.fullName,
                  value: customer.id,
                }))}
              />,
              <CheckPicker
                label="État"
                size="xs"
                cleanable
                searchable={false}
                value={filterValues.states}
                onChange={filterFunctions.setStates}
                data={[
                  { label: getEnumLabel(ContractState.IS_CUSTOMIZED), value: ContractState.IS_CUSTOMIZED },
                  { label: getEnumLabel(ContractState.WITHOUT_FORM), value: ContractState.WITHOUT_FORM },
                  { label: getEnumLabel(ContractState.SELECTION_PENDING), value: ContractState.SELECTION_PENDING },
                  { label: getEnumLabel(ContractState.REVISION_PENDING), value: ContractState.REVISION_PENDING },
                  { label: getEnumLabel(ContractState.PAYMENT_PENDING), value: ContractState.PAYMENT_PENDING },
                  { label: getEnumLabel(ContractState.REFUND_PENDING), value: ContractState.REFUND_PENDING },
                  { label: getEnumLabel(ContractState.COMPLETED), value: ContractState.COMPLETED },
                ]}
              />,
              <SelectPicker
                label="Statut"
                size="xs"
                cleanable
                searchable={false}
                value={filterValues.isCanceled}
                onChange={filterFunctions.setIsCanceled}
                data={[
                  { label: "Non annulé", value: "0" },
                  { label: "Annulé", value: "1" },
                ]}
              />,
            ],
            applyButton: null,
          },
          right: {
            inputs: [],
          },
        },
      }}
      content={{
        table: {
          emptyBodies: [
            {
              show: contracts.length === 0,
              title: "Aucun contrat",
              content: "Pour créer un contrat, cliquez sur le bouton ci-dessous.",
              button: {
                text: "Créer un contrat",
                security: {
                  permissions: [[PermissionName.CONTRACT_CREATE]],
                },
                onClick: () => {
                  navigate(
                    toProjectContractCreateView({
                      tenantId: tenant.id,
                      projectId: project.id,
                    })
                  );
                },
              },
            },
          ],
          headColumns: [
            {
              name: "date",
              value: "DATE",
            },
            {
              name: "state",
              value: "STATUT",
            },
            {
              name: "form",
              value: "FORMULAIRE",
            },
            {
              name: "selection",
              value: "SÉLECTION",
            },
            {
              name: "customers",
              value: "CLIENTS",
            },
            {
              name: "total",
              value: "TOTAL",
            },
            {
              name: "paid",
              value: "PAYÉ",
            },
            {
              name: "refunded",
              value: "REMBOURSÉ",
            },
            {
              name: "non-refundable",
              value: "NON REMBOURSABLE",
            },
            {
              name: "balance",
              value: "SOLDE",
            },
          ],
          bodyRows: [],
          bodyRowGroups: applyFilters(units).map((unit) => {
            return {
              key: unit.id,
              columns: [
                {
                  name: "date",
                  component: unit.label,
                },
              ],
              actions: [],
              rows: unit.contracts.map((contract) => {
                return {
                  key: contract.id,
                  href:
                    contract.state === ContractState.IS_CUSTOMIZED
                      ? toProjectContractInvoicesView({
                          tenantId: tenant.id,
                          projectId: project.id,
                          contractId: contract.id,
                        })
                      : toProjectContractSelectionsView({
                          tenantId: tenant.id,
                          projectId: project.id,
                          contractId: contract.id,
                        }),
                  columns: [
                    {
                      name: "date",
                      component: <DateTime value={contract.createdAt} />,
                    },
                    {
                      name: "state",
                      component: (
                        <EnumLabel
                          value={contract.state}
                          appearances={[
                            { value: ContractState.IS_CUSTOMIZED, appearance: "secondary" },
                            { value: ContractState.WITHOUT_FORM, appearance: "primary" },
                            { value: ContractState.WITHOUT_SELECTION, appearance: "primary" },
                            { value: ContractState.SELECTION_PENDING, appearance: "primary" },
                            { value: ContractState.REVISION_PENDING, appearance: "primary" },
                            { value: ContractState.PAYMENT_PENDING, appearance: "warning" },
                            { value: ContractState.REFUND_PENDING, appearance: "warning" },
                            { value: ContractState.COMPLETED, appearance: "secondary" },
                          ]}
                          uppercase
                          bold
                          block
                        />
                      ),
                    },
                    {
                      name: "form",
                      component: contract.form?.name ?? "--",
                    },
                    {
                      name: "selection",
                      component: !contract.isCanceled ? (
                        contract.currentSelection ? (
                          contract.currentSelection.label
                        ) : (
                          "--"
                        )
                      ) : (
                        <EnumLabel value="Annulé" appearances={[{ value: "Annulé", appearance: "danger" }]} uppercase block bold width={200} />
                      ),
                    },
                    {
                      name: "customers",
                      component: (
                        <div className="contract-customer-list">
                          {contract.customers.map(({ customer }) => (
                            <span key={customer.id}>{customer.fullName}</span>
                          ))}
                        </div>
                      ),
                    },
                    {
                      name: "total",
                      component: contract.state !== ContractState.IS_CUSTOMIZED ? contract.totalAmount.toCurrencyFormat() : Number(0).toCurrencyFormat(),
                    },
                    {
                      name: "paid",
                      component: contract.state !== ContractState.IS_CUSTOMIZED ? contract.paidAmount.toCurrencyFormat() : Number(0).toCurrencyFormat(),
                    },
                    {
                      name: "refunded",
                      component: contract.state !== ContractState.IS_CUSTOMIZED ? contract.refundedAmount.toCurrencyFormat() : Number(0).toCurrencyFormat(),
                    },
                    {
                      name: "non-refundable",
                      component: contract.state !== ContractState.IS_CUSTOMIZED ? contract.nonRefundableAmount.toCurrencyFormat() : Number(0).toCurrencyFormat(),
                    },
                    {
                      name: "balance",
                      component: contract.state !== ContractState.IS_CUSTOMIZED ? <BalanceAmount value={contract.balanceAmount} /> : Number(0).toCurrencyFormat(),
                    },
                  ],
                  actions: [
                    {
                      __type: "Button",
                      content: "Annuler le contrat",
                      show: !contract.isCanceled,
                      security: {
                        permissions: [[PermissionName.CONTRACT_CANCEL]],
                      },
                      onClick: () => {
                        setContractToCancel(contract);
                      },
                    },
                    {
                      __type: "Button",
                      content: "Supprimer le contrat",
                      show: !contract.isCanceled && (contract.selections.length === 0 || contract.selections.every((contractSelection) => contractSelection.state === ContractSelectionState.PENDING)),
                      security: {
                        permissions: [[PermissionName.CONTRACT_DELETE]],
                      },
                      onClick: () => {
                        setContractToDelete(contract);
                      },
                    },
                  ],
                };
              }),
            };
          }),
        },
      }}
    >
      {contractToDelete && (
        <ContractDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          contract={contractToDelete}
          onCompleted={() => {
            setContractToDelete(null);
          }}
          onClose={() => {
            setContractToDelete(null);
          }}
        />
      )}
      {contractToCancel && (
        <ContractCancelDialog
          tenantId={tenant.id}
          projectId={project.id}
          contract={contractToCancel}
          onCompleted={() => {
            setContractToCancel(null);
          }}
          onClose={() => {
            setContractToCancel(null);
          }}
        />
      )}
    </ViewList>
  );
};
