import "./index.scss";
import { useUpdateContractInvoiceRefundMutation } from "graphql/mutations";
import { FunctionComponent } from "react";
import { ModalForm } from "buildingBlocks";
import { ContractInvoiceRefundForm, useContractInvoiceRefundForm } from "forms";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly contract: ProjectProviderTypes.MappedProjectContract;
  readonly refund: ProjectProviderTypes.MappedProjectRefund;
  readonly onCompleted: () => void;
  readonly onCancel: () => void;
};

export const ContractInvoiceRefundUpdateModal: FunctionComponent<Props> = ({ tenantId, projectId, contract, refund, onCompleted, onCancel }) => {
  const { values, setValues } = useContractInvoiceRefundForm({
    label: refund.label,
    date: new Date(refund.date),
    referenceNumber: refund.referenceNumber ?? "",
    mode: refund.mode,
    amount: refund.amount,
    notes: refund.notes ?? "",
  });
  const { updateContractInvoiceRefund, updateContractInvoiceRefundLoading, updateContractInvoiceRefundErrors } = useUpdateContractInvoiceRefundMutation(onCompleted, undefined, false);
  const invoice = contract.invoices.find((invoice) => invoice.id === refund.invoiceId)!;
  return (
    <ModalForm
      id="contract-invoice-refund-update-modal"
      title="Modifier un remboursement"
      errors={updateContractInvoiceRefundErrors}
      loading={updateContractInvoiceRefundLoading}
      disabled={values.amount <= 0}
      submitButton={{
        onClick: async () => {
          await updateContractInvoiceRefund({
            tenantId: tenantId,
            projectId: projectId,
            contractId: contract.id,
            refundId: refund.id,
            invoiceId: invoice.id,
            date: values.date,
            mode: values.mode,
            referenceNumber: values.referenceNumber.trim() || null,
            amount: values.amount,
            notes: values.notes.trim() || null,
          });
        },
      }}
      closeButton={{
        text: "Annuler",
        onClick: onCancel,
      }}
    >
      <ContractInvoiceRefundForm values={values} setValues={setValues} invoice={invoice} refundId={refund.id} />
    </ModalForm>
  );
};
