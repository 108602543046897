import { ViewForm } from "buildingBlocks";
import { UserForm, useUserForm } from "forms";
import { useCreateUserMutation } from "graphql/mutations";
import { useRouting } from "hooks";
import { useTenantContext } from "providers";
import { FunctionComponent } from "react";

export const TenantSettingsUserCreateView: FunctionComponent = () => {
  const { navigate, toTenantSettingsUsersView } = useRouting();
  const { tenant, roles, projects } = useTenantContext();
  const { values, setValues } = useUserForm({
    email: "",
    firstName: "",
    lastName: "",
    hasAccessToAllProjects: false,
    hasAccessToTestTenant: false,
    roleId: null,
    projectIds: [],
  });
  const { createUser, createUserLoading, createUserErrors } = useCreateUserMutation(({ tenantId }) => {
    navigate(
      toTenantSettingsUsersView({
        tenantId: tenantId,
      })
    );
  });
  return (
    <ViewForm
      id="tenant-settings-user-update-view"
      title="Créer un utilisateur"
      security={{
        isTenantSecurityManager: true,
      }}
      errors={createUserErrors}
      loading={createUserLoading}
      disabled={values.roleId === null}
      onSubmit={async () => {
        await createUser({
          tenantId: tenant.id,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          hasAccessToAllProjects: values.hasAccessToAllProjects,
          hasAccessToTestTenant: values.hasAccessToTestTenant,
          roleId: values.roleId!,
          projectIds: values.projectIds,
        });
      }}
      onCancel={() => {
        navigate(
          toTenantSettingsUsersView({
            tenantId: tenant.id,
          })
        );
      }}
    >
      <UserForm values={values} setValues={setValues} roles={roles} projects={projects} />
    </ViewForm>
  );
};
