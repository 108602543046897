import "./index.scss";
import { FunctionComponent } from "react";
import { Card, DictionaryList, getEnumLabel } from "buildingBlocks";
import { BalanceAmount } from "components";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly contract: ProjectProviderTypes.MappedProjectContract;
};

export const ContractCard: FunctionComponent<Props> = ({ contract }) => (
  <Card className="contract-card" title="Contrat">
    <DictionaryList
      inline
      items={[
        {
          title: "Formulaire",
          description: contract.form?.name ?? "Aucun",
        },
        {
          title: "État",
          description: <strong>{getEnumLabel(contract.state).toUpperCase()}</strong>,
        },
        {
          title: "Montant vente",
          description: contract.totalAmount.toCurrencyFormat(),
        },
        {
          title: "Solde client",
          description: <BalanceAmount value={contract.balanceAmount} />,
        },
      ]}
    />
  </Card>
);
