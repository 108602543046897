import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  CUSTOMER_FRAGMENT,
  PROVIDER_FRAGMENT,
  PROJECT_FRAGMENT,
  COUNTRY_FRAGMENT,
  PERMISSION_FRAGMENT,
  ACTIVITY_FRAGMENT,
  CONTRACT_FRAGMENT,
  FORM_FRAGMENT,
  MODEL_FRAGMENT,
  PRODUCT_CATEGORY_FRAGMENT,
  PRODUCT_FRAGMENT,
  REPORT_FRAGMENT,
  SECTION_FRAGMENT,
  UNIT_FRAGMENT,
  USER_INFO_FRAGMENT,
  SUMNMARY_REPORT_FRAGMENT,
  BILLING_REPORT_FRAGMENT,
  CONSTRUCTION_REPORT_FRAGMENT,
  CUSTOMER_REPORT_FRAGMENT,
  REFUND_FRAGMENT,
  PAYMENT_FRAGMENT,
  INVOICE_FRAGMENT,
  TENANT_FRAGMENT,
  USER_FRAGMENT,
  ROLE_FRAGMENT,
  TENANT_PROFILE_FRAGMENT,
  TAX_FRAGMENT,
  TAX_GROUP_FRAGMENT,
  TAX_AGENCY_FRAGMENT,
} from "./fragments";
import {
  ActivityFragment,
  BillingReportFragment,
  ConstructionReportFragment,
  ContractFragment,
  CountryFragment,
  CustomerFragment,
  CustomerReportFragment,
  FetchCountriesQuery,
  FetchCountriesQueryVariables,
  FetchPermissionsQuery,
  FetchPermissionsQueryVariables,
  FetchTenantsQuery,
  FetchTenantsQueryVariables,
  FindProjectQuery,
  FindProjectQueryVariables,
  FindTenantQuery,
  FindTenantQueryVariables,
  FormFragment,
  GetReportBillingQuery,
  GetReportBillingQueryVariables,
  GetReportConstructionQuery,
  GetReportConstructionQueryVariables,
  GetReportCustomerQuery,
  GetReportCustomerQueryVariables,
  GetReportSummaryQuery,
  GetReportSummaryQueryVariables,
  GetUserInfoQuery,
  GetUserInfoQueryVariables,
  InvoiceFragment,
  ModelFragment,
  PaymentFragment,
  PermissionFragment,
  ProductCategoryFragment,
  ProductFragment,
  ProjectFragment,
  ProviderFragment,
  RefundFragment,
  ReportFragment,
  RoleFragment,
  SectionFragment,
  SummaryReportFragment,
  TaxAgencyFragment,
  TaxFragment,
  TaxGroupFragment,
  TenantFragment,
  TenantProfileFragment,
  UnitFragment,
  UserFragment,
  UserInfoFragment,
} from "./schema";

// Country
const FECTH_COUNTRIES_QUERY = gql`
  ${COUNTRY_FRAGMENT}
  query FetchCountries {
    countries: countries {
      ...Country
    }
  }
`;

export const useFetchCountriesQuery = (onCompleted: (countries: ReadonlyArray<CountryFragment>) => void) => {
  return useQuery<FetchCountriesQuery, FetchCountriesQueryVariables>(FECTH_COUNTRIES_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      onCompleted(data.countries);
    },
  });
};

// Permission
const FETCH_PERMISSIONS_QUERY = gql`
  ${PERMISSION_FRAGMENT}
  query FetchPermissions {
    permissions: permissions {
      ...Permission
    }
  }
`;

export const useFetchPermissionsQuery = (onCompleted: (permissions: ReadonlyArray<PermissionFragment>) => void) => {
  return useQuery<FetchPermissionsQuery, FetchPermissionsQueryVariables>(FETCH_PERMISSIONS_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      onCompleted(data.permissions);
    },
  });
};

// Tenant
const FECTH_TENANTS_QUERY = gql`
  ${TENANT_FRAGMENT}
  query FetchTenants {
    tenants: tenants {
      ...Tenant
    }
  }
`;

export const useFetchTenantsQuery = (onCompleted: (tenants: ReadonlyArray<TenantFragment>) => void) => {
  return useQuery<FetchTenantsQuery, FetchTenantsQueryVariables>(FECTH_TENANTS_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      onCompleted(data.tenants);
    },
  });
};

const FIND_TENANT_QUERY = gql`
  ${TENANT_FRAGMENT}
  ${PROJECT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${PROVIDER_FRAGMENT}
  ${TENANT_PROFILE_FRAGMENT}
  ${TAX_FRAGMENT}
  ${TAX_GROUP_FRAGMENT}
  ${TAX_AGENCY_FRAGMENT}
  ${USER_FRAGMENT}
  ${ROLE_FRAGMENT}
  query FindTenant($tenantId: ID!) {
    tenant: tenant(tenantId: $tenantId) {
      ...Tenant
      profile {
        ...TenantProfile
      }
      taxes {
        ...Tax
      }
      taxGroups {
        ...TaxGroup
      }
      taxAgencies {
        ...TaxAgency
      }
      roles {
        ...Role
      }
      users {
        ...User
      }
      customers {
        ...Customer
      }
      providers {
        ...Provider
      }
      projects {
        ...Project
      }
    }
  }
`;

export const useFindTenantLazyQuery = (
  onCompleted: (
    tenant: TenantFragment,
    profile: TenantProfileFragment,
    taxes: ReadonlyArray<TaxFragment>,
    taxGroups: ReadonlyArray<TaxGroupFragment>,
    taxAgencies: ReadonlyArray<TaxAgencyFragment>,
    roles: ReadonlyArray<RoleFragment>,
    users: ReadonlyArray<UserFragment>,
    projects: ReadonlyArray<ProjectFragment>,
    customers: ReadonlyArray<CustomerFragment>,
    providers: ReadonlyArray<ProviderFragment>
  ) => void
) => {
  return useLazyQuery<FindTenantQuery, FindTenantQueryVariables>(FIND_TENANT_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      const tenant = data.tenant;
      onCompleted(
        {
          __typename: "Tenant",
          id: tenant.id,
          type: tenant.type,
          status: tenant.status,
          name: tenant.name,
        },
        {
          __typename: "TenantProfile",
          companyName: tenant.profile.companyName,
          email: tenant.profile.email,
          phone: tenant.profile.phone,
          language: tenant.profile.language,
          currencyCode: tenant.profile.currencyCode,
          websiteUrl: tenant.profile.websiteUrl,
          address: {
            __typename: "Address",
            line: tenant.profile.address.line,
            city: tenant.profile.address.city,
            countryCode: tenant.profile.address.countryCode,
            subdivisionCode: tenant.profile.address.subdivisionCode,
            postalCode: tenant.profile.address.postalCode,
          },
        },
        tenant.taxes,
        tenant.taxGroups,
        tenant.taxAgencies,
        tenant.roles,
        tenant.users,
        tenant.projects,
        tenant.customers,
        tenant.providers
      );
    },
  });
};

// Project
const FIND_PROJECT_QUERY = gql`
  ${PROJECT_FRAGMENT}
  ${REPORT_FRAGMENT}
  ${MODEL_FRAGMENT}
  ${ACTIVITY_FRAGMENT}
  ${CONTRACT_FRAGMENT}
  ${FORM_FRAGMENT}
  ${INVOICE_FRAGMENT}
  ${SECTION_FRAGMENT}
  ${PRODUCT_FRAGMENT}
  ${PRODUCT_CATEGORY_FRAGMENT}
  ${REFUND_FRAGMENT}
  ${PAYMENT_FRAGMENT}
  ${UNIT_FRAGMENT}
  query FindProject($tenantId: ID!, $projectId: ID!) {
    project: project(tenantId: $tenantId, projectId: $projectId) {
      ...Project
      activities {
        ...Activity
      }
      contracts {
        ...Contract
      }
      forms {
        ...Form
      }
      invoices {
        ...Invoice
      }
      models {
        ...Model
      }
      products {
        ...Product
      }
      productCategories {
        ...ProductCategory
      }
      payments {
        ...Payment
      }
      reports {
        ...Report
      }
      refunds {
        ...Refund
      }
      sections {
        ...Section
      }
      units {
        ...Unit
      }
    }
  }
`;

export const useFindProjectLazyQuery = (
  onCompleted: (
    project: ProjectFragment,
    activities: ReadonlyArray<ActivityFragment>,
    contracts: ReadonlyArray<ContractFragment>,
    forms: ReadonlyArray<FormFragment>,
    invoices: ReadonlyArray<InvoiceFragment>,
    models: ReadonlyArray<ModelFragment>,
    productCategories: ReadonlyArray<ProductCategoryFragment>,
    products: ReadonlyArray<ProductFragment>,
    payments: ReadonlyArray<PaymentFragment>,
    refunds: ReadonlyArray<RefundFragment>,
    reports: ReadonlyArray<ReportFragment>,
    sections: ReadonlyArray<SectionFragment>,
    units: ReadonlyArray<UnitFragment>
  ) => void
) => {
  return useLazyQuery<FindProjectQuery, FindProjectQueryVariables>(FIND_PROJECT_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      const project = data.project;
      onCompleted(
        {
          __typename: "Project",
          id: project.id,
          createdAt: project.createdAt,
          updatedAt: project.updatedAt,
          code: project.code,
          name: project.name,
          label: project.label,
          description: project.description,
          contactInfo: project.contactInfo,
          tenantId: project.tenantId,
        },
        project.activities,
        project.contracts,
        project.forms,
        project.invoices,
        project.models,
        project.productCategories,
        project.products,
        project.payments,
        project.refunds,
        project.reports,
        project.sections,
        project.units
      );
    },
  });
};

// Report
const GET_REPORT_SUMMARY_QUERY = gql`
  ${SUMNMARY_REPORT_FRAGMENT}
  query GetReportSummary($tenantId: ID!, $projectId: ID!, $reportId: ID!, $unitFloor: Int, $activityId: ID) {
    report: reportSummary(tenantId: $tenantId, projectId: $projectId, reportId: $reportId, unitFloor: $unitFloor, activityId: $activityId) {
      ...SummaryReport
    }
  }
`;

export const useGetReportSummaryLazyQuery = (onCompleted: (report: SummaryReportFragment) => void) => {
  const [getReportSummary, { loading: reportSummaryLoading }] = useLazyQuery<GetReportSummaryQuery, GetReportSummaryQueryVariables>(GET_REPORT_SUMMARY_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      onCompleted(data.report);
    },
  });
  return { getReportSummary, reportSummaryLoading };
};

const GET_REPORT_BILLING_QUERY = gql`
  ${BILLING_REPORT_FRAGMENT}
  query GetReportBilling($tenantId: ID!, $projectId: ID!, $unitIds: [ID!]!) {
    report: reportBilling(tenantId: $tenantId, projectId: $projectId, unitIds: $unitIds) {
      ...BillingReport
    }
  }
`;

export const useGetReportBillingLazyQuery = (onCompleted: (report: BillingReportFragment) => void) => {
  const [getReportBilling, { loading: reportBillingLoading }] = useLazyQuery<GetReportBillingQuery, GetReportBillingQueryVariables>(GET_REPORT_BILLING_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      onCompleted(data.report);
    },
  });
  return { getReportBilling, reportBillingLoading };
};

const GET_REPORT_CONSTRUCTION_QUERY = gql`
  ${CONSTRUCTION_REPORT_FRAGMENT}
  query GetReportConstruction($tenantId: ID!, $projectId: ID!, $reportId: ID!, $unitId: ID!) {
    report: reportConstruction(tenantId: $tenantId, projectId: $projectId, reportId: $reportId, unitId: $unitId) {
      ...ConstructionReport
    }
  }
`;

export const useGetReportConstructionLazyQuery = (onCompleted: (report: ConstructionReportFragment) => void) => {
  const [getReportConstruction, { loading: reportConstructionLoading }] = useLazyQuery<GetReportConstructionQuery, GetReportConstructionQueryVariables>(GET_REPORT_CONSTRUCTION_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      onCompleted(data.report);
    },
  });
  return { getReportConstruction, reportConstructionLoading };
};

const GET_REPORT_CUSTOMER_QUERY = gql`
  ${CUSTOMER_REPORT_FRAGMENT}
  query GetReportCustomer($tenantId: ID!, $projectId: ID!, $reportId: ID!, $unitId: ID!) {
    report: reportCustomer(tenantId: $tenantId, projectId: $projectId, reportId: $reportId, unitId: $unitId) {
      ...CustomerReport
    }
  }
`;

export const useGetReportCustomerLazyQuery = (onCompleted: (report: CustomerReportFragment) => void) => {
  const [getReportCustomer, { loading: reportCustomerLoading }] = useLazyQuery<GetReportCustomerQuery, GetReportCustomerQueryVariables>(GET_REPORT_CUSTOMER_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      onCompleted(data.report);
    },
  });
  return { getReportCustomer, reportCustomerLoading };
};

// User
const GET_USER_INFO_QUERY = gql`
  ${USER_INFO_FRAGMENT}
  query GetUserInfo {
    userInfo: userInfo {
      ...UserInfo
    }
  }
`;

export const useGetUserInfoQuery = (onCompleted: (userInfo: UserInfoFragment) => void, onError: () => void) => {
  return useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GET_USER_INFO_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      onCompleted(data.userInfo);
    },
    onError: onError,
  });
};
