import "./index.scss";
import { FunctionComponent, useState } from "react";
import { Dialog, DialogBodyDelete, DialogFooterDelete } from "buildingBlocks";
import { useDeleteSectionMutation } from "graphql/mutations";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly section: ProjectProviderTypes.MappedProjectSection;
  readonly onCompleted: () => void;
  readonly onClose: () => void;
};

export const SectionDeleteDialog: FunctionComponent<Props> = ({ tenantId, projectId, section, onCompleted, onClose }) => {
  const [value, setValue] = useState<string>("");
  const { deleteSection, deleteSectionLoading } = useDeleteSectionMutation(onCompleted, onClose, true);
  return (
    <Dialog id="section-delete-dialog" title="Supprimer la section">
      <DialogBodyDelete objectName={section.title} dictionaryList={[]} value={value} setValue={setValue} />
      <DialogFooterDelete
        disabled={section.title !== value}
        loading={deleteSectionLoading}
        onClose={onClose}
        onClick={async () => {
          await deleteSection({
            tenantId: tenantId,
            projectId: projectId,
            sectionId: section.id,
          });
        }}
      />
    </Dialog>
  );
};
