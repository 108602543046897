import "./index.scss";
import { useUpdateContractRevisionMutation } from "graphql/mutations";
import { FunctionComponent } from "react";
import { ModalForm } from "buildingBlocks";
import { ContractRevisionUpdateForm, useContractRevisionUpdateForm } from "forms";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";
import { MappedTaxGroup } from "providers";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly contract: ProjectProviderTypes.MappedProjectContract;
  readonly contractSelection: ProjectProviderTypes.MappedProjectContractSelection;
  readonly taxGroups: ReadonlyArray<MappedTaxGroup>;
  readonly onCompleted: () => void;
  readonly onCancel: () => void;
};

export const ContractRevisionUpdateModal: FunctionComponent<Props> = ({ tenantId, projectId, contract, contractSelection, taxGroups, onCompleted, onCancel }) => {
  const { values, setValues } = useContractRevisionUpdateForm({
    label: contractSelection.label,
    reason: contractSelection.revisionReason ?? "",
    terms: contractSelection.billingSettings.terms,
    creditAmount: contractSelection.billingSettings.creditAmount,
    revisionAmount: contractSelection.billingSettings.revisionAmount,
    taxGroupId: taxGroups[0].id,
  });
  const { updateContractRevision, updateContractRevisionErrors, updateContractRevisionLoading } = useUpdateContractRevisionMutation(onCompleted, undefined, false);
  return (
    <ModalForm
      id="contract-revision-update-modal"
      title="Modifier la révision"
      errors={updateContractRevisionErrors}
      loading={updateContractRevisionLoading}
      disabled={false}
      submitButton={{
        onClick: async () => {
          await updateContractRevision({
            tenantId: tenantId,
            projectId: projectId,
            contractId: contract.id,
            selectionId: contractSelection.id,
            taxGroupId: values.taxGroupId,
            reason: values.reason.trim() || null,
            terms: values.terms,
            creditAmount: values.creditAmount,
            revisionAmount: values.revisionAmount,
          });
        },
      }}
      closeButton={{
        text: "Annuler",
        onClick: onCancel,
      }}
    >
      <ContractRevisionUpdateForm values={values} setValues={setValues} taxGroups={taxGroups} />
    </ModalForm>
  );
};
