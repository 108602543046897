import "./index.scss";
import { useDeleteContractMutation } from "graphql/mutations";
import { FunctionComponent, useState } from "react";
import { Dialog, DialogBodyDelete, DialogFooterDelete } from "buildingBlocks";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly contract: ProjectProviderTypes.MappedProjectContract;
  readonly onCompleted: () => void;
  readonly onClose: () => void;
};

export const ContractDeleteDialog: FunctionComponent<Props> = ({ tenantId, projectId, contract, onCompleted, onClose }) => {
  const [value, setValue] = useState<string>("");
  const { deleteContract, deleteContractLoading } = useDeleteContractMutation(onCompleted, onClose, true);
  return (
    <Dialog id="contract-delete-dialog" title="Supprimer le contrat">
      <DialogBodyDelete objectName={contract.unit.label} dictionaryList={[]} value={value} setValue={setValue} />
      <DialogFooterDelete
        disabled={contract.unit.label !== value}
        loading={deleteContractLoading}
        onClose={onClose}
        onClick={async () => {
          await deleteContract({
            tenantId: tenantId,
            projectId: projectId,
            contractId: contract.id,
          });
        }}
      />
    </Dialog>
  );
};
