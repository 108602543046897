import "./index.scss";
import { FunctionComponent } from "react";

export enum DateTimeFormat {
  DATE_AND_TIME,
  DATE_ONLY,
  TIME_ONLY,
}

type Props = {
  readonly value: any;
  readonly format?: DateTimeFormat | undefined;
};

export const DateTime: FunctionComponent<Props> = ({ value, format = DateTimeFormat.DATE_ONLY }) => {
  switch (format) {
    case DateTimeFormat.DATE_AND_TIME: {
      return <span className="datetime">{new Date(value).toLocaleString("fr-CA")}</span>;
    }
    case DateTimeFormat.DATE_ONLY: {
      return <span className="datetime">{new Date(value).toLocaleDateString("fr-CA")}</span>;
    }
    case DateTimeFormat.TIME_ONLY: {
      return <span className="datetime">{new Date(value).toLocaleTimeString("fr-CA")}</span>;
    }
    default: {
      throw new Error("Invalid DateTimeFormat.");
    }
  }
};
