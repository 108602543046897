import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { SearchInputGroup, ViewList, Text } from "buildingBlocks";
import { useRouting } from "hooks";
import { MappedTaxGroup, useTenantContext } from "providers";

const useTenantSettingsTaxGroupsViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback((taxGroups: ReadonlyArray<MappedTaxGroup>) => taxGroups.filter((taxGroup) => taxGroup.name.toSearchFormat().includes(search.toSearchFormat())), [search]),
  };
};

export const TenantSettingsTaxGroupsView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useTenantSettingsTaxGroupsViewFilters();
  const { navigate, toTenantView, toTenantSettingsView } = useRouting();
  const { tenant, taxGroups } = useTenantContext();
  return (
    <ViewList
      id="tenant-settings-tax-groups-view"
      security={{
        isTenantAccountingManager: true,
      }}
      header={{
        title: "Tous les groupes de taxes",
        returnButton: {
          onClick: () => {
            navigate(
              toTenantSettingsView({
                tenantId: tenant.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              appearance: "default",
              text: "Fermer les paramètres",
              show: true,
              security: {},
              onClick: () => {
                navigate(
                  toTenantView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [<SearchInputGroup size="xs" placeholder="Recherche par nom" value={filterValues.search} onChange={filterFunctions.setSearch} />],
            applyButton: null,
          },
          right: {
            inputs: [],
          },
        },
      }}
      content={{
        table: {
          headColumns: [
            {
              name: "name",
              value: "NOM",
            },
            {
              name: "taxes",
              value: "TAXE(S)",
            },
            {
              name: "rate",
              value: "TAUX (%)",
            },
          ],
          bodyRows: applyFilters(taxGroups).map((taxGroup) => {
            return {
              key: taxGroup.id,
              columns: [
                {
                  name: "name",
                  component: <Text highlight>{taxGroup.name}</Text>,
                },
                {
                  name: "taxes",
                  component: taxGroup.taxes.map((tax) => tax.name).join(", "),
                },
                {
                  name: "rate",
                  component: taxGroup.taxes.reduce((previous, current) => previous + current.rate, 0),
                },
              ],
              actions: [],
            };
          }),
          bodyRowGroups: [],
        },
      }}
    />
  );
};
