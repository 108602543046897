import "./index.scss";
import { FunctionComponent, useState } from "react";
import { CheckPicker, Form, Input, SelectPicker, Toggle } from "rsuite";
import produce from "immer";
import { FormContainer } from "buildingBlocks";
import { MappedRole } from "providers";
import { ProjectFragment } from "graphql/schema";

type UserFormValues = {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly hasAccessToTestTenant: boolean;
  readonly hasAccessToAllProjects: boolean;
  readonly roleId: string | null;
  readonly projectIds: string[];
};

type Props = {
  readonly values: UserFormValues;
  readonly setValues: (values: UserFormValues) => void;
  readonly roles: ReadonlyArray<MappedRole>;
  readonly projects: ReadonlyArray<ProjectFragment>;
  readonly readonlyEmail?: boolean;
};

export const useUserForm = (initialValues: UserFormValues) => {
  const [values, setValues] = useState<UserFormValues>(initialValues);
  return { values, setValues };
};

export const UserForm: FunctionComponent<Props> = ({ values, setValues, roles, projects, readonlyEmail = false }) => (
  <Form id="user-form">
    <FormContainer highlight>
      <Form.Group>
        <Form.ControlLabel>Courriel</Form.ControlLabel>
        <Input
          readOnly={readonlyEmail}
          value={values.email}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.email = value;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Prénom</Form.ControlLabel>
        <Input
          value={values.firstName}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.firstName = value;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Nom</Form.ControlLabel>
        <Input
          value={values.lastName}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.lastName = value;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Rôle</Form.ControlLabel>
        <SelectPicker
          value={values.roleId}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.roleId = value;
              })
            );
          }}
          cleanable={false}
          data={roles.map((role) => ({
            label: role.name,
            value: role.id,
          }))}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Accès à tous les projets</Form.ControlLabel>
        <Toggle
          checked={values.hasAccessToAllProjects}
          onChange={(checked) => {
            setValues(
              produce(values, (draft) => {
                draft.hasAccessToAllProjects = checked;
              })
            );
          }}
        />
      </Form.Group>
    </FormContainer>
    <FormContainer>
      <Form.Group>
        <Form.ControlLabel>Projets</Form.ControlLabel>
        <CheckPicker
          disabled={values.hasAccessToAllProjects}
          value={[...values.projectIds]}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.projectIds = value;
              })
            );
          }}
          cleanable
          data={projects.map((project) => ({
            label: project.label,
            value: project.id,
          }))}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Accès à l'organisation de test</Form.ControlLabel>
        <Toggle
          checked={values.hasAccessToTestTenant}
          onChange={(checked) => {
            setValues(
              produce(values, (draft) => {
                draft.hasAccessToTestTenant = checked;
              })
            );
          }}
        />
      </Form.Group>
    </FormContainer>
  </Form>
);
