import "./index.scss";
import { FunctionComponent } from "react";
import { DateTime, Document, DocumentTitle, Report, Table, TableBody, TableColumn, TableHead, TableRow } from "buildingBlocks";
import { ConstructionReportFragment, CustomerReportFragment, ReportCellType, UnitState } from "graphql/schema";

type Props = {
  readonly report: ConstructionReportFragment | CustomerReportFragment;
};

export const ReportUnit: FunctionComponent<Props> = ({ report }) => {
  const customer0 = report.customerInfos.find((customerInfo) => customerInfo.index === 0);
  const customer1 = report.customerInfos.find((customerInfo) => customerInfo.index === 1);
  const contractSelectionInfo0 = report.contractSelectionInfos.find((contractSelectionInfo) => contractSelectionInfo.revisionNumber === 0);
  const contractSelectionInfo1 = report.contractSelectionInfos.find((contractSelectionInfo) => contractSelectionInfo.revisionNumber === 1);
  const contractSelectionInfo2 = report.contractSelectionInfos.find((contractSelectionInfo) => contractSelectionInfo.revisionNumber === 2);
  const contractSelectionInfo3 = report.contractSelectionInfos.find((contractSelectionInfo) => contractSelectionInfo.revisionNumber === 3);
  if (!(report.unitState === UnitState.CONTRACT_COMPLETED || report.unitState === UnitState.CONTRACT_PAYMENT_PENDING || report.unitState === UnitState.CONTRACT_REFUND_PENDING)) {
    return null;
  }
  return (
    <Report id="report-unit">
      <Document>
        <DocumentTitle subtitle="Contrats" value={report.__typename === "ConstructionReport" ? "Fiche de construction" : "Fiche du client"} />
        <Table border className="table-overview" size="sm">
          <TableHead>
            <TableRow>
              <TableColumn name="label">Information sur l'unité</TableColumn>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableColumn name="label">Unité</TableColumn>
              <TableColumn name="value">{report.unitNumber}</TableColumn>
              {report.__typename === "CustomerReport" && <TableColumn name="label">Première rencontre</TableColumn>}
              {report.__typename === "CustomerReport" && (
                <TableColumn name="value" disabled={contractSelectionInfo0 === undefined}>
                  {contractSelectionInfo0 ? <DateTime value={contractSelectionInfo0.date} /> : ""}
                </TableColumn>
              )}
            </TableRow>
            <TableRow>
              <TableColumn name="label">Étage</TableColumn>
              <TableColumn name="value">{report.unitFloor}</TableColumn>
              {report.__typename === "CustomerReport" && <TableColumn name="label">Révision 1</TableColumn>}
              {report.__typename === "CustomerReport" && (
                <TableColumn name="value" disabled={contractSelectionInfo1 === undefined}>
                  {contractSelectionInfo1 ? <DateTime value={contractSelectionInfo1.date} /> : ""}
                </TableColumn>
              )}
            </TableRow>
            <TableRow>
              <TableColumn name="label">Nb de SDB</TableColumn>
              <TableColumn name="value">{report.unitNumberOfBathrooms}</TableColumn>
              {report.__typename === "CustomerReport" && <TableColumn name="label">Révision 2</TableColumn>}
              {report.__typename === "CustomerReport" && (
                <TableColumn name="value" disabled={contractSelectionInfo2 === undefined}>
                  {contractSelectionInfo2 ? <DateTime value={contractSelectionInfo2.date} /> : ""}
                </TableColumn>
              )}
            </TableRow>
            <TableRow>
              <TableColumn name="label">Nb de CAC</TableColumn>
              <TableColumn name="value">{report.unitNumberOfBedrooms}</TableColumn>
              {report.__typename === "CustomerReport" && <TableColumn name="label">Révision 3</TableColumn>}
              {report.__typename === "CustomerReport" && (
                <TableColumn name="value" disabled={contractSelectionInfo3 === undefined}>
                  {contractSelectionInfo3 ? <DateTime value={contractSelectionInfo3.date} /> : ""}
                </TableColumn>
              )}
            </TableRow>
          </TableBody>
        </Table>
        {report.__typename === "CustomerReport" && (
          <Table border className="table-overview" size="sm">
            <TableHead>
              <TableRow>
                <TableColumn name="label">Information sur les clients</TableColumn>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableColumn name="label">Nom (1)</TableColumn>
                <TableColumn name="value">{customer0?.fullName}</TableColumn>
                <TableColumn name="label">Nom (2)</TableColumn>
                <TableColumn name="value" disabled={customer1 === undefined}>
                  {customer1?.fullName}
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn name="label">Téléphone (1)</TableColumn>
                <TableColumn name="value">{customer0?.phone.toPhoneNumberFormat()}</TableColumn>
                <TableColumn name="label">Téléphone (2)</TableColumn>
                <TableColumn name="value" disabled={customer1 === undefined}>
                  {customer1?.phone.toPhoneNumberFormat()}
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn name="label">Courriel (1)</TableColumn>
                <TableColumn name="value">{customer0?.email}</TableColumn>
                <TableColumn name="label">Courriel (2)</TableColumn>
                <TableColumn name="value" disabled={customer1 === undefined}>
                  {customer1?.email}
                </TableColumn>
              </TableRow>
            </TableBody>
          </Table>
        )}
        <Table border className="table-base-title" size="sm">
          <TableHead>
            <TableRow>
              <TableColumn name="title">SÉLECTION DE BASE (STANDARD AU PROJET)</TableColumn>
            </TableRow>
          </TableHead>
        </Table>
        {report.baseTables.map((table) => (
          <Table border className="table-base" size="sm" key={table.index}>
            <TableHead>
              <TableRow>
                <TableColumn name="element">{table.activityTitle}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn name="element">ÉLÉMENT</TableColumn>
                <TableColumn name="room">PIÈCE</TableColumn>
                <TableColumn name="option">OPTION</TableColumn>
                {table.__typename === "ConstructionReportTable" && <TableColumn name="provider">FOURNISSEUR</TableColumn>}
                {table.__typename === "ConstructionReportTable" && <TableColumn name="provider-product-model">MODÈLE PRODUIT</TableColumn>}
                {table.__typename === "ConstructionReportTable" && <TableColumn name="provider-product-code"># PRODUIT</TableColumn>}
                {table.__typename === "ConstructionReportTable" && <TableColumn name="product-format">FORMAT</TableColumn>}
                <TableColumn name="comments">COMMENTAIRES</TableColumn>
              </TableRow>
            </TableHead>
            <TableBody>
              {table.rows.map((row) => (
                <TableRow key={row.index}>
                  <TableColumn name="element">{row.elementName}</TableColumn>
                  <TableColumn name="room">{row.roomName}</TableColumn>
                  <TableColumn name="option" disabled={row.type === ReportCellType.NONE}>
                    {row.value}
                  </TableColumn>
                  {row.__typename === "ConstructionReportTableRow" && <TableColumn name="provider">{row.providerName}</TableColumn>}
                  {row.__typename === "ConstructionReportTableRow" && <TableColumn name="provider-product-model">{row.productModel}</TableColumn>}
                  {row.__typename === "ConstructionReportTableRow" && <TableColumn name="provider-product-code">{row.productCode}</TableColumn>}
                  {row.__typename === "ConstructionReportTableRow" && <TableColumn name="product-format">{row.productFormat}</TableColumn>}
                  <TableColumn name="comments">{row.comments}</TableColumn>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ))}
        <Table border className="table-extra-title" size="sm">
          <TableHead>
            <TableRow>
              <TableColumn name="title">AJOUT OU MODIFICATION À LA SÉLECTION DE BASE (EXTRA)</TableColumn>
            </TableRow>
          </TableHead>
        </Table>
        {report.extraTables.map((table) => (
          <Table border className="table-extra" size="sm" key={table.index}>
            <TableHead>
              <TableRow>
                <TableColumn name="element">{table.activityTitle} - EXTRA</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn name="element">ÉLÉMENT</TableColumn>
                <TableColumn name="room">PIÈCE</TableColumn>
                <TableColumn name="option">OPTION</TableColumn>
                {table.__typename === "ConstructionReportTable" && <TableColumn name="provider">FOURNISSEUR</TableColumn>}
                {table.__typename === "ConstructionReportTable" && <TableColumn name="provider-product-model">MODÈLE PRODUIT</TableColumn>}
                {table.__typename === "ConstructionReportTable" && <TableColumn name="provider-product-code"># PRODUIT</TableColumn>}
                {table.__typename === "ConstructionReportTable" && <TableColumn name="product-format">FORMAT</TableColumn>}
                <TableColumn name="comments">COMMENTAIRES</TableColumn>
              </TableRow>
            </TableHead>
            <TableBody>
              {table.rows.map((row) => (
                <TableRow key={row.index}>
                  <TableColumn name="element">{row.elementName}</TableColumn>
                  <TableColumn name="room">{row.roomName}</TableColumn>
                  <TableColumn name="option" disabled={row.type === ReportCellType.NONE}>
                    {row.value}
                  </TableColumn>
                  {row.__typename === "ConstructionReportTableRow" && <TableColumn name="provider">{row.providerName}</TableColumn>}
                  {row.__typename === "ConstructionReportTableRow" && <TableColumn name="provider-product-model">{row.productModel}</TableColumn>}
                  {row.__typename === "ConstructionReportTableRow" && <TableColumn name="provider-product-code">{row.productCode}</TableColumn>}
                  {row.__typename === "ConstructionReportTableRow" && <TableColumn name="product-format">{row.productFormat}</TableColumn>}
                  <TableColumn name="comments">{row.comments}</TableColumn>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ))}
      </Document>
    </Report>
  );
};
