import "./index.scss";
import produce from "immer";
import { ContractSelectionConditionsAcceptModal } from "modals";
import { FunctionComponent, useState } from "react";
import { Checkbox, Form, Message } from "rsuite";

type ContractSelectionConfirmFormValues = {
  readonly isTermsAndConditionsAccepted: boolean | null;
};

type Props = {
  readonly values: ContractSelectionConfirmFormValues;
  readonly setValues: (values: ContractSelectionConfirmFormValues) => void;
};

export const useContractSelectionConfirmForm = (initialValues: ContractSelectionConfirmFormValues) => {
  const [values, setValues] = useState<ContractSelectionConfirmFormValues>(initialValues);
  return { values, setValues };
};

export const ContractSelectionConfirmForm: FunctionComponent<Props> = ({ values, setValues }) => {
  const [open, setOpen] = useState<boolean>(false);
  if (values.isTermsAndConditionsAccepted === null) {
    return null;
  }
  return (
    <>
      <Form id="contract-selection-confirm-form">
        <Message type="warning">
          <Checkbox
            style={{
              textAlign: "justify",
            }}
            checked={values.isTermsAndConditionsAccepted}
            onClick={
              values.isTermsAndConditionsAccepted
                ? () => {
                    setValues(
                      produce(values, (draft) => {
                        draft.isTermsAndConditionsAccepted = false;
                      })
                    );
                  }
                : () => {
                    setOpen(true);
                  }
            }
          >
            Je confirme avoir lu, compris et accepté toutes les conditions relatives à mes choix personnalisés, y compris les limitations du modèle 3D, les modalités de paiement, les conséquences en
            cas de non-paiement, et la possibilité de modifications des produits par Dév Méta inc.
          </Checkbox>
        </Message>
      </Form>
      {open && (
        <ContractSelectionConditionsAcceptModal
          onAccept={() => {
            setValues(
              produce(values, (draft) => {
                draft.isTermsAndConditionsAccepted = true;
              })
            );
            setOpen(false);
          }}
          onRefuse={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};
