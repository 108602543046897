import "./index.scss";
import { ViewForm } from "buildingBlocks";
import { ProductsForm, useProductsForm } from "forms";
import { useUpdateProductsMutation } from "graphql/mutations";
import { useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { FunctionComponent } from "react";
import { PermissionName } from "graphql/schema";

export const ProjectProductsUpdateView: FunctionComponent = () => {
  const { navigate, toProjectProductsView } = useRouting();
  const { tenant, providers } = useTenantContext();
  const { project, activities, products, productCategories } = useProjectContext();
  const { values, setValues } = useProductsForm({
    products: products.map((product) => ({
      id: product.id,
      categoryId: product.categoryId,
      name: product.name,
      variant: product.variant ?? "",
      format: product.format ?? "",
      usedBySections: product.usedBySections,
      provider: {
        id: product.provider.id,
        productCode: product.provider.productCode ?? "",
        productModel: product.provider.productModel ?? "",
        productUrl: product.provider.productUrl ?? "",
      },
      activities: product.activities.map((productActivity) => ({
        activityId: productActivity.activityId,
        index: productActivity.index,
      })),
    })),
  });
  const { updateProducts, updateProductsLoading } = useUpdateProductsMutation(
    ({ tenantId, projectId }) => {
      navigate(
        toProjectProductsView({
          tenantId: tenantId,
          projectId: projectId,
        })
      );
    },
    undefined,
    true
  );
  return (
    <ViewForm
      id="project-products-update-view"
      title="Modifier les produits"
      security={{
        permissions: [[PermissionName.PRODUCT_READ, PermissionName.PRODUCT_UPDATE]],
      }}
      loading={updateProductsLoading}
      onSubmit={async () => {
        await updateProducts({
          tenantId: tenant.id,
          projectId: project.id,
          products: values.products.map((product) => ({
            id: product.id,
            provider: {
              id: product.provider.id,
              productCode: product.provider.productCode.trim() || null,
              productModel: product.provider.productModel.trim() || null,
              productUrl: product.provider.productUrl.trim() || null,
            },
            activities: product.activities.map((productActivity) => ({
              activityId: productActivity.activityId,
              index: productActivity.index,
            })),
          })),
        });
      }}
      onCancel={() => {
        navigate(
          toProjectProductsView({
            tenantId: tenant.id,
            projectId: project.id,
          })
        );
      }}
    >
      <ProductsForm values={values} setValues={setValues} activities={activities} productCategories={productCategories} providers={providers} />
    </ViewForm>
  );
};
