import "./index.scss";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Unity } from "react-unity-webgl";
import { UnityContextHook } from "react-unity-webgl/distribution/types/unity-context-hook";

type Link = {
  readonly modelRoomSharedId: string;
  readonly items: ReadonlyArray<{
    readonly modelRoomElementSharedId: string;
    readonly modelRoomElementOptionSharedId: string;
    readonly metadata: {
      readonly modelRoomElementName: string;
      readonly productName: string;
    } | null;
  }>;
};

type Props = {
  readonly unityContextHook: UnityContextHook;
  readonly link: Link | null;
};

export const UnityModel: FunctionComponent<Props> = ({ unityContextHook, link }) => {
  const [unityIsLoaded, setUnityIsLoaded] = useState<boolean>(false);
  const { unityProvider, addEventListener, unload, removeEventListener, sendMessage } = unityContextHook;
  useEffect(() => {
    return () => {
      unload();
    };
  }, [unload]);
  useEffect(() => {
    if (unityIsLoaded) {
      sendMessage("ModelManager", "OnChange", JSON.stringify(link));
    }
    // eslint-disable-next-line
  }, [unityIsLoaded, sendMessage, JSON.stringify(link)]);
  const handler = useCallback(() => {
    setUnityIsLoaded(true);
  }, [setUnityIsLoaded]);
  useEffect(() => {
    addEventListener("UnityIsLoaded", handler);
    return () => {
      removeEventListener("UnityIsLoaded", handler);
    };
  }, [addEventListener, removeEventListener, handler]);
  return <Unity className="unity-model" unityProvider={unityProvider} />;
};
