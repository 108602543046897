import "./index.scss";
import { FunctionComponent } from "react";
import { Button } from "rsuite";
import { Dialog, DialogBody, DialogFooter } from "buildingBlocks";

export enum UpdateContractSelectionGroupDialogMode {
  PREVIOUS = "PREVIOUS",
  NEXT = "NEXT",
}

type Props = {
  readonly mode: UpdateContractSelectionGroupDialogMode;
  readonly loading: boolean;
  readonly onSave: (mode: UpdateContractSelectionGroupDialogMode) => void;
  readonly onClose: (mode: UpdateContractSelectionGroupDialogMode) => void;
};

export const ContractSelectionGroupUpdateDialog: FunctionComponent<Props> = ({ loading, mode, onSave, onClose }) => (
  <Dialog id="contract-selection-group-update-dialog" title="Modifier le groupe de sélection" centered>
    <DialogBody>Voulez-vous enregistrer les modifications apportées à ce groupe de sélection?</DialogBody>
    <DialogFooter>
      <Button appearance="primary" size="sm" loading={loading} onClick={() => onSave(mode)}>
        Enregistrer
      </Button>
      <Button appearance="default" size="sm" disabled={loading} onClick={() => onClose(mode)}>
        Ne pas enregistrer
      </Button>
    </DialogFooter>
  </Dialog>
);
