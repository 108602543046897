import "./index.scss";
import { FunctionComponent, useState } from "react";
import { Dialog, DialogBodyDelete, DialogFooterDelete } from "buildingBlocks";
import { useDeleteProviderMutation } from "graphql/mutations";
import { ProviderFragment } from "graphql/schema";

type Props = {
  readonly tenantId: string;
  readonly provider: ProviderFragment;
  readonly onCompleted: () => void;
  readonly onClose: () => void;
};

export const ProviderDeleteDialog: FunctionComponent<Props> = ({ tenantId, provider, onCompleted, onClose }) => {
  const [value, setValue] = useState<string>("");
  const { deleteProvider, deleteProviderLoading } = useDeleteProviderMutation(onCompleted, onClose, true);
  return (
    <Dialog id="provider-delete-dialog" title="Supprimer le fournisseur">
      <DialogBodyDelete objectName={provider.name} dictionaryList={[]} value={value} setValue={setValue} />
      <DialogFooterDelete
        disabled={provider.name !== value}
        loading={deleteProviderLoading}
        onClose={onClose}
        onClick={async () => {
          await deleteProvider({
            tenantId: tenantId,
            providerId: provider.id,
          });
        }}
      />
    </Dialog>
  );
};
