import { FunctionComponent } from "react";
import { useRouting } from "hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext, useProjectContext, useSecurityContext, useTenantContext } from "providers";
import { Layout } from "buildingBlocks/Layout";
import { PermissionName } from "graphql/schema";

type Props = {
  readonly loading: boolean;
};

export const ProjectLayout: FunctionComponent<Props> = ({ loading }) => {
  const {
    navigate,
    matchPath,
    toTenantView,
    toTenantsView,
    toProjectsView,
    toProjectView,
    toProjectActivitiesView,
    toProjectContractsView,
    toProjectContractSelectionsView,
    toProjectContractInvoicesView,
    toProjectContractPaymentsView,
    toProjectContractRefundsView,
    toProjectContractSelectionGroupUpdateView,
    toProjectFormsView,
    toProjectModelsView,
    toProjectProductsView,
    toProjectSectionsView,
    toProjectReportsView,
  } = useRouting();
  const { tenants } = useAppContext();
  const { tenant, projects } = useTenantContext();
  const { project } = useProjectContext();
  const { logout } = useAuth0();
  const { hasTenant } = useSecurityContext();
  const disabled = matchPath(
    toProjectContractSelectionGroupUpdateView({
      tenantId: null,
      projectId: null,
      contractId: null,
      contractSelectionId: null,
      contractSelectionGroupId: null,
    })
  );
  return (
    <Layout
      onHeaderClick={() => {
        navigate(
          toProjectsView({
            tenantId: tenant.id,
          })
        );
      }}
      disabled={disabled}
      loading={loading}
      tenant={tenant}
      headerNav={{
        tenants: tenants.filter((tenant) =>
          hasTenant({
            tenantId: tenant.id,
          })
        ),
        onTenantChange: (tenantId) => {
          navigate(
            toTenantView({
              tenantId: tenantId,
            })
          );
        },
        onTenantClean: () => {
          navigate(toTenantsView());
        },
        projectId: project.id,
        projects: projects,
        onProjectChange: (projectId) => {
          navigate(
            toProjectView({
              tenantId: tenant.id,
              projectId: projectId,
            })
          );
        },
        onProjectClean: () => {
          navigate(
            toProjectsView({
              tenantId: tenant.id,
            })
          );
        },
      }}
      onLogout={() => {
        logout();
      }}
      navs={[
        {
          items: [
            {
              __type: "NavItem",
              name: "Unités",
              disabled: disabled || loading,
              show: true,
              active: matchPath(
                toProjectView({
                  tenantId: null,
                  projectId: null,
                })
              ),
              security: {},
              onClick: () => {
                navigate(
                  toProjectView({
                    tenantId: tenant.id,
                    projectId: project.id,
                  })
                );
              },
            },
            {
              __type: "NavItem",
              name: "Contrats",
              disabled: disabled || loading,
              show: true,
              active:
                matchPath(
                  toProjectContractsView({
                    tenantId: null,
                    projectId: null,
                  })
                ) ||
                matchPath(
                  toProjectContractSelectionsView({
                    tenantId: null,
                    projectId: null,
                    contractId: null,
                  })
                ) ||
                matchPath(
                  toProjectContractInvoicesView({
                    tenantId: null,
                    projectId: null,
                    contractId: null,
                  })
                ) ||
                matchPath(
                  toProjectContractPaymentsView({
                    tenantId: null,
                    projectId: null,
                    contractId: null,
                  })
                ) ||
                matchPath(
                  toProjectContractRefundsView({
                    tenantId: null,
                    projectId: null,
                    contractId: null,
                  })
                ),
              security: {
                permissions: [[PermissionName.CONTRACT_READ]],
              },
              onClick: () => {
                navigate(
                  toProjectContractsView({
                    tenantId: tenant.id,
                    projectId: project.id,
                  })
                );
              },
            },
          ],
        },
        {
          items: [
            {
              __type: "NavItem",
              name: "Activités",
              disabled: disabled || loading,
              show: true,
              active: matchPath(
                toProjectActivitiesView({
                  tenantId: null,
                  projectId: null,
                })
              ),
              security: {
                permissions: [[PermissionName.ACTIVITY_READ]],
              },
              onClick: () => {
                navigate(
                  toProjectActivitiesView({
                    tenantId: tenant.id,
                    projectId: project.id,
                  })
                );
              },
            },
            {
              __type: "NavItem",
              name: "Modèles",
              disabled: disabled || loading,
              show: true,
              active: matchPath(
                toProjectModelsView({
                  tenantId: null,
                  projectId: null,
                })
              ),
              security: {
                permissions: [[PermissionName.MODEL_READ]],
              },
              onClick: () => {
                navigate(
                  toProjectModelsView({
                    tenantId: tenant.id,
                    projectId: project.id,
                  })
                );
              },
            },
            {
              __type: "NavItem",
              name: "Produits",
              disabled: disabled || loading,
              show: true,
              active: matchPath(
                toProjectProductsView({
                  tenantId: null,
                  projectId: null,
                })
              ),
              security: {
                permissions: [[PermissionName.PRODUCT_READ]],
              },
              onClick: () => {
                navigate(
                  toProjectProductsView({
                    tenantId: tenant.id,
                    projectId: project.id,
                  })
                );
              },
            },
          ],
        },
        {
          items: [
            {
              __type: "NavItem",
              name: "Rapports",
              disabled: disabled || loading,
              show: true,
              active: matchPath(
                toProjectReportsView({
                  tenantId: null,
                  projectId: null,
                })
              ),
              security: {
                permissions: [[PermissionName.REPORT_READ]],
              },
              onClick: () => {
                navigate(
                  toProjectReportsView({
                    tenantId: tenant.id,
                    projectId: project.id,
                  })
                );
              },
            },
          ],
        },
        {
          title: "Configuration",
          items: [
            {
              __type: "NavItem",
              name: "Formulaires",
              disabled: disabled || loading,
              show: true,
              active: matchPath(
                toProjectFormsView({
                  tenantId: null,
                  projectId: null,
                })
              ),
              security: {
                permissions: [[PermissionName.FORM_READ]],
              },
              onClick: () => {
                navigate(
                  toProjectFormsView({
                    tenantId: tenant.id,
                    projectId: project.id,
                  })
                );
              },
            },
            {
              __type: "NavItem",
              name: "Liste des prix",
              disabled: disabled || loading,
              show: true,
              active: matchPath(
                toProjectSectionsView({
                  tenantId: null,
                  projectId: null,
                })
              ),
              security: {
                permissions: [[PermissionName.SECTION_READ]],
              },
              onClick: () => {
                navigate(
                  toProjectSectionsView({
                    tenantId: tenant.id,
                    projectId: project.id,
                  })
                );
              },
            },
          ],
        },
      ]}
    />
  );
};
