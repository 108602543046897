import { ViewForm } from "buildingBlocks";
import { UnitForm, useUnitForm } from "forms";
import { PermissionName } from "graphql/schema";
import { useUpdateUnitMutation } from "graphql/mutations";
import { useRouting, useProjectUnitContext } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { FunctionComponent } from "react";

export const ProjectUnitUpdateView: FunctionComponent = () => {
  const { navigate, toProjectView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, forms } = useProjectContext();
  const { unit } = useProjectUnitContext();
  const { values, setValues } = useUnitForm({
    number: unit.number,
    floor: unit.floor,
    numberOfBathrooms: unit.numberOfBathrooms,
    numberOfBedrooms: unit.numberOfBedrooms,
    formId: unit.formId,
  });
  const { updateUnit, updateUnitLoading, updateUnitErrors } = useUpdateUnitMutation(({ tenantId, projectId }) => {
    navigate(
      toProjectView({
        tenantId: tenantId,
        projectId: projectId,
      })
    );
  });
  return (
    <ViewForm
      id="project-unit-update-view"
      title="Modifier l'unité"
      security={{
        permissions: [[PermissionName.UNIT_READ, PermissionName.UNIT_UPDATE]],
      }}
      errors={updateUnitErrors}
      loading={updateUnitLoading}
      onSubmit={async () => {
        await updateUnit({
          tenantId: tenant.id,
          projectId: project.id,
          unitId: unit.id,
          number: values.number,
          floor: values.floor,
          numberOfBedrooms: values.numberOfBedrooms,
          numberOfBathrooms: values.numberOfBathrooms,
          formId: values.formId,
        });
      }}
      onCancel={() => {
        navigate(
          toProjectView({
            tenantId: tenant.id,
            projectId: project.id,
          })
        );
      }}
    >
      <UnitForm values={values} setValues={setValues} forms={forms} />
    </ViewForm>
  );
};
