import { FunctionComponent } from "react";
import { PermissionName } from "graphql/schema";
import { ViewForm } from "buildingBlocks";
import { useProjectActivityContext, useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { useUpdateActivityMutation } from "graphql/mutations";
import { ActivityForm, useActivityForm } from "forms";

export const ProjectActivityUpdateView: FunctionComponent = () => {
  const { navigate, toProjectActivitiesView } = useRouting();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { activity } = useProjectActivityContext();
  const { values, setValues } = useActivityForm({
    number: activity.number,
    title: activity.title,
    description: activity.description ?? "",
  });
  const { updateActivity, updateActivityLoading, updateActivityErrors } = useUpdateActivityMutation(({ tenantId, projectId }) => {
    navigate(
      toProjectActivitiesView({
        tenantId: tenantId,
        projectId: projectId,
      })
    );
  });
  return (
    <ViewForm
      id="project-activity-update-view"
      title="Modifier l'activité"
      security={{
        permissions: [[PermissionName.ACTIVITY_READ, PermissionName.ACTIVITY_UPDATE]],
      }}
      errors={updateActivityErrors}
      loading={updateActivityLoading}
      onSubmit={async () => {
        await updateActivity({
          tenantId: tenant.id,
          projectId: project.id,
          activityId: activity.id,
          number: values.number,
          title: values.title,
          description: values.description.trim() || null,
        });
      }}
      onCancel={() => {
        navigate(
          toProjectActivitiesView({
            tenantId: tenant.id,
            projectId: project.id,
          })
        );
      }}
    >
      <ActivityForm values={values} setValues={setValues} />
    </ViewForm>
  );
};
