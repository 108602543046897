import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { EnumLabel, SearchInputGroup, ViewList, Text } from "buildingBlocks";
import { useProjectModelContext, useRouting } from "hooks";
import { Tag } from "rsuite";
import { useProjectContext, useTenantContext } from "providers";
import { PermissionName } from "graphql/schema";
import { ModelDeleteDialog } from "dialogs";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

const useProjectModelViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback(
      (modelRooms: ReadonlyArray<ProjectProviderTypes.MappedProjectModelRoom>) =>
        modelRooms
          .filter(
            (modelRoom) =>
              modelRoom.name.toSearchFormat().includes(search.toSearchFormat()) ||
              modelRoom.elements.some((modelRoomElement) => modelRoomElement.name.toSearchFormat().includes(search.toSearchFormat()))
          )
          .map((modelRoom) => ({
            ...modelRoom,
            elements: modelRoom.name.toSearchFormat().includes(search.toSearchFormat())
              ? modelRoom.elements
              : modelRoom.elements.filter((modelRoomElement) => modelRoomElement.name.toSearchFormat().includes(search.toSearchFormat())),
          })),
      [search]
    ),
  };
};

export const ProjectModelView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useProjectModelViewFilters();
  const { navigate, toProjectModelsView } = useRouting();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { model } = useProjectModelContext();
  const [modelToDelete, setModelToDelete] = useState<ProjectProviderTypes.MappedProjectModel | null>(null);
  return (
    <ViewList
      id="project-model-view"
      security={{
        permissions: [[PermissionName.MODEL_READ]],
      }}
      header={{
        subtitle: "Modèle",
        title: model.name,
        returnButton: {
          onClick: () => {
            navigate(
              toProjectModelsView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [],
          otherActions: [
            {
              __type: "Button",
              content: "Supprimer le modèle",
              show: true,
              security: {
                permissions: [[PermissionName.MODEL_DELETE]],
              },
              onClick: () => {
                setModelToDelete(model);
              },
            },
          ],
        },
        filter: {
          left: {
            inputs: [<SearchInputGroup size="xs" placeholder="Recherche par étiquette" value={filterValues.search} onChange={filterFunctions.setSearch} />],
            applyButton: null,
          },
          right: {
            inputs: [],
          },
        },
      }}
      content={{
        table: {
          headColumns: [
            {
              name: "index",
              value: "#",
            },
            {
              name: "name",
              value: "PIÈCE / ÉLÉMENT",
            },
            {
              name: "type",
              value: "TYPE",
            },
            {
              name: "option-count",
              value: "NB OPTIONS",
            },
          ],
          bodyRows: [],
          bodyRowGroups: applyFilters(model.rooms).map((modelRoom) => {
            return {
              key: modelRoom.id,
              columns: [
                {
                  name: "index",
                  component: <Tag className="model-room-index">{modelRoom.index + 1}</Tag>,
                },
                {
                  name: "name",
                  component: modelRoom.name,
                },
                {
                  name: "type",
                  component: "",
                },
                {
                  name: "option-count",
                  component: "",
                },
              ],
              actions: [],
              rows: modelRoom.elements.map((modelRoomElement) => {
                return {
                  key: modelRoomElement.id,
                  actions: [],
                  columns: [
                    {
                      name: "index",
                      component: <Tag className="model-room-element-index">{modelRoomElement.index + 1}</Tag>,
                    },
                    {
                      name: "name",
                      component: <Text highlight>{modelRoomElement.name}</Text>,
                    },
                    {
                      name: "type",
                      component: <EnumLabel value={modelRoomElement.type} uppercase bold block width={200} />,
                    },
                    {
                      name: "option-count",
                      component: modelRoomElement.options.length,
                    },
                  ],
                };
              }),
            };
          }),
        },
      }}
    >
      {modelToDelete && (
        <ModelDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          model={modelToDelete}
          onCompleted={() => {
            navigate(
              toProjectModelsView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          }}
          onClose={() => {
            setModelToDelete(null);
          }}
        />
      )}
    </ViewList>
  );
};
