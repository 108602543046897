import "./index.scss";
import { FunctionComponent } from "react";
import { getEnumLabel, Link, Panel, PanelControl, ViewDetail } from "buildingBlocks";
import { useRouting } from "hooks";
import { useAppContext, useTenantContext } from "providers";

export const TenantSettingsProfileView: FunctionComponent = () => {
  const { navigate, toTenantView, toTenantSettingsView } = useRouting();
  const { countries } = useAppContext();
  const { tenant, profile } = useTenantContext();
  const country = countries.find((country) => country.code === profile.address.countryCode)!;
  const countrySubdivision = country.subdivisions.find((countrySubdivision) => countrySubdivision.code === profile.address.subdivisionCode)!;
  return (
    <ViewDetail
      id="tenant-settings-profile-view"
      security={{
        isTenantProfileManager: true,
      }}
      header={{
        title: "Profil de l'organisation",
        returnButton: {
          onClick: () => {
            navigate(
              toTenantSettingsView({
                tenantId: tenant.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              appearance: "default",
              text: "Fermer les paramètres",
              show: true,
              security: {},
              onClick: () => {
                navigate(
                  toTenantView({
                    tenantId: tenant.id,
                  })
                );
              },
            },
          ],
          otherActions: [],
        },
      }}
    >
      <Panel>
        <PanelControl label="Nom de l'organisation" values={[profile.companyName]} />
        <PanelControl label="Courriel de l'expéditeur" values={[profile.email]} />
        <PanelControl label="Téléphone" values={[profile.phone.toPhoneNumberFormat()]} />
        <PanelControl label="Langue" values={[getEnumLabel(profile.language)]} />
        <PanelControl label="Devise de base" values={[profile.currencyCode]} />
        <PanelControl
          label="URL de site Web"
          values={[
            profile.websiteUrl ? (
              <Link external href={profile.websiteUrl}>
                {profile.websiteUrl}
              </Link>
            ) : (
              "--"
            ),
          ]}
        />
        <PanelControl
          label="Adresse de l'organisation"
          values={[
            <address>
              {profile.address.line}
              <br />
              {profile.address.city}, {countrySubdivision.name.toUpperCase()}, {profile.address.postalCode}
              <br />
              {country.name.toUpperCase()}
            </address>,
          ]}
        />
      </Panel>
    </ViewDetail>
  );
};
