import "./index.scss";
import { FunctionComponent, PropsWithChildren, ReactNode } from "react";
import { useEnsureSecurity } from "hooks";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HeaderToolbar } from "buildingBlocks/HeaderToolbar";
import { IconButton } from "rsuite";
import { Types } from "types";

type Props = PropsWithChildren & {
  readonly id: string;
  readonly security: Types.Security;
  readonly header: {
    readonly returnButton?: {
      readonly onClick: () => void;
    };
    readonly title: ReactNode;
    readonly subtitle: string;
    readonly toolbar: Types.HeaderToolbar;
  };
};

export const ViewModel: FunctionComponent<Props> = ({ children, id, security, header }) => {
  useEnsureSecurity(security);
  return (
    <section id={id} className="view-model">
      <header>
        <div className="left">
          <div>
            {header.returnButton && <IconButton className="btn-return" size="sm" icon={<FontAwesomeIcon icon={faAngleLeft} />} appearance="default" onClick={header.returnButton.onClick} />}
            <div>
              {header.subtitle && <small className="subtitle">{header.subtitle}</small>}
              <h1>{header.title}</h1>
            </div>
          </div>
          <HeaderToolbar buttons={header.toolbar.buttons} otherActions={header.toolbar.otherActions} />
        </div>
      </header>
      <main>{children}</main>
    </section>
  );
};
