import "./index.scss";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FormContainer, FormPanel, getEnumLabel, InputIndex } from "buildingBlocks";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";
import produce from "immer";
import { FunctionComponent, useState } from "react";
import { Form, Input, SelectPicker } from "rsuite";
import { Guid } from "utils";
import { ReportRoomElementDisplay } from "graphql/schema";

export type ReportFormRoomElementValues = {
  readonly id: string;
  readonly index: number;
  readonly name: string;
  readonly display: ReportRoomElementDisplay;
  readonly isDeleted: boolean;
};

type ReportFormValues = {
  readonly name: string;
  readonly activities: ReadonlyArray<{
    readonly activityId: string;
    readonly index: number;
  }>;
  readonly rooms: ReadonlyArray<{
    readonly id: string;
    readonly index: number;
    readonly name: string;
    readonly isDeleted: boolean;
    readonly elements: ReadonlyArray<ReportFormRoomElementValues>;
  }>;
};

type Props = {
  readonly values: ReportFormValues;
  readonly setValues: (values: ReportFormValues) => void;
  readonly activities: ReadonlyArray<ProjectProviderTypes.MappedProjectActivity>;
};

export const useReportForm = (initialValues: ReportFormValues) => {
  const [values, setValues] = useState<ReportFormValues>(initialValues);
  return { values, setValues };
};

export const ReportForm: FunctionComponent<Props> = ({ values, setValues, activities }) => {
  const activityIds = values.activities.map((reportActivity) => reportActivity.activityId);
  return (
    <Form id="report-form">
      <FormContainer highlight>
        <Form.Group>
          <Form.ControlLabel>Nom</Form.ControlLabel>
          <Input size="lg" value={values.name} readOnly />
        </Form.Group>
      </FormContainer>
      <FormContainer
        fluid
        empty={{
          show: values.activities.length === 0,
          label: "Aucune activité",
          content: "Pour créer une activité cliquez sur le bouton 'Ajouter une activité' en haut à droite.",
        }}
        header={{
          title: "Activités",
          actions: [
            {
              name: "Ajouter une activité",
              icon: faAdd,
              disabled: activities.length === values.activities.length || activities.every((activity) => activityIds.includes(activity.id)),
              onClick: () => {
                setValues(
                  produce(values, (draft) => {
                    draft.activities.push({
                      activityId: "",
                      index: draft.activities.length,
                    });
                  })
                );
              },
            },
          ],
        }}
      >
        {[...values.activities]
          .sort((left, right) => left.index - right.index)
          .map((reportActivity) => (
            <FormPanel
              key={reportActivity.index}
              header={{
                start: {
                  index: reportActivity.index,
                  buttons: {
                    increase: {
                      disabled: reportActivity.index === 0,
                      onClick: () => {
                        setValues(
                          produce(values, (draft) => {
                            const draftActivityToIncrease = draft.activities.find((draftActivity) => draftActivity.index === reportActivity.index);
                            const draftActivityToDecrease = draft.activities.find((draftActivity) => draftActivity.index === reportActivity.index - 1);
                            if (draftActivityToIncrease && draftActivityToDecrease) {
                              draftActivityToIncrease.index = draftActivityToIncrease.index - 1;
                              draftActivityToDecrease.index = draftActivityToDecrease.index + 1;
                            }
                          })
                        );
                      },
                    },
                    decrease: {
                      disabled: reportActivity.index === values.activities.length - 1,
                      onClick: () => {
                        setValues(
                          produce(values, (draft) => {
                            const draftActivityToDecrease = draft.activities.find((draftActivity) => draftActivity.index === reportActivity.index);
                            const draftActivityToIncrease = draft.activities.find((draftActivity) => draftActivity.index === reportActivity.index + 1);
                            if (draftActivityToIncrease && draftActivityToDecrease) {
                              draftActivityToIncrease.index = draftActivityToIncrease.index - 1;
                              draftActivityToDecrease.index = draftActivityToDecrease.index + 1;
                            }
                          })
                        );
                      },
                    },
                  },
                },
                center: {
                  inputs: [
                    {
                      name: "label",
                      label: "Étiquette",
                      node: (
                        <SelectPicker
                          size="sm"
                          readOnly={!!reportActivity.activityId}
                          cleanable={false}
                          value={reportActivity.activityId}
                          onChange={(value) => {
                            setValues(
                              produce(values, (draft) => {
                                const draftActivity = draft.activities.find((draftActivity) => draftActivity.index === reportActivity.index);
                                if (draftActivity) {
                                  draftActivity.activityId = value!;
                                }
                              })
                            );
                          }}
                          data={activities
                            .filter((activity) => activity.id === reportActivity.activityId || !activityIds.includes(activity.id))
                            .map((activity) => ({
                              label: activity.label,
                              value: activity.id,
                            }))}
                        />
                      ),
                    },
                  ],
                },
                end: {
                  buttons: [
                    {
                      name: "Supprimer l'activité",
                      onClick: () => {
                        setValues(
                          produce(values, (draft) => {
                            draft.activities = draft.activities
                              .filter((draftActivity) => draftActivity.index !== reportActivity.index)
                              .map((draftActivity, draftActivityIndex) => ({
                                ...draftActivity,
                                index: draftActivityIndex,
                              }));
                          })
                        );
                      },
                    },
                  ],
                },
              }}
            ></FormPanel>
          ))}
      </FormContainer>
      <FormContainer
        fluid
        empty={{
          show: values.rooms.length === 0,
          label: "Aucune pièce",
          content: "Pour créer une pièce cliquez sur le bouton 'Ajouter une pièce' en haut à droite.",
        }}
        header={{
          title: "Pièces",
          actions: [
            {
              name: "Ajouter une pièce",
              icon: faAdd,
              disabled: false,
              onClick: () => {
                setValues(
                  produce(values, (draft) => {
                    draft.rooms.push({
                      id: Guid.newGuid(),
                      index: draft.rooms.length,
                      name: "",
                      isDeleted: false,
                      elements: [
                        {
                          id: Guid.newGuid(),
                          index: 0,
                          name: "",
                          display: ReportRoomElementDisplay.DEFAULT,
                          isDeleted: false,
                        },
                      ],
                    });
                  })
                );
              },
            },
          ],
        }}
      >
        {[...values.rooms]
          .sort((left, right) => left.index - right.index)
          .map((room) => (
            <FormPanel
              key={room.id}
              header={{
                start: {
                  index: room.index,
                  buttons: {
                    increase: {
                      disabled: room.index === 0,
                      onClick: () => {
                        setValues(
                          produce(values, (draft) => {
                            const draftRoomToIncrease = draft.rooms.find((draftRoom) => draftRoom.index === room.index);
                            const draftRoomToDecrease = draft.rooms.find((draftRoom) => draftRoom.index === room.index - 1);
                            if (draftRoomToIncrease && draftRoomToDecrease) {
                              draftRoomToIncrease.index = draftRoomToIncrease.index - 1;
                              draftRoomToDecrease.index = draftRoomToDecrease.index + 1;
                            }
                          })
                        );
                      },
                    },
                    decrease: {
                      disabled: room.index === values.rooms.length - 1,
                      onClick: () => {
                        setValues(
                          produce(values, (draft) => {
                            const draftRoomToDecrease = draft.rooms.find((draftRoom) => draftRoom.index === room.index);
                            const draftRoomToIncrease = draft.rooms.find((draftRoom) => draftRoom.index === room.index + 1);
                            if (draftRoomToIncrease && draftRoomToDecrease) {
                              draftRoomToIncrease.index = draftRoomToIncrease.index - 1;
                              draftRoomToDecrease.index = draftRoomToDecrease.index + 1;
                            }
                          })
                        );
                      },
                    },
                  },
                },
                center: {
                  inputs: [
                    {
                      name: "name",
                      label: "Nom",
                      node: (
                        <Input
                          size="sm"
                          value={room.name}
                          onChange={(value) => {
                            setValues(
                              produce(values, (draft) => {
                                const draftRoom = draft.rooms.find((draftRoom) => draftRoom.id === room.id);
                                if (draftRoom) {
                                  draftRoom.name = value;
                                }
                              })
                            );
                          }}
                        />
                      ),
                    },
                  ],
                },
                end: {
                  buttons: [
                    {
                      name: "Supprimer l'élément",
                      onClick: () => {
                        setValues(
                          produce(values, (draft) => {
                            draft.rooms = draft.rooms
                              .filter((draftRoom) => draftRoom.id !== room.id)
                              .map((draftRoom, draftRoomIndex) => ({
                                ...draftRoom,
                                index: draftRoomIndex,
                              }));
                          })
                        );
                      },
                    },
                  ],
                },
              }}
              body={{
                table: {
                  title: "Éléments",
                  buttons: [
                    {
                      appearance: "link",
                      text: "Ajouter un élément",
                      icon: faAdd,
                      onClick: () => {
                        setValues(
                          produce(values, (draft) => {
                            const draftRoom = draft.rooms.find((draftRoom) => draftRoom.id === room.id);
                            if (draftRoom) {
                              draftRoom.elements.push({
                                id: Guid.newGuid(),
                                index: draftRoom.elements.length,
                                name: "",
                                display: ReportRoomElementDisplay.DEFAULT,
                                isDeleted: false,
                              });
                            }
                          })
                        );
                      },
                    },
                  ],
                  headColumns: [
                    {
                      name: "index",
                      value: "INDEX",
                    },
                    {
                      name: "name",
                      value: "NOM",
                    },
                    {
                      name: "display",
                      value: "AFFICHAGE",
                    },
                  ],
                  bodyRows: [...room.elements]
                    .sort((left, right) => left.index - right.index)
                    .map((roomElement) => {
                      return {
                        key: roomElement.id,
                        appearance: "default",
                        columns: [
                          {
                            name: "index",
                            component: (
                              <InputIndex
                                index={roomElement.index}
                                buttons={{
                                  increase: {
                                    disabled: roomElement.index === 0,
                                    onClick: () => {
                                      setValues(
                                        produce(values, (draft) => {
                                          const draftRoom = draft.rooms.find((draftRoom) => draftRoom.id === room.id);
                                          if (draftRoom) {
                                            const draftRoomElementToIncrease = draftRoom.elements.find((draftRoomElement) => draftRoomElement.index === roomElement.index);
                                            const draftRoomElementToDecrease = draftRoom.elements.find((draftRoomElement) => draftRoomElement.index === roomElement.index - 1);
                                            if (draftRoomElementToIncrease && draftRoomElementToDecrease) {
                                              draftRoomElementToIncrease.index = draftRoomElementToIncrease.index - 1;
                                              draftRoomElementToDecrease.index = draftRoomElementToDecrease.index + 1;
                                            }
                                          }
                                        })
                                      );
                                    },
                                  },
                                  decrease: {
                                    disabled: roomElement.index === room.elements.length - 1,
                                    onClick: () => {
                                      setValues(
                                        produce(values, (draft) => {
                                          const draftRoom = draft.rooms.find((draftRoom) => draftRoom.id === room.id);
                                          if (draftRoom) {
                                            const draftRoomElementToDecrease = draftRoom.elements.find((draftRoomElement) => draftRoomElement.index === roomElement.index);
                                            const draftRoomElementToIncrease = draftRoom.elements.find((draftRoomElement) => draftRoomElement.index === roomElement.index + 1);
                                            if (draftRoomElementToIncrease && draftRoomElementToDecrease) {
                                              draftRoomElementToIncrease.index = draftRoomElementToIncrease.index - 1;
                                              draftRoomElementToDecrease.index = draftRoomElementToDecrease.index + 1;
                                            }
                                          }
                                        })
                                      );
                                    },
                                  },
                                }}
                              />
                            ),
                          },
                          {
                            name: "name",
                            component: (
                              <Input
                                size="sm"
                                value={roomElement.name}
                                onChange={(value) => {
                                  setValues(
                                    produce(values, (draft) => {
                                      const draftRoom = draft.rooms.find((draftElement) => draftElement.id === room.id);
                                      if (draftRoom) {
                                        const draftRoomElement = draftRoom.elements.find((draftRoomElement) => draftRoomElement.id === roomElement.id);
                                        if (draftRoomElement) {
                                          draftRoomElement.name = value;
                                        }
                                      }
                                    })
                                  );
                                }}
                              />
                            ),
                          },
                          {
                            name: "display",
                            component: (
                              <SelectPicker
                                value={roomElement.display}
                                onChange={(value) => {
                                  setValues(
                                    produce(values, (draft) => {
                                      const draftRoom = draft.rooms.find((draftRoom) => draftRoom.id === room.id);
                                      if (draftRoom) {
                                        const draftRoomElement = draftRoom.elements.find((draftRoomElement) => draftRoomElement.id === roomElement.id);
                                        if (draftRoomElement) {
                                          draftRoomElement.display = value!;
                                        }
                                      }
                                    })
                                  );
                                }}
                                cleanable
                                data={[
                                  {
                                    label: getEnumLabel(ReportRoomElementDisplay.DEFAULT),
                                    value: ReportRoomElementDisplay.DEFAULT,
                                  },
                                  {
                                    label: getEnumLabel(ReportRoomElementDisplay.EXTRA_ONLY),
                                    value: ReportRoomElementDisplay.EXTRA_ONLY,
                                  },
                                ]}
                              />
                            ),
                          },
                        ],
                        actions: [
                          {
                            __type: "Button",
                            content: "Supprimer l'élément",
                            show: true,
                            security: {},
                            onClick: () => {
                              setValues(
                                produce(values, (draft) => {
                                  const draftRoom = draft.rooms.find((draftRoom) => draftRoom.id === room.id);
                                  if (draftRoom) {
                                    draftRoom.elements = draftRoom.elements
                                      .filter((draftRoomElement) => draftRoomElement.id !== roomElement.id)
                                      .map((draftRoomElement, draftElementIndex) => ({
                                        ...draftRoomElement,
                                        index: draftElementIndex,
                                      }));
                                  }
                                })
                              );
                            },
                          },
                        ],
                      };
                    }),
                  bodyRowGroups: [],
                },
              }}
            />
          ))}
      </FormContainer>
    </Form>
  );
};
