import { createContext, FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import { useList } from "react-use";
import { ContainerLoader } from "buildingBlocks";
import { useFindTenantLazyQuery } from "graphql/queries";
import { Outlet, useParams } from "react-router-dom";
import { useRouting } from "hooks";
import { IHookStateSetAction } from "react-use/lib/misc/hookState";
import { useAppContext, useSecurityContext } from "providers";
import {
  CurrencyCode,
  CustomerFragment,
  Language,
  PermissionFragment,
  ProjectFragment,
  ProviderFragment,
  RoleFragment,
  TaxAgencyFragment,
  TaxFragment,
  TaxGroupFragment,
  TenantFragment,
  TenantProfileFragment,
  TenantStatus,
  TenantType,
  UserFragment,
} from "graphql/schema";

export type MappedRole = Omit<RoleFragment, "permissions"> & {
  readonly permissions: ReadonlyArray<PermissionFragment>;
};

export type MappedUser = Omit<Omit<UserFragment, "roleId">, "projectIds"> & {
  readonly role: MappedRole;
  readonly projects: ReadonlyArray<ProjectFragment>;
};

export type MappedTax = Omit<TaxFragment, "agencyId"> & {
  readonly agency: TaxAgencyFragment;
};

export type MappedTaxGroup = Omit<TaxGroupFragment, "taxIds"> & {
  readonly taxes: ReadonlyArray<MappedTax>;
};

type Params = {
  readonly tenantId: string;
};

type TenantContextValue = {
  readonly tenant: TenantFragment;
  readonly profile: TenantProfileFragment;
  readonly taxes: ReadonlyArray<MappedTax>;
  readonly taxGroups: ReadonlyArray<MappedTaxGroup>;
  readonly taxAgencies: ReadonlyArray<TaxAgencyFragment>;
  readonly roles: ReadonlyArray<MappedRole>;
  readonly users: ReadonlyArray<MappedUser>;
  readonly projects: ReadonlyArray<ProjectFragment>;
  readonly customers: ReadonlyArray<CustomerFragment>;
  readonly providers: ReadonlyArray<ProviderFragment>;
  readonly upsertTaxes: (tenantId: string, taxes: ReadonlyArray<TaxFragment>) => void;
  readonly removeTaxes: (tenantId: string, taxIds: ReadonlyArray<string>) => void;
  readonly upsertTaxGroups: (tenantId: string, taxGroups: ReadonlyArray<TaxGroupFragment>) => void;
  readonly removeTaxGroups: (tenantId: string, taxGroupIds: ReadonlyArray<string>) => void;
  readonly upsertTaxAgencies: (tenantId: string, taxAgencies: ReadonlyArray<TaxAgencyFragment>) => void;
  readonly removeTaxAgencies: (tenantId: string, taxAgencyIds: ReadonlyArray<string>) => void;
  readonly upsertRoles: (tenantId: string, roles: ReadonlyArray<RoleFragment>) => void;
  readonly removeRoles: (tenantId: string, roleIds: ReadonlyArray<string>) => void;
  readonly upsertUsers: (tenantId: string, users: ReadonlyArray<UserFragment>) => void;
  readonly removeUsers: (tenantId: string, userIds: ReadonlyArray<string>) => void;
  readonly upsertProjects: (tenantId: string, projects: ReadonlyArray<ProjectFragment>) => void;
  readonly removeProjects: (tenantId: string, projectIds: ReadonlyArray<string>) => void;
  readonly upsertCustomers: (tenantId: string, customers: ReadonlyArray<CustomerFragment>) => void;
  readonly removeCustomers: (tenantId: string, customerIds: ReadonlyArray<string>) => void;
  readonly upsertProviders: (tenantId: string, providers: ReadonlyArray<ProviderFragment>) => void;
  readonly removeProviders: (tenantId: string, providerIds: ReadonlyArray<string>) => void;
};

const initialValue: TenantContextValue = {
  tenant: {
    __typename: "Tenant",
    id: "",
    status: TenantStatus.SUSPENDED,
    type: TenantType.PRINCIPAL,
    name: "",
  },
  profile: {
    __typename: "TenantProfile",
    companyName: "",
    email: "",
    phone: "",
    language: Language.FR,
    currencyCode: CurrencyCode.CAD,
    websiteUrl: "",
    address: {
      __typename: "Address",
      line: "",
      city: "",
      countryCode: "",
      subdivisionCode: "",
      postalCode: "",
    },
  },
  taxes: [],
  taxGroups: [],
  taxAgencies: [],
  roles: [],
  users: [],
  projects: [],
  customers: [],
  providers: [],
  upsertTaxes: () => {},
  removeTaxes: () => {},
  upsertTaxGroups: () => {},
  removeTaxGroups: () => {},
  upsertTaxAgencies: () => {},
  removeTaxAgencies: () => {},
  upsertRoles: () => {},
  removeRoles: () => {},
  upsertUsers: () => {},
  removeUsers: () => {},
  upsertProjects: () => {},
  removeProjects: () => {},
  upsertCustomers: () => {},
  removeCustomers: () => {},
  upsertProviders: () => {},
  removeProviders: () => {},
};

const TenantContext = createContext<TenantContextValue>(initialValue);

const useTenantContext = () => useContext(TenantContext);

function useCollection<T extends { id: string }>(
  tenant: TenantFragment,
  compareFn?: (a: T, b: T) => number
): [
  T[],
  {
    set: (newList: IHookStateSetAction<T[]>) => void;
    upsert: (tenantId: string, itemsToUpdate: ReadonlyArray<T>) => void;
    remove: (tenantId: string, ids: ReadonlyArray<string>) => void;
  },
] {
  const [data, { set }] = useList<T>([]);
  return [
    data,
    {
      set,
      upsert: useCallback(
        (tenantId: string, itemsToUpdate: ReadonlyArray<T>) => {
          if (tenantId === tenant.id) {
            const map: Map<string, T> = new Map(data.map((item) => [item.id, item]));
            for (const item of itemsToUpdate) {
              map.set(item.id, item);
            }
            let array = Array.from(map.values());
            if (compareFn) {
              array = array.sort(compareFn);
            }
            set(array);
          }
        },
        [tenant, data, set, compareFn]
      ),
      remove: useCallback(
        (tenantId: string, ids: ReadonlyArray<string>) => {
          if (tenantId === tenant.id) {
            set(data.filter((item) => !ids.includes(item.id)));
          }
        },
        [tenant, data, set]
      ),
    },
  ];
}

const TenantProvider: FunctionComponent = () => {
  const { permissions } = useAppContext();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [tenant, setTenant] = useState<TenantFragment>(initialValue.tenant);
  const [profile, setProfile] = useState<TenantProfileFragment>(initialValue.profile);
  const [taxes, { set: setTaxes, upsert: upsertTaxes, remove: removeTaxes }] = useCollection<TaxFragment>(tenant, (left, right) => left.name.localeCompare(right.name));
  const [taxGroups, { set: setTaxGroups, upsert: upsertTaxGroups, remove: removeTaxGroups }] = useCollection<TaxGroupFragment>(tenant, (left, right) => left.name.localeCompare(right.name));
  const [taxAgencies, { set: setTaxAgencies, upsert: upsertTaxAgencies, remove: removeTaxAgencies }] = useCollection<TaxAgencyFragment>(tenant, (left, right) => left.name.localeCompare(right.name));
  const [users, { set: setUsers, upsert: upsertUsers, remove: removeUsers }] = useCollection<UserFragment>(tenant, (left, right) => left.fullName.localeCompare(right.fullName));
  const [roles, { set: setRoles, upsert: upsertRoles, remove: removeRoles }] = useCollection<RoleFragment>(tenant, (left, right) => left.name.localeCompare(right.name));
  const [projects, { set: setProjects, upsert: upsertProjects, remove: removeProjects }] = useCollection<ProjectFragment>(tenant, (left, right) => left.name.localeCompare(right.name));
  const [customers, { set: setCustomers, upsert: upsertCustomers, remove: removeCustomers }] = useCollection<CustomerFragment>(tenant, (left, right) =>
    left.displayName.localeCompare(right.displayName)
  );
  const [providers, { set: setProviders, upsert: upsertProviders, remove: removeProviders }] = useCollection<ProviderFragment>(tenant, (left, right) => left.name.localeCompare(right.name));
  const [findTenant, { loading: loadingTenant }] = useFindTenantLazyQuery((tenant, profile, taxes, taxGroups, taxAgencies, roles, users, projects, customers, providers) => {
    setTenant(tenant);
    setProfile(profile);
    setTaxes([...taxes]);
    setTaxGroups([...taxGroups]);
    setTaxAgencies([...taxAgencies]);
    setUsers([...users]);
    setRoles([...roles]);
    setProjects([...projects]);
    setCustomers([...customers]);
    setProviders([...providers]);
    setLoaded(true);
  });
  const mappedRoles: ReadonlyArray<MappedRole> = roles.map((role) => ({
    ...role,
    permissions: permissions.filter((permission) => role.permissions.includes(permission.name)),
  }));
  const mappedUsers: ReadonlyArray<MappedUser> = users.map((user) => ({
    ...user,
    role: mappedRoles.find((role) => role.id === user.roleId)!,
    projects: projects.filter((project) => user.projectIds.includes(project.id)),
  }));
  const mappedTaxes: ReadonlyArray<MappedTax> = taxes.map((tax) => {
    const agency = taxAgencies.find((taxAgency) => taxAgency.id === tax.agencyId);
    return {
      ...tax,
      agency: agency!,
    };
  });
  const mappedTaxGroups: ReadonlyArray<MappedTaxGroup> = taxGroups.map((taxGroup) => {
    return {
      ...taxGroup,
      taxes: mappedTaxes.filter((tax) => taxGroup.taxIds.includes(tax.id)),
    };
  });
  const { navigate, toTenantsView } = useRouting();
  const { tenantId } = useParams<Params>();
  const { hasTenant } = useSecurityContext();
  useEffect(() => {
    if (tenantId && !hasTenant({ tenantId: tenantId })) {
      navigate(toTenantsView());
    }
  }, [tenantId, hasTenant, navigate, toTenantsView]);
  useEffect(() => {
    if (tenantId) {
      findTenant({
        variables: {
          tenantId: tenantId,
        },
      });
    }
  }, [tenantId, findTenant]);
  if (!loaded || loadingTenant) {
    return <ContainerLoader />;
  }
  return (
    <TenantContext.Provider
      value={{
        tenant: tenant,
        profile: profile,
        taxes: mappedTaxes,
        taxGroups: mappedTaxGroups,
        taxAgencies: taxAgencies,
        roles: mappedRoles,
        users: mappedUsers,
        projects: projects,
        customers: customers,
        providers: providers,
        upsertTaxes,
        removeTaxes,
        upsertTaxGroups,
        removeTaxGroups,
        upsertTaxAgencies,
        removeTaxAgencies,
        upsertUsers,
        removeUsers,
        upsertRoles,
        removeRoles,
        upsertProjects,
        removeProjects,
        upsertCustomers,
        removeCustomers,
        upsertProviders,
        removeProviders,
      }}
    >
      <Outlet />
    </TenantContext.Provider>
  );
};

export { TenantProvider, useTenantContext };
