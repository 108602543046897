import "./index.scss";
import { FunctionComponent, useState } from "react";
import { Link, Panel, PanelControl, ViewDetail } from "buildingBlocks";
import { useTenantProviderContext, useRouting } from "hooks";
import { ProviderDeleteDialog } from "dialogs";
import { useTenantContext } from "providers";
import { ProviderFragment, PermissionName } from "graphql/schema";

export const ProviderView: FunctionComponent = () => {
  const { navigate, toTenantProvidersView, toTenantProviderUpdateView } = useRouting();
  const { tenant } = useTenantContext();
  const { provider } = useTenantProviderContext();
  const [providerToDelete, setProviderToDelete] = useState<ProviderFragment | null>(null);
  return (
    <ViewDetail
      id="provider-view"
      security={{
        permissions: [[PermissionName.PROVIDER_READ]],
      }}
      header={{
        title: provider.name,
        subtitle: "Fournisseur",
        returnButton: {
          onClick: () => {
            navigate(
              toTenantProvidersView({
                tenantId: tenant.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              text: "Modifier le fournisseur",
              security: {
                permissions: [[PermissionName.PROVIDER_UPDATE]],
              },
              show: true,
              onClick: () => {
                navigate(
                  toTenantProviderUpdateView({
                    tenantId: tenant.id,
                    providerId: provider.id,
                  })
                );
              },
            },
          ],
          otherActions: [
            {
              __type: "Button",
              content: "Supprimer le fournisseur",
              security: {
                permissions: [[PermissionName.PROVIDER_DELETE]],
              },
              show: true,
              onClick: () => {
                setProviderToDelete(provider);
              },
            },
          ],
        },
      }}
    >
      <Panel>
        <PanelControl label="Description" values={[provider.description ?? "--"]} />
        <PanelControl
          label="Site Web"
          values={[
            provider.websiteUrl ? (
              <Link href={provider.websiteUrl} external>
                {provider.websiteUrl}
              </Link>
            ) : (
              "--"
            ),
          ]}
        />
      </Panel>
      {providerToDelete && (
        <ProviderDeleteDialog
          tenantId={tenant.id}
          provider={providerToDelete}
          onCompleted={() => {
            setProviderToDelete(null);
          }}
          onClose={() => {
            setProviderToDelete(null);
          }}
        />
      )}
    </ViewDetail>
  );
};
