import "./index.scss";
import { FunctionComponent } from "react";

type Props = {
  readonly value: number;
};

export const BalanceAmount: FunctionComponent<Props> = ({ value }) => {
  let balance = value;
  if (value < 0) {
    balance = value * -1;
  }
  return (
    <span className={`balance-amount${value < 0 ? " balance-amount-credit" : ""}`}>
      {value < 0 ? "CR " : ""}
      {balance.toCurrencyFormat()}
    </span>
  );
};
