import "./index.scss";
import { CountryFragment, Gender, Language } from "graphql/schema";
import { Fragment, FunctionComponent, useState } from "react";
import { Form, Input, SelectPicker } from "rsuite";
import { FormContainer, getEnumLabel } from "buildingBlocks";
import produce from "immer";

type CustomerFormValues = {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly phone: string;
  readonly gender: Gender;
  readonly language: Language;
  readonly notes: string;
  readonly address: {
    readonly line: string;
    readonly city: string;
    readonly subdivisionCode: string;
    readonly countryCode: string;
    readonly postalCode: string;
  };
};

type Props = {
  readonly values: CustomerFormValues;
  readonly setValues: (values: CustomerFormValues) => void;
  readonly countries: ReadonlyArray<CountryFragment>;
};

export const useCustomerForm = (initialValues: CustomerFormValues) => {
  const [values, setValues] = useState<CustomerFormValues>(initialValues);
  return { values, setValues };
};

export const CustomerForm: FunctionComponent<Props> = ({ values, setValues, countries }) => {
  const country = countries.find((country) => country.code === values.address.countryCode);
  return (
    <Form id="customer-form">
      <FormContainer highlight>
        <Form.Group>
          <Form.ControlLabel>Prénom</Form.ControlLabel>
          <Input
            value={values.firstName}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.firstName = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Nom</Form.ControlLabel>
          <Input
            value={values.lastName}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.lastName = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Courriel</Form.ControlLabel>
          <Input
            value={values.email}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.email = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Téléphone</Form.ControlLabel>
          <Input
            value={values.phone}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.phone = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Genre</Form.ControlLabel>
          <SelectPicker
            value={values.gender}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.gender = value!;
                })
              );
            }}
            data={[
              { label: getEnumLabel(Gender.MALE), value: Gender.MALE },
              { label: getEnumLabel(Gender.FEMALE), value: Gender.FEMALE },
            ]}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Langue</Form.ControlLabel>
          <SelectPicker
            value={values.language}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.language = value!;
                })
              );
            }}
            cleanable={false}
            data={[
              { label: getEnumLabel(Language.FR), value: Language.FR },
              { label: getEnumLabel(Language.EN), value: Language.EN },
            ]}
          />
        </Form.Group>
      </FormContainer>
      <FormContainer
        header={{
          title: "Adresse",
          actions: [],
        }}
      >
        <Form.Group>
          <Form.ControlLabel>Pays</Form.ControlLabel>
          <SelectPicker
            value={values.address.countryCode}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.address.countryCode = value!;
                })
              );
            }}
            cleanable={false}
            data={countries.map((country) => ({
              label: country.name,
              value: country.code,
            }))}
          />
        </Form.Group>
        {country && (
          <Fragment>
            <Form.Group>
              <Form.ControlLabel>Ligne</Form.ControlLabel>
              <Input
                value={values.address.line}
                onChange={(value) => {
                  setValues(
                    produce(values, (draft) => {
                      draft.address.line = value;
                    })
                  );
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Ville</Form.ControlLabel>
              <Input
                value={values.address.city}
                onChange={(value) => {
                  setValues(
                    produce(values, (draft) => {
                      draft.address.city = value;
                    })
                  );
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>État/Province/Région</Form.ControlLabel>
              <SelectPicker
                value={values.address.subdivisionCode}
                onChange={(value) => {
                  setValues(
                    produce(values, (draft) => {
                      draft.address.subdivisionCode = value!;
                    })
                  );
                }}
                cleanable={false}
                disabled={country === undefined}
                data={country.subdivisions.map((subdivision) => ({
                  label: subdivision.name,
                  value: subdivision.code,
                }))}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Code postal</Form.ControlLabel>
              <Input
                value={values.address.postalCode}
                onChange={(value) => {
                  setValues(
                    produce(values, (draft) => {
                      draft.address.postalCode = value;
                    })
                  );
                }}
              />
            </Form.Group>
          </Fragment>
        )}
      </FormContainer>
    </Form>
  );
};
