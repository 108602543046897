import "./index.scss";
import { FunctionComponent, useState } from "react";
import { Dialog, DialogBodyDelete, DialogFooterDelete } from "buildingBlocks";
import { useDeleteContractRevisionMutation } from "graphql/mutations";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly contractId: string;
  readonly revision: ProjectProviderTypes.MappedProjectContractSelection;
  readonly onCompleted: () => void;
  readonly onClose: () => void;
};

export const ContractRevisionDeleteDialog: FunctionComponent<Props> = ({ tenantId, projectId, contractId, revision, onCompleted, onClose }) => {
  const [value, setValue] = useState<string>("");
  const { deleteContractRevision, deleteContractRevisionLoading } = useDeleteContractRevisionMutation(onCompleted, onClose, true);
  return (
    <Dialog id="contract-revision-delete-dialog" title="Supprimer la sélection">
      <DialogBodyDelete objectName={revision.label} dictionaryList={[]} value={value} setValue={setValue} />
      <DialogFooterDelete
        disabled={revision.label !== value}
        loading={deleteContractRevisionLoading}
        onClose={onClose}
        onClick={async () => {
          await deleteContractRevision({
            tenantId: tenantId,
            projectId: projectId,
            contractId: contractId,
            selectionId: revision.id,
          });
        }}
      />
    </Dialog>
  );
};
