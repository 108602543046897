import "./index.scss";
import { useCreateContractRefundMutation } from "graphql/mutations";
import { FunctionComponent } from "react";
import { ModalForm } from "buildingBlocks";
import { RefundMode } from "graphql/schema";
import { ContractRefundForm, useContractRefundForm } from "forms";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly contract: ProjectProviderTypes.MappedProjectContract;
  readonly refunds: ReadonlyArray<ProjectProviderTypes.MappedProjectRefund>;
  readonly onCompleted: () => void;
  readonly onCancel: () => void;
};

export const ContractRefundCreateModal: FunctionComponent<Props> = ({ tenantId, projectId, contract, refunds, onCompleted, onCancel }) => {
  const { values, setValues } = useContractRefundForm({
    label: `RFD-${("000000" + (refunds.length === 0 ? 1 : refunds.map((refund) => refund.number).max()! + 1)).slice(-6)}`,
    date: new Date(),
    referenceNumber: "",
    mode: RefundMode.CHECK,
    amount: 0,
    notes: "",
  });
  const { createContractRefund, createContractRefundLoading, createContractRefundErrors } = useCreateContractRefundMutation(onCompleted, undefined, false);
  return (
    <ModalForm
      id="contract-refund-create-modal"
      title="Créer un remboursement"
      errors={createContractRefundErrors}
      loading={createContractRefundLoading}
      disabled={values.amount <= 0}
      submitButton={{
        onClick: async () => {
          await createContractRefund({
            tenantId: tenantId,
            projectId: projectId,
            contractId: contract.id,
            date: values.date,
            mode: values.mode,
            referenceNumber: values.referenceNumber.trim() || null,
            amount: values.amount,
            notes: values.notes.trim() || null,
          });
        },
      }}
      closeButton={{
        text: "Annuler",
        onClick: onCancel,
      }}
    >
      <ContractRefundForm values={values} setValues={setValues} contract={contract} />
    </ModalForm>
  );
};
