import "./index.scss";
import { Fragment, FunctionComponent } from "react";
import { UnitState } from "graphql/schema";
import { Message } from "rsuite";

type Props = {
  readonly unitState: UnitState;
};

export const ReportMessage: FunctionComponent<Props> = ({ unitState }) => {
  const reportMessage = getReportMessageByState(unitState);
  if (reportMessage) {
    return (
      <Message type="info" showIcon className={`report-message ${reportMessage.className}`} header={reportMessage.title}>
        {reportMessage.paragraphs.map((paragraph, paragraphIndex) => (
          <Fragment key={paragraphIndex}>{paragraph}</Fragment>
        ))}
      </Message>
    );
  }
  return null;
};

const getReportMessageByState = (unitState: UnitState) => {
  switch (unitState) {
    case UnitState.NO_CONTRACT: {
      return {
        title: "Unité sans contrat",
        paragraphs: [<p>L'unité n'est associée à aucun contrat.</p>, <p>Pour associer une unité à un client, cliquez sur "Accueil' dans le menu de gauche.</p>],
        className: "report-message-no-contract",
      };
    }
    case UnitState.CONTRACT_IS_CUSTOMIZED: {
      return {
        title: "Unité en personnalisation",
        paragraphs: [<p>Ce type de contrat implique une personnalisation majeure de l'unité par le client, ce qui n'est pas pris en charge par la plateforme.</p>],
        className: "report-message-contract-is-customized",
      };
    }
    case UnitState.CONTRACT_WITHOUT_FORM: {
      return {
        title: "Unité sans formulaire",
        paragraphs: [<p>Cette unité a été associée à un client avec un contrat, mais aucun formulaire n'a été sélectionné pour permettre au client de faire sa sélection de choix.</p>],
        className: "report-message-contract-without-form",
      };
    }
    case UnitState.CONTRACT_SELECTION_PENDING: {
      return {
        title: "Sélection en attente",
        paragraphs: [<p>Pour accéder au rapport, vous devez compléter la sélection de base du client.</p>],
        className: "report-message-contract-selection-pending",
      };
    }
    case UnitState.CONTRACT_REVISION_PENDING: {
      return {
        title: "Révision en attente",
        paragraphs: [<p>Pour accéder au rapport, vous devez compléter la révision du client.</p>],
        className: "report-message-contract-revision-pending",
      };
    }
    case UnitState.CONTRACT_PAYMENT_PENDING: {
      return null;
    }
    case UnitState.CONTRACT_REFUND_PENDING: {
      return null;
    }
    case UnitState.CONTRACT_COMPLETED: {
      return null;
    }
    default: {
      throw new Error("ReportMessage: Invalid switch case.");
    }
  }
};
