import { useRouting } from "hooks";
import "./index.scss";
import { FunctionComponent, PropsWithChildren } from "react";

type Props = PropsWithChildren & {
  readonly href: string;
  readonly external?: boolean;
};

export const Link: FunctionComponent<Props> = ({ href, children, external = false }) => {
  const { navigate } = useRouting();
  if (external) {
    return (
      <a className="link" href={href} rel="noreferrer" target="_blank">
        {children}
      </a>
    );
  }
  return (
    <span
      className="link"
      onClick={() => {
        navigate(href);
      }}
    >
      {children}
    </span>
  );
};
