import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { EnumLabel, SearchInputGroup, TooltipWrapper, ViewList, Text } from "buildingBlocks";
import { useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { PermissionName, SectionElementType, SectionState } from "graphql/schema";
import { Tag } from "rsuite";
import { SectionConfigureDialog, SectionDeleteDialog, SectionPublishDialog } from "dialogs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

const useProjectSectionsViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback(
      (sections: ReadonlyArray<ProjectProviderTypes.MappedProjectSection>) =>
        sections
          .filter(
            (section) =>
              section.title.toSearchFormat().includes(search.toSearchFormat()) ||
              section.elements.some(
                (element) =>
                  (element.__typename === "SectionElement" && element.label.toSearchFormat().includes(search.toSearchFormat())) ||
                  (element.__typename === "SectionElementSet" && element.sectionElementGroupName.toSearchFormat().includes(search.toSearchFormat()))
              )
          )
          .map((section) => ({
            ...section,
            elements: section.title.toSearchFormat().includes(search.toSearchFormat())
              ? section.elements
              : section.elements.filter(
                  (element) =>
                    (element.__typename === "SectionElement" && element.label.toSearchFormat().includes(search.toSearchFormat())) ||
                    (element.__typename === "SectionElementSet" && element.sectionElementGroupName.toSearchFormat().includes(search.toSearchFormat()))
                ),
          })),
      [search]
    ),
  };
};

export const ProjectSectionsView: FunctionComponent = () => {
  const { navigate, toProjectView, toProjectSectionView, toProjectSectionCreateView, toProjectSectionUpdateView, toProjectSectionVibesView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, sections } = useProjectContext();
  const { filterValues, filterFunctions, applyFilters } = useProjectSectionsViewFilters();
  const [sectionToPublish, setSectionToPublish] = useState<ProjectProviderTypes.MappedProjectSection | null>(null);
  const [sectionToConfigure, setSectionToConfigure] = useState<ProjectProviderTypes.MappedProjectSection | null>(null);
  const [sectionToDelete, setSectionToDelete] = useState<ProjectProviderTypes.MappedProjectSection | null>(null);
  return (
    <ViewList
      id="project-sections-view"
      security={{
        permissions: [[PermissionName.SECTION_READ]],
      }}
      header={{
        title: "Toutes les sections",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              text: "Créer une section",
              show: sections.length !== 0,
              security: {
                permissions: [[PermissionName.SECTION_CREATE]],
              },
              onClick: () => {
                navigate(
                  toProjectSectionCreateView({
                    tenantId: tenant.id,
                    projectId: project.id,
                  })
                );
              },
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [<SearchInputGroup size="xs" placeholder="Recherche par étiquette" value={filterValues.search} onChange={filterFunctions.setSearch} />],
            applyButton: null,
          },
          right: {
            inputs: [],
          },
        },
      }}
      content={{
        table: {
          emptyBodies: [
            {
              show: sections.length === 0,
              title: "Aucune section",
              content: "Pour créer une section, cliquez sur le bouton ci-dessous.",
              button: {
                text: "Créer une section",
                security: {
                  permissions: [[PermissionName.SECTION_CREATE]],
                },
                onClick: () => {
                  navigate(
                    toProjectSectionCreateView({
                      tenantId: tenant.id,
                      projectId: project.id,
                    })
                  );
                },
              },
            },
          ],
          headColumns: [
            {
              name: "index",
              value: "#",
            },
            {
              name: "name",
              value: "SECTION / ÉLÉMENT",
            },
            {
              name: "type",
              value: "ÉTAT / TYPE",
            },
            {
              name: "number",
              value: "NB AMBIANCES / NB OPTIONS",
            },
          ],
          bodyRows: [],
          bodyRowGroups: applyFilters(sections).map((section) => {
            return {
              key: section.id,
              columns: [
                {
                  name: "index",
                  component: <Tag className="section-index">{section.index + 1}</Tag>,
                },
                {
                  name: "name",
                  component: (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {section.modelLink && (
                        <TooltipWrapper tooltip="Lié au(x) modèle(s)">
                          <FontAwesomeIcon color="grey" size="sm" icon={faLink} />
                        </TooltipWrapper>
                      )}
                      <span>{section.title}</span>
                    </div>
                  ),
                },
                {
                  name: "type",
                  component: (
                    <EnumLabel
                      value={section.state}
                      appearances={[
                        {
                          value: SectionState.CREATED,
                          appearance: "primary",
                        },
                        {
                          value: SectionState.CONFIGURED,
                          appearance: "primary",
                        },
                        {
                          value: SectionState.PUBLISHED,
                          appearance: "primary",
                        },
                        {
                          value: SectionState.ARCHIVED,
                          appearance: "primary",
                        },
                      ]}
                      block
                      bold
                      uppercase
                      width={200}
                    />
                  ),
                },
                {
                  name: "number",
                  component: section.vibes.length,
                },
              ],
              actions: [
                {
                  __type: "Button",
                  content: "Voir la section",
                  show: true,
                  security: {
                    permissions: [[PermissionName.SECTION_READ]],
                  },
                  onClick: () => {
                    navigate(
                      toProjectSectionView({
                        tenantId: tenant.id,
                        projectId: project.id,
                        sectionId: section.id,
                      })
                    );
                  },
                },
                {
                  __type: "Button",
                  content: "Voir les ambiances",
                  show: section.getElementsWithoutSets().some((sectionElement) => sectionElement.isManagedByVibes),
                  security: {},
                  onClick: () => {
                    navigate(
                      toProjectSectionVibesView({
                        tenantId: tenant.id,
                        projectId: project.id,
                        sectionId: section.id,
                      })
                    );
                  },
                },
                {
                  __type: "Button",
                  content: "Configurer la section",
                  show: section.state === SectionState.CREATED && section.getElementsWithoutSets().some((sectionElement) => sectionElement.isManagedByVibes),
                  security: {
                    permissions: [[PermissionName.SECTION_CONFIGURE]],
                  },
                  onClick: () => {
                    setSectionToConfigure(section);
                  },
                },
                {
                  __type: "Button",
                  content: "Publier la section",
                  show:
                    (section.state === SectionState.CONFIGURED && section.vibes.length >= 1) ||
                    (section.state === SectionState.CREATED && section.vibes.length === 0 && section.getElementsWithoutSets().every((sectionElement) => !sectionElement.isManagedByVibes)),
                  security: {
                    permissions: [[PermissionName.SECTION_PUBLISH]],
                  },
                  onClick: () => {
                    setSectionToPublish(section);
                  },
                },
                {
                  __type: "Button",
                  content: "Modifier la section",
                  show: section.state === SectionState.CREATED,
                  security: {
                    permissions: [[PermissionName.SECTION_UPDATE]],
                  },
                  onClick: () => {
                    navigate(
                      toProjectSectionUpdateView({
                        tenantId: tenant.id,
                        projectId: project.id,
                        sectionId: section.id,
                      })
                    );
                  },
                },
                {
                  __type: "Button",
                  content: "Supprimer la section",
                  show: section.state === SectionState.CREATED || section.state === SectionState.CONFIGURED,
                  security: {
                    permissions: [[PermissionName.SECTION_DELETE]],
                  },
                  onClick: () => {
                    setSectionToDelete(section);
                  },
                },
              ],
              rows: section.elements.map((sectionElement) => {
                if (sectionElement.__typename === "SectionElement") {
                  return {
                    key: sectionElement.id,
                    columns: [
                      {
                        name: "index",
                        component: <Tag className="section-element-index">{sectionElement.index + 1}</Tag>,
                      },
                      {
                        name: "name",
                        component: (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            {sectionElement.modelLink && (
                              <TooltipWrapper tooltip="Lié au(x) modèle(s)">
                                <FontAwesomeIcon color="grey" size="sm" icon={faLink} />
                              </TooltipWrapper>
                            )}
                            <Text highlight>{sectionElement.label}</Text>
                          </div>
                        ),
                      },
                      {
                        name: "type",
                        component: (
                          <EnumLabel
                            value={sectionElement.type}
                            appearances={[
                              {
                                value: SectionElementType.NONE,
                                appearance: "secondary",
                              },
                              {
                                value: SectionElementType.BOOLEAN,
                                appearance: "secondary",
                              },
                              {
                                value: SectionElementType.READONLY,
                                appearance: "secondary",
                              },
                              {
                                value: SectionElementType.UNIQUE,
                                appearance: "secondary",
                              },
                            ]}
                            block
                            bold
                            uppercase
                            width={200}
                          />
                        ),
                      },
                      {
                        name: "number",
                        component: sectionElement.getOptionsWithoutSets().length,
                      },
                    ],
                    actions: [],
                  };
                }
                return {
                  key: sectionElement.sectionElementGroupId,
                  columns: [
                    {
                      name: "index",
                      component: (
                        <Tag className="section-element-index">
                          {sectionElement.minIndex === sectionElement.maxIndex ? `${sectionElement.minIndex + 1}` : `${sectionElement.minIndex + 1}-${sectionElement.maxIndex + 1}`}
                        </Tag>
                      ),
                    },
                    {
                      name: "name",
                      component: (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <Text highlight>
                            {sectionElement.sectionElementGroupName} [{sectionElement.elements.map((element) => element.label).join(", ")}]
                          </Text>
                        </div>
                      ),
                    },
                    {
                      name: "type",
                      component: (
                        <EnumLabel
                          value="GROUPE"
                          appearances={[
                            {
                              value: "GROUPE",
                              appearance: "secondary",
                            },
                          ]}
                          block
                          bold
                          uppercase
                          width={200}
                        />
                      ),
                    },
                    {
                      name: "number",
                      component: sectionElement.elements.length,
                    },
                  ],
                  actions: [],
                };
              }),
            };
          }),
        },
      }}
    >
      {sectionToPublish && (
        <SectionPublishDialog
          tenantId={tenant.id}
          projectId={project.id}
          section={sectionToPublish}
          onCompleted={() => {
            setSectionToPublish(null);
          }}
          onClose={() => {
            setSectionToPublish(null);
          }}
        />
      )}
      {sectionToConfigure && (
        <SectionConfigureDialog
          tenantId={tenant.id}
          projectId={project.id}
          section={sectionToConfigure}
          onCompleted={() => {
            setSectionToConfigure(null);
          }}
          onClose={() => {
            setSectionToConfigure(null);
          }}
        />
      )}
      {sectionToDelete && (
        <SectionDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          section={sectionToDelete}
          onCompleted={() => {
            setSectionToDelete(null);
          }}
          onClose={() => {
            setSectionToDelete(null);
          }}
        />
      )}
    </ViewList>
  );
};
