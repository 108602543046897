import "./index.scss";
import { FunctionComponent, ReactNode } from "react";

type Props = {
  readonly inline?: boolean;
  readonly items: ReadonlyArray<{
    readonly title: string;
    readonly description: ReactNode;
  }>;
};

export const DictionaryList: FunctionComponent<Props> = ({ inline = false, items }) => (
  <div className={`dictionary-list${inline ? ` dictionary-list-inline` : ""}`}>
    {items.map((item, itemIndex) => (
      <div key={itemIndex} className="dictionary-list-item">
        <span className="dictionary-list-item-title">{item.title}</span>
        <span className="dictionary-list-item-description">{item.description}</span>
      </div>
    ))}
  </div>
);
