import "./index.scss";
import { ButtonGroup, Button, Whisper, Popover, Dropdown, IconButton } from "rsuite";
import { FunctionComponent } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { TypeAttributes } from "rsuite/esm/@types/common";
import { Types } from "types";
import { useSecurityContext } from "providers";

type Props = {
  readonly size?: TypeAttributes.Size;
  readonly appearance?: TypeAttributes.Appearance;
  readonly icon?: IconProp;
  readonly text: string;
  readonly disabled: boolean;
  readonly onClick: () => void;
  readonly actions: ReadonlyArray<Types.Action>;
};

export const ButtonSplit: FunctionComponent<Props> = ({ size = "md", appearance = "primary", icon, text, onClick, disabled, actions }) => {
  const { isAuthorized } = useSecurityContext();
  const authorizedActions = actions.filter((action) => action.__type === "Divider" || (action.__type === "Button" && isAuthorized({ security: action.security })));
  return (
    <ButtonGroup className="button-split" size={size}>
      <Button onClick={onClick} disabled={disabled} appearance={appearance}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {text}
      </Button>
      {authorizedActions.length !== 0 && (
        <Whisper
          placement="bottomEnd"
          trigger="click"
          speaker={({ onClose, left, top, className }, ref) => {
            return (
              <Popover ref={ref} className={className} style={{ left, top }} full>
                <Dropdown.Menu
                  onSelect={() => {
                    onClose();
                  }}
                >
                  {authorizedActions.map((action, index) => {
                    if (action.show === false) {
                      return null;
                    }
                    if (action.__type === "Divider") {
                      return <Dropdown.Item key={index} divider />;
                    }
                    return (
                      <Dropdown.Item onClick={action.onClick} key={index} eventKey={index}>
                        {action.icon && <FontAwesomeIcon icon={action.icon} />}
                        {action.content}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Popover>
            );
          }}
        >
          <IconButton appearance={appearance} icon={<FontAwesomeIcon icon={faAngleDown} />} />
        </Whisper>
      )}
    </ButtonGroup>
  );
};
