import "./index.scss";
import { CustomerFragment } from "graphql/schema";
import { FunctionComponent } from "react";
import { getEnumLabel, TooltipWrapper, Text } from "buildingBlocks";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  readonly highlight?: boolean;
  readonly customer: CustomerFragment;
};

export const CustomerDetails: FunctionComponent<Props> = ({ customer, highlight = false }) => (
  <div className="customer-details">
    <span>{getEnumLabel(customer.type)}</span>
    <div>
      {customer.isDefault && (
        <TooltipWrapper tooltip="Verrouillé">
          <FontAwesomeIcon color="grey" size="sm" icon={faLock} />
        </TooltipWrapper>
      )}
      {highlight ? <Text highlight>{customer.displayName}</Text> : customer.displayName}
    </div>
  </div>
);
